import * as Yup from 'yup';

export const USER_YUPSCHEMA = {
    email: Yup.string().required('Please enter Email ID').email('Email format is incorrect'),
    firstName: Yup.string().required('Please enter First Name'),
    lastName: Yup.string().required('Please enter Last Name'),
    radioValue: Yup.string().notRequired(),
    dropdownClient: Yup.lazy(() =>
        Yup.string().when(['radioValue'], (radioValue: any) => {
            if (radioValue !== undefined && radioValue !== 'ACG') {
                return Yup.string().required('Please select Client');
            } else {
                return Yup.string().notRequired();
            }
        })
    ),
    dropdownRole: Yup.string().required('Please select Role'),
    dropdownCountryCode: Yup.string().required('Please select Country Code'),
    mobile: Yup.string()
        .matches(/^[0-9]\d{9}$/, 'Mobile Number must be of 10 digit')
        .required('Please Enter Mobile Number')
};

export const ROLE_YUPSCHEMA = {
    roleName: Yup.string().required('Please enter Role Name'),
    roleCode: Yup.string().required('Please enter Role Code'),
    radioValue: Yup.string().notRequired(),
    dropdownClient: Yup.lazy(() =>
        Yup.string().when(['radioValue'], (radioValue: any) => {
            if (radioValue !== 'ACG') {
                return Yup.string().required('Please select Client');
            } else {
                return Yup.string().notRequired();
            }
        })
    )
};
