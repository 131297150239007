import { useState, useEffect } from 'react';
import { Container } from '@mui/material';
import AcgStepper from '../../../../atoms/Stepper';
import ClientInfo from './ClientInfo';
import AssignFeatures from './AssignFeatures';
import '../../FeatureManagement/CreateReport/index.scss';

type Props = {
    drawerClose: Function;
    setCurrTab: Function;
    setReload: Function;
    setSnackbarOpts: Function;
    isEdit: boolean;
    dtlData: any;
};
// const options = {};
const CreateClient = (props: Props) => {
    const { drawerClose, setCurrTab, setReload = () => {}, setSnackbarOpts = () => {}, isEdit, dtlData } = props;
    const [step, setStep] = useState(0);
    const [stepState, setStepState] = useState({ info: true, assign: false });
    const [step1Vals, setStep1Vals] = useState<any>({});

    useEffect(() => {
        if (step === 0) {
            setStepState({ info: true, assign: false });
        } else {
            setStepState({ info: false, assign: true });
        }
    }, [step]);

    return (
        <Container className="createClient">
            <div style={{ marginTop: '37px' }}>
                <AcgStepper
                    steps={[
                        { label: 'Client Info', active: stepState?.info },
                        { label: 'Assign Features', active: stepState?.assign }
                    ]}
                />
            </div>
            {step == 0 ? (
                <ClientInfo
                    drawerClose={drawerClose}
                    setCurrTab={setCurrTab}
                    setReload={setReload}
                    setSnackbarOpts={setSnackbarOpts}
                    setStep={setStep}
                    step1Vals={step1Vals}
                    setStep1Vals={setStep1Vals}
                    isEdit={isEdit}
                    dtlData={dtlData}
                />
            ) : (
                <AssignFeatures
                    drawerClose={drawerClose}
                    setCurrTab={setCurrTab}
                    setReload={setReload}
                    setSnackbarOpts={setSnackbarOpts}
                    setStep={setStep}
                    step1Vals={step1Vals}
                    setStep1Vals={setStep1Vals}
                    isEdit={isEdit}
                    dtlData={dtlData}
                />
            )}
        </Container>
    );
};

export default CreateClient;
