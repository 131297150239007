import * as React from 'react';
import { useState, useEffect } from 'react';
import CustomTable from '../../../../atoms/CustomTable/index';
import CustomTableToolbar from '../../../../atoms/CustomTableToolbar';
import allAlarms from '../../../../_mocks_/allAlarms';
import { Container, Card } from '@mui/material';
import { applySortFilter } from '../../../../utils/helper';
// import "../index.scss";
// import { filter } from "lodash";
// import MapCustomTabs from "../../../atoms/ButtonTabs/MapCustomTabs";
// import FilterModal from "../../../atoms/filterModal";
import MachineMonitoringDrawer from '../../../../atoms/MachineMonitoringDrawer';
import useGetState from '../../../../utils/hooks/useGetState';
import { useDispatch } from 'react-redux';
import { ACTION_CODES, STORE_KEYS } from '../../../../constants/apiConstants';
import { executeACGAction, resetStoreKey, updateScreenIdentifiers } from '../../../../store/slice';
// import StatusCard from "../../../atoms/DashboardStatus/StatusCard";
import AlertBox from '../../../../atoms/AlertBox';

const TABLE_HEAD = [
    { id: 'CustomerName', label: 'Client', alignRight: false },
    { id: 'PlantName', label: 'Plant', alignRight: false },
    { id: 'MachineSerialNo', label: 'Machine', alignRight: false },
    { id: 'Action', label: 'Action', alignLeft: true }
];

const options = {
    DEFAULT: {
        message: '',
        open: false,
        type: ''
    },
    RM_SUCCESS: {
        message: 'Remote Map Deleted',
        open: true,
        type: 'success'
    },
    USR_ADD_SUCCESS: {
        message: 'Remote User Added successfully!',
        open: true,
        type: 'success'
    }
};

type Anchor = 'right';

const ContactDetails = (props: any) => {
    const { data, usedIn } = props;
    const [state, setState] = useState({
        right: false
    });
    const [page, setPage] = useState<number>(0);
    const [order, setOrder] = useState('desc');
    const [orderBy, setOrderBy] = useState('CustomerName');
    const [selected, setSelected] = useState<any>([]);
    const [filterName, setFilterName] = useState<string>('');
    const [tableData, setTableData] = useState<any[]>([]);
    const reduxState = useGetState();
    const dispatch = useDispatch();
    const [removeBxOpen, setRemoveBxOpen] = useState(false);
    const closeRmBx = () => {
        setRemoveBxOpen(false);
    };
    const [rmUser, setRmUser] = useState(false);
    const [snackbarOptions, setSnackbarOptions] = useState(options.DEFAULT);
    const [selectedRow, setSelectedRow] = useState<any>({});
    const [RCUMapKey, setRCUMapKey] = useState<any>();
    // const state = useGetState();
    const removeFn = (row: any, ele: any) => {
        setSelectedRow({ ...row });
        setRCUMapKey(row?.RCUMapKey);
        // setDtlData(row);
        setRemoveBxOpen(true);
    };

    useEffect(() => {
        getContactDetailsList();
        return () => {
            setTableData([]);
        };
    }, []);

    useEffect(() => {
        if (rmUser) {
            closeRmBx();
            dispatch(
                executeACGAction({
                    payload: {
                        requestType: 'DELETE',
                        urlPath: ACTION_CODES.DELETE_MAP_CONTACT + RCUMapKey
                    },
                    uniqueScreenIdentifier: {
                        rowDeleted: true
                    },
                    storeKey: STORE_KEYS.DELETE
                })
            );
            setRmUser(false);
        }
    }, [rmUser]);
    useEffect(() => {
        if (reduxState[STORE_KEYS.DELETE]?.rowDeleted) {
            if (reduxState?.[STORE_KEYS.DELETE]?.statusCode === 200) {
                setSnackbarOptions(options.RM_SUCCESS);
                getContactDetailsList();
                return () => {
                    setTableData([]);
                };
                // setDtlData([]);
                // setCreateDrawerOpen(false);
                // getList();
                // getSummary();
                // alert('row deleted')
            }
            dispatch(
                updateScreenIdentifiers({
                    storeKey: STORE_KEYS.DELETE,
                    uniqueScreenIdentifier: {
                        rowDeleted: false
                    }
                })
            );
        }
    }, [reduxState[STORE_KEYS.DELETE]?.rowDeleted]);

    const getContactDetailsList = () => {
        const RCUserKey = data?.RCUserKey
        dispatch(
            executeACGAction({
                payload: {
                    requestType: 'GET',
                    urlPath: `${ACTION_CODES.GET_CONTACT_DETAILS}/${RCUserKey}`

                },
                storeKey: STORE_KEYS.CONTACT_DETAILS
            })
        );
    };

    useEffect(() => {
        if (
            reduxState?.[STORE_KEYS.CONTACT_DETAILS]?.body?.data &&
            reduxState?.[STORE_KEYS.CONTACT_DETAILS]?.statusCode === 200
        ) {
            const __activeAlarms = reduxState?.[STORE_KEYS.CONTACT_DETAILS]?.body?.data;
            setTableData(__activeAlarms);
            dispatch(resetStoreKey({ storeKey: STORE_KEYS.CONTACT_DETAILS }));
        }
        dispatch(
            updateScreenIdentifiers({
                storeKey: STORE_KEYS.CONTACT_DETAILS
            })
        );
    }, [reduxState?.[STORE_KEYS.CONTACT_DETAILS]?.body?.data]);

    const filteredList = applySortFilter(tableData, order, filterName, orderBy);

    const handleRequestSort = (event: any, property: any) => {
        setFilterName('');
        console.log(event, property);
        const isAsc = orderBy === event && order === 'asc';
        setOrder(isAsc ? 'desc' : 'asc');
        setOrderBy(event);
    };

    const handleSelectAllClick = (event: any) => {
        if (event.target.checked) {
            const newSelecteds = [];
            for (let i = 0; i < tableData.length; i++) {
                newSelecteds.push(tableData[i].id);
            }
            const toRemove = tableData.slice(page, tableData.length);
            setSelected(newSelecteds);
            return;
        }
        setSelected([]);
    };

    const handleClick = (requestedBy: string) => {
        const selectedIndex = selected.indexOf(requestedBy);
        let newSelected: any[] | ((prevState: unknown[]) => unknown[]) = [];
        if (selectedIndex === -1) {
            newSelected = newSelected.concat(selected, requestedBy);
        } else if (selectedIndex === 0) {
            newSelected = newSelected.concat(selected.slice(1));
        } else if (selectedIndex === selected.length - 1) {
            newSelected = newSelected.concat(selected.slice(0, -1));
        } else if (selectedIndex > 0) {
            newSelected = newSelected.concat(selected.slice(0, selectedIndex), selected.slice(selectedIndex + 1));
        }
        setSelected(newSelected);
    };

    const toggleDrawer = (anchor: Anchor, open: boolean) => (event: React.KeyboardEvent | React.MouseEvent) => {
        if (
            event.type === 'keydown' &&
            ((event as React.KeyboardEvent).key === 'Tab' || (event as React.KeyboardEvent).key === 'Shift')
        ) {
            return;
        }
        setState({ ...state, [anchor]: open });
    };

    const handleFilterByName = (event: { target: { value: React.SetStateAction<string> } }) => {
        setOrderBy('CustomerName');
        setFilterName(event.target.value);
    };

    const handleChangePage = (event: any, newPage: React.SetStateAction<number>) => {
        setPage(newPage);
    };

    const showRow = (event: any) => { };

    const handleRoute = (row: any, ele: any) => {
        if (ele === 'Action') {
        }
    };

    const handleFilter = () => { };

    return (
        <div>
            <div>
                <Container
                    maxWidth="xl"
                    style={{
                        paddingLeft: '3px',
                        paddingRight: '3px'
                    }}
                >
                    <Card
                        style={{
                            borderRadius: '0px',
                            backgroundColor: '#2b2d42',
                            overflow: 'inherit'
                        }}
                    >
                        <CustomTableToolbar
                            drawerOpen={toggleDrawer('right', true)}
                            numSelected={selected?.length}
                            filterName={filterName}
                            onFilterName={handleFilterByName}
                            displayInput={true}
                            text={{
                                title: 'Mapped Machines',
                                subTitle: `${tableData?.length} Machines`
                            }}
                            filterButton={false}
                            customButton={false}
                        //     radios={true}
                        //     radioValue={radioValue}
                        //     radioFields={  [
                        //       { label: 'All', value: 'All' },
                        //       { label: 'Unassigned', value: 'Unassigned' },
                        //       { label: 'Onboarded', value: 'Onboarded' }
                        //   ]}
                        //     onRadioChange = {handleRadioValue}
                        />
                    </Card>
                </Container>
                <CustomTable
                    selectable={false}
                    headers={TABLE_HEAD}
                    body={filteredList}
                    filteredUsers={filteredList}
                    filterName={filterName}
                    selected={selected}
                    order={order}
                    orderBy={orderBy}
                    onRequestSort={handleRequestSort}
                    onSelectAllClick={handleSelectAllClick}
                    rowChange={(event: string) => handleClick(event)}
                    rowCount={filteredList.length}
                    onPageChange={handleChangePage}
                    page={page}
                    setPageNumber={setPage}
                    selectRow={showRow}
                    route={handleRoute}
                    isRequestPage={true}
                    link={true}
                    removeFn={removeFn}
                    onlyDeleteModeInner={true}
                />

                <AlertBox
                    isOpen={removeBxOpen}
                    handleClose={closeRmBx}
                    leftBtnPress={closeRmBx}
                    rightBtnPress={() => setRmUser(true)}
                    modalHeader="Delete Remote Map"
                    modalContent="Are you sure you want to delete Remote Map?"
                />
            </div>
        </div>
    );
};

export default ContactDetails;
