import { Card } from '@mui/material';
import Label from '../../atoms/Label';
import { sentenceCase } from 'change-case';
import Clock from '../../../assets/images/clock.svg';
import Plant from '../../../assets/images/Plant.svg';
import './index.scss';

type Props = {
    data?: any;
    selectedRow?: any;
    type?: any;
    alarmList?: Boolean;
};

const MachineMonitoringCard = (props: Props) => {
    const { title, line, status, location, time } = props?.data;
    const { selectedRow, type, alarmList = false } = props;
    return (
        <Card
            className="machineMonitoringCard"
            onClick={selectedRow}
            style={{ cursor: selectedRow ? 'pointer' : '', marginBottom: type === 'noBottom' ? '0px' : '' }}
        >
            <div className="machineBlock">
                <div className={alarmList ? 'cardMaxWidth' : ''}>
                    <h5 className="title">{title}</h5>
                    {/* <p className="line">{line}</p> */}
                </div>

                <Label
                    variant="ghost"
                    color={
                        status === 'Critical'
                            ? 'critical'
                            : status === 'Major'
                            ? 'major'
                            : status === 'Minor'
                            ? 'minor'
                            : status === 'Failure'
                            ? 'failure'
                            : status === 'Maintenance'
                            ? 'maintenance'
                            : status === 'General'
                            ? 'general'
                            : 'major'
                    }
                >
                    {/* {status ? sentenceCase(status) : null} */}
                    {status ? sentenceCase(status) : 'Unavailable'}
                </Label>
            </div>{' '}
            <div className="machineBlock">
                <p style={{ wordBreak: 'break-word' }} className="line">
                    {line}
                </p>
            </div>
            <div className="machineBlock">
                <p className="paraGraph">
                    <img className="location" src={Plant} alt=""></img>
                    {location}
                </p>
                <p className="paraGraph">
                    <img className="time" src={Clock} alt="" />
                    {time}
                </p>
            </div>
        </Card>
    );
};
export default MachineMonitoringCard;
