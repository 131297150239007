import React, { useEffect, useRef } from 'react';
import { Chart, LinearScale, CategoryScale, Title, Tooltip, Legend } from 'chart.js';
import { BoxPlotController, BoxAndWiskers } from '@sgratzl/chartjs-chart-boxplot';

// Register necessary ChartJS components
Chart.register(
  BoxPlotController,
  BoxAndWiskers,
  LinearScale,
  CategoryScale,
  Title,
  Tooltip,
  Legend
);

const BoxPlotGraph = ({ data }) => {
  const chartRef = useRef(null); // Ref for the chart's canvas
  const chartInstance = useRef(null); // Ref for the chart instance

  useEffect(() => {
    // Cleanup the previous chart before creating a new one
    if (chartInstance.current) {
      chartInstance.current.destroy();
    }

    if (chartRef.current && data?.length > 0) {
      const ctx = chartRef.current.getContext('2d'); // Get canvas context

      // Prepare the chart data
      const chartData = {
        labels: data.map((item) => item.LotNos), // Using `LotNos` as the x-axis labels
        datasets: data.map((item) => ({
          label: item.LotNos,
          data: [
            {
              min: item.Metrics.Min,
              q1: item.Metrics.Min + (item.Metrics.Avg - item.Metrics.Min) / 2, // Estimating Q1
              median: item.Metrics.Avg, // Using the Avg as the median
              q3: item.Metrics.Avg + (item.Metrics.Max - item.Metrics.Avg) / 2, // Estimating Q3
              max: item.Metrics.Max,
              upperError: item.Metrics.Avg + item.Metrics.StdDev, // Upper error bar
              lowerError: item.Metrics.Avg - item.Metrics.StdDev, // Lower error bar
            },
          ],
          // Upper part of the box is red
          backgroundColor: 'rgba(255, 99, 132, 0.3)', // Red for the upper half of the box plot (Median to Q3)
          // Lower part of the box is blue
          borderColor: 'black', // Blue for the lower half of the box plot (Q1 to Median)
          borderWidth: 1,
          borderSkipped: false, // To ensure borders appear around the box
          hoverBackgroundColor: 'rgba(255, 99, 132, 0.5)',
          hoverBorderColor: 'rgba(54, 162, 235, 1)',
        })),
      };

      // Chart options with tooltip customizations
      const options = {
        responsive: true,
        scales: {
          y: {
            beginAtZero: true,
          },
        },
        plugins: {
          title: {
            display: true,
            // text: 'Box Plot with Std.Dev Error Bars',
          },
          legend: {
            position: 'top',
          },
          tooltip: {
            enabled: true,
            mode: 'index',
            intersect: false,
            backgroundColor: 'rgba(0,0,0,0.7)', // Dark background for the tooltip
            titleFont: {
              size: 14,
              weight: 'bold',
              family: 'Arial',
            },
            bodyFont: {
              size: 12,
              family: 'Arial',
            },
            padding: 10,
            cornerRadius: 5,
            bodySpacing: 5,
            callbacks: {
              // Custom tooltip content
              label: (context) => {
                const datasetIndex = context.datasetIndex;
                const label = context.dataset.label || '';
                const dataPoint = context.raw;
                return `${label} | Min: ${dataPoint.min}, Q1: ${dataPoint.q1}, Median: ${dataPoint.median}, Q3: ${dataPoint.q3}, Max: ${dataPoint.max}`;
              },
            },
          },
        },
        layout: {
          padding: {
            bottom: 50, // Adjust bottom padding to help with the bottom label alignment
          },
        },
        elements: {
          line: {
            borderColor: 'black', // Black color for T-lines (whiskers)
            borderWidth: 1, // T-line thickness
          },
        },
      };

      // Create the chart with the prepared chartData
      chartInstance.current = new Chart(ctx, {
        type: 'boxplot', // Using the 'boxplot' type
        data: chartData,
        options: options,
      });
    }

    // Cleanup function when component is unmounted or data changes
    return () => {
      if (chartInstance.current) {
        chartInstance.current.destroy();
      }
    };
  }, [data]); // Re-run the effect when 'data' changes

  return (

      <canvas ref={chartRef}/>
    
  );
};

export default BoxPlotGraph;
