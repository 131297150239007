import * as Yup from "yup";

export const VALIDATION_YUPSCHEMA = {
  dropdownMonth: Yup.string().required("Please select month"),
  dropdownPlant: Yup.string().required("Please select plant"),
  dropdownModel: Yup.string().required("Please select machine model"),
  dropdownMachineParams: Yup.array().optional(),
  dropdownSerialNo: Yup.string().required("Please select line / serial no."),
  // dropdownBatch: Yup.string().nullable().when(['dateFromSelect', 'dateToSelect'], (dateFromSelect: any, dateToSelect: any) => {
  //   const diffInMs = dateToSelect.getTime() - dateFromSelect.getTime();
  //   const diffInHours = diffInMs / (1000 * 60 * 60);
  //   return diffInHours <= 48 //IT SHOULD BE 2 DAYS ONLY AS PER THE REQUIREMENT
  //     ? Yup.string().notRequired()
  //     : Yup.string().required('Please select batch');
  // })
};