import { useState, useEffect, useRef, useMemo } from 'react';
import { Container, Card, Grid } from '@mui/material';
import AcgButton from '../../../../atoms/Button/index';
import { Formik, Form, FormikProps } from 'formik';
import * as Yup from 'yup';
import Label from '../../../../atoms/Label/index';
import TextInput from '../../../../atoms/TextInput';
import { executeACGAction, resetErr } from '../../../../store/slice';
import { useDispatch } from 'react-redux';
import useGetState from '../../../../utils/hooks/useGetState';
import { _noop } from '../../../../utils/common';
import { USER_YUPSCHEMA } from '../schema';
import { ACTION_CODES, STORE_KEYS } from '../../../../constants/apiConstants';
import CustomRadioGroup from '../../../../atoms/CustomRadioGroup';
import DropDown from '../../../../atoms/Dropdown';
import { renameObjKeys } from '../../../Login/helper';

type Props = {
    drawerClose: Function;
    setCurrTab: Function;
    setReload: Function;
    setSnackbarOpts: Function;
    setStep: Function;
    step1Vals: any;
    setStep1Vals: Function;
    isEdit: boolean;
    dtlData: any;
};

type ClientType = {
    firstName: string | '';
    lastName: string | '';
    radioValue: string | '';
    dropdownClient?: string | '';
    dropdownRole?: string | '';
    email: string | '';
    dropdownCountryCode: string | '';
    mobile: string | '';
    userKey: string | '';
};

const CreateUser = (props: Props) => {
    const { drawerClose, setCurrTab, setStep1Vals, step1Vals, setStep, setSnackbarOpts, isEdit, dtlData } = props;
    const formRef = useRef<any>(null);
    const [clientEmail, setClientEmail] = useState<any>(null);
    const [clientMobile, setClientMobile] = useState<any>(null);
    const [emailValidation, setEmailValidation] = useState<boolean>(false);
    const [validationSchema, setValidationSchema] = useState({});
    const [clients, setClients] = useState<any>([]);
    const [countryCode, setcountryCode] = useState<any>([]);
    const [roles, setRoles] = useState<any>([]);
    const [customerKey, setCustomerKey] = useState<any>(dtlData?.CustomerKey || step1Vals?.dropdownClient || null);
    const [roleKey, setRoleKey] = useState<any>(dtlData?.CustRoleKey || step1Vals?.dropdownRole || null);
    const [userType, setUserType] = useState<any>(
        isEdit
            ? dtlData?.IsACGUser === 'Y'
                ? 'ACG'
                : step1Vals?.radioValue == 'ACG'
                    ? 'ACG'
                    : 'Customer'
            : step1Vals?.radioValue
                ? step1Vals?.radioValue == 'ACG'
                    ? 'ACG'
                    : 'Customer'
                : 'ACG'
    );

    const state = useGetState();
    const userDetails = state[STORE_KEYS.USER_DTL]
    const dispatch = useDispatch();
    useEffect(() => {
        if (userDetails?.IsACGUser === 'N') {
            setUserType('Customer')
        }
    }, [])
    const [initialValues] = useState(
        isEdit
            ? {
                firstName: step1Vals?.firstName || dtlData?.FirstName,
                lastName: step1Vals?.lastName || dtlData?.LastName,
                radioValue: step1Vals?.radioValue || dtlData?.radioValue,
                dropdownRole: step1Vals?.dropdownRole || dtlData?.CustRoleKey,
                dropdownClient: step1Vals?.dropdownClient || dtlData?.CustomerKey,
                email: step1Vals?.email || dtlData?.EmailId,
                mobile: step1Vals?.mobile || dtlData?.PhoneNo?.slice(-10),
                dropdownCountryCode: step1Vals?.dropdownCountryCode || dtlData?.PhoneCountryKey,
            }
            : {
                firstName: step1Vals?.firstName || '',
                lastName: step1Vals?.lastName || '',
                radioValue: step1Vals?.radioValue || 'ACG',
                dropdownRole: step1Vals?.dropdownRole || '',
                dropdownClient: step1Vals?.dropdownClient || '',
                email: step1Vals?.email || '',
                mobile: step1Vals?.mobile || '',
                dropdownCountryCode: step1Vals?.dropdownCountryCode || '',
            }
    );

    useEffect(() => {
        setValidationSchema(Yup.object().shape(USER_YUPSCHEMA));
        if (step1Vals?.radioValue !== 'ACG' || userType !== 'ACG') {
            dispatch(
                executeACGAction({
                    payload: {
                        requestType: 'GET',
                        urlPath: ACTION_CODES.GET_CLIENT_DROPDOWN
                    },
                    storeKey: STORE_KEYS.CLIENT_DROPDOWN,
                    uniqueScreenIdentifier: {
                        isLoading: true
                    }
                })
            );
        }
        dispatch(
            executeACGAction({
                payload: {
                    requestType: 'GET',
                    urlPath: ACTION_CODES.GET_COUNTRY_CODE
                },
                storeKey: STORE_KEYS.COUNTRY_CODE_DROPDOWN,
                uniqueScreenIdentifier: {
                    isLoading: true
                }
            })
        );
    }, []);

    useEffect(() => {
        if (customerKey || userType === 'ACG') {
            dispatch(
                executeACGAction({
                    payload: {
                        requestType: 'GET',
                        urlPath: `${ACTION_CODES.GET_ROLES}${userType === 'ACG' ? -1 : customerKey}`
                    },
                    storeKey: STORE_KEYS.ROLE_DROPDOWN,
                    uniqueScreenIdentifier: {
                        isLoading: true
                    }
                })
            );
        }
    }, [customerKey]);

    useEffect(() => {
        if (state?.[STORE_KEYS.CLIENT_DROPDOWN]?.body?.data) {
            const clientsDt = state?.[STORE_KEYS.CLIENT_DROPDOWN]?.body?.data;
            setClients(
                clientsDt?.map((item: any) => {
                    const newVal = renameObjKeys(item, { CustomerKey: 'code', CustomerName: 'name' });
                    return newVal;
                })
            );
        }
    }, [state?.[STORE_KEYS.CLIENT_DROPDOWN]?.body?.data]);

    useEffect(() => {
        if (state?.[STORE_KEYS.ROLE_DROPDOWN]?.body?.data) {
            const rolesDt = state?.[STORE_KEYS.ROLE_DROPDOWN]?.body?.data;
            setRoles(
                rolesDt?.map((item: any) => {
                    const newVal = renameObjKeys(item, { CustRoleKey: 'code', RoleName: 'name' });
                    return newVal;
                })
            );
        }
    }, [state?.[STORE_KEYS.ROLE_DROPDOWN]?.body?.data]);

    const getName = (key: any, type: any) => {
        if (type === 'role') {
            if (state?.[STORE_KEYS.ROLE_DROPDOWN]?.body?.data && roleKey) {
                const rolesDt = state?.[STORE_KEYS.ROLE_DROPDOWN]?.body?.data;
                const roleObj = rolesDt?.filter((item: any) => item.CustRoleKey === key);
                if (roleObj?.[0]?.RoleName) return roleObj[0].RoleName;
            }
        } else {
            const clientsDt = state?.[STORE_KEYS.CLIENT_DROPDOWN]?.body?.data;
            const clientObj = clientsDt?.filter((item: any) => item.CustomerKey === key);
            if (clientObj?.[0]?.CustomerName) return clientObj[0].CustomerName;
        }
    };

    useEffect(() => {
        if (state?.[STORE_KEYS.COUNTRY_CODE_DROPDOWN]?.body?.data) {
            const clientsDt = state?.[STORE_KEYS.COUNTRY_CODE_DROPDOWN]?.body?.data;
            setcountryCode(
                clientsDt?.map((item: any) => {
                    const newVal = renameObjKeys(item, { PhoneCountryKey: 'code', CountryPhoneCode: 'name' });
                    return newVal;
                })
            );
        }
    }, [state?.[STORE_KEYS.COUNTRY_CODE_DROPDOWN]?.body?.data]);

    const handleSubmit = async (values: any) => {
        if(!emailValidation || isEdit) {
        const vals = {
            firstName: values?.firstName.replace(/'/g, "''"),
            lastName: values?.lastName.replace(/'/g, "''"),
            radioValue:
                values?.radioValue || isEdit
                    ? dtlData?.IsACGUser === 'Y'
                        ? values?.radioValue || 'ACG'
                        : values?.radioValue || 'Customer'
                    : 'ACG',
            dropdownRole: values?.dropdownRole,
            dropdownClient: values?.dropdownClient,
            email: values?.email,
            mobile: values?.mobile,
            dropdownCountryCode: values?.dropdownCountryCode,
            clientName: getName(values?.dropdownClient, 'client'),
            roleName: getName(values?.dropdownRole, 'role'),
            userKey: isEdit? dtlData.UserKey : ''
        };
        setStep1Vals(vals);
        setStep(1);
    }
    };

    const renderButtons = () => {
        return (
            <div className={'btns noWrap'}>
                <>
                    <AcgButton
                        name={'Cancel'}
                        handleClick={drawerClose}
                        secondary={true}
                        className={'first-btn'}
                        type="button"
                        variant="contained"
                    />

                    <AcgButton
                        name="Proceed"
                        handleClick={() => { }}
                        type="submit"
                        className="second-btn"
                        variant="contained"
                    />
                </>
            </div>
        );
    };

    const handleSnackbarError = (err: any) => {
        const errorMsg = err?.message || 'Internal Server error';
        const snackbarError = {
            message: errorMsg,
            type: 'reject',
            open: true
        };

        setSnackbarOpts(snackbarError);
    };

    useEffect(() => {
        if (state?.err) {
            handleSnackbarError(state?.err);
            dispatch(resetErr());
        }
    }, [state?.err]);

    useEffect(() => {
        // setValidationSchema(Yup.object().shape(USER_YUPSCHEMA));
        const clientsDt = state?.[STORE_KEYS.CLIENT_DROPDOWN]?.body?.data;
        const client = clientsDt?.find((item: any) => {
            return item.CustomerKey === customerKey;
        });
        console.log(client)
        const domain = client?.DomainName !== undefined ? "\\"+client?.DomainName : "\\acg-world.com";
        const domain2 = "scitechcentre.com";
        const domain3 = client?.DomainName;
        new RegExp(`^[a-zA-Z0-9._-]+@${domain}`).test(clientEmail) || new RegExp(`^[a-zA-Z0-9._-]+@${domain2}`).test(clientEmail) || new RegExp(`^[a-zA-Z0-9._-]+@${domain3}`).test(clientEmail)? setEmailValidation(false) : setEmailValidation(true);
    }
    ,[customerKey, clientEmail, clientMobile ])

    return (
        <Formik
            initialValues={initialValues}
            validationSchema={validationSchema}
            onSubmit={(values: any) => {
                handleSubmit(values);
            }}
            validateOnBlur={false}
            validateOnChange={false}
            innerRef={formRef}
        >
            {(formikprops: FormikProps<ClientType>) => {
                const { values, handleChange, handleBlur, isValid, errors, touched, setFieldValue, validateField } =
                    formikprops;
                values && setClientEmail(values.email);
                return (
                    <Form>
                        <>
                            <Grid container spacing={2}>
                                <Grid item xs={12} sm={12} md={6} xl={6} lg={6}>
                                    <Label label="First Name" className={'labelDiv'} />
                                    <TextInput
                                        variant="outlined"
                                        fullWidth
                                        type="text"
                                        name="firstName"
                                        value={values.firstName}
                                        autoComplete="off"
                                        formInput="inputDiv"
                                        onChange={(e: any) => {
                                            handleChange(e);
                                        }}
                                        onBlur={handleBlur}
                                        placeholder="Enter First Name"
                                        helperText={errors.firstName && touched.firstName ? errors.firstName : ''}
                                    />
                                </Grid>
                                <Grid item xs={12} sm={12} md={6} xl={6} lg={6}>
                                    <Label label="Last Name" className={'labelDiv'} />
                                    <TextInput
                                        variant="outlined"
                                        fullWidth
                                        type="text"
                                        name="lastName"
                                        value={values.lastName}
                                        autoComplete="off"
                                        formInput="inputDiv"
                                        onChange={(e: any) => {
                                            handleChange(e);
                                        }}
                                        onBlur={handleBlur}
                                        placeholder="Enter Last Name"
                                        helperText={errors.lastName && touched.lastName ? errors.lastName : ''}
                                    />
                                </Grid>
                            </Grid>
                            {userDetails?.IsACGUser !== 'N' ?
                                <Grid container spacing={2} className="radio-buttons">
                                    <Grid item>
                                        <Label label="Type of User" />
                                        <CustomRadioGroup
                                            value={values?.radioValue || userType}
                                            fields={[
                                                { label: 'ACG', value: 'ACG' },
                                                { label: 'Client', value: 'Customer' }
                                            ]}
                                            onChange={(e: any) => {
                                                handleChange(e);
                                                setFieldValue('radioValue', e.currentTarget.value);
                                                setRoleKey(null);
                                                setCustomerKey(null);
                                                setFieldValue('dropdownClient', '');
                                                setFieldValue('dropdownRole', '');
                                                setUserType(e.currentTarget.value);
                                                setFieldValue('email','');
                                            }}
                                            getEvent={true}
                                        />
                                    </Grid>
                                </Grid>
                                :
                                null
                            }
                            {userType !== 'ACG' && (
                                <>
                                    <Label label="Client" className={'labelDiv'} />
                                    <DropDown
                                        onChange={(event) => {
                                            handleChange(event);
                                            setRoleKey(null);
                                            setCustomerKey(event.target.value);
                                            setFieldValue('email','');
                                        }}
                                        className="inputDiv"
                                        name="dropdownClient"
                                        items={clients}
                                        placeHolder="Select Client"
                                        value={values.dropdownClient}
                                        helperText={
                                            errors.dropdownClient && touched.dropdownClient ? errors.dropdownClient : ''
                                        }
                                    />
                                </>
                            )}
                            {(customerKey || userType === 'ACG') && (
                                <>
                                    <Label label="Role" className={'labelDiv'} />
                                    <DropDown
                                        onChange={(event: any) => {
                                            handleChange(event);
                                            setRoleKey(event.target.value);
                                        }}
                                        className="inputDiv"
                                        name="dropdownRole"
                                        items={roles}
                                        placeHolder="Select Role"
                                        value={values.dropdownRole}
                                        helperText={
                                            errors.dropdownRole && touched.dropdownRole ? errors.dropdownRole : ''
                                        }
                                    />
                                </>
                            )}
                            <Label label="Mobile Number" className={'labelDiv'} />
                            <Grid container className="email-section">
                                <Grid item style={{ width: '50%' }}>
                                    <DropDown
                                        onChange={(event: any) => {
                                            handleChange(event);
                                        }}
                                        className="inputDiv"
                                        name="dropdownCountryCode"
                                        items={countryCode}
                                        placeHolder="Select Country Code"
                                        value={values.dropdownCountryCode}
                                        helperText={
                                            errors.dropdownCountryCode && touched.dropdownCountryCode
                                                ? errors.dropdownCountryCode
                                                : ''
                                        }
                                    />
                                </Grid>
                                <Grid item style={{ marginLeft: '10px', width: '100%', marginTop: '-5px' }}>
                                    <TextInput
                                        variant="outlined"
                                        fullWidth
                                        type="text"
                                        name="mobile"
                                        value={values.mobile}
                                        autoComplete="off"
                                        formInput="inputDiv"
                                        onChange={(e: any) => {
                                            setClientMobile(e.target.value)
                                            handleChange(e);
                                        }}
                                        maxLength={10}
                                        onBlur={handleBlur}
                                        placeholder="Enter Mobile Number"
                                        helperText={errors.mobile && touched.mobile ? errors.mobile : ''}
                                    />
                                </Grid>
                            </Grid>
                            <Label label="Email" className={'labelDiv'} />
                            {console.log(touched.email, emailValidation)}
                            <TextInput
                                variant="outlined"
                                fullWidth
                                type="text"
                                name="email"
                                value={values.email}
                                autoComplete="off"
                                formInput="inputDiv"
                                onChange={(e: any) => {
                                    setClientEmail(e.target.value)
                                    handleChange(e);
                                }}
                                onBlur={handleBlur}
                                placeholder="Enter Email Address"
                                disabled={isEdit}
                                helperText={touched.email && values.email === "" ? "Please enter Email ID" : touched.email && emailValidation ? "Invalid Domain" : ""}
                            />
                        </>
                        {renderButtons()}
                    </Form>
                );
            }}
        </Formik>
    );
};

export default CreateUser;
