import { useEffect, useRef, useState } from 'react';
import ACGButton from '../../../../atoms/Button';
import { Grid } from '@mui/material';
import Label from '../../../../atoms/Label/index';
import TextInput from '../../../../atoms/TextInput';
import { Formik, Form, FormikProps } from 'formik';
import * as Yup from 'yup';
import { PLANT_YUPSCHEMA } from '../schema';
import DropDown from '../../../../atoms/Dropdown';
import { useDispatch } from 'react-redux';
import { executeACGAction, updateScreenIdentifiers } from '../../../../store/slice';
import { ACTION_CODES, STORE_KEYS } from '../../../../constants/apiConstants';
import useGetState from '../../../../utils/hooks/useGetState';
import { renameObjKeys } from '../../../Login/helper';
import AcgTimePicker from '../../../../atoms/TimePicker';
import dateFormat from 'dateformat';

type Props = {
    drawerClose: Function;
    setStep: Function;
    dtlData?: any;
    setDtlData?: Function;
};

type PlantType = {
    dropdownClient: string | '';
    dropdownCountry: string | '';
    dropdownState: string | '';
    plantCode: string | '';
    plantName: string | '';
    plantAddress: string | '';
    dropdownTimeZone: string | '';
    time: Date;
};
const PlantInfo = (props: Props) => {
    const { drawerClose, setStep, dtlData, setDtlData = () => { } } = props;
    const [clients, setClients] = useState<any>([]);
    const [tzDt, setTzDt] = useState<any>([]);
    const formRef = useRef<any>(null);
    const [ctrys, setCtrys] = useState<any>([]);
    const [states, setStates] = useState<any>([]);
    const [tz, setTz] = useState([]);
    const [validationSchema, setValidationSchema] = useState({});
    const [initialValues] = useState({
        dropdownClient: dtlData?.CustomerKey || '',
        dropdownCountry: dtlData?.CountryKey || '',
        dropdownState: dtlData?.LocationKey || '',
        plantCode: dtlData?.PlantCode || '',
        plantName: dtlData?.PlantName || '',
        plantAddress: dtlData?.PlantAddress || '',
        dropdownTimeZone: dtlData?.TimeZoneKey || '',
        time: new Date(`20 Feb 2023 ${dtlData?.ShiftStartTime}`) || new Date()
    });
    const dispatch = useDispatch();
    const state = useGetState();

    useEffect(() => {
        setValidationSchema(Yup.object().shape(PLANT_YUPSCHEMA));
        const clientsDt = state?.[STORE_KEYS.CLIENT_LIST]?.body?.data;
        setClients(
            clientsDt?.map((item: any) => {
                const newVal = renameObjKeys(item, { CustomerKey: 'code', CustomerName: 'name' });
                return newVal;
            })
        );
        const tzDts = state?.[STORE_KEYS.TIMEZONES]?.body?.data;
        setTzDt(
            tzDts?.map((item: any) => {
                const newVal = renameObjKeys(item, { TimeZoneKey: 'code', TimeZoneName: 'name' });
                return newVal;
            })
        );
        dispatch(
            executeACGAction({
                payload: {
                    requestType: 'GET',
                    urlPath: ACTION_CODES.GET_COUNTRIES
                },
                uniqueScreenIdentifier: { ctrysLoaded: true },
                storeKey: STORE_KEYS.COUNTRIES
            })
        );
    }, []);

    useEffect(() => {
        if (state?.[STORE_KEYS.COUNTRIES]?.ctrysLoaded) {
            const data = state?.[STORE_KEYS.COUNTRIES]?.body?.data;
            const ctrysArr = data?.map((item: any) => {
                const newVal = renameObjKeys(item, { CountryKey: 'code', CountryName: 'name' });
                return newVal;
            });
            setCtrys(ctrysArr);
            dispatch(
                executeACGAction({
                    payload: {
                        requestType: 'GET',
                        urlPath: ACTION_CODES.GET_TIMEZONES
                    },
                    uniqueScreenIdentifier: { tzLoaded: true },
                    storeKey: STORE_KEYS.TIMEZONES
                })
            );
            dispatch(
                updateScreenIdentifiers({
                    storeKey: STORE_KEYS.COUNTRIES,
                    uniqueScreenIdentifier: {
                        ctrysLoaded: false
                    }
                })
            );
        }
    }, [state?.[STORE_KEYS.COUNTRIES]?.ctrysLoaded]);
    useEffect(() => {
        if (state?.[STORE_KEYS.TIMEZONES]?.tzLoaded) {
            const tzDat = state?.[STORE_KEYS.TIMEZONES]?.body?.data;
            const tzArr = tzDat?.map((item: any) => {
                const newVal = renameObjKeys(item, { TimeZoneKey: 'code', TimeZoneName: 'name' });
                return newVal;
            });
            setTz(tzArr);
            if (dtlData?.CountryKey != '') {
                getStates(dtlData?.CountryKey);
            }
            dispatch(
                updateScreenIdentifiers({
                    storeKey: STORE_KEYS.TIMEZONES,
                    uniqueScreenIdentifier: {
                        tzLoaded: false
                    }
                })
            );
        }
    }, [state?.[STORE_KEYS.TIMEZONES]?.tzLoaded]);

    const getStates = (country: any) => {
        dispatch(
            executeACGAction({
                payload: {
                    requestType: 'GET',
                    urlPath: `${ACTION_CODES.GET_LOCATIONS}/${country}`
                },
                uniqueScreenIdentifier: { statesRecd: true },
                storeKey: STORE_KEYS.STATES
            })
        );
    };

    useEffect(() => {
        if (state?.[STORE_KEYS.STATES]?.statesRecd) {
            const stDt = state?.[STORE_KEYS.STATES]?.body?.data;
            const stArr = stDt?.map((item: any) => {
                const newVal = renameObjKeys(item, { LocationKey: 'code', LocationName: 'name' });
                return newVal;
            });
            setStates(stArr);
            dispatch(
                updateScreenIdentifiers({
                    storeKey: STORE_KEYS.STATES,
                    uniqueScreenIdentifier: {
                        statesRecd: false
                    }
                })
            );
        }
    }, [state?.[STORE_KEYS.STATES]?.statesRecd]);

    const handleSubmit = async (values: any) => {
        setDtlData({
            lines: dtlData?.lines,
            CustomerKey: values?.dropdownClient,
            CountryKey: values?.dropdownCountry,
            PlantCode: values?.plantCode,
            PlantName: values?.plantName,
            PlantAddress: values?.plantAddress,
            TimeZoneKey: values?.dropdownTimeZone,
            LocationKey: values?.dropdownState,
            TimeZoneName: tzDt?.find((tzVal: any) => tzVal?.code == values?.dropdownTimeZone)?.name,
            ShiftStartTime: dateFormat(values?.time, 'HH:MM:ss').toString(),
            clientVal: clients?.find((cli: any) => cli?.code == values?.dropdownClient)?.name,
            stateVal: states?.find((sta: any) => sta?.code == values?.dropdownState)?.name,
            CustomerName: clients?.find((cli: any) => cli?.code == values?.dropdownClient)?.name,
            ctryVal: ctrys?.find((cry: any) => cry?.code == values?.dropdownCountry)?.name
        });
        setStep(1);
    };
    const renderButtons = () => {
        return (
            <div className={'btns noWrap'}>
                <ACGButton
                    name={'Cancel'}
                    handleClick={drawerClose}
                    secondary={true}
                    className={'first-btn'}
                    type="button"
                    variant="contained"
                />
                <ACGButton
                    name={'Next'}
                    handleClick={() => { }}
                    type="submit"
                    className="second-btn"
                    variant="contained"
                />
            </div>
        );
    };

    return (
        <Formik
            initialValues={initialValues}
            validationSchema={validationSchema}
            onSubmit={(values: any) => {
                handleSubmit(values);
            }}
            validateOnBlur={false}
            validateOnChange={false}
            innerRef={formRef}
        >
            {(formikprops: FormikProps<PlantType>) => {
                const { values, handleChange, handleBlur, isValid, errors, touched, setFieldValue } = formikprops;
                return (
                    <Form>
                        <Label label="Client" className={'labelDiv'} />
                        <DropDown
                            onChange={(event) => {
                                handleChange(event);
                            }}
                            className="inputDiv"
                            name="dropdownClient"
                            items={clients}
                            disabled={true}
                            placeHolder="Select Client"
                            value={values.dropdownClient}
                            helperText={errors.dropdownClient && touched.dropdownClient ? errors.dropdownClient : ''}
                        />
                        <Label label="Country" className={'labelDiv'} />
                        <DropDown
                            onChange={(event: any) => {
                                handleChange(event);
                                getStates(event.target.value);
                                setFieldValue('dropdownState', '');
                            }}
                            className="inputDiv"
                            name="dropdownCountry"
                            items={ctrys}
                            placeHolder="Select Country"
                            value={values.dropdownCountry}
                            helperText={errors.dropdownCountry && touched.dropdownCountry ? errors.dropdownCountry : ''}
                        />
                        {/* <Grid container spacing={2}>
                            <Grid item xs={12} sm={12} md={8} xl={8} lg={8}> */}
                        <Label label="State" className={'labelDiv'} />
                        <DropDown
                            onChange={(event: any) => {
                                handleChange(event);
                            }}
                            className="inputDiv"
                            name="dropdownState"
                            items={states}
                            placeHolder="Select State"
                            value={values.dropdownState}
                            helperText={
                                errors.dropdownState && touched.dropdownState ? errors.dropdownState : ''
                            }
                        />
                        {/* </Grid>
                        </Grid> */}
                        <Label label="Plant Code" className={'labelDiv'} />
                        <TextInput
                            variant="outlined"
                            fullWidth
                            type="text"
                            name="plantCode"
                            value={values.plantCode}
                            disabled={true}
                            autoComplete="off"
                            formInput="inputDiv"
                            onChange={(e: any) => {
                                handleChange(e);
                            }}
                            onBlur={handleBlur}
                            placeholder="Enter Plant Code"
                            helperText={errors.plantCode && touched.plantCode ? errors.plantCode : ''}
                        />
                        <Label label="Plant Name" className={'labelDiv'} />
                        <TextInput
                            variant="outlined"
                            fullWidth
                            type="text"
                            name="plantName"
                            value={values.plantName}
                            autoComplete="off"
                            formInput="inputDiv"
                            onChange={(e: any) => {
                                handleChange(e);
                            }}
                            onBlur={handleBlur}
                            placeholder="Enter Plant Name"
                            helperText={errors.plantName && touched.plantName ? errors.plantName : ''}
                        />
                        <Label label="Plant Address" className={'labelDiv'} />
                        <TextInput
                            variant="outlined"
                            fullWidth
                            multiline
                            type="text"
                            name="plantAddress"
                            value={values.plantAddress}
                            autoComplete="off"
                            formInput="longInputDiv"
                            height="80px"
                            onChange={(e: any) => {
                                handleChange(e);
                            }}
                            onBlur={handleBlur}
                            placeholder="Enter Plant Address"
                            helperText={errors.plantAddress && touched.plantAddress ? errors.plantAddress : ''}
                        />

                        <Label label="Time Zone" className={'labelDiv'} />
                        <DropDown
                            onChange={(event) => {
                                handleChange(event);
                            }}
                            className="inputDiv"
                            name="dropdownTimeZone"
                            items={tz}
                            placeHolder="Select Time Zone"
                            value={values.dropdownTimeZone}
                            helperText={
                                errors.dropdownTimeZone && touched.dropdownTimeZone ? errors.dropdownTimeZone : ''
                            }
                        />
                        <Grid container spacing={2}>
                            <Grid item xs={12} sm={12} md={12} xl={12} lg={12}>
                                <Label label="Shift Start Time" className={'labelDiv'} />
                                <AcgTimePicker
                                    className="inputDiv"
                                    value={values.time}
                                    handleChange={(value: any) => {
                                        setFieldValue('time', new Date(value.$d));
                                    }}
                                />
                            </Grid>
                        </Grid>
                        {renderButtons()}
                    </Form>
                );
            }}
        </Formik>
    );
};

export default PlantInfo;
