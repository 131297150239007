import axios from 'axios'

export default {
    getData: (request) =>
        axios({
            method: 'GET',
            url: 'https://in1-ccaas-api.ozonetel.com/CAServices/PhoneManualDial.php',
            headers: {
                'Content-Type': 'application/json',
                'Accept': 'application/json',
                // 'Access-Control-Allow-Origin': '*',
                // 'Access-Control-Allow-Methods': 'POST, GET',
                // 'Access-Control-Allow-Headers': 'Content-Type, Access-Control-Allow-Headers, Authorization, X-Requested-With',
            },
            params: {
                'apiKey': "KKc21720d2c4e75f20e5664d15354e29ba",//'KK93ac24eec654da2971d32e44fb49588c',
                'userName': 'acg_pam',// request.userName,
                'custNumber': request.custNumber,//"7042617745",//request.custNumber,//'8591009650',//'8600849833',//'7977684006',
                'phoneName': request.phoneName, //  request.phoneName,//'Aniket','Ayushi_Gupta',
                'did': "912269315340",//'912268493653',
            }
        }),
    // .then(response => {
    //   console.log(">>>> 1",response);
    // }).catch(error => {
    //   console.log(">>>> 2",error);
    // })

    getCallBackData: () =>
        axios({
            method: 'POST',
            //url: 'http://172.16.123.132:3006/callDetails',
            // url: 'https://appservicesfuturedna-staging.azurewebsites.net/callDetails',
            url: 'https://appservice-datamatics-prod.azurewebsites.net/api/callDetails',
            headers: {
                'Content-Type': 'application/x-www-form-urlencoded',
                'Accept': 'application/json',
            }
        })

}
