import { TableCell, TableBody, TableRow } from '@mui/material';
import React from 'react';
import UserMoreMenu from '../../CustomTableMenu';
import MenuDropDown from '../../MenuDropDown/MenuDropDown';
import moment from 'moment';

type ComponentsProps = {
    rows: any[];
    DropdownOptions?: any[];
    HeaderList: any[];
    isAction: boolean;
    iconColName?: string;
    iconComponent?: any;
    iconClick?: any;
};

function TableBodyContainer(props: ComponentsProps) {
    const { rows, DropdownOptions, HeaderList, isAction, iconColName, iconComponent ,iconClick} = props;
    const ColumnName = HeaderList.filter((item) => item.key != "Action")
    console.log("🚀 ~ TableBodyContainer ~ ColumnName:", ColumnName)

    return (
        <TableBody>
            {rows.map((row, index) => (
                <TableRow className='tableRow' key={row.name}>
                    {ColumnName.map((header) => {
                        if (header.key == iconColName){
                            return (
                                <TableCell
                                key={header.key}
                                align='left'
                                style={{ borderBottom: '1px solid #898ba726', color: '#C0C2D8' }}
                                onClick={()=>iconClick(row?.[header.key])}
                            >
                               {iconComponent}
                            </TableCell>
                            )
                        }
                            return (
                                <TableCell
                                    key={header.key}
                                    align='left'
                                    style={{ borderBottom: '1px solid #898ba726', color: '#C0C2D8' }}
                                >
                                    {header.key == "SOPUploadDateTime"?moment.utc(row?.[header.key],"DD/MM/YYYY | hh:mm:ss A").format("DD/MM/YYYY | hh:mm:ss A"): header.key.includes("Time")? moment.utc(row?.[header.key]).format("DD/MM/YYYY hh:mm:ss A"): row?.[header.key]}
                                </TableCell>
                            )
                    })}
                    {isAction ? <TableCell align='left' style={{ borderBottom: '1px solid #898ba726', color: '#C0C2D8' }}>
                        <MenuDropDown DropdownOptions={DropdownOptions} values={row} />
                    </TableCell> : null}
                </TableRow>
            ))}
        </TableBody>
    );
}

export default TableBodyContainer;