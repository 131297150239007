import { useState, useEffect } from 'react';
import { styled } from '@mui/material/styles';
import { ACTION_CODES, STORE_KEYS, BASEURL } from '../../../../constants/apiConstants';
import DownArrow from '../../../../../assets/down-arrow.svg';
import useGetState from '../../../../utils/hooks/useGetState';
import ACGButton from '../../../../atoms/Button';
import Paper from '@mui/material/Paper';
import Label from '../../../../atoms/Label/index';
import Table from '@mui/material/Table';
import Container from '@mui/material/Container';
import Box from '@mui/material/Box';
import TableBody from '@mui/material/TableBody';
import TableCell, { tableCellClasses } from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import TextInput from '../../../../atoms/TextInput';
import InputLabel from '@mui/material/InputLabel';
import MenuItem from '@mui/material/MenuItem';
import Radio from '@mui/material/Radio';
import RadioGroup from '@mui/material/RadioGroup';
import FormControlLabel from '@mui/material/FormControlLabel';
import FormControl from '@mui/material/FormControl';
import Select from '@mui/material/Select';
import TextField from '@mui/material/TextField';
import Tooltip from '@mui/material/Tooltip';
import HelpIcon from '@mui/icons-material/Help';
import { Typography } from '@mui/material';
import Checkbox from '@mui/material/Checkbox';
import ListItemText from '@mui/material/ListItemText';
import OutlinedInput from '@mui/material/OutlinedInput';
import Grid from '@mui/material/Grid';
import Stack from '@mui/material/Stack';
import Button from '@mui/material/Button';
import TrailAnalysisService from '../../trialAnalysisServices';
// import "../../cm.css"
const ITEM_HEIGHT = 48;
const ITEM_PADDING_TOP = 8;
const MenuProps = {
    PaperProps: {
        style: {
            maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
            width: 250,
        },
    },
}
const StyledTableCell = styled(TableCell)(({ theme }) => ({
    [`&.${tableCellClasses.head}`]: {

        color: theme.palette.common.white,
    },
    [`&.${tableCellClasses.body}`]: {
        fontSize: 14,
        color: theme.palette.common.white,
    },
}));

const StyledTableRow = styled(TableRow)(({ theme }) => ({
    '&:nth-of-type(odd)': {
        backgroundColor: theme.palette.action.hover,
    },
    // hide last border
    '&:last-child td, &:last-child th': {
        border: 0,
    },
}));
export default function CreateForm(props: any) {
    const [milled, setMilled] = useState("Y");
    const [unMilled, setUnmilled] = useState("Y");
    const [lubricated, setLubricated] = useState("Y");
    const [milledData, setMilledData] = useState<any>([]);
    const [unMilledData, setUnmilledData] = useState<any>([]);
    const [lubricatedData, setLubricatedData] = useState<any>([]);
    const [canBeEdited, setCanbeEdited] = useState(false);
    const state = useGetState();
    const token = state?.[STORE_KEYS.USER_DTL].token;
    const loggedInUser = state[STORE_KEYS.USER_DTL]?.LoggedUserKey;

    useEffect(() => {
        const data = { "trialKey": props.newKey, token: token }
        let m: any, u: any, l: any;
        TrailAnalysisService.getTrailAnalysisCQA(data)
            .then((response: any) => {
                const values = [];
                const result = response?.data.message[0];
                const data = result[0];
                if (data[0].TrialCQAPSDMilledGranulesParamKeys === '-1') {
                    setMilled("N")
                    m = 'N';
                } else {
                    setMilled("Y");
                    const milledKeys = data[0].TrialCQAPSDMilledGranulesParamKeys.split(",")
                    const reverseMilled: any = [];
                    props.psdData[0].map((i: any) => {
                        if (milledKeys.includes(i.TrialCQAPSDParamKey.toString())) {
                            reverseMilled.push(i)
                        }
                    })
                    setMilledData(reverseMilled);
                }
                if (data[0].TrialCQAPSDUnmilledGranulesParamKeys === '-1') {
                    setUnmilled("N")
                    u = "N";
                } else {
                    setUnmilled("Y");
                    const unMilledKeys = data[0].TrialCQAPSDUnmilledGranulesParamKeys.split(",")
                    const reverseUnmilled: any = [];
                    props.psdData[1].map((i: any) => {
                        if (unMilledKeys.includes(i.TrialCQAPSDParamKey.toString())) {
                            reverseUnmilled.push(i)
                        }
                    })
                    setUnmilledData(reverseUnmilled);
                }
                if (data[0].TrialCQAPSDLubricatedBlendParamKeys === '-1') {
                    setLubricated("N")
                    l = "N";
                } else {
                    setLubricated("Y")
                    const lKeys = data[0].TrialCQAPSDLubricatedBlendParamKeys.split(",")
                    const reverseLKeys: any = [];
                    props.psdData[2].map((i: any) => {
                        if (lKeys.includes(i.TrialCQAPSDParamKey.toString())) {
                            reverseLKeys.push(i)
                        }
                    })
                    setLubricatedData(reverseLKeys);
                }
                if (m === "N" && u === "N" && l === "N") {
                    setCanbeEdited(true);
                }
            })
            .catch((err) => {
                console.log(err);
            });
    }, [props.trailKey])

    const handleChangeMilled = (event: any, type: any) => {
        setMilled(event.target.value);
    }
    const handleChangeUnMilled = (event: any) => {
        setUnmilled(event.target.value);
    }
    const handleChangelubricated = (event: any) => {
        setLubricated(event.target.value);
    }

    const handleChangeMilledNames = (id: any) => {
        let newSelected: any = [];
        if (props.presetKey === '') {
            const selectedIndex = milledData.indexOf(id);
            if (selectedIndex === -1) {
                newSelected = newSelected.concat(milledData, id);
            } else if (selectedIndex === 0) {
                newSelected = newSelected.concat(milledData.slice(1));
            } else if (selectedIndex === milledData.length - 1) {
                newSelected = newSelected.concat(milledData.slice(0, -1));
            } else if (selectedIndex > 0) {
                newSelected = newSelected.concat(
                    milledData.slice(0, selectedIndex),
                    milledData.slice(selectedIndex + 1)
                );
            }
        }
        else {
            const selectedIndex = milledData.findIndex((t: any) => t.TrialCQAPSDParamKey === id.TrialCQAPSDParamKey);
            if (selectedIndex === -1) {
                newSelected = newSelected.concat(milledData, id);
            } else if (selectedIndex === 0) {
                newSelected = newSelected.concat(milledData.slice(1));
            } else if (selectedIndex === milledData.length - 1) {
                newSelected = newSelected.concat(milledData.slice(0, -1));
            } else if (selectedIndex > 0) {
                newSelected = newSelected.concat(
                    milledData.slice(0, selectedIndex),
                    milledData.slice(selectedIndex + 1)
                );
            }
        }
        setMilledData(newSelected);
    }
    const handleChangeUnMilledNames = (id: any) => {
        let newSelected: any = [];
        if (props.presetKey === '') {
            const selectedIndex = unMilledData.indexOf(id);
            if (selectedIndex === -1) {
                newSelected = newSelected.concat(unMilledData, id);
            } else if (selectedIndex === 0) {
                newSelected = newSelected.concat(unMilledData.slice(1));
            } else if (selectedIndex === unMilledData.length - 1) {
                newSelected = newSelected.concat(unMilledData.slice(0, -1));
            } else if (selectedIndex > 0) {
                newSelected = newSelected.concat(
                    unMilledData.slice(0, selectedIndex),
                    unMilledData.slice(selectedIndex + 1)
                );
            }
        }
        else {
            const selectedIndex = unMilledData.findIndex((t: any) => t.TrialCQAPSDParamKey === id.TrialCQAPSDParamKey);
            if (selectedIndex === -1) {
                newSelected = newSelected.concat(unMilledData, id);
            } else if (selectedIndex === 0) {
                newSelected = newSelected.concat(unMilledData.slice(1));
            } else if (selectedIndex === unMilledData.length - 1) {
                newSelected = newSelected.concat(unMilledData.slice(0, -1));
            } else if (selectedIndex > 0) {
                newSelected = newSelected.concat(
                    unMilledData.slice(0, selectedIndex),
                    unMilledData.slice(selectedIndex + 1)
                );
            }
        }
        setUnmilledData(newSelected);
    }
    const handleChangeLubricatedNames = (id: any) => {
        let newSelected: any = [];
        if (props.presetKey === '') {
            const selectedIndex = lubricatedData.indexOf(id);
            if (selectedIndex === -1) {
                newSelected = newSelected.concat(lubricatedData, id);
            } else if (selectedIndex === 0) {
                newSelected = newSelected.concat(lubricatedData.slice(1));
            } else if (selectedIndex === lubricatedData.length - 1) {
                newSelected = newSelected.concat(lubricatedData.slice(0, -1));
            } else if (selectedIndex > 0) {
                newSelected = newSelected.concat(
                    lubricatedData.slice(0, selectedIndex),
                    lubricatedData.slice(selectedIndex + 1)
                );
            }
        }
        else {
            const selectedIndex = lubricatedData.findIndex((t: any) => t.TrialCQAPSDParamKey === id.TrialCQAPSDParamKey);
            if (selectedIndex === -1) {
                newSelected = newSelected.concat(lubricatedData, id);
            } else if (selectedIndex === 0) {
                newSelected = newSelected.concat(lubricatedData.slice(1));
            } else if (selectedIndex === lubricatedData.length - 1) {
                newSelected = newSelected.concat(lubricatedData.slice(0, -1));
            } else if (selectedIndex > 0) {
                newSelected = newSelected.concat(
                    lubricatedData.slice(0, selectedIndex),
                    lubricatedData.slice(selectedIndex + 1)
                );
            }
        }
        setLubricatedData(newSelected);
    }

    const reset = () => {
        setCanbeEdited(false);
        setLubricatedData([]);
        setMilledData([]);
        setUnmilledData([]);
        setMilled("Y");
        setUnmilled("Y");
        setLubricated("Y");
    }
    const createCAQEntry = () => {
        const milledKeys: any = [], unMilledKeys: any = [], lubricatedKeys: any = [];
        milledData?.map((i: any) => milledKeys.push(i.TrialCQAPSDParamKey));
        unMilledData?.map((i: any) => unMilledKeys.push(i.TrialCQAPSDParamKey));
        lubricatedData?.map((i: any) => lubricatedKeys.push(i.TrialCQAPSDParamKey));
        const body = {
            userId: loggedInUser,
            trialKey: props.newKey,
            milledKeys: milledKeys.length > 0 ? milledKeys.toString() : '-1',
            unMilledKeys: unMilledKeys.length > 0 ? unMilledKeys.toString() : '-1',
            lubricatedKeys: lubricatedKeys.length > 0 ? lubricatedKeys.toString() : '-1',
            token: token
        }
        TrailAnalysisService.createTrailAnalysisCQA(body)
            .then((response: any) => {
                const result = response?.data.message[0];
                const finalResult = result[0]
                if (finalResult[0].Result === "Error") {
                    reset();
                    props.onClose();
                    props.handleReset();
                    props.notify(finalResult[0].ResultMessage);
                }
                else {
                    reset();
                    props.handleNext();

                }
            })
            .catch((err) => {
                console.log(err);
            });
    }

    const milledDisabled = milled === "Y" ? milledData.length === 0 ? true : false : false;
    const unMilledDisabled = unMilled === "Y" ? unMilledData.length === 0 ? true : false : false;
    const lubricatedDisabled = lubricated === "Y" ? lubricatedData.length === 0 ? true : false : false;

    const renderButtons = () => {
        return (
            <div className={'btns noWrap'}>
                <ACGButton
                    name={'Cancel'}
                    handleClick={() => { props.drawerClose(); reset(); props.handleReset() }}
                    secondary={true}
                    className={'first-btn'}
                    type="button"
                    variant="contained"
                />
                <ACGButton
                    name={'Next'}
                    handleClick={() => { createCAQEntry() }}
                    type="submit"
                    className="second-btn"
                    variant="contained"
                    disabled={milledDisabled || unMilledDisabled || lubricatedDisabled ? true : false}
                />
            </div>
        );
    };

    const DropDownIcon = (prop: any) => (
        <img style={{ marginRight: '5px', marginTop: '-2px' }} src={DownArrow} {...prop} />
    );

    return (
        <Container className="createReport">
            <Box>
                <div className='formwrap all-labelcls'>
                    <Label label="CAQ Parameters for Template" className={'labelDiv'} />
                    <FormControl fullWidth>
                        <Grid container spacing={2}>
                            <Grid item xs={2}>
                                <FormControl fullWidth>
                                    <Label label="Static Fields for LOD" className={'labelDiv'} />
                                    <TextInput id="outlined-basic"
                                        variant="outlined"
                                        sx={{
                                            marginBottom: "20px", height: '20px', "& .MuiInputBase-input.Mui-disabled": {
                                                WebkitTextFillColor: "#ffffff",
                                            }
                                        }}
                                        value="LOD of granules/pellet"
                                        maxLength={50}
                                        fullWidth={true}
                                        disabled={true}
                                    />
                                </FormControl>
                            </Grid>
                            <Grid item xs={2}>
                                <FormControl fullWidth>
                                    <Label label="&nbsp;" className={'labelDiv'} />
                                    <TextInput id="outlined-basic"
                                        variant="outlined"
                                        sx={{
                                            marginBottom: "20px", height: '20px', "& .MuiInputBase-input.Mui-disabled": {
                                                WebkitTextFillColor: "#ffffff",
                                            }
                                        }}
                                        value="LOD Temperature"
                                        maxLength={50}
                                        fullWidth={true}
                                        disabled={true}
                                    />
                                </FormControl>
                            </Grid>
                            <Grid item xs={2}>
                                <FormControl fullWidth>
                                    <Label label="&nbsp;" className={'labelDiv'} />
                                    <TextInput id="outlined-basic"
                                        variant="outlined"
                                        sx={{
                                            marginBottom: "20px", height: '20px', "& .MuiInputBase-input.Mui-disabled": {
                                                WebkitTextFillColor: "#ffffff",
                                            }
                                        }}
                                        value="% Yield of Batch"
                                        maxLength={50}
                                        fullWidth={true}
                                        disabled={true}
                                    />
                                </FormControl>
                            </Grid>
                            <Grid item xs={2}>
                                <FormControl fullWidth>
                                    <Label label="&nbsp;" className={'labelDiv'} />
                                    <TextInput id="outlined-basic"
                                        variant="outlined"
                                        sx={{
                                            marginBottom: "20px", height: '20px', "& .MuiInputBase-input.Mui-disabled": {
                                                WebkitTextFillColor: "#ffffff",
                                            }
                                        }}
                                        value="Screen"
                                        maxLength={50}
                                        fullWidth={true}
                                        disabled={true}
                                    />
                                </FormControl>
                            </Grid>
                            <Grid item xs={2}>
                                <FormControl fullWidth>
                                    <Label label="&nbsp;" className={'labelDiv'} />
                                    <TextInput id="outlined-basic"
                                        variant="outlined"
                                        sx={{
                                            marginBottom: "20px", height: '20px', "& .MuiInputBase-input.Mui-disabled": {
                                                WebkitTextFillColor: "#ffffff",
                                            }
                                        }}
                                        value="Mill RPM"
                                        maxLength={50}
                                        fullWidth={true}
                                        disabled={true}
                                    />
                                </FormControl>
                            </Grid>

                            <Grid item xs={4}>
                                <Label label="Selectable fields for PSD" className={'labelDiv'} />
                                <FormControl fullWidth>
                                    <Label label="PSD of milled granules. Required?" className={'labelDiv'} />
                                    <RadioGroup
                                        row
                                        aria-labelledby="demo-row-radio-buttons-group-label"
                                        name="row-radio-buttons-group" className='radiolabls'

                                    >
                                        <FormControlLabel value="Y" control={<Radio disabled={props.trailKey !== '' && canBeEdited === false ? true : false} onChange={handleChangeMilled} checked={milled === 'Y' ? true : false} />} label="Yes" />
                                        <FormControlLabel value="N" control={<Radio disabled={props.trailKey !== '' && canBeEdited === false ? true : false} onChange={handleChangeMilled} checked={milled === 'N' ? true : false} />} label="No" />
                                    </RadioGroup>
                                    {
                                        milled === "Y" ?
                                            <>
                                                <Grid item xs={4}>
                                                    <FormControl fullWidth>
                                                        <Label label="Sleeve Size" className={'labelDiv'} />
                                                        <FormControl sx={{ width: '100%' }}>
                                                            {milledData?.length > 0 ? null : (
                                                                <InputLabel
                                                                    style={{
                                                                        fontFamily: 'Montserrat',
                                                                        fontSize: '13px',
                                                                        marginTop: "-6px",
                                                                        paddingLeft: '12px',
                                                                        color: '#b1c2df',
                                                                        opacity: '0.7'
                                                                    }}
                                                                    id="demo-multiple-checkbox-label"
                                                                >
                                                                    Select Sleeve Size
                                                                </InputLabel>
                                                            )}
                                                            <Select
                                                                IconComponent={DropDownIcon}
                                                                labelId="demo-multiple-checkbox-label"
                                                                id="demo-multiple-checkbox"
                                                                sx={{ marginBottom: "20px", backgroundColor: "#1d1e2c", borderRadius: 3, marginTop: '10px', color: "#b1c2df" ,"& .MuiInputBase-input.Mui-disabled": {
                                                                    WebkitTextFillColor: "#ffffff",
                                                                } }}
                                                                multiple
                                                                value={milledData}
                                                                input={<OutlinedInput />}
                                                                renderValue={(selected) => selected.map((x: any) => x.TrialCQAPSDParamName).join(', ')}
                                                                MenuProps={MenuProps}
                                                                disabled={!canBeEdited ? true : false}
                                                            >
                                                                {props.psdData[0]?.map((i: any) => (
                                                                    <MenuItem value={i} onClick={() => handleChangeMilledNames(i)}>
                                                                        <Checkbox
                                                                            checked={
                                                                                milledData.findIndex((item: any) => item.TrialCQAPSDParamKey === i.TrialCQAPSDParamKey) >= 0
                                                                            }
                                                                        />
                                                                        <ListItemText primary={i.TrialCQAPSDParamName} />
                                                                    </MenuItem>
                                                                ))}
                                                            </Select>
                                                        </FormControl>
                                                    </FormControl>
                                                </Grid>
                                                <Grid item xs={4}>
                                                    <FormControl fullWidth>
                                                        <TextInput id="outlined-basic"
                                                            variant="outlined"
                                                            sx={{
                                                                marginBottom: "20px", height: '20px', "& .MuiInputBase-input.Mui-disabled": {
                                                                    WebkitTextFillColor: "#ffffff",
                                                                }
                                                            }}
                                                            value="Bulk Density"
                                                            maxLength={50}
                                                            fullWidth={true}
                                                            disabled={true}
                                                        />
                                                    </FormControl>
                                                </Grid>
                                                <Grid item xs={4}>
                                                    <FormControl fullWidth>
                                                        <TextInput id="outlined-basic"
                                                            variant="outlined"
                                                            sx={{
                                                                marginBottom: "20px", height: '20px', "& .MuiInputBase-input.Mui-disabled": {
                                                                    WebkitTextFillColor: "#ffffff",
                                                                }
                                                            }}
                                                            value="Trapped Density"
                                                            maxLength={50}
                                                            fullWidth={true}
                                                            disabled={true}
                                                        />
                                                    </FormControl>
                                                </Grid>
                                            </>
                                            :
                                            null
                                    }
                                </FormControl>
                            </Grid>
                            <Grid item xs={4}>
                                <Label label="&nbsp;" className={'labelDiv'} />
                                <FormControl fullWidth>
                                    <Label label="PSD of unmilled granules. Required?" className={'labelDiv'} />
                                    <RadioGroup
                                        row
                                        aria-labelledby="demo-row-radio-buttons-group-label"
                                        name="row-radio-buttons-group" className='radiolabls'

                                    >
                                        <FormControlLabel value="Y" control={<Radio disabled={props.trailKey !== '' && canBeEdited === false ? true : false} onChange={handleChangeUnMilled} checked={unMilled === 'Y' ? true : false} />} label="Yes" />
                                        <FormControlLabel value="N" control={<Radio disabled={props.trailKey !== '' && canBeEdited === false ? true : false} onChange={handleChangeUnMilled} checked={unMilled === 'N' ? true : false} />} label="No" />
                                    </RadioGroup>
                                    {
                                        unMilled === "Y" ?
                                            <>
                                                <Grid item xs={4}>
                                                    <FormControl fullWidth>
                                                        <Label label="Sleeve Size" className={'labelDiv'} />
                                                        <FormControl sx={{ width: '100%' }}>
                                                            {unMilledData?.length > 0 ? null : (
                                                                <InputLabel
                                                                    style={{
                                                                        fontFamily: 'Montserrat',
                                                                        fontSize: '13px',
                                                                        marginTop: "-6px",
                                                                        paddingLeft: '12px',
                                                                        color: '#b1c2df',
                                                                        opacity: '0.7'
                                                                    }}
                                                                    id="demo-multiple-checkbox-label"
                                                                >
                                                                    Select Sleeve Size
                                                                </InputLabel>
                                                            )}
                                                            <Select
                                                                IconComponent={DropDownIcon}
                                                                labelId="demo-multiple-checkbox-label"
                                                                id="demo-multiple-checkbox"
                                                                sx={{ marginBottom: "20px", backgroundColor: "#1d1e2c", borderRadius: 3, marginTop: '10px', color: "#b1c2df","& .MuiInputBase-input.Mui-disabled": {
                                                                    WebkitTextFillColor: "#ffffff",
                                                                } }}
                                                                multiple
                                                                value={unMilledData}
                                                                input={<OutlinedInput />}
                                                                renderValue={(selected) => selected.map((x: any) => x.TrialCQAPSDParamName).join(', ')}
                                                                MenuProps={MenuProps}
                                                                disabled={!canBeEdited ? true : false}
                                                            >
                                                                {props.psdData[1]?.map((i: any) => (
                                                                    <MenuItem value={i} onClick={() => handleChangeUnMilledNames(i)}>
                                                                        <Checkbox
                                                                            checked={
                                                                                unMilledData.findIndex((item: any) => item.TrialCQAPSDParamKey === i.TrialCQAPSDParamKey) >= 0
                                                                            }
                                                                        />
                                                                        <ListItemText primary={i.TrialCQAPSDParamName} />
                                                                    </MenuItem>
                                                                ))}
                                                            </Select>
                                                        </FormControl>
                                                    </FormControl>
                                                </Grid>
                                                <Grid item xs={4}>
                                                    <FormControl fullWidth>
                                                        <TextInput id="outlined-basic"
                                                            variant="outlined"
                                                            sx={{
                                                                marginBottom: "20px", height: '20px', "& .MuiInputBase-input.Mui-disabled": {
                                                                    WebkitTextFillColor: "#ffffff",
                                                                }
                                                            }}
                                                            value="Bulk Density"
                                                            maxLength={50}
                                                            fullWidth={true}
                                                            disabled={true}
                                                        />
                                                    </FormControl>
                                                </Grid>
                                                <Grid item xs={4}>
                                                    <FormControl fullWidth>
                                                        <TextInput id="outlined-basic"
                                                            variant="outlined"
                                                            sx={{
                                                                marginBottom: "20px", height: '20px', "& .MuiInputBase-input.Mui-disabled": {
                                                                    WebkitTextFillColor: "#ffffff",
                                                                }
                                                            }}
                                                            value="Trapped Density"
                                                            maxLength={50}
                                                            fullWidth={true}
                                                            disabled={true}
                                                        />
                                                    </FormControl>
                                                </Grid>
                                            </>
                                            :
                                            null
                                    }
                                </FormControl>
                            </Grid>
                            <Grid item xs={4}>
                                <Label label="&nbsp;" className={'labelDiv'} />
                                <FormControl fullWidth>
                                    <Label label="PSD of lubricated blend. Required?" className={'labelDiv'} />
                                    <RadioGroup
                                        row
                                        aria-labelledby="demo-row-radio-buttons-group-label"
                                        name="row-radio-buttons-group" className='radiolabls'

                                    >
                                        <FormControlLabel value="Y" control={<Radio disabled={props.trailKey !== '' && canBeEdited === false ? true : false} onChange={handleChangelubricated} checked={lubricated === 'Y' ? true : false} />} label="Yes" />
                                        <FormControlLabel value="N" control={<Radio disabled={props.trailKey !== '' && canBeEdited === false ? true : false} onChange={handleChangelubricated} checked={lubricated === 'N' ? true : false} />} label="No" />
                                    </RadioGroup>
                                    {
                                        lubricated === "Y" ?
                                            <>
                                                <Grid item xs={4}>
                                                    <FormControl fullWidth>
                                                        <Label label="Sleeve Size" className={'labelDiv'} />
                                                        <FormControl sx={{ width: '100%' }}>
                                                            {lubricatedData?.length > 0 ? null : (
                                                                <InputLabel
                                                                    style={{
                                                                        fontFamily: 'Montserrat',
                                                                        fontSize: '13px',
                                                                        marginTop: "-6px",
                                                                        paddingLeft: '12px',
                                                                        color: '#b1c2df',
                                                                        opacity: '0.7'
                                                                    }}
                                                                    id="demo-multiple-checkbox-label"
                                                                >
                                                                    Select Sleeve Size
                                                                </InputLabel>
                                                            )}
                                                            <Select
                                                                IconComponent={DropDownIcon}
                                                                labelId="demo-multiple-checkbox-label"
                                                                id="demo-multiple-checkbox"
                                                                sx={{ marginBottom: "20px", backgroundColor: "#1d1e2c", borderRadius: 3, marginTop: '10px', color: "#b1c2df" ,"& .MuiInputBase-input.Mui-disabled": {
                                                                    WebkitTextFillColor: "#ffffff",
                                                                } }}
                                                                multiple
                                                                value={lubricatedData}
                                                                input={<OutlinedInput />}
                                                                renderValue={(selected) => selected.map((x: any) => x.TrialCQAPSDParamName).join(', ')}
                                                                MenuProps={MenuProps}
                                                                disabled={!canBeEdited ? true : false}
                                                            >
                                                                {props.psdData[2]?.map((i: any) => (
                                                                    <MenuItem value={i} onClick={() => handleChangeLubricatedNames(i)}>
                                                                        <Checkbox
                                                                            checked={
                                                                                lubricatedData.findIndex((item: any) => item.TrialCQAPSDParamKey === i.TrialCQAPSDParamKey) >= 0
                                                                            }
                                                                        />
                                                                        <ListItemText primary={i.TrialCQAPSDParamName} />
                                                                    </MenuItem>
                                                                ))}
                                                            </Select>
                                                        </FormControl>
                                                    </FormControl>
                                                </Grid>
                                                <Grid item xs={4}>
                                                    <FormControl fullWidth>
                                                        <TextInput id="outlined-basic"
                                                            variant="outlined"
                                                            sx={{
                                                                marginBottom: "20px", height: '20px', "& .MuiInputBase-input.Mui-disabled": {
                                                                    WebkitTextFillColor: "#ffffff",
                                                                }
                                                            }}
                                                            value="Bulk Density"
                                                            maxLength={50}
                                                            fullWidth={true}
                                                            disabled={true}
                                                        />
                                                    </FormControl>
                                                </Grid>
                                                <Grid item xs={4}>
                                                    <FormControl fullWidth>
                                                        <TextInput id="outlined-basic"
                                                            variant="outlined"
                                                            sx={{
                                                                marginBottom: "20px", height: '20px', "& .MuiInputBase-input.Mui-disabled": {
                                                                    WebkitTextFillColor: "#ffffff",
                                                                }
                                                            }}
                                                            value="Trapped Density"
                                                            maxLength={50}
                                                            fullWidth={true}
                                                            disabled={true}
                                                        />
                                                    </FormControl>
                                                </Grid>
                                            </>
                                            :
                                            null
                                    }
                                </FormControl>
                            </Grid>
                            <Grid item xs={6}>
                                <Label label="Static Fields for Tablet Parameter Limit Update" className={'labelDiv'} />
                            </Grid>
                            <Grid item xs={6}></Grid>
                            <Grid item xs={2}>
                                <FormControl fullWidth>

                                    <TextInput id="outlined-basic"
                                        variant="outlined"
                                        sx={{
                                            marginBottom: "20px", height: '20px', "& .MuiInputBase-input.Mui-disabled": {
                                                WebkitTextFillColor: "#ffffff",
                                            }
                                        }}
                                        value="Avg wt of Tablets"
                                        maxLength={50}
                                        fullWidth={true}
                                        disabled={true}
                                    />
                                </FormControl>
                            </Grid>
                            <Grid item xs={2}>
                                <FormControl fullWidth>
                                    <TextInput id="outlined-basic"
                                        variant="outlined"
                                        sx={{
                                            marginBottom: "20px", height: '20px', "& .MuiInputBase-input.Mui-disabled": {
                                                WebkitTextFillColor: "#ffffff",
                                            }
                                        }}
                                        value="Avg Thickness of Tablets"
                                        maxLength={50}
                                        fullWidth={true}
                                        disabled={true}
                                    />
                                </FormControl>
                            </Grid>
                            <Grid item xs={2}>
                                <FormControl fullWidth>
                                    <TextInput id="outlined-basic"
                                        variant="outlined"
                                        sx={{
                                            marginBottom: "20px", height: '20px', "& .MuiInputBase-input.Mui-disabled": {
                                                WebkitTextFillColor: "#ffffff",
                                            }
                                        }}
                                        value="Avg Diameter of Tablets"
                                        maxLength={50}
                                        fullWidth={true}
                                        disabled={true}
                                    />
                                </FormControl>
                            </Grid>
                            <Grid item xs={2}>
                                <FormControl fullWidth>
                                    <TextInput id="outlined-basic"
                                        variant="outlined"
                                        sx={{
                                            marginBottom: "20px", height: '20px', "& .MuiInputBase-input.Mui-disabled": {
                                                WebkitTextFillColor: "#ffffff",
                                            }
                                        }}
                                        value="Filling Depth"
                                        maxLength={50}
                                        fullWidth={true}
                                        disabled={true}
                                    />
                                </FormControl>
                            </Grid>
                            <Grid item xs={2}>
                                <FormControl fullWidth>
                                    <TextInput id="outlined-basic"
                                        variant="outlined"
                                        sx={{
                                            marginBottom: "20px", height: '20px', "& .MuiInputBase-input.Mui-disabled": {
                                                WebkitTextFillColor: "#ffffff",
                                            }
                                        }}
                                        value="Applied Force"
                                        maxLength={50}
                                        fullWidth={true}
                                        disabled={true}
                                    />
                                </FormControl>
                            </Grid>
                            <Grid item xs={2}>
                                <FormControl fullWidth>
                                    <TextInput id="outlined-basic"
                                        variant="outlined"
                                        sx={{
                                            marginBottom: "20px", height: '20px', "& .MuiInputBase-input.Mui-disabled": {
                                                WebkitTextFillColor: "#ffffff",
                                            }
                                        }}
                                        value="Min Hardness"
                                        maxLength={50}
                                        fullWidth={true}
                                        disabled={true}
                                    />
                                </FormControl>
                            </Grid>
                            <Grid item xs={2}>
                                <FormControl fullWidth>
                                    <TextInput id="outlined-basic"
                                        variant="outlined"
                                        sx={{
                                            marginBottom: "20px", height: '20px', "& .MuiInputBase-input.Mui-disabled": {
                                                WebkitTextFillColor: "#ffffff",
                                            }
                                        }}
                                        value="Max Hardness"
                                        maxLength={50}
                                        fullWidth={true}
                                        disabled={true}
                                    />
                                </FormControl>
                            </Grid>
                            <Grid item xs={2}>
                                <FormControl fullWidth>
                                    <TextInput id="outlined-basic"
                                        variant="outlined"
                                        sx={{
                                            marginBottom: "20px", height: '20px', "& .MuiInputBase-input.Mui-disabled": {
                                                WebkitTextFillColor: "#ffffff",
                                            }
                                        }}
                                        value="DT Time"
                                        maxLength={50}
                                        fullWidth={true}
                                        disabled={true}
                                    />
                                </FormControl>
                            </Grid>
                            <Grid item xs={2}>
                                <FormControl fullWidth>
                                    <TextInput id="outlined-basic"
                                        variant="outlined"
                                        sx={{
                                            marginBottom: "20px", height: '20px', "& .MuiInputBase-input.Mui-disabled": {
                                                WebkitTextFillColor: "#ffffff",
                                            }
                                        }}
                                        value="Friability of tablet at 100 rpm"
                                        maxLength={50}
                                        fullWidth={true}
                                        disabled={true}
                                    />
                                </FormControl>
                            </Grid>
                            <Grid item xs={2}>
                                <FormControl fullWidth>
                                    <TextInput id="outlined-basic"
                                        variant="outlined"
                                        sx={{
                                            marginBottom: "20px", height: '20px', "& .MuiInputBase-input.Mui-disabled": {
                                                WebkitTextFillColor: "#ffffff",
                                            }
                                        }}
                                        value="Friability of tablet at 400 rpm"
                                        maxLength={50}
                                        fullWidth={true}
                                        disabled={true}
                                    />
                                </FormControl>
                            </Grid>
                        </Grid>
                    </FormControl>
                </div>
            </Box>
            {renderButtons()}
        </Container>
    );
}