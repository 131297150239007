import { TableRow, TableCell, TableHead } from '@mui/material';
interface Props {
    headLabel: any;
}
const TableColumns = (props: Props) => {
    const { headLabel } = props;
    return (
        <TableHead className="tableColumn">
            <TableRow >
                {headLabel.map((headCell: any, idx: number) => {
                    const cellKey = idx + 1;
                    return (
                        <TableCell
                            style={headCell.id === 'ParamCode' ? { zIndex: 900, position: 'sticky', left: 0 } : { position: 'sticky', padding: "18px 24px" }}
                            key={cellKey}
                            align={headCell.alignRight ? 'right' : 'left'}
                        >
                            {headCell.label}
                        </TableCell>
                    );
                })}
            </TableRow>
        </TableHead>
    );
};

export default TableColumns;
