import FMicon from '../../assets/images/navigationicons/FMicon.svg';
import CMicon from '../../assets/images/navigationicons/CMicon.svg';
import UMicon from '../../assets/images/navigationicons/UMicon.svg';
import MMicon from '../../assets/images/navigationicons/MachineManagementIcon.svg';
import AuditTrail from '../../assets/images/navigationicons/AuditTrail.svg';
import MachineMonitoringIcon from '../../assets/images/navigationicons/MachineMonitoring.svg';
import RemoteAssistanceIcon from '../../assets/images/navigationicons/RemoteAssistance.svg';
import BMIcon from '../../assets/images/BroadCastIcon.svg';
import ReportsIcon from '../../assets/images/navigationicons/menu_user_reports.svg';
import SystemConfigIcon from '../../assets/images/navigationicons/SystemConfigIcon.svg';
import DNRPIcon from '../../assets/images/navigationicons/menu-dn-rp.svg'
import MaintenanceModuleIcon from '../../assets/images/navigationicons/MaintenanceModule.svg';
import ConditionMonitoringIcon from '../../assets/images/navigationicons/ConditionMonitoring.svg';
import TrialAnalysisIcon from '../../assets/images/navigationicons/TrialAnalysis.svg';
import FMEAAnalysisIcon from '../../assets/images/navigationicons/FMEA.svg';
import PMECDashboardICon from '../../assets/images/navigationicons/pmec.svg';
import NotificationIcon from '../../assets/images/navigationicons/notify.svg'
import GoldenBatchIcon from '../../assets/images/navigationicons/gb-dashboard.svg';
import GrafanaIcon from '../../assets/images/navigationicons/grafana-icon.svg';

import ComponentLifeCycleIcon from '../../assets/images/navigationicons/clc.svg';
export const paths = {
    LOGIN: { path: ['/', '/login'] },
    FORGOT_PWD: { path: '/forgotPassword', title: 'Forgot Password' },
    MACHINE_MONITOR: { path: '/machine-monitoring', title: 'Machine Monitoring' },
    MACHINE_MGMT: { path: '/machine-management', title: 'Machine Management' },
    BROADCAST_MGMT: { path: '/broadcast-management', title: 'Broadcast Management' }
};

export const ROUTES_PATH = [
    {
        isVisible: true,
        isHomePage: false,
        isAccess: false,
        FeartureCode: 'FEATMGMT',
        path: '/feature-management',
        title: '',
        imgSrc: FMicon
    },
    {
        isVisible: true,
        isHomePage: false,
        isAccess: false,
        FeartureCode: 'SYSCONFIG',
        path: '/system-configuration',
        title: '',
        imgSrc: SystemConfigIcon
    },
    {
        isVisible: true,
        isHomePage: false,
        isAccess: false,
        FeartureCode: 'RPTDB',
        path: '/user-reports',
        title: '',
        imgSrc: ReportsIcon
    },
    {
        isVisible: true,
        isHomePage: false,
        isAccess: false,
        FeartureCode: 'DL',
        path: '/downloads',
        title: '',
        imgSrc: DNRPIcon
    },
    {
        isVisible: true,
        isHomePage: false,
        isAccess: false,
        FeartureCode: 'CLMGMT',
        path: '/client-management',
        title: '',
        imgSrc: CMicon
    },
    {
        isVisible: true,
        isHomePage: false,
        isAccess: false,
        FeartureCode: 'RMTASSIST',
        path: '/remote-assistance',
        title: '',
        imgSrc: RemoteAssistanceIcon
    },
    {
        isVisible: true,
        isHomePage: false,
        isAccess: false,
        FeartureCode: 'AUDITTRAIL',
        path: '/audit-trail',
        title: '',
        imgSrc: AuditTrail
    },
    {
        isVisible: true,
        isHomePage: false,
        isAccess: false,
        FeartureCode: 'CONDMNT',
        path: '/condition-monitoring',
        title: '',
        imgSrc: FMicon
    },
    {
        isVisible: true,
        isHomePage: false,
        isAccess: false,
        FeartureCode: 'MACHMGMT',
        path: '/machine-management',
        title: '',
        imgSrc: MMicon
    },
    {
        isVisible: true,
        isHomePage: false,
        isAccess: false,
        FeartureCode: 'MACHMON',
        path: '/machine-monitoring',
        title: '',
        imgSrc: MachineMonitoringIcon
    },
    {
        isVisible: false,
        isHomePage: false,
        isAccess: false,
        FeartureCode: 'MACHMON',
        path: '/alarm-detail'
    },
    {
        isVisible: false,
        isHomePage: false,
        isAccess: false,
        FeartureCode: 'MACHMON',
        path: '/machine-detail'
    },
    {
        isVisible: false,
        isHomePage: false,
        isAccess: false,
        FeartureCode: 'MACHMON',
        path: '/event-detail'
    },
    {
        isVisible: true,
        isHomePage: false,
        isAccess: false,
        FeartureCode: 'USERMGMT',
        path: '/user-management',
        title: '',
        imgSrc: UMicon
    },
    {
        isVisible: true,
        isHomePage: false,
        isAccess: false,
        FeartureCode: 'BC',
        path: '/broadcast-management',
        title: '',
        imgSrc: BMIcon
    },
    {
        isVisible: true,
        isHomePage: false,
        isAccess: false,
        FeartureCode: 'MAINTMOD',
        path: '/maintenance-module',
        title: '',
        imgSrc: MaintenanceModuleIcon
    },
    {
        isVisible: true,
        isHomePage: false,
        isAccess: false,
        FeartureCode: 'CONDMON',
        path: '/condition-monitoring',
        title: '',
        imgSrc: ConditionMonitoringIcon
    },
    {
        isVisible: true,
        isHomePage: false,
        isAccess: false,
        FeartureCode: 'TRAILANY',
        path: '/trial-analysis',
        title: '',
        imgSrc: TrialAnalysisIcon
    },
    {
        isVisible: true,
        isHomePage: false,
        isAccess: false,
        FeartureCode: 'PMEC',
        path: '/oee-dashboard',
        title: '',
        imgSrc: PMECDashboardICon
    },
    {
        isVisible: false,
        isHomePage: false,
        isAccess: false,
        FeartureCode: 'PMEC',
        path: '/oee-dashboard/kpi',
        title: '',
        imgSrc: PMECDashboardICon
    },
    {
        isVisible: false,
        isHomePage: false,
        isAccess: false,
        FeartureCode: 'PMEC',
        path: '/oee-dashboard-downtime/kpi',
        title: '',
        imgSrc: PMECDashboardICon
    },
    {
        isVisible: false,
        isHomePage: false,
        isAccess: false,
        FeartureCode: 'PMEC',
        path: '/oee-insights/kpi',
        title: '',
        imgSrc: PMECDashboardICon
    },
    {
        isVisible: true,
        isHomePage: false,
        isAccess: false,
        FeartureCode: 'FMEAANY',
        path: '/fmea-analysis',
        title: '',
        imgSrc: FMEAAnalysisIcon
    },
    {
        isVisible: true,
        isHomePage: false,
        isAccess: false,
        FeartureCode: 'NOTIFIY',
        path: '/notifications',
        title: '',
        imgSrc: NotificationIcon
    },
    {
        isVisible: true,
        isHomePage: false,
        isAccess: false,
        FeartureCode: 'CLC',
        path: '/component-life-cycle',
        title: '',
        imgSrc: ComponentLifeCycleIcon
    },
    {
        isVisible: true,
        isHomePage: false,
        isAccess: false,
        FeartureCode: 'GLDASH',
        path: '/golden-batch-dashboard',
        title: '',
        imgSrc: GoldenBatchIcon
    },
    {
        isVisible: true,
        isHomePage: false,
        isAccess: false,
        FeartureCode: 'GRAFANA',
        path: '/grafana',
        title: '',
        imgSrc: GrafanaIcon
    }
    // ,
    // {
    //     isVisible: true,
    //     isHomePage: false,
    //     isAccess: false,
    //     FeartureCode: 'GOLDBATCH',
    //     path: '/goldenbatch-report',
    //     title: '',
    //     imgSrc: TrialAnalysisIcon
    // }
];
