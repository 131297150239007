import { SetStateAction, useEffect, useRef, useState } from 'react';
import {
    Checkbox,
    Container,
    FormControl,
    ListItemText,
    MenuItem,
    Grid,
    Select,
    Typography,
    TextField,
    Box
} from '@mui/material';
import { Formik, Form, FormikProps, useFormikContext } from 'formik';
import useGetState from '../../../../utils/hooks/useGetState';
import CustomDateTimePicker from '../../../../atoms/CustomDateTimePicker';
import { useDispatch } from 'react-redux';
import { executeACGAction, resetErr, updateScreenIdentifiers } from '../../../../store/slice';
import ACGButton from '../../../../atoms/Button';
import Label from '../../../../atoms/Label/index';
import TextInput from '../../../../atoms/TextInput';
import DropDown from '../../../../atoms/Dropdown';
import CustomDatePicker from '../../../../atoms/CustomDatePicker';
import AcgTimePicker from '../../../../atoms/TimePicker';
import { fetchBlobImage } from '../../../../utils/helper';
import { convertBlobFileToBase64 } from '../../../../utils/helper';
import Axios from 'axios';
import { ACTION_CODES, STORE_KEYS, BASEURL } from '../../../../constants/apiConstants';

type ComponentProps = {
    drawerClose: Function;
    setCurrTab: Function;
    setReload: Function;
    setSnackbarOpts: Function;
    dtlData: any;
    selectedRow: any;
    isEdit: boolean;
    setDtlData: Function;
    setSelectedRow: Function;
    assetList: any;
    categoryList: any;
    userList: any;
    preventiveMaintenanceWorkOrderKey: any;
    preventiveMaintenanceKey: any;
    getPreventiveMaintenanceWorkOrderList: Function;
    fetchPreventiveMaintenanceWorkOrderDetails: Function;
    assignPreventive: Boolean;
    getPreventiveMaintenanceList: Function;
};
type ModuleType = {
    startDateMO: any;
    startDateMC: any;
    scName: string | '';
    scValue: string | '';
    scDesc: string | '';
    
};

const CreateModule = (props: ComponentProps) => {
    const { preventiveMaintenanceWorkOrderKey, preventiveMaintenanceKey, drawerClose, setCurrTab, setReload = () => { }, setSnackbarOpts = () => { }, dtlData, isEdit, setDtlData = () => { }, selectedRow, setSelectedRow = () => { }, assetList, categoryList, userList, getPreventiveMaintenanceWorkOrderList = () => { }, fetchPreventiveMaintenanceWorkOrderDetails = () => { }, assignPreventive, getPreventiveMaintenanceList = () => {} } = props;
    const formRef = useRef<any>(null);
    const [assignedUserKey, setAssignedUserKey] = useState<number | string>('');
    const [formError, setFormError] = useState({ modelCode: '', modelName: '', paramsUpload: '', modelImage: '', hasError: false });
    const [initialValues, setInitialValues] = useState<any>({
    });
    const state = useGetState();
    const token = state?.[STORE_KEYS.USER_DTL].token;
    const loggedInUser = state[STORE_KEYS.USER_DTL]?.LoggedUserKey;
    const dispatch = useDispatch();
    const ITEM_HEIGHT = 48;
    const ITEM_PADDING_TOP = 8;
    const MenuProps = {
        PaperProps: {
            style: {
                maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
                width: 250
            }
        }
    };

    const options = {
        AD_SUCCESS: {
            message: `Preventive Maintenance user assigned successfully!`,
            open: true,
            type: 'success'
        },
    };

    const renderButtons = () => {
        return (
            <div className={'btns noWrap'}>
                <ACGButton
                    name={'Cancel'}
                    handleClick={drawerClose}
                    secondary={true}
                    className={'first-btn'}
                    type="button"
                    variant="contained"
                />
                <ACGButton
                    name={'Submit'}
                    handleClick={() => { handleSubmit() }}
                    type="submit"
                    className="second-btn"
                    variant="contained"
                    disabled={assignedUserKey === "" ? true : false}
                />
            </div>
        );
    };

    const handleSubmit = async () => {
        const bundle = {
            payload: {
                urlPath: ACTION_CODES.ASSIGN_PREVENTIVE_MAINTENANCE_WORK_ORDER_USER,
                requestType: 'POST',
                reqObj: {
                    userId: loggedInUser,
                    workOrderKey: preventiveMaintenanceWorkOrderKey,  
                    userKey: assignedUserKey
                }
            },
            uniqueScreenIdentifier: { assignedUser: true },
            storeKey: STORE_KEYS.ASSIGN_PREVENTIVE_MAINTENANCE_WORK_ORDER_USER
        };

        const bundle2 = {
            payload: {
                urlPath: ACTION_CODES.ASSIGN_PREVENTIVE_MAINTENANCE_USER,
                requestType: 'POST',
                reqObj: {
                    userId: loggedInUser,
                    pmKey: preventiveMaintenanceKey,  
                    userKey: assignedUserKey
                }
            },
            uniqueScreenIdentifier: { assignedPMUser: true },
            storeKey: STORE_KEYS.ASSIGN_PREVENTIVE_MAINTENANCE_USER
        };
        if(assignPreventive)  dispatch(executeACGAction(bundle2));
        else dispatch(executeACGAction(bundle));
    };

    useEffect(() => {
        getPreventiveMaintenanceWorkOrderList();
        getPreventiveMaintenanceList();
        if (state?.[STORE_KEYS.ASSIGN_PREVENTIVE_MAINTENANCE_WORK_ORDER_USER]?.assignedUser) {
            if (state?.[STORE_KEYS.ASSIGN_PREVENTIVE_MAINTENANCE_WORK_ORDER_USER]?.status === "Success") {
                setReload(true);
                assignPreventive ? setCurrTab(0) : setCurrTab(1);
                setSnackbarOpts(options.AD_SUCCESS);
                drawerClose();
                preventiveMaintenanceWorkOrderKey !== '' ? fetchPreventiveMaintenanceWorkOrderDetails(preventiveMaintenanceWorkOrderKey) : undefined;
            }
            dispatch(
                updateScreenIdentifiers({
                    storeKey: STORE_KEYS.ASSIGN_PREVENTIVE_MAINTENANCE_WORK_ORDER_USER,
                    uniqueScreenIdentifier: {
                        assignedUser: false
                    }
                })
            );
        }
    }, [state?.[STORE_KEYS.ASSIGN_PREVENTIVE_MAINTENANCE_WORK_ORDER_USER]?.assignedUser]);

    useEffect(() => {
        getPreventiveMaintenanceWorkOrderList();
        getPreventiveMaintenanceList();
        if (state?.[STORE_KEYS.ASSIGN_PREVENTIVE_MAINTENANCE_USER]?.assignedPMUser) {
            if (state?.[STORE_KEYS.ASSIGN_PREVENTIVE_MAINTENANCE_USER]?.status === "Success") {
                setReload(true);
                assignPreventive ? setCurrTab(0) : setCurrTab(1);
                setSnackbarOpts(options.AD_SUCCESS);
                drawerClose();
                // preventiveMaintenanceWorkOrderKey !== '' ? fetchPreventiveMaintenanceWorkOrderDetails(preventiveMaintenanceWorkOrderKey) : undefined;
           
            }
            dispatch(
                updateScreenIdentifiers({
                    storeKey: STORE_KEYS.ASSIGN_PREVENTIVE_MAINTENANCE_USER,
                    uniqueScreenIdentifier: {
                        assignedPMUser: false
                    }
                })
            );
        }
    }, [state?.[STORE_KEYS.ASSIGN_PREVENTIVE_MAINTENANCE_USER]?.assignedPMUser]);

    const handleSnackbarError = (err: any) => {
        const errorMsg = err?.message || 'Internal Server error';
        const snackbarError = {
            message: errorMsg,
            type: 'reject',
            open: true
        };
        setSnackbarOpts(snackbarError);
    };

    useEffect(() => {
        if (state?.err) {
            handleSnackbarError(state?.err);
            dispatch(resetErr());
        }
    }, [state?.err]);

    const handleChangeActionType = (event: { target: { value: any; }; }) => {
        setAssignedUserKey(event.target.value);
    }

    return (
        <Container className="createReport">
            <Formik
                initialValues={initialValues}
                onSubmit={(values: any) => {
                    // handleSubmit(values);
                }}
                validateOnBlur={false}
                validateOnChange={false}
                innerRef={formRef}
            >
                {(formikprops: FormikProps<ModuleType>) => {
                    const { values, handleChange, handleBlur, errors, touched, setFieldValue } = formikprops;
                    return (
                        <Form>
                             
                            <Label label="Select User &nbsp; *" className={'labelDiv'} />
                                    <DropDown
                                        onChange={handleChangeActionType}
                                        className="inputDiv"
                                        name="dropdownModel"
                                        items={userList}
                                        placeHolder="Select User"
                                        value={assignedUserKey}
                                    />
                                        
                            {renderButtons()}
                        </Form>
                    );
                }}
            </Formik>
        </Container>
    );
};
export default CreateModule;
