//import * as React from 'react';
import Rating from '@mui/material/Rating';
import './index.scss';
type Props = {
    value?: number;
    markKey?: any;
    unMarkKey?: any;
    handleChange?: Function;
};

export default function BasicRating(props: Props) {
    // const [value, setValue] = React.useState<number | null>(0);
    const { value, markKey, unMarkKey, handleChange = () => { } } = props;

    return (
        <Rating
            className='ratingClass'
            name="simple-controlled"
            value={value}
            // onChange={(event, newValue) => {
            //     setValue(newValue);
            // }}
            onChange={(event, newValue) => handleChange(newValue, markKey, unMarkKey)}
            max={1}
        />
    );
}
