import { useState, useCallback, useEffect, useMemo } from "react";
import { Container } from "@mui/material";
import { read, utils } from 'xlsx';
import ACGButton from '../../../atoms/Button';
import CustomEditableTable from '../../../atoms/CustomEditableTable';
import HeaderToolbar from '../../../atoms/CustomEditableTable/HeaderToolbar';
import { executeACGAction, resetErr, updateScreenIdentifiers } from '../../../store/slice';
import { useDispatch } from 'react-redux';
import { ACTION_CODES, STORE_KEYS } from '../../../constants/apiConstants';
import useGetState from '../../../utils/hooks/useGetState';
import HeaderItems from "./HeaderItems";
import { applySortFilter } from "../../../utils/helper";
import RightDrawer from '../../../atoms/tempDrawer/indexAlt';
import SnackbarAlert from "../../../atoms/Snackbar";
import GroupParameter from "../GroupParameter/GroupParameter";
const TABLE_HEAD = [
    { id: "ParamCode", label: "D Tags", alignRight: false },
    { id: "ParamName", label: "Parameter Name", alignRight: false },
    { id: "ParamDesc", label: "Parameter Description", alignRight: false },
    { id: "SectionName", label: "Section", alignRight: false },
    { id: "ComponentName", label: "Component", alignRight: false },
    { id: "ParamLevel", label: "Parameter Level", alignRight: false },
    { id: "Category", label: "Category", alignRight: false },
    { id: "SubCategory", label: "Sub Category", alignRight: false },
    { id: "DataType", label: "Data Type", alignRight: false },
    { id: "Severity", label: "Severity", alignRight: false },
    { id: "AlarmType", label: "Alarm Type", alignRight: false },
    { id: "UOM", label: "UOM", alignRight: false },
    { id: "DowntimeFlag", label: "Downtime Flag", alignRight: false },
    { id: "IOTag", label: "IOTag", alignRight: false }
];
const options = {
    DEFAULT: {
        message: '',
        open: false,
        type: ''
    },
    ED_SUCCESS: {
        message: 'Model updated successfully!',
        open: true,
        type: 'success'
    }
};
const rowsPerPage = 10;
const EditModel = (props: any) => {
    const { closeDrawer, closeDrawerViewDrawer, data, setSnackbarOptions } = props;
    const { MachineModelKey, MachineModelName, MachineModelCode, MachineModelImagePath } = data;
    const dispatch = useDispatch();
    const stateObj = useGetState();
    const [parameters, setParameters] = useState<any[]>([]);
    const [searchedText, setSearchedText] = useState<string>("");
    const [page, setPage] = useState<number>(0);
    const [order, setOrder] = useState('desc');
    const [orderBy, setOrderBy] = useState('ParamName');
    const [machineModelName, setMachineModelName] = useState("");
    const [machineModelImage, setMachineModelImage] = useState<any>([]);
    const [listGroupParamDrawer, setlIstGroupParamDrawer] = useState(false);
    const [imageMachineMOdelBase64, setImageMachineMOdelBase64] = useState<any>([]);
    const [imageEdited, setImageEdited] = useState(false);
    const [isSetFile, setIsSetFile] = useState<any>([]);

    const getModels = () => {
        dispatch(
            executeACGAction({
                payload: {
                    requestType: 'GET',
                    urlPath: ACTION_CODES.GET_MODELS
                },
                uniqueScreenIdentifier: { modLoaded: true },
                storeKey: STORE_KEYS.MODELS
            })
        );
    }

    useEffect(() => {
        getModels()
    }, [])

    useEffect(() => {
        setMachineModelName(MachineModelName)
    }, [MachineModelName])
    useEffect(() => {
        // getBase64FromUrl(MachineModelImagePath).then((res: any) => setImageMachineMOdelBase64(res.replace(/^data:image\/[a-z]+;base64,/, "")))
        setMachineModelImage(MachineModelImagePath)
    }, [MachineModelImagePath])

    // For updating the parameters state while editing parametre
    const getUpdatedRowData = useCallback((rowData: any) => {

        const { MMParamKey } = rowData;
        const updatedParameters = parameters.map((parameter) => parameter.MMParamKey === MMParamKey ?
            { ...rowData, isEdit: true } : parameter);
        setParameters(updatedParameters);

    }, [parameters])

    const searchParametersHandler = useCallback((searchInput: string) => {
        setSearchedText(searchInput);
    }, [])

    const filteredList = applySortFilter(parameters, order, searchedText, orderBy);
    const parametersCount = filteredList?.length;

    const memorizeParameters = useMemo(() => {
        return filteredList.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage);
    }, [page, rowsPerPage, parametersCount, order, orderBy]);

    const handleChangePage = useCallback((event: any, newPage: React.SetStateAction<number>) => {
        setPage(newPage);
    }, [])

    // For Parameters 
    useEffect(() => {
        setParameters(stateObj?.[STORE_KEYS.MODEL_PARMS]?.body?.data ?? []);
    }, [stateObj?.[STORE_KEYS.MODEL_PARMS]?.body?.data])

    // For update success alert
    useEffect(() => {
        if (stateObj?.[STORE_KEYS.EDIT_MACHIN_EMODEL_PARAMETER_DATA]?.editMachineModelParameterLoaded &&
            stateObj?.[STORE_KEYS.MACHINES_EDIT_MACHINEMODEL_DATA]?.machinesEditMachineModelLoaded
        ) {

            if (stateObj?.[STORE_KEYS.EDIT_MACHIN_EMODEL_PARAMETER_DATA]?.statusCode === 200 &&
                stateObj?.[STORE_KEYS.MACHINES_EDIT_MACHINEMODEL_DATA]?.statusCode === 200) {
                setSnackbarOptions(options.ED_SUCCESS);
                getModels();
                closeDrawerViewDrawer();
                closeDrawer();
            }
        } else if (stateObj?.[STORE_KEYS.EDIT_MACHIN_EMODEL_PARAMETER_DATA]?.editMachineModelParameterLoaded) {
            if (stateObj?.[STORE_KEYS.EDIT_MACHIN_EMODEL_PARAMETER_DATA]?.statusCode === 200) {
                setSnackbarOptions(options.ED_SUCCESS);
                closeDrawerViewDrawer();
                closeDrawer();
            }
        } else if (stateObj?.[STORE_KEYS.MACHINES_EDIT_MACHINEMODEL_DATA]?.machinesEditMachineModelLoaded) {
            if (stateObj?.[STORE_KEYS.MACHINES_EDIT_MACHINEMODEL_DATA]?.statusCode === 200) {
                setSnackbarOptions(options.ED_SUCCESS);
                getModels();
                closeDrawerViewDrawer();
                closeDrawer();
                setTimeout(() => { window.location.reload(); }, 1000)
            }
        }
        dispatch(
            updateScreenIdentifiers({
                storeKey: STORE_KEYS.EDIT_MACHIN_EMODEL_PARAMETER_DATA,
                uniqueScreenIdentifier: {
                    editMachineModelParameterLoaded: false
                }
            })
        );
        dispatch(
            updateScreenIdentifiers({
                storeKey: STORE_KEYS.MACHINES_EDIT_MACHINEMODEL_DATA,
                uniqueScreenIdentifier: {
                    machinesEditMachineModelLoaded: false
                }
            })
        );
    }, [stateObj?.[STORE_KEYS.EDIT_MACHIN_EMODEL_PARAMETER_DATA]?.editMachineModelParameterLoaded,
    stateObj?.[STORE_KEYS.MACHINES_EDIT_MACHINEMODEL_DATA]?.machinesEditMachineModelLoaded
    ]);

    const validateInput = (params: []) => {
        const errors = { paramsUpload: '', hasError: false };
        if (params?.length) {
            errors.paramsUpload = '';
            errors.hasError = false;
        } else {
            errors.paramsUpload = 'Please upload parameters';
            errors.hasError = true;
        }
        return errors;
    }

    const renderButtons = () => {
        return (
            <div className={'btns noWrap'} style={{ display: 'flex', float: 'right', marginTop: '15px' }}>
                <ACGButton
                    disabled={stateObj?.isLoading}
                    name={'Cancel'}
                    handleClick={closeDrawer}
                    secondary={true}
                    className={'first-btn'}
                    type="button"
                    variant="contained"
                    style={{ marginRight: '25px', padding: '5px', width: '100px', height: '35px', marginBottom: '10px' }}
                />
                <ACGButton
                    disabled={stateObj?.isLoading}
                    name={`${stateObj?.isLoading ? 'Saving...' : 'Save'}`}
                    handleClick={saveHandler}
                    type="submit"
                    className="second-btn"
                    variant="contained"
                    style={{ marginRight: '25px', padding: '5px', width: '100px', height: '35px', marginBottom: '10px' }}
                />
            </div>
        );
    };

    // Save handler
    const saveHandler = () => {
        const file = isSetFile[0];
        if (file) {
            const runValidation = validateInput(isSetFile);
            if (!runValidation.hasError) {

                const reader: FileReader = new FileReader();
                reader.readAsArrayBuffer(file);
                reader.onload = (e: any) => {
                    const binarystr = new Uint8Array(e.target.result);
                    const wb = read(binarystr, { type: 'array', raw: true, cellFormula: false });
                    const wsname = wb.SheetNames[0];
                    const data = utils.sheet_to_json(wb.Sheets[wsname]);
                    const bundle = {
                        payload: {
                            urlPath: ACTION_CODES.ADD_MACHINE_MODEL_PARAMETER,
                            requestType: 'POST',
                            reqObj: {
                                machineModelKey: MachineModelKey,
                                machineModelParameters: data,
                            }
                        },
                        uniqueScreenIdentifier: { addMachineModelParameterLoaded: true },
                        storeKey: STORE_KEYS.ADD_MACHINE_MODEL_PARAMETER
                    };
                    dispatch(executeACGAction(bundle));
                }
            }
        }

        console.log("iiiiiii", imageMachineMOdelBase64);
        const bundle = {
            payload: {
                urlPath: ACTION_CODES.MACHINES_EDIT_MACHINEMODEL,
                requestType: 'PUT',
                reqObj: {
                    machineModelCode: MachineModelCode,
                    machineModelName: machineModelName,
                    machineModelImagePath: machineModelImage,
                    base64: imageEdited
                }
            },
            uniqueScreenIdentifier: { machinesEditMachineModelLoaded: true },
            storeKey: STORE_KEYS.MACHINES_EDIT_MACHINEMODEL_DATA
        };
        dispatch(executeACGAction(bundle));
        setImageEdited(false)
        // }
        const updatedParameters = parameters
            .filter((parameter) => parameter.isEdit === true)
            .map((parameter) => {
                const { isEdit, MachineModelName, MMParamKey, MachineModelKey, MachineModelImagePath, ...rest } = parameter;
                return rest;
            })
        if (updatedParameters.length) {
            const bundle = {
                payload: {
                    urlPath: ACTION_CODES.EDIT_MACHIN_EMODEL_PARAMETER,
                    requestType: 'PUT',
                    reqObj: {
                        machineModelKey: MachineModelKey,
                        machineModelParameters: updatedParameters
                    }
                },
                uniqueScreenIdentifier: { editMachineModelParameterLoaded: true },
                storeKey: STORE_KEYS.EDIT_MACHIN_EMODEL_PARAMETER_DATA
            };
            dispatch(executeACGAction(bundle));
        }
    }

    useEffect(() => {
        if (stateObj?.[STORE_KEYS.MACHINES_EDIT_MACHINEMODEL_DATA]?.machinesEditMachineModelLoaded) {
            if (stateObj?.[STORE_KEYS.MACHINES_EDIT_MACHINEMODEL_DATA]?.statusCode === 200) {
                closeDrawer();
                getModels();
            }
            dispatch(
                updateScreenIdentifiers({
                    storeKey: STORE_KEYS.MACHINES_EDIT_MACHINEMODEL_DATA,
                    uniqueScreenIdentifier: {
                        machinesEditMachineModelLoaded: false
                    }
                })
            );
        }
    }, [stateObj?.[STORE_KEYS.MACHINES_EDIT_MACHINEMODEL_DATA]?.machinesEditMachineModelLoaded]);

    useEffect(() => {
        if (stateObj?.[STORE_KEYS.MACHINES_EDIT_MACHINEMODEL]?.addMachineModelParameterLoaded) {
            if (stateObj?.[STORE_KEYS.MACHINES_EDIT_MACHINEMODEL]?.statusCode === 200) {

                getModels();
                closeDrawer();
                setSnackbarOptions(options.ED_SUCCESS);
            }
            dispatch(
                updateScreenIdentifiers({
                    storeKey: STORE_KEYS.MACHINES_EDIT_MACHINEMODEL,
                    uniqueScreenIdentifier: {
                        addMachineModelParameterLoaded: false
                    }
                })
            );
        }
    }, [stateObj?.[STORE_KEYS.MACHINES_EDIT_MACHINEMODEL]?.addMachineModelParameterLoaded]);

    const handleSnackbarError = (err: any) => {
        const errorMsg = err?.message || 'Internal Server error';
        const snackbarError = {
            message: errorMsg,
            type: 'reject',
            open: true
        };
        setSnackbarOptions(snackbarError);
    };
    useEffect(() => {
        if (stateObj?.err) {
            handleSnackbarError(stateObj?.err);
            dispatch(resetErr());
        }
    }, [stateObj?.err]);

    const categories = stateObj?.[STORE_KEYS.ADD_CATEGORY]?.body?.data ?? [];
    const datatypes = stateObj?.[STORE_KEYS.ADD_DATATYPE]?.body?.data ?? [];
    const severities = stateObj?.[STORE_KEYS.ADD_SEVERITY_DROPDOWN]?.body?.data ?? [];
    // console.log("memorizeParameters", memorizeParameters);

    const getBase64FromUrl = async (url: any) => {
        const data = await fetch(url, {
            mode: "no-cors",
        });
        const blob = await data.blob();
        return new Promise((resolve) => {
            const reader = new FileReader();
            reader.readAsDataURL(blob);
            reader.onloadend = () => {
                const base64data = reader.result;
                console.log("6464646", base64data);
                resolve(base64data);
            }
        });
    }

    return (
        <div>
            <div>
                {/* <img id="imageid" src={MachineModelImagePath}></img> */}
                <Container
                    maxWidth="xl"
                    style={{
                        paddingLeft: "3px",
                        paddingRight: "3px",
                    }}
                >
                    <HeaderItems
                        data={data}
                        machineModelCode={MachineModelCode}
                        MachineModelName={machineModelName}
                        setMachineModelName={setMachineModelName}
                        setMachineModelImage={setMachineModelImage}
                        MachineModelImagePath={MachineModelImagePath}
                        MachineModelKey={MachineModelKey}
                        setImageEdited={setImageEdited}
                    />
                    <div>
                        <HeaderToolbar
                            MachineModelKey={MachineModelKey}
                            searchParametersHandler={searchParametersHandler}
                            parametersCount={parametersCount}
                            setlIstGroupParamDrawer={setlIstGroupParamDrawer}
                            setIsSetFile={setIsSetFile}
                        />
                        <CustomEditableTable
                            headers={TABLE_HEAD}
                            filteredUsers={memorizeParameters}
                            getUpdatedRowData={getUpdatedRowData}
                            categories={useMemo(() => categories, [categories?.length])}
                            datatypes={useMemo(() => datatypes, [datatypes?.length])}
                            severities={useMemo(() => severities, [severities?.length])}
                            parametersCount={parametersCount}
                            page={page}
                            rowsPerPage={rowsPerPage}
                            handleChangePage={handleChangePage}
                            setPageNumber={setPage}
                        />
                    </div>
                </Container>
                {renderButtons()}
            </div>
            <RightDrawer
                handlerOpen={() => { }}
                handlerClose={() => setlIstGroupParamDrawer(false)}
                status={listGroupParamDrawer}
                drawerHeader={`Group Parameter`}
                children={<GroupParameter
                    closeDrawer={() => setlIstGroupParamDrawer(false)}
                    MachineModelKey={MachineModelKey}
                />
                }
                type="model"
            />
        </div>
    );
};
export default EditModel;
