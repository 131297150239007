import { Radio, FormHelperText, RadioGroupProps, RadioGroup } from '@mui/material';
import FormControlLabel, { FormControlLabelProps } from '@material-ui/core/FormControlLabel';
import FormControl from '@material-ui/core/FormControl';
import FormLabel from '@material-ui/core/FormLabel';
import './index.scss';

type ComponentProps = RadioGroupProps & {
    options: any;
    label?: any;
    labelPlacement?: FormControlLabelProps['labelPlacement'];
    disabled?: boolean;
    inputRef?: FormControlLabelProps['inputRef'];
    helperText?: string;
    rowDirection?: boolean;
    defaultValue?: string;
};

const RadioButton = (props: ComponentProps) => {
    const {
        options,
        label,
        labelPlacement,
        disabled = false,
        inputRef,
        helperText,
        rowDirection,
        defaultValue,
        ...rest
    } = props;
    return (
        <FormControl component="fieldset" className={'acgRadio'} style={{ fontFamily: 'Montserrat', color: 'white' }}>
            {label && <FormLabel>{label}</FormLabel>}
            <RadioGroup row={rowDirection ? true : false} defaultValue={defaultValue} {...rest}>
                {options?.map((option: any, index: number) => {
                    const radioKey = index + 1;
                    return (
                        <FormControlLabel
                            key={radioKey}
                            value={option?.value}
                            control={<Radio />}
                            label={option?.label}
                            labelPlacement={labelPlacement || 'end'}
                            disabled={option?.disabled || disabled}
                            inputRef={inputRef}
                        />
                    );
                })}
            </RadioGroup>
            {helperText && <FormHelperText>{helperText}</FormHelperText>}
        </FormControl>
    );
};

export default RadioButton;
