import { useState, useEffect } from 'react';
import { useDispatch } from 'react-redux';
import { Container } from '@mui/material';
import MapCustomTabs from '../../../atoms/ButtonTabs/MapCustomTabs';
import StatusCard from '../../../atoms/DashboardStatus/StatusCard';
import BroadCastIcon from '../../../../assets/images/BroadCastIcon.svg';
import { ACTION_CODES, STORE_KEYS } from '../../../constants/apiConstants';
import { executeACGAction, updateScreenIdentifiers } from '../../../store/slice';
import useGetState from '../../../utils/hooks/useGetState';
import DrawerDropdown from '../../../atoms/DrawerDropdown';
import RightDrawer from '../../../atoms/tempDrawer/indexAlt';
import { SCREEN_TITLES } from '../../../constants/StringConstants';
import SnackbarAlert from '../../../atoms/Snackbar';
import Upcoming from '../Upcoming';
import History from '../History';
import '../../Admin/ClientManagement/index.scss';
import ACGButton from '../../../atoms/Button';
import ScheduleBroadcast from '../ScheduleBroadcast';
import './index.scss';

const tabs = ['Upcoming', 'History'];
const options = {
    DEFAULT: {
        message: '',
        open: false,
        type: ''
    }
};
const BCManagement = () => {
    const [currTab, setTabChange] = useState(0);
    const [radioValue, setRadioValue] = useState('ACG');
    const [createDrawerOpen, setCreateDrawerOpen] = useState(false);
    const [snackbarOptions, setSnackbarOptions] = useState(options.DEFAULT);
    const [reload, setReload] = useState(false);
    const [clickSchedule, setClickSchedule] = useState(false);
    const dispatch = useDispatch();
    const state = useGetState();
    const feature = state?.[STORE_KEYS.USER_DTL]?.features;
    const userRole = feature?.find((item:any) => item.FeatureCode === "BC")?.IsEditable
    const setCurrTab = (val: number) => {
        setTabChange(val);
        if (radioValue == 'All' && currTab === 1) {
            setRadioValue('ACG');
        }
    };

    const getSummary = () => {
        dispatch(
            executeACGAction({
                payload: {
                    requestType: 'GET',
                    urlPath: ACTION_CODES.BC_SUMMARY
                },
                storeKey: STORE_KEYS.BC_SMRY
            })
        );
    };

    const getBcClients = () => {
        dispatch(
            executeACGAction({
                payload: {
                    requestType: 'GET',
                    urlPath: ACTION_CODES.GET_BC_CLIENTS
                },
                uniqueScreenIdentifier: { bcClRecd: true },
                storeKey: STORE_KEYS.BC_CLIENTS
            })
        );
    };

    useEffect(() => {
        if (state?.[STORE_KEYS.BC_CLIENTS]?.bcClRecd) {
            if (clickSchedule) {
                setClickSchedule(false);
                setCreateDrawerOpen(true);
            }
            dispatch(
                updateScreenIdentifiers({
                    storeKey: STORE_KEYS.BC_CLIENTS,
                    uniqueScreenIdentifier: {
                        bcClRecd: false
                    }
                })
            );
        }
    }, [state?.[STORE_KEYS.BC_CLIENTS]?.bcClRecd]);

    useEffect(getSummary, []);

    const navigateTab = () => {
        switch (currTab) {
            case 1:
                return <History
                    getBcClients={getBcClients}
                    setCurrTab={setCurrTab}
                    setReload={setReload}
                    reload={reload}
                    summary={getSummary}
                />

            default:
                return (
                    <Upcoming
                        getBcClients={getBcClients}
                        setCurrTab={setCurrTab}
                        setReload={setReload}
                        reload={reload}
                        summary={getSummary}
                    />
                );
        }
    };
    const closeSnackbar = () => setSnackbarOptions(options.DEFAULT);

    return (
        <div className="dashboard broadCast">
            <SnackbarAlert options={snackbarOptions} handleClose={closeSnackbar} />
            <Container maxWidth="xl" style={{ marginTop: '60px' }}>
                <div style={{ display: 'flex', alignItems: 'center', flexWrap: 'wrap' }}>
                    <div style={{ flex: '80%' }}>
                        <div className="headinglabel">{SCREEN_TITLES.BROADCAST_MANAGEMENT}</div>
                        <StatusCard
                            type="feature"
                            totalClients={[
                                {
                                    count: state?.[STORE_KEYS.BC_SMRY]?.body?.data?.[0]?.TotalBroadcasts
                                        ? state?.[STORE_KEYS.BC_SMRY]?.body?.data?.[0]?.TotalBroadcasts
                                        : 0,
                                    handleClick: () => { },
                                    image: BroadCastIcon,
                                    text: 'Total Broadcasts'
                                },
                                {
                                    count: state?.[STORE_KEYS.BC_SMRY]?.body?.data?.[0]?.UpcomingBroadcasts
                                        ? state?.[STORE_KEYS.BC_SMRY]?.body?.data?.[0]?.UpcomingBroadcasts
                                        : 0,
                                    handleClick: () => { },
                                    image: BroadCastIcon,
                                    text: 'Upcoming Broadcasts'
                                }
                            ]}
                        />
                    </div>
                    {userRole === "Y" ?
                    <div
                        style={{
                            flex: '10%',
                            marginLeft: '20px',
                            marginTop: '50px',
                            display: 'flex',
                            justifyContent: 'flex-end'
                        }}
                        className="dashboardDropdownDiv"
                    >
                        <ACGButton
                            name={'Schedule Broadcast'}
                            handleClick={() => {
                                setClickSchedule(true);
                                getBcClients();
                            }}
                            className={'scheduleBtn'}
                            type="button"
                            variant="contained"
                            style={{
                                backgroundColor: '#5D97F6',
                                color: 'black',
                                fontSize: '12px',
                                width: '150px',
                                height: '45px',
                                textTransform: 'capitalize',
                                letterSpacing: '0.2px',
                                fontFamily: 'Montserrat',
                                outline: 'none',
                                fontWeight: 'bold'
                            }}
                        />
                    </div>
                    : null}
                </div>

                <div style={{ marginTop: '35px', zIndex: '0' }}>
                    <MapCustomTabs
                        navigateTab={navigateTab}
                        currTab={currTab}
                        setCurrTab={setCurrTab}
                        tabsArray={tabs}
                        className="tabs"
                        position="relative"
                        type="nonCurve"
                    />
                </div>
            </Container>
            <RightDrawer
                handlerOpen={() => { }}
                handlerClose={() => setCreateDrawerOpen(false)}
                status={createDrawerOpen}
                drawerHeader={'Schedule Broadcast'}
                children={
                    <>
                        <ScheduleBroadcast
                            drawerClose={() => setCreateDrawerOpen(false)}
                            setCurrTab={setCurrTab}
                            setReload={setReload}
                            setSnackbarOpts={setSnackbarOptions}
                        />
                    </>
                }
            />
        </div>
    );
};

export default BCManagement;
