import { useState, useEffect } from 'react';
import Box from '@mui/material/Box';
import Label from '../../../../atoms/Label/index';
import TextInput from '../../../../atoms/TextInput';
import DropDown from '../../../../atoms/Dropdown';
import InputLabel from '@mui/material/InputLabel';
import MenuItem from '@mui/material/MenuItem';
import FormControl from '@mui/material/FormControl';
import Radio from '@mui/material/Radio';
import RadioGroup from '@mui/material/RadioGroup';
import FormControlLabel from '@mui/material/FormControlLabel';
import Container from '@mui/material/Container';
import ACGButton from '../../../../atoms/Button';
import useGetState from '../../../../utils/hooks/useGetState';
import { ACTION_CODES, STORE_KEYS, BASEURL } from '../../../../constants/apiConstants';
import notificationService from '../../notificationService';
import "../../cm.scss";
import { Grid } from '@mui/material';

export default function CreateForm(props: any) {
  const [option, setOption] = useState('1');

  const handleChange = (event: any) => {
    setOption(event.target.value);
    props.handleCancelAck(props.selectedRow?.CondAlertKey);
  };
  console.log("==>",props.ackSelectedNotification.find((i: any) => props.selectedRow?.CondAlertKey === i.id)?.value)
  const renderButtons = () => {
    return (
      <div className={'btns noWrap'} style={{ display: "flex", justifyContent: "flex-end", width: "100%" }}>
        <ACGButton
          name={'Cancel'}
          handleClick={props.drawerClose}
          secondary={true}
          className={'first-btn'}
          type="button"
          variant="contained"
        />
        <ACGButton
          name={'Submit'}
          handleClick={() => { props.handleSaveAck(props.selectedRow?.CondAlertKey, props.selectedRow?.AlertType) }}
          type="submit"
          className="second-btn"
          variant="contained"
          disabled={option === '1' && (props.ackSelectedNotification.find((i: any) => props.selectedRow?.CondAlertKey === i.id)?.value === "" || props.ackSelectedNotification.find((i: any) => props.selectedRow?.CondAlertKey === i.id)?.value === undefined ? true : false)?true:false}
        />
      </div>
    );
  };

  return (
    <Container className="createReport">
      <Box>
        <div >
          <FormControl fullWidth>
            <Label label="Select Acknowlegment type" className={'CMlabelDiv'} />
            <RadioGroup
              row
              aria-labelledby="demo-row-radio-buttons-group-label"
              name="row-radio-buttons-group" className='radiolabls'
            >
              <FormControlLabel value="1" control={<Radio onChange={handleChange} checked={option === '1' ? true : false} />} label="Accept" />
              <FormControlLabel value="2" control={<Radio onChange={handleChange} checked={option === '2' ? true : false} />} label="Ignore" />
            </RadioGroup>
         
            <Label label= { option === '1' ? "Enter Action Taken *" : "Enter Remarks"} className={'CMlabelDiv'} />
            <TextInput 
              id="outlined-basic"
            autoComplete='off'
              fullWidth
              variant="outlined"
              onChange={(e: any) => props.handleChangeAck(e, props.selectedRow?.CondAlertKey, option)}
              placeholder={ option === '1' ? "Enter Action Taken" : "Enter Remarks"}
              value={props.ackSelectedNotification?.find((i: any) => props.selectedRow?.CondAlertKey === i.id) ? props.ackSelectedNotification.find((i: any) => props.selectedRow?.CondAlertKey === i.id)?.value : ""}
            />

          </FormControl>
        </div>
      </Box>
      <Grid style={{ display: "flex", marginTop: 20 }}>
        {renderButtons()}
      </Grid>

    </Container>
  );
}