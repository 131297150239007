import { useState, useEffect, useRef } from 'react';
import { useHistory } from "react-router-dom";
import { useDispatch } from 'react-redux';
import { Container } from '@mui/material';
import MapCustomTabs from '../../../../app/atoms/ButtonTabs/MapCustomTabs';
import { applySortFilter } from '../../../utils/helper';
import { ACTION_CODES, STORE_KEYS, BASEURL } from '../../../../app/constants/apiConstants';
import Axios from 'axios';
import CustomDatePicker from '../../../atoms/CustomDatePicker';
import useGetState from '../../../../app/utils/hooks/useGetState';
import { _noop } from '../../../../app/utils/common';
import '../../Admin/ClientManagement/index.scss';
import RightDrawer from '../../../../app/atoms/tempDrawer/indexAlt';
import Breadcrumb from '../../../atoms/BreadCrumb';
import Logs from './Logs/Config';
import PMECFilter from './Logs/CreateConfig';
import SnackbarAlert from '../../../atoms/Snackbar';
import DropDown from '../../../atoms/Dropdown';
import RadioSelectionComponent from './GraphOEE/radioselection';
import { Chart, registerables, ArcElement } from "chart.js";
import TrendLine from './GraphOEE/trendLine';
import moment from 'moment';
import ACGButton from '../../../atoms/Button';
import AIInsights from '../OEEInsights';
import RefreshIcon from '@mui/icons-material/Refresh';
import html2canvas from 'html2canvas';
import { jsPDF } from 'jspdf';

Chart.register(...registerables);
Chart.register(ArcElement);
const tabs = ['Machine List'];
const options = {
    DEFAULT: {
        message: '',
        open: false,
        type: ''
    }
};
const SystemConfiguration = () => {
    const history = useHistory();
    const [currTab, setTabChange] = useState(0);
    const [radioValue, setRadioValue] = useState('All');
    const [drawerHeader, setDrawerHeader] = useState('AI');
    const [clientList, setClientList] = useState<any[]>([]);
    const [createValue, setCreateValue] = useState('');
    const [createDrawerOpen, setCreateDrawerOpen] = useState(false);
    const [snackbarOptions, setSnackbarOptions] = useState(options.DEFAULT);
    const [reload, setReload] = useState(false);
    const [dtlData, setDtlData] = useState<any>({});
    const [selectedRow, setSelectedRow] = useState<any>();
    const [customerKey, setCustomerKey] = useState("");
    const [customersData, setCustomersData] = useState<any[]>([]);
    const [presetKey, setPresetKey] = useState('');
    const [deleteModalOpen, setDeleteModalOpen] = useState(false);
    const [deleteGrp, setDeleteGrp] = useState(true);
    const [deletePresetAlert] = useState(true);
    const date = new Date();
    date.setDate(date.getDate() - 14)
    const [inDate, setInDate] = useState(date);
    const d = new Date();
    const [endDate, setEndDate] = useState(new Date());
    const [defaultStartDate, setDefultStartDate] = useState(date);
    const [defaultEndDate, setDefaultEndDate] = useState(new Date());
    const [errorDate, setErrorFalse] = useState(false);
    const [plantsData, setPlantsData] = useState<any[]>([]);
    const [_machines, set_Machines] = useState<any>([]);
    const [selectedMachineModel, setSelectedMachineModel] = useState<any>(11);
    const [_selectedMachineModel, setSelected_MachineModel] = useState<any>('');
    const [plantKeys, setPlantKeys] = useState([]);
    const [alertsData, setAlertsData] = useState([]);
    const [appliedFilters, setAppliedFilters] = useState(false);
    const [machineModel, setMachineModel] = useState<any>([]);
    const [machines, setMachines] = useState<any>([]);
    const [selectedMachine, setSelectedMachine] = useState('');
    const [selectedMachines, setSelectedMachines] = useState<any>([]);
    const dispatch = useDispatch();
    const state = useGetState();
    const token = state?.[STORE_KEYS.USER_DTL].token;
    const loggedInUser = state[STORE_KEYS.USER_DTL]?.LoggedUserKey;
    const AIInsightsShow = state[STORE_KEYS.USER_DTL]?.AIInsightVisibleFlag;
    const userEmail = state[STORE_KEYS.USER_DTL]?.EmailId;
    const [products, setProducts] = useState<any[]>([]);
    const [operators, setOperators] = useState<any[]>([]);
    const [selectedOP, setSelectedOP] = useState<any>([]);
    const [selectedOption, setSelectedOption] = useState('option1');
    const [oeeMaster, setOEEMaster] = useState<any>([]);
    const [oeeDetails, setOEEDetails] = useState<any>([{ OEE: 0, Availability: 0, Performance: 0, Quality: 0 }]);
    const [OPData, setOPData] = useState<any>([]);
    const [selectAllOP, setSelectAllOP] = useState<boolean>(false);
    const [selectedPlant, setSelectedPlant] = useState<any>(null);
    const [aIInsightsList, setAIInsightsList] = useState<any>([]);
    const [selectedInsight, setSelectedInsight] = useState<any>('');
    const [right, setRight] = useState(false);
    const [defaultData, setDefaultData] = useState<any>([]);
    const [showLoader, setShowLoader] = useState(false);

    const componentRef = useRef<any>();

    const getCreateValue = (ele: string) => {
        setSelectedInsight(aIInsightsList[0]?.code);
        setDrawerHeader('AI');
        setCreateValue("Insights");
        setCreateDrawerOpen(true);
    };

    useEffect(() => {
        if (oeeMaster.length > 0) {
            const filteredList = applySortFilter(oeeMaster, "asc", "", "OEEPer", true);
            setSelectedMachine(filteredList[0]?.MachineKey);
        }
    }, [oeeMaster])

    useEffect(() => {
        if (selectedMachine !== "") {
            getOEEDetails(selectedMachine);
            fetchOP(selectedMachine)
        }

    }, [selectedMachine])

    const changeOP = (type: any) => {
        setSelectedOP([]);
        setSelectedOption(type);
        setOPData([]);
    }

    const handleChange = async (data: any) => {
        setSelectedOP([]);
        setOPData([]);
        setSelectedMachine(data);
        setSelectedOption('option1');
    };

    useEffect(() => {
        handleSelectAll();
    }, [operators, products, selectedOption]);

    const handleCheckboxChange = (id: any) => {
        let data: any;
        setSelectedOP((prevSelected: any) => {
            if (prevSelected.includes(id)) {
                data = prevSelected.filter((optionId: any) => optionId !== id);
                return prevSelected.filter((optionId: any) => optionId !== id);
            } else {
                data = [...prevSelected, id];
                return [...prevSelected, id];
            }
        });
        getOPSummary(data, selectedOption);
    };

    const handleSelectAll = () => {
        if (selectedOption === "option1") {
            if (selectedOP.length === operators.length) {
                setSelectedOP([]);
            } else {
                const data = operators.map(option => option.OperatorKey)
                getOPSummary(data, selectedOption);
                setSelectedOP(data);
            }
        } else {
            if (selectedOP.length === products.length) {
                setSelectedOP([]);
            } else {
                const data = products.map(option => option.ProductKey)
                getOPSummary(data, selectedOption);
                setSelectedOP(data);
            }
        }
    };

    const getOEEDetails = async (mk: any) => {
        const payload = {
            machineKey: mk,
            from: inDate,
            to: endDate
        };
        await Axios({
            url: BASEURL + '/' + ACTION_CODES.API_PMEC_GET_OEE_DETAILS,
            method: 'post',
            data: payload,
            headers: {
                Authorization: 'Bearer ' + token
            }
        })
            .then((response) => {
                if (response.status == 200 && response?.data) {
                    const result = response?.data?.message;
                    const tmpTableData = result[0][0];
                    setOEEDetails(tmpTableData);
                }
            })
            .catch((error) => {
                if (window.newrelic) {
                    window.newrelic.noticeError(error?.message);
                }
                return {
                    status: error
                };
            });
    }

    const getOPSummary = async (op: any, optype: any) => {
        if (selectedMachine !== "") {
            const opURL = optype === "option1" ? ACTION_CODES.API_PMEC_GET_OPERATORS_SUMMARY : ACTION_CODES.API_PMEC_GET_PRODUCTS_SUMMARY;

            const payload = {
                machineKey: selectedMachine,
                from: inDate,
                to: endDate,
                op: op.toString()
            };
            await Axios({
                url: BASEURL + '/' + opURL,
                method: 'post',
                data: payload,
                headers: {
                    Authorization: 'Bearer ' + token
                }
            })
                .then((response) => {
                    if (response.status == 200 && response?.data) {
                        const result = response?.data?.message;
                        const tmpTableData = result[0][0];
                        setOPData(tmpTableData);
                    }
                })
                .catch((error) => {
                    if (window.newrelic) {
                        window.newrelic.noticeError(error?.message);
                    }
                    return {
                        status: error
                    };
                });
        }
    }

    const setCurrTab = (val: number) => {
        setTabChange(val);
        if (radioValue == 'All' && currTab === 1) {
            setRadioValue('All');
        }
    };

    useEffect(() => {
        window.newrelic.setCustomAttribute('LoggedInUser', userEmail);
        window.newrelic.addPageAction('OEE-Dashboard', { action: 'Landed on OEE Dashboard page' });
    }, [])

    useEffect(() => {
        if (selectedMachineModel !== "" && selectedPlant) {
            fetchMachines(machineModel);
            fetchOEEMaster(inDate, endDate, selectedMachineModel);
        }
    }, [inDate, endDate, selectedMachineModel, selectedPlant])

    useEffect(() => {
        (async () => {
            const payload = {
                userId: loggedInUser,
            };
            await Axios({
                url: BASEURL + '/' + ACTION_CODES.API_PMEC_GET_PLANTS,
                method: 'post',
                data: payload,
                headers: {
                    Authorization: 'Bearer ' + token
                }
            })
                .then((response) => {
                    if (response.status == 200 && response?.data) {
                        const result = response?.data?.message;
                        const tmpTableData = result[0][0];
                        setPlantsData([
                            ...tmpTableData?.map((item: any) => ({ code: item.PlantKey, name: item.PlantName }))])
                        setSelectedPlant(tmpTableData[0]?.PlantKey)
                    }
                })
                .catch((error) => {
                    if (window.newrelic) {
                        window.newrelic.noticeError(error?.message);
                    }
                    return {
                        status: error.response.status
                    };
                });

        })();
    }, []);

    const fetchAIInsightsList = async (model: any) => {
        const payload = {
            loggedInUserKey: loggedInUser,
            machineModelKey: model,
            plantKey: 'All'
        };
        await Axios({
            url: BASEURL + '/' + ACTION_CODES.API_PMEC_GET_AI_INSIGHTS,
            method: 'post',
            data: payload,
            headers: {
                Authorization: 'Bearer ' + token
            }
        })
            .then(async (response) => {
                if (response.status == 200 && response?.data) {
                    const result = response?.data?.message;
                    const tmpTableData = result[0][0];
                    setAIInsightsList([
                        ...tmpTableData?.map((item: any) => ({ code: item.PromptCode, name: item.PromptName }))])

                }
            })
            .catch((error) => {
                if (window.newrelic) {
                    window.newrelic.noticeError(error?.message);
                }
                return {
                    status: error
                };
            });
    }

    useEffect(() => {
        if (selectedPlant) {
            const payload = {
                userId: loggedInUser,
                plantKey: selectedPlant
            };

            (async () => {
                await Axios({
                    url: BASEURL + '/' + ACTION_CODES.API_PMEC_GET_MACHINE_MODEL,
                    method: 'post',
                    data: payload,
                    headers: {
                        Authorization: 'Bearer ' + token
                    }
                })
                    .then((response) => {
                        if (response.status == 200 && response?.data) {
                            const result = response?.data?.message;
                            const tmpTableData: any = result[0][0];
                            setMachineModel([
                                ...tmpTableData?.map((item: any) => ({ code: item.MachineModelKey, name: item.MachineModelName }))])
                            setSelectedMachineModel(tmpTableData[0]?.MachineModelKey)
                            fetchAIInsightsList(tmpTableData[0]?.MachineModelKey);
                        }
                    })
                    .catch((error) => {
                        if (window.newrelic) {
                            window.newrelic.noticeError(error?.message);
                        }
                        return {
                            status: error.response.status
                        };
                    });
            })();

        }
    }, [selectedPlant])

    const handleChangePlant = (event: any) => {
        setSelectedOption('option1');
        window.newrelic.addPageAction('OEE-Dashboard', { action: 'Plant name changed', additionalInfo: `Plant Name changed to ${event.target.value}` });
        setSelectedPlant(event.target.value);
        setSelectedMachineModel('');
    };

    const fetchOEEMaster = async (sd: any, ed: any, mdl: any) => {
        const payload = {
            userId: loggedInUser,
            model: mdl,
            from: sd,
            to: ed,
            plantKey: selectedPlant
        };
        await Axios({
            url: BASEURL + '/' + ACTION_CODES.API_PMEC_GET_OEE_MASTER,
            method: 'post',
            data: payload,
            headers: {
                Authorization: 'Bearer ' + token
            }
        })
            .then((response) => {
                if (response.status == 200 && response?.data) {
                    const result = response?.data?.message;
                    const tmpTableData = result[0][0];
                    setOEEMaster(tmpTableData);
                }
            })
            .catch((error) => {
                if (window.newrelic) {
                    window.newrelic.noticeError(error?.message);
                }
                return {
                    status: error
                };
            });
    }

    const fetchMachines = async (model: any) => {
        const payload = {
            userId: loggedInUser,
            model: selectedMachineModel,
            plantKey: selectedPlant
        };
        await Axios({
            url: BASEURL + '/' + ACTION_CODES.API_PMEC_GET_MACHINES,
            method: 'post',
            data: payload,
            headers: {
                Authorization: 'Bearer ' + token
            }
        })
            .then((response) => {
                if (response.status == 200 && response?.data) {
                    const result = response?.data?.message;
                    const tmpTableData = result[0][0];
                    setMachines([
                        ...tmpTableData?.map((item: any) => ({ code: item.MachineKey, name: item.MachineName }))])
                }
            })
            .catch((error) => {
                if (window.newrelic) {
                    window.newrelic.noticeError(error?.message);
                }
                return {
                    status: error
                };
            });
    }

    const fetchOP = async (mKey: any) => {
        const payload = {
            machineKey: mKey,
            from: inDate,
            to: endDate
        };
        await Axios({
            url: BASEURL + '/' + ACTION_CODES.API_PMEC_GET_PRODUCTS,
            method: 'post',
            data: payload,
            headers: {
                Authorization: 'Bearer ' + token
            }
        })
            .then(async (response) => {
                if (response.status == 200 && response?.data) {
                    const result = response?.data?.message;
                    const tmpTableData = await result[0][0];
                    setProducts(tmpTableData);
                }
            })
            .catch((error) => {
                if (window.newrelic) {
                    window.newrelic.noticeError(error?.message);
                }
                return {
                    status: error.response.status
                };
            });
        await Axios({
            url: BASEURL + '/' + ACTION_CODES.API_PMEC_GET_OPERATORS,
            method: 'post',
            data: payload,
            headers: {
                Authorization: 'Bearer ' + token
            }
        })
            .then(async (response) => {
                if (response.status == 200 && response?.data) {
                    const result = response?.data?.message;
                    const tmpTableData = await result[0][0];
                    setOperators(tmpTableData);
                }
            })
            .catch((error) => {
                if (window.newrelic) {
                    window.newrelic.noticeError(error?.message);
                }
                return {
                    status: error.response.status
                };
            });
    }

    const ApplyFilters = (flag: boolean) => {
        fetchMachines(machineModel);
        fetchOEEMaster(inDate, endDate, selectedMachineModel);
    }

    const clearFilter: any = async () => {
        setAppliedFilters(false);
        const date = new Date();
        date.setDate(date.getDate() - 6)
        setInDate(date);
        setEndDate(new Date());
        setMachines([]);
        setSelectedMachineModel('11');
        setSelectedMachine('');
        setSelectedOP([]);
        setOEEDetails([{ OEE: 0, Avaialability: 0, Performance: 0, Quality: 0 }]);
        setPlantKeys([]);
        setCustomerKey('');
        ApplyFilters(false);
        fetchOEEMaster(date, new Date(), 11);
    }

    const refreshInights = async (isFilter = false) => {
        setDefaultData([]);
        // setShowLoader(true);

        const formData = new FormData();
        formData.append("insight_category", selectedInsight);
        await Axios({
            url: BASEURL + '/genaiapp/chat/create_thread',
            method: 'post',
            data: formData,
            headers: { "Content-Type": "multipart/form-data" }
        })
            .then(async (response) => {
                if (response.status == 200) {
                    // setShowLoader(false);
                    const aiData: any = [];
                    aiData['isUser'] = false;
                    const obj = { [selectedInsight]: [] }
                    const isCategoryPresent = defaultData.some((item: any) => item.hasOwnProperty(selectedInsight));
                    if (!isCategoryPresent) {
                        setDefaultData([...defaultData, obj]);
                    } else {
                        setDefaultData([obj]);
                    }

                }
            })
            .catch((error) => {
                if (window.newrelic) {
                    window.newrelic.noticeError(error?.message);
                }
                return {
                    status: error
                };
            });
    };

    const handleDownloadPdf = async () => {
        const element = componentRef.current;

        // Style adjustments for PDF rendering
        const originalFontSize = element.style.fontSize;
        const originalHeight = element.style.height;
        const originalOverflow = element.style.overflow;
        element.style.fontSize = '8px';
        element.style.height = `${element.scrollHeight}px`;
        element.style.overflow = 'unset';

        // Convert each image to base64
        const images = element.querySelectorAll('img');
        await Promise.all(Array.from(images).map(async (img: any) => {
            if (img.src.includes('172.16.123.78:7010')) {
                img.src = img.src.replace('http://172.16.123.78:7010', window.location.origin);
            }
            if (!img.complete) {
                await new Promise((resolve) => {
                    img.onload = resolve;
                    img.onerror = resolve;
                });
            }
        }));

        try {
            // Generate PDF with html2canvas
            const canvas = await html2canvas(element, {
                backgroundColor: null,
                scale: 2,
                scrollX: 0,
                scrollY: 0,
                useCORS: true,
                allowTaint: false,
            });

            const imageData = canvas.toDataURL('image/png');
            const pdf = new jsPDF('p', 'mm', 'a4');

            const downloadDate = new Date().toLocaleString('en-GB');
            const fromDate = moment(inDate).format('DD/MM/YYYY');
            const toDate = moment(endDate).format('DD/MM/YYYY');
            const model = machineModel.filter((el: any) => el.code === _selectedMachineModel);
            const machinesName = _machines
                .filter((machine: any) => selectedMachines.includes(machine?.code))
                .map((machine: any) => machine?.name)
                .join(', ');;

            // Set font size for the header
            pdf.setFontSize(10);

            // Add text for Download Date, From Date, To Date, Model
            pdf.text(`Download Date & Time: ${downloadDate}`, 10, 10);
            pdf.text(`From Date: ${fromDate ?? "-"}`, 10, 15);
            pdf.text(`To Date: ${toDate ?? "-"}`, 10, 20);
            pdf.text(`Model: ${model[0]?.name ?? "-"}`, 10, 25);

            // Split the long Machines string to fit within page width
            const machineText = `Machines: ${machinesName ?? "-"}`;
            const margin = 10;
            const pageWidth = pdf.internal.pageSize.getWidth();
            const textWidth = pageWidth - 2 * margin;  // Account for margin on both sides

            const machineLines = pdf.splitTextToSize(machineText, textWidth);

            // Add machines text to the PDF with wrapping
            let currentY = 30;
            machineLines.forEach((line: any) => {
                pdf.text(line, margin, currentY);
                currentY += 5;  // Adjust line height as necessary
            });

            const pageHeight = pdf.internal.pageSize.getHeight();

            let imgHeight = (canvas.height * pageWidth) / canvas.width;
            let position = 0;

            while (position < imgHeight) {
                pdf.addImage(imageData, 'PNG', 0, -position + currentY, pageWidth, imgHeight);
                position += pageHeight;
                if (position < imgHeight) {
                    pdf.addPage();
                }
            }

            const timestamp = new Date().toISOString().replace(/[:.]/g, '-');
            pdf.save(`AI-insights_${timestamp}.pdf`);

        } finally {
            // Restore original styles
            element.style.fontSize = originalFontSize;
            element.style.height = originalHeight;
            element.style.overflow = originalOverflow;
        }
    };

    const navigateTab = () => {
        switch (currTab) {

            case 0:
                return (
                    <Logs
                        oeeMaster={oeeMaster}
                        selectedMachineModel={selectedMachineModel}
                        presetKey={presetKey}
                        setPresetKey={setPresetKey}
                        deleteModalOpen={deleteModalOpen}
                        deleteGrp={deleteGrp}
                        deleteKey={presetKey}
                        deletePresetAlert={deletePresetAlert}
                        setDtlData={setDtlData}
                        dtlData={dtlData}
                        selectedRow={selectedRow}
                        setSelectedRow={() => setSelectedRow}
                        setCreateDrawerOpen={setCreateDrawerOpen}
                        setCreateValue={setCreateValue}
                        setDrawerHeader={setDrawerHeader}
                        setSnackbarOpts={setSnackbarOptions}

                        appliedFilters={appliedFilters}
                        clearFilter={clearFilter}
                        setSelectedMachineModel={setSelectedMachineModel}
                        setCustomerKey={setCustomerKey}
                        setInDate={setInDate}
                        setEndDate={setEndDate}
                        inDate={inDate}
                        endDate={endDate}
                    />
                )

            default:
                return (
                    <Logs
                        alertsData={alertsData}

                        presetKey={presetKey}
                        setPresetKey={setPresetKey}
                        deleteModalOpen={deleteModalOpen}
                        deleteGrp={deleteGrp}
                        deleteKey={presetKey}
                        deletePresetAlert={deletePresetAlert}
                        setDtlData={setDtlData}
                        dtlData={dtlData}
                        selectedRow={selectedRow}
                        setSelectedRow={() => setSelectedRow}
                        setCreateDrawerOpen={setCreateDrawerOpen}
                        setCreateValue={setCreateValue}
                        setDrawerHeader={setDrawerHeader}
                        setSnackbarOpts={setSnackbarOptions}

                        appliedFilters={appliedFilters}
                        clearFilter={clearFilter}
                        setSelectedMachineModel={setSelectedMachineModel}
                        setCustomerKey={setCustomerKey}
                        setInDate={setInDate}
                        setEndDate={setEndDate}
                    />
                );
        }
    };

    const handleMachineModel = (event: any) => {
        setSelectedOption('option1');
        window.newrelic.addPageAction('OEE-Dashboard', { action: 'Machine Model changed', additionalInfo: `Machine Model to ${event.target.value}` });
        setSelectedMachineModel(event.target.value);
        setSelectAllOP(false);
        setOPData([]);
        setSelectedOP([]);
        setSelectedMachine('');
        setOEEDetails([{ OEE: 0, Availability: 0, Performance: 0, Quality: 0 }]);
    }

    const closeSnackbar = () => setSnackbarOptions(options.DEFAULT);

    const breadcrumbData = [
        { label: "Home", url: "/oee-dashboard" },
        {
            label: "Production Dashboard",
            url: `/oee-dashboard/kpi?mdk=${selectedMachineModel}&mk=${oeeMaster[0]?.MachineKey}&pk=${selectedPlant}&sd=${moment.utc(defaultStartDate).format("MM/DD/YYYY")}&ed=${moment.utc(defaultEndDate).format("MM/DD/YYYY")}`
        },
        {
            label: "OEE Downtime",
            url: `/oee-dashboard-downtime/kpi?mdk=${selectedMachineModel}&mk=${oeeMaster[0]?.MachineKey}&pk=${selectedPlant}&sd=${moment.utc(defaultStartDate).format("MM/DD/YYYY")}&ed=${moment.utc(defaultEndDate).format("MM/DD/YYYY")}`
        },
    ];

    return (
        <div className="dashboard PMEC-Dash">
            <SnackbarAlert options={snackbarOptions} handleClose={closeSnackbar} />
            <Container maxWidth="xl" style={{ marginTop: '60px' }}>
                <div style={{
                    display: "flex",
                    width: "100%",
                    justifyContent: "space-between",
                    alignItems: "center"
                }}>
                    <div className="headinglabel mb10" style={{ marginBottom: '15px' }}>
                        OEE Dashboard
                    </div>
                    {AIInsightsShow === "Y" && <ACGButton name="AI Insights" disabled={machineModel === "" || aIInsightsList.length === 0} className='AiInsightsBtn' onClick={(e: any) => { getCreateValue(e) }} />}
                </div>
                <Breadcrumb
                    disabled={oeeMaster.length === 0} data={breadcrumbData} />
                <div className='filterdiv'>
                    <div className='width_filter'>
                        <div className='lableTitle'>From</div>
                        <CustomDatePicker
                            name="dateFromSelect"
                            value={inDate}
                            maxDate={new Date()}
                            className="inputDiv"
                            onChange={(val: any) => {
                                setSelectedOption('option1');
                                setInDate(val);
                                setOEEDetails([{ OEE: 0, Availability: 0, Performance: 0, Quality: 0 }]);
                                setSelectedMachine('');
                                setSelectedOP([]);
                                setOPData([]);
                                window.newrelic.addPageAction('OEE-Dashboard', { action: 'From date changed', additionalInfo: `From Date changed to ${val}` });
                            }}
                            inputFormat="dd/MM/yyyy"
                            placeholder="Select From Date"
                            minDate={new Date("01-01-2023")}

                        />
                    </div>
                    <div className='width_filter'>
                        <div className='lableTitle'>To</div>
                        <CustomDatePicker
                            name="dateFromSelect"
                            value={endDate}
                            minDate={inDate}
                            maxDate={new Date()}
                            className="inputDiv"
                            onChange={(val: any) => {
                                setSelectedOption('option1');
                                setEndDate(val);
                                setOEEDetails([{ OEE: 0, Availability: 0, Performance: 0, Quality: 0 }]);
                                setSelectedMachine('')
                                setSelectedOP([]);
                                setOPData([]);
                                window.newrelic.addPageAction('OEE-Dashboard', { action: 'To date changed', additionalInfo: `To Date changed to ${val}` });
                            }}
                            inputFormat="dd/MM/yyyy"
                            placeholder="Select End Date"
                        />
                    </div>
                    <div className='width_filter'>
                        <div className='lableTitle'>Select Plant</div>
                        <DropDown
                            onChange={handleChangePlant}
                            className="inputDiv dropdownbx longtxt padingLR"
                            name="dropdownModel"
                            items={plantsData}
                            placeHolder="Select Plant"
                            value={selectedPlant}
                        />
                    </div>
                    <div className='width_filter'>
                        <div className='lableTitle'>Select Machine Model</div>
                        <DropDown
                            onChange={handleMachineModel}
                            className="inputDiv dropdownbx padingLR"
                            name="dropdownModel"
                            items={machineModel}
                            placeHolder="Select Machine Model"
                            value={selectedMachineModel}
                        />
                    </div>
                </div>
                <div style={{ marginTop: '10px', zIndex: '0', position: 'relative' }}>
                    <MapCustomTabs
                        navigateTab={navigateTab}
                        currTab={currTab}
                        setCurrTab={setCurrTab}
                        tabsArray={tabs}
                        className="tabs"
                        position="relative"
                    // type="nonCurve"
                    />
                </div>
                <div className='filterdiv mt10'>
                    <div className='width_filter widthIncrease'>
                        <DropDown
                            onChange={(e: any) => handleChange(e.target.value)}
                            className="inputDiv dropdownbx longtxt"
                            name="dropdownModel"
                            items={machines}
                            placeHolder="Select Machine Serial No."
                            value={selectedMachine}
                        />
                    </div>
                    <div className='width_filter'>

                    </div>
                    <div className='width_filter'>

                    </div>
                </div>
                <div className='graphcontainer pmec_dashgraph OEEhomeCls'>
                    <div className='leftgraph'>
                        <div className='comnclsMachineSr machine-srfiler'>
                            <div className='titleGraph'>OEE Parameters</div>
                        </div>
                        <div className='oeeParametersGraph'>
                            <TrendLine oeeDetails={oeeDetails} style={{ width: "100%" }} />
                        </div>
                    </div>
                    <div className='rightgraph'>
                        <div className='titleGraph'>Production</div>
                        <RadioSelectionComponent
                            selectedOption={selectedOption}
                            changeOP={(t: any) => changeOP(t)}
                            handleCheckboxChange={(id: any) => handleCheckboxChange(id)}
                            handleSelectAll={handleSelectAll}
                            operators={operators}
                            products={products}
                            selectedOP={selectedOP}
                            setSelectedOP={setSelectedOP}
                            selectedMachine={selectedMachine}
                            OPData={OPData}
                            selectAllOP={selectAllOP}
                            setSelectAllOP={setSelectAllOP}
                            setOPData={setOPData}
                            selectedMachineModel={selectedMachineModel}
                        />
                    </div>
                </div>
            </Container>
            <RightDrawer
                alertType={createValue}
                handlerOpen={() => { }}
                handlerClose={() => { setCreateDrawerOpen(false); setDrawerHeader('AI'); setSelectedInsight(''); setDefaultData([]); }}
                status={createDrawerOpen}
                drawerHeader={`${drawerHeader} ${createValue}`}
                right={right}
                setRight={setRight}
                defaultData={defaultData}
                refreshInights={refreshInights}
                handleDownloadPdf={handleDownloadPdf}
                children={
                    drawerHeader === 'AI' && createValue === "Insights" ? (
                        <AIInsights
                            aIInsightsList={aIInsightsList}
                            machineModel={machineModel}
                            selectedInsight={selectedInsight}
                            setSelectedInsight={setSelectedInsight}
                            right={right}
                            defaultData={defaultData}
                            setDefaultData={setDefaultData}
                            showLoader={showLoader}
                            setShowLoader={setShowLoader}
                            handleDownloadPdf={handleDownloadPdf}
                            componentRef={componentRef}
                            selectedMachineModel={_selectedMachineModel}
                            setSelectedMachineModel={setSelected_MachineModel}
                            setSelectedMachines={setSelectedMachines}
                            selectedMachines={selectedMachines}
                            machines={_machines}
                            setMachines={set_Machines}
                            refreshInights={refreshInights}
                        />)
                        :
                        null
                }
            />
        </div>
    );
};

export default SystemConfiguration;
