import { useState, useEffect } from 'react';
import { useDispatch } from 'react-redux';
import { Container } from '@mui/material';
import MapCustomTabs from '../../../atoms/ButtonTabs/MapCustomTabs';
import StatusCard from '../../../atoms/DashboardStatus/StatusCard';
import Clients from './Clients';
import Plants from './Plants';
import TotalClientsIcon from '../../../../assets/images/TotalClients.svg';
import PlantIcon from '../../../../assets/images/dashboardicons/Plant.svg';
import MachineIcon from '../../../../assets/images/dashboardicons/Machines.svg';
import { ACTION_CODES, STORE_KEYS } from '../../../constants/apiConstants';
import { SCREEN_TITLES } from '../../../constants/StringConstants';
import { executeACGAction } from '../../../store/slice';
import useGetState from '../../../utils/hooks/useGetState';
import { _noop } from '../../../utils/common';
import DrawerDropdown from '../../../atoms/DrawerDropdown';
import RightDrawer from '../../../atoms/tempDrawer/indexAlt';
import CreateClient from './CreateClient';
import CreatePlant from './CreatePlant';
import EditPlant from './EditPlant';
import SnackbarAlert from '../../../atoms/Snackbar';
import './index.scss';

const tabs = ['Clients', 'Plants'];

const options = {
    DEFAULT: {
        message: '',
        open: false,
        type: ''
    }
};
const ClientManagement = () => {
    const [currTab, setCurrTab] = useState(0);
    const dispatch = useDispatch();
    const [createValue, setCreateValue] = useState('');
    const [createDrawerOpen, setCreateDrawerOpen] = useState(false);
    const [snackbarOptions, setSnackbarOptions] = useState(options.DEFAULT);
    const [reload, setReload] = useState(false);
    const [drawerHeader, setDrawerHeader] = useState('Create');
    const [dtlData, setDtlData] = useState<any>();
    const state = useGetState();
    const getCreateValue = (ele: string) => {
        setDrawerHeader('Create');
        setCreateValue(ele);
        setCreateDrawerOpen(true);
    };
    const getClientSummary = () => {
        dispatch(
            executeACGAction({
                payload: {
                    requestType: 'GET',
                    urlPath: ACTION_CODES.GET_CLIENT_SUMMARY
                },
                storeKey: STORE_KEYS.CLIENT_SUMMARY
            })
        );
    };

    useEffect(getClientSummary, []);

    const navigateTab = () => {
        switch (currTab) {
            case 1:
                return (
                    <Plants
                        getClientSummary={getClientSummary}
                        dtlProps={{
                            setCreateValue: setCreateValue,
                            setCreateDrawerOpen: setCreateDrawerOpen,
                            drawerHeader: drawerHeader,
                            setDrawerHeader: setDrawerHeader,
                            dtlData: dtlData,
                            setDtlData: setDtlData
                        }}
                        setReload={setReload}
                        reload={reload}
                    />
                );
            default:
                return (
                    <Clients
                        getClientSummary={getClientSummary}
                        dtlProps={{
                            setCreateValue: setCreateValue,
                            setCreateDrawerOpen: setCreateDrawerOpen,
                            drawerHeader: drawerHeader,
                            setDrawerHeader: setDrawerHeader,
                            dtlData: dtlData,
                            setDtlData: setDtlData
                        }}
                    />
                );
        }
    };

    const {
        TotalClients = 0,
        TotalPlants = 0,
        TotalMachines = 0
    } = state[STORE_KEYS.CLIENT_SUMMARY]?.body?.data?.[0] || {};
    const closeSnackbar = () => setSnackbarOptions(options.DEFAULT);
    return (
        <div className="dashboard">
            <SnackbarAlert options={snackbarOptions} handleClose={closeSnackbar} />
            <Container maxWidth="xl" style={{ marginTop: '60px' }}>
                <div style={{ display: 'flex', alignItems: 'center', flexWrap: 'wrap' }}>
                    <div style={{ flex: '80%' }}>
                        <div className="headinglabel">{SCREEN_TITLES.CLIENT_MANAGEMENT}</div>
                        <StatusCard
                            totalClients={[
                                {
                                    count: TotalClients,
                                    handleClick: _noop,
                                    image: TotalClientsIcon,
                                    text: 'Total Clients'
                                },
                                {
                                    count: TotalPlants,
                                    handleClick: _noop,
                                    image: PlantIcon,
                                    text: 'Total Plants'
                                },
                                {
                                    count: TotalMachines,
                                    handleClick: _noop,
                                    image: MachineIcon,
                                    text: 'Total Machines'
                                }
                            ]}
                        />
                    </div>
                    <div
                        style={{
                            flex: '10%',
                            marginLeft: '20px',
                            marginTop: '60px',
                            display: 'flex',
                            justifyContent: 'flex-end'
                        }}
                        className="dashboardDropdownDiv"
                    >
                        <DrawerDropdown click={getCreateValue} items={['Client', 'Plant']} />
                    </div>
                </div>
                <div style={{ marginTop: '35px', zIndex: '0' }}>
                    <MapCustomTabs
                        navigateTab={navigateTab}
                        currTab={currTab}
                        setCurrTab={setCurrTab}
                        tabsArray={tabs}
                        className="tabs"
                        position="relative"
                    />
                </div>
                <RightDrawer
                    handlerOpen={() => { }}
                    handlerClose={() => setCreateDrawerOpen(false)}
                    status={createDrawerOpen}
                    drawerHeader={`${drawerHeader} ${createValue}`}
                    children={
                        drawerHeader === 'Create' ? (
                            createValue === 'Plant' ? (
                                <CreatePlant
                                    drawerClose={() => setCreateDrawerOpen(false)}
                                    setCurrTab={setCurrTab}
                                    setReload={setReload}
                                    setSnackbarOpts={setSnackbarOptions}
                                />
                            ) : (
                                <CreateClient
                                    drawerClose={() => setCreateDrawerOpen(false)}
                                    setCurrTab={setCurrTab}
                                    setReload={setReload}
                                    dtlData={null}
                                    setSnackbarOpts={setSnackbarOptions}
                                    isEdit={false}
                                />
                            )
                        ) : createValue === 'Plant' ? (
                            <EditPlant
                                drawerClose={() => setCreateDrawerOpen(false)}
                                setSnackbarOpts={setSnackbarOptions}
                                setReload={setReload}
                                dtlData={dtlData}
                                setCurrTab={setCurrTab}
                                setDtlData={setDtlData}
                            />
                        ) : (
                            <CreateClient
                                drawerClose={() => setCreateDrawerOpen(false)}
                                setCurrTab={setCurrTab}
                                setReload={setReload}
                                setSnackbarOpts={setSnackbarOptions}
                                dtlData={dtlData}
                                isEdit={true}
                            />
                        )
                    }
                />
            </Container>
        </div>
    );
};

export default ClientManagement;
