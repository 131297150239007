import { useState, useEffect, useRef } from 'react';
import Box from '@mui/material/Box';
import Tooltip from '@mui/material/Tooltip';
import Axios from 'axios';
import EditIcon from '@mui/icons-material/Edit';
import AddIcon from '@mui/icons-material/Add';
import DeleteIcon from '@mui/icons-material/Delete';
import RightDrawer from '../../../atoms/tempDrawer/indexAlt';
import { Typography } from '@mui/material';
import Label from '../../../atoms/Label/index';
import TextInput from '../../../atoms/TextInput';
import DownArrow from '../../../../assets/down-arrow.svg';
import DropDown from '../../../atoms/Dropdown';
import InputLabel from '@mui/material/InputLabel';
import CheckBoxOutlineBlankIcon from '@mui/icons-material/CheckBoxOutlineBlank';
import FailureIndicatorConfig from '../CreateFailureIndicatorConfig';
import { QueryBuilderMaterial } from '@react-querybuilder/material';
import CheckBoxIcon from '@mui/icons-material/CheckBox';
import { createTheme, ThemeProvider } from '@mui/material/styles';
import Checkbox from '@mui/material/Checkbox';
import OutlinedInput from '@mui/material/OutlinedInput';
import HelpIcon from '@mui/icons-material/Help';
import Autocomplete from '@mui/material/Autocomplete';
import Radio from '@mui/material/Radio';
import RadioGroup from '@mui/material/RadioGroup';
import FormControlLabel from '@mui/material/FormControlLabel';
import MenuItem from '@mui/material/MenuItem';
import FormControl from '@mui/material/FormControl';
import Select from '@mui/material/Select';
import ListItemText from '@mui/material/ListItemText';
import TextField from '@mui/material/TextField';
import Stack from '@mui/material/Stack';
import Button from '@mui/material/Button';
import Container from '@mui/material/Container';
import ACGButton from '../../../atoms/Button';
import useGetState from '../../../utils/hooks/useGetState';
import { ACTION_CODES, STORE_KEYS, BASEURL } from '../../../constants/apiConstants';
import FMEAService from '../fmeaServices';
import "../cm.scss";
import "./style.css";
import QueryBuilder, { defaultOperators, RuleValidator, ValidationResult, formatQuery } from 'react-querybuilder';
import { Grid } from '@mui/material';
import { AnyARecord } from 'dns';
import { fontWeight } from '@mui/system';
import { set } from 'date-fns';

const ITEM_HEIGHT = 48;
const ITEM_PADDING_TOP = 8;
const MenuProps = {
  PaperProps: {
    style: {
      maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
      width: 250,
    },
  },
};
const muiTheme = createTheme();
const icon = <CheckBoxOutlineBlankIcon fontSize="small" />;
const checkedIcon = <CheckBoxIcon fontSize="small" />;

export default function CreateForm(props: any) {
  const [notificationNames, setNotificationNames] = useState<any>([]);
  const ref = useRef(null);
  const state = useGetState();
  const token = state?.[STORE_KEYS.USER_DTL].token;
  const loggedInUser = state[STORE_KEYS.USER_DTL]?.LoggedUserKey;

  useEffect(() => {
    if (props.assignedNotifications.length > 0) {
      (async function() {
        const data: any = [];
      await props.assignedNotifications.map(async(i:any)=> {

        handleChangeNotificationNames(props.notificationData?.filter((j:any) => { return i.FMEANotifGrpKey?.toString() === j.FMEANotifGrpKey?.toString() }))
        props.notificationData?.map((j:any) => { if(i.FMEANotifGrpKey?.toString() === j.FMEANotifGrpKey?.toString()){
          data.push(j);
        } })
       
      })
      setNotificationNames(data);
    })()
    }
  }, [props.assignedNotifications])

  const handleChangeNotificationNames = async (id: any) => {
    let newSelected: any = [];
    if (props.customKey === '') {
      const selectedIndex = notificationNames.indexOf(id);
      if (selectedIndex === -1) {
        newSelected = newSelected.concat(notificationNames, id);
      } else if (selectedIndex === 0) {
        newSelected = newSelected.concat(notificationNames.slice(1));
      } else if (selectedIndex === notificationNames.length - 1) {
        newSelected = newSelected.concat(notificationNames.slice(0, -1));
      } else if (selectedIndex > 0) {
        newSelected = newSelected.concat(
          notificationNames.slice(0, selectedIndex),
          notificationNames.slice(selectedIndex + 1)
        );
      }
    }
    else {
      const selectedIndex:any = await notificationNames.findIndex((t:any) => t.FMEANotifGrpKey === id.FMEANotifGrpKey);
      if (selectedIndex === -1) {
        newSelected = newSelected.concat(notificationNames, id);
      } else if (selectedIndex === 0) {
        newSelected = newSelected.concat(notificationNames.slice(1));
      } else if (selectedIndex === notificationNames.length - 1) {
        newSelected = newSelected.concat(notificationNames.slice(0, -1));
      } else if (selectedIndex > 0) {
        newSelected = newSelected.concat(
          notificationNames.slice(0, selectedIndex),
          notificationNames.slice(selectedIndex + 1)
        );
      }
    }
    setNotificationNames(newSelected);
  }

  const reset = async () => {
    setNotificationNames([]);
  }

  const options = {
    AD_SUCCESS: {
      message: `Notification group assigned successfully!`,
      open: true,
      type: 'success'
    },
    AD_FAIL: {
      message: `Notification group failed to assign!`,
      open: true,
      type: 'remark'
    }
  };

  const createCustomAlert = () => {
    const na:any = [];
    notificationNames.map((i:any)=> na.push(i.FMEANotifGrpKey))
    const body = {
      userId: loggedInUser,
      presetRuleKey: props.customKey,
      type: props.assignedNotifications.length === 0 ? 'Add' : 'Edit',
      groups: na.toString(),
      token: token
    }
    FMEAService.assignFMEANotificationGroups(body)
      .then((response: any) => {
        if (response.status === 200) {
          reset();
          props.setCurrTab(1);
          const result = response?.data?.message[0];
          const checkIfExsist = result[0];
          const isError = checkIfExsist[0];
          if (isError.Result === 'Error') {
            props.setSnackbarOpts(options.AD_FAIL);
          }
          else {
            props.setSnackbarOpts(options.AD_SUCCESS);
          }
          props.drawerClose();
          props.fetchCustomAlerts();
        }
      })
      .catch((err) => {
        console.log(err);
      });
  }

  const check = (object: any) => {
    for (const value of Object.values(object)) {
      if (value === "" || (typeof value === "object" && check(value))) {
        return true;
      }
    }

    return false;
  }

  const yesDisabled = 
   notificationNames.length === 0 ? true : false;

  const DropDownIcon = (prop: any) => (
    <img style={{ marginRight: '5px', marginTop: '-2px' }} src={DownArrow} {...prop} />
  );

  const renderButtons = () => {
    return (
      <div className={'btns noWrap'} style={{ display: "flex", margin: "10px 0", justifyContent: "flex-end", width: "100%" }}>
        <ACGButton
          name={'Cancel'}
          handleClick={() => {props.drawerClose(); setNotificationNames([])}}
          secondary={true}
          className={'first-btn'}
          style={{ height: '40px', marginRight: '10px' }}
          type="button"
          variant="contained"
        />
        <ACGButton
          name={'Submit'}
          handleClick={() => { createCustomAlert() }}
          type="submit"
          className="second-btn"
          style={{ height: '40px', fontWeight: '500' }}
          variant="contained"
          disabled={yesDisabled ? true : false}
        />
      </div>
    );
  };

  return (
    <Container className="createReportt">
      
      <Box>
        <div className='formwrap all-labelcls'>
          <FormControl fullWidth>
     
          <Grid item xs={4}>
          <div className='labelfixed'>
                <FormControl fullWidth>
                  <Label label="Notification Groups &nbsp; *" className={'CMlabelDiv'} />
                  <FormControl sx={{ width: '100%' }}>
              {notificationNames?.length > 0 ? null : (
                <InputLabel
                style={{
                  fontFamily: 'Montserrat',
                  fontSize: '12px',
                  paddingTop: '0px',
                  marginBottom: '0px',
                  paddingLeft: '12px',
                  color: '#b1c2df',
                  lineHeight: '1',
                  minHeight: '20px',
                  opacity: '0.7'
                }}
                  id="demo-multiple-checkbox-label"
                >
                  Select notification groups
                </InputLabel>
              )}
              <Select
                IconComponent={DropDownIcon}
                labelId="demo-multiple-checkbox-label"
                id="demo-multiple-checkbox"
                sx={{ marginBottom: "20px", backgroundColor: "#1d1e2c", borderRadius: 3, marginTop: '10px', color: "#b1c2df" }}
                multiple
                value={notificationNames}
                input={<OutlinedInput />}
                renderValue={(selected) => selected.map((x:any) => x.FMEANotifGroupName).join(', ')}
                MenuProps={MenuProps}
              >
                {props.notificationData?.map((i:any, index:number) => (
                    <MenuItem key={index} value={i} onClick={()=>handleChangeNotificationNames(i)}>
                      <Checkbox
                        checked={
                          notificationNames.findIndex((item:any) => item.FMEANotifGrpKey === i.FMEANotifGrpKey) >= 0
                        }
                      />
                      <ListItemText key={index} primary={i.FMEANotifGroupName} />
                    </MenuItem>
                  ))}
              </Select>
                </FormControl>
                </FormControl>
                </div>
              </Grid>
          </FormControl>
        </div>
      </Box>
      <Grid style={{ display: "flex", marginTop: 20 }}>
        {renderButtons()}
      </Grid>
    </Container>
  );
}