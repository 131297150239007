import { Card, Grid, Avatar } from '@mui/material';
import Label from '../../atoms/Label';
import { sentenceCase } from 'change-case';
import Clock from '../../../assets/images/clock.svg';
import Plant from '../../../assets/images/Plant.svg';
import Menu from '../CustomTableMenu';
import './index.scss';
import DashboardIcon from '../../../assets/images/user-reports/Dashboard_icon.svg';
import ReportsIcon from '../../../assets/images/user-reports/Report_icon.svg';
import Tooltip from '@mui/material/Tooltip';
import { BlobServiceClient, BlobItem } from "@azure/storage-blob";
import { fetchBlobImage } from '../../utils/helper';
export enum ModalCardType {
    Report,
    Dashboard,
    Machine
}

type Props = {
    data?: any;
    selectedRow?: any;
    removeFn?: any;
    cardType?: ModalCardType
};

const ModalTableCard =  (props: Props) => {
    const { MachineModelName, avatarUrl, ParameterCount, MachineCount, MachineModelImagePath } = props?.data;
    const { selectedRow, removeFn, cardType } = props;
    const account = "stbtffuturednadlsqa";
    const sas = 'sp=racwle&st=2024-07-09T13:07:26Z&se=2025-07-08T21:07:26Z&spr=https&sv=2022-11-02&sr=c&sig=9xfDXquPJRY5MEOIawH6IuvF%2BMYHGivOGHpMn4xYZyo%3D';
    const blobServiceClient = new BlobServiceClient(`https://${account}.blob.core.windows.net?${sas}`);

    const containerName = 'smartconnected-files';
    const containerClient = blobServiceClient.getContainerClient(containerName);

    const blobClient = fetchBlobImage(MachineModelName+".png");
    return (
        <Card className="modalTableCard">
            <div className="mainDiv">
                <Grid container>
                    <Grid item xs={3}  style={{ cursor: 'pointer' }} onClick={selectedRow}>
                        {cardType == ModalCardType.Dashboard || cardType == ModalCardType.Report ?
                            <div className="tableImage">
                                <img src={cardType == ModalCardType.Report ? ReportsIcon :
                                    cardType == ModalCardType.Dashboard ? DashboardIcon : "null"} alt="image" />
                            </div> :
                            <div className="tableImage"> 
                            <Avatar
                                src={blobClient.url}
                                alt={'image'}
                                style={{ borderRadius: '6px', height: '60px', width: '60px' }}
                            />
                            </div>
                        }
                    </Grid>
                    <Grid item xs={9} className="paraDiv">
                        <Grid
                            item
                            xs={12}
                            style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}
                        >
                            {cardType === ModalCardType.Machine ? <Tooltip title={MachineModelName}  style={{ cursor: 'pointer' }} onClick={selectedRow}><p className="headingCard">{MachineModelName.length > 8 ? MachineModelName.slice(0, 10) + '...' : MachineModelName}</p></Tooltip> : <p className="headingCard">{MachineModelName}</p>}
                            {cardType !== ModalCardType.Report && cardType !== ModalCardType.Dashboard &&
                                <div style={{ marginTop: '-15px' }}>
                                    <Menu click={selectedRow} remove={removeFn} />
                                </div>
                            }
                        </Grid>
                        {cardType !== ModalCardType.Report && cardType !== ModalCardType.Dashboard && (
                            <Grid container  style={{ cursor: 'pointer' }} onClick={selectedRow}>
                                <Grid item xs={6}>
                                    <p className="mainPara">Parameters</p>
                                    <p className="subPara">{ParameterCount}</p>
                                    <div className="borderRight"></div>
                                </Grid>
                                <Grid item xs={6}>
                                    <p className="mainPara">Machines</p>
                                    <p className="subPara">{MachineCount}</p>
                                </Grid>
                            </Grid>)}
                    </Grid>
                </Grid>
            </div>
        </Card>
    );
};

export default ModalTableCard;