import { styled } from '@material-ui/core';

const StyledLabel = styled('label')((props: any) => {
    return {
        fontSize: 12,
        fontWeight: 500,
        marginBottom: 0,
        color: '#B1C2DF'
    };
});

const DetailValue = (props: any) => {
    const { label = '', ...rest } = props;
    return <StyledLabel {...rest}>{label}</StyledLabel>;
};

export default DetailValue;
