import { useState, useEffect } from 'react';
import { styled } from '@mui/material/styles';
import { ACTION_CODES, STORE_KEYS, BASEURL } from '../../../../constants/apiConstants';
import useGetState from '../../../../utils/hooks/useGetState';
import ACGButton from '../../../../atoms/Button';
import Paper from '@mui/material/Paper';
import Label from '../../../../atoms/Label/index';
import Table from '@mui/material/Table';
import Container from '@mui/material/Container';
import Box from '@mui/material/Box';
import TableBody from '@mui/material/TableBody';
import TableCell, { tableCellClasses } from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import TextInput from '../../../../atoms/TextInput';
import InputLabel from '@mui/material/InputLabel';
import MenuItem from '@mui/material/MenuItem';
import Radio from '@mui/material/Radio';
import RadioGroup from '@mui/material/RadioGroup';
import FormControlLabel from '@mui/material/FormControlLabel';
import FormControl from '@mui/material/FormControl';
import Select from '@mui/material/Select';
import TextField from '@mui/material/TextField';
import Tooltip from '@mui/material/Tooltip';
import HelpIcon from '@mui/icons-material/Help';
import { Typography } from '@mui/material';
import Checkbox from '@mui/material/Checkbox';
import ListItemText from '@mui/material/ListItemText';
import OutlinedInput from '@mui/material/OutlinedInput';
import Grid from '@mui/material/Grid';
import Stack from '@mui/material/Stack';
import Button from '@mui/material/Button';
import TrailAnalysisService from '../../trialAnalysisServices';
// import "../../cm.css"
const ITEM_HEIGHT = 48;
const ITEM_PADDING_TOP = 8;
const MenuProps = {
    PaperProps: {
        style: {
            maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
            width: 250,
        },
    },
}
const StyledTableCell = styled(TableCell)(({ theme }) => ({
    [`&.${tableCellClasses.head}`]: {

        color: theme.palette.common.white,
    },
    [`&.${tableCellClasses.body}`]: {
        fontSize: 14,
        color: theme.palette.common.white,
    },
}));

const StyledTableRow = styled(TableRow)(({ theme }) => ({
    '&:nth-of-type(odd)': {
        backgroundColor: theme.palette.action.hover,
    },
    // hide last border
    '&:last-child td, &:last-child th': {
        border: 0,
    },
}));
export default function CreateForm(props:any) {
    const state = useGetState();
    const token:any = state?.[STORE_KEYS.USER_DTL].token;
    const loggedInUser = state[STORE_KEYS.USER_DTL]?.LoggedUserKey;
    const [rawMaterialsInfoData, setRawMaterialsInfoData] = useState<any>([]);
    const [rawMaterialsValues, setRawMaterialsValues] = useState<any>([]);
    const [totalValue, setTotalValue] = useState(0);
    const [disable, setDisable] = useState(true);
    
    useEffect(() => {
        if(props.trailKey === ''){
        fetchRawmaterialsInfo();
        }
    }, []);

    const fetchRawmaterialsInfo = () => {
        const data = { "trialKey": props.newKey, "type": "N", token: token }
        TrailAnalysisService.getTrailAnalysisRawMaterialsInfo(data)
            .then((response:any) => {
                const values:any = [];
                const result = response?.data.message[0];
                setRawMaterialsInfoData(result[0]);
                result[0]?.map((i:any, index:number) => {
      
                    let object = {
                        "TrialRawMatKey": i.TrialRawMatKey,
                        "TrialRawMatlValue": '',
                        "TrialRawMatlComposition":''
                    }
                    values.push(object);
                    
                })
                setRawMaterialsValues(values);
            })
            .catch((err) => {
                console.log(err);
            });

    }

    useEffect(() => {
        const data = { "trialKey": props.newKey, "type": "N", token: token }
        TrailAnalysisService.getTrailAnalysisRawMaterialsInfo(data)
            .then(async (response:any) => {
                const values:any = [];
                const result = await response?.data.message[0];
                setRawMaterialsInfoData(result[0]);
                await result[0]?.map(async(i:any, index:number) => {
      
                    let object = {
                        "TrialRawMatKey": i.TrialRawMatKey,
                        "TrialRawMatlValue": i.TrialRawMatlValue,
                        "TrialRawMatlComposition": i.TrialRawMatlComposition
                    }
                    values.push(object);
                    
                })
                setRawMaterialsValues(values);
            })
            .catch((err) => {
                console.log(err);
            });

    }, [props.trailKey]);

    useEffect(()=>{
        const check = isEmpty(rawMaterialsValues)
        setDisable(check);
    },[rawMaterialsValues])

    const isEmpty = (value:any) => {
        if (typeof value === 'object' && value !== null && value !== "" ) {
          for (const v of Object.values(value)) {
            if (isEmpty(v)) return true;
          }
        }
        return (
          value === undefined ||
          value === null ||
          value === "" ||
          (typeof value === 'object' && Object.keys(value).length === 0) ||
          (typeof value === 'string' && value.trim().length === 0)
        );
      };

    const reset = () => { setTotalValue(0);}

    const createRawMaterialsValues = () => { 
        const data = {
            userId: loggedInUser,
            formData: JSON.stringify(rawMaterialsValues),
            token: token
            }
            TrailAnalysisService.createRawMaterialsValues(data)
                .then((response:any) => {
                    const result = response.data.message[0];
                    props.handleNext();
                    reset();
                })
                .catch((err) => {
                    console.log(err);
                });
    }

    const handleFormChange = (event:any, index:number) => {
            let data:any = [...rawMaterialsValues];
            data[index][event.target.name] = event.target.value;
            console.log(data)
            setRawMaterialsValues(data);
    }

    useEffect(()=>{
        const total = rawMaterialsValues.reduce((n:any, {TrialRawMatlValue}:any) => n + Number(TrialRawMatlValue), 0);
        setTotalValue(total);
    },[rawMaterialsValues])

    const renderButtons = () => {
        return (
            <div className={'btns noWrap'}>
                <ACGButton
                    name={'Cancel'}
                    handleClick={()=>{props.drawerClose();  reset(); props.handleReset()}}
                    secondary={true}
                    className={'first-btn'}
                    type="button"
                    variant="contained"
                />
                <ACGButton
                    name={'Next'}
                    handleClick={() => { props.handleNext() }}
                    type="submit"
                    className="second-btn"
                    variant="contained"
                />
            </div>
        );
    };

    return (
        <Container  className="createReport">
            <Box>
                <div className='formwrap all-labelcls'>
                <FormControl fullWidth>
                        <Grid container spacing={2}>
                            <Grid item xs={2}>
                                <FormControl fullWidth>
                                <Label label="Plant Name" className={'labelDiv'} />
                                    <TextInput id="outlined-basic"
                                        variant="outlined"
                                        sx={{ marginBottom: "20px", height: '20px' }}
                                        value="ACG Lab"
                                        maxLength={50}
                                        fullWidth={true}
                                        disabled={true}
                                    />
                                </FormControl>
                            </Grid>

                            <Grid item xs={2}>
                                <FormControl fullWidth>
                                <Label label="Customer Name" className={'labelDiv'} />
                                    <TextInput id="outlined-basic"
                                        variant="outlined"
                                        sx={{ marginBottom: "20px", height: '20px' }}
                                        value={rawMaterialsInfoData[0]?.TrialCustomerName}
                                        maxLength={50}
                                        fullWidth={true}
                                        disabled={true}
                                    />
                                </FormControl>
                            </Grid>

                            <Grid item xs={2}>
                                <FormControl fullWidth>
                                <Label label="Product Name" className={'labelDiv'} />
                                    <TextInput id="outlined-basic"
                                        variant="outlined"
                                        sx={{ marginBottom: "20px", height: '20px' }}
                                        value={rawMaterialsInfoData[0]?.TrialProductName}
                                        maxLength={50}
                                        fullWidth={true}
                                        disabled={true}
                                    />
                                </FormControl>
                            </Grid>

                            <Grid item xs={2}>
                                <FormControl fullWidth>
                                <Label label="Recipe Name" className={'labelDiv'} />
                                    <TextInput id="outlined-basic"
                                        variant="outlined"
                                        sx={{ marginBottom: "20px", height: '20px' }}
                                        value={rawMaterialsInfoData[0]?.TrialRecipeName}
                                        maxLength={50}
                                        fullWidth={true}
                                        disabled={true}
                                    />
                                </FormControl>
                            </Grid>

                            <Grid item xs={2}>
                                <FormControl fullWidth>
                                <Label label="Batch Size" className={'labelDiv'} />
                                    <TextInput id="outlined-basic"
                                        variant="outlined"
                                        sx={{ marginBottom: "20px", height: '20px' }}
                                        value={rawMaterialsInfoData[0]?.TrialBatchSize}
                                        maxLength={50}
                                        fullWidth={true}
                                        disabled={true}
                                    />
                                </FormControl>
                            </Grid>
                            <Grid xs={12} sx={{ marginTop: 2, padding: 2 }}>
                                <TableContainer component={Paper} className='tablemainList' >
                                    <Table sx={{ minWidth: 700 }} aria-label="customized table" className='table_List'>
                                        <TableHead className='tableHeadList'>
                                            <TableRow className='tableHeadListRow'>
                                                <StyledTableCell className='alertlistth'>Raw Material</StyledTableCell>
                                                <StyledTableCell className='alertlistth'>Trail Category</StyledTableCell>
                                                <StyledTableCell align="center" className='alertlistth'>% Composition</StyledTableCell>
                                                <StyledTableCell align="center" className='alertlistth'>Qty required for Batch Size</StyledTableCell>
                                            </TableRow>
                                        </TableHead>
                                        <TableBody>
                                            {rawMaterialsInfoData.length > 0 && rawMaterialsValues.length > 0?
                                                rawMaterialsInfoData.map((row:any, index:number) =>(
                                                <StyledTableRow   key={`list-${index}`}>
                                                <StyledTableCell component="th" scope="row" className='colortxtcolumn'>
                                                    {row.TrialRawMatlName}
                                                </StyledTableCell>
                                                <StyledTableCell component="th" scope="row" className='colortxtcolumn'>
                                                    {row.TrialRawMatlCatName?.replace(/([A-Z])/g, ' $1').trim()}
                                                </StyledTableCell>
                                                <StyledTableCell component="th" scope="row" className='colortxtcolumn' align='center'>
                                                    <TextInput
                                                        type="number"
                                                        key={`kg-${index}`}
                                                        name='TrialRawMatlValue'
                                                        placeholder='Material Value'
                                                        onChange={event => handleFormChange(event, index)}
                                                        value={rawMaterialsValues.find((i:any) => i.TrialRawMatKey === row.TrialRawMatKey)?.TrialRawMatlValue?rawMaterialsValues.find((i:any) => i.TrialRawMatKey === row.TrialRawMatKey)?.TrialRawMatlValue:rawMaterialsValues.find((i:any) => i.TrialRawMatKey === row.TrialRawMatKey)?.TrialRawMatlValue}
                                                        disabled
                                                    />
                                                </StyledTableCell>
                                                
                                                <StyledTableCell component="th" scope="row" className='colortxtcolumn' align='center'>
                                                    <TextInput
                                                         key={`pct-${index}`}
                                                        type="number"
                                                        name='TrialRawMatlValue'
                                                        placeholder='Material Value'
                                                        onChange={event => handleFormChange(event, index)}
                                                        value={rawMaterialsValues.find((i:any) => i.TrialRawMatKey === row.TrialRawMatKey)?.TrialRawMatlValue?rawMaterialsValues.find((i:any) => i.TrialRawMatKey === row.TrialRawMatKey)?.TrialRawMatlValue:rawMaterialsValues.find((i:any) => i.TrialRawMatKey === row.TrialRawMatKey)?.TrialRawMatlValue}
                                                        disabled
                                                    />
                                                </StyledTableCell>
                                                
                                            </StyledTableRow>
                                        ))
                                        :
                                        <StyledTableRow >
                                            <StyledTableCell colSpan={9} align="left" className='nodataalert'>No records found.</StyledTableCell>

                                        </StyledTableRow>
                                            }

                                        </TableBody>
                                    </Table>
                                </TableContainer>
                            </Grid>
                        </Grid>

                    </FormControl>
                </div>
            </Box>
            {renderButtons()}
        </Container>
    );
}