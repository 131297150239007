import * as Yup from "yup";

export const VALIDATION_YUPSCHEMA = {
  dateTimeFromSelect: Yup.date().required("Please select from date")
    .max(new Date(), 'To date cannot be in the future')
    .min(new Date(Date.now() - 6 * 30 * 24 * 60 * 60 * 1000), // Last 6 monthts
      'From date cannot be more than 6 monthss in the past'),
  dateTimeToSelect: Yup.date().required("Please select to date")
    .max(new Date(), 'To date cannot be in the future')
    .when("dateFromSelect",
      (dateTimeFromSelect, yup) => dateTimeFromSelect && yup.min(dateTimeFromSelect, "To date cannot be before start date"))
    .test(
      'date-range',
      'Time range should not be more than 24 hours',
      function (value) {
        const fromDate = this.resolve(Yup.ref('dateTimeFromSelect')) as Date;
        const toDate = value as Date;
        const diffInMs = toDate.getTime() - fromDate.getTime();
        const diffInDays = diffInMs / (1000 * 60 * 60 * 24);
        return diffInDays <= 1; //24Hrs only
      }),
  dropdownPlant: Yup.string().required("Please select plant"),
  dropdownMachine: Yup.string().required("Please select machine"),
};