import React from 'react'
import { TableCell, TableHead, TableRow, TableSortLabel } from '@mui/material';

import UpArrowIcon from '../../../../assets/images/upArrow.svg';
import DownArrowIcon from '../../../../assets/images/downArrow.svg';
import "./index.scss";

type ComponentsProps = {
    HeaderList: any[],
    handleSorting:any
}
function TableHeaderComponent(props: ComponentsProps) {
    const { HeaderList,handleSorting } = props
    const Icon = (val: any) => {
        return (
            <span
                style={{
                    display: 'flex',
                    flexDirection: 'column',
                    justifyContent: 'Center',
                    alignItems: 'Center',
                    marginLeft: '5px'
                }}
            >
                {' '}
                <img
                    src={DownArrowIcon}
                    style={{ fontSize: '15px', marginBottom: '-4px', fontWeight: '900' }}
                onClick={() => handleSorting(val, 'asc')}
                />
                <img
                    src={UpArrowIcon}
                    style={{ fontSize: '15px', marginTop: '-7px', fontWeight: '900' }}
                onClick={() => handleSorting(val, 'desc')}
                />
            </span>
        );
    };
    return (
        <TableHead className="tableColumn">
            <TableRow>
                {HeaderList?.map((header) => (
                    <TableCell
                        key={header.label}
                        align={'left'}
                        sortDirection={"desc"}
                    >
                        <TableSortLabel
                            IconComponent={() => Icon(header.key)}
                            hideSortIcon={!header.icon}
                        >
                            {header.label}
                        </TableSortLabel>
                    </TableCell>
                ))}
            </TableRow>
        </TableHead>
    )
}

export default TableHeaderComponent