import React, { useState, useEffect } from 'react';
import 'chartjs-plugin-datalabels';
import ChartDataLabels from 'chartjs-plugin-datalabels';
import {
  Chart as ChartJS,
  LinearScale,
  CategoryScale,
  BarElement,
  PointElement,
  LineElement,
  Tooltip,
  LineController,
  BarController,
} from 'chart.js';
import { Chart } from 'react-chartjs-2';

ChartJS.register(
  LinearScale,
  CategoryScale,
  BarElement,
  PointElement,
  LineElement,
  Tooltip,
  LineController,
  BarController
);

const Stacked = (props: any) => {
  const [labelSet, setLabelSet] = useState<any>([]);
  const [opdata1, setOPData1] = useState<any>([]);
  const [opdata2, setOPData2] = useState<any>([]);
  const [opdata3, setOPData3] = useState<any>([]);
  const [opdata4, setOPData4] = useState<any>([]);
  const [hiddenDatasets, setHiddenDatasets] = useState<string[]>([]);

  useEffect(() => {
    const lab: any = [], d1: any = [], d2: any = [], d3: any = [], d4: any = [];

    props?.oeeDetails.map((i: any) => {
      if (!lab.includes(i.TimeName)) lab.push(i.TimeName);
      d1.push(i.Availability);
      d2.push(i.OEE);
      d3.push(i.Performance);
      d4.push(i.Quality);
    });
    setLabelSet(lab);
    setOPData1(d1);
    setOPData2(d2);
    setOPData3(d3);
    setOPData4(d4);
  }, [props]);

  const toggleDataset = (label: string) => {
    setHiddenDatasets((prevHidden) =>
      prevHidden.includes(label)
        ? prevHidden.filter((item) => item !== label)
        : [...prevHidden, label]
    );
  };

  const options = {
    plugins: {
      datalabels: { color: 'white', display: false },
      legend: {
        display: false, // Disable default legend
      },
      tooltip: {
        callbacks: {
          label: function (tooltipItem: any) {
            let label = tooltipItem.dataset.label || '';
            if (label) {
              label += ': ';
            }
            label += tooltipItem.raw.toFixed(2); // Formats to two decimal points
            return label;
          },
        },
      },
    },
    responsive: true,
    scales: {
      x: {
        stacked: true,
        ticks: {
          color: 'white',
        },
        grid: {
          color: 'transparent',
        },
      },
      y: {
        type: 'linear' as const,
        display: true,
        position: 'left' as const,
        ticks: {
          color: 'white',
        },
        grid: {
          color: '#878f99',
        },
        title: {
          display: true,
          text: 'Percentage %',
          color: 'white',
          font: {
            size: 12,
          },
        },
      },
    },
  };

  const data = {
    labels: labelSet,
    datasets: [
      {
        label: 'OEE %',
        data: opdata2,
        backgroundColor: '#0dcaf0',
        borderColor: '#0dcaf0',
        borderWidth: 2,
        type: 'line' as const,
        hidden: hiddenDatasets.includes('OEE %'),
        yAxisID: 'y',
      },
      {
        label: 'Availability %',
        data: opdata1,
        backgroundColor: '#ffc107',
        borderColor: '#ffc107',
        borderWidth: 2,
        type: 'line' as const,
        hidden: hiddenDatasets.includes('Availability %'),
        yAxisID: 'y',
      },
      {
        label: 'Performance %',
        data: opdata3,
        backgroundColor: '#0d6efd',
        borderColor: '#0d6efd',
        borderWidth: 2,
        type: 'line' as const,
        hidden: hiddenDatasets.includes('Performance %'),
        yAxisID: 'y',
      },
      {
        label: 'Quality %',
        data: opdata4,
        backgroundColor: '#198754',
        borderColor: '#198754',
        borderWidth: 2,
        type: 'line' as const,
        hidden: hiddenDatasets.includes('Quality %'),
        yAxisID: 'y',
      },
    ],
  };

  const renderCustomLegend = () => {
    return (
      <div style={{ display: 'flex', justifyContent: 'center' }} className='legendcls'>
        {data.datasets.map((dataset, index) => (
          <div
            key={index}
            onClick={() => toggleDataset(dataset.label)}
            style={{
              display: 'flex',
              alignItems: 'center',
              marginRight: '15px',
              opacity: hiddenDatasets.includes(dataset.label) ? 0.5 : 1,
            }}
          >
            <div
              style={{
                backgroundColor: dataset.backgroundColor as string,
                width: '35px',
                height: '10px',
                marginRight: '5px',
              }}
            />
            <span style={{ color: '#e0e0e0', fontSize: '10px', fontFamily: 'montserrat' }}>
              {dataset.label}
            </span>
          </div>
        ))}
      </div>
    );
  };

  return (
    <div>
      {renderCustomLegend()}
      <Chart type='bar' data={data} options={options} plugins={[ChartDataLabels]} />
    </div>
  );
};

export default Stacked;
