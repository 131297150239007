import { useEffect, useRef, useState } from 'react';
import { Container } from '@mui/material';
import { Formik, Form, FormikProps } from 'formik';
import * as Yup from 'yup';
import { MD_YUPSCHEMA } from '../schema';
import useGetState from '../../../../utils/hooks/useGetState';
import { useDispatch } from 'react-redux';
import { executeACGAction, resetErr, updateScreenIdentifiers } from '../../../../store/slice';
import ACGButton from '../../../../atoms/Button';
import Label from '../../../../atoms/Label/index';
import TextInput from '../../../../atoms/TextInput';
import '../CreateReport/index.scss';
import { ACTION_CODES, STORE_KEYS } from '../../../../constants/apiConstants';
import { renameObjKeys } from '../../../Login/helper';

type ComponentProps = {
    drawerClose: Function;
    setSnackbarOpts: Function;
    dtlData?: any;
    setReload: Function;
    setDtlData: Function;
};
type ModuleType = {
    mdCode: string | '';
    mdName: string | '';
    mdOrdId: Number | null;
};
const options = {
    AD_SUCCESS: {
        message: 'Module updated successfully!',
        open: true,
        type: 'success'
    }
};
const EditModule = (props: ComponentProps) => {
    const { drawerClose, setSnackbarOpts = () => {}, dtlData = {}, setReload = () => {}, setDtlData } = props;
    const formRef = useRef<any>(null);
    const [validationSchema, setValidationSchema] = useState({});
    const [initialValues] = useState({
        mdCode: dtlData?.ModuleCode || '',
        mdName: dtlData?.ModuleName || '',
        mdOrdId: dtlData?.ModuleOrderId || null
    });
    const state = useGetState();
    const dispatch = useDispatch();
    const renderButtons = () => {
        return (
            <div className={'btns noWrap'}>
                <ACGButton
                    name={'Cancel'}
                    handleClick={drawerClose}
                    secondary={true}
                    className={'first-btn'}
                    type="button"
                    variant="contained"
                />
                <ACGButton
                    name={'Submit'}
                    handleClick={() => {}}
                    type="submit"
                    className="second-btn"
                    variant="contained"
                />
            </div>
        );
    };
    useEffect(() => {
        setValidationSchema(Yup.object().shape(MD_YUPSCHEMA));
    }, []);
    const handleSubmit = async () => {
        const bundle = {
            payload: {
                urlPath: ACTION_CODES.EDIT_MODULE,
                requestType: 'PUT',
                reqObj: {
                    moduleCode: formRef?.current?.values?.mdCode,
                    moduleName: formRef?.current?.values?.mdName,
                    moduleOrderId: Number(formRef?.current?.values?.mdOrdId)
                }
            },
            uniqueScreenIdentifier: { mdAdded: true },
            storeKey: STORE_KEYS.ADD_RPT
        };
        dispatch(executeACGAction(bundle));
    };
    useEffect(() => {
        if (state?.[STORE_KEYS.ADD_RPT]?.mdAdded) {
            if (state?.[STORE_KEYS.ADD_RPT]?.statusCode === 200) {
                setDtlData(
                    renameObjKeys(
                        { ...formRef?.current?.values, ModuleKey: dtlData?.ModuleKey },
                        {
                            mdCode: 'ModuleCode',
                            mdName: 'ModuleName',
                            mdOrdId: 'ModuleOrderId'
                        }
                    )
                );
                setSnackbarOpts(options.AD_SUCCESS);
                setReload(true);
                drawerClose();
            }
            dispatch(
                updateScreenIdentifiers({
                    storeKey: STORE_KEYS.ADD_RPT,
                    uniqueScreenIdentifier: {
                        mdAdded: false
                    }
                })
            );
        }
    }, [state?.[STORE_KEYS.ADD_RPT]?.mdAdded]);
    const handleSnackbarError = (err: any) => {
        const errorMsg = err?.message || 'Internal Server error';
        const snackbarError = {
            message: errorMsg,
            type: 'reject',
            open: true
        };
        setSnackbarOpts(snackbarError);
    };

    useEffect(() => {
        if (state?.err) {
            handleSnackbarError(state?.err);
            dispatch(resetErr());
        }
    }, [state?.err]);

    return (
        <Container className="createReport">
            <Formik
                initialValues={initialValues}
                validationSchema={validationSchema}
                onSubmit={() => {
                    handleSubmit();
                }}
                validateOnBlur={false}
                validateOnChange={false}
                innerRef={formRef}
            >
                {(formikprops: FormikProps<ModuleType>) => {
                    const { values, handleChange, handleBlur, errors, touched } = formikprops;
                    return (
                        <Form>
                            <Label label="Module Code" className={'labelDiv'} />
                            <TextInput
                                variant="outlined"
                                autoFocus
                                fullWidth
                                type="text"
                                name="mdCode"
                                value={values.mdCode}
                                autoComplete="off"
                                formInput="inputDiv"
                                onChange={(e: any) => {
                                    handleChange(e);
                                }}
                                onBlur={handleBlur}
                                placeholder="Enter Module Code"
                                disabled={true}
                                helperText={errors.mdCode && touched.mdCode ? errors.mdCode : ''}
                            />
                            <Label label="Module Name" className={'labelDiv'} />
                            <TextInput
                                variant="outlined"
                                fullWidth
                                type="text"
                                name="mdName"
                                value={values.mdName}
                                autoComplete="off"
                                formInput="inputDiv"
                                onChange={(e: any) => {
                                    handleChange(e);
                                }}
                                onBlur={handleBlur}
                                placeholder="Enter Module Name"
                                helperText={errors.mdName && touched.mdName ? errors.mdName : ''}
                            />
                            <Label label="Module Order ID" className={'labelDiv'} />
                            <TextInput
                                variant="outlined"
                                fullWidth
                                type="text"
                                name="mdOrdId"
                                value={values.mdOrdId?.toString()}
                                autoComplete="off"
                                formInput="inputDiv"
                                onChange={(e: any) => {
                                    handleChange(e);
                                }}
                                onBlur={handleBlur}
                                placeholder="Enter Module Order ID"
                                helperText={errors.mdOrdId && touched.mdOrdId ? errors.mdOrdId : ''}
                            />
                            {renderButtons()}
                        </Form>
                    );
                }}
            </Formik>
        </Container>
    );
};
export default EditModule;
