import React from 'react';
import { Box, FilledInput, InputAdornment } from '@mui/material';
import { Icon } from '@iconify/react';
import searchFill from '@iconify/icons-eva/search-fill';
import closeFill from '@iconify/icons-eva/close-fill';
import { styled } from '@mui/material/styles';
import './index.scss';

const SearchStyle = styled(FilledInput)(({ theme }) => ({
    width: 300,
    transition: theme.transitions.create(['box-shadow', 'width'], {
        easing: theme.transitions.easing.easeInOut,
        duration: theme.transitions.duration.shorter
    }),
    borderRadius: 10,
    height: 40,
    position: 'absolute',
    right: '10vw',
    // '&.Mui-focused': { width: 320, boxShadow: theme.customShadows.z8 },
    '& fieldset': {
        borderWidth: '1px !important'
        // borderColor: `${theme.palette.grey[500_32]} !important`
    },
    [theme.breakpoints.down('xs')]: {
        right: '18vw',
        width: '250px'
    },
    [theme.breakpoints.down('sm')]: {
        right: '18vw',
        width: '250px'
    },
    [theme.breakpoints.up('md')]: {
        right: '13vw '
    },
    [theme.breakpoints.up('lg')]: {
        right: '10vw'
    }
}));

type Props = {
    searchText?: string;
    setSearchText?: (event: React.ChangeEvent<{ value: unknown }>) => void;
    resetSearchText?: () => void;
    searchLabel?: string;
};

const SearchBar = (props: Props) => {
    const { searchText, setSearchText, resetSearchText, searchLabel = '' } = props;

    return (
        <div className="drawerContent">
            <SearchStyle
                className="searchBox"
                placeholder={searchLabel ? searchLabel : 'Search'}
                value={searchText}
                onChange={setSearchText}
                startAdornment={
                    <div>
                        <InputAdornment position="start" variant="outlined" className="searchBoxIcon">
                            <Box component={Icon} icon={searchFill} sx={{ color: 'text.disabled' }} />
                        </InputAdornment>
                        {searchText && searchText.length > 0 ? (
                            <InputAdornment position="end" className="searchBoxIconCancel" onClick={resetSearchText}>
                                <Box component={Icon} icon={closeFill} sx={{ color: 'text.disabled' }} />
                            </InputAdornment>
                        ) : null}
                    </div>
                }
            />
        </div>
    );
};

export default SearchBar;
