import * as React from 'react';
import { useState, useEffect } from 'react';
import CustomTable from '../../atoms/CustomTable';
import CustomTableToolbar from '../../atoms/CustomTableToolbar';
import { Container, Card, Grid, TableRow, TableCell, Typography } from '@mui/material';
import { applySortFilter } from '../../utils/helper';
// import "../index.scss";
// import { filter } from "lodash";
// import MapCustomTabs from "../../../atoms/ButtonTabs/MapCustomTabs";
// import FilterModal from "../../../atoms/filterModal";
import ACGButton from '../../atoms/Button';
import TextInput from '../../atoms/TextInput';
import Label from '../../atoms/Label/index';
import { ACTION_CODES, STORE_KEYS } from '../../constants/apiConstants';
import { useDispatch } from 'react-redux';
import { executeACGAction, updateScreenIdentifiers } from '../../store/slice';
import useGetState from '../../utils/hooks/useGetState';
import SnackbarAlert from '../../atoms/Snackbar';

// import StatusCard from "../../../atoms/DashboardStatus/StatusCard";
const options = {
    DEFAULT: {
        message: '',
        open: false,
        type: ''
    },
    ADD_SUCCESS: {
        message: 'Model created successfully!',
        open: true,
        type: 'success'
    },
    EDIT_SUCCESS: {
        message: 'Model updated successfully!',
        open: true,
        type: 'success'
    }
};
const TABLE_HEAD = [
    { id: 'machineName', label: 'Select All', alignRight: false },
    { id: 'Category', label: 'Category', alignRight: false }
    //   { id: "Action", label: "Action", alignLeft: true },
];
type Anchor = 'right';
const AddParams = (props: any) => {
    const { closeDrawer, MachineModelKey, setSnackbarOptions, isEdit, data, setReload } = props;
    const [state, setState] = useState({
        right: false
    });
    const dispatch = useDispatch();
    const stateObj = useGetState();
    const [page, setPage] = useState<number>(0);
    const [order, setOrder] = useState('desc');
    const [orderBy, setOrderBy] = useState('machineName');
    const [selected, setSelected] = useState<any>([]);
    const [filterName, setFilterName] = useState<string>('');
    const [tableData, setTableData] = useState<any[]>([]);
    const [tableData2, setTableData2] = useState<any[]>([]);
    const [page2, setPage2] = useState<number>(0);
    const [order2, setOrder2] = useState('desc');
    const [orderBy2, setOrderBy2] = useState('machineName');
    const [selected2, setSelected2] = useState<any>([]);
    const [filterName2, setFilterName2] = useState<string>('');
    const [selectedModalData, setSelectedModalData] = useState('');
    const [tempData, setTempData] = useState<any>([]);
    const [removeData, setRemoveData] = useState<any>([]);
    const [groupCode, setGroupCode] = useState<string>(isEdit ? data?.GroupCode : '');
    const [groupName, setGroupName] = useState<string>(isEdit ? data?.GroupName : '');
    const [snackbarOptions, setSnackbarOptions2] = useState(options.DEFAULT);

    const [formError, setFormError] = useState({ groupCode: '', groupName: '', hasError: false });

    const filteredList = applySortFilter(tableData, order, filterName, orderBy);
    const filteredList2 = applySortFilter(tableData2, order2, filterName2, orderBy2);
    const filterList3 = stateObj?.[STORE_KEYS.GET_PARAM_GROUP_DETAILS_DATA]?.body?.data;
    const handleRequestSort = (event: any, property: any) => {
        setFilterName('');
        console.log(event, property);
        const isAsc = orderBy === event && order === 'asc';
        setOrder(isAsc ? 'desc' : 'asc');
        setOrderBy(event);
    };

    const handleSelectAllClick = (event: any) => {
        if (event.target.checked) {
            const newSelecteds = [];
            const entireSet = [];
            for (let i = 0; i < tableData.length; i++) {
                newSelecteds.push(tableData[i].id);
                entireSet.push(tableData[i]);
            }
            const toRemove = tableData.slice(page, tableData.length);
            setSelected(newSelecteds);
            setTempData(entireSet);
            return;
        }
        setSelected([]);
        setTempData([]);
    };

    const handleSelectAllClick2 = (event: any) => {
        if (event.target.checked) {
            const newSelecteds = [];
            const entireSet = [];
            for (let i = 0; i < tableData2.length; i++) {
                newSelecteds.push(tableData2[i].id);
                entireSet.push(tableData2[i]);
            }
            const toRemove = tableData2.slice(page, tableData2.length);
            setSelected2(newSelecteds);
            setRemoveData(entireSet);
            return;
        }
        setSelected2([]);
        setRemoveData([]);
    };

    const handleClick = (requestedBy: string) => {
        console.log(requestedBy);
        const selectedIndex = selected.indexOf(requestedBy);
        // | ((prevState: unknown[]) => unknown[])
        let newSelected: any[] = [];
        if (selectedIndex === -1) {
            newSelected = newSelected.concat(selected, requestedBy);
        } else if (selectedIndex === 0) {
            newSelected = newSelected.concat(selected.slice(1));
        } else if (selectedIndex === selected.length - 1) {
            newSelected = newSelected.concat(selected.slice(0, -1));
        } else if (selectedIndex > 0) {
            newSelected = newSelected.concat(selected.slice(0, selectedIndex), selected.slice(selectedIndex + 1));
        }
        const newData = [...tableData].filter((ele) => newSelected.includes(ele?.id));
        console.log(newData);
        setSelected(newSelected);
        setTempData(newData);
    };

    const handleClick2 = (requestedBy: string) => {
        console.log(requestedBy);
        const selectedIndex = selected2.indexOf(requestedBy);
        // | ((prevState: unknown[]) => unknown[])
        let newSelected: any[] = [];
        if (selectedIndex === -1) {
            newSelected = newSelected.concat(selected2, requestedBy);
        } else if (selectedIndex === 0) {
            newSelected = newSelected.concat(selected2.slice(1));
        } else if (selectedIndex === selected2.length - 1) {
            newSelected = newSelected.concat(selected2.slice(0, -1));
        } else if (selectedIndex > 0) {
            newSelected = newSelected.concat(selected2.slice(0, selectedIndex), selected2.slice(selectedIndex + 1));
        }
        const newData = [...tableData2].filter((ele) => newSelected.includes(ele?.id));
        console.log(newData);
        setSelected2(newSelected);
        setRemoveData(newData);
    };

    const toggleDrawer = (anchor: Anchor, open: boolean) => (event: React.KeyboardEvent | React.MouseEvent) => {
        if (
            event.type === 'keydown' &&
            ((event as React.KeyboardEvent).key === 'Tab' || (event as React.KeyboardEvent).key === 'Shift')
        ) {
            return;
        }
        setState({ ...state, [anchor]: open });
    };

    const handleFilterByName = (event: { target: { value: React.SetStateAction<string> } }) => {
        setOrderBy('machineName');
        setFilterName(event.target.value);
    };
    const handleFilterByName2 = (event: { target: { value: React.SetStateAction<string> } }) => {
        setOrderBy('machineName');
        setFilterName2(event.target.value);
    };

    const handleChangePage = (event: any, newPage: React.SetStateAction<number>) => {
        setPage(newPage);
    };

    const showRow = (event: any) => { };

    const handleRoute = (row: any, ele: any) => {
        if (ele === 'Action') {
        }
    };

    const handleFilter = () => { };

    const handleChange = (event: any) => {
        // alert(event.target.value)
        console.log(event.target.value);
        setSelectedModalData(event.target.value);
    };

    const addParameters = () => {
        if (tempData?.length > 0) {
            const data = [...tableData2, ...tempData];
            const existingData = [...tableData].filter((ele) => !tempData.includes(ele));
            setTableData2(data);
            setTableData(existingData);
            setTempData([]);
            setSelected([]);
            setSelected2([]);
        }
    };

    const removeParameters = () => {
        const updatedData = [...tableData, ...removeData];
        const existingData = [...tableData2].filter((ele) => !removeData.includes(ele));
        setTableData(updatedData);
        setTableData2(existingData);
        setRemoveData([]);
        setSelected([]);
        setSelected2([]);
    };

    const getMMParametersName = () => {
        if (!MachineModelKey) {
            return
        }
        dispatch(
            executeACGAction({
                payload: {
                    requestType: 'GET',
                    urlPath: ACTION_CODES.MACHINES_GET_MM_PARAMETERS_NAME + '/' + MachineModelKey
                },
                uniqueScreenIdentifier: { machinesGetMMParametersNameLoaded: true },
                storeKey: STORE_KEYS.MACHINES_GET_MM_PARAMETERS_NAME_DATA
            })
        );
    };

    const getParamGroupDetails = () => {
        if (!MachineModelKey) {
            return
        }
        dispatch(
            executeACGAction({
                payload: {
                    requestType: 'POST',
                    urlPath: ACTION_CODES.MACHINES_GET_PARAMGROUP_DETAILS,
                    reqObj: {
                        machineModelKey: MachineModelKey
                    }
                },
                uniqueScreenIdentifier: { getParamGroupDetailsLoaded: true },
                storeKey: STORE_KEYS.MACHINES_GET_PARAMGROUP_DETAILS_DATA
            })
        );
    };

    useEffect(() => {
        if (stateObj?.[STORE_KEYS.MACHINES_GET_MM_PARAMETERS_NAME_DATA]?.machinesGetMMParametersNameLoaded) {
            if (stateObj?.[STORE_KEYS.MACHINES_GET_MM_PARAMETERS_NAME_DATA]?.statusCode === 200) {
                const data = stateObj?.[STORE_KEYS.MACHINES_GET_MM_PARAMETERS_NAME_DATA]?.body?.data?.map(
                    (d: {
                        Category: any; MMParamKey: number; ParamName: string
                    }) => {
                        return { id: d?.MMParamKey, machineName: d?.ParamName, Category: d?.Category };
                    }
                );
                if (isEdit) {
                    const newData: any[] = [];
                    for (let i = 0; i < filteredListEdit?.length; i++) {
                        const selectedIndex = selected.indexOf(filteredListEdit[i]);
                        // | ((prevState: unknown[]) => unknown[])
                        let newSelected: any[] = [];
                        if (selectedIndex === -1) {
                            newSelected = newSelected.concat(selected, filteredListEdit[i]);
                        } else if (selectedIndex === 0) {
                            newSelected = newSelected.concat(selected.slice(1));
                        } else if (selectedIndex === selected.length - 1) {
                            newSelected = newSelected.concat(selected.slice(0, -1));
                        } else if (selectedIndex > 0) {
                            newSelected = newSelected.concat(
                                selected.slice(0, selectedIndex),
                                selected.slice(selectedIndex + 1)
                            );
                        }
                        const news = [...data].filter((ele) => ele.machineName! == newSelected[0]?.ParamName);
                        newData.push(...news);
                    }
                    const existingData = [...data].filter((ele) => !newData.includes(ele));
                    setTableData(existingData);
                    setTableData2(newData);
                } else {
                    setTableData(data);
                }
                dispatch(
                    updateScreenIdentifiers({
                        storeKey: STORE_KEYS.MACHINES_GET_MM_PARAMETERS_NAME_DATA,
                        uniqueScreenIdentifier: {
                            machinesGetMMParametersNameLoaded: false
                        }
                    })
                );
            }
        }
    }, [stateObj?.[STORE_KEYS.MACHINES_GET_MM_PARAMETERS_NAME_DATA]?.machinesGetMMParametersNameLoaded]);
    const resetSearch = () => {
        setOrderBy('machineName');
        setFilterName('');
    };
    const resetSearch2 = () => {
        setOrderBy('machineName');
        setFilterName2('');
    };
    const renderButtons = () => {
        return (
            <div className={'btns noWrap'} style={{ display: 'flex', float: 'right', marginTop: '15px', marginBottom: '10px' }}>
                <ACGButton
                    name={'Cancel'}
                    disabled={stateObj?.isLoading}
                    handleClick={closeDrawer}
                    secondary={true}
                    className={'first-btn'}
                    type="button"
                    variant="contained"
                    style={{ marginRight: '25px', padding: '5px', width: '100px', height: '35px' }}
                />
                <ACGButton
                    name={`${stateObj?.isLoading ? 'Saving...' : 'Save'}`}
                    disabled={stateObj?.isLoading}
                    handleClick={saveHandler}
                    type="submit"
                    className="second-btn"
                    variant="contained"
                    style={{ marginRight: '25px', padding: '5px', width: '100px', height: '35px' }}
                />
            </div>
        );
    };

    useEffect(() => {
        getMMParametersName();
    }, []);

    const validateInput = (code: string, name: string,) => {
        const errors = { groupCode: '', groupName: '', tableData2: '', modelImage: '', hasError: false };
        if (code?.trim()) {
            errors.groupCode = '';
            errors.hasError = false;
        } else {
            errors.groupCode = 'Please enter group code';
            errors.hasError = true;
        }
        if (name?.trim()) {
            errors.groupName = '';
            errors.hasError = false;
        } else {
            errors.groupName = 'Please enter group name';
            errors.hasError = true;
        }
        return errors;
    }

    const saveHandler = () => {
        const runValidation = validateInput(groupCode, groupName);
        if (!runValidation.hasError) {
            setFormError(runValidation);
        }
        if (groupCode && groupName && tableData2?.length) {
            dispatch(
                executeACGAction({
                    payload: {
                        requestType: isEdit ? 'PUT' : 'POST',
                        urlPath: isEdit ? ACTION_CODES.EDIT_PARAM_GROUP : ACTION_CODES.ADD_PARAM_GROUP,
                        reqObj: {
                            machineModelKey: MachineModelKey,
                            groupCode: groupCode,
                            groupName: groupName
                        }
                    },
                    uniqueScreenIdentifier: { addParamGroupLoaded: true },
                    storeKey: STORE_KEYS.ADD_PARAM_GROUP_DATA
                })
            );
        }
        else {
            setFormError(runValidation);
        }
    };
    useEffect(() => {
        if (stateObj?.[STORE_KEYS.ADD_PARAM_GROUP_DATA]?.addParamGroupLoaded) {
            if (stateObj?.[STORE_KEYS.ADD_PARAM_GROUP_DATA]?.statusCode === 200) {
                const machMdlParamGrpKey = stateObj?.[STORE_KEYS.ADD_PARAM_GROUP_DATA]?.body?.data.machMdlParamGrpKey;
                if (tableData2?.length && machMdlParamGrpKey) {
                    const IDs = tableData2?.map((param) => param.id).join(',');
                    dispatch(
                        executeACGAction({
                            payload: {
                                requestType: 'POST',
                                urlPath: ACTION_CODES.MACHINES_PARAMETERS_MAPPING,
                                reqObj: {
                                    machMdlParamGrpKey: machMdlParamGrpKey,
                                    mmParamKeys: IDs
                                }
                            },
                            uniqueScreenIdentifier: { paramGroupMappingLoaded: true },
                            storeKey: STORE_KEYS.MACHINES_PARAMETERS_MAPPING_DATA
                        })
                    );
                }
            }
            dispatch(
                updateScreenIdentifiers({
                    storeKey: STORE_KEYS.ADD_PARAM_GROUP_DATA,
                    uniqueScreenIdentifier: {
                        addParamGroupLoaded: false
                    }
                })
            );
        }
    }, [stateObj?.[STORE_KEYS.ADD_PARAM_GROUP_DATA]?.addParamGroupLoaded]);

    useEffect(() => {
        if (stateObj?.[STORE_KEYS.MACHINES_PARAMETERS_MAPPING_DATA]?.paramGroupMappingLoaded) {
            console.log('calling--again');
            if (stateObj?.[STORE_KEYS.MACHINES_PARAMETERS_MAPPING_DATA]?.statusCode === 200) {
                getParamGroupDetails();
                isEdit ? setSnackbarOptions2(options.EDIT_SUCCESS) : setSnackbarOptions(options.ADD_SUCCESS)
                setTimeout(() => {
                    closeDrawer();
                }, 2000);
                setReload(true)
            }
            dispatch(
                updateScreenIdentifiers({
                    storeKey: STORE_KEYS.MACHINES_PARAMETERS_MAPPING_DATA,
                    uniqueScreenIdentifier: {
                        paramGroupMappingLoaded: false
                    }
                })
            );
        }
    }, [stateObj?.[STORE_KEYS.MACHINES_PARAMETERS_MAPPING_DATA]?.paramGroupMappingLoaded]);

    const filteredListEdit = stateObj?.[STORE_KEYS.GET_PARAM_GROUP_DETAILS_DATA]?.body?.data ?? [];
    const closeSnackbar = () => setSnackbarOptions(options.DEFAULT);

    return (
        <div>
            <SnackbarAlert options={snackbarOptions} handleClose={closeSnackbar} />
            <div
                style={{
                    paddingLeft: '30px',
                    paddingRight: '30px',
                    paddingTop: '10px'
                }}
            >
                <Grid container spacing={2}>
                    <Grid item xs={6} lg={3} md={4} sm={6}>
                        <Label label="Group Code" className={'labelDiv'} />
                        <TextInput
                            variant="outlined"
                            fullWidth
                            disabled={isEdit ? true : false}
                            type="text"
                            name="groupCode"
                            value={groupCode}
                            autoComplete="off"
                            formInput="inputDiv"
                            onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
                                setGroupCode(e.target.value);
                            }}
                            onBlur={() => { }}
                            placeholder="Enter Group Code"
                            // helperText={''}
                            helperText={formError.groupCode}
                        />
                    </Grid>
                    <Grid item xs={6} lg={3} md={4} sm={6}>
                        <Label label="Group Name" className={'labelDiv'} />
                        <TextInput
                            variant="outlined"
                            fullWidth
                            type="text"
                            name="groupName"
                            value={groupName}
                            autoComplete="off"
                            formInput="inputDiv"
                            onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
                                setGroupName(e.target.value);
                            }}
                            placeholder="Enter Group Name"
                            // helperText={''}
                            helperText={formError.groupName}
                        />
                    </Grid>
                </Grid>
                <Container
                    maxWidth="xl"
                    style={{
                        paddingLeft: '3px',
                        paddingRight: '3px',
                        marginTop: '30px',
                        display: 'flex',
                        justifyContent: 'space-around'
                    }}
                >
                    <div style={{ width: '48%' }}>
                        <Card
                            style={{
                                borderRadius: '0px',
                                backgroundColor: '#2b2d42',
                                overflow: 'inherit'
                            }}
                        >
                            <CustomTableToolbar
                                drawerOpen={toggleDrawer('right', true)}
                                numSelected={selected?.length}
                                filterName={filterName}
                                resetInput={resetSearch}
                                countNoShow={true}
                                clip={true}
                                onFilterName={handleFilterByName}
                                displayInput={true}
                                text={{ title: 'Model Parameters', subTitle: `${selected?.length} selected` }}
                                filterButton={false}
                                customButton={false}
                            />
                        </Card>
                    </div>
                    <div style={{ width: '48%' }}>
                        <Card
                            style={{
                                borderRadius: '0px',
                                backgroundColor: '#2b2d42',
                                overflow: 'inherit',
                                marginLeft: '3px'
                            }}
                        >
                            <CustomTableToolbar
                                drawerOpen={toggleDrawer('right', true)}
                                numSelected={selected2?.length}
                                filterName={filterName2}
                                resetInput={resetSearch2}
                                clip={true}
                                countNoShow={true}
                                onFilterName={handleFilterByName2}
                                displayInput={true}
                                text={{
                                    title: 'Model Parameters',
                                    subTitle: `${selected2?.length} selected`
                                }}
                                filterButton={false}
                                customButton={false}
                            />
                        </Card>
                    </div>
                </Container>
                <div style={{ display: 'flex', justifyContent: 'space-around' }}>
                    <div className="customscrollBar" style={{ height: '65vh', width: '48%', overflowX: 'hidden' }}>
                        <CustomTable
                            selectable={true}
                            headers={TABLE_HEAD}
                            body={filteredList}
                            filteredUsers={filteredList}
                            filterName={filterName}
                            selected={selected}
                            order={order}
                            orderBy={orderBy}
                            onRequestSort={handleRequestSort}
                            onSelectAllClick={handleSelectAllClick}
                            rowChange={(event: string) => handleClick(event)}
                            rowCount={filteredList.length}
                            onPageChange={handleChangePage}
                            page={page}
                            setPageNumber={setPage}
                            selectRow={showRow}
                            route={handleRoute}
                            isRequestPage={true}
                            link={true}
                            clip={true}
                        />
                    </div>
                    <div
                        className="customscrollBar"
                        style={{ height: '65vh', overflow: 'overlay', width: '48%', overflowX: 'hidden' }}
                    >
                        <CustomTable
                            selectable={true}
                            headers={TABLE_HEAD}
                            body={filteredList2}
                            filteredUsers={filteredList2}
                            filterName={filterName2}
                            selected={selected2}
                            order={order2}
                            orderBy={orderBy2}
                            onRequestSort={() => { }}
                            onSelectAllClick={handleSelectAllClick2}
                            rowChange={(event: string) => {
                                handleClick2(event);
                            }}
                            rowCount={filteredList2.length}
                            onPageChange={handleChangePage}
                            page={page2}
                            setPageNumber={setPage2}
                            selectRow={showRow}
                            route={handleRoute}
                            isRequestPage={true}
                            link={true}
                            clip={true}
                            alreadyAdded={filterList3}
                            addParams={true}
                        />
                    </div>
                </div>
                <br />
                <div style={{ display: 'flex', justifyContent: 'flex-start' }}>
                    <ACGButton
                        divWidth="50%"
                        name={'Add'}
                        handleClick={addParameters}
                        secondary={true}
                        className={'first-btn'}
                        type="button"
                        variant="contained"
                        style={{ marginRight: '25px', padding: '5px', width: '100px', height: '35px' }}
                    />
                    <ACGButton
                        divWidth="50%"
                        name={'Remove'}
                        handleClick={removeParameters}
                        secondary={true}
                        className={'first-btn'}
                        type="button"
                        variant="contained"
                        style={{ marginRight: '25px', padding: '5px', width: '100px', height: '35px' }}
                    />
                </div>
                {renderButtons()}
            </div>
        </div>
    );
};

export default AddParams;
