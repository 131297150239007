import { useEffect, useState } from 'react';
import { Treemap, Tooltip, ResponsiveContainer, Legend } from "recharts";
import './treegraph.scss';
const CustomizedContent = (props: any) => {
    const { root, depth, x, y, width, height, index, colors, name, value, onMouseEnter } = props;
    return (
        <g>
            <rect
                x={x}
                y={y}
                width={width}
                height={height}
                style={{
                    fill: depth < 2 ? "#2b2d42" : root.children[index]?.color,
                    stroke: "#fff",
                    strokeWidth: 1 / (depth + 1e-10),
                    strokeOpacity: 1 / (depth + 1e-10),
                }}
                onMouseEnter={onMouseEnter}
            />
            {depth === 1 ? (
                <text
                    x={x + width / 2}
                    y={y + height / 2 + 7}
                    textAnchor="middle"
                    fill="#fff"
                    fontSize={12}
                >
                    {/* {name} */}
                </text>
            ) : null}
            {depth === 1 ? (
                <text x={x + 4} y={y + 18} fill="#fff" fontSize={12} fillOpacity={0.9}>
                    {/* {index + 1} */}
                </text>
            ) : null}
        </g>
    );
};
const CustomLegend = ({ colors, data }: any) => {
    return (
        <div className='wraplegends'>
            {data.map((entry: any, index: any) => {
                return (
                    <div className='divclstree' key={index}
                        style={{ display: 'flex', alignItems: 'center' }}
                    >
                        <div className='colorslegends' style={{
                            width: 32,
                            height: 10,
                            backgroundColor: colors[index],
                            marginRight: 4,
                        }} />
                        <span className='namelegends' style={{ fontSize: '12px' }}>{entry?.DTCategoryName} </span>
                    </div>
                )
            })}

        </div>

    )
};
export default function TreemapChart(props: any) {
    const [chartData, setChartData] = useState<any>([]);
    const [chartColors, setChartColors] = useState<any>([]);

    useEffect(() => {
        (async () => {
            const data: any = [], planned: any = [], unplanned: any = [], other: any = [];
            const colors: any = [];
            props.dtAnalysis?.sort((a:any,b:any) => b.DTCategoryValue - a.DTCategoryValue)?.map((i: any) => {
                if ( i.DTCategoryName.includes('Unplanned')) {
                    unplanned.push({ name: i.DTCategoryName.trim(), size: i.DTCategoryValue, color: i.DTCategoryCode })
                }
                  else if (i.DTCategoryName.includes('Planned')) {
                    planned.push({ name: i.DTCategoryName.trim(), size: i.DTCategoryValue, color: i.DTCategoryCode })
                } 
                 else {
                    other.push({ name: i.DTCategoryName.trim(), size: i.DTCategoryValue, color: i.DTCategoryCode })
                }
                colors.push(i.DTCategoryCode)
            });
           
            if(other.length > 0) {
                data.push({ name: 'Run Time', children: other })
            }
            if(planned.length > 0) {
                data.push( { name: 'Planned', children: planned })
            } 
            if(unplanned.length > 0) {
                data.push({ name: 'Unplanned', children: unplanned })
            } 
            setChartData(data);
            setChartColors(colors);
        })();
    }, [props.dtAnalysis]);

    const CustomTooltip = ({ active, payload, label }: any) => {
        if (active && payload && payload.length) {

            return (
                <div style={{ background: 'rgba(0, 0, 0, 0.5)', padding: '15px', borderRadius: '5px', height: '60px' }}>
                    <p style={{ color: '#ffff', fontSize: 12, lineHeight: '1px' }}>Name: {`${payload[0].payload.name}`}</p>
                    <p style={{ color: '#ffff', fontSize: 12 }}>Value: {`${Number(payload[0].value).toFixed(2)}`} (in Mins)</p>
                </div>
            );
        }

        return null;
    };

    return (
        <div style={{ width: "100%", display: "inline-flex", background: '#2b2d42', borderRadius: '5px', padding: "10px" }}>
            <div style={{ width: "70%", height: "400px" }}>
                <ResponsiveContainer>
                    {chartData.length > 0 ?
                        <Treemap
                            width={200}
                            height={400}
                            data={chartData ? chartData : []}
                            dataKey="size"
                            stroke="#fff"
                            fill="#8884d8"
                            content={chartData.length > 0 ? <CustomizedContent colors={chartColors} /> : <></>}
                        >
                            {chartData.length > 0 ? <Tooltip
                                content={chartData.length > 0 ? <CustomTooltip /> : <></>} active={false} /> : null}
                        </Treemap>
                        : <></>
                    }
                </ResponsiveContainer>

            </div>
            <div style={{ width: "30%", padding: "0px 10px 0 20px" }}>
                <CustomLegend
                    colors={chartColors}
                    data={props.dtAnalysis}
                /></div>
        </div>
    );
}
