import { useState, useEffect } from 'react';
import Paper from '@mui/material/Paper';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TablePagination from '@mui/material/TablePagination';
import TableRow from '@mui/material/TableRow';
import { PropaneSharp } from '@mui/icons-material';

const columns = [
  {
    id: 'AssemblyName',
    label: 'Assembly Name',
    minWidth: 170,
    align: 'center',

  },
  {
    id: 'ComponentName',
    label: 'Component Name',
    minWidth: 170,
    align: 'center',
  },
  {
    id: 'CalendarLifeInHours',
    label: 'Calendar Life In Hrs',
    minWidth: 10,
    align: 'center',
  },
  {
    id: 'OperatingLifeInHours',
    label: 'Operating Life In Hrs',
    minWidth: 10,
    align: 'center',
  },
  {
    id: 'ReplacementAlertInHours',
    label: 'Replacement Alert In Hrs',
    minWidth: 10,
    align: 'center',
  },
  {
    id: 'AlertsBasisOperatingHours',
    label: 'Alerts Basis Operating Hours',
    minWidth: 10,
    align: 'center',
  },
  {
    id: 'Result',
    label: 'Result',
    minWidth: 50,
    align: 'center',
  },
  {
    id: 'ResultMessage',
    label: 'Result Message',
    minWidth: 170,
    align: 'center',
  },
];

function createData(AssemblyName:any, ComponentName:any, CalendarLifeInHours:any, OperatingLifeInHours:any , ReplacementAlertInHours:any, AlertsBasisOperatingHours:any, Result:any, ResultMessage:any) {

  return { AssemblyName, ComponentName, CalendarLifeInHours, OperatingLifeInHours , ReplacementAlertInHours, AlertsBasisOperatingHours, Result, ResultMessage };
}

export default function StickyHeadTable(props:any) {
  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(10);
  const [rows, setRows] = useState<any>([]);
  const handleChangePage = (event:any, newPage:any) => {
    setPage(newPage);
  };

  useEffect(()=>{
    if(props.sheetResult.length){
        const data : any =[];
        props.sheetResult.map((i:any)=>{
            data.push(createData(i.AssemblyName, i.ComponentName, i.CalendarLifeInHours, i.OperatingLifeInHours , i.ReplacementAlertInHours, i.AlertsBasisOperatingHours, i.Result, i.ResultMessage));
        })
        setRows(data)
    }
},[])

  const handleChangeRowsPerPage = (event:any) => {
    setRowsPerPage(+event.target.value);
    setPage(0);
  };

  return (
    <Paper sx={{ width: '100%', overflow: 'hidden' }}>
      <TableContainer sx={{ maxHeight: 440}}>
        <Table stickyHeader aria-label="sticky table" className='tableColumn clcTh'>
          <TableHead >
            <TableRow>
              {columns.map((column) => (
                <TableCell
                  key={column.id}
                  style={{ minWidth: column.minWidth}}
                >
                  {column.label}
                </TableCell>
              ))}
            </TableRow>
          </TableHead>
          <TableBody>
            {rows
              .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
              .map((row:any) => {
                return (
                  <TableRow hover role="checkbox" tabIndex={-1} key={row.code} className='tableRow clcTd'>
                    {columns.map((column:any) => {
                      const value = row[column.id];
                      return (
                        <TableCell key={column.id} align={column.align}>
                          {column.format && typeof value === 'number'
                            ? column.format(value)
                            : <span style={ column.id === "Result" && value === "Error" ? {color: "red"} : column.id === "Result" && value === "Success" ? {color: "green"} :  {color: "#ffffff"}}>{value}</span>}
                        </TableCell>
                      );
                    })}
                  </TableRow>
                );
              })}
          </TableBody>
        </Table>
      </TableContainer>
      <TablePagination
        style={{color: "#ffffff", boxShadow: "none", background: "#2b2d42"}}
        className='paginationCLC'
        rowsPerPageOptions={[]}
        component="div"
        count={rows.length}
        rowsPerPage={10}
        page={page}
        onPageChange={handleChangePage}
        onRowsPerPageChange={handleChangeRowsPerPage}
      />
    </Paper>
  );
}