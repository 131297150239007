import React, { useState, useEffect } from 'react';
import { useDispatch } from 'react-redux';
import CustomTable from '../../../../../atoms/CustomTable/index';
import CustomTableToolbar from '../../../../../atoms/CustomTableToolbar';
import { Container, Card } from '@mui/material';
import { STORE_KEYS } from '../../../../../constants/apiConstants';
import { resetErr } from '../../../../../store/slice';
import useGetState from '../../../../../utils/hooks/useGetState';
import { applySortFilter } from '../../../../../utils/helper';
import SnackbarAlert from '../../../../../atoms/Snackbar';
import PMECService from '../../OEEServices';
const options = {
    DEFAULT: {
        message: '',
        open: false,
        type: ''
    },
    RM_SUCCESS: {
        message: 'Preset Alert Deleted',
        open: true,
        type: 'success'
    },
};

type Anchor = 'right';

const Users = (props: any) => {
    //console.log('props', props.selectedMachineModel);

    const {
        oeeMaster,
        radioValue,
        selectedClient,
        setDtlData,
        dtlData,
        setReload,
        reload,
        selectedRow,
        setSelectedRow,
        setCreateValue,
        setDrawerHeader,
        setCreateDrawerOpen: setEditDrawerOpen,
        setBreakdownKey,
        getalertsData,
        fetchPresetAlerts,
        setPresetKey,
        appliedFilters,
        clearFilter,
        setCustomerKey,
        setSelectedMachineModel,
        setInDate,
        setEndDate,
        inDate,
        endDate,
    } = props;
    const spped_unit = props.selectedMachineModel === 2 ? 'rpm' : 'bpm';
    const totalQuan_unit = props.selectedMachineModel === 2 ? 'Tablet Count' : 'Blister Count';
    const TABLE_HEAD = [
        { id: 'MachineName', label: 'Machine Name', alignRight: false },
        {
            id: 'OEEPer', label: (
                <span>
                    OEE <br />(%)
                </span>
            ), alignRight: false
        },
        {
            id: 'BatchTime', label: (
                <span>
                    Batch Time <br /> Hrs (A%)
                </span>
            ), alignRight: false
        },
        {
            id: 'AvgSpeed', label: (
                <span>
                    Average Speed <br /> {spped_unit} (P%)
                </span>
            ), alignRight: false
        },
        {
            id: 'TotalProd', label: (
                <span>
                    Total Production <br /> {totalQuan_unit} (Q%)
                </span>
            ), alignRight: false
        },
        { id: 'MachineStatus', label: 'Machine Status', alignRight: false },
        { id: 'BatchStatus', label: 'Batch', alignRight: false }
    ];

    const dispatch = useDispatch();
    const [drawerState, setDrawerState] = useState({
        right: false
    });
    const [page, setPage] = useState<number>(0);
    const [order, setOrder] = useState('asc');
    const [orderBy, setOrderBy] = useState('OEEPer');
    const [selected, setSelected] = useState<any>([]);
    // const [selectedRow, setSelectedRow] = useState<any>({});
    const [filterName, setFilterName] = useState<string>('');
    const [tableData, setTableData] = useState<any[]>([]);
    const [machineModel, setMachineModel] = useState<any>([]);
    const [createDrawerOpen, setCreateDrawerOpen] = useState(false);
    const [isSearch, setIsSearch] = useState(true);
    const state = useGetState();
    const token = state?.[STORE_KEYS.USER_DTL].token;
    const getLoggedUserId = state[STORE_KEYS.USER_DTL]?.LoggedUserKey;

    useEffect(() => {
        if (reload) {
            setReload(false);
            setTableData([]);
        }
    }, [reload]);

    const [snackbarOptions, setSnackbarOptions] = useState(options.DEFAULT);
    const [removeBxOpen, setRemoveBxOpen] = useState(false);
    const [rmUser, setRmUser] = useState(false);
    const [RNVKey, setRNVKey] = useState<any>();
    const closeRmBx = () => {
        setRemoveBxOpen(false);
    };
    const removeFn = async (row: any, ele: any) => {
        setSelectedRow({ ...row });
        setRNVKey(ele?.RNVKey);
        setRemoveBxOpen(true);
    };

    useEffect(() => {
        if (rmUser) {
            closeRmBx();
            const body = { userId: getLoggedUserId, key: selectedRow?.RuleEngKey, table: 'RuleEngine', token: token }
            PMECService.deleteNotificationGM(body)
                .then((response) => {
                    if (response.status === 200) {
                        props.setSnackbarOpts(options.RM_SUCCESS);
                        props.fetchPresetAlerts();
                    }
                })
                .catch((err) => {
                    console.log(err);
                });
            setRmUser(false);
        }
    }, [rmUser]);

    const handleRequestSort = (event: any, property: any) => {
        const isAsc = orderBy === event && order === 'asc';
        setOrder(isAsc ? 'desc' : 'asc');
        setOrderBy(event);
    };

    const handleSelectAllClick = (event: any) => {
        if (event.target.checked) {
            const newSelecteds = [];
            for (let i = 0; i < oeeMaster.length; i++) {
                newSelecteds.push(oeeMaster[i].id);
            }
            const toRemove = oeeMaster.slice(page, oeeMaster.length);
            setSelected(newSelecteds);
            return;
        }
        setSelected([]);
    };

    const handleClick = (requestedBy: string) => {
        const selectedIndex = selected.indexOf(requestedBy);
        let newSelected: any[] | ((prevState: unknown[]) => unknown[]) = [];
        if (selectedIndex === -1) {
            newSelected = newSelected.concat(selected, requestedBy);
        } else if (selectedIndex === 0) {
            newSelected = newSelected.concat(selected.slice(1));
        } else if (selectedIndex === selected.length - 1) {
            newSelected = newSelected.concat(selected.slice(0, -1));
        } else if (selectedIndex > 0) {
            newSelected = newSelected.concat(selected.slice(0, selectedIndex), selected.slice(selectedIndex + 1));
        }
        setSelected(newSelected);
    };

    const toggleDrawer = (anchor: Anchor, open: boolean) => (event: React.KeyboardEvent | React.MouseEvent) => {
        if (
            event.type === 'keydown' &&
            ((event as React.KeyboardEvent).key === 'Tab' || (event as React.KeyboardEvent).key === 'Shift')
        ) {
            return;
        }
        setDrawerState({ ...drawerState, [anchor]: open });
    };

    const filteredList = applySortFilter(oeeMaster, order, filterName, orderBy, isSearch);

    const handleFilterByName = (event: { target: { value: string } }) => {
        setPage(0);
        setFilterName(event.target.value);
        setIsSearch(false);
        setOrderBy('MachineName');
    };

    const handleChangePage = (event: any, newPage: React.SetStateAction<number>) => {
        setPage(newPage);
    };

    const showRow = (event: any) => { };

    const handleSnackbarError = (err: any) => {
        const errorMsg = err?.message || 'Internal Server error';
        const snackbarError = {
            message: errorMsg,
            type: 'reject',
            open: true
        };
        setSnackbarOptions(snackbarError);
    };

    useEffect(() => {
        if (state?.err) {
            handleSnackbarError(state?.err);
            dispatch(resetErr());
        }
    }, [state?.err]);

    const handleRoute = (row: any, ele: any) => {
        if (ele === 'Action') {
            setSelectedRow({ ...row });
            props.fetchPresetAlertDetails(row?.RuleEngKey);
            setCreateDrawerOpen(true);
        }
    };

    const closeSnackbar = () => setSnackbarOptions(options.DEFAULT);
    const removeMd = (ele: any) => {
        // setMdKey(ele?.ModuleKey);
        setRemoveBxOpen(true);
    };
    const resetInput = () => {
        setFilterName('');
        setIsSearch(true);
        setOrderBy('OEEPer');
    };

    return (
        <div className='pmec-table oeedashboard'>
            <div className='pmec-tableInner'>
                <SnackbarAlert options={snackbarOptions} handleClose={closeSnackbar} />
                <Container
                    maxWidth="xl"
                    style={{
                        paddingLeft: '0px',
                        paddingRight: '0px'
                    }}
                >
                    <Card
                        style={{
                            borderRadius: '0px',
                            backgroundColor: '#2b2d42',
                            overflow: 'inherit'
                        }}
                    >
                        <CustomTableToolbar
                            drawerOpen={() => {
                                setEditDrawerOpen(true);
                                setCreateValue("Alerts");
                                setDrawerHeader("Filter");
                                setSelectedMachineModel([]);
                                setCustomerKey('');
                                const date = new Date();
                                date.setDate(date.getDate() - 6)
                                setInDate(date);
                                setEndDate(new Date());
                            }}
                            numSelected={selected?.length}
                            filterName={filterName}
                            onFilterName={handleFilterByName}
                            resetInput={resetInput}
                            displayInput={true}
                            text={{
                                // title: 'All List ',
                                subTitle: `Total - ${filteredList.length} ${appliedFilters === false ? '' : ''}`
                            }}
                            filterButton={false}
                            customButton={false}
                            filterLogsButton={true}
                            appliedFilters={appliedFilters}
                            clearFilter={clearFilter}
                        />
                    </Card>
                </Container>
                <CustomTable
                    selectable={false}
                    headers={TABLE_HEAD}
                    body={filteredList}
                    isLoading={state.isLoading}
                    filteredUsers={filteredList}
                    filterName={filterName}
                    selected={selected}
                    isTableImageReqd={true}
                    order={order}
                    orderBy={orderBy}
                    onRequestSort={handleRequestSort}
                    onSelectAllClick={handleSelectAllClick}
                    rowChange={(event: string) => handleClick(event)}
                    rowCount={filteredList.length}
                    onPageChange={handleChangePage}
                    page={page}
                    setPageNumber={setPage}
                    selectRow={showRow}
                    route={handleRoute}
                    isRequestPage={true}
                    link={true}
                    removeFn={removeFn}
                    {...(radioValue === 'Customer' && !selectedClient
                        ? { noDataText: 'Select Client to display users' }
                        : {})}
                    workflow={false}
                    loadMode={true}
                    rules={true}
                    pmec={true}
                    inDate={inDate}
                    endDate={endDate}
                />
            </div>
        </div>
    );
};

export default Users;
