import React, { useState, useEffect } from 'react';
import { Grid } from '@mui/material';
import Label from '../../../atoms/Label/index';
import TextInput from '../../../atoms/TextInput';
import Attachments from '../../../atoms/Attachments';
import { Avatar } from '@mui/material';
import { convertBlobFileToBase64 } from '../../../utils/helper';
import Box from '@mui/material/Box';
import { green } from '@mui/material/colors';
import Icon from '@mui/material/Icon';
import ModeEditOutlineOutlinedIcon from '@mui/icons-material/ModeEditOutlineOutlined';
import { fetchBlobImage } from '../../../utils/helper';
import Edit from '../../../../assets/images/edit.svg';

interface Props {
    machineModelCode: string;
    MachineModelName: string;
    MachineModelImagePath: string;
    setMachineModelName: Function;
    MachineModelKey: number;
    setMachineModelImage: Function;
    data?: any;
    setImageEdited: Function;
}

const HeaderItems = (props: Props) => {
    const { MachineModelKey, machineModelCode, MachineModelName, setMachineModelName, MachineModelImagePath, setMachineModelImage, data, setImageEdited } = props;
    const [editImage, setEditImage] = useState(false);
    const [selectedModelImage, setSelectedModelImage] = useState<any>([]);

    const machineModelNameChangeHandler = (e: React.ChangeEvent<HTMLInputElement>) => {
        const val = e.currentTarget.value;
        setMachineModelName(val);
    };

    // useEffect(() => {
    //     changeImageUrl();
    // }, [selectedModelImage.length > 0])
    useEffect(() => {
        if (selectedModelImage.length > 0) {
            changeImageUrl();
            setImageEdited(true)
        }

    }, [selectedModelImage, editImage])

    const changeImageUrl = async () => {
        const base64Image = await convertBlobFileToBase64(selectedModelImage[0]);
        const strImage = base64Image?.replace(/^data:image\/[a-z]+;base64,/, "");
        setMachineModelImage(strImage)
    }

    return (
        <div style={{ padding: '24px', borderBottom: '1px solid rgb(43 45 66 / 100%)', marginBottom: '24px' }}>
            <Grid container spacing={2}>
                {editImage
                    ?
                    <Grid item xs={3} lg={1}>
                        {/* <Label label="Upload Image" className={'labelDiv'} /> */}
                        <Attachments
                            viewOnly={false}
                            setError={selectedModelImage}
                            isSetFile={setSelectedModelImage}
                        />
                    </Grid>
                    :
                    <Box className="attachment_wrapper">
                        {<img onClick={() => setEditImage(true)} src={Edit} alt="X" className="closeBtn" />}
                        <>
                            <img
                                src={data.MachineModelCode+".png" ? fetchBlobImage(data.MachineModelCode+".png").url : undefined}
                                alt={'avtar'}
                            />

                        </>
                    </Box>
                }
                <Grid item xs={4} lg={3} md={3} sm={6}>
                    <Label label="Model Code" className={'labelDiv'} />
                    <TextInput
                        variant="outlined"
                        fullWidth
                        readOnly={true}
                        type="text"
                        name="modelCode"
                        value={machineModelCode}
                        autoComplete="off"
                        formInput="inputDiv"
                        onChange={() => { }}
                        onBlur={() => { }}
                        placeholder="Enter Model Code"
                        helperText={''}
                    />
                </Grid>
                <Grid item xs={4} lg={3} md={3} sm={6}>
                    <Label label="Model Name" className={'labelDiv'} />
                    <TextInput
                        variant="outlined"
                        fullWidth
                        type="text"
                        name="modelName"
                        value={MachineModelName}
                        autoComplete="off"
                        formInput="inputDiv"
                        onChange={machineModelNameChangeHandler}
                        onBlur={() => { }}
                        placeholder="Model Name"
                        helperText={''}
                    />
                </Grid>
                {/*    
                <Grid item xs={4} lg={3} md={2} sm={6}>
                    <div
                        style={{
                            display: 'flex',
                            justifyContent: 'center',
                            marginLeft: 'auto',
                            position: 'relative',
                            left: '60%',
                            top: '40%'
                        }}
                    >
                        <ACGButton
                            // disabled={stateObj?.isLoading}
                            name={'Parameter Group'}
                            handleClick={() => setlIstGroupParamDrawer(true)}
                            secondary={true}
                            className={'first-btn'}
                            type="button"
                            variant="contained"
                            style={{ marginRight: '25px', padding: '5px', width: '150px', height: '35px' }}
                        />
                    </div>
                </Grid>
                {/*       
                <Grid item xs={4} lg={3} md={2} sm={6}>
                    <div style={{ display: 'flex', justifyContent: 'center', marginLeft: 'auto' }}>
                        <Button variant="contained" size="small" className="addButton">
                            Download Excel Templete
                        </Button>
                    </div>

                </Grid>
                <Grid>
                    <div style={{ display: 'flex', justifyContent: 'center', marginLeft: 'auto' }}>
                        <Button variant="contained" size="small" className="addButton">
                            Add Parameters
                        </Button>
                    </div>
                </Grid>
                    */}
            </Grid>
        </div>
    );
};

export default React.memo(HeaderItems);
