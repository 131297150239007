import { useEffect, useRef, useState } from 'react';
import { Container, Paper, Typography, IconButton } from '@mui/material';
import { Formik, Form, FormikProps } from 'formik';
import * as Yup from 'yup';
import { MD_YUPSCHEMA } from './schema';
import { CREATEPWD_YUPSCHEMA } from './schema';
import useGetState from '../../../../utils/hooks/useGetState';
import { useDispatch } from 'react-redux';
import { executeACGAction, resetErr, updateScreenIdentifiers } from '../../../../store/slice';
import ACGButton from '../../../../atoms/Button';
import Label from '../../../../atoms/Label/index';
import TextInput from '../../../../atoms/TextInput';
import VisibilityOffIcon from '../../../../../assets/visibilityOffIcon.svg';
// import '../CreateReport/index.scss';
import VisibilityIcon from '../../../../../assets/visibilityIcon.svg';
import { ACTION_CODES, STORE_KEYS } from '../../../../constants/apiConstants';
import SnackbarAlert from '../../../../atoms/Snackbar';
// import useGetState from '../../../../utils/hooks/useGetState';

type ComponentProps = {
    drawerClose: Function;
    setCurrTab?: Function;
    setReload?: Function;
    setSnackbarOpts?: Function;
    handleLogout?: Function;
};
// type ModuleType = {
//     oldPwd: string | '';
//     password: string | '';
//     // mdOrdId: Number | null;
// };
const options = {
    AD_SUCCESS: {
        message: 'Password Updated successfully!',
        open: true,
        type: 'success'
    },
    DEFAULT: {
        message: '',
        open: false,
        type: ''
    }
};
const UpdatePassword = (props: ComponentProps) => {
    const { drawerClose, setCurrTab, setReload = () => { }, handleLogout = () => { } } = props;
    const formRef = useRef<any>(null);
    const [validationSchema, setValidationSchema] = useState({});
    const [initialValues] = useState({
        oldPwd: '',
        password: '',
        confirmPwd: ''
    });
    const [snackbarOptions, setSnackbarOpts] = useState(options.DEFAULT);
    const [showPassword, setShowPassword] = useState(false);
    const [disableSubmit, setDisableSubmit] = useState(true);
    const state = useGetState();
    const userName = state[STORE_KEYS.USER_DTL]
    const handleClick = () => {
        setShowPassword((prev) => !prev);
    };
    const closeSnackbar = () => setSnackbarOpts(options.DEFAULT);
    const [showConfirmPassword, setShowConfirmPassword] = useState(false);
    const [showConfirmPasswordNew, setShowConfirmPasswordNew] = useState(false);
    // const state = useGetState();
    const dispatch = useDispatch();
    const handleClickConfirm = () => {
        setShowConfirmPassword((prev) => !prev);
    };
    const handleClickConfirmPass = () => {
        setShowConfirmPasswordNew((prev) => !prev);
    };

    const renderButtons = () => {
        return (
            <div className={'btns noWrap'}>
                <ACGButton
                    name={'Cancel'}
                    handleClick={drawerClose}
                    secondary={true}
                    className={'first-btn'}
                    type="button"
                    variant="contained"
                />
                <ACGButton
                    name={'Submit'}
                    handleClick={() => { }}
                    type="submit"
                    className="second-btn"
                    variant="contained"
                />
            </div>
        );
    };
    useEffect(() => {
        setValidationSchema(Yup.object().shape(CREATEPWD_YUPSCHEMA));
    }, []);
    const handleSubmit = async () => {
        const userEmail = userName?.EmailId
        const bundle = {
            payload: {
                urlPath: ACTION_CODES.UPDATEPWD,
                requestType: 'POST',
                reqObj: {
                    email: userEmail,
                    currentPassword: formRef?.current?.values?.oldPwd,
                    newPassword: formRef?.current?.values?.password,
                    // moduleOrderId: Number(formRef?.current?.values?.mdOrdId)
                }
            },
            uniqueScreenIdentifier: { mdAdded: true },
            storeKey: STORE_KEYS.UPDATE_PWD
        };
        dispatch(executeACGAction(bundle));
    };
    useEffect(() => {
        if (state?.[STORE_KEYS.UPDATE_PWD]?.statusCode === 200) {
            setSnackbarOpts(options.AD_SUCCESS);
            setTimeout(() => {
                drawerClose();
                handleLogout();
            }, 2000);
        }
        dispatch(
            updateScreenIdentifiers({
                storeKey: STORE_KEYS.UPDATE_PWD,
                uniqueScreenIdentifier: {
                    mdAdded: false
                }
            })
        );
    }, [state?.[STORE_KEYS.UPDATE_PWD]?.statusCode === 200]);
    const handleSnackbarError = (err: any) => {
        const errorMsg = err?.message || 'Internal Server error';
        const snackbarError = {
            message: errorMsg,
            type: 'reject',
            open: true
        };
        setSnackbarOpts(snackbarError);
    };

    useEffect(() => {
        if (state?.err) {
            handleSnackbarError(state?.err);
            dispatch(resetErr());
        }
    }, [state?.err]);

    return (
        <Container className="createReport">
            <SnackbarAlert options={snackbarOptions} handleClose={closeSnackbar} />
            <Formik
                initialValues={initialValues}
                validationSchema={validationSchema}
                onSubmit={() => {
                    handleSubmit();
                }}
                validateOnBlur={false}
                validateOnChange={false}
                innerRef={formRef}
            >
                {(formikprops) => {
                    const { values, handleChange, handleBlur, errors, touched } = formikprops;
                    return (
                        <Form>
                            <Label label="Old Password" className={'labelDiv'} />
                            <TextInput
                                variant="outlined"
                                maxLength={30}
                                autoFocus
                                fullWidth
                                type={showPassword ? 'text' : 'password'}
                                name="oldPwd"
                                value={values.oldPwd}
                                autoComplete="off"
                                formInput="inputDiv"
                                onChange={(e) => {
                                    setDisableSubmit(false);
                                    handleChange(e);
                                }}
                                endAdornment={
                                    <IconButton tabIndex={-1} className="password-eye" onClick={handleClick}>
                                        {showPassword ? (
                                            <img src={VisibilityIcon} alt="logo" />
                                        ) : (
                                            <img src={VisibilityOffIcon} alt="logo" />
                                        )}
                                    </IconButton>
                                }
                                onBlur={handleBlur}
                                placeholder="Enter old password"
                                helperText={errors.oldPwd && touched.oldPwd ? errors.oldPwd : ''}
                            />
                            <Label label="New Password" className={'labelDiv'} />
                            <TextInput
                                variant="outlined"
                                maxLength={50}
                                fullWidth
                                autoComplete="off"
                                type={showConfirmPassword ? 'text' : 'password'}
                                name="password"
                                // formInput="textBoxDiv"
                                formInput="inputDiv"
                                onChange={(e) => {
                                    setDisableSubmit(false);
                                    handleChange(e);
                                }}
                                placeholder="Enter new password"
                                value={values.password}
                                onBlur={handleBlur}
                                endAdornment={
                                    <IconButton tabIndex={-1} className="password-eye" onClick={handleClickConfirm}>
                                        {showConfirmPassword ? (
                                            <img src={VisibilityIcon} alt="logo" />
                                        ) : (
                                            <img src={VisibilityOffIcon} alt="logo" />
                                        )}
                                    </IconButton>
                                }
                                helperText={errors.password && touched.password ? errors.password : ''}
                            />
                            <Label className={'labelDiv'} label="Confirm Password" />
                            <TextInput
                                variant="outlined"
                                maxLength={50}
                                fullWidth
                                autoComplete="off"
                                type={showConfirmPasswordNew ? 'text' : 'password'}
                                name="confirmPwd"
                                // formInput="textBoxDiv"
                                formInput="inputDiv"
                                onChange={(e) => {
                                    setDisableSubmit(false);
                                    handleChange(e);
                                }}
                                endAdornment={
                                    <IconButton tabIndex={-1} className="password-eye" onClick={handleClickConfirmPass}>
                                        {showConfirmPasswordNew ? (
                                            <img src={VisibilityIcon} alt="logo" />
                                        ) : (
                                            <img src={VisibilityOffIcon} alt="logo" />
                                        )}
                                    </IconButton>
                                }
                                placeholder="Enter Confirm Password"
                                value={values.confirmPwd}
                                onBlur={handleBlur}
                                helperText={errors.confirmPwd && touched.confirmPwd ? errors.confirmPwd : ''}
                            />
                            {renderButtons()}
                        </Form>
                    );
                }}
            </Formik>
        </Container>
    );
};
export default UpdatePassword;
