import { useEffect, useRef, useState } from 'react';
import { Container } from '@mui/material';
import { Formik, Form, FormikProps } from 'formik';
import * as Yup from 'yup';
import { MD_YUPSCHEMA } from './schema';
import useGetState from '../../../utils/hooks/useGetState';
import { useDispatch } from 'react-redux';
import { executeACGAction, resetErr, updateScreenIdentifiers } from '../../../store/slice';
import ACGButton from '../../../atoms/Button';
import Label from '../../../atoms/Label/index';
import TextInput from '../../../atoms/TextInput';
// import '../CreateReport/index.scss';
import { ACTION_CODES, STORE_KEYS } from '../../../constants/apiConstants';

type ComponentProps = {
    drawerClose: Function;
    setCurrTab: Function;
    setReload: Function;
    setSnackbarOpts: Function;
    dtlData: any;
    selectedRow: any;
    isEdit: boolean;
    getSummary: Function;
    setDtlData: Function;
    setSelectedRow: Function;
};
type ModuleType = {
    scName: string | '';
    scValue: string | '';
    scDesc: string | '';
};
const options = {
    AD_SUCCESS: {
        message: 'System Configuration created successfully!',
        open: true,
        type: 'success'
    }
};
const CreateModule = (props: ComponentProps) => {
    const { drawerClose, setCurrTab, setReload = () => { }, setSnackbarOpts = () => { }, dtlData, isEdit, getSummary = () => { }, setDtlData = () => { }, selectedRow, setSelectedRow = () => { } } = props;
    const formRef = useRef<any>(null);
    const [validationSchema, setValidationSchema] = useState({});
    const [initialValues] = useState({
        scName: '',
        scValue: '',
        scDesc: ''
    });
    const state = useGetState();
    const dispatch = useDispatch();
    const renderButtons = () => {
        return (
            <div className={'btns noWrap'}>
                <ACGButton
                    name={'Cancel'}
                    handleClick={drawerClose}
                    secondary={true}
                    className={'first-btn'}
                    type="button"
                    variant="contained"
                />
                <ACGButton
                    name={'Submit'}
                    handleClick={() => { }}
                    type="submit"
                    className="second-btn"
                    variant="contained"
                />
            </div>
        );
    };
    useEffect(() => {
        setValidationSchema(Yup.object().shape(MD_YUPSCHEMA));

    }, []);
    const handleSubmit = async () => {
        const bundle = {
            payload: {
                urlPath: isEdit ? ACTION_CODES.EDIT_SYSCONFIG : ACTION_CODES.ADD_SYSCONFIG,
                requestType: isEdit ? 'PUT' : 'POST',
                reqObj: {
                    sysAttributeName: formRef?.current?.values?.scName,
                    sysAttributeValue: formRef?.current?.values?.scValue,
                    sysAttributeDesc: formRef?.current?.values?.scDesc
                }
            },
            uniqueScreenIdentifier: { mdAdded: true },
            storeKey: STORE_KEYS.ADD_SYS_CONFIG
        };
        dispatch(executeACGAction(bundle));
    };
    useEffect(() => {
        if (state?.[STORE_KEYS.ADD_SYS_CONFIG]?.mdAdded) {
            if (state?.[STORE_KEYS.ADD_SYS_CONFIG]?.statusCode === 200) {
                setReload(true);
                setCurrTab(0);
                setSnackbarOpts(options.AD_SUCCESS);
                drawerClose();
            }
            dispatch(
                updateScreenIdentifiers({
                    storeKey: STORE_KEYS.ADD_SYS_CONFIG,
                    uniqueScreenIdentifier: {
                        mdAdded: false
                    }
                })
            );
        }
    }, [state?.[STORE_KEYS.ADD_SYS_CONFIG]?.mdAdded]);
    const handleSnackbarError = (err: any) => {
        const errorMsg = err?.message || 'Internal Server error';
        const snackbarError = {
            message: errorMsg,
            type: 'reject',
            open: true
        };
        setSnackbarOpts(snackbarError);
    };

    useEffect(() => {
        if (state?.err) {
            handleSnackbarError(state?.err);
            dispatch(resetErr());
        }
    }, [state?.err]);

    return (
        <Container className="createReport">
            <Formik
                initialValues={initialValues}
                validationSchema={validationSchema}
                onSubmit={() => {
                    handleSubmit();
                }}
                validateOnBlur={false}
                validateOnChange={false}
                innerRef={formRef}
            >
                {(formikprops: FormikProps<ModuleType>) => {
                    const { values, handleChange, handleBlur, errors, touched } = formikprops;
                    return (
                        <Form>
                            <Label label="Name" className={'labelDiv'} />
                            <TextInput
                                variant="outlined"
                                autoFocus
                                fullWidth
                                type="text"
                                name="scName"
                                value={values.scName}
                                autoComplete="off"
                                formInput="inputDiv"
                                onChange={(e: any) => {
                                    handleChange(e);
                                }}
                                onBlur={handleBlur}
                                placeholder="Enter name"
                                helperText={errors.scName && touched.scName ? errors.scName : ''}
                            />
                            <Label label="Value" className={'labelDiv'} />
                            <TextInput
                                variant="outlined"
                                fullWidth
                                type="text"
                                name="scValue"
                                value={values.scValue}
                                autoComplete="off"
                                formInput="inputDiv"
                                onChange={(e: any) => {
                                    handleChange(e);
                                }}
                                onBlur={handleBlur}
                                placeholder="Enter value"
                                helperText={errors.scValue && touched.scValue ? errors.scValue : ''}
                            />
                            <Label label="Description" className={'labelDiv'} />
                            <TextInput
                                variant="outlined"
                                fullWidth
                                type="text"
                                name="scDesc"
                                value={values.scDesc?.toString()}
                                autoComplete="off"
                                formInput="inputDiv"
                                onChange={(e: any) => {
                                    handleChange(e);
                                }}
                                onBlur={handleBlur}
                                placeholder="Enter description"
                                helperText={errors.scDesc && touched.scDesc ? errors.scDesc : ''}
                            />
                            {renderButtons()}
                        </Form>
                    );
                }}
            </Formik>
        </Container>
    );
};
export default CreateModule;
