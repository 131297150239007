import * as React from 'react';
import { styled } from '@mui/material/styles';
import Box from '@mui/material/Box';
import List from '@mui/material/List';
import ListItem from '@mui/material/ListItem';
import ListItemText from '@mui/material/ListItemText';
import IconButton from '@mui/material/IconButton';
import Grid from '@mui/material/Grid';
import EditIcon from '@mui/icons-material/Edit';
import DeleteIcon from '@mui/icons-material/Delete';

const Demo = styled('div')(({ theme }) => ({
  backgroundColor: theme.palette.background.paper,
}));

export default function GroupMembers(props:any) {
  const [dense, setDense] = React.useState(false);
  const [secondary, setSecondary] = React.useState(false);

  const deleteClicked = (args:any) => {
    props.deleteClicked(args);
  }

  return (
    <Box sx={{ flexGrow: 1, maxWidth: 752 }}>
      <Grid className='usergrid'>
        <Grid item sx={{padding : 0}} className='user_griditem'>
          <Demo className='user_demo'>
            <List dense={dense} className='user_list'>
              {props.groupMemberList?.map( (i:any) =>{
              return(
                <ListItem
                sx={{padding : 0}}
                
                  secondaryAction={
                    <>
                    {/* <IconButton edge="end" aria-label="delete">
                      <EditIcon />
                    </IconButton> */}
                    <IconButton edge="end" aria-label="delete">
                    <DeleteIcon onClick={()=> {deleteClicked(i.NotifGrpDtlsKey)}}/>
                  </IconButton>
                  </>
                  }
                >
                  <ListItemText
                  style={{color: "#ffff"}}
                    primary={i.UserName}
                    secondary={i.EmailId}
                  />
                </ListItem>
              );
              })}
            </List>
          </Demo>
        </Grid>
      </Grid>
    </Box>
  );
}