import { useState, useCallback, useEffect, useMemo } from 'react';
import { Container } from '@mui/material';
import ACGButton from '../../../atoms/Button';
import CustomEditableTable from '../../../atoms/CustomEditableTable';
import HeaderToolbar from '../../../atoms/CustomEditableTable/HeaderToolbar';
import { executeACGAction, resetErr, updateScreenIdentifiers } from '../../../store/slice';
import { useDispatch } from 'react-redux';
import { ACTION_CODES, STORE_KEYS } from '../../../constants/apiConstants';
import useGetState from '../../../utils/hooks/useGetState';
import HeaderItems from './HeaderItems';
import { applySortFilter } from '../../../utils/helper';
import SnackbarAlert from '../../../atoms/Snackbar';
import AddParams from './AddParams';

const options = {
    DEFAULT: {
        message: '',
        open: false,
        type: ''
    },
    ED_SUCCESS: {
        message: 'Machine Parameters updated successfully!',
        open: true,
        type: 'success'
    }
};
const rowsPerPage = 10;
const EditMachine = (props: any) => {
    const { closeDrawer, data } = props;
    const MachineModelKey = data?.MachineModelKey;
    const dispatch = useDispatch();
    const stateObj = useGetState();
    const [parameters, setParameters] = useState<any[]>([]);
    const [searchedText, setSearchedText] = useState<string>('');
    const [page, setPage] = useState<number>(0);
    const [order, setOrder] = useState('desc');
    const [orderBy, setOrderBy] = useState('ParamName');
    const [machineModelName, setMachineModelName] = useState('BQS-name');
    const [snackbarOptions, setSnackbarOptions] = useState(options.DEFAULT);
    const [payload, setPayload] = useState<any>({});

    const getModelParams = () => {
        dispatch(
            executeACGAction({
                payload: {
                    requestType: 'GET',
                    urlPath: ACTION_CODES.GET_MODEL_PARMS + '/' + MachineModelKey
                },
                uniqueScreenIdentifier: { parmsLoaded: true },
                storeKey: STORE_KEYS.MODEL_PARMS
            })
        );
    };

    // For updating the parameters state while editing parametre
    const getUpdatedRowData = useCallback(
        (rowData: any) => {
            const { MMParamKey } = rowData;
            const updatedParameters = parameters.map((parameter) =>
                parameter.MMParamKey === MMParamKey ? { ...rowData, isEdit: true } : parameter
            );
            setParameters(updatedParameters);
        },
        [parameters]
    );

    const renderButtons = () => {
        return (
            <div className={'btns noWrap'} style={{ display: 'flex', float: 'right', marginTop: '15px' }}>
                <ACGButton
                    disabled={stateObj?.isLoading}
                    name={'Cancel'}
                    handleClick={closeDrawer}
                    secondary={true}
                    className={'first-btn'}
                    type="button"
                    variant="contained"
                    style={{ marginRight: '25px', padding: '5px', width: '100px', height: '35px' }}
                />
                <ACGButton
                    disabled={stateObj?.isLoading}
                    name={`${stateObj?.isLoading ? 'Saving...' : 'Save'}`}
                    handleClick={() => {}}
                    type="submit"
                    className="second-btn"
                    variant="contained"
                    style={{ marginRight: '25px', padding: '5px', width: '100px', height: '35px' }}
                />
            </div>
        );
    };

    const handleSnackbarError = (err: any) => {
        const errorMsg = err?.message || 'Internal Server error';
        const snackbarError = {
            message: errorMsg,
            type: 'reject',
            open: true
        };
        setSnackbarOptions(snackbarError);
    };
    useEffect(() => {
        if (stateObj?.err) {
            handleSnackbarError(stateObj?.err);
            dispatch(resetErr());
        }
    }, [stateObj?.err]);

    const closeSnackbar = () => setSnackbarOptions(options.DEFAULT);
    const categories = stateObj?.[STORE_KEYS.ADD_CATEGORY]?.body?.data ?? [];
    const datatypes = stateObj?.[STORE_KEYS.ADD_DATATYPE]?.body?.data ?? [];
    const severities = stateObj?.[STORE_KEYS.ADD_SEVERITY_DROPDOWN]?.body?.data ?? [];
    const handleSubmit = (values: any) => {
        const reqPayload = {
            payload: {
                urlPath: ACTION_CODES.EDIT_MACHINES,
                requestType: 'PUT',
                reqObj: {
                    machineModelKey: values.dropdownModelNameKey,
                    machineSerialNo: values.machineSrNo,
                    machineName: values.machineName,
                    machineFamilyKey: values.dropdownMachineFamilyKey
                }
            },
            uniqueScreenIdentifier: { machineAdded: true },
            storeKey: STORE_KEYS.ADD_RPT
        };
        dispatch(executeACGAction(reqPayload));
        setPayload({
            machineModelKey: values.dropdownModelNameKey,
            machineSerialNo: values.machineSrNo,
            machineName: values.machineName,
            machineFamilyKey: values.dropdownMachineFamilyKey
        });
        return;
    };
    const uploadParams = () => {};
    const downloadparams = () => {};
    const addParams = () => {};

    useEffect(() => {
        if (stateObj?.[STORE_KEYS.ADD_RPT]?.paramsMapped) {
            console.log('calling--again');
            if (stateObj?.[STORE_KEYS.ADD_RPT]?.statusCode === 200) {
                closeDrawer();
                setSnackbarOptions(options.ED_SUCCESS);
            }
            dispatch(
                updateScreenIdentifiers({
                    storeKey: STORE_KEYS.ADD_RPT,
                    uniqueScreenIdentifier: {
                        paramsMapped: false
                    }
                })
            );
        }
    }, [stateObj?.[STORE_KEYS.ADD_RPT]?.paramsMapped]);

    return (
        <div>
            <div>
                <SnackbarAlert options={snackbarOptions} handleClose={closeSnackbar} />
                <Container
                    maxWidth="xl"
                    style={{
                        paddingLeft: '3px',
                        paddingRight: '3px'
                    }}
                >
                    <HeaderItems
                        dtlData={data}
                        uploadParams={uploadParams}
                        downloadparams={downloadparams}
                        addParams={addParams}
                        handleSubmit={handleSubmit}
                    >
                        <div>
                            <AddParams
                                closeDrawer={() => {}}
                                MachineModelKey={MachineModelKey}
                                MachineKey={data.MachineKey}
                                setSnackbarOptions={setSnackbarOptions}
                            />
                        </div>
                        {renderButtons()}
                    </HeaderItems>
                </Container>
            </div>
        </div>
    );
};
export default EditMachine;
