import { useState, useEffect, useCallback, useMemo } from 'react';
import * as XLSX from 'xlsx';
import Axios from 'axios';
import FD from 'js-file-download';
import { useDispatch } from "react-redux";
import Table from "../../../../atoms/StickyHeaderTable";
import { applySortFilter } from "../../../../utils/helper";
import CustomEditableTable from '../../../../atoms/CustomEditMapTable';
import { executeACGAction, reset, resetStoreKey, updateScreenIdentifiers } from "../../../../store/slice";
import { ACTION_CODES, STORE_KEYS, BASEURL } from '../../../../constants/apiConstants';
import ACGButton from '../../../../atoms/Button'
import Attachments from '../../../../atoms/ExcelAttachments';
import useGetState from '../../../../utils/hooks/useGetState';
import Box from '@mui/material/Box';
import Link from '@mui/material/Link';
import Container from '@mui/material/Container';
import Button from '@mui/material/Button';
import DropDown from '../../../../atoms/Dropdown';
import DownloadIcon from '@mui/icons-material/Download';
import UploadIcon from '@mui/icons-material/Upload';
import FormControl from '@mui/material/FormControl';
import Label from '../../../../atoms/Label/index';
import Grid from '@mui/material/Grid';
import "../../ta.css"

const ITEM_HEIGHT = 48;
const ITEM_PADDING_TOP = 8;
const MenuProps = {
    PaperProps: {
        style: {
            maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
            width: 250,
        },
    },
};
const TABLE_HEAD = [
    { id: "AssemblyName", label: "Assembly Name", alignRight: false },
    { id: "ComponentName", label: "Component Name", alignRight: false },
    { id: "CalendarLifeInHours", label: "Calendar Life In Hours", alignRight: false },
    { id: "AlertsBasisOperatingHours", label: "Alerts Basis Operating Hours", alignRight: false },
    { id: "OperatingLifeInHours", label: "Operating Life In Hours", alignRight: false },
    // { id: "ComponentName", label: "Component", alignRight: false },
    { id: "LastReplacedDate", label: "Last Replaced Date", alignRight: false },
    { id: "PostDueReminderInHours", label: "Post Due Reminder In Hours", alignRight: false }
];
const rowsPerPage = 10;
export default function CreateForm(props: any) {
    const dispatch = useDispatch();
    const stateObj = useGetState();
    const [order, setOrder] = useState('desc');
    const [parameters, setParameters] = useState<any[]>([]);
    const [searchedText, setSearchedText] = useState<string>("");
    const [orderBy, setOrderBy] = useState('ParamName');
    const editing = props.componentLSKey !== '' ? true : false;
    const [machineModelKey, setMachineModelKey] = useState('');
    const [page, setPage] = useState<number>(0);
    const [selectedTemplate, setSelectedTemplate] = useState<any>([]);
    const [templateError, setTemplateError] = useState<boolean>(false);
    const [isSetFile, setIsSetFile] = useState<any>([]);
    const [templateSuccess, setTemplateSuccess] = useState(false);
    const [sheetData, setSheetData] = useState<any>([]);
    const [sheetResult, setSheetResult] = useState<any>([]);
    const state = useGetState();
    const token = state?.[STORE_KEYS.USER_DTL].token;
    const loggedInUser = state[STORE_KEYS.USER_DTL]?.LoggedUserKey;
    const [updatedReplaceDate, setupdatedReplaceDate] = useState<any>([]);

    const reset = async () => {
        setMachineModelKey('');
        props.setFilteredMachineMapping(0);
        props.setCLCMappingList([]);
        props.setFilteredSerial(0);
        props.setMachineSerialData([]);
    }

    const options = {
        AD_SUCCESS: {
            message: props.isEditing ? "Successfully edited machine components onboarded." : "Successfully machine components onboarded.",
            open: true,
            type: 'success'
        },
        AD_FAIL: {
            message: props.isEditing ? "Failed to edit onboard machine components" : "Failed to onboard machine components",
            open: true,
            type: 'remark'
        },
    };

    const createNewEntry = (type: string) => {
        const jsonData: any = [];
        props.clcMappingList?.map((itm: any) => {
            jsonData.push({ MCLCModelComponentKey: itm.MCLCModelComponentKey, LastReplacedDate: itm.LastReplacedDate })
        })
        dispatch(
            executeACGAction({
                payload: {
                    requestType: 'POST',
                    urlPath: ACTION_CODES.POST_CLC_MAPPING_LIST,
                    reqObj: {
                        "LoggedUserKey": loggedInUser,
                        "MachineModelKey": parseInt(props.filteredMachineMapping),
                        "MachineKey": parseInt(props.filteredSerial),
                        "ComponentsJson": JSON.stringify(jsonData),
                        "AddEditActivity": type
                    }
                },
                uniqueScreenIdentifier: { clcPostMapList: true },
                storeKey: STORE_KEYS.POST_CLC_MAPPING_LIST
            })
        );
    };

    useEffect(() => {
        if (state?.[STORE_KEYS.POST_CLC_MAPPING_LIST]?.clcPostMapList) {
            const response = state?.[STORE_KEYS.POST_CLC_MAPPING_LIST]?.body?.data;
            setSheetResult(response);
            setTemplateSuccess(false);
            dispatch(
                updateScreenIdentifiers({
                    storeKey: STORE_KEYS.POST_CLC_MAPPING_LIST,
                    uniqueScreenIdentifier: {
                        clcPostMapList: false
                    }
                })
            );
            reset();
            props.setCreateDrawerOpen(false);
            props.setSnackbarOpts(options.AD_SUCCESS)
            props.getOnboardList(props.filteredMachineTab2);
        }
    }, [state?.[STORE_KEYS.POST_CLC_MAPPING_LIST]?.clcPostMapList]);

    useEffect(() => {
        if (props.isEditing) {
            props.getAllMachineSerial(props.selectedRow?.MachineModelKey, "mapping");
            getCLCMappingList(props.selectedRow?.MachineKey, "mapping");
        }
    }, [props.isEditing])

    const getCLCMappingList = (e: any, type: string) => {

        props.setFilteredSerial(e)
        if ((props.selectedRow?.MachineModelKey || props.filteredMachineMapping) && e) {
            dispatch(
                executeACGAction({
                    payload: {
                        requestType: 'POST',
                        urlPath: ACTION_CODES.GET_CLC_MAPPING_LIST,
                        reqObj: {
                            "LoggedUserKey": loggedInUser,
                            "MachineModelKey": type === "mapping" ? parseInt(props.selectedRow?.MachineModelKey) : parseInt(props.filteredMachineMapping),
                            "MachineKey": parseInt(e)
                        }
                    },
                    uniqueScreenIdentifier: { clcList: true },
                    storeKey: STORE_KEYS.GET_CLC_MAPPING_LIST
                })
            );
        }

    }

    useEffect(() => {
        if (state?.[STORE_KEYS.GET_CLC_MAPPING_LIST]?.clcList) {
            const response = state?.[STORE_KEYS.GET_CLC_MAPPING_LIST]?.body?.data;
            props.setCLCMappingList(response);
            dispatch(
                updateScreenIdentifiers({
                    storeKey: STORE_KEYS.GET_CLC_MAPPING_LIST,
                    uniqueScreenIdentifier: {
                        clcList: false
                    }
                })
            );
        }
    }, [state?.[STORE_KEYS.GET_CLC_MAPPING_LIST]?.clcList]);

    const renderButtons = () => {
        return (
            <div className={'btns noWrap'}>
                <ACGButton
                    name={'Cancel'}
                    handleClick={() => { props.drawerClose(); reset() }}
                    secondary={true}
                    className={'first-btn'}
                    type="button"
                    variant="contained"
                />
                <ACGButton
                    name={'Submit'}
                    handleClick={() => { createNewEntry("Add") }}
                    type="submit"
                    className="second-btn"
                    variant="contained"
                    disabled={props.filteredSerial === 0 || props.filteredMachineMapping === 0 ? true : false}
                />
            </div>
        );
    };

    // For updating the parameters state while editing parametre
    const getUpdatedRowData = useCallback((rowData: any) => {

        const { MMParamKey } = rowData;
        const updatedParameters = parameters.map((parameter) => parameter.MMParamKey === MMParamKey ?
            { ...rowData, isEdit: true } : parameter);
        setParameters(updatedParameters);

    }, [parameters])

    const filteredList = applySortFilter(parameters, order, searchedText, orderBy);
    const parametersCount = filteredList?.length;

    const memorizeParameters = useMemo(() => {
        return filteredList.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage);
    }, [page, rowsPerPage, parametersCount, order, orderBy]);

    const handleChangePage = useCallback((event: any, newPage: React.SetStateAction<number>) => {
        setPage(newPage);
    }, [])

    return (
        <Container className="createReport clc-cls">
            <Box>
                <div className='formwrap all-labelcls clclabel'>
                    <FormControl fullWidth>
                        <Grid container spacing={2}>
                            <Grid item md={4}>
                                <FormControl fullWidth>
                                    <Label label="Select Machine Model *" className={'CMlabelDiv'} />
                                    <DropDown
                                        onChange={(e) => props.getAllMachineSerial(e, "")}
                                        className="inputDiv"
                                        name="dropdownModel"
                                        items={props.machineModelData}
                                        placeHolder="Select Machine Model"
                                        value={props.filteredMachineMapping === 0 ? '' : props.filteredMachineMapping}
                                        disabled={props.isEditing}
                                    />
                                </FormControl>
                            </Grid>
                            <Grid item md={4}>
                                <Label label="Machine Serial *" className={'CMlabelDiv'} />
                                <DropDown
                                    onChange={(e) => getCLCMappingList(e.target.value, "")}
                                    className="inputDiv"
                                    name="dropdownModel"
                                    items={props.machineSerialData}
                                    placeHolder="Select Machine Model"
                                    value={props.filteredSerial === 0 ? '' : props.filteredSerial}
                                    disabled={props.isEditing}
                                />
                            </Grid>
                            <Grid item md={12} className='tablemapping'>

                                <CustomEditableTable
                                    headers={TABLE_HEAD}
                                    filteredUsers={props.clcMappingList}
                                    getUpdatedRowData={props.clcMappingList}
                                    parametersCount={parametersCount}
                                    page={page}
                                    rowsPerPage={rowsPerPage}
                                    handleChangePage={handleChangePage}
                                    setPageNumber={setPage}
                                    updatedReplaceDate={updatedReplaceDate}
                                    setCLCMappingList={props.setCLCMappingList}
                                    clcMappingList={props.clcMappingList}
                                />

                            </Grid>
                        </Grid>
                    </FormControl>
                </div>
                <div className='butondrower'>
                    {renderButtons()}
                </div>
            </Box>
        </Container>
    );
}