import { useState } from 'react';
import { Typography, Box } from '@mui/material';
import { useEffect } from 'react';
import './index.scss';
import Remove from '../../../assets/images/remove.svg';
// @ts-ignore
import Files from 'react-files';
import UploadIcon from '@mui/icons-material/Upload';
import Button from '@mui/material/Button';
import ACGButton from '../Button';
// import closeIcon from '../../../assets/images/close-icon.svg';

type Props = {
    click?: any;
    basePath?: any;
    isArrayType?: boolean;
    viewOnly?: boolean;
    formDocs?: any;
    setFormDocs?: any;
    setError?: any;
    setDocsChng?: any;
    type?: any
    isSetFile?: any;
    margin?: boolean;
    isEdit?: any;
    uploadFile?: any;
    setSelectedTemplate?: any;
    templateError?: any;
    setTemplateError?: any;
    selectedTemplate?: any;
    templateSuccess?: any;
};

const Attachments = (props: Props) => {
    const { viewOnly = true, formDocs = [], setFormDocs = () => { }, setError, setDocsChng, type, isSetFile, margin = true, isEdit, uploadFile, templateError, setTemplateError, selectedTemplate, templateSuccess } = props;

    const filesChange = (files: any, e: any) => {
        setTemplateError(false);
        uploadFile(files);
    }
    return (
        <>
            {(selectedTemplate.length === 0 && !templateError && !templateSuccess) || templateError ?
                    <Files
                        className={!type ? "files-dropzone-list" : "files-dropzone-list noBorders"}
                        onChange={filesChange}
                        accepts={type ? type : ['image/png']}
                        multiple={false}
                        maxFileSize={10000000}
                        minFileSize={0}
                        clickable
                    >
                        {!type ? '+' :
                         
                            <Button onClick={() => {filesChange }} type="submit" sx={{ width: "100px" }} >
                                <UploadIcon style={{ width: "30px", height: "30px", color: "#ffffff" }} />

                            </Button>
                        }

                    </Files>

                : null}
            {templateError ? <Typography color={"red"}>Invalid Template !</Typography> : null}
            {templateSuccess ? <Typography color={"#ffffff"}>Uploaded Successfully.</Typography> : null}
        </>
    );
};
export default Attachments;
