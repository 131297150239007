import { useState, useEffect } from 'react';
import * as React from 'react';
import CustomTable from '../../../atoms/CustomTable/index';
import CustomTableToolbar from '../../../atoms/CustomTableToolbar';
import machines from '../../../_mocks_/machines';
import { Container, Card, Box } from '@mui/material';
import models from '../../../_mocks_/models';
// import "../index.scss";
// import { filter } from "lodash";
// import MapCustomTabs from "../../../atoms/ButtonTabs/MapCustomTabs";
// import FilterModal from "../../../atoms/filterModal";
import TemporaryDrawer from '../../../atoms/tempDrawer';
import MachineMonitoringDrawer from '../../../atoms/MachineMonitoringDrawer';
import AlarmDetailDrawer from '../../../atoms/AlarmDetailDrawer';
import { useDispatch } from 'react-redux';
import useGetState from '../../../utils/hooks/useGetState';
import { executeACGAction, resetStoreKey, updateScreenIdentifiers } from '../../../store/slice';
import { ACTION_CODES, STORE_KEYS } from '../../../constants/apiConstants';
import { formatAlarmData, formatAlarmDataObj } from '../../../utils/formatData';
import DropDown from '../../../atoms/Dropdown';
import FilterDrawer from '../../../atoms/FilterDrawer';
import { applySortFilter } from '../../../utils/helper';
import Label from '../../../atoms/Label/index';
// import StatusCard from "../../../atoms/DashboardStatus/StatusCard";

type Anchor = 'right';

const ActiveAlarms = () => {
    const [state, setState] = useState({
        right: false
    });
    const [page, setPage] = useState<number>(0);
    const [order, setOrder] = useState('desc');
    const [orderBy, setOrderBy] = useState('title');
    const [selected, setSelected] = useState<any>([]);
    const [filterName, setFilterName] = useState<string>('');
    const [tableData, setTableData] = useState<any[]>([]);
    const [initialTableData, setInitialTableData] = useState<any[]>([]);
    const [createDrawerOpen, setCreateDrawerOpen] = useState(false);
    const [selectedRow, setSelectedRow] = useState<any>([]);
    const [filterOpen, setFilterOpen] = useState<boolean>(false);
    const [selectedSeverityKey, setSeverityKey] = useState<any>('All Severity');
    const [severityDropdown, setseverityDropdown] = useState<any[]>([]);
    const dispatch = useDispatch();
    const reduxState = useGetState();

    useEffect(() => {
        getActiveAlarms();
        getSeverityDropdownData();
        return () => {
            setTableData([]);
            setInitialTableData([]);
            dispatch(resetStoreKey({ storeKey: STORE_KEYS.SPOCS }));
        };
    }, []);

    const getSeverityDropdownData = () => {
        dispatch(
            executeACGAction({
                payload: {
                    requestType: 'GET',
                    urlPath: ACTION_CODES.GET_SEVERITY_DROPDOWN
                },
                uniqueScreenIdentifier: { severityDropdown: true },
                storeKey: STORE_KEYS.ADD_SEVERITY_DROPDOWN
            })
        );
    };

    useEffect(() => {
        if (
            reduxState?.[STORE_KEYS.ADD_SEVERITY_DROPDOWN]?.body?.data &&
            reduxState?.[STORE_KEYS.ADD_SEVERITY_DROPDOWN]?.statusCode === 200
        ) {
            const __activeAlarms = reduxState?.[STORE_KEYS.ADD_SEVERITY_DROPDOWN]?.body?.data;
            setseverityDropdown(
                __activeAlarms?.map((item: any) => {
                    return { code: item.Severity, name: item.Severity };
                })
            );
            dispatch(resetStoreKey({ storeKey: STORE_KEYS.ADD_SEVERITY_DROPDOWN }));
            dispatch(
                updateScreenIdentifiers({
                    storeKey: STORE_KEYS.ADD_SEVERITY_DROPDOWN,
                    uniqueScreenIdentifier: {
                        severityDropdown: false
                    }
                })
            );
        }
    }, [reduxState?.[STORE_KEYS.ADD_SEVERITY_DROPDOWN]?.severityDropdown]);

    const getActiveAlarms = () => {
        dispatch(
            executeACGAction({
                payload: {
                    requestType: 'GET',
                    urlPath: ACTION_CODES.GET_ALL_ACTIVE_ALARMS_OF_ALARM
                },
                uniqueScreenIdentifier: { activeAlarmsReceived: true },
                storeKey: STORE_KEYS.ACTIVE_ALARMS_OF_ALARM
            })
        );
    };

    useEffect(() => {
        if (
            reduxState?.[STORE_KEYS.ACTIVE_ALARMS_OF_ALARM]?.body?.data &&
            reduxState?.[STORE_KEYS.ACTIVE_ALARMS_OF_ALARM]?.statusCode === 200
        ) {
            const __activeAlarms = formatAlarmData(reduxState?.[STORE_KEYS.ACTIVE_ALARMS_OF_ALARM]?.body?.data);
            const selectedAlarms = __activeAlarms.filter((i: { MachineKey: { toString: () => string | null; }; }) => i.MachineKey.toString() === localStorage.getItem('selectedAlaramMachineKey'));
            setTableData(localStorage.getItem('selectedAlaramMachineKey') ? selectedAlarms : __activeAlarms);
            setInitialTableData(localStorage.getItem('selectedAlaramMachineKey') ? selectedAlarms : __activeAlarms);
            dispatch(resetStoreKey({ storeKey: STORE_KEYS.ACTIVE_ALARMS_OF_ALARM }));
        }
        dispatch(
            updateScreenIdentifiers({
                storeKey: STORE_KEYS.ACTIVE_ALARMS_OF_ALARM,
                uniqueScreenIdentifier: {
                    activeAlarmsReceived: false
                }
            })
        );
    }, [reduxState?.[STORE_KEYS.ACTIVE_ALARMS_OF_ALARM]?.activeAlarmsReceived]);

    const handleChangePage = (event: any, newPage: React.SetStateAction<number>) => {
        setPage(newPage);
    };

    const toggleDrawer = (anchor: Anchor, open: boolean) => (event: React.KeyboardEvent | React.MouseEvent) => {
        if (
            event.type === 'keydown' &&
            ((event as React.KeyboardEvent).key === 'Tab' || (event as React.KeyboardEvent).key === 'Shift')
        ) {
            return;
        }
        setState({ ...state, [anchor]: open });
    };

    const handleFilterByName = (event: { target: { value: React.SetStateAction<string> } }) => {
        setPage(0);
        setFilterName(event.target.value);
    };

    const filteredList = applySortFilter(tableData, order, filterName, orderBy);

    const getSpocs = (machineKey: any) => {
        dispatch(
            executeACGAction({
                payload: {
                    requestType: 'GET',
                    urlPath: `${ACTION_CODES.GET_SPOC_DTL}/${machineKey}`
                },
                uniqueScreenIdentifier: { spocsRecd: true },
                storeKey: STORE_KEYS.SPOCS
            })
        );
    };

    const handleRoute = (row: any, ele: any) => {
        const __row = { ...row };
        if (ele === 'Action') {
            const __data = { ...formatAlarmDataObj(row) };
            setSelectedRow(__data);
            setCreateDrawerOpen(true);
        } else {
            getSpocs(row.MachineKey);
            setSelectedRow(__row);
            setCreateDrawerOpen(true);
        }
    };
    
    const closeDrawer = () => {
        setSelectedRow([]);
        setCreateDrawerOpen(false);
        // setTimeout(() => {
        //   window.scrollTo(0,0)
        // }, 100);
    };

    const clearFilter = () => {
        setPage(0);
        setTableData(initialTableData);
        setSeverityKey([]);
        setFilterOpen(false);
    };
    const applyFilter = () => {
        setPage(0);
        setFilterName('');
        setOrderBy('title');
        setOrder('desc');
        if (selectedSeverityKey !== 'All Severity') {
            const newData = [...initialTableData];
            const x = newData.filter((ele: any) => ele?.status == selectedSeverityKey);
            setTableData(x);
        } else {
            setTableData(initialTableData);
        }
        setFilterOpen(false);
    };

    const resetInput = () => {
        setFilterName('');
    };

    return (
        <div>
            <div>
                <Container
                    maxWidth="xl"
                    style={{
                        paddingLeft: '3px',
                        paddingRight: '3px'
                    }}
                >
                    <Card
                        style={{
                            borderRadius: '0px',
                            backgroundColor: '#2b2d42',
                            overflow: 'inherit'
                        }}
                    >
                        <CustomTableToolbar
                            drawerOpen={() => setFilterOpen(true)}
                            numSelected={selected?.length}
                            filterName={filterName}
                            onFilterName={handleFilterByName}
                            resetInput={resetInput}
                            displayInput={true}
                            text={{
                                title: 'Active Alarms',
                                subTitle: `${tableData?.length} Alarms`
                            }}
                            filterButton={true}
                            customButton={false}
                        />
                    </Card>
                </Container>
                <CustomTable
                    selectable={false}
                    headers={[]}
                    body={filteredList}
                    filteredUsers={filteredList}
                    filterName={filterName}
                    selected={[]}
                    order={''}
                    orderBy={''}
                    onRequestSort={() => { }}
                    onSelectAllClick={() => { }}
                    rowChange={() => { }}
                    rowCount={filteredList.length}
                    onPageChange={handleChangePage}
                    page={page}
                    setPageNumber={setPage}
                    selectRow={() => { }}
                    route={handleRoute}
                    isRequestPage={true}
                    link={true}
                    type="alarmList"
                />
            </div>
            {/* <MachineMonitoringDrawer
                handlerOpen={() => {}}
                handlerClose={closeDrawer}
                status={createDrawerOpen}
                data={selectedRow}
                type={'models'}
        /> */}
            <AlarmDetailDrawer
                handlerOpen={() => { }}
                handlerClose={closeDrawer}
                status={createDrawerOpen}
                data={selectedRow}
                block={true}
                type="all"
                usedIn=""
            />
            <FilterDrawer
                handlerClose={() => {
                    setFilterOpen(false);
                    // setFilterGrp(-1);
                }}
                status={filterOpen}
                drawerHeader={'Filter'}
                children={
                    <Container className="createReport">
                        <Box style={{ minHeight: '75vh' }}>
                            <Label label="Severity" className={'labelDiv'} />
                            <DropDown
                                onChange={(event: any) => {
                                    setSeverityKey(event.target.value);
                                }}
                                // label="Severity"
                                placeHolder="Select Severity"
                                name="dropdownSeverity"
                                className="inputDiv"
                                items={severityDropdown}
                                value={selectedSeverityKey}
                            />
                        </Box>
                    </Container>
                }
                clearFn={clearFilter}
                applyFn={applyFilter}
            />
        </div>
    );
};

export default ActiveAlarms;
