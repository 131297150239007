import { Box, Container, FormControl, Grid } from '@mui/material'
import React, { useEffect, useState } from 'react'
import Label from '../Label/index'
import TextInput from '../TextInput'
import DropDown from '../Dropdown'
import { useDispatch } from 'react-redux'
import { executeACGAction, resetErr } from '../../store/slice'
import { ACTION_CODES, STORE_KEYS } from '../../constants/apiConstants'
import useGetState from '../../utils/hooks/useGetState'
import { renameObjKeys } from '../../features/Login/helper'
import ACGButton from '../Button'

import styles from "./style.module.scss"
import SnackbarAlert from '../Snackbar'

const options = {
  DEFAULT: {
    message: '',
    open: false,
    type: ''
  }
};
type ComponentsProps = {
  MachineKey: string | number,
  TabName: string,
  editDetails: { isEdit: boolean, values: any },
  handlerClose:any
}
function CreateAlertDrawer(props: ComponentsProps) {
  const { MachineKey, TabName, editDetails ,handlerClose} = props
  const dispatch = useDispatch()
  const stateVal = useGetState();
  const loggedInUser = stateVal[STORE_KEYS.USER_DTL]?.LoggedUserKey;

  const [snackbarOptions, setSnackbarOptions] = useState(options.DEFAULT);
  const [parametersList, setParametersList] = useState<any[]>([])
  const [paramList, setParamList] = useState<any>(null)
  const [operatorList, setOperatorList] = useState<any[]>([])
  const [severityList, setSeverityList] = useState<any[]>([])

  const [alertName, setAlertName] = useState<any>(editDetails.isEdit ? editDetails.values.AlertName : "")
  const [selectParamValue, setSelectParamValue] = useState<any>("")
  const [textDropParamValue, setTextDropParamValue] = useState<any>("")
  const [operatorValue, setOperatorValue] = useState<any>("")
  const [severityValue, setSeverityValue] = useState<any>("")
  const [isSubmit, setIsSubmit] = useState<boolean>(false)
  const [errors, setErrors] = useState<{ alertName: Boolean, parameter: Boolean, textDropParam: Boolean, operator: Boolean, severity: Boolean }>({
    alertName: false, parameter: false, textDropParam: false, operator: false, severity: false
  })
  // const [validationError, setValidationError] = useState('');
  const closeSnackbar = () => setSnackbarOptions(options.DEFAULT);
  // const handleBlur = () => {
  //   // Define a regular expression pattern for characters and spaces
  //   const characterAndSpacePattern = /^[a-zA-Z\s]*$/;

  //   if (!characterAndSpacePattern.test(alertName)) {
  //     setValidationError('Please enter only characters and spaces.');
  //   } else {
  //     setValidationError('');
  //   }
  // };
  const handleUserNameChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const inputValue = event.target.value;
    setAlertName(inputValue);
    // const alphabeticRegex = /^[a-zA-Z]*$/;

    // if (inputValue.match(alphabeticRegex) || inputValue === '') {

    //     setAlertName(inputValue);
    // }
}
  const getClientList = () => {
    dispatch(
      executeACGAction({
        payload: {
          requestType: 'POST',
          urlPath: ACTION_CODES.GET_CUSTOM_PARAMETERS,
          reqObj: {
            "MachineKey": MachineKey,
            "ParameterType": TabName
          }
        },
        storeKey: STORE_KEYS.CUSTOM_ALERTS_PARAMETERS
      })
    );
  };
  const getParameterValue = () => {
    dispatch(
      executeACGAction({
        payload: {
          requestType: 'POST',
          urlPath: ACTION_CODES.GET_CUSTOM_PARAMETER_VALUE,
          reqObj: {
            "ParamKey": selectParamValue
          }
        },
        storeKey: STORE_KEYS.CUSTOM_ALERTS_PARAMETER_VALUE
      })
    );
  };
  const getOperatorsList = () => {
    dispatch(
      executeACGAction({
        payload: {
          requestType: 'POST',
          urlPath: ACTION_CODES.GET_CUSTOM_OPERATORS,
          reqObj: {
            "DataType": paramList?.[0].DataType
          }
        },
        storeKey: STORE_KEYS.CUSTOM_ALERTS_OPERATORS
      })
    );
  };
  const getSeverityList = () => {
    dispatch(
      executeACGAction({
        payload: {
          requestType: 'GET',
          urlPath: ACTION_CODES.GET_CUSTOM_SEVERITY,
        },
        storeKey: STORE_KEYS.CUSTOM_ALERTS_SEVERITY
      })
    );
  };

  const handleSubmit = () => {
    if(!errors.alertName && !errors.operator && !errors.parameter && !errors.severity && !errors.textDropParam){

    dispatch(
      executeACGAction({
        payload: {
          requestType: 'POST',
          urlPath: ACTION_CODES.SUBMIT_CUSTOM_ADD_ALERT,
          reqObj: {
            "LoggedUserKey": loggedInUser,
            "MachineKey": MachineKey,
            "AlertName": alertName,
            "MMParamKey": selectParamValue,
            "OperatorKey": operatorValue,
            "ParamValue": textDropParamValue,
            "SeverityKey": severityValue,
            "MachineCustomAlertKey": null,
            "AddEditActivity": "Add"
          }
        },
        storeKey: STORE_KEYS.CUSTOM_ALERTS_SUCCESS
      })
    );
  }
    setIsSubmit(true)
    
  }
  const handleEdit = () => {
    if(!errors.alertName && !errors.operator && !errors.parameter && !errors.severity && !errors.textDropParam){
    dispatch(
      executeACGAction({
        payload: {
          requestType: 'POST',
          urlPath: ACTION_CODES.SUBMIT_CUSTOM_ADD_ALERT,
          reqObj: {
            "LoggedUserKey": loggedInUser,
            "MachineKey": MachineKey,
            "AlertName": alertName,
            "MMParamKey": selectParamValue,
            "OperatorKey": operatorValue,
            "ParamValue": textDropParamValue,
            "SeverityKey": severityValue,
            "MachineCustomAlertKey": editDetails?.values?.MachineCustomAlertKey,
            "AddEditActivity": "Edit"
          }
        },
        storeKey: STORE_KEYS.CUSTOM_ALERTS_EDIT_SUCCESS
      })
    );
  }
    setIsSubmit(true)
  }
  const handleCancel=()=>{
    setIsSubmit(false)
    handlerClose()
  }

  useEffect(() => {
    getClientList()
    getSeverityList()
  }, []);
  useEffect(() => {
    if (selectParamValue != "") getParameterValue()
  }, [selectParamValue]);
  useEffect(() => {
    if (paramList) getOperatorsList()
  }, [paramList]);

  useEffect(() => {
    if (stateVal?.[STORE_KEYS.CUSTOM_ALERTS_PARAMETERS]?.body?.data) {
      const grpsList = stateVal?.[STORE_KEYS.CUSTOM_ALERTS_PARAMETERS]?.body?.data?.map((item: any) => {
        const newVal = renameObjKeys(item, { MMParamKey: 'code', ParamName: 'name' });
        return newVal;
      });
      setParametersList(grpsList);
    }

  }, [stateVal?.[STORE_KEYS.CUSTOM_ALERTS_PARAMETERS]])
  useEffect(() => {

    if (stateVal?.[STORE_KEYS.CUSTOM_ALERTS_PARAMETER_VALUE]?.body?.data) {
      const grpsList = stateVal?.[STORE_KEYS.CUSTOM_ALERTS_PARAMETER_VALUE]?.body?.data?.map((item: any) => {
        const newVal = renameObjKeys(item, { ParamValue: 'code', ParamValueName: 'name' });
        return newVal;
      });
      setParamList(grpsList);

    }

  }, [stateVal?.[STORE_KEYS.CUSTOM_ALERTS_PARAMETER_VALUE]])

  useEffect(() => {

    if (stateVal?.[STORE_KEYS.CUSTOM_ALERTS_OPERATORS]?.body?.data) {
      const grpsList = stateVal?.[STORE_KEYS.CUSTOM_ALERTS_OPERATORS]?.body?.data?.map((item: any) => {
        const newVal = renameObjKeys(item, { OperatorKey: 'code', OperatorName: 'name' });
        return newVal;
      });
      setOperatorList(grpsList);
    }

  }, [stateVal?.[STORE_KEYS.CUSTOM_ALERTS_OPERATORS]])
  useEffect(() => {

    if (stateVal?.[STORE_KEYS.CUSTOM_ALERTS_SEVERITY]?.body?.data) {
      const grpsList = stateVal?.[STORE_KEYS.CUSTOM_ALERTS_SEVERITY]?.body?.data?.map((item: any) => {
        const newVal = renameObjKeys(item, { SeverityKey: 'code', SeverityName: 'name' });
        return newVal;
      });
      setSeverityList(grpsList);
    }

  }, [stateVal?.[STORE_KEYS.CUSTOM_ALERTS_SEVERITY]])
  useEffect(() => {
    if (stateVal?.[STORE_KEYS.CUSTOM_ALERTS_SUCCESS]?.body?.data?.[0]?.Result == "Success" && isSubmit) {
      const errorMsg = "Successfully Added the Custom Alert Rule Details";
      const snackbarError = {
        message: errorMsg,
        type: 'success',
        open: true
      };
      setSnackbarOptions(snackbarError);
      setIsSubmit(false)
      setTimeout( () => {handleCancel()}, 5000 )
    }
    if (stateVal?.[STORE_KEYS.CUSTOM_ALERTS_SUCCESS]?.body?.data?.[0]?.Result == "Error" && isSubmit) {

      const errorMsg = stateVal?.[STORE_KEYS.CUSTOM_ALERTS_SUCCESS]?.body?.data?.[0]?.ResultMessage || 'Internal Server error';
      const snackbarError = {
        message: errorMsg,
        type: 'reject',
        open: true
      };
      setSnackbarOptions(snackbarError);
      setIsSubmit(false)
    }

  }, [stateVal?.[STORE_KEYS.CUSTOM_ALERTS_SUCCESS]])
  useEffect(() => {
    if (stateVal?.[STORE_KEYS.CUSTOM_ALERTS_EDIT_SUCCESS]?.body?.data?.[0]?.Result == "Success" && isSubmit) {
      const errorMsg = "Successfully Updated the Custom Alert Rule Details";
      const snackbarError = {
        message: errorMsg,
        type: 'success',
        open: true
      };
      setSnackbarOptions(snackbarError);
      setIsSubmit(false)
      setTimeout( () => {handleCancel()}, 5000 )
    }
    if (stateVal?.[STORE_KEYS.CUSTOM_ALERTS_EDIT_SUCCESS]?.body?.data?.[0]?.Result == "Error" && isSubmit) {

      const errorMsg = stateVal?.[STORE_KEYS.CUSTOM_ALERTS_EDIT_SUCCESS]?.body?.data?.[0]?.ResultMessage || 'Internal Server error';
      const snackbarError = {
        message: errorMsg,
        type: 'reject',
        open: true
      };
      setSnackbarOptions(snackbarError);
      setIsSubmit(false)
    }

  }, [stateVal?.[STORE_KEYS.CUSTOM_ALERTS_EDIT_SUCCESS]])
  useEffect(() => {
    if (editDetails.isEdit && parametersList.length) {
      const NewParamValue = parametersList?.find((item) => item?.name == editDetails?.values?.ParamName)
      setSelectParamValue(NewParamValue?.code)
    }
  }, [editDetails, parametersList])
  useEffect(() => {
    if (editDetails.isEdit && operatorList.length) {
      setOperatorValue(editDetails?.values?.OperatorKey)
    }
  }, [editDetails, operatorList])
  useEffect(() => {
    
    if (editDetails.isEdit && paramList?.[0].UIType == "Textbox") {
      setTextDropParamValue(editDetails?.values?.ParamValue)
    }
    else if (editDetails.isEdit && paramList?.[0].UIType == "Dropdown") {
      const NewParamValue = paramList?.find((item: any) => item?.code == editDetails?.values?.ParamValue)
      setTextDropParamValue(NewParamValue?.code)
    }

  }, [editDetails, paramList])
  useEffect(() => {
    if (editDetails.isEdit && severityList?.length) {
      const NewParamValue = severityList?.find((item: any) => item?.name == editDetails?.values?.SeverityName)
      setSeverityValue(NewParamValue?.code)
    }
  }, [editDetails, severityList])

  useEffect(() => {
    if (alertName.length) {
      setErrors((prev) => ({ ...prev, alertName: false }))
    } else {
      setErrors((prev) => ({ ...prev, alertName: true }))
    }
    if (selectParamValue>0) {
      setErrors((prev) => ({ ...prev, parameter: false }))
    } else {
      setErrors((prev) => ({ ...prev, parameter: true }))
    }
    if (textDropParamValue.length) {
      setErrors((prev) => ({ ...prev, textDropParam: false }))
    } else {
      setErrors((prev) => ({ ...prev, textDropParam: true }))
    }
    if (operatorValue>0) {
      setErrors((prev) => ({ ...prev, operator: false }))
    } else {
      setErrors((prev) => ({ ...prev, operator: true }))
    }
    if (severityValue>0) {
      setErrors((prev) => ({ ...prev, severity: false }))
    } else {
      setErrors((prev) => ({ ...prev, severity: true }))
    }
  }, [alertName, selectParamValue, textDropParamValue, operatorValue, severityValue])
  return (
    <Container className="createReportt">
      <SnackbarAlert options={snackbarOptions} handleClose={closeSnackbar} />
      <Box>
        <div className='formwrap all-labelcls'>
          <FormControl fullWidth>
            <Grid container>
              <Grid item xs={12}>
                <FormControl fullWidth>
                  <Label label="Alert Name &nbsp; *" className={'CMlabelDiv'} />

                  <TextInput
                    fullWidth
                    placeholder='Enter Alert Name'
                    id="outlined-basic"
                    className="multilinetxt"
                    multiline
                    maxRows={4}
                    sx={{ margin: '0px', padding: '2px' }}
                    value={alertName}
                    onChange={handleUserNameChange}
                    // onBlur={handleBlur}
                  // size='500'
                  />
                  {/* </Box> */}
                </FormControl>
                {/* {validationError && (
        <div className={styles.errorss}>{validationError}</div> */}
      {/* )} */}
                {isSubmit && errors.alertName ? <div className={styles.errorss}>Please enter name</div> : null}
              </Grid>
              <Grid item xs={12}>
                <FormControl fullWidth>
                  <Label label="Parameter List &nbsp; *" className={'CMlabelDiv'} />
                  <DropDown
                    className="inputDiv"
                    name="dropdownModel"
                    items={parametersList}
                    placeHolder="Select Parameter"
                    value={selectParamValue}
                    onChange={(event) => {
                      setSelectParamValue(event.target.value);
                    }}
                  />
                </FormControl>
                {isSubmit && errors.parameter ? <div className={styles.errorss}>Please Select Parameter</div> : null}
              </Grid>
              <Grid item xs={12}>
                <FormControl fullWidth>
                  <Label label="Operator &nbsp; *" className={'CMlabelDiv'} />
                  <DropDown
                    className="inputDiv"
                    name="dropdownModel"
                    items={operatorList}
                    placeHolder='Select Operator'
                    value={operatorValue}
                    onChange={(event) => {
                      setOperatorValue(event.target.value);
                    }}
                  />
                </FormControl>
                {isSubmit && errors.operator ? <div className={styles.errorss}>Please Select Operator</div> : null}
              </Grid>
              {paramList?.[0].UIType == "Textbox" && <Grid item xs={12}>
                <FormControl fullWidth>
                  <Label label="Param Value &nbsp; *" className={'CMlabelDiv'} />

                  <TextInput
                    type={paramList?.[0].DataType == "FLOAT" ? 'number' : 'text'}
                    fullWidth
                    placeholder='Enter Param Value'
                    id="outlined-basic"
                    className="multilinetxt"

                    sx={{ margin: '0px', padding: '2px' }}
                    value={textDropParamValue}
                    onChange={(e) => setTextDropParamValue(e.target.value)}
                  // size='500'
                  />
                  {/* </Box> */}
                </FormControl>
                {isSubmit && errors.textDropParam ? <div className={styles.errorss}>Please Enter Param Value</div> : null}
              </Grid>}
              {paramList?.[0].UIType == "Dropdown" && <Grid item xs={12}>
                <FormControl fullWidth>
                  <Label label="Param Value &nbsp; *" className={'CMlabelDiv'} />
                  <DropDown
                    className="inputDiv"
                    name="dropdownModel"
                    items={paramList}
                    placeHolder="Select Param Value"
                    value={textDropParamValue}
                    onChange={(event) => {
                      setTextDropParamValue(event.target.value);
                    }}
                  />
                </FormControl>
                {isSubmit && errors.textDropParam ? <div className={styles.errorss}>Please Select Param Value</div> : null}
              </Grid>}

              <Grid item xs={12}>
                <FormControl fullWidth>
                  <Label label="Severity &nbsp; *" className={'CMlabelDiv'} />
                  <DropDown
                    className="inputDiv"
                    name="dropdownModel"
                    items={severityList}
                    placeHolder='Select Severity'
                    value={severityValue}
                    onChange={(event) => {
                      setSeverityValue(event.target.value);
                    }}
                  />
                </FormControl>
                {isSubmit && errors.severity ? <div className={styles.errorss}>Please Select Severity</div> : null}
              </Grid>

            </Grid>
            <div className={styles.btnBox}>
              <ACGButton
                name={'Cancel'}
                handleClick={handleCancel}
                secondary={true}
                className={'first-btn'}
                type="button"
                variant="contained"
              />
              {editDetails.isEdit ? <ACGButton
                name={'Update'}
                handleClick={handleEdit}
                type="submit"
                className="second-btn"
                variant="contained"
              // disabled={yesDisabled ? true : false}
              /> : <ACGButton
                name={'Submit'}
                handleClick={handleSubmit}
                type="submit"
                className="second-btn"
                variant="contained"
              // disabled={yesDisabled ? true : false}
              />}
            </div>
          </FormControl>
        </div>
      </Box>

    </Container>
  )
}

export default CreateAlertDrawer