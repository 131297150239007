import { Paper, Grid, Typography, IconButton } from '@mui/material';
import { Formik, Form } from 'formik';
import { useState, useEffect, useRef } from 'react';
import * as Yup from 'yup';
import { useHistory } from 'react-router-dom';
import Button from '../../../../atoms/Button/index';
import Label from '../../../../atoms/Label/index';
import TextInput from '../../../../atoms/TextInput/index';
import { FORGOTPWD_YUPSCHEMA } from './schema';
import VisibilityIcon from '../../../../../assets/visibilityIcon.svg';
import VisibilityOffIcon from '../../../../../assets/visibilityOffIcon.svg';
import { useDispatch, useSelector } from 'react-redux';
import { ACTION_CODES, STORE_KEYS } from '../../../../constants/apiConstants';
import { executeACGAction, resetErr, updateScreenIdentifiers } from '../../../../store/slice';
import { acgSelector } from '../../../../store/selector';
import { createStructuredSelector } from 'reselect';
import SnackbarAlert from '../../../../atoms/Snackbar';

const options = {
    DEFAULT: {
        message: '',
        open: false,
        type: ''
    }
};

type ComponentProps = { data: any };
const ForgotPwdDtlTemplate = (props: ComponentProps) => {
    const { data } = props;
    const dispatch = useDispatch();
    const history = useHistory();
    const [validationSchema, setValidationSchema] = useState({});
    const [showPassword, setShowPassword] = useState(false);
    const [showConfirmPassword, setShowConfirmPassword] = useState(false);
    const [snackbarOptions, setSnackbarOptions] = useState(options.DEFAULT);
    const [disableProceed, setDisableProceed] = useState(true);
    const handleClickConfirm = () => {
        setShowConfirmPassword((prev) => !prev);
    };
    const handleClick = () => {
        setShowPassword((prev) => !prev);
    };
    const formRef = useRef<any>(null);
    const [initialValues] = useState({
        answer: '',
        password: '',
        confirmPwd: ''
    });
    const acgStateSelector = createStructuredSelector({
        acgSlice: acgSelector()
    });
    const { acgSlice: state } = useSelector(acgStateSelector);

    useEffect(() => {
        setValidationSchema(Yup.object().shape(FORGOTPWD_YUPSCHEMA));
    }, []);

    const handleSubmit = async () => {
        const bundle = {
            payload: {
                urlPath: ACTION_CODES.FORGOTPWD,
                requestType: 'POST',
                reqObj: {
                    email: data?.values?.email,
                    forgetPwdQnkey: data?.values?.forgetPwdQnkey,
                    answer: formRef?.current?.values?.answer.replace(/'/g, "''"),
                    newPassword: formRef?.current?.values?.password
                }
            },
            uniqueScreenIdentifier: { setForgotPwd: true },
            storeKey: STORE_KEYS.ADD_QA
        };
        dispatch(executeACGAction(bundle));
    };

    useEffect(() => {
        if (state?.addQa?.setForgotPwd) {
            if (state?.addQa?.statusCode === 200) {
                dispatch(
                    updateScreenIdentifiers({
                        storeKey: STORE_KEYS.ADD_QA,
                        uniqueScreenIdentifier: {
                            setForgotPwd: false
                        }
                    })
                );
                data?.setTemplateType('passwordSuccess');
            }
        }
    }, [state?.addQa?.setForgotPwd]);

    const handleSnackbarError = (err: any) => {
        const errorMsg = err?.message || 'Internal Server error';
        const snackbarError = {
            message: errorMsg,
            type: 'reject',
            open: true
        };
        setSnackbarOptions(snackbarError);
    };

    useEffect(() => {
        if (state?.err) {
            if (state?.err?.message?.toLowerCase()?.includes('is locked')) {
                data?.setTemplateType('lockAccount');
                dispatch(resetErr());
            } else {
                handleSnackbarError(state?.err);
            }
        }
    }, [state?.err]);

    const closeSnackbar = () => setSnackbarOptions(options.DEFAULT);
    return (
        <Paper elevation={2} className="loginPaper createPass">
            <SnackbarAlert className="login-snackbar" options={snackbarOptions} handleClose={closeSnackbar} />
            <div className="loginFormHeader">
                <Typography className="welcomeText">Create Password</Typography>
                <Typography className="loginText">Create your new password</Typography>
            </div>
            <Formik
                initialValues={initialValues}
                validationSchema={validationSchema}
                onSubmit={() => {
                    handleSubmit();
                }}
                validateOnBlur={false}
                innerRef={formRef}
            >
                {(formikprops) => {
                    const { values, handleChange, handleBlur, isValid, errors, touched } = formikprops;
                    return (
                        <Form>
                            <Label className="password-class" label={state?.changePwd?.body?.data?.[0]?.Question} />
                            <TextInput
                                variant="outlined"
                                maxLength={50}
                                fullWidth
                                autoComplete="off"
                                type="text"
                                name="answer"
                                formInput="textBoxDiv"
                                onChange={(e) => {
                                    const userChange = 'inputType' in e?.nativeEvent;
                                    if (userChange) {
                                        setDisableProceed(false);
                                        handleChange(e);
                                    }
                                }}
                                placeholder="Enter answer"
                                value={values.answer}
                                onBlur={handleBlur}
                                helperText={errors.answer && touched.answer ? errors.answer : ''}
                            />

                            <Label className="password-class" label="New Password" />
                            <TextInput
                                variant="outlined"
                                maxLength={50}
                                fullWidth
                                autoComplete="off"
                                type={showPassword ? 'text' : 'password'}
                                name="password"
                                formInput="textBoxDiv"
                                onChange={(e) => {
                                    const userChange = 'inputType' in e?.nativeEvent;
                                    if (userChange) {
                                        setDisableProceed(false);
                                        handleChange(e);
                                    }
                                }}
                                endAdornment={
                                    <IconButton tabIndex={-1} className="password-eye" onClick={handleClick}>
                                        {showPassword ? (
                                            <img src={VisibilityIcon} alt="logo" />
                                        ) : (
                                            <img src={VisibilityOffIcon} alt="logo" />
                                        )}
                                    </IconButton>
                                }
                                placeholder="Enter New Password"
                                value={values.password}
                                onBlur={handleBlur}
                                helperText={errors.password && touched.password ? errors.password : ''}
                            />
                            <Label className="password-class" label="Confirm Password" />
                            <TextInput
                                variant="outlined"
                                maxLength={50}
                                fullWidth
                                autoComplete="off"
                                type={showConfirmPassword ? 'text' : 'password'}
                                name="confirmPwd"
                                formInput="textBoxDiv"
                                onChange={(e) => {
                                    setDisableProceed(false);
                                    handleChange(e);
                                }}
                                endAdornment={
                                    <IconButton tabIndex={-1} className="password-eye" onClick={handleClickConfirm}>
                                        {showConfirmPassword ? (
                                            <img src={VisibilityIcon} alt="logo" />
                                        ) : (
                                            <img src={VisibilityOffIcon} alt="logo" />
                                        )}
                                    </IconButton>
                                }
                                placeholder="Enter Confirm Password"
                                value={values.confirmPwd}
                                onBlur={handleBlur}
                                helperText={errors.confirmPwd && touched.confirmPwd ? errors.confirmPwd : ''}
                            />
                            <Grid container spacing={2}>
                                <Grid item xs={12} sm={6} md={6} xl={6} lg={6}>
                                    <Button
                                        formInput="buttonDiv"
                                        fullWidth
                                        name="Cancel"
                                        handleClick={() => history.push('/')}
                                        secondary
                                        type="button"
                                        variant="contained"
                                    />
                                </Grid>
                                <Grid item xs={12} sm={6} md={6} xl={6} lg={6}>
                                    <Button
                                        formInput="buttonDiv"
                                        fullWidth
                                        name="Proceed"
                                        type="submit"
                                        variant="contained"
                                        secondary={false}
                                        disabled={disableProceed || !isValid}
                                    />
                                </Grid>
                            </Grid>
                        </Form>
                    );
                }}
            </Formik>
        </Paper>
    );
};

export default ForgotPwdDtlTemplate;
