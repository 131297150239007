// feature/UserReport/DownloadableReport/BatchReport/index
import Drawer from '@mui/material/Drawer';
import { Form, Formik, FormikProps } from 'formik';
import Label from '../../../../atoms/Label/index';
import ACGButton from '../../../../atoms/Button';
import { useEffect, useRef, useState } from 'react';
import CustomDatePicker from '../../../../atoms/CustomDatePicker';
import { getLastSixMonths } from "../../../../utils/helper";
import './index.scss';
import { FormHelperText} from '@mui/material';
import DownArrow from '../../../../../assets/down-arrow.svg';
import DropDown from '../../../../atoms/Dropdown';
import InputLabel from '@mui/material/InputLabel';
import { VALIDATION_YUPSCHEMA } from './ValidationSchema';
import * as Yup from 'yup';
import {
    Checkbox,
    Container,
    FormControl,
    ListItemText,
    MenuItem,
    Select,
} from '@mui/material';
import { executeACGAction, reset, resetStoreKey } from '../../../../store/slice';
import { ACTION_CODES, BASEURL, STORE_KEYS } from '../../../../constants/apiConstants';
import useGetState from '../../../../utils/hooks/useGetState';
import { renameObjKeys } from '../../../Login/helper';
import { useDispatch } from 'react-redux';
import dateFormat from 'dateformat';
import Axios from 'axios';
import FileDownload from 'js-file-download';
import Box from '@mui/material/Box';
import { SnackBarOptions, Dateformat } from '../CommonUtil';
import SnackbarAlert from '../../../../atoms/Snackbar';
import { trackErrorsInNewRelic } from '../../../../utils/newRelicFuncations';

type Props = {
    status: any;
    handlerOpen: any;
    handlerClose: any;
    data?: any;
    drawerHeader?: any;
    block?: boolean;
};

type BatchParams = {
    dateFromSelect: string | '';
    dateToSelect: string | '';
    dropdownPlant: string | '';
    dropdownModel: string | '';
    dropdownMachineParams: string[];
    dropdownSerialNo: string | '';
};

const ITEM_HEIGHT = 48;
const ITEM_PADDING_TOP = 8;
const MenuProps = {
    PaperProps: {
        style: {
            maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
            width: 250
        }
    }
};

export default function AuditReportDrawer(props: Props) {
    const { status, handlerClose } = props;

    const [snackbarOptions, setSnackbarOptions] = useState(SnackBarOptions.DEFAULT);

    const [statusMessage, setStatusMessage] = useState<string>();

    const DropDownIcon = (prop: any) => (
        <img style={{ marginRight: '5px', marginTop: '-2px' }} src={DownArrow} {...prop} />
    );

    const drawerClose = () => {
        handlerClose();
    };

    const [batchParams, setBatchParams] = useState<BatchParams>({
        dateFromSelect: dateFormat(new Date().setHours(-(24 * 7)), Dateformat.YYYY_MM_DD),
        dateToSelect: dateFormat(new Date().setHours(-24), Dateformat.YYYY_MM_DD),
        dropdownPlant: '',
        dropdownModel: '',
        dropdownMachineParams: [],
        dropdownSerialNo: '',
    });

    const [plantsList, setPlantsList] = useState<any>();
    //const [selectedPlant, setSelectedPlant] = useState<any>();

    const [machineModels, setMachineModels] = useState<any>();
    const [selectedModel, setSelectedModel] = useState<any>();

    const [machineParams, setMachineParams] = useState<any>([]);
    const [selectedMachineParams, setSelectedMachineParams] = useState<any>([]);

    const [machineSerialNos, setMachineSerialNos] = useState<any>();
    const [selectedMachineSerialNo, setSelectedMachineSerialNo] = useState<any>();

    const [batches, setBatches] = useState<any>();
    const [isSubmit, setIsSubmit] = useState<any>(false);
    const [isGenerating, setIsGenerating] = useState<boolean>(false);
    const [reportResponse, setReportResponse] = useState<any>(null);

    const [isDownloadError, setIsDownloadError] = useState<boolean>(false);

    const [validationSchema, setValidationSchema] = useState({});

    const state = useGetState();
    const dispatch = useDispatch();
    const formRef = useRef<any>(null);
    const REPORT_CODE = 'BATRPT';
    const [selectedBatch, setSelectedBatch] = useState<any>([]);
    const closeSnackbar = () => setSnackbarOptions(SnackBarOptions.DEFAULT);
    const [presetKey, setPresetKey] = useState('');
    const [noBatches, setNoBatches] = useState(true);
    const userEmail = state[STORE_KEYS.USER_DTL]?.EmailId;
    useEffect(() => {
        window.newrelic.setCustomAttribute('LoggedInUser', userEmail);
        window.newrelic.addPageAction('Batch Report', { action: 'Landed on Batch Report page' });
    }, [])
    useEffect(() => {
        const date = new Date();
        dispatch(resetStoreKey({ storeKey: STORE_KEYS.DN_REPORT_PLANTS_DATA }));
        dispatch(resetStoreKey({ storeKey: STORE_KEYS.DN_REPORT_PLANTS_DATA }));
        resetResult();

        setMachineSerialNos(null);
        setMachineParams([]);
        setMachineModels(null);

        setMachineModels(null);
        setSelectedModel(null);
        setMachineParams(null);
        setSelectedMachineParams(null);
        setMachineSerialNos(null);
        setSelectedMachineSerialNo(null);
        setBatches(null);
    
        setBatchParams({
            ...batchParams,
            dateFromSelect: dateFormat(
                new Date(new Date(date.getFullYear(), date.getMonth(), 1)),
                Dateformat.YYYY_MM_DD
            ),
            dateToSelect: dateFormat(
                new Date(),
                Dateformat.YYYY_MM_DD
            ),
            dropdownPlant: '',
            dropdownModel: '',
            dropdownMachineParams: [],
            dropdownSerialNo: '',
        });

        if (status) {
            setValidationSchema(Yup.object().shape(VALIDATION_YUPSCHEMA));
            dispatch(
                executeACGAction({
                    payload: {
                        requestType: 'POST',
                        urlPath: ACTION_CODES.DN_REPORT_PLANTS_DATA,
                        reqObj: {
                            reportCode: REPORT_CODE
                        }
                    },
                    storeKey: STORE_KEYS.DN_REPORT_PLANTS_DATA
                })
            );
        }
    }, [status]);

    useEffect(() => {
        if (state?.[STORE_KEYS.DN_REPORT_PLANTS_DATA]?.body?.data) {
            const data = state?.[STORE_KEYS.DN_REPORT_PLANTS_DATA]?.body?.data;
            const plants = data?.map((item: any) => {
                const newVal = renameObjKeys(item, { PlantKey: 'code', PlantName: 'name' });
                return newVal;
            });
            setPlantsList(plants);
            updateStatusMessage();
            dispatch(resetStoreKey({ storeKey: STORE_KEYS.DN_REPORT_PLANTS_DATA }));
        }
    }, [state?.[STORE_KEYS.DN_REPORT_PLANTS_DATA]?.body?.data]);

    useEffect(() => {
        if (state?.[STORE_KEYS.DN_ALMANLS_REPORT_MACHINE_MODEL_DATA]?.body?.data) {
            const data = state?.[STORE_KEYS.DN_ALMANLS_REPORT_MACHINE_MODEL_DATA]?.body?.data;
            const models = data?.map((item: any) => {
                const newVal = renameObjKeys(item, { MachineModelKey: 'code', MachineModelName: 'name' });
                return newVal;
            });
            setMachineModels(models);
            updateStatusMessage();
            dispatch(resetStoreKey({ storeKey: STORE_KEYS.DN_ALMANLS_REPORT_MACHINE_MODEL_DATA }));
        }
    }, [state?.[STORE_KEYS.DN_ALMANLS_REPORT_MACHINE_MODEL_DATA]?.body?.data]);

    useEffect(() => {
        if (state?.[STORE_KEYS.DN_REPORT_LINE_SERIAL_DATA]?.body?.data) {
            const data = state?.[STORE_KEYS.DN_REPORT_LINE_SERIAL_DATA]?.body?.data;
            let index = 0;
            const cats = data?.map((item: any) => {
                const item1 = Object.assign({}, item, { code: index });
                const newVal = renameObjKeys(item1, { MachineKey: 'code', LineMachineName: 'name' });
                index++;
                return newVal;
            });
            setMachineSerialNos(cats);
            dispatch(resetStoreKey({ storeKey: STORE_KEYS.DN_REPORT_LINE_SERIAL_DATA }));
        }
    }, [state?.[STORE_KEYS.DN_REPORT_LINE_SERIAL_DATA]?.body?.data]);

    useEffect(() => {
        batchParams.dropdownPlant && dispatch(
            executeACGAction({
                payload: {
                    requestType: 'POST',
                    urlPath: ACTION_CODES.DN_ALMANLS_REPORT_MACHINE_MODEL_DATA,
                    reqObj: {
                        reportCode: REPORT_CODE,
                        plantKey: batchParams.dropdownPlant
                    }
                },
                storeKey: STORE_KEYS.DN_ALMANLS_REPORT_MACHINE_MODEL_DATA
            })
        );

        if (selectedModel) {
            dispatch(
                executeACGAction({
                    payload: {
                        requestType: 'POST',
                        urlPath: ACTION_CODES.DN_REPORT_OPTIONAL_PARAMS,
                        reqObj: {
                            mmKey: selectedModel
                        }
                    },
                    storeKey: STORE_KEYS.DN_REPORT_OPTIONAL_PARAMS
                })
            );
            batchParams.dropdownPlant && dispatch(
                executeACGAction({
                    payload: {
                        requestType: 'POST',
                        urlPath: ACTION_CODES.DN_REPORT_LINE_SERIAL_DATA,
                        reqObj: {
                            machineModelKey: selectedModel,
                            plantKey: batchParams.dropdownPlant,
                            reportCode: REPORT_CODE
                        }
                    },
                    storeKey: STORE_KEYS.DN_REPORT_LINE_SERIAL_DATA
                })
            );
            setSelectedModel(null);
        }
        if (selectedMachineSerialNo) {
            batchParams.dateFromSelect && batchParams.dateToSelect && dispatch(
                executeACGAction({
                    payload: {
                        requestType: 'POST',
                        urlPath: ACTION_CODES.DN_REPORT_BATCH_TYPE,
                        reqObj: {
                            mKey: selectedMachineSerialNo,
                            fDate: dateFormat(batchParams.dateFromSelect, Dateformat.YYYY_MM_DD),
                            tDate: dateFormat(batchParams.dateToSelect, Dateformat.YYYY_MM_DD)
                        }
                    },
                    storeKey: STORE_KEYS.DN_REPORT_BATCH_TYPE
                })
            );
        }
    }, [selectedMachineSerialNo, selectedModel, batchParams.dateToSelect, batchParams.dateFromSelect, batchParams.dropdownPlant]);

    useEffect(() => {
        if (state?.[STORE_KEYS.DN_REPORT_BATCH_TYPE]?.body?.data) {
            const data = state?.[STORE_KEYS.DN_REPORT_BATCH_TYPE]?.body?.data;
            const dpBatches = data?.map((item: any) => {
                const newVal = renameObjKeys(item, { BatchKey: 'code', BatchNo: 'name' });
                return newVal;
            });

            setBatches(dpBatches);

            dispatch(resetStoreKey({ storeKey: STORE_KEYS.DN_REPORT_BATCH_TYPE }));
        }

        if (state?.[STORE_KEYS.DN_REPORT_OPTIONAL_PARAMS]?.body?.data) {
            const data = state?.[STORE_KEYS.DN_REPORT_OPTIONAL_PARAMS]?.body?.data;
            const optParams = data?.map((item: any) => {
                const newVal = renameObjKeys(item, { MMParamKey: 'code', ParamName: 'name', paramName2: 'ala' });
                return newVal;
            });
            setMachineParams(optParams);
            dispatch(resetStoreKey({ storeKey: STORE_KEYS.DN_REPORT_OPTIONAL_PARAMS }));
        }

        if (state?.[STORE_KEYS.DN_REPORT_LINE_SERIAL_DATA]?.body?.data) {
            const data = state?.[STORE_KEYS.DN_REPORT_LINE_SERIAL_DATA]?.body?.data;
            const optParams = data?.map((item: any) => {
                const newVal = renameObjKeys(item, { MachineKey: 'code', LineMachineName: 'name' });
                return newVal;
            });
            setMachineSerialNos(optParams);
            dispatch(resetStoreKey({ storeKey: STORE_KEYS.DN_REPORT_OPTIONAL_PARAMS }));
        }
    }, [
        state?.[STORE_KEYS.DN_REPORT_BATCH_TYPE]?.body?.data,
        state?.[STORE_KEYS.DN_REPORT_OPTIONAL_PARAMS]?.body?.data,
        state?.[STORE_KEYS.DN_REPORT_LINE_SERIAL_DATA]?.body?.data
    ]);

    const handleSubmit = async (values: any) => {
                if(selectedBatch.length === 0) {
            setNoBatches(true);
            return;
        } else setNoBatches(false);
        //alert("Submit");
        setIsSubmit(false)
        const batch:any = [], batchName:any = [];
        const fromDate = dateFormat(values.dateFromSelect, Dateformat.YYYY_MM_DD);
        const toDate = dateFormat(values.dateToSelect, Dateformat.YYYY_MM_DD);
        const plant = plantsList.find((item: any) => item.code == values.dropdownPlant);
        const model = machineModels.find((item: any) => item.code == values.dropdownModel);
        const params = machineParams?.filter((item: any) => selectedMachineParams.includes(item.code));
        const serialNo = machineSerialNos.find((item: any) => item.code == values.dropdownSerialNo);
        selectedBatch.map((i:any)=> {batch.push(i.code); batchName.push(i.name)})

        const optParams = params?.map((item: any) => {
            const newVal = renameObjKeys(item, { code: 'MMParamKey', name: 'ParamName', ala: 'ParamName2' });
            return newVal;
        });

        if (fromDate && toDate && model && serialNo && plant) {

            const payload = {
                startDate: fromDate,
                endDate: toDate,
                plant: plant.name,
                machineModel: model.name,
                batch: batch ? batch.name : null,
                serialNo: serialNo.name,
                msno: serialNo.name,
                bsDate: batch ? batch.BatchStartTime : null,
                beDate: batch ? batch.BatchEndTime : null,
                batchKey: batch,
                batchNames: batchName,
                optParam: optParams || null
            };

            const token = state?.[STORE_KEYS.USER_DTL].token;

            //resetResult();

            setIsGenerating(true);

            Axios({
                url: BASEURL + '/' + ACTION_CODES.DN_BATCH_REPORT_DATA,
                method: 'post',
                responseType: 'blob',
                data: payload,
                headers: {
                    Authorization: 'Bearer ' + token
                }
            })
                .then((response) => {
                    if (response.status == 200 && response?.data) {
                        setIsDownloadError(false);
                        setIsGenerating(false);
                        setReportResponse(response.data);
                    }
                })
                .catch((error) => {
                  trackErrorsInNewRelic({error: error})

                    setIsGenerating(false);
                    setIsDownloadError(true);
                    return {
                        status: error.response.status
                    };
                });
        } else {
            alert('Please select all the fields or bad request');
        }
    };

    const sendEmail = () => {

        const token = state?.[STORE_KEYS.USER_DTL].token;
        const userEmail = state?.[STORE_KEYS.USER_DTL]?.EmailId;
        const fileName = batchParams?.dropdownModel + '_Batch_report.xlsx';

        if (reportResponse && token && userEmail && fileName) {

            setSnackbarOptions(SnackBarOptions.SENDING_EMAIL);

            const formData = new FormData();
            formData.append('file', reportResponse, 'file.xlsx');
            formData.append('filename', fileName);
            formData.append('userId', userEmail);

            Axios({
                url: BASEURL + '/' + ACTION_CODES.DN_RPT_EMAIL_REPORT,
                method: 'post',
                data: formData,
                headers: {
                    'Content-Type': 'multipart/form-data',
                    Authorization: 'Bearer ' + token
                }
            })
                .then((response) => {
                    if (response.status == 200 && response?.data) {
                        setIsDownloadError(false);
                        setSnackbarOptions(SnackBarOptions.SENT_EMAIL);
                    }
                    else {
                        setSnackbarOptions(SnackBarOptions.ERROR_SENDING_EMAIL);
                    }
                })
                .catch((error) => {
                  trackErrorsInNewRelic({error: error})
                    setIsDownloadError(true);
                    setSnackbarOptions(SnackBarOptions.ERROR_SENDING_EMAIL);
                    return {
                        status: error.response.status
                    };
                });
        }
    };

    const isOptional = (values: any) => {
        return values.dateFromSelect &&
            values.dateToSelect &&
            new Date(values.dateToSelect).getTime() - new Date(values.dateFromSelect).getTime() <= 48 * 60 * 60 * 1000;
    };

    const updateStatusMessage = () => {
        const message = plantsList?.length <= 0 ? 'No plants available' :
            machineModels?.length <= 0 ? 'No models available' :
                machineSerialNos?.length <= 0 ? 'No machine serial number available' :
                    batches?.length <= 0 ? 'No batches available' : '';
        setStatusMessage(message);
    };

    const resetResult = () => {
        setSelectedBatch([]);
        setNoBatches(true);
        setIsDownloadError(false);
        setIsGenerating(false);
        setReportResponse(null);
        setIsSubmit(false);
    };
    const crossClick = () => {
        setIsDownloadError(false);
        setIsGenerating(false);
        setReportResponse(null);
        setIsSubmit(false);
    };
    const handleChangeBatch = (e:any, id: any) => {
       setNoBatches(false);
        if(selectedBatch.includes(id) || selectedBatch.length < 3){
        let newSelected: any = [];
        if (presetKey === '') {
            const selectedIndex = selectedBatch.indexOf(id);
            if (selectedIndex === -1) {
                newSelected = newSelected.concat(selectedBatch, id);
            } else if (selectedIndex === 0) {
                newSelected = newSelected.concat(selectedBatch.slice(1));
            } else if (selectedIndex === selectedBatch.length - 1) {
                newSelected = newSelected.concat(selectedBatch.slice(0, -1));
            } else if (selectedIndex > 0) {
                newSelected = newSelected.concat(
                    selectedBatch.slice(0, selectedIndex),
                    selectedBatch.slice(selectedIndex + 1)
                );
            }
        }
        else {
            const selectedIndex = selectedBatch.findIndex((t: any) => t.BatchNo === id.BatchNo);
            if (selectedIndex === -1) {
                newSelected = newSelected.concat(selectedBatch, id);
            } else if (selectedIndex === 0) {
                newSelected = newSelected.concat(selectedBatch.slice(1));
            } else if (selectedIndex === selectedBatch.length - 1) {
                newSelected = newSelected.concat(selectedBatch.slice(0, -1));
            } else if (selectedIndex > 0) {
                newSelected = newSelected.concat(
                    selectedBatch.slice(0, selectedIndex),
                    selectedBatch.slice(selectedIndex + 1)
                );
            }
        }
        setSelectedBatch(newSelected);
    }
    }

    return (
        <div>
            <SnackbarAlert options={snackbarOptions} handleClose={closeSnackbar} />
            <Drawer anchor={'right'} open={status} onClose={drawerClose} style={{ zIndex: '9999' }} className='allreports'>
                <Box role="presentation" className={'standardBox1'}>
                    <div className="baseDiv">
                        <div
                            onClick={() => {
                                resetResult();
                                drawerClose();
                            }}
                            style={{
                                border: '1px solid #5D97F6',
                                borderRadius: '50px',
                                height: '25px',
                                width: '25px',
                                position: 'absolute',
                                left: '20px',
                                top: '16px',
                                cursor: 'pointer'
                            }}
                        >
                            <span
                                style={{
                                    position: 'relative',
                                    fontSize: '12px',
                                    color: '#5D97F6',
                                    left: '35%',
                                    top: '-3%'
                                }}
                            >
                                X
                            </span>
                        </div>
                        <div
                            style={{
                                display: 'flex',
                                justifyContent: 'space-between',
                                alignItems: 'flex-start',
                                width: '100%'
                                // padding:'10px'
                            }}
                        >
                            <div style={{ display: 'block', marginLeft: '45px' }}>
                                <h6
                                    style={{
                                        color: '#DADCEE',
                                        fontSize: '16px',
                                        fontFamily: 'Montserrat'
                                        // marginTop: '-7px'
                                    }}
                                >
                                    Batch Report
                                </h6>
                            </div>
                        </div>
                    </div>
                    <Container style={{ backgroundColor: '#2b2d42' }} className={'alm-dn-report-form1'}>
                        <Formik
                            initialValues={batchParams}
                            validationSchema={validationSchema}
                            onSubmit={(values: any) => {
                                handleSubmit(values);
                            }}
                            validateOnBlur={false}
                            validateOnChange={true}
                            innerRef={formRef}
                        >
                            {(formikprops: FormikProps<any>) => {
                                const {
                                    values,
                                    handleChange,
                                    handleBlur,
                                    isValid,
                                    errors,
                                    setTouched,
                                    touched,
                                    setFieldValue,
                                    resetForm
                                } = formikprops;
                                
                                return (
                                    <Form className="detailForm">
                                        <Label label="From Date" className={'labelDiv'} />
                                        <CustomDatePicker
                                            name="dateFromSelect"
                                            value={values.dateFromSelect}
                                            className="inputDiv"
                                            onChange={(val: any) => {
                                                
                                                setFieldValue('dateFromSelect', val);
                                                setBatchParams({ ...batchParams, dateFromSelect: val });
                                                setSelectedBatch([]);
                                                setBatches([]);

                                                resetResult();
                                            }}
                                            inputFormat="dd/MM/yyyy"
                                            placeholder="Select From Date"
                                            minDate={getLastSixMonths()}
                                        />
                                        {errors?.dateFromSelect && (
                                            <div style={{ marginTop: '10px' }}>
                                                <span
                                                    style={{
                                                        color: '#F06868',
                                                        fontSize: '11px',
                                                        fontWeight: 600,
                                                        letterSpacing: 0,
                                                        marginTop: '20px',
                                                        marginBottom: '15px',
                                                        fontFamily: 'Montserrat',
                                                        marginLeft: '5%'
                                                    }}
                                                >
                                                    {errors?.dateFromSelect}
                                                </span>
                                            </div>
                                        )}
                                        <Label label="To Date" className={'labelDiv'} />
                                        <CustomDatePicker
                                            name="dateToSelect"
                                            value={values.dateToSelect}
                                            className="inputDiv"
                                            onChange={async (val: any) => {
                                                setSelectedBatch([]);
                                                setBatches([]);
                                                setTouched({}, false);  
                                                await setFieldValue('dateToSelect', val);
                                                setBatchParams({ ...batchParams, dateToSelect: val });
                                                resetResult();
                                            }}
                                            inputFormat="dd/MM/yyyy"
                                            placeholder="dateToSelect"
                                            minDate={values.dateFromSelect}
                                        />
                                        {errors?.dateToSelect && (
                                            <div style={{ marginTop: '10px' }}>
                                                <span
                                                    style={{
                                                        color: '#F06868',
                                                        fontSize: '11px',
                                                        fontWeight: 600,
                                                        letterSpacing: 0,
                                                        marginTop: '20px',
                                                        marginBottom: '15px',
                                                        fontFamily: 'Montserrat',
                                                        marginLeft: '5%'
                                                    }}
                                                >
                                                    {errors?.dateToSelect}
                                                </span>
                                            </div>
                                        )}
                                        <Label label="Plants" className={'labelDiv'} />
                                        <DropDown
                                            onChange={(plantEvent) => {
                                                handleChange(plantEvent);
                                                const plant = plantsList.find(
                                                    (item: any) => item.code === plantEvent.target.value
                                                );
                                                if (plant) {

                                                    setBatchParams({ ...batchParams, dropdownPlant: plant.code.toString() });
                                                    setSelectedBatch([]);
                                                    setSelectedModel(null);
                                                    setSelectedModel(null);
                                                    setFieldValue('dropdownModel', '');

                                                    setSelectedMachineParams([]);
                                                    setFieldValue('dropdownMachineParams', []);

                                                    setMachineSerialNos(null);
                                                    setSelectedMachineSerialNo(null);
                                                    setFieldValue('dropdownSerialNo', '');

                                                    setBatches(null);
                                                    resetResult();
                                                }
                                            }}
                                            className="inputDiv"
                                            name="dropdownPlant"
                                            items={plantsList}
                                            placeHolder="Select Plant"
                                            value={values.dropdownPlant || []}
                                            helperText={
                                                errors.dropdownPlant && touched.dropdownPlant ? errors.dropdownPlant : ''
                                            }
                                        />
                                        <Label label="Machine Model" className={'labelDiv'} />
                                        <DropDown
                                            onChange={(modelEvent: any) => {
                                                handleChange(modelEvent);
                                                const model = machineModels.find(
                                                    (item: any) => item.code === modelEvent.target.value
                                                );
                                                if (model) {
                                                    setSelectedMachineParams([]);
                                                    setFieldValue('dropdownMachineParams', []);
                                                    setSelectedBatch([]);
                                                    setMachineSerialNos(null);
                                                    setSelectedMachineSerialNo(null);
                                                    setFieldValue('dropdownSerialNo', '');

                                                    setBatches([]);

                                                    setSelectedModel(model.code.toString());

                                                    setBatchParams({ ...batchParams, dropdownModel: model.name})
                                                    resetResult();
                                                }
                                            }}
                                            className="inputDiv"
                                            name="dropdownModel"
                                            items={machineModels}
                                            placeHolder="Select Machine Model"
                                            value={values.dropdownModel}
                                            helperText={
                                                errors.dropdownModel && touched.dropdownModel ? errors.dropdownModel : ''
                                            }
                                        />
                                        <div className='labelfixed'>
                                        <Label label="Select Parameter (Optional Max 5)" className={'labelDiv'} />
                                        <FormControl sx={{ m: 1, width: '100%' }}>
                                            {values.dropdownMachineParams?.length > 0 ? null : (
                                                <InputLabel
                                                    style={{
                                                        fontFamily: 'Montserrat',
                                                        fontSize: '12px',
                                                        paddingTop: '0px',
                                                        paddingLeft: '20px',
                                                        color: '#b1c2df',
                                                        opacity: '0.7',
                                                        marginTop: '-5px',
                                                        marginLeft: '-5px',
                                                    }}
                                                    id="demo-multiple-checkbox-label"
                                                    className='labelalign'
                                                >
                                                    Select Parameters
                                                </InputLabel>
                                            )}
                                            <Select
                                                IconComponent={DropDownIcon}
                                                labelId="multiple-checkbox-label"
                                                placeholder="Select Parameters"
                                                id="multiple-checkbox"
                                                multiple
                                                displayEmpty
                                                label=""
                                                // style={{ backgroundColor: '#1d1e2c', color: '#b1c2df' }}
                                                style={{
                                                    backgroundColor: '#1d1e2c',
                                                    color: '#b1c2df',
                                                    fontFamily: 'Montserrat',
                                                    fontSize: '12px',
                                                    borderRadius: '10px',
                                                    height: '40px',
                                                    marginLeft: '15px',
                                                    marginRight: '15px',
                                                    width: '100%',
                                                }}
                                                className="inputDivselectparam"
                                                name="dropdownMachineParams"
                                                value={values.dropdownMachineParams}
                                                onChange={(paramEvent: any) => {
                                                    handleChange(paramEvent);
                                                    setSelectedBatch([]);
                                                    if (paramEvent.target.value.length <= 5) {
                                                        setSelectedMachineParams(paramEvent.target.value);
                                                    }
                                                    resetResult();
                                                }}
                                                // input={<OutlinedInput label="Plan" />}
                                                MenuProps={MenuProps}
                                                renderValue={(selected) =>
                                                    machineParams
                                                        ?.filter((name: any) => selected.includes(name.code))
                                                        ?.map((record: any) => record.name)
                                                        ?.join(', ')
                                                }
                                            >
                                                {machineParams?.map((machine: any) => (
                                                    <MenuItem key={machine.code} value={machine.code}>
                                                        <Checkbox
                                                            checked={selectedMachineParams?.indexOf(machine.code) > -1}
                                                        />
                                                        <ListItemText primary={machine.name} />
                                                    </MenuItem>
                                                ))}
                                            </Select>
                                        </FormControl>
                                        </div>
                                        <Label label="Line / Serial Number" className={'labelDiv'} />
                                        <DropDown
                                            onChange={(snEvent: any) => {
                                                setSelectedBatch([]);
                                                const serialNo = machineSerialNos.find(
                                                    (item: any) => item.code === snEvent.target.value
                                                );

                                                if (serialNo) {
                                                    setSelectedMachineSerialNo(serialNo.code.toString());
                                                }

                                                handleChange(snEvent);
                                                resetResult();
                                            }}
                                            className="inputDiv"
                                            name="dropdownSerialNo"
                                            items={machineSerialNos}
                                            placeHolder="Select Line / Serial Number"
                                            value={values.dropdownSerialNo}
                                            helperText={
                                                errors.dropdownSerialNo && touched.dropdownSerialNo
                                                    ? errors.dropdownSerialNo
                                                    : ''
                                            }
                                        />
                                        <div className='labelfixed'>
                                        <Label label="Select Batch (Max-3)" className={'labelDiv'} />
                                        <FormControl sx={{ width: '100%' }}>
                                            {selectedBatch?.length > 0 ? null : (
                                                <InputLabel
                                                    style={{
                                                        fontFamily: 'Montserrat',
                                                        fontSize: '12px',
                                                        paddingTop: '0px',
                                                        paddingLeft: '20px',
                                                        color: '#b1c2df',
                                                        opacity: '0.7',
                                                        marginTop: '5px',
                                                        marginLeft: '-5px',
                                                    }}
                                                    id="demo-multiple-checkbox-label"
                                                >
                                                    Select Batch
                                                </InputLabel>
                                            )}
                                            <Select
                                                IconComponent={DropDownIcon}
                                                labelId="demo-multiple-checkbox-label"
                                                id="demo-multiple-checkbox"
                                                className='inputDivselectparam'
                                                label=""
                                                multiple
                                                fullWidth
                                                value={selectedBatch}
                                                sx={{ marginBottom: "10px", backgroundColor: "#1d1e2c", borderRadius: 3, marginTop: '10px', color: "#b1c2df" }}
                                                placeholder='Please select Batches'
                                                // input={<OutlinedInput />}
                                                renderValue={(selected) => selected?.map((x: any) => x.name).join(', ')}
                                                MenuProps={MenuProps}
                                                style={{backgroundColor: '#1d1e2c',
                                                color: '#b1c2df',
                                                fontFamily: 'Montserrat',
                                                fontSize: '12px',
                                                borderRadius: '10px',
                                                height: '40px',
                                                marginLeft: '15px',
                                                marginRight: '15px',
                                                width: '100%',}}

                                            >
                                                {batches?.map((i: any) => (

                                                    <MenuItem value={i}
                                                        onClick={(e:any) => { handleChangeBatch(e,i);  }}
                                                        // disabled={selectedBatch.length < 3 ? false : true}
                                                    >
                                                        <Checkbox
                                                            checked={
                                                                selectedBatch.findIndex((item: any) => item.code === i.code) >= 0
                                                            }

                                                        />
                                                        <ListItemText primary={i.name} />
                                                    </MenuItem>
                                                ))}
                                            </Select>
                                            {isSubmit && noBatches === true ? <FormHelperText style={{color:"#F06868",
                                            fontSize: "11px",
                                            fontFamily: "Montserrat",
                                            fontWeight: 600,
                                            }}>Please select batch</FormHelperText>: null}
                                        </FormControl>
                                        </div>
                                    
                                        {!isGenerating && !reportResponse && (
                                            <div className={'btns noWrap'}>
                                                <ACGButton
                                                    name={'Reset'}
                                                    handleClick={() => {
                                                        setBatchParams({
                                                            ...batchParams,
                                                            dateFromSelect: dateFormat(
                                                                new Date().setHours(-(24 * 9)),
                                                                Dateformat.YYYY_MM_DD
                                                            ),
                                                            dateToSelect: dateFormat(
                                                                new Date().setHours(-24),
                                                                Dateformat.YYYY_MM_DD
                                                            ),
                                                            dropdownPlant: '',
                                                            dropdownModel: '',
                                                            dropdownMachineParams: [],
                                                            dropdownSerialNo: '',
                                                        });
                                                        resetForm();
                                                        resetResult();
                                                    }}
                                                    secondary={true}
                                                    className={'first-btn'}
                                                    type="button"
                                                    variant="contained"
                                                />
                                                <ACGButton
                                                    name={'Submit'}
                                                
                                                    handleClick={() => {
                                                        //alert("Hello"); 
                                                    setIsSubmit(true) }}
                                                    type="submit"
                                                    className="second-btn"
                                                    variant="contained"
                                                />
                                            </div>
                                        )}
                                    </Form>
                                );
                            }}
                        </Formik>

                        {isGenerating && !reportResponse && <Label label="Generating Report ..." className={'labelDiv'} />}

                        {isDownloadError && (
                            <Label label="Report Generation Error, Please retry" className={'labelDiv'} />
                        )}

                        {reportResponse && (
                            <div className={'btns noWrap'} style={{ position: 'relative' }}>
                                <ACGButton
                                    name={'Download'}
                                    handleClick={() => {
                                        if (reportResponse) {
                                            FileDownload(reportResponse, batchParams?.dropdownModel + '_Batch_report.xlsx');
                                        }
                                    }}
                                    className={'first-btn'}
                                    type="button"
                                    variant="contained"
                                />
                                <ACGButton
                                    name={'Email'}
                                    handleClick={() => {
                                        if (reportResponse) {
                                            sendEmail();
                                        }
                                    }}
                                    className={'first-btn'}
                                    variant="contained"
                                />
                                <div
                                    onClick={() => {
                                        crossClick();
                                    }}
                                    style={{
                                        border: '1px solid #5D97F6',
                                        borderRadius: '50px',
                                        height: '25px',
                                        width: '25px',
                                        position: 'relative',
                                        right: '10px',
                                        top: '8px',
                                        cursor: 'pointer'
                                    }}
                                >
                                    <span
                                        style={{
                                            position: 'relative',
                                            fontSize: '12px',
                                            color: '#5D97F6',
                                            left: '35%',
                                            top: '-3%'
                                        }}
                                    >
                                        X
                                    </span>
                                </div>
                            </div>
                        )}
                    </Container>
                </Box>
            </Drawer>
        </div>
    );
}