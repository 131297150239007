import React from 'react';
import './index.scss';
import Scrollbar from '../Scrollbar';
import TableColumns from './TableColumns/index';
import { makeStyles } from '@material-ui/core/styles';
import TableRows from './TableRows/index';
import theme from '../../../utilities/theme/theme.module.scss';
import TablePaginator from './TablePagination/index';
import NoData from '../../../assets/nodata.svg';

import {
    Card,
    Table,
    TableBody,
    TableRow,
    TableCell,
    Container,
    TableContainer,
    Typography,
    TablePagination
} from '@mui/material';

const useStyles = makeStyles({
    root: {
        paddingLeft: '3px !important',
        paddingRight: '3px !important',
        '& .simplebar-placeholder': {
            minHeight: '400px !imortant',
            height: 'auto !important'
        },
        // '& .simplebar-content .MuiTableContainer-root': {
        //     overflowX: 'clip',
        //     minWidth: 'max-content'
        // },
        '& .MuiPaper-root': {
            // marginTop: '20px',
            borderRadius: '0px 0px 15px 15px'
        },
        '& .MuiTableCell-head .MuiButtonBase-root': {
            // fontSize: '14px',
            fontWeight: 500
        },
        '& *,.MuiTableCell-root, .MuiTypography-root,.MuiButtonBase-root': {
            // fontFamily: 'poppins'
            // fontSize: '13px'
        },

        '&  .MuiTableRow-root.Mui-selected': {
            // backgroundColor: 'rgba(25, 118, 210, 0.20) !important'
        },
        '& .MuiTableRow-root:nth-child(odd)': {
            // backgroundColor: `${theme.s050Bg}`,
        },
        '& .MuiTableRow-head': {
            // backgroundColor: "white !important",
        },
        '& .MuiTablePagination-root .MuiToolbar-root ': {
            // backgroundColor: "#E0E9EF",
            backgroundColor: '#2b2d42'
        },
        '& .MuiToolbar-root': {
            backgroundColor: `${theme.s050Bg}`
        },
        '& .MuiTablePagination-spacer': {
            display: 'none'
        },
        '& .MuiTablePagination-displayedRows': {
            marginTop: '1rem',
            fontFamily: 'PoppinsSemiBold',
            fontSize: '12px'
        }
    }
});
interface Props {
    headers: any;
    filteredUsers: any;
    drawerTable?: boolean;
    isTableImageReqd?: boolean;
    idKey?: string;
    getUpdatedRowData: Function;
    parametersCount: number;
    page: number;
    handleChangePage: any;
    setPageNumber: Function;
    rowsPerPage: number;
    updatedReplaceDate?: any;
    setCLCMappingList?: any;
    clcMappingList?: any;
    qualityAttributeList?: any;
    setQualityAttributeList?: any;
    limitParameters?: any;
    tabIndex?: any;
    drawerHeader?: any;
}
function CustomEditableTable(props: Props) {
    const {
        headers,
        filteredUsers,
        drawerTable,
        isTableImageReqd = true,
        idKey = 'id',
        getUpdatedRowData,
        parametersCount,
        page,
        handleChangePage,
        setPageNumber,
        rowsPerPage,
        updatedReplaceDate,
        setCLCMappingList,
        clcMappingList,
        setQualityAttributeList,
        limitParameters,
        tabIndex=null,
        drawerHeader
    } = props;
    const classes = useStyles();
    const scrollCSS = {
        '&::-webkit-scrollbar': {
            width: '0.3em'
        },
        '&::-webkit-scrollbar-track': {
            backgroundColor: '#2b2d42',
            boxShadow: 'inset 0 0 6px rgba(0, 0, 0, 0.3)'
        },
        '&::-webkit-scrollbar-thumb': {
            backgroundColor: 'darkgrey',
            outline: '1px solid slategrey'
        }
    };

    return (
        <Container
            maxWidth="xl"
            className={!drawerTable ? classes.root + ' TableBase' : classes.root + ' DrawerTableBase'}
        >
            <Card
                style={{
                    borderRadius: '0px 0px 10px 10px',
                    backgroundColor: '#2e304a'
                }}
            >
                <Scrollbar>
                    <TableContainer
                        sx={
                            !drawerTable
                                ? {
                                      minWidth: 800,
                                      maxHeight: 400,
                                      ...scrollCSS
                                  }
                                : {
                                      minWidth: 300,
                                      maxHeight: 300,
                                      ...scrollCSS
                                  }
                        }
                        style={{ backgroundColor: '#2e304a' }}
                    >
                        <Table className="actualTable" stickyHeader>
                             <TableColumns headLabel={headers} />
                            <TableBody>
                                {
                                    filteredUsers && filteredUsers?.map((row: any, index: number) => {
                                        return (
                                            <TableRows
                                                key={index}
                                                header={headers}
                                                row={row}
                                                isTableImageReqd={isTableImageReqd}
                                                getUpdatedRowData={getUpdatedRowData}
                                                updatedReplaceDate={updatedReplaceDate}
                                                setCLCMappingList={setCLCMappingList}
                                                clcMappingList={props.clcMappingList}
                                                qualityAttributeList={props.qualityAttributeList}
                                                setQualityAttributeList={setQualityAttributeList}
                                                limitParameters={limitParameters}
                                                tabIndex={tabIndex}
                                                drawerHeader={drawerHeader}
                                            />
                                        );
                                    })}
                                {filteredUsers?.length == 0 ? (
                                    <TableRow className="emptyTable">
                                        <TableCell colSpan={14} style={{ width: '100%' }}>
                                            <div className="nodataRow">
                                                <img src={NoData} alt="no data" />
                                                <Typography sx={{ mt: '2%' }}>No data to show</Typography>
                                            </div>
                                        </TableCell>
                                    </TableRow>
                                ) : null}
                            </TableBody>
                        </Table>
                    </TableContainer>
                </Scrollbar>
                <TablePagination
                    className="table_pagination"
                    rowsPerPageOptions={[]}
                    component="div"
                    count={parametersCount}
                    rowsPerPage={rowsPerPage}
                    page={page}
                    onPageChange={handleChangePage}
                    labelDisplayedRows={() => {}}
                    ActionsComponent={() => (
                        <TablePaginator
                            count={parametersCount}
                            rowsPerPage={rowsPerPage}
                            page={page}
                            onPageChange={handleChangePage}
                            setPageNumber={setPageNumber}
                        />
                    )}
                />
            </Card>
        </Container>
    );
}
export default React.memo(CustomEditableTable);
