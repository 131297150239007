import * as Yup from "yup";

export const VALIDATION_YUPSCHEMA = {
  dateFromSelect: Yup.date().required("Please select from date")
    .max(new Date(), 'To date cannot be in the future')
    .min(new Date(Date.now() - 6 * 30 * 24 * 60 * 60 * 1000), //CHANGE THIS TO 6 Months AS PER THE REQUIREMENTS
      'From date cannot be more than 6 months in the past'),
  dateToSelect: Yup.date().required("Please select to date")
    .max(new Date(), 'To date cannot be in the future')
    .when("dateFromSelect",
      (dateFromSelect, yup) => dateFromSelect && yup.min(dateFromSelect, "To date cannot be before start date"))
    .test(
      'date-range',
      'Date range should not be more than one month',
      function (value) {
        const fromDate = this.resolve(Yup.ref('dateFromSelect')) as Date;
        const toDate = value as Date;
        const diffInMs = toDate.getTime() - fromDate.getTime();
        const diffInDays = diffInMs / (1000 * 60 * 60 * 24);
        return diffInDays <= 31; //IT SHOULD BE 31 DAYS ONLY AS PER THE REQUIREMENT
      }),
  dropdownPlant: Yup.string().required("Please select plant"),
  dropdownModel: Yup.string().required("Please select machine model"),
  dropdownMachineParams: Yup.array().optional(),
  dropdownSerialNo: Yup.string().required("Please select line / serial no."),
  // dropdownBatch: Yup.string().nullable().when(['dateFromSelect', 'dateToSelect'], (dateFromSelect: any, dateToSelect: any) => {
  //   const diffInMs = dateToSelect.getTime() - dateFromSelect.getTime();
  //   const diffInHours = diffInMs / (1000 * 60 * 60);
  //   return diffInHours <= 48 //IT SHOULD BE 2 DAYS ONLY AS PER THE REQUIREMENT
  //     ? Yup.string().notRequired()
  //     : Yup.string().required('Please select batch');
  // })
};