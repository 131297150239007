import { useState } from 'react';
import { Typography, Box } from '@mui/material';
import { useEffect } from 'react';
import './index.scss';
import Remove from '../../../assets/images/remove.svg';
// @ts-ignore
import Files from 'react-files';
import ACGButton from '../Button';
// import closeIcon from '../../../assets/images/close-icon.svg';

type Props = {
    click?: any;
    basePath?: any;
    isArrayType?: boolean;
    viewOnly?: boolean;
    formDocs?: any;
    setFormDocs?: any;
    setError?: any;
    setDocsChng?: any;
    type?: any
    isSetFile?: any;
    margin?: boolean;
    isEdit?: any;
    placeholder?: any;
    isSopFile?: any;
    timeState?: any;
};

const Attachments = (props: Props) => {
    const { timeState,isSopFile, placeholder, viewOnly = true, formDocs = [], setFormDocs = () => { }, setError, setDocsChng, type, isSetFile, margin = true, isEdit } = props;
    let displayValues;
    const [selectedFilename, setSelectedFilename] = useState([]);
    console.log("🚀 ~ Attachments ~ selectedFilename:", selectedFilename)
    const [allowDownload, setAllowDownload] = useState(false);

    const clickHandler = (docs: any, filename: any) => {
        if (docs && filename) {
            props?.click(docs);
            setSelectedFilename(filename);
            setAllowDownload(true);
        } else {
            return;
        }
    };

    // useEffect(() => {
    //     if (allowDownload && props?.basePath) {
    //         forceDownload(props?.basePath, selectedFilename);
    //     }
    //     setTimeout(() => {
    //         setAllowDownload(false);
    //     }, 1000);
    // }, [props?.basePath]);

    // const onFilesChange = (files: any) => {
    //     setDocsChng(true);
    //     for (const file of files) {
    //         file.fileName = file?.name;
    //         file.docCategoryCode = 'CERTIFICATE';
    //         //file.docTypeCode = 'FORM_HARD_COPY';
    //         file.docTypeCode = file?.name;
    //         getBase64(file)
    //             .then((result: any) => (file['fileData'] = result))
    //             .catch((e: any) => console.log(e));
    //     }
    //     const copyFiles = [...formDocs, ...files];

    //     if (copyFiles?.length > 2) {
    //         setError();
    //     } else {
    //         setFormDocs(copyFiles);
    //     }
    // };
    const filesChange = (files: any) => {

        setSelectedFilename(files)
        isSetFile(files)
    }

    const onFilesError = (error: any, file: any) => {
        console.log('error code ' + error.code + ': ' + error.message);
    };
    const filesRemoveOne = (idx: number) => {
        setSelectedFilename([])
        isSetFile([])

    };

    if (selectedFilename.length > 0) {
        displayValues = selectedFilename.map((ele: any, index: number) => {
           
            let fileName
            if (isSopFile && ele?.preview?.type=="file") {
                let time = timeState;
                let sopNameValue = ele.name.split(".pdf")
                fileName = sopNameValue[0] + time + ".pdf"
            } else {
                fileName = ele?.name
            }
            return (
                <Box className="attachment_wrapper">
                    {!viewOnly && !type && <img onClick={() => filesRemoveOne(index)} src={Remove} alt="X" className="closeBtn" />}
                    {ele?.url ? (
                        <>
                            <img
                                src={ele?.url}
                                onClick={viewOnly ? () => clickHandler(ele?.filePath, ele?.fileName) : () => { }}
                                style={{ cursor: viewOnly ? 'pointer' : 'auto' }}
                            />
                            <Typography className="h4">{ele?.docTypeCode}</Typography>
                        </>
                    ) : !type ? (
                        <>
                            <img src={ele?.preview?.url} />
                            {/* <Typography className="h4">{ele?.name}</Typography> */}
                        </>
                    ) :

                        <div style={{ width: '100%', border: '1px solid grey', borderRadius: '6px', padding: '10px', display: 'flex', alignItems: 'center', marginTop: !margin ? '0px' : '0px' }}>
                            {!viewOnly && <img onClick={() => filesRemoveOne(index)} src={Remove} alt="close" className="closeBtn" />}
                            <Typography className="h4" style={{ color: '#e0e0e0', paddingLeft: '36px' }}>{fileName}</Typography>
                        </div>}
                </Box>
            )
        })
    }
    // if (formDocs?.length > 0) {
    //     displayValues = formDocs?.map((ele: any, idx: number) => {
    //         return (
    //             <Box className="attachment_wrapper">
    //                 {!viewOnly && <img onClick={() => filesRemoveOne(idx)} src={closeIcon} className="closeBtn" />}
    //                 {ele?.url ? (
    //                     <>
    //                         <img
    //                             src={ele?.url}
    //                             onClick={viewOnly ? () => clickHandler(ele?.filePath, ele?.fileName) : () => {}}
    //                             style={{ cursor: viewOnly ? 'pointer' : 'auto' }}
    //                         />
    //                         <Typography className="h4">{ele?.docTypeCode}</Typography>
    //                     </>
    //                 ) : (
    //                     <>
    //                         <img src={ele?.preview?.url} />
    //                         <Typography className="h4">{ele?.name}</Typography>
    //                     </>
    //                 )}
    //             </Box>
    //         );
    //     });
    // }

    useEffect(() => {
        if (isEdit) {
            let obj: any = [{ name: isEdit }]
            setSelectedFilename(obj)
        }
    }, [isEdit])
    return (
        <>
            {displayValues}
            {/* {formDocs && formDocs?.length < 2 && !viewOnly ? ( */}
            {selectedFilename && selectedFilename.length < 1 ?
                <li className="files-list-item">
                    <Files
                        className={!type ? "files-dropzone-list" : "files-dropzone-list noBorders"}
                        // onChange={onFilesChange}
                        onChange={filesChange}
                        onError={onFilesError}
                        accepts={type ? type : ['image/png']}
                        multiple
                        maxFileSize={10000000}
                        minFileSize={0}
                        clickable
                    >
                        {!type ? '+' :
                            <ACGButton
                                name={placeholder ?? 'Upload Parameters'}
                                handleClick={() => { }}
                                type="submit"
                                className="second-btn"
                                variant="contained"
                                style={{ padding: '5px', width: '170px', height: '35px' }}
                            />}

                    </Files>
                </li>
                : null}
            {/* ) : null} */}
        </>
    );
};
export default Attachments;
