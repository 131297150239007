import * as React from 'react';
import { useState, useEffect } from 'react';
import CustomTable from '../../../atoms/CustomTable';
import CustomTableToolbar from '../../../atoms/CustomTableToolbar';
import { Container, Card, Grid, TableRow, TableCell, Typography } from '@mui/material';
import { applySortFilter } from '../../../utils/helper';
import ACGButton from '../../../atoms/Button';
import TextInput from '../../../atoms/TextInput';
import Label from '../../../atoms/Label/index';
import { ACTION_CODES, STORE_KEYS } from '../../../constants/apiConstants';
import { useDispatch } from 'react-redux';
import { executeACGAction, updateScreenIdentifiers } from '../../../store/slice';
import useGetState from '../../../utils/hooks/useGetState';

const options = {
    DEFAULT: {
        message: '',
        open: false,
        type: ''
    },
    ADD_SUCCESS: {
        message: 'Model created successfully!',
        open: true,
        type: 'success'
    }
};
const TABLE_HEAD = [
    { id: 'machineName', label: 'Select All', alignRight: false }
    //   { id: "Action", label: "Action", alignLeft: true },
];
type Anchor = 'right';
const AddParams = (props: any) => {
    const { closeDrawer, MachineModelKey, setSnackbarOptions, MachineKey } = props;
    const [state, setState] = useState({
        right: false
    });
    const dispatch = useDispatch();
    const stateObj = useGetState();
    const [page, setPage] = useState<number>(0);
    const [order, setOrder] = useState('desc');
    const [orderBy, setOrderBy] = useState('machineName');
    const [selected, setSelected] = useState<any>([]);
    const [filterName, setFilterName] = useState<string>('');
    const [tableData, setTableData] = useState<any[]>([]);
    const [tableData2, setTableData2] = useState<any[]>([]);
    const [page2, setPage2] = useState<number>(0);
    const [order2, setOrder2] = useState('desc');
    const [orderBy2, setOrderBy2] = useState('machineName');
    const [selected2, setSelected2] = useState<any>([]);
    const [filterName2, setFilterName2] = useState<string>('');
    const [selectedModalData, setSelectedModalData] = useState('');
    const [tempData, setTempData] = useState<any>([]);
    const [removeData, setRemoveData] = useState<any>([]);
    const [groupCode, setGroupCode] = useState<string>('');
    const [groupName, setGroupName] = useState<string>('');

    const filteredList = applySortFilter(tableData, order, filterName, orderBy);
    const filteredList2 = applySortFilter(tableData2, order2, filterName2, orderBy2);

    const handleRequestSort = (event: any, property: any) => {
        setFilterName('');
        console.log(event, property);
        const isAsc = orderBy === event && order === 'asc';
        setOrder(isAsc ? 'desc' : 'asc');
        setOrderBy(event);
    };

    const handleSelectAllClick = (event: any) => {
        if (event.target.checked) {
            const newSelecteds = [];
            const entireSet = [];
            for (let i = 0; i < tableData.length; i++) {
                newSelecteds.push(tableData[i].id);
                entireSet.push(tableData[i]);
            }
            const toRemove = tableData.slice(page, tableData.length);
            setSelected(newSelecteds);
            setTempData(entireSet);
            return;
        }
        setSelected([]);
        setTempData([]);
    };

    const handleSelectAllClick2 = (event: any) => {
        if (event.target.checked) {
            const newSelecteds = [];
            const entireSet = [];
            for (let i = 0; i < tableData2.length; i++) {
                newSelecteds.push(tableData2[i].id);
                entireSet.push(tableData2[i]);
            }
            const toRemove = tableData2.slice(page, tableData2.length);
            setSelected2(newSelecteds);
            setRemoveData(entireSet);
            return;
        }
        setSelected2([]);
        setRemoveData([]);
    };

    const handleClick = (requestedBy: string) => {
        console.log(requestedBy);
        const selectedIndex = selected.indexOf(requestedBy);
        // | ((prevState: unknown[]) => unknown[])
        let newSelected: any[] = [];
        if (selectedIndex === -1) {
            newSelected = newSelected.concat(selected, requestedBy);
        } else if (selectedIndex === 0) {
            newSelected = newSelected.concat(selected.slice(1));
        } else if (selectedIndex === selected.length - 1) {
            newSelected = newSelected.concat(selected.slice(0, -1));
        } else if (selectedIndex > 0) {
            newSelected = newSelected.concat(selected.slice(0, selectedIndex), selected.slice(selectedIndex + 1));
        }
        const newData = [...tableData].filter((ele) => newSelected.includes(ele?.id));
        console.log(newData);
        setSelected(newSelected);
        setTempData(newData);
    };

    const handleClick2 = (requestedBy: string) => {
        console.log(requestedBy);
        const selectedIndex = selected2.indexOf(requestedBy);
        // | ((prevState: unknown[]) => unknown[])
        let newSelected: any[] = [];
        if (selectedIndex === -1) {
            newSelected = newSelected.concat(selected2, requestedBy);
        } else if (selectedIndex === 0) {
            newSelected = newSelected.concat(selected2.slice(1));
        } else if (selectedIndex === selected2.length - 1) {
            newSelected = newSelected.concat(selected2.slice(0, -1));
        } else if (selectedIndex > 0) {
            newSelected = newSelected.concat(selected2.slice(0, selectedIndex), selected2.slice(selectedIndex + 1));
        }
        const newData = [...tableData2].filter((ele) => newSelected.includes(ele?.id));
        console.log(newData);
        setSelected2(newSelected);
        setRemoveData(newData);
    };

    const toggleDrawer = (anchor: Anchor, open: boolean) => (event: React.KeyboardEvent | React.MouseEvent) => {
        if (
            event.type === 'keydown' &&
            ((event as React.KeyboardEvent).key === 'Tab' || (event as React.KeyboardEvent).key === 'Shift')
        ) {
            return;
        }
        setState({ ...state, [anchor]: open });
    };

    const handleFilterByName = (event: { target: { value: React.SetStateAction<string> } }) => {
        setOrderBy('machineName');
        setFilterName(event.target.value);
    };
    const handleFilterByName2 = (event: { target: { value: React.SetStateAction<string> } }) => {
        setOrderBy('machineName');
        setFilterName2(event.target.value);
    };

    const handleChangePage = (event: any, newPage: React.SetStateAction<number>) => {
        setPage(newPage);
    };

    const showRow = (event: any) => {};

    const handleRoute = (row: any, ele: any) => {
        if (ele === 'Action') {
        }
    };

    const handleFilter = () => {};

    const handleChange = (event: any) => {
        // alert(event.target.value)
        console.log(event.target.value);
        setSelectedModalData(event.target.value);
    };

    const addParameters = () => {
        if (tempData?.length > 0) {
            const data = [...tableData2, ...tempData];
            const existingData = [...tableData].filter((ele) => !tempData.includes(ele));
            setTableData2(data);
            setTableData(existingData);
            setTempData([]);
            setSelected([]);
            setSelected2([]);
        }
    };

    const removeParameters = () => {
        const updatedData = [...tableData, ...removeData];
        const existingData = [...tableData2].filter((ele) => !removeData.includes(ele));
        setTableData(updatedData);
        setTableData2(existingData);
        setRemoveData([]);
        setSelected([]);
        setSelected2([]);
    };

    const getMMParametersName = () => {
        dispatch(
            executeACGAction({
                payload: {
                    requestType: 'GET',
                    urlPath: ACTION_CODES.GET_MODEL_PARMS + '/' + MachineModelKey
                },
                uniqueScreenIdentifier: { mmParamsAdded: true },
                storeKey: STORE_KEYS.MODEL_PARMS
            })
        );
    };

    // const getMachineParams = () => {
    //     // GET_MACHINE_PARMS_DETAIL
    //     dispatch(
    //         executeACGAction({
    //             payload: {
    //                 requestType: 'GET',
    //                 urlPath: `${ACTION_CODES.GET_MACHINE_PARMS_DETAIL}/${MachineKey}`
    //             },
    //             uniqueScreenIdentifier: { dtlParmsAdded: true },
    //             storeKey: STORE_KEYS.MACHINE_PARMS
    //         })
    //     );
    // };

    useEffect(() => {
        if (stateObj?.[STORE_KEYS.MODEL_PARMS]?.mmParamsAdded) {
            if (stateObj?.[STORE_KEYS.MODEL_PARMS]?.statusCode === 200) {
                const data = stateObj?.[STORE_KEYS.MODEL_PARMS]?.body?.data?.map(
                    (d: { MMParamKey: number; ParamName: string }) => {
                        return { id: d?.MMParamKey, machineName: d?.ParamName };
                    }
                );
                const selectedRow = stateObj?.[STORE_KEYS.MACHINE_PARMS]?.body?.data?.map(
                    (d: { MMParamKey: number; ParamName: string }) => {
                        return { id: d?.MMParamKey, machineName: d?.ParamName };
                    }
                );
                const ids = new Set(selectedRow.map((item: { id: number }) => item?.id));
                setTableData(data.filter((item: { id: number }) => !ids.has(item?.id)));
                dispatch(
                    updateScreenIdentifiers({
                        storeKey: STORE_KEYS.MODEL_PARMS,
                        uniqueScreenIdentifier: {
                            mmParamsAdded: false
                        }
                    })
                );
            }
        }
    }, [stateObj?.[STORE_KEYS.MODEL_PARMS]?.mmParamsAdded, stateObj?.[STORE_KEYS.MACHINE_PARMS]?.body?.data]);

    useEffect(() => {
        if (stateObj?.[STORE_KEYS.MACHINE_PARMS]?.body?.data) {
            if (stateObj?.[STORE_KEYS.MACHINE_PARMS]?.statusCode === 200) {
                const data = stateObj?.[STORE_KEYS.MACHINE_PARMS]?.body?.data?.map(
                    (d: { MMParamKey: number; ParamName: string }) => {
                        return { id: d?.MMParamKey, machineName: d?.ParamName };
                    }
                );
                setTableData2(data);
                dispatch(
                    updateScreenIdentifiers({
                        storeKey: STORE_KEYS.MACHINE_PARMS,
                        uniqueScreenIdentifier: {
                            mmParamsAdded: false
                        }
                    })
                );
            }
        }
    }, [stateObj?.[STORE_KEYS.MACHINE_PARMS]?.body?.data]);

    useEffect(() => {
        getMMParametersName();
        // getMachineParams();
    }, []);

    useEffect(() => {
        if (stateObj?.[STORE_KEYS.ADD_RPT]?.machineAdded) {
            if (stateObj?.[STORE_KEYS.ADD_RPT]?.statusCode === 200) {
                const __machineKey = stateObj?.[STORE_KEYS.ADD_RPT]?.body?.data.MachineKey;
                console.log(__machineKey);
                if (tableData2?.length) {
                    const IDs = tableData2?.map((param) => param.id).join(',');
                    dispatch(
                        executeACGAction({
                            payload: {
                                requestType: 'POST',
                                urlPath: ACTION_CODES.ADD_MACHINE_PARAMS,
                                reqObj: {
                                    machineKey: MachineKey,
                                    mmParamKeys: IDs
                                }
                            },
                            uniqueScreenIdentifier: { paramsMapped: true },
                            storeKey: STORE_KEYS.ADD_RPT
                        })
                    );
                }
            }
            dispatch(
                updateScreenIdentifiers({
                    storeKey: STORE_KEYS.ADD_RPT,
                    uniqueScreenIdentifier: {
                        machineAdded: false
                    }
                })
            );
        }
    }, [stateObj?.[STORE_KEYS.ADD_RPT]?.machineAdded]);

    const resetMachineParamSearch = () => {
        setOrderBy('machineName');
        setFilterName2('');
    };
    const resetModelParamSearch = () => {
        setOrderBy('machineName');
        setFilterName('');
    };
    return (
        <div>
            <div>
                <Container
                    maxWidth="xl"
                    style={{
                        paddingLeft: '3px',
                        paddingRight: '3px',
                        marginTop: '60px',
                        display: 'flex',
                        justifyContent: 'space-around'
                    }}
                >
                    <div style={{ width: '48%' }}>
                        <Card
                            style={{
                                borderRadius: '0px',
                                backgroundColor: '#2b2d42',
                                overflow: 'inherit'
                            }}
                        >
                            <CustomTableToolbar
                                drawerOpen={toggleDrawer('right', true)}
                                numSelected={selected?.length}
                                filterName={filterName}
                                resetInput={resetModelParamSearch}
                                countNoShow={true}
                                clip={true}
                                onFilterName={handleFilterByName}
                                displayInput={true}
                                text={{ title: 'Model Parameters', subTitle: `${selected?.length} selected` }}
                                filterButton={false}
                                customButton={false}
                            />
                        </Card>
                    </div>
                    <div style={{ width: '48%' }}>
                        <Card
                            style={{
                                borderRadius: '0px',
                                backgroundColor: '#2b2d42',
                                overflow: 'inherit',
                                marginLeft: '3px'
                            }}
                        >
                            <CustomTableToolbar
                                drawerOpen={toggleDrawer('right', true)}
                                numSelected={selected2?.length}
                                filterName={filterName2}
                                clip={true}
                                countNoShow={true}
                                onFilterName={handleFilterByName2}
                                resetInput={resetMachineParamSearch}
                                displayInput={true}
                                text={{
                                    title: 'Machine Parameters',
                                    subTitle: `${selected2?.length} selected`
                                }}
                                filterButton={false}
                                customButton={false}
                            />
                        </Card>
                    </div>
                </Container>
                <div style={{ display: 'flex', justifyContent: 'space-around' }}>
                    <div className="customscrollBar" style={{ height: '65vh', width: '48%', overflowX: 'hidden' }}>
                        <CustomTable
                            selectable={true}
                            headers={TABLE_HEAD}
                            body={filteredList}
                            filteredUsers={filteredList}
                            filterName={filterName}
                            selected={selected}
                            order={order}
                            orderBy={orderBy}
                            onRequestSort={handleRequestSort}
                            onSelectAllClick={handleSelectAllClick}
                            rowChange={(event: string) => handleClick(event)}
                            rowCount={filteredList.length}
                            onPageChange={handleChangePage}
                            page={page}
                            setPageNumber={setPage}
                            selectRow={showRow}
                            route={handleRoute}
                            isRequestPage={true}
                            link={true}
                            clip={true}
                        />
                    </div>
                    <div
                        className="customscrollBar"
                        style={{ height: '65vh', overflow: 'overlay', width: '48%', overflowX: 'hidden' }}
                    >
                        <CustomTable
                            selectable={true}
                            headers={TABLE_HEAD}
                            body={filteredList2}
                            filteredUsers={filteredList2}
                            filterName={filterName2}
                            selected={selected2}
                            order={order2}
                            orderBy={orderBy2}
                            onRequestSort={() => {}}
                            onSelectAllClick={handleSelectAllClick2}
                            rowChange={(event: string) => {
                                handleClick2(event);
                            }}
                            rowCount={filteredList2.length}
                            onPageChange={handleChangePage}
                            page={page2}
                            setPageNumber={setPage2}
                            selectRow={showRow}
                            route={handleRoute}
                            isRequestPage={true}
                            link={true}
                            clip={true}
                        />
                    </div>
                </div>
                <br />
                <div style={{ display: 'flex', justifyContent: 'flex-start', paddingLeft: '25px' }}>
                    <ACGButton
                        divWidth="50%"
                        name={'Add'}
                        handleClick={addParameters}
                        secondary={true}
                        className={'first-btn'}
                        type="button"
                        variant="contained"
                        style={{ marginRight: '25px', padding: '5px', width: '100px', height: '35px' }}
                    />
                    <ACGButton
                        divWidth="50%"
                        name={'Remove'}
                        handleClick={removeParameters}
                        secondary={true}
                        className={'first-btn'}
                        type="button"
                        variant="contained"
                        style={{ marginRight: '25px', padding: '5px', width: '100px', height: '35px' }}
                    />
                </div>
            </div>
        </div>
    );
};

export default AddParams;
