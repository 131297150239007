import { useState, useEffect } from 'react';
import { styled, makeStyles } from '@mui/material/styles';
import { ACTION_CODES, STORE_KEYS, BASEURL } from '../../../../constants/apiConstants';
import useGetState from '../../../../utils/hooks/useGetState';
import ACGButton from '../../../../atoms/Button';
import Paper from '@mui/material/Paper';
import DropDown from '../../../../atoms/Dropdown';
import Label from '../../../../atoms/Label/index';
import Table from '@mui/material/Table';
import Container from '@mui/material/Container';
import Box from '@mui/material/Box';
import TableBody from '@mui/material/TableBody';
import TableCell, { tableCellClasses } from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import TextInput from '../../../../atoms/TextInput';
import InputLabel from '@mui/material/InputLabel';
import MenuItem from '@mui/material/MenuItem';
import Radio from '@mui/material/Radio';
import RadioGroup from '@mui/material/RadioGroup';
import FormControlLabel from '@mui/material/FormControlLabel';
import FormControl from '@mui/material/FormControl';
import Select from '@mui/material/Select';
import TextField from '@mui/material/TextField';
import Tooltip from '@mui/material/Tooltip';
import HelpIcon from '@mui/icons-material/Help';
import { Typography } from '@mui/material';
import Checkbox from '@mui/material/Checkbox';
import ListItemText from '@mui/material/ListItemText';
import OutlinedInput from '@mui/material/OutlinedInput';
import Grid from '@mui/material/Grid';
import Stack from '@mui/material/Stack';
import Button from '@mui/material/Button';
import TrailAnalysisService from '../../trialAnalysisServices';
import "../../ta.css";

const ITEM_HEIGHT = 48;
const ITEM_PADDING_TOP = 8;
const MenuProps = {
    PaperProps: {
        style: {
            maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
            width: 250,
        },
    },
}

const StyledTableCell = styled(TableCell)(({ theme }) => ({
    [`&.${tableCellClasses.head}`]: {

        color: theme.palette.common.white,
    },
    [`&.${tableCellClasses.body}`]: {
        fontSize: 14,
        color: theme.palette.common.white,
    },
}));

const StyledTableRow = styled(TableRow)(({ theme }) => ({
    '&:nth-of-type(odd)': {
        backgroundColor: theme.palette.action.hover,
    },
    // hide last border
    '&:last-child td, &:last-child th': {
        border: 0,
    },
}));

export default function CreateForm(props: any) {
    const state = useGetState();
    const token: any = state?.[STORE_KEYS.USER_DTL].token;
    const loggedInUser = state[STORE_KEYS.USER_DTL]?.LoggedUserKey;
    const [bprInfoData, setBprInfoData] = useState<any>([]);
    const [bprInfoValues, setBprInfoValues] = useState<any>([]);
    const [disable, setDisable] = useState(true);
    
    useEffect(() => {
        fetchBPRInfo();
    }, []);

    const fetchBPRInfo = () => {
        const data = { "trialKey": props.newKey, token: token }
        TrailAnalysisService.getTrailAnalysisBPRLimitInfo(data)
            .then((response: any) => {
                const values: any = [];
                const result = response?.data.message[0];
                setBprInfoData(result[0]);
                result[0]?.map((i: any, index: number) => {

                    let object = {
                        "TrialBPRLimitKey": '',
                        "TrialBPRLimitOperatorKey": '',
                        "MinValue": '',
                        "MaxValue": '',
                        "Unit": ''
                    }
                    values.push(object);

                })
                setBprInfoValues(values);
            })
            .catch((err) => {
                console.log(err);
            });

    }

    useEffect(() => {
        props.fetchBPROperators();
        const data = { "trialKey": props.newKey, token: token }
        TrailAnalysisService.getTrailAnalysisBPRLimitInfo(data)
            .then((response: any) => {
                const values: any = [];
                const result = response?.data.message[0];
                setBprInfoData(result[0]);
                result[0]?.map((i: any, index: number) => {

                    let object = {
                        "TrialBPRLimitKey": i.TrialBPRLimitKey,
                        "TrialBPRLimitOperatorKey": i.TrialBPRLimitOperatorKey,
                        "MinValue": i.MinValue,
                        "MaxValue": i.MaxValue,
                        "Unit": i.Unit
                    }
                    values.push(object);

                })
                setBprInfoValues(values);
            })
            .catch((err) => {
                console.log(err);
            });

    }, [props.trailKey]);

    useEffect(() => {
        const filteredObject1 = recursiveRemoveUnitKey( bprInfoValues, 'Unit');
        let check1 = check(filteredObject1);
        setDisable(check1);
    }, [bprInfoValues])

    const recursiveRemoveUnitKey = ( object: any, deleteKey1: any) => {
        let count = 0;
        count !== 0 && delete object[deleteKey1];
        
        Object.values(object).forEach((val, index) => {
            count = count + 1;
            console.log(val)
            if (val === null) return
            if (typeof val !== 'object') return;
            recursiveRemoveUnitKey( val, deleteKey1)

        })
        return object;
    }

    function check(object: any) {
        let count = 0;
        let value: any;
        for (value of Object.values(object)) {
            if (value !== null ) {
                if (object[count]?.TrialBPRLimitOperatorKey === "3" || object[count]?.TrialBPRLimitOperatorKey === 3) {
                    if (value.MinValue === null || value.MinValue === "" || value.MinValue === "NaN" || value.MaxValue === null || value.MaxValue === "" || value.MaxValue === "NaN" || Number(value.MinValue) > Number(value.MaxValue) || (typeof value === "object" && check(value))) {
                        return true;
                    }
                }
                else {
                    if (value?.MinValue === null || value.MaxValue === "NaN" || value?.MinValue === "" || value.MinValue === "NaN" || (typeof value === "object" && check(value))) {
                        return true;
                    }
                }
                count = count + 1
            }
        }

        return false;
    }

    const reset = () => { setBprInfoValues([]); }
    const options = {
        AD_SUCCESS: {
          message: `Trial ${props.newTrialNotification === true ? 'created' : 'edited'} successfully!`,
          open: true,
          type: 'success'
        },
        AD_FAIL: {
          message: `Trial creation error !`,
          open: true,
          type: 'remark'
        }
      };
    const createBPRLimitValues = () => {
        const data = {
            userId: loggedInUser,
            formData: JSON.stringify(bprInfoValues),
            token: token
        }
        TrailAnalysisService.createBPRLimitValues(data)
            .then((response: any) => {
                props.setSnackbarOpts(options.AD_SUCCESS);
                const result = response?.data.message[0];
                props.fetchTrailAnalysis('');
                props.drawerClose();
                props.handleReset()
                reset();
            })
            .catch((err) => {
                console.log(err);
            });
    }

    const handleChangeOperator = (event: any, index: any, key: any) => {
        let data: any = [...bprInfoValues];
        data[index][event.target.name] = event.target.value.toString();
        data[index]["TrialBPRLimitKey"] = key;
        setBprInfoValues(data);
    }

    const handleFormChangeMin = (event: any, index: any) => {
        let data: any = [...bprInfoValues];
        let value = event.target.value = Math.max(0, parseInt(event.target.value)).toString().slice(0, 3)
        value.replace(/\D/g, "")
        data[index][event.target.name] = value;
        setBprInfoValues(data);
    }

    const handleFormChangeMax = (event: any, index: any) => {
        let data: any = [...bprInfoValues];
        let value = event.target.value = Math.max(0, parseInt(event.target.value)).toString().slice(0, 3)
        value.replace(/\D/g, "")
        data[index][event.target.name] = value;
        setBprInfoValues(data);
    }

    const handleFormChangeUnit = (event: any, index: any) => {
        let data: any = [...bprInfoValues];
        data[index][event.target.name] = event.target.value;
        setBprInfoValues(data);
    }

    const renderButtons = () => {
        return (
            <div className={'btns noWrap'}>
                <ACGButton
                    name={'Cancel'}
                    handleClick={() => { props.drawerClose(); reset(); props.handleReset() }}
                    secondary={true}
                    className={'first-btn'}
                    type="button"
                    variant="contained"
                />
                <ACGButton
                    name={'Next'}
                    handleClick={() => { createBPRLimitValues() }}
                    type="submit"
                    className="second-btn"
                    variant="contained"
                    disabled={disable ? true : false}
                />
            </div>
        );
    };

    return (
        <Container className="createReport">
            <Box>
                <div className='formwrap all-labelcls'>
                    <FormControl fullWidth>
                        <Grid container spacing={2}>

                            <Grid xs={12} sx={{ marginTop: 2, padding: 2 }}>
                                <TableContainer component={Paper} className='tablemainList' >
                                    <Table sx={{ minWidth: 700 }} aria-label="customized table" className='table_List'>
                                        <TableHead className='tableHeadList'>
                                            <TableRow className='tableHeadListRow'>
                                                <StyledTableCell className='alertlistth'>Category</StyledTableCell>
                                                <StyledTableCell className='alertlistth'>Parameter Name</StyledTableCell>
                                                <StyledTableCell className='alertlistth'>Condition</StyledTableCell>
                                                <StyledTableCell align="center" className='alertlistth'>Value 1</StyledTableCell>
                                                <StyledTableCell align="center" className='alertlistth'>Value 2</StyledTableCell>
                                                <StyledTableCell align="center" className='alertlistth'>Unit</StyledTableCell>
                                            </TableRow>
                                        </TableHead>
                                        <TableBody>
                                            {bprInfoData.length > 0 ?
                                                bprInfoData.map((row: any, index: number) => (
                                                    <StyledTableRow key={index}>
                                                        <StyledTableCell component="th" scope="row" className='colortxtcolumn'>
                                                            {row.TrialParamCategory}
                                                        </StyledTableCell>
                                                        <StyledTableCell component="th" scope="row" className='colortxtcolumn'>
                                                            {row.TrialParamName}
                                                        </StyledTableCell>
                                                        <StyledTableCell component="th" scope="row" className='colortxtcolumn arrowColors'>
                                                            {/* <DropDown
                                                                onChange={(e) => { handleChangeOperator(e, index, row.TrialBPRLimitKey) }}
                                                                className="inputDiv"
                                                                name="dropdownModel"
                                                                items={props.bprOperators}
                                                                placeHolder="Select Condition"
                                                                value={bprInfoValues[index].TrialBPRLimitOperatorKey}
                                                                style={{
                                                                    "& .MuiInputBase-input.Mui-disabled": {
                                                                        WebkitTextFillColor: "#e0e0e0",
                                                                    }
                                                                }}
                                                                key={index}
                                                            /> */}
                                                            <FormControl sx={{ width: '100%' }}>
                                                                {bprInfoValues?.length > 0 ? null : (
                                                                    <InputLabel
                                                                        style={{
                                                                            fontFamily: 'Montserrat',
                                                                            fontSize: '13px',
                                                                            paddingTop: '9px',
                                                                            paddingLeft: '12px',
                                                                            color: '#b1c2df',
                                                                            opacity: '0.7'
                                                                        }}
                                                                        id="demo-multiple-checkbox-label"
                                                                    >
                                                                        Select Condition
                                                                    </InputLabel>
                                                                )}
                                                                <Select
                                                                    labelId="demo-simple-select-label"
                                                                    id="demo-simple-select"
                                                                    sx={{
                                                                        "& .MuiInputBase-input.Mui-disabled": {
                                                                            WebkitTextFillColor: "#e0e0e0",
                                                                        }, color: "#ffff"
                                                                    }}
                                                                    name="TrialBPRLimitOperatorKey"
                                                                    value={bprInfoValues.length > 0 ? bprInfoValues[index].TrialBPRLimitOperatorKey : ''}
                                                                    onChange={(e) => { handleChangeOperator(e, index, row.TrialBPRLimitKey) }}
                                                                // disabled={props.trailKey === '' ? false : true}
                                                                >
                                                                    {props.bprOperators?.map((i: any) => {

                                                                        return (
                                                                            <MenuItem value={i.TrialBPRLimitOperatorKey}>{i.TrialBPRLimitOperatorName}</MenuItem>
                                                                        )

                                                                    })}
                                                                </Select>
                                                            </FormControl>
                                                        </StyledTableCell>
                                                        <StyledTableCell component="th" scope="row" className='colortxtcolumn' align='center'>
                                                            <TextInput
                                                                fullWidth={true}
                                                                type="number"
                                                                name='MinValue'
                                                                placeholder='Min Value'
                                                                onChange={(e: any) => {
                                                                    handleFormChangeMin(e, index);
                                                                }}
                                                                
                                                                className="no-spinners"
                                                                maxLength={3}
                                                                InputProps={{
                                                                    inputProps: {
                                                                        min: 0, maxLength: 3, max: 3, inputMode: "numeric",
                                                                        pattern: "[0-9]*"
                                                                    }
                                                                }}
                                                                value={bprInfoValues.find((i: any) => i.TrialBPRLimitKey === row.TrialBPRLimitKey)?.MinValue}
                                                            // disabled={row.RawMaterialWeightIn === "KG" ? true : false}
                                                            />
                                                        </StyledTableCell>
                                                        <StyledTableCell component="th" scope="row" className='colortxtcolumn' align='center'>
                                                            {bprInfoValues.find((i: any) => i.TrialBPRLimitKey === row.TrialBPRLimitKey)?.TrialBPRLimitOperatorKey === "3" || bprInfoValues.find((i: any) => i.TrialBPRLimitKey === row.TrialBPRLimitKey)?.TrialBPRLimitOperatorKey === 3 ?
                                                                <TextInput
                                                                    fullWidth={true}
                                                                    type="number"
                                                                    name='MaxValue'
                                                                    placeholder='Max Value'
                                                                    
                                                                    onChange={(e: any) => {

                                                                        handleFormChangeMax(e, index);
                                                                    }}
                                                                    className="no-spinners"
                                                                    maxLength={3}
                                                                    InputProps={{
                                                                        inputProps: {
                                                                            min: 0, maxLength: 3, max: 3, inputMode: "numeric",
                                                                            pattern: "[0-9]*"
                                                                        }
                                                                    }}
                                                                    value={bprInfoValues.find((i: any) => i.TrialBPRLimitKey === row.TrialBPRLimitKey)?.MaxValue}
                                                                // disabled={row.RawMaterialWeightIn === "KG" ? true : false}
                                                                />
                                                                : null
                                                            }
                                                        </StyledTableCell>
                                                        <StyledTableCell component="th" scope="row" className='colortxtcolumn' align='center'>
                                            
                                                            <TextInput
                                                                fullWidth={true}
                                                                maxLength={3}
                                                                sx={{ width: "100px" }}
                                                                name='Unit'
                                                                placeholder='Unit Value'
                                                                onChange={event => handleFormChangeUnit(event, index)}
                                                                value={bprInfoValues.find((i: any) => i.TrialBPRLimitKey === row.TrialBPRLimitKey)?.Unit}
                                                            // disabled={row.RawMaterialWeightIn === "KG" ? true : false}
                                                            />
                                                        </StyledTableCell>

                                                    </StyledTableRow>
                                                ))
                                                :
                                                <StyledTableRow >
                                                    <StyledTableCell colSpan={5} align="left" className='nodataalert'>No records found.</StyledTableCell>

                                                </StyledTableRow>
                                            }

                                        </TableBody>
                                    </Table>
                                </TableContainer>
                            </Grid>
                        </Grid>

                    </FormControl>
                </div>
            </Box>
            {renderButtons()}
        </Container>
    );
}