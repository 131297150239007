import * as React from 'react';
import { useState, useEffect } from 'react';
import Accordion from '@mui/material/Accordion';
import AccordionSummary from '@mui/material/AccordionSummary';
import AccordionDetails from '@mui/material/AccordionDetails';
import Typography from '@mui/material/Typography';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import Popover from '../Popover';
import InfoIcon from '@mui/icons-material/Info';
import { Checkbox } from '@mui/material';
import { iteratorSymbol } from 'immer/dist/internal';
import SearchBar from '../SearchBar';

type popoverProps = {
    data?: any;
    edit?: boolean;
    onSelecteChange: any;
    isSearch?: boolean;
};
export default function SimpleAccordion(props: popoverProps) {
    const { data, edit, onSelecteChange, isSearch = false } = props;
    const [expanded, setExpanded] = React.useState<string | false>(false);
    const [searchText, setSearchText] = useState<string>('');
    const handleChange = (panel: string) => (event: React.SyntheticEvent, isExpanded: boolean) => {
        setExpanded(isExpanded ? panel : false);
    };
    const [selected, setSelected] = useState<any>([]);

    useEffect(() => {
        setSelected(data || []);
    }, [data]);

    useEffect(() => {
        if (selected?.length) {
            onSelecteChange(selected);
        }
    }, [selected]);

    const handleClick = (idx: any, ele: any) => {
        const newData = [...selected].map((elem: any) => {
            if (elem.title === ele.title) {
                elem.plantData[idx].isSelected = !elem.plantData[idx].isSelected;
            }
            if (!elem.plantData?.[idx]?.isSelected) {
                elem.isSelected = false;
            }
            const anyFalse = elem.plantData.filter((item: any) => item.isSelected === false);
            if (anyFalse.length == 0) {
                elem.isSelected = true;
            }
            return elem;
        });
        setSelected(newData);
    };

    const handleSelectAllClick = (ele: any) => {
        const newData = [...selected].map((elem: any) => {
            if (elem.title === ele.title) {
                elem.isSelected = !elem.isSelected;
                elem.plantData.forEach((item: any) => {
                    return (item.isSelected = elem.isSelected);
                });
            }
            return elem;
        });
        setSelected(newData);
    };

    const onSearchTextChange = (e: any) => {
        const { value } = e?.target;
        setSearchText(value);
    };
    function findDeepObject(dataSet: any, matchFn: any) {
        return dataSet.reduce((current: any, row: any) => {
            if (typeof row === 'object') {
                for (const prop in row) {
                    if (Array.isArray(row[prop])) {
                        var set = findDeepObject(row[prop], matchFn);
                        if (set && set.length > 0) {
                            // copy the row, replace the array property with the results
                            current.push(Object.assign({}, row, { [prop]: set }));
                            // since the full row is now in there
                            // no need to check more
                            break;
                        }
                    } else if (matchFn(row[prop])) {
                        // copy not ref
                        current.push(Object.assign({}, row));
                        break;
                    }
                }
            }
            return current;
        }, []);
    }
    return (
        <div style={{ display: 'flex', flexDirection: 'column', justifyContent: 'center' }}>
            {isSearch && (
                <div style={{ position: 'relative', margin: '20px 0px 80px' }}>
                    <SearchBar
                        searchText={searchText}
                        setSearchText={onSearchTextChange}
                        resetSearchText={() => setSearchText('')}
                    />
                </div>
            )}
            <div>
                {findDeepObject(selected, (i: any) => {
                    return i.toString().toLowerCase().indexOf(searchText?.toLowerCase()) >= 0;
                })?.map((ele: any, index: any) => {
                    const keyIdx = index + 1;
                    return (
                        <Accordion
                            expanded={expanded === 'panel' + keyIdx}
                            onChange={handleChange('panel' + keyIdx)}
                            key={keyIdx}
                        >
                            <AccordionSummary
                                expandIcon={<ExpandMoreIcon />}
                                aria-controls={'panel' + keyIdx + 'a-content'}
                                id={'panel' + keyIdx + 'a-header'}
                            >
                                {edit ? (
                                    <Checkbox checked={ele.isSelected} onChange={() => handleSelectAllClick(ele)} />
                                ) : null}

                                <Typography style={{ marginTop: '10px' }}>{ele.title}</Typography>
                                <Typography
                                    className="h5"
                                    style={{
                                        position: 'absolute',
                                        right: '60px',
                                        top: edit ? '24px' : '20px',
                                        fontSize: '12px'
                                    }}
                                >
                                    {ele?.plantData?.filter((d: any) => d.isSelected)?.length
                                        ? `(${ele?.plantData?.filter((d: any) => d.isSelected)?.length})`
                                        : null}
                                </Typography>
                            </AccordionSummary>
                            <AccordionDetails>
                                {ele?.plantData.map((elem: any, idx: any) => {
                                    const __index = idx + 1;
                                    return (
                                        <div style={{ display: 'flex' }} key={__index + '__pl'}>
                                            {edit ? (
                                                <Checkbox
                                                    checked={elem.isSelected || ele.isSelected ? true : false}
                                                    onChange={() => handleClick(idx, ele)}
                                                />
                                            ) : null}
                                            <Typography
                                                style={{
                                                    display: 'flex',
                                                    justifyContent: 'space-between',
                                                    width: '100%'
                                                }}
                                                key={__index + '__typo'}
                                            >
                                                {elem?.title}
                                                <Popover
                                                    itemsArray={[
                                                        {
                                                            userName: elem.name
                                                        }
                                                    ]}
                                                    infoOnly={true}
                                                    src={''}
                                                />
                                            </Typography>
                                        </div>
                                    );
                                })}
                            </AccordionDetails>
                        </Accordion>
                    );
                })}
            </div>
        </div>
    );
}
