import React, { useState, useEffect } from 'react';
import CustomTable from '../../../../atoms/CustomTable/index';
import CustomTableToolbar from '../../../../atoms/CustomTableToolbar';
import { Container, Card } from '@mui/material';
import '../../ClientManagement/index.scss';
import { useDispatch } from 'react-redux';
import useGetState from '../../../../utils/hooks/useGetState';
import { executeACGAction, updateScreenIdentifiers } from '../../../../store/slice';
import { ACTION_CODES, STORE_KEYS } from '../../../../constants/apiConstants';
import { applySortFilter } from '../../../../utils/helper';
import DetailDrawer from '../../../../atoms/DetailDrawer';
import AlertBox from '../../../../atoms/AlertBox';

const TABLE_HEAD = [
    { id: 'MenuOrderId', label: 'Display Order', alignRight: false },
    { id: 'name', label: 'Name', alignRight: false },
    { id: 'ModuleName', label: 'Module', alignRight: false },
    { id: 'Action', label: 'Action', alignLeft: true }
];
const options = {
    DEFAULT: {
        message: '',
        open: false,
        type: ''
    },
    RM_SUCCESS: {
        message: 'Report deleted successfully!',
        open: true,
        type: 'success'
    }
};
type ComponentProps = {
    getSummary: Function;
    setReload: Function;
    reload: Boolean;
    dtlProps?: any;
};
const Menus = (props: ComponentProps) => {
    const { getSummary, setReload = () => {}, reload = false, dtlProps = {} } = props;
    const [state, setState] = useState({
        right: false
    });
    const [page, setPage] = useState<number>(0);
    const [order, setOrder] = useState('desc');
    const [orderBy, setOrderBy] = useState('name');
    const [selected, setSelected] = useState<any>([]);
    const [filterName, setFilterName] = useState<string>('');
    const [tableData, setTableData] = useState<any[]>([]);
    const [snackbarOptions, setSnackbarOptions] = useState(options.DEFAULT);
    const [removeBxOpen, setRemoveBxOpen] = useState(false);
    const [rmMenu, setRmMenu] = useState(false);
    const [isMenuLoading,setIsMenuLoading]=useState(false)

    const dispatch = useDispatch();
    const stateObj = useGetState();

    const closeRmBx = () => {
        setRemoveBxOpen(false);
    };
    const getMenus = () => {
        setIsMenuLoading(true)
        dispatch(
            executeACGAction({
                payload: {
                    requestType: 'GET',
                    urlPath: ACTION_CODES.GET_MENUS
                },
                uniqueScreenIdentifier: { menusLoaded: true },
                storeKey: STORE_KEYS.MENUS
            })
        );
    };

    useEffect(() => {
        getMenus();
    }, []);

    useEffect(() => {
        if (reload) {
            getSummary();
            getMenus();
            setReload(false);
        }
    }, [reload]);

    useEffect(() => {
        if (stateObj?.[STORE_KEYS.MENUS]?.menusLoaded) {
            const list = stateObj?.[STORE_KEYS.MENUS]?.body?.data;
            const setData = list?.map((mod: any) => {
                const record = { ...mod };
                record.name = record?.MenuName;
                return record;
            });
            setTableData(setData);
            dispatch(
                updateScreenIdentifiers({
                    storeKey: STORE_KEYS.MENUS,
                    uniqueScreenIdentifier: {
                        menusLoaded: false
                    }
                })
            );
            setIsMenuLoading(false)
        }
    }, [stateObj?.[STORE_KEYS.MENUS]?.menusLoaded]);

    const handleRequestSort = (event: any, property: any) => {
        setFilterName('');
        const isAsc = orderBy === event && order === 'asc';
        setOrder(isAsc ? 'desc' : 'asc');
        setOrderBy(event);
    };

    const handleSelectAllClick = (event: any) => {
        if (event.target.checked) {
            const newSelecteds = [];
            for (let i = 0; i < tableData.length; i++) {
                newSelecteds.push(tableData[i].id);
            }
            const toRemove = tableData.slice(page, tableData.length);
            setSelected(newSelecteds);
            return;
        }
        setSelected([]);
    };

    const handleClick = (requestedBy: string) => {
        const selectedIndex = selected.indexOf(requestedBy);
        let newSelected: any[] | ((prevState: unknown[]) => unknown[]) = [];
        if (selectedIndex === -1) {
            newSelected = newSelected.concat(selected, requestedBy);
        } else if (selectedIndex === 0) {
            newSelected = newSelected.concat(selected.slice(1));
        } else if (selectedIndex === selected.length - 1) {
            newSelected = newSelected.concat(selected.slice(0, -1));
        } else if (selectedIndex > 0) {
            newSelected = newSelected.concat(selected.slice(0, selectedIndex), selected.slice(selectedIndex + 1));
        }
        setSelected(newSelected);
    };
    const filteredList = applySortFilter(tableData, order, filterName, orderBy);
    const handleFilterByName = (event: { target: { value: React.SetStateAction<string> } }) => {
        setPage(0);
        setOrderBy('name');
        setFilterName(event.target.value);
    };
    const handleChangePage = (event: any, newPage: React.SetStateAction<number>) => {
        setPage(newPage);
    };

    const showRow = (event: any) => {};

    const handleRoute = (row: any, ele: any) => {
        if (ele === 'Action') {
            dtlProps?.setDtlData(row);
            setDtlDrawerOpen(true);
        }
    };

    const [dtlDrawerOpen, setDtlDrawerOpen] = useState(false);
    const closeDtlDrawer = () => {
        setDtlDrawerOpen(false);
    };

    const handleFilter = () => {};
    const [menuKey, setMenuKey] = useState<any>();

    const removeUser = (ele: any) => {
        setMenuKey(ele?.MenuKey);
        setRemoveBxOpen(true);
    };

    useEffect(() => {
        if (rmMenu) {
            closeRmBx();
            dispatch(
                executeACGAction({
                    payload: {
                        requestType: 'DELETE',
                        urlPath: `${ACTION_CODES.DELETE_MENU}/${menuKey}`
                    },
                    uniqueScreenIdentifier: {
                        menuRemoved: true
                    },
                    storeKey: STORE_KEYS.DELETE_MENU
                })
            );
            setRmMenu(false);
        }
    }, [rmMenu]);

    useEffect(() => {
        if (stateObj?.[STORE_KEYS.DELETE_MENU]?.menuRemoved) {
            if (stateObj?.[STORE_KEYS.DELETE_MENU]?.statusCode === 200) {
                setSnackbarOptions(options.RM_SUCCESS);
                closeDtlDrawer();
                getSummary();
                getMenus();
            }
            dispatch(
                updateScreenIdentifiers({
                    storeKey: STORE_KEYS.DELETE_MENU,
                    uniqueScreenIdentifier: {
                        menuRemoved: false
                    }
                })
            );
        }
    }, [stateObj?.[STORE_KEYS.DELETE_MENU]?.menuRemoved]);
    const resetInput = () => {
        setFilterName('');
    };

    return (
        <div>
          
                <div>
                    <Container
                        maxWidth="xl"
                        style={{
                            paddingLeft: '3px',
                            paddingRight: '3px'
                        }}
                    >
                        <Card
                            style={{
                                borderRadius: '0px',
                                backgroundColor: '#2b2d42',
                                overflow: 'inherit'
                            }}
                        >
                            <CustomTableToolbar
                                drawerOpen={() => {}}
                                numSelected={selected?.length}
                                filterName={filterName}
                                onFilterName={handleFilterByName}
                                displayInput={true}
                                text={{
                                    title: 'Menu List',
                                    subTitle: `${filteredList?.length} Menus`
                                }}
                                filterButton={false}
                                customButton={false}
                                resetInput={resetInput}
                            />
                        </Card>
                    </Container>
                    <CustomTable
                        selectable={false}
                        headers={TABLE_HEAD}
                        body={filteredList}
                        isLoading={isMenuLoading}
                        filteredUsers={filteredList}
                        filterName={filterName}
                        selected={selected}
                        order={order}
                        orderBy={orderBy}
                        onRequestSort={handleRequestSort}
                        onSelectAllClick={handleSelectAllClick}
                        rowChange={(event: string) => handleClick(event)}
                        rowCount={filteredList?.length}
                        onPageChange={handleChangePage}
                        page={page}
                        setPageNumber={setPage}
                        selectRow={showRow}
                        route={handleRoute}
                        isRequestPage={true}
                        link={true}
                        removeFn={removeUser}
                    />
                </div>
         
            <AlertBox
                isOpen={removeBxOpen}
                handleClose={closeRmBx}
                leftBtnPress={closeRmBx}
                rightBtnPress={() => setRmMenu(true)}
                modalHeader="Delete Menu"
                modalContent="Are you sure you want to delete menu?"
            />
            <DetailDrawer hasImage={false}
                handlerOpen={() => {}}
                handlerClose={closeDtlDrawer}
                status={dtlDrawerOpen}
                data={{
                    name: dtlProps?.dtlData?.MenuName,
                    details: [
                        { code: 'Menu Code', name: dtlProps?.dtlData?.MenuCode },
                        { code: 'Menu Name', name: dtlProps?.dtlData?.MenuName },
                        { code: 'Menu Order Id', name: dtlProps?.dtlData?.MenuOrderId }
                    ]
                }}
                type={'Feature'}
                editFn={() => {
                    dtlProps?.setCreateDrawerOpen(true);
                    dtlProps?.setCreateValue('Menu');
                    dtlProps?.setDrawerHeader('Edit');
                }}
                deleteFn={() => removeUser(dtlProps?.dtlData)}
            />
        </div>
    );
};

export default Menus;
