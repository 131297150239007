import { useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';
import { ACTION_CODES, STORE_KEYS } from '../../../constants/apiConstants';
import { executeACGAction } from '../../../store/slice';
import useGetState from '../../../utils/hooks/useGetState';
import CustomTable from '../../../atoms/CustomTable/index';
import { Container, Grid } from '@mui/material';
import Label from '../../../atoms/Label/index';
import TextInput from '../../../atoms/TextInput';
interface Params {
    closeDrawer: Function,
    MachineModelKey: number,
    data: any
}
const TABLE_HEAD = [
    { id: 'MachineModelCode', label: 'Machine Model Code', alignRight: false },
    { id: 'ParamCode', label: 'Param Code', alignRight: false },
    { id: 'ParamName', label: 'Param Name', alignRight: false },
    { id: 'Category', label: 'Category', alignRight: false},
    { id: 'ParamDesc', label: 'Param Desc', alignLeft: true }
];
const GroupParameterList = (params: Params) => {
    const { MachineModelKey, closeDrawer, data } = params;
    const { MachMdlParamGrpKey, GroupName, GroupCode } = data;
    const dispatch = useDispatch();
    const stateObj = useGetState();
    const [selectedRow, setSelectedRow] = useState<any>([]);
    const [page, setPage] = useState<number>(0);
    const [order, setOrder] = useState('desc');
    const [orderBy, setOrderBy] = useState('GroupCode');
    const [selected, setSelected] = useState<any>([]);
    const [filterName, setFilterName] = useState<string>('');
    console.log('data->', data);

    const getParamGroupDetails = (key: number) => {
        if(!key){
            return
        }
        dispatch(
            executeACGAction({
                payload: {
                    requestType: 'GET',
                    urlPath: ACTION_CODES.GET_PARAM_GROUP_DETAILS + '/' + key,
                },
                uniqueScreenIdentifier: { getParamGroupDetailsLoaded: true },
                storeKey: STORE_KEYS.GET_PARAM_GROUP_DETAILS_DATA
            })
        );
    }

    useEffect(() => {
        getParamGroupDetails(MachMdlParamGrpKey);
    }, [MachMdlParamGrpKey])

    const handleRequestSort = (event: any, property: any) => {
        setFilterName('');
        const isAsc = orderBy === event && order === 'asc';
        setOrder(isAsc ? 'desc' : 'asc');
        setOrderBy(event);
    };
    const handleChangePage = (event: any, newPage: React.SetStateAction<number>) => {
        setPage(newPage);
    };
    const handleSelectAllClick = (event: any) => {

    };
    const showRow = (row: any) => {
        const rowId = row['MMParamKey'];
        const isSelected = selected.length && selected.indexOf(rowId) !== -1;
        if (isSelected) {
            setSelected([...selected.filter((item: number) => item !== rowId)]);
        } else {
            setSelected([...selected, rowId]);
        }
    };

    const handleRoute = (row: any, ele: any) => {
        if (ele === 'Action') {
        }
    };

    const handleClick = (requestedBy: string) => {
        const selectedIndex = selected.indexOf(requestedBy);
        let newSelected: any[] | ((prevState: unknown[]) => unknown[]) = [];
        if (selectedIndex === -1) {
            newSelected = newSelected.concat(selected, requestedBy);
        } else if (selectedIndex === 0) {
            newSelected = newSelected.concat(selected.slice(1));
        } else if (selectedIndex === selected.length - 1) {
            newSelected = newSelected.concat(selected.slice(0, -1));
        } else if (selectedIndex > 0) {
            newSelected = newSelected.concat(selected.slice(0, selectedIndex), selected.slice(selectedIndex + 1));
        }
        setSelected(newSelected);
    };

    const filteredList = stateObj?.[STORE_KEYS.GET_PARAM_GROUP_DETAILS_DATA]?.body?.data ?? [];

    return <>
        <Container
            maxWidth="xl"
            style={{
                paddingLeft: '3px',
                paddingRight: '3px'
            }}
        >
            <div style={{ padding: '24px', borderBottom: '1px solid rgb(43 45 66 / 100%)', marginBottom: '24px' }}>

                <Grid container spacing={2}>

                    <Grid item xs={4} lg={3} md={3} sm={6}>
                        <Label label="Group Code" className={'labelDiv'} />
                        <TextInput
                            variant="outlined"
                            fullWidth
                            readOnly={true}
                            type="text"
                            name="GroupCode"
                            value={GroupCode}
                            autoComplete="off"
                            formInput="inputDiv"
                            onChange={() => {
                            }}
                            onBlur={() => { }}
                            placeholder=""
                            helperText={''}
                        />
                    </Grid>
                    <Grid item xs={4} lg={3} md={3} sm={6}>
                        <Label label="Group Name" className={'labelDiv'} />
                        <TextInput
                            readOnly={true}
                            variant="outlined"
                            fullWidth
                            type="text"
                            name="GroupName"
                            value={GroupName}
                            autoComplete="off"
                            formInput="inputDiv"
                            onChange={() => { }}
                            onBlur={() => { }}
                            placeholder=""
                            helperText={''}
                        />
                    </Grid>
                </Grid>
                <div style={{ paddingTop: '15px' }}>
                    <CustomTable
                        selectable={false}
                        headers={TABLE_HEAD}
                        body={filteredList}
                        filteredUsers={filteredList}
                        filterName={filterName}
                        selected={selected}
                        order={order}
                        orderBy={orderBy}
                        onRequestSort={handleRequestSort}
                        onSelectAllClick={handleSelectAllClick}
                        rowChange={(event: string) => handleClick(event)}
                        rowCount={filteredList.length}
                        onPageChange={handleChangePage}
                        page={page}
                        setPageNumber={setPage}
                        selectRow={showRow}
                        route={handleRoute}
                        isRequestPage={true}
                        link={true}
                        idKey="MMParamKey"
                    />
                </div>
            </div>
        </Container>
    </>
}

export default GroupParameterList;
