import { useState, useEffect, useRef, useMemo } from 'react';
import { Container, Card, Grid } from '@mui/material';
import AcgButton from '../../../../atoms/Button/index';
import { Formik, Form, FormikProps } from 'formik';
import * as Yup from 'yup';
import Label from '../../../../atoms/Label/index';
import TextInput from '../../../../atoms/TextInput';
import { executeACGAction, resetErr } from '../../../../store/slice';
import { useDispatch } from 'react-redux';
import useGetState from '../../../../utils/hooks/useGetState';
import CustomDatePicker from '../../../../atoms/CustomDatePicker';
import { _noop } from '../../../../utils/common';
import { CLIENTINFO_YUPSCHEMA } from '../schema';
import { ACTION_CODES, STORE_KEYS } from '../../../../constants/apiConstants';

type Props = {
    drawerClose: Function;
    setCurrTab: Function;
    setReload: Function;
    setSnackbarOpts: Function;
    setStep: Function;
    step1Vals: any;
    setStep1Vals: Function;
    isEdit: boolean;
    dtlData: any;
};

type ClientType = {
    clientCode: string | '';
    clientName: string | '';
    clientAllowedUsers: string | '';
    clientDomain: string | '';
    clientEndDate: string | '';
};

const CreateClient = (props: Props) => {
    const { drawerClose, setCurrTab, setStep1Vals, step1Vals, setStep, setSnackbarOpts, isEdit, dtlData } = props;
    const formRef = useRef<any>(null);
    const [validationSchema, setValidationSchema] = useState({});
    const state = useGetState();
    const dispatch = useDispatch();

    const [initialValues] = useState(
        isEdit
            ? {
                clientCode: step1Vals.customerCode || dtlData?.CustomerCode,
                clientName: step1Vals.customerName || dtlData?.CustomerName,
                clientDomain: step1Vals.clientDomain || dtlData?.DomainName,
                clientAllowedUsers: step1Vals.noOfUserAllowed || dtlData?.NoOfUserAllowed,
                clientEndDate: step1Vals.customerEndDate || new Date(dtlData?.CustomerEndDate),
                customerImagePath:
                    step1Vals.customerImagePath || dtlData?.CustomerImagePath || 'https://picsum.photos/200/300'
            }
            : {
                clientCode: step1Vals.customerCode || '',
                clientName: step1Vals.customerName || '',
                clientDomain: step1Vals.clientDomain || '',
                clientAllowedUsers: step1Vals.noOfUserAllowed || '',
                clientEndDate: step1Vals.customerEndDate || new Date(),
                customerImagePath: step1Vals.customerImagePath || 'https://picsum.photos/200/300'
            }
    );
    useEffect(() => {
        setValidationSchema(Yup.object().shape(CLIENTINFO_YUPSCHEMA));
    }, []);

    const handleSubmit = async (values: any) => {
        const vals = {
            customerCode: values.clientCode,
            customerName: values?.clientName,
            noOfUserAllowed: Number(values?.clientAllowedUsers),
            clientDomain: values?.clientDomain,
            customerEndDate: values?.clientEndDate
        };
        setStep1Vals(vals);
        setStep(1);
    };

    const renderButtons = () => {
        return (
            <div className={'btns noWrap'}>
                <>
                    <AcgButton
                        name={'Cancel'}
                        handleClick={drawerClose}
                        secondary={true}
                        className={'first-btn'}
                        type="button"
                        variant="contained"
                    />

                    <AcgButton
                        name="Proceed"
                        handleClick={() => { }}
                        type="submit"
                        className="second-btn"
                        variant="contained"
                    />
                </>
            </div>
        );
    };

    const handleSnackbarError = (err: any) => {
        const errorMsg = err?.message || 'Internal Server error';
        const snackbarError = {
            message: errorMsg,
            type: 'reject',
            open: true
        };

        setSnackbarOpts(snackbarError);
    };

    useEffect(() => {
        if (state?.err) {
            handleSnackbarError(state?.err);
            dispatch(resetErr());
        }
    }, [state?.err]);

    return (
        <Formik
            initialValues={initialValues}
            validationSchema={validationSchema}
            onSubmit={(values: any) => {
                handleSubmit(values);
            }}
            validateOnBlur={false}
            validateOnChange={false}
            innerRef={formRef}
        >
            {(formikprops: FormikProps<ClientType>) => {
                const { values, handleChange, handleBlur, isValid, errors, touched, setFieldValue, validateField } =
                    formikprops;
                return (
                    <Form>
                        <>
                            <Label label="Client Code" className={'labelDiv'} />
                            <TextInput
                                variant="outlined"
                                maxLength={30}
                                autoFocus
                                fullWidth
                                type="text"
                                name="clientCode"
                                value={values.clientCode}
                                autoComplete="off"
                                formInput="inputDiv"
                                onChange={(e: any) => {
                                    handleChange(e);
                                }}
                                disabled={isEdit}
                                onBlur={handleBlur}
                                placeholder="Enter Client Code"
                                helperText={errors.clientCode && touched.clientCode ? errors.clientCode : ''}
                            />
                            <Label label="Client Name" className={'labelDiv'} />
                            <TextInput
                                variant="outlined"
                                maxLength={60}
                                // autoFocus
                                fullWidth
                                type="text"
                                name="clientName"
                                value={values.clientName}
                                autoComplete="off"
                                formInput="inputDiv"
                                onChange={(e: any) => {
                                    handleChange(e);
                                }}
                                onBlur={handleBlur}
                                placeholder="Enter Client Name"
                                helperText={errors.clientName && touched.clientName ? errors.clientName : ''}
                            />
                            <Label label="Client Domain" className={'labelDiv'} />
                            <TextInput
                                variant="outlined"
                                maxLength={60}
                                // autoFocus
                                fullWidth
                                type="text"
                                name="clientDomain"
                                value={values.clientDomain}
                                autoComplete="off"
                                formInput="inputDiv"
                                onChange={(e: any) => {
                                    handleChange(e);
                                }}
                                onBlur={handleBlur}
                                placeholder="Enter Client Name"
                                helperText={errors.clientDomain && touched.clientDomain ? errors.clientDomain : ''}
                            />
                            <Label label="Number of users allowed" className={'labelDiv'} />
                            <TextInput
                                variant="outlined"
                                maxLength={5}
                                // autoFocus
                                fullWidth
                                type="text"
                                name="clientAllowedUsers"
                                value={values.clientAllowedUsers}
                                autoComplete="off"
                                formInput="inputDiv"
                                onChange={(e: any) => {
                                    handleChange(e);
                                }}
                                onBlur={handleBlur}
                                placeholder="Enter Number of users allowed"
                                helperText={
                                    errors.clientAllowedUsers && touched.clientAllowedUsers
                                        ? errors.clientAllowedUsers
                                        : ''
                                }
                            />
                            <Label label="Client end date" className={'labelDiv'} />
                            <CustomDatePicker
                                name="clientEndDate"
                                value={values.clientEndDate}
                                className="inputDiv"
                                onChange={(val: any) => {
                                    setFieldValue('clientEndDate', val);
                                }}
                                inputFormat="dd/MM/yyyy"
                                placeholder="Select Client End Date"
                                helperText={errors.clientEndDate && touched.clientEndDate ? errors.clientEndDate : ''}
                                minDate={new Date()}
                            />
                        </>
                        {renderButtons()}
                    </Form>
                );
            }}
        </Formik>
    );
};

export default CreateClient;
