import { useState, useEffect } from 'react';
import { useDispatch } from 'react-redux';
import { Container } from '@mui/material';
import MapCustomTabs from '../../../atoms/ButtonTabs/MapCustomTabs';
import StatusCard from '../../../atoms/DashboardStatus/StatusCard';
import Roles from './Roles';
import Users from './Users';
import TotalRolesIcon from '../../../../assets/images/dashboardicons/TotalRoles.svg';
import TotalUserIcon from '../../../../assets/images/dashboardicons/TotalUsers.svg';
import UserManagementSelectionBar from '../../../atoms/UserManagementSelectionBar';
import { ACTION_CODES, STORE_KEYS } from '../../../constants/apiConstants';
import { executeACGAction, updateScreenIdentifiers } from '../../../store/slice';
import useGetState from '../../../utils/hooks/useGetState';
import { _noop } from '../../../utils/common';
import '../ClientManagement/index.scss';
import CustomRadioGroup from '../../../atoms/CustomRadioGroup';
import DropDown from '../../../atoms/Dropdown/index';
import Label from '../../../atoms/Label/index';
import { Grid } from '@material-ui/core';
import DrawerDropdown from '../../../atoms/DrawerDropdown';
import RightDrawer from '../../../atoms/tempDrawer/indexAlt';
import CreateUser from './CreateUser';
import { SCREEN_TITLES } from '../../../constants/StringConstants';
import CreateRole from './CreateRole';
import SnackbarAlert from '../../../atoms/Snackbar';

const tabs = ['Roles', 'Users'];
const ALL_CLIENT = { code: null, name: '--Select Client--' };
const options = {
    DEFAULT: {
        message: '',
        open: false,
        type: ''
    }
};
const UserManagement = () => {
    const [currTab, setTabChange] = useState(0);
    const [radioValue, setRadioValue] = useState('ACG');
    const [selectedClient, setSelectedClient] = useState(null);
    const [drawerHeader, setDrawerHeader] = useState('Create');
    const [clientList, setClientList] = useState<any[]>([]);
    const [createValue, setCreateValue] = useState('');
    const [createDrawerOpen, setCreateDrawerOpen] = useState(false);
    const [snackbarOptions, setSnackbarOptions] = useState(options.DEFAULT);
    const [reload, setReload] = useState(false);
    const [dtlData, setDtlData] = useState<any>({});
    const [page, setPage] = useState<number>(0);
    const dispatch = useDispatch();
    const state = useGetState();

    const getCreateValue = (ele: string) => {
        setDrawerHeader('Create');
        setCreateValue(ele);
        setCreateDrawerOpen(true);
    };

    const setCurrTab = (val: number) => {
        setTabChange(val);
        if (radioValue == 'All' && currTab === 1) {
            setRadioValue('ACG');
        }
    };

    const handleRadioValue = (val: string) => {
        setPage(0);
        setRadioValue(val);
        setSelectedClient(null);
    };

    const getSummary = () => {
        dispatch(
            executeACGAction({
                payload: {
                    requestType: 'GET',
                    urlPath: ACTION_CODES.GET_USER_SUMMARY
                },
                storeKey: STORE_KEYS.USER_SUMMARY
            })
        );
    };

    const getClients = () => {
        const tmpTableData = state[STORE_KEYS.CLIENT_DROPDOWN]?.body?.data;

        if (tmpTableData?.length) {
            setClientList([
                ALL_CLIENT,
                ...tmpTableData.map((item: any) => ({ code: item.CustomerKey, name: item.CustomerName }))
            ]);
            dispatch(
                updateScreenIdentifiers({
                    storeKey: STORE_KEYS.CLIENT_DROPDOWN,
                    uniqueScreenIdentifier: {
                        isLoading: false
                    }
                })
            );
        } else if (!state[STORE_KEYS.CLIENT_DROPDOWN]?.isLoading) {
            dispatch(
                executeACGAction({
                    payload: {
                        requestType: 'GET',
                        urlPath: ACTION_CODES.GET_CLIENT_DROPDOWN
                    },
                    storeKey: STORE_KEYS.CLIENT_DROPDOWN,
                    uniqueScreenIdentifier: {
                        isLoading: true
                    }
                })
            );
        }
    };

    useEffect(getSummary, []);
    useEffect(() => {
        if (radioValue === 'Customer' && !clientList.length) {
            getClients();
        }
    }, [radioValue, state[STORE_KEYS.CLIENT_DROPDOWN]?.body?.data]);

    const navigateTab = () => {
        switch (currTab) {
            case 1:
                return (
                    <Users
                        getSummary={getSummary}
                        radioValue={radioValue}
                        selectedClient={selectedClient}
                        setDtlData={setDtlData}
                        dtlData={dtlData}
                        setCreateDrawerOpen={setCreateDrawerOpen}
                        setCreateValue={setCreateValue}
                        setDrawerHeader={setDrawerHeader}
                        setTabChange={setTabChange}
                        setPage={setPage}
                        page={page}
                    />   
                );

            default:
                return (
                    <Roles
                        getSummary={getSummary}
                        radioValue={radioValue}
                        selectedClient={selectedClient}
                        setDtlData={setDtlData}
                        dtlData={dtlData}
                        setCreateDrawerOpen={setCreateDrawerOpen}
                        setCreateValue={setCreateValue}
                        setDrawerHeader={setDrawerHeader}
                        setTabChange={setTabChange}
                    />
                );
        }
    };
    const closeSnackbar = () => setSnackbarOptions(options.DEFAULT);

    const { TotalRoles = 0, TotalUsers = 0 } = state[STORE_KEYS.USER_SUMMARY]?.body?.data?.[0] || {};

    return (
        <div className="dashboard">
            <SnackbarAlert options={snackbarOptions} handleClose={closeSnackbar} />

            <Container maxWidth="xl" style={{ marginTop: '60px' }}>
                <div style={{ display: 'flex', alignItems: 'center', flexWrap: 'wrap' }}>
                    <div style={{ flex: '80%' }}>
                        <div className="headinglabel">{SCREEN_TITLES.USER_MANAGEMENT}</div>
                        <StatusCard
                            totalClients={[
                                {
                                    count: TotalRoles,
                                    handleClick: _noop,
                                    image: TotalRolesIcon,
                                    text: 'Total Roles'
                                },
                                {
                                    count: TotalUsers,
                                    handleClick: _noop,
                                    image: TotalUserIcon,
                                    text: 'Total Users'
                                }
                            ]}
                        />
                    </div>
                    <div
                        style={{
                            flex: '10%',
                            marginLeft: '20px',
                            marginTop: '60px',
                            display: 'flex',
                            justifyContent: 'flex-end'
                        }}
                        className="dashboardDropdownDiv"
                    >
                        <DrawerDropdown click={getCreateValue} items={['New User', 'New Role']} />
                    </div>
                </div>
                <div style={{ marginTop: '35px', zIndex: '0' }}>
                    {state[STORE_KEYS.USER_DTL]?.IsACGUser === 'Y' && (
                        <UserManagementSelectionBar>
                            <Grid container className="section-buttons" spacing={2}>
                                <Grid item>
                                    <Label label="Type of User" />
                                    <CustomRadioGroup
                                        value={radioValue}
                                        fields={[
                                            ...(currTab === 1 ? [{ label: 'All', value: 'All' }] : []),
                                            { label: 'ACG', value: 'ACG' },
                                            { label: 'Client', value: 'Customer' }
                                        ]}
                                        onChange={handleRadioValue}
                                    />
                                </Grid>
                                {radioValue === 'Customer' && (
                                    <Grid item xs={4}>
                                        <Label label="Client" />
                                        <DropDown
                                            onChange={(event: any) => {
                                                setSelectedClient(event?.target?.value);
                                                setPage(0);
                                            }}
                                            className="user-client-dropdown"
                                            name="client"
                                            items={clientList}
                                            value={selectedClient}
                                            restricted={true}
                                        />
                                    </Grid>
                                )}
                            </Grid>
                        </UserManagementSelectionBar>
                    )}
                    <MapCustomTabs
                        navigateTab={navigateTab}
                        currTab={currTab}
                        setCurrTab={setCurrTab}
                        tabsArray={tabs}
                        className="tabs"
                        position="relative"
                        type="nonCurve"
                    />
                </div>
            </Container>
            <RightDrawer
                handlerOpen={() => {}}
                handlerClose={() => setCreateDrawerOpen(false)}
                status={createDrawerOpen}
                drawerHeader={`${drawerHeader} ${createValue}`}
                children={
                    drawerHeader === 'Create' ? (
                        createValue === 'New User' ? (
                            <CreateUser
                                drawerClose={() => setCreateDrawerOpen(false)}
                                setCurrTab={setCurrTab}
                                setReload={setReload}
                                dtlData={dtlData}
                                setSnackbarOpts={setSnackbarOptions}
                                isEdit={false}
                            />
                        ) : (
                            <CreateRole
                                drawerClose={() => setCreateDrawerOpen(false)}
                                setCurrTab={setCurrTab}
                                setReload={setReload}
                                dtlData={dtlData}
                                setSnackbarOpts={setSnackbarOptions}
                                isEdit={false}
                            />
                        )
                    ) : createValue === 'New User' ? (
                        <CreateUser
                            drawerClose={() => setCreateDrawerOpen(false)}
                            setCurrTab={setCurrTab}
                            setReload={setReload}
                            dtlData={dtlData}
                            setSnackbarOpts={setSnackbarOptions}
                            isEdit={true}
                        />
                    ) : (
                        <CreateRole
                            drawerClose={() => setCreateDrawerOpen(false)}
                            setCurrTab={setCurrTab}
                            setReload={setReload}
                            dtlData={dtlData}
                            setSnackbarOpts={setSnackbarOptions}
                            isEdit={true}
                        />
                    )
                }
            />
        </div>
    );
};

export default UserManagement;
