import React, { useState, useEffect } from 'react';
import { useDispatch } from "react-redux";
import { Icon } from '@iconify/react';
import searchFill from '@iconify/icons-eva/search-fill';
import closeFill from '@iconify/icons-eva/close-fill';
import filterIcon from '../../../assets/images/filter.svg';
import { styled } from '@mui/material/styles';
import { Box, Toolbar, FilledInput, InputAdornment, Button } from '@mui/material';
import AddIcon from '../../../assets/images/AddIcon.svg';
import useDebounce from '../../hooks/useDebounce';
import Attachments from '../../atoms/Attachments';
import { read, utils } from 'xlsx';
import useGetState from "../../utils/hooks/useGetState";
import { ACTION_CODES, STORE_KEYS } from "../../constants/apiConstants";
import { executeACGAction, resetErr, updateScreenIdentifiers } from "../../store/slice";

const RootStyle = styled(Toolbar)(({ theme }) => ({
    height: 96,
    display: 'flex',
    justifyContent: 'space-between'
    // padding: theme.spacing(0, 1, 0, 3)
}));

const SearchStyle = styled(FilledInput)(({ theme }) => ({
    width: 300,
    transition: theme.transitions.create(['box-shadow', 'width'], {
        easing: theme.transitions.easing.easeInOut,
        duration: theme.transitions.duration.shorter
    }),
    borderRadius: 50,
    height: 40,
    // position: 'absolute',
    right: '1vw',
    // '&.Mui-focused': { width: 320, boxShadow: theme.customShadows.z8 },
    '& fieldset': {
        borderWidth: '1px !important'
        // borderColor: `${theme.palette.grey[500_32]} !important`
    },
    [theme.breakpoints.down('xs')]: {
        right: '18vw',
        width: '250px'
    },
    [theme.breakpoints.down('sm')]: {
        right: '18vw',
        width: '250px'
    },
    [theme.breakpoints.up('md')]: {
        right: '13vw '
    },
    [theme.breakpoints.up('lg')]: {
        right: '1vw'
    }
}));

interface Props {
    searchParametersHandler: Function,
    parametersCount: number,
    setlIstGroupParamDrawer: Function,
    MachineModelKey: any
    setIsSetFile: Function
}

const HeaderToolbar = (props: Props) => {
    const { searchParametersHandler, parametersCount, setlIstGroupParamDrawer, MachineModelKey, setIsSetFile} = props;

    const [searchInput, setSearchInput] = useState<string>("");
    const debouncedSearchInput = useDebounce<string>(searchInput, 500);
    const dispatch = useDispatch();
    const stateObj = useGetState();
    const searchInputChangeHandler = (event: React.ChangeEvent<HTMLInputElement>) => {
        const val = event.currentTarget.value;
        setSearchInput(val);
    }

    useEffect(() => {

        searchParametersHandler(debouncedSearchInput)
    }, [debouncedSearchInput, searchParametersHandler]);

    const resetInputHandler = () => {
        setSearchInput("");
    }

    return (
        <RootStyle className="tableToolbar">

            <div className={'typeAndCount'}>
                <h6 style={{ marginBottom: '0px' }}>Parameter List</h6>
                <p style={{ marginBottom: '0px', fontSize: '12px' }}>{parametersCount} Parameters</p>
            </div>
            <div style={{width: "200px"}}>
            <Attachments viewOnly={false} type={['.xlsx', '.xls']} isSetFile={setIsSetFile} margin={false}/>
            </div>
            <SearchStyle
                className={'searchBox'}
                value={searchInput}
                onChange={searchInputChangeHandler}
                placeholder="Search"
                startAdornment={
                    <div>
                        <InputAdornment position="start" variant="outlined" className="searchBoxIcon">
                            <Box component={Icon} icon={searchFill} sx={{ color: 'text.disabled' }} />
                        </InputAdornment>
                        {searchInput?.length > 0 ? (
                            <InputAdornment position="end" className="searchBoxIconCancel" onClick={resetInputHandler}>
                                <Box component={Icon} icon={closeFill} sx={{ color: 'text.disabled' }} />
                            </InputAdornment>
                        ) : null}
                    </div>
                }
            />
            <div className="filter" onClick={() => { setlIstGroupParamDrawer(true) }} style={{ display: 'flex' }}>
                <span>Group</span>
            </div>
            {/*    
            <div style={{ display: 'flex', justifyContent: 'center', marginLeft: 'auto' }}>
                <Button
                    variant="contained"
                    size="small"
                    className="addButton"
                    style={{
                        background: 'linear-gradient(270deg, #0f249d 0%, #0f1785 100%)',
                        borderRadius: '20px'
                    }}
                >
                    <div style={{ display: 'flex', justifyContent: 'space-around' }}>
                        <span>Download Excel Templete </span>
                    </div>
                </Button>
            </div>

            <div style={{ display: 'flex', justifyContent: 'center', marginLeft: 'auto' }}>
                <Button
                    variant="contained"
                    size="small"
                    className="addButton"
                    style={{
                        background: 'linear-gradient(270deg, #0f249d 0%, #0f1785 100%)',
                        borderRadius: '20px'
                    }}

                >
                    <div style={{ display: 'flex', justifyContent: 'space-around' }}>
                        <div>
                            <img className="addIcon" src={AddIcon} alt="addIcon" />
                        </div>
                        <span>Add Parameters</span>
                    </div>
                </Button>
            </div>
            */}
        </RootStyle>
    );
};

export default React.memo(HeaderToolbar);
