import { useState, useEffect, useRef } from 'react';
import Box from '@mui/material/Box';
import Tooltip from '@mui/material/Tooltip';
import Axios from 'axios';
import EditIcon from '@mui/icons-material/Edit';
import AddIcon from '@mui/icons-material/Add';
import AlertBox from '../../../atoms/AlertBox';
import DeleteIcon from '@mui/icons-material/Delete';
import RightDrawer from '../../../atoms/tempDrawer/indexAlt';
import { Typography } from '@mui/material';
import Label from '../../../atoms/Label/index';
import TextInput from '../../../atoms/TextInput';
import DownArrow from '../../../../assets/down-arrow.svg';
import DropDown from '../../../atoms/Dropdown';
import InputLabel from '@mui/material/InputLabel';
import CheckBoxOutlineBlankIcon from '@mui/icons-material/CheckBoxOutlineBlank';
import FailureIndicatorConfig from '../CreateFailureIndicatorConfig';
import { QueryBuilderMaterial } from '@react-querybuilder/material';
import CheckBoxIcon from '@mui/icons-material/CheckBox';
import { createTheme, ThemeProvider } from '@mui/material/styles';
import Checkbox from '@mui/material/Checkbox';
import OutlinedInput from '@mui/material/OutlinedInput';
import HelpIcon from '@mui/icons-material/Help';
import Autocomplete from '@mui/material/Autocomplete';
import Radio from '@mui/material/Radio';
import RadioGroup from '@mui/material/RadioGroup';
import FormControlLabel from '@mui/material/FormControlLabel';
import MenuItem from '@mui/material/MenuItem';
import FormControl from '@mui/material/FormControl';
import Select from '@mui/material/Select';
import ListItemText from '@mui/material/ListItemText';
import TextField from '@mui/material/TextField';
import Stack from '@mui/material/Stack';
import Button from '@mui/material/Button';
import Container from '@mui/material/Container';
import ACGButton from '../../../atoms/Button';
import useGetState from '../../../utils/hooks/useGetState';
import { ACTION_CODES, STORE_KEYS, BASEURL } from '../../../constants/apiConstants';
import FMEAService from '../fmeaServices';
import "../cm.scss";
import "./style.css";
import QueryBuilder, { defaultOperators, RuleValidator, ValidationResult, formatQuery } from 'react-querybuilder';
import { Grid } from '@mui/material';
import { AnyARecord } from 'dns';
import { fontWeight } from '@mui/system';
import { set } from 'date-fns';

const ITEM_HEIGHT = 48;
const ITEM_PADDING_TOP = 8;
const MenuProps = {
  PaperProps: {
    style: {
      maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
      width: 250,
    },
  },
};

const initialQuery: any = { combinator: 'and', rules: [] };
export default function CreateForm(props: any) {
  const ref = useRef(null);
  const state = useGetState();
  const token = state?.[STORE_KEYS.USER_DTL].token;
  const loggedInUser = state[STORE_KEYS.USER_DTL]?.LoggedUserKey;
  const [parameterNames, setParameterNames] = useState<any[]>([]);
  const [parameterNames2, setParameterNames2] = useState<any[]>([]);
  const [parameterData, setParameterData] = useState([]);
  const [customizedQuery, setCustomizedQuery] = useState('');
  const [query, setQuery] = useState<any>(initialQuery);
  const [query2, setQuery2] = useState<any>(initialQuery);
  const [indicatorDone, setIndicatorDone] = useState<boolean>(false);
  const [done, setDone] = useState<boolean>(false);
  const [groupNameData, setGroupNameData] = useState([]);
  // const [groupKeyNames, setGroupKeyNames] = useState<any[]>([]);
  const [operators, setOperators] = useState([]);
  const [allQuries, setAllQueries] = useState<any>([]);
  const [nestedQueries, setNestedQueries] = useState<any>([]);
  const [removeBxOpen, setRemoveBxOpen] = useState(false);
  const [rmUser, setRmUser] = useState(false);
  const [key, setKey] = useState('');
  const [ind, setInd] = useState(0);

  useEffect(() => {
    props.setCustomizedQuery2([]);
    FMEAService.getFMEAOperators({ token: token })
      .then((response: any) => {
        const result = response?.data?.message[0];
        setOperators(result[0]);
      })
      .catch((err) => {
        console.log(err);
      });
    getGroupName(props.editableCustomData[0]?.MachineModelKey);
  }, []);

  useEffect(() => {
    setIndicatorDone(false);
    if (props.failureIndicatorsData[0] && props.customKey !== '') {
      (async function () {
        const body = { userId: loggedInUser, ruleKey: props.customKey, token: token }
        await props.getPotentialCause(body);
        getGroupName(props.editableCustomData[0]?.MachineModelKey);
        props.setGroupKeyNames([{ MachMdlParamGrpKey: -1, GroupName: "All" }]);
        let r, rKeys: any, pKeys: any;
        let reversPKeys: any = [];
        const p = await getParameters(props.editableCustomData[0]?.MachineModelKey, -1).then((result: any) => {
          pKeys = (props.failureIndicatorsData[0]?.ParamCodes).split(',');
          result?.map((k: any) => {
            if (pKeys.includes(k.ParamCode.toString())) {
              reversPKeys.push(k)
              handleChangeParameterName(k, result);
            }
          })
          setParameterNames(reversPKeys);
        });
        const qry: any = await JSON.parse(String(props.failureIndicatorsData[0]?.ConditionJson))
        setQuery(qry);
      })()
    }
  }, [props.customKey])

  const getParameters = async (args: any, args2: any) => {
    let finalResult;
    const body = { userId: loggedInUser, machineModelKey: args, groupKey: args2, token: token }
    await FMEAService.getParameterList(body)
      .then(async (response: any) => {
        const result = response?.data?.message[0];
        setParameterData(result[0]);
        finalResult = result[0];
      })
      .catch((err) => {
        console.log(err);
      });
    return finalResult;
  }

  const handleChangeParameterName = (id: any, args: any) => {
    let newSelected: any = [];
    if (props.customKey === '') {
      const selectedIndex = parameterNames.indexOf(id);
      if (selectedIndex === -1) {
        newSelected = newSelected.concat(parameterNames, id);
      } else if (selectedIndex === 0) {
        newSelected = newSelected.concat(parameterNames.slice(1));
      } else if (selectedIndex === parameterNames.length - 1) {
        newSelected = newSelected.concat(parameterNames.slice(0, -1));
      } else if (selectedIndex > 0) {
        newSelected = newSelected.concat(
          parameterNames.slice(0, selectedIndex),
          parameterNames.slice(selectedIndex + 1)
        );
      }
    }
    else {
      const selectedIndex = parameterNames.findIndex((t) => t.MMParamKey === id.MMParamKey);
      if (selectedIndex === -1) {
        newSelected = newSelected.concat(parameterNames, id);
      } else if (selectedIndex === 0) {
        newSelected = newSelected.concat(parameterNames.slice(1));
      } else if (selectedIndex === parameterNames.length - 1) {
        newSelected = newSelected.concat(parameterNames.slice(0, -1));
      } else if (selectedIndex > 0) {
        newSelected = newSelected.concat(
          parameterNames.slice(0, selectedIndex),
          parameterNames.slice(selectedIndex + 1)
        );
      }
    }
    setParameterNames(newSelected);
  }

  useEffect(() => {
    if (query.rules.length === 0) {
      setQuery(initialQuery);
    }

    let qry = formatQuery(query, 'sql'), referenceQuery = formatQuery(query, 'sql');
    for (let i = 0; i < qry.length; i++) {
      if (qry[i] === 'D') {
        const actualName = parameterNames?.find((j: any) => (j.ParamCode === qry.substring(i, i + 5)))?.ParamName;
        referenceQuery = referenceQuery.replace(qry.substring(i, i + 5), actualName)
      }
    }
    setCustomizedQuery(referenceQuery);
  }, [query])

  useEffect(() => {
    if (props.potentialCauses.length > 0) {
      const rl: any = [], tempData:any = [];;
      (async function () {
        await props.setCustomizedQuery2([]);
        let index = 0;
        for (let item of props.potentialCauses) {
          index = index + 1
          const body = { ruleKey: item.PotentialCauseKey, token: token }
          await FMEAService.getExsistingPotentialCauseRecommendations(body)
            .then(async (response: any) => {
              const result = await response.data.message[0];
              if (result) {
                rl.push(result[0]);
              }
            }).catch((err) => {
              console.log(err);
            });
          await props.setRecommndList(rl)
          
          await FMEAService.getExsistingPotentialCauseDetails(body)
            .then(async (response: any) => {
              const result = await response.data.message[0];
              tempData.push(result[0][0])
            });
            tempData.sort((a:any, b:any) => parseInt(a.PotentialCauseKey) - parseInt(b.PotentialCauseKey));
            console.log(tempData)
            setInd(index);
            if(index+1 === props.potentialCauses.length || props.potentialCauses.length === 1) setNestedQueries(tempData); 
            
        }
        setDone(true);
      })()
    }
  }, [props.potentialCauses])

  useEffect(()=>{
    if(ind === props.potentialCauses.length) {
      (async function () {
        const d1: any = [], d2: any = [], d3: any = [], rl: any = [], cqry: any = [];
        console.log(nestedQueries)
        for (let itm of nestedQueries) {
          await d1.push(itm?.ConditionFormula);
          await d2.push(itm?.ConditionJson);
          await d3.push(itm?.ParamCodes);
          props.setGroupKeyNames(itm?.MachMdlParamGrpKeys != "undefined" ? itm?.MachMdlParamGrpKeys : [{ MachMdlParamGrpKey: -1, GroupName: "All" }]);

          let r, rKeys: any, pKeys: any;
          let reversPKeys: any = [];
          const p = await getParameters(props.editableCustomData[0]?.MachineModelKey, -1).then(async (result: any) => {
            pKeys = await (itm?.ParamCodes).split(',');
            result.map(async (k: any) => {
              if (pKeys.includes(k.ParamCode.toString())) {
                await reversPKeys.push(k)
                handleChangeParameterName(k, result);
              }
            })
            setParameterNames2(reversPKeys);

          });
          const qry: any = await JSON.parse(itm?.ConditionJson)
          setQuery2(qry);

        props.setQueryData(d1);
        props.setCustQuery(d2);
        props.setParameters(d3);
        }
          })();
    }
  },[nestedQueries,ind])

  useEffect(() => {
    if (query2?.rules.length === 0) {
      setQuery2(initialQuery);
    }
    let qry = formatQuery(query2, 'sql'), referenceQuery = formatQuery(query2, 'sql');
    for (let i = 0; i < qry.length; i++) {
      if (qry[i] === 'D') {
        const actualName = parameterNames2?.find((j: any) => (j.ParamCode === qry.substring(i, i + 5)))?.ParamName;
        referenceQuery = referenceQuery.replace(qry.substring(i, i + 5), actualName)
      }
      if (referenceQuery !== "(1 = 1)") props.setCustomizedQuery2([...props.customizedQuery2, referenceQuery]);
    }
  }, [query2])

  const getGroupName = async (args: any) => {
    let finalResult;
    const body = { userId: loggedInUser, machineModelKey: args, token: token }
    FMEAService.getFMEAGroupNames(body)
      .then((response: any) => {
        const result = response?.data?.message[0];
        setGroupNameData(result[0]);
        finalResult = result[0];
      })
      .catch((err) => {
        console.log(err);
      });
    return finalResult;
  }

  const options = {
    AD_SUCCESS: {
      message: `Causal parameter deleted successfully!`,
      open: true,
      type: 'success'
    },
  };

  const deleteCasualParameter = (key: any) => {
    setRemoveBxOpen(true);
    setKey(key);
  }

  useEffect(() => {
    if (rmUser) {
      closeRmBx();
      const body = { userId: loggedInUser, ruleKey: key, token: token }
      FMEAService.deletetExsistingFMEACasualParameter(body)
        .then((response) => {
          props.setSnackbarOpts(options.AD_SUCCESS);
          props.getPotentialCause({ userId: loggedInUser, ruleKey: props.customKey, token: token });
        })
        .catch((err) => {
          console.log(err);
        });
      setRmUser(false);
      setKey('')
    }
  }, [rmUser]);

  const closeRmBx = () => {
    setRemoveBxOpen(false);
  };

  return (
    <div className="createReportt">
      <div className="wrapper failuremodulePage">
        <div className="contentWrap monitoringcontentWrap monitoringPage detailPage">
          <div className=" fm-container">
            <div className="row">
              <div className="col-sm-6">
                <div className="grid-header">
                  <span className="grid-label">
                    Failure Mode
                  </span>
                </div>
              </div>
              <div className="col-sm-6">
                <button className="pull-right btn add-entry-button" onClick={() => {
                  props.setNewEntry(true);
                  props.setCreateValue("Causal Parameter");
                }}>
                  <svg width="12" height="12" viewBox="0 0 12 12" fill="none" className='marginR10' xmlns="http://www.w3.org/2000/svg">
                    <path d="M5.99951 0.666504V11.3332" stroke="#ffffff" stroke-linecap="round" stroke-linejoin="round" />
                    <path d="M0.666504 6H11.3332" stroke="#ffffff" stroke-linecap="round" stroke-linejoin="round" />
                  </svg>

                  Add Causal Parameter
                </button>

              </div>
              <div className="container-fluid">
                <div className="row">
                  <div className="col-sm-5">
                    <div className='wraplabels'>
                      <label className="labelDiv label-style">Failure Mode Name</label>
                      <div className="details-container">
                        <span className="sub-grid-label">{props.editableCustomData[0]?.FailureMode}</span>
                        <label
                          className="edit"
                          style={{ marginLeft: "10px" }}
                          onClick={() => {
                            const body = { userId: loggedInUser, ruleKey: props.customKey, token: token }
                            props.fetchFailureMode(body);
                            props.setCreateValue("Failure Mode Entry");
                            props.setNewEntry(true);
                          }}
                        >
                          <EditIcon />

                        </label>
                      </div>
                    </div>
                    <div className='wraplabels'>
                      <label className="labelDiv label-style">Failure Indicator</label>
                      <div className="details-container">
                        <div>
                          <span className="fa fa-minus-square"></span>
                        </div>
                        <div className="tree-trunk">

                          <div >
                            <span className="tree-branch"></span>
                            <span className="tree-branch-content detail-content">
                            {customizedQuery}
                            </span>
                            <label
                              className="edit"
                              style={{ marginLeft: "10px" }}
                              onClick={() => {
                                props.setCreateValue("Failure Indicator");
                                props.setNewEntry(true);
                              }}
                            >
                              <EditIcon />

                            </label>
                          </div>

                        </div>

                      </div>
                    </div>
                  </div>

                  <div className="col-sm-7">
                    <div className='wraplabels'>
                      <label className="labelDiv label-style">Potential Effect of Failure</label>
                      <div className="details-container">
                        <p className="detail-content">{props.editableCustomData[0]?.PotentialEffectsOfFailure}</p>
                      </div>
                    </div>
                  </div>
                </div>
                <div>
                  <div>
                    <div className="potential-cause-title-box">
                      <span className="grid-label">Potential Cause</span>
                    </div>

                    <div className="row potential-cause-grid-content">
                    
                      {
                      props.potentialCauses.length === 0 ?
                      <div style={{padding:"20px"}}><span style={{ color: '#fd3939', marginTop: "50px" }}>No potential cause added!</span></div>
                      :
                      props.potentialCauses && props.customizedQuery2.length > 0 ?
                        props.potentialCauses.map((item: any, index: number) => (
                          <>
                            <div
                              key={index}
                              className="col-md-6"
                            >
                              <div className="potential-cause-container">
                                <div className="row">
                                  <div className="col-sm-6">
                                    <div className="potential-cause-title pull-left sub-grid-label">
                                      {item.PotentialCause}
                                    </div>
                                  </div>
                                  <div className="col-sm-6 text-right">
                                    <span className="rpn-box">RPN : {item.RPN || "NA"}</span>
                                    <label
                                      className="edit"
                                      style={{ marginLeft: "8px" }}
                                      onClick={() => {
                                        props.setSelectedCasualParameter(
                                          index
                                        );
                                        props.setCasualParameterKey(item?.PotentialCauseKey)
                                        props.setCreateValue("Causal Parameter");
                                        props.setNewEntry(false);
                                      }}
                                    >
                                      <EditIcon />

                                    </label>
                                    <label
                                      className="edit"
                                      onClick={() => {
                                        deleteCasualParameter(item?.PotentialCauseKey)
                                      }}

                                    >
                                      <DeleteIcon />

                                    </label>
                                  </div>
                                </div>

                                <div className="row">
                                  <div className="col-md-12 form-group">
                                    <span className="badge badge-secondary status-badge potential-badge">
                                      Occurrence Frequency -{" "}
                                      {item.OccurenceCriteria || "NA"}
                                    </span>
                                    <span className="badge badge-secondary status-badge potential-badge">
                                      Ease of detection -{" "}
                                      {item.DetectionCriteria || "NA"}
                                    </span>
                                  </div>
                                </div>

                                <div>
                                  <span className="fa fa-minus-square"></span>
                                  <span className="sub-grid-label ml-8">Causal Parameter</span>
                                </div>
                                <div className="tree-trunk">

                                  <div >
                                    <span className="tree-branch"></span>
                                    <span className="tree-branch-content detail-content">
                                      {props.customizedQuery2[index]}
                                    </span>
                                  </div>
                                </div>
                                <div className="clearfix form-group"></div>

                                <div className="recommended recommended-action-list">
                                  {props.recommandList && props.recommandList.length > 0 &&
                                    <span className="sub-grid-label">Recommended Action</span>
                                  }

                                  <div className="clearfix"></div>
                                  {props.recommandList &&
                                    props.recommandList.length > 0 && (
                                      <div className="recommended">
                                        {props.recommandList &&
                                          props.recommandList[index]?.map((item: any, ind: number) => (
                                            <div className="card" key={ind}>

                                              {item?.RecommendedAction}

                                            </div>
                                          ))}
                                      </div>
                                    )}
                                </div>
                              </div>
                            </div>
                            {index % 2 == 1 && <div className="clearfix"></div>}
                          </>
                        ))
                      :
                      <div className="ChartDiv"><h6 style={{ color: 'white', marginTop: "50px" }} data-text="Loading....."></h6></div>
                      }
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <AlertBox
        isOpen={removeBxOpen}
        handleClose={closeRmBx}
        leftBtnPress={closeRmBx}
        rightBtnPress={() => setRmUser(true)}
        modalHeader="Delete casual parameter"
        modalContent="Are you sure you want to delete casual parameter?"
      />
    </div>
  );
}