import { useState, useEffect, useRef, useMemo } from 'react';
import { Container, Card, Grid } from '@mui/material';
import AcgButton from '../../../../atoms/Button/index';
import { Formik, Form, FormikProps } from 'formik';
import * as Yup from 'yup';
import Label from '../../../../atoms/Label/index';
import TextInput from '../../../../atoms/TextInput';
import { executeACGAction, resetErr } from '../../../../store/slice';
import { useDispatch } from 'react-redux';
import useGetState from '../../../../utils/hooks/useGetState';
import { _noop } from '../../../../utils/common';
import { ROLE_YUPSCHEMA } from '../schema';
import { ACTION_CODES, STORE_KEYS } from '../../../../constants/apiConstants';
import CustomRadioGroup from '../../../../atoms/CustomRadioGroup';
import DropDown from '../../../../atoms/Dropdown';
import { renameObjKeys } from '../../../Login/helper';

type Props = {
    drawerClose: Function;
    setCurrTab: Function;
    setReload: Function;
    setSnackbarOpts: Function;
    setStep: Function;
    step1Vals: any;
    setStep1Vals: Function;
    isEdit: boolean;
    dtlData: any;
};

type ClientType = {
    roleCode: string | '';
    roleName: string | '';
    radioValue: string | '';
    dropdownClient: string | '';
};

const CreateRole = (props: Props) => {
    const { drawerClose, setStep1Vals, step1Vals, setStep, setSnackbarOpts, isEdit, dtlData } = props;
    const formRef = useRef<any>(null);
    const [validationSchema, setValidationSchema] = useState({});
    const [clients, setClients] = useState<any>([]);
    const [clientName, setClientName] = useState<any>('');
    const [userType, setUserType] = useState<any>(
        isEdit
            ? dtlData?.IsACGUser === 'Y'
                ? 'ACG'
                : step1Vals?.radioValue == 'ACG'
                ? 'ACG'
                : 'Customer'
            : step1Vals?.radioValue
            ? step1Vals?.radioValue == 'ACG'
                ? 'ACG'
                : 'Customer'
            : 'ACG'
    );
    const state = useGetState();
    const dispatch = useDispatch();

    const [initialValues] = useState(
        isEdit
            ? {
                  roleCode: step1Vals?.roleCode || dtlData?.RoleCode,
                  roleName: step1Vals?.roleName || dtlData?.RoleName,
                  radioValue: step1Vals?.radioValue || dtlData?.radioValue,
                  customerName: dtlData?.CustomerName,
                  dropdownClient:
                      state?.[STORE_KEYS.USER_DTL]?.IsACGUser === 'N'
                          ? state?.[STORE_KEYS.USER_DTL]?.CustomerKey
                          : step1Vals?.dropdownClient || dtlData?.CustomerKey
              }
            : {
                  roleCode: step1Vals?.roleCode || '',
                  roleName: step1Vals?.roleName || '',
                  radioValue: step1Vals?.radioValue || 'ACG',
                  dropdownClient:
                      state?.[STORE_KEYS.USER_DTL]?.IsACGUser === 'N'
                          ? state?.[STORE_KEYS.USER_DTL]?.CustomerKey
                          : step1Vals?.dropdownClient || ''
              }
    );

    useEffect(() => {
        setValidationSchema(Yup.object().shape(ROLE_YUPSCHEMA));
        if (step1Vals?.radioValue !== 'ACG' || userType !== 'ACG') {
            dispatch(
                executeACGAction({
                    payload: {
                        requestType: 'GET',
                        urlPath: ACTION_CODES.GET_CLIENT_DROPDOWN
                    },
                    storeKey: STORE_KEYS.CLIENT_DROPDOWN,
                    uniqueScreenIdentifier: {
                        isLoading: true
                    }
                })
            );
        }
    }, [userType]);

    useEffect(() => {
        if (
            state?.[STORE_KEYS.CLIENT_DROPDOWN]?.body?.data &&
            state?.[STORE_KEYS.CLIENT_DROPDOWN]?.statusCode === 200
        ) {
            const clientsDt = state?.[STORE_KEYS.CLIENT_DROPDOWN]?.body?.data;
            setClients(
                clientsDt?.map((item: any) => {
                    const newVal = renameObjKeys(item, { CustomerKey: 'code', CustomerName: 'name' });
                    return newVal;
                })
            );
        }
    }, [state?.[STORE_KEYS.CLIENT_DROPDOWN]?.body?.data]);

    const handleSubmit = async (values: any) => {
        const vals = {
            roleCode: values?.roleCode?.toString(),
            roleName: values?.roleName,
            radioValue: values?.radioValue || initialValues.radioValue,
            dropdownClient: values?.dropdownClient,
            customerName: clientName || dtlData?.CustomerName
        };
        setStep1Vals(vals);
        setStep(1);
    };

    const renderButtons = () => {
        return (
            <div className={'btns noWrap'}>
                <>
                    <AcgButton
                        name={'Cancel'}
                        handleClick={drawerClose}
                        secondary={true}
                        className={'first-btn'}
                        type="button"
                        variant="contained"
                    />

                    <AcgButton
                        name="Proceed"
                        handleClick={() => {}}
                        type="submit"
                        className="second-btn"
                        variant="contained"
                    />
                </>
            </div>
        );
    };

    const handleSnackbarError = (err: any) => {
        const errorMsg = err?.message || 'Internal Server error';
        const snackbarError = {
            message: errorMsg,
            type: 'reject',
            open: true
        };

        setSnackbarOpts(snackbarError);
    };

    useEffect(() => {
        if (state?.err) {
            handleSnackbarError(state?.err);
            dispatch(resetErr());
        }
    }, [state?.err]);

    return (
        <Formik
            initialValues={initialValues}
            validationSchema={validationSchema}
            onSubmit={(values: any) => {
                handleSubmit(values);
            }}
            validateOnBlur={false}
            validateOnChange={false}
            innerRef={formRef}
        >
            {(formikprops: FormikProps<ClientType>) => {
                const { values, handleChange, handleBlur, isValid, errors, touched, setFieldValue, validateField } =
                    formikprops;

                return (
                    <Form>
                        <>
                            <Grid container spacing={2} className="radio-buttons">
                                <Grid item>
                                    <Label label="Type of User" />
                                    <CustomRadioGroup
                                        value={values?.radioValue || userType}
                                        fields={[
                                            { label: 'ACG', value: 'ACG' },
                                            { label: 'Client', value: 'Customer' }
                                        ]}
                                        onChange={(e: any) => {
                                            handleChange(e);
                                            setFieldValue('radioValue', e.currentTarget.value);
                                            setUserType(e.currentTarget.value);
                                        }}
                                        getEvent={true}
                                        disabled={values?.roleCode !== "" ? true : false}
                                    />
                                </Grid>
                            </Grid>
                            {userType !== 'ACG' && (
                                <>
                                    <Label label="Client" className={'labelDiv'} />
                                    <DropDown
                                        onChange={(event) => {
                                            handleChange(event);
                                            const custName = clients?.filter(
                                                (item: any) => item.code == event.target.value
                                            );
                                            setClientName(custName?.[0]?.name);
                                        }}
                                        className="inputDiv"
                                        name="dropdownClient"
                                        disabled={state?.[STORE_KEYS.USER_DTL]?.IsACGUser === 'N'}
                                        items={clients}
                                        placeHolder="Select Client"
                                        value={values.dropdownClient}
                                        helperText={
                                            errors.dropdownClient && touched.dropdownClient ? errors.dropdownClient : ''
                                        }
                                    />
                                </>
                            )}
                            <Label label="Role Code" className={'labelDiv'} />
                            <TextInput
                                variant="outlined"
                                fullWidth
                                type="text"
                                name="roleCode"
                                value={values.roleCode}
                                autoComplete="off"
                                formInput="inputDiv"
                                onChange={(e: any) => {
                                    handleChange(e);
                                }}
                                disabled={isEdit}
                                onBlur={handleBlur}
                                placeholder="Enter Role Code"
                                helperText={errors.roleCode && touched.roleCode ? errors.roleCode : ''}
                            />
                            <Label label="Role Name" className={'labelDiv'} />
                            <TextInput
                                variant="outlined"
                                fullWidth
                                type="text"
                                name="roleName"
                                value={values.roleName}
                                autoComplete="off"
                                formInput="inputDiv"
                                onChange={(e: any) => {
                                    handleChange(e);
                                }}
                                onBlur={handleBlur}
                                placeholder="Enter Role Name"
                                helperText={errors.roleName && touched.roleName ? errors.roleName : ''}
                            />
                        </>
                        {renderButtons()}
                    </Form>
                );
            }}
        </Formik>
    );
};

export default CreateRole;
