import * as Yup from "yup";

export const VALIDATION_YUPSCHEMA = {
  dateFromSelect: Yup.date().required("Please select from date")
    .max(new Date(), 'To date cannot be in the future')
    .min(new Date(2021, 0, 1, 0, 0, 0), //For downtime, can select from 1 jan 2021
      'From date cannot be lesser than 1st Jan 2021'),
  dateToSelect: Yup.date().required("Please select to date")
    .max(new Date(), 'To date cannot be in the future')
    .when("dateFromSelect",
      (dateFromSelect, yup) => dateFromSelect && yup.min(dateFromSelect, "To date cannot be before start date"))
    .test(
      'date-range',
      'Date range selected should not be more than one month',
      function (value) {
        const fromDate = this.resolve(Yup.ref('dateFromSelect')) as Date;
        const toDate = value as Date;
        const diffInMs = toDate.getTime() - fromDate.getTime();
        const diffInDays = diffInMs / (1000 * 60 * 60 * 24);
        return diffInDays <= 31; // User can select upto 31 days range
      }),
  // dropdownMachineName: Yup.string().required("Please select machine"),
  // dropdownProduct: Yup.string().required("Please select Product"),
  // dropdownBatchNo: Yup.string().required("Please select Batch No"),
  // dropdownShiftName: Yup.string().required("Please select Shift"),
};