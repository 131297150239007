import { useState, useEffect } from 'react';
import * as React from 'react';
import CustomTable from '../../../atoms/CustomTable/index';
import CustomTableToolbar from '../../../atoms/CustomTableToolbar';
import { Container, Card } from '@mui/material';
// import "../index.scss";
// import { filter } from "lodash";
// import MapCustomTabs from "../../../atoms/ButtonTabs/MapCustomTabs";
// import FilterModal from "../../../atoms/filterModal";
import TemporaryDrawer from '../../../atoms/tempDrawer';
import MachineMonitoringDrawer from '../../../atoms/MachineMonitoringDrawer';
import { useDispatch } from 'react-redux';
import { executeACGAction, resetErr, updateScreenIdentifiers } from '../../../store/slice';
import { ACTION_CODES, STORE_KEYS } from '../../../constants/apiConstants';
import useGetState from '../../../utils/hooks/useGetState';
import { applySortFilter, fetchBlobImage } from '../../../utils/helper';
import SnackbarAlert from '../../../atoms/Snackbar';
import AlertBox from '../../../atoms/AlertBox';
import EditModel from '../EditModel/EditModel';
import RightDrawer from '../../../atoms/tempDrawer/indexAlt'
// import StatusCard from "../../../atoms/DashboardStatus/StatusCard";

type Anchor = 'right';
const options = {
    DEFAULT: {
        message: '',
        open: false,
        type: ''
    },
    RM_SUCCESS: {
        message: 'Model deleted successfully!',
        open: true,
        type: 'success'
    }
};
type Props = { getSummary?: any };
const Models = (props: Props) => {
    const { getSummary } = props;
    const [state, setState] = useState({
        right: false
    });
    const [page, setPage] = useState<number>(0);
    const [order, setOrder] = useState('desc');
    const [orderBy, setOrderBy] = useState('MachineModelName');
    const [selected, setSelected] = useState<any>([]);
    const [filterName, setFilterName] = useState<string>('');
    const [tableData, setTableData] = useState<any[]>([]);
    const [createDrawerOpen, setCreateDrawerOpen] = useState(false);
    const [editDrawerOpen, setEditDrawerOpen] = useState(false);
    const [selectedRow, setSelectedRow] = useState<any>([]);
    const [snackbarOptions, setSnackbarOptions] = useState(options.DEFAULT);
    const [removeBxOpen, setRemoveBxOpen] = useState(false);
    const [rmModel, setRmModel] = useState(false);
    const [isModalLoading,setIsModalLoading]=useState(false)
    const dispatch = useDispatch();
    const stateObj = useGetState();
    const closeRmBx = () => {
        setRemoveBxOpen(false);
    };

    const getModels = () => {
        setIsModalLoading(true)
        dispatch(
            executeACGAction({
                payload: {
                    requestType: 'GET',
                    urlPath: ACTION_CODES.GET_MODELS
                },
                uniqueScreenIdentifier: { modLoaded: true },
                storeKey: STORE_KEYS.MODELS
            })
        );
    };

    useEffect(() => {
        getModels();
    }, []);

    useEffect(() => {
        if (stateObj?.[STORE_KEYS.MODELS]?.modLoaded) {
            setTableData(stateObj?.[STORE_KEYS.MODELS]?.body?.data);
            dispatch(
                updateScreenIdentifiers({
                    storeKey: STORE_KEYS.MODELS,
                    uniqueScreenIdentifier: {
                        modLoaded: false
                    }
                })
            );
            setIsModalLoading(false)
        }
    }, [stateObj?.[STORE_KEYS.MODELS]?.modLoaded]);

    const filteredList = applySortFilter(tableData, order, filterName, orderBy);

    const handleChangePage = (event: any, newPage: React.SetStateAction<number>) => {
        setPage(newPage);
    };

    const toggleDrawer = (anchor: Anchor, open: boolean) => (event: React.KeyboardEvent | React.MouseEvent) => {
        if (
            event.type === 'keydown' &&
            ((event as React.KeyboardEvent).key === 'Tab' || (event as React.KeyboardEvent).key === 'Shift')
        ) {
            return;
        }
        setState({ ...state, [anchor]: open });
    };

    const handleFilterByName = (event: { target: { value: React.SetStateAction<string> } }) => {
        setPage(0);
        setOrderBy('MachineModelName');
        setFilterName(event.target.value);
    };

    const handleRoute = async (row: any) => {
        dispatch(
            executeACGAction({
                payload: {
                    requestType: 'GET',
                    urlPath: `${ACTION_CODES.GET_MODEL_PARMS}/${row?.MachineModelKey}`
                },
                uniqueScreenIdentifier: { parmsLoaded: true },
                storeKey: STORE_KEYS.MODEL_PARMS
            })
        );
        const link = await fetchBlobImage(row.MachineModelName + ".png");
        const updatedRow = { ...row, MachineModelImagePath: link.url };
        setSelectedRow(updatedRow);
    };
    const [modelKey, setModelKey] = useState<any>();

    const removeModel = (ele: any) => {
        setModelKey(ele?.MachineModelKey);
        setRemoveBxOpen(true);
    };

    useEffect(() => {
        if (rmModel) {
            closeRmBx();
            dispatch(
                executeACGAction({
                    payload: {
                        requestType: 'DELETE',
                        urlPath: `${ACTION_CODES.DELETE_MODEL}/${modelKey}`
                    },
                    uniqueScreenIdentifier: {
                        modelRemoved: true
                    },
                    storeKey: STORE_KEYS.DELETE_RPT
                })
            );
            setRmModel(false);
        }
    }, [rmModel]);

    useEffect(() => {
        if (stateObj?.[STORE_KEYS.DELETE_RPT]?.modelRemoved) {
            if (stateObj?.[STORE_KEYS.DELETE_RPT]?.statusCode === 200) {
                setSnackbarOptions(options.RM_SUCCESS);
                closeDrawer();
                getSummary();
                getModels();
            }
            dispatch(
                updateScreenIdentifiers({
                    storeKey: STORE_KEYS.DELETE_RPT,
                    uniqueScreenIdentifier: {
                        modelRemoved: false
                    }
                })
            );
        }
    }, [stateObj?.[STORE_KEYS.DELETE_RPT]?.modelRemoved]);

    useEffect(() => {
        if (stateObj?.[STORE_KEYS.MODEL_PARMS]?.parmsLoaded) {
            dispatch(
                updateScreenIdentifiers({
                    storeKey: STORE_KEYS.MODEL_PARMS,
                    uniqueScreenIdentifier: {
                        parmsLoaded: false
                    }
                })
            );
            setCreateDrawerOpen(true);
        }
    }, [stateObj?.[STORE_KEYS.MODEL_PARMS]?.parmsLoaded]);

    const closeDrawer = () => {
        setSelectedRow([]);
        setCreateDrawerOpen(false);
    };

    const resetInput = () => {
        setFilterName('');
    };

    const handleSnackbarError = (err: any) => {
        const errorMsg = err?.message || 'Internal Server error';
        const snackbarError = {
            message: errorMsg,
            type: 'reject',
            open: true
        };
        setSnackbarOptions(snackbarError);
    };

    useEffect(() => {
        if (stateObj?.err) {
            handleSnackbarError(stateObj?.err);
            dispatch(resetErr());
        }
    }, [stateObj?.err]);

    const openEditModelDrawer = () => {

        setEditDrawerOpen(true);
    }

    const handlerCloseEditDrawer = () => {

        console.log("Yes");
        setEditDrawerOpen(false);
    }

    const closeSnackbar = () => setSnackbarOptions(options.DEFAULT);

    return (
        <div>
            <SnackbarAlert options={snackbarOptions} handleClose={closeSnackbar} />
            <div>
                <Container
                    maxWidth="xl"
                    style={{
                        paddingLeft: '3px',
                        paddingRight: '3px'
                    }}
                >
                    <Card
                        style={{
                            borderRadius: '0px',
                            backgroundColor: '#2b2d42',
                            overflow: 'inherit'
                        }}
                    >
                        <CustomTableToolbar
                            drawerOpen={toggleDrawer('right', true)}
                            numSelected={selected?.length}
                            filterName={filterName}
                            onFilterName={handleFilterByName}
                            displayInput={true}
                            text={{
                                title: 'Total Models',
                                subTitle: `${filteredList?.length} Models`
                            }}
                            filterButton={false}
                            customButton={false}
                            resetInput={resetInput}
                        />
                    </Card>
                </Container>
                <CustomTable
                    isLoading={isModalLoading}
                    selectable={false}
                    headers={[]}
                    body={filteredList}
                    filteredUsers={filteredList}
                    filterName={''}
                    selected={[]}
                    order={''}
                    orderBy={''}
                    onRequestSort={() => { }}
                    onSelectAllClick={() => { }}
                    rowChange={() => { }}
                    rowCount={filteredList?.length}
                    onPageChange={handleChangePage}
                    page={page}
                    setPageNumber={setPage}
                    selectRow={() => { }}
                    route={handleRoute}
                    isRequestPage={true}
                    link={true}
                    type="modelList"
                    noDataText={'No Model data available'}
                    removeFn={removeModel}
                />
            </div>
            <AlertBox
                isOpen={removeBxOpen}
                handleClose={closeRmBx}
                leftBtnPress={closeRmBx}
                rightBtnPress={() => setRmModel(true)}
                modalHeader="Delete Model"
                modalContent="Are you sure you want to delete model?"
            />
            <MachineMonitoringDrawer
                handlerOpen={() => { }}
                handlerClose={closeDrawer}
                status={createDrawerOpen}
                data={selectedRow}
                type={'models'}
                deleteFn={() => removeModel(selectedRow)}
                editFn={openEditModelDrawer}
            />

            <RightDrawer
                handlerOpen={() => { }}
                handlerClose={handlerCloseEditDrawer}
                status={editDrawerOpen}
                drawerHeader={`Edit Model`}
                children={<EditModel
                    data={selectedRow}
                    setSnackbarOptions={setSnackbarOptions}
                    closeDrawer={handlerCloseEditDrawer}
                    closeDrawerViewDrawer={closeDrawer}
                />
                }
                type="model"
            />

        </div>
    );
};

export default Models;
