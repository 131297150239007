import React, { useState, useRef } from 'react';
import { Box, Typography, Paper, Avatar, IconButton, Snackbar } from '@mui/material';
import ThumbUpIcon from '@mui/icons-material/ThumbUp';
import ThumbDownIcon from '@mui/icons-material/ThumbDown';
import ContentCopyIcon from '@mui/icons-material/ContentCopy';
import Markdown from 'markdown-to-jsx'
import { PropagateLoader } from 'react-spinners';
import EmailAvatar from '../Avatar/avatar';
import Axios from 'axios';
// import BotImage from '../../../assets/images/ACG_user.png';
import BotImage from '../../../assets/images/userlogo.png';
import { BASEURL } from '../../constants/apiConstants';

const MessageBubble = ({ tab, email, message, isUser, showLoader, lastItem, itemIndex, data, selectedInsight, setDefaultData, feedback, images }: any) => {
  const divRef = useRef<any>(null);
  const [copySnackbar, setCopySnackbar] = useState<boolean>(false);
  const [snackMessage, setSnackMessage] = useState('');

  const handleLike = () => {
    const updatedData = [...data]
    if (updatedData[tab][selectedInsight][itemIndex]) {
      updatedData[tab][selectedInsight][itemIndex].feedback = 1;
    }
    setDefaultData(updatedData);
    const payload = {
      question: updatedData[tab][selectedInsight][itemIndex - 1] === undefined ? selectedInsight : updatedData[tab][selectedInsight][itemIndex - 1].text,
      answer: updatedData[tab][selectedInsight][itemIndex].text,
      feedback: 1
    }
    sendFeedback(payload);
  };

  const handleDislike = () => {
    const updatedData = [...data]
    if (updatedData[tab][selectedInsight][itemIndex]) {
      updatedData[tab][selectedInsight][itemIndex].feedback = 0;
    }
    setDefaultData(updatedData);
    const payload = {
      question: updatedData[tab][selectedInsight][itemIndex - 1] === undefined ? selectedInsight : updatedData[tab][selectedInsight][itemIndex - 1].text,
      answer: updatedData[tab][selectedInsight][itemIndex].text,
      feedback: 0
    }
    sendFeedback(payload);
  };

  const handleCopy = () => {
    setSnackMessage("Message copied!");
    navigator.clipboard.writeText(message);
    setCopySnackbar(true);
  };

  const handleCloseSnackbar = () => {
    setCopySnackbar(false);
  };

  const sendFeedback = async (payload: any) => {
    const formData = new FormData();
    formData.append("question", payload.question);
    formData.append("answer", payload.answer);
    formData.append("feedback", payload.feedback);
    await Axios({
      url: BASEURL + '/genaiapp/chat/feedback',
      method: 'post',
      data: formData,
      headers: { "Content-Type": "multipart/form-data" }
    })
      .then((response) => {
        if (response.status == 200 && response?.data) {
          if (payload.feedback === 1) {
            setSnackMessage("Message Liked!");
            setCopySnackbar(true);
          } else {
            setSnackMessage("Message Disliked!");
            setCopySnackbar(true);
          }
          return response?.data;
        }
      })
      .catch((error) => {
        if (window.newrelic) {
          window.newrelic.noticeError(error?.message);
        }
        return {
          status: error
        };
      });
  }

  return (
    <Box display="flex" flexDirection="column" alignItems={isUser ? 'flex-end' : 'flex-start'} mb={4}>
      <>
        <div className='userbottom'>
          {!isUser && <Avatar alt="User Avatar" src={BotImage} sx={{ mr: 1, backgroundColor: '#ffff' }} style={{ marginLeft: '0 !important' }} />}
          <Box display="flex" alignItems="center" justifyContent={isUser ? 'flex-end' : ""}>
            <Paper
              elevation={1}
              sx={{
                p: "10px 24px",
                maxWidth: "70%",
                minWidth: "300px",
                borderRadius: isUser ? '16px 16px 0 16px !important' : '16px 16px 16px 0 !important',
                backgroundColor: isUser ? '#edf4fd !important' : 'rgb(245 245 245) !important',
                color: "#000",
                border: "0.5px solid grey"
              }}
            >
              <Typography
                ref={divRef}
                variant="body2"
                className="messageImage"
                style={{overflow: "auto", color: "black",}}
              ><Markdown >{message}</Markdown></Typography>
              {images && images.length > 0 && images.map((i: any) => {
                const url = i.replace("http://172.16.123.78:7010", window.location.origin + '/api/genaiapp');
                return (
                  <img src={url} alt="image" style={{ width: "100%", height: "300px", marginBottom: "20px" ,  marginTop: "10px"}} />
                )
              })}
              {!isUser && <Box display="flex" mt={1} justifyContent={isUser ? 'flex-end' : 'flex-end'} maxWidth="100%">
                <Box display="flex" gap={1}>
                  <IconButton onClick={handleLike}
                    size="small"
                    aria-label="like"
                    className='bgBtns'
                    sx={{
                      color: feedback === 1 ? '#5d97f6' : '#ffff',
                    }}
                  >
                    <ThumbUpIcon fontSize="small" />
                  </IconButton>
                  <IconButton onClick={handleDislike}
                    size="small"
                    aria-label="dislike"
                    className='bgBtns'
                    sx={{
                      color: feedback === 0 ? '#5d97f6' : '#ffff',
                    }}
                  >
                    <ThumbDownIcon fontSize="small" />
                  </IconButton>
                  <IconButton onClick={handleCopy} size="small" sx={{
                    color: '#ffff',
                  }} aria-label="copy" className='bgBtns'>
                    <ContentCopyIcon fontSize="small" />
                  </IconButton>
                </Box>
              </Box>
              }
            </Paper>
          </Box>
          {isUser && <EmailAvatar email={email} />}
        </div>
      </>
      <Snackbar
        open={copySnackbar}
        autoHideDuration={2000}
        onClose={handleCloseSnackbar}
        message={snackMessage}
        anchorOrigin={{ vertical: 'top', horizontal: 'right' }}
      />
    </Box>
  );
}

export default MessageBubble;
