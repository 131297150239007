import React, { useState, useEffect } from "react";
type Props = {
    closeDrawer?: any;
    pdf?: any
}
const AddModel = (props: Props) => {
    const { closeDrawer, pdf } = props;
    return (
        <div>
            Hi
        </div>
    );
};

export default AddModel;
