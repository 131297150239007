import React, { useState, useEffect, ChangeEvent } from 'react'
import { useDispatch } from 'react-redux';
import { executeACGAction } from '../../store/slice';
import { ACTION_CODES, STORE_KEYS } from '../../constants/apiConstants';
import useGetState from '../../utils/hooks/useGetState';
import SnackbarAlert from '../Snackbar';
import { Box, Container, FilledInput, InputAdornment, TablePagination } from '@mui/material';
import { Card } from 'material-ui';
import CustomTableToolbar from '../CustomTableToolbar';
import AlertBox from '../AlertBox';
import CustomAlertsTable from '../CustomAlertsTable/CustomAlertsTable';
import styles from "./index.module.scss"
import { styled } from '@mui/material/styles';
import { Icon } from '@iconify/react';
import searchFill from '@iconify/icons-eva/search-fill';
import closeFill from '@iconify/icons-eva/close-fill';
import TablePaginationActions from '../CustomTable/TablePagination';
type ComponentsProps = {
  MachineKey: string | number,
  handleEdit: any,
  TabName: string,
  setTotalAlertCounts: any
}
const SearchStyle = styled(FilledInput)(({ theme }) => ({
  width: 320,
  transition: theme.transitions.create(['box-shadow', 'width'], {
    easing: theme.transitions.easing.easeInOut,
    duration: theme.transitions.duration.shorter
  }),
  borderRadius: 10,
  background: '#1d1e2c',
  height: 40,
  marginRight: 20,
  color: '#e0e0e0',
  fontFamily: "Montserrat",
  fontSize: 14,
  // position: 'absolute',
  // right: '1vw',
  '&.Mui-focused': { width: 320, background: '#1d1e2c', marginRight: 20, },
  '&:hover': { width: 320, background: '#1d1e2c', marginRight: 20, },
  '& fieldset': {
    borderWidth: '1px !important'
    // borderColor: `${theme.palette.grey[500_32]} !important`
  },
  [theme.breakpoints.down('xs')]: {
    right: '18vw',
    width: '250px'
  },
  [theme.breakpoints.down('sm')]: {
    right: '18vw',
    width: '250px'
  },
  [theme.breakpoints.up('md')]: {
    right: '13vw '
  },
  [theme.breakpoints.up('lg')]: {
    right: '0vw'
  }
}));
const options = {
  DEFAULT: {
    message: '',
    open: false,
    type: ''
  }
};

function ViewAlertDrawer(props: ComponentsProps) {
  const { MachineKey, handleEdit, TabName, setTotalAlertCounts } = props
  const dispatch = useDispatch()
  const stateVal = useGetState();
  const loggedInUser = stateVal[STORE_KEYS.USER_DTL]?.LoggedUserKey;
  const HeaderList = [
    { label: "Alert Name", key: "AlertName",icon:true },
    { label: "Param Name", key: "ParamName",icon:true },
    { label: "Severity Name", key: "SeverityName",icon:true },
    { label: "Condition Formula", key: "ConditionFormula",icon:true },
    { label: "Actions", key: "Actions",icon:false }
  ]
  const rowPerPage = 10

  const [alertsList, setAlertsList] = useState<any[]>([])
  const [tempAlertsList, setTempAlertsList] = useState<any[]>([])
  const [paginatedData, setPaginatedData] = useState<any[]>([])
  const [filterName, setFilterName] = useState<string>("")
  const [page, setPage] = useState<number>(0)
  const [loading, setLoading] = useState(true);
  const [isSubmit, setIsSubmit] = useState(false);

  const [snackbarOptions, setSnackbarOptions] = useState(options.DEFAULT);
  const closeSnackbar = () => setSnackbarOptions(options.DEFAULT);
  
  function sortAlerts(array: any, order: any,col:string) {

    if (order == 'asc') {
      return array.sort((a: any, b: any) => a[col].localeCompare(b[col]));
    }
    else if (order == 'desc') {
      return array.sort((a: any, b: any) => b[col].localeCompare(a[col]));
    }
    else {
      console.error('Invalid order parameter. Use "asc" or "desc".');
      return array;
    }
  }
  const handleSorting = (col: string, type: string) => {
      let unSortedArr=[...tempAlertsList]
      let newarr = sortAlerts(unSortedArr, type,col)
      setAlertsList(newarr)
  }

  const getAlertList = () => {
    setLoading(true)
    setAlertsList([])
    dispatch(
      executeACGAction({
        payload: {
          requestType: 'POST',
          urlPath: ACTION_CODES.GET_CUSTOM_EXISTING_ALERTS,
          reqObj: {
            "LoggedUserKey": loggedInUser,
            "MachineKey": MachineKey,
            "ParameterType": TabName
          }
        },
        storeKey: STORE_KEYS.CUSTOM_ALERTS_EXISTING_ALERTS
      })
    );
  };

  const resetInput = () => {
    setFilterName("")
  }

  const handleFilterName = (e: ChangeEvent<HTMLInputElement>) => {
    const value = e.target.value.toLowerCase();
    setFilterName(value);
  };

  const handlePageChange = (event: any, newPage: number) => {
    setPage(newPage);
  };
  const handleDelete = (AlertKey: any) => {
    dispatch(
      executeACGAction({
        payload: {
          requestType: 'POST',
          urlPath: ACTION_CODES.DELETE_CUSTOM_ALERT,
          reqObj: {
            "LoggedUserKey": loggedInUser,
            "MachineAlertKey": AlertKey.MachineCustomAlertKey
          }
        },
        storeKey: STORE_KEYS.CUSTOM_ALERTS_DELETE_SUCCESS
      })
    );
    setIsSubmit(true)
  }

  useEffect(() => {
    if (stateVal?.[STORE_KEYS.CUSTOM_ALERTS_DELETE_SUCCESS]?.body?.data?.[0]?.Result == "Success" && isSubmit) {
      const errorMsg = 'Successfully Deleted Custom Alert';
      const snackbarError = {
        message: errorMsg,
        type: 'success',
        open: true
      };
      setSnackbarOptions(snackbarError);
      setIsSubmit(false)
      getAlertList()
    }
    if (stateVal?.[STORE_KEYS.CUSTOM_ALERTS_DELETE_SUCCESS]?.body?.data?.[0]?.Result == "Error" && isSubmit) {

      const errorMsg = stateVal?.[STORE_KEYS.CUSTOM_ALERTS_DELETE_SUCCESS]?.body?.data?.[0]?.ResultMessage || 'Internal Server error';
      const snackbarError = {
        message: errorMsg,
        type: 'reject',
        open: true
      };
      setSnackbarOptions(snackbarError);
      setIsSubmit(false)
    }

  }, [stateVal?.[STORE_KEYS.CUSTOM_ALERTS_DELETE_SUCCESS]])

  useEffect(getAlertList, [])
  useEffect(() => {
    if (stateVal?.[STORE_KEYS.CUSTOM_ALERTS_EXISTING_ALERTS]?.body?.data) {
      setAlertsList(stateVal?.[STORE_KEYS.CUSTOM_ALERTS_EXISTING_ALERTS]?.body?.data);
      setTempAlertsList(stateVal?.[STORE_KEYS.CUSTOM_ALERTS_EXISTING_ALERTS]?.body?.data);
    }

  }, [stateVal?.[STORE_KEYS.CUSTOM_ALERTS_EXISTING_ALERTS]])

  useEffect(() => {
    if (filterName.length) {
      let newFilterArr = tempAlertsList.filter((item) => item.AlertName.toLowerCase().includes(filterName.toLowerCase()))
      setAlertsList(newFilterArr)
    } else {
      setAlertsList(tempAlertsList)
    }
    setPage(0)
  }, [filterName])

  useEffect(() => {
    const startIndex = page * rowPerPage;
    const endIndex = startIndex + rowPerPage;
    const paginatedData = alertsList.slice(startIndex, endIndex);
    setPaginatedData(paginatedData);
    setLoading(false)
  }, [page, alertsList]);

  useEffect(() => {
    setTotalAlertCounts(alertsList.length)
  }, [alertsList])

  return (
    <div className={styles.container}>
      <SnackbarAlert options={snackbarOptions} handleClose={closeSnackbar} />
      <div className={styles.header}>
        <SearchStyle
          // className="searchBox"
          className={
            styles.searchBox
          }
          value={filterName}
          onChange={handleFilterName}
          placeholder="Search"
          startAdornment={
            <div>
              <InputAdornment position="start" variant="outlined" className="searchBoxIcon">
                <Box component={Icon} icon={searchFill} sx={{ color: 'white' }} />
              </InputAdornment>
              {filterName?.length > 0 ? (
                <InputAdornment position="end" className={styles.searchBoxIconCancel} onClick={resetInput}>
                  <Box component={Icon} icon={closeFill} sx={{ color: 'white' }} />
                </InputAdornment>
              ) : null}
            </div>
          }
        />
      </div>
      <CustomAlertsTable handleDelete={handleDelete} handleSorting={handleSorting} loading={loading} HeaderList={HeaderList} rows={paginatedData} getAlertList={getAlertList} handleEdit={handleEdit} isAction={true} />
      <div className={styles.paginationBox}>
        <TablePaginationActions
          count={alertsList.length}
          page={page}
          rowsPerPage={rowPerPage}
          onPageChange={handlePageChange}
          setPageNumber={setPage}
        />
      </div>

    </div>
  )
}

export default ViewAlertDrawer