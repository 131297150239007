import Box from '@mui/material/Box';
import Stepper from '@mui/material/Stepper';
import Step from '@mui/material/Step';
import StepLabel from '@mui/material/StepLabel';
import './index.scss';

type ComponentProps = {
    steps?: any[];
    className?: string;
};

const AcgStepper = (props: ComponentProps) => {
    const {
        steps = [
            { label: 'User Information', active: true },
            { label: 'Assign Access', active: false }
        ],
        className
    } = props;
    const active = steps?.findIndex((step) => step.active);
    const stepperClass = ` ${className} acgStepper`;

    return (
        <Box sx={{ width: '100%' }} className={stepperClass}>
            <Stepper
                // activeStep={active + 1}
                alternativeLabel
            >
                {steps?.map((step, index) => (
                    <Step key={step?.label} {...props}>
                        <StepLabel
                            icon={index + 1}
                            StepIconProps={{
                                classes: {
                                    root: active === index ? 'iconClassActive' : 'iconClass'
                                    //completed: active === index ? 'iconClass' : 'iconClassActive',
                                    // active: active !== index ? 'iconClassActive' : 'iconClass'
                                }
                            }}
                        >
                            {step?.label}
                        </StepLabel>
                    </Step>
                ))}
            </Stepper>
        </Box>
    );
};

export default AcgStepper;
