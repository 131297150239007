import { Box } from '@mui/material';
import TextField from '@mui/material/TextField';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { TimePicker } from '@mui/x-date-pickers/TimePicker';
import { makeStyles } from '@material-ui/core';
type Props = {
    value?: any;
    handleChange?: any;
    label?: string;
    className?: string;
    minDate?: any;
};
const useStyles = makeStyles({
    root: {
        '& .MuiPopper-root-MuiPickersPopper-root': {
            zIndex: '9999',
            backgroundColor: '#1D1E2C'
        },
        '& .MuiInputBase-root': {
            color: '#c0c2d8',
            padding: '8.5px 14px',
            fontSize: '12px',
            width: '100%'
        },
        '& .MuiOutlinedInput-root': {
            backgroundColor: '#1D1E2C',
            borderRadius: '10px',
            borderWidth: '1px',
            borderColor: '#2B2D42',
            width: '100%'
        },
        '& .MuiButtonBase-root': {
            color: '#c0c2d8'
        },
        '&. MuiPickersPopper-paper': {
            backgroundColor: '#1D1E2C'
        }
    }
});
const AcgTimePicker = (props: Props) => {
    const { value, handleChange = () => { }, label, className } = props;
    const classes = useStyles();
    return (
        <Box className={`${classes.root} ${className}`}>
            <LocalizationProvider dateAdapter={AdapterDayjs}>
                <TimePicker
                    label={label ? label : ''}
                    openTo="hours"
                    views={['hours', 'minutes', 'seconds']}
                    value={value}
                    onChange={handleChange}
                    renderInput={(params: any) => <TextField {...params} style={{ width: '100%' }} />}
                />
            </LocalizationProvider>
        </Box>
    );
};

export default AcgTimePicker;
