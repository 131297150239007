import React, { useState, useEffect } from 'react';
import { useDispatch } from 'react-redux';
import { Container, Card } from '@mui/material';
import CustomTable from '../../../atoms/CustomTable/index';
import CustomTableToolbar from '../../../atoms/CustomTableToolbar';
import TemporaryDrawer from '../../../atoms/tempDrawer';
import { ACTION_CODES, STORE_KEYS } from '../../../constants/apiConstants';
import { executeACGAction, resetErr, updateScreenIdentifiers } from '../../../store/slice';
import useGetState from '../../../utils/hooks/useGetState';
import { applySortFilter } from '../../../utils/helper';

const TABLE_HEAD = [
    { id: 'UserName', label: 'User Name', alignRight: false },
    { id: 'EmailId', label: 'Email ID', alignRight: false },
    { id: 'PhoneNo', label: 'Phone Number', alignRight: false },
    { id: 'Activity', label: 'Activity', alignRight: false },
    { id: 'ActivityTime', label: 'Activity Time', alignRight: false }
];

type Anchor = 'right';

type Props = { dtlProps: any };

const Audit = (props: Props) => {
    const { dtlProps } = props;
    const dispatch = useDispatch();
    const [drawerState, setDrawerState] = useState({
        right: false
    });
    const [page, setPage] = useState<number>(0);
    const [order, setOrder] = useState('desc');
    const [orderBy, setOrderBy] = useState('ActivityTime');
    const [selected, setSelected] = useState<any>([]);
    const [filterName, setFilterName] = useState<string>('');
    const [tableData, setTableData] = useState<any[]>([]);
    const [createDrawerOpen, setCreateDrawerOpen] = useState(false);
    const [dtlDrawerOpen, setDtlDrawerOpen] = useState(false);
    const [selectedRow, setSelectedRow] = useState<any>([]);
    const state = useGetState();
    const getClientList = () => {
        dispatch(
            executeACGAction({
                payload: {
                    requestType: 'GET',
                    urlPath: ACTION_CODES.GET_AUDIT
                },
                storeKey: STORE_KEYS.AUDIT_LIST
            })
        );
    };
    useEffect(getClientList, []);

    useEffect(() => {
        const tmpTableData = state[STORE_KEYS.AUDIT_LIST]?.body?.data;
        tmpTableData &&
            setTableData(
                tmpTableData.map((item: any) => ({
                    ...item
                    // UserName: fDate(item.CustomerEndDate, 'MM dd, yyyy')
                }))
            );
    }, [state[STORE_KEYS.AUDIT_LIST]?.body?.data]);

    const handleRequestSort = (event: any, property: any) => {
        setFilterName('');
        const isAsc = orderBy === event && order === 'asc';
        setOrder(isAsc ? 'desc' : 'asc');
        setOrderBy(event);
    };

    const handleSelectAllClick = (event: any) => {
        if (event.target.checked) {
            const newSelecteds = [];
            for (let i = 0; i < tableData.length; i++) {
                newSelecteds.push(tableData[i].id);
            }
            const toRemove = tableData.slice(page, tableData.length);
            setSelected(newSelecteds);
            return;
        }
        setSelected([]);
    };

    const handleClick = (requestedBy: string) => {
        const selectedIndex = selected.indexOf(requestedBy);
        let newSelected: any[] | ((prevState: unknown[]) => unknown[]) = [];
        if (selectedIndex === -1) {
            newSelected = newSelected.concat(selected, requestedBy);
        } else if (selectedIndex === 0) {
            newSelected = newSelected.concat(selected.slice(1));
        } else if (selectedIndex === selected.length - 1) {
            newSelected = newSelected.concat(selected.slice(0, -1));
        } else if (selectedIndex > 0) {
            newSelected = newSelected.concat(selected.slice(0, selectedIndex), selected.slice(selectedIndex + 1));
        }
        setSelected(newSelected);
    };

    const toggleDrawer = (anchor: Anchor, open: boolean) => (event: React.KeyboardEvent | React.MouseEvent) => {
        if (
            event.type === 'keydown' &&
            ((event as React.KeyboardEvent).key === 'Tab' || (event as React.KeyboardEvent).key === 'Shift')
        ) {
            return;
        }
        setDrawerState({ ...drawerState, [anchor]: open });
    };

    const filteredList = applySortFilter(tableData, order, filterName, orderBy);

    const handleFilterByName = (event: { target: { value: string } }) => {
        setOrderBy('UserName');
        setFilterName(event.target.value);
    };

    const handleChangePage = (event: any, newPage: React.SetStateAction<number>) => {
        setPage(newPage);
    };

    const showRow = (event: any) => {};

    const handleRoute = (row: any, ele: any) => {
        if (ele === 'Action') {
            dispatch(
                executeACGAction({
                    payload: {
                        requestType: 'GET',
                        urlPath: `${ACTION_CODES.GET_CLNTFEATURES}/${row?.CustomerKey}`
                    },
                    uniqueScreenIdentifier: { cltFtrsRecd: true },
                    storeKey: STORE_KEYS.CLIENT_FTRS
                })
            );
            dtlProps?.setDtlData({ ...row, name: row?.CustomerName });
        }
    };
    useEffect(() => {
        if (state?.[STORE_KEYS.CLIENT_FTRS]?.cltFtrsRecd) {
            dtlProps?.setDtlData({ ...dtlProps?.dtlData, features: state?.[STORE_KEYS.CLIENT_FTRS]?.body?.data });
            setDtlDrawerOpen(true);
            dispatch(
                updateScreenIdentifiers({
                    storeKey: STORE_KEYS.CLIENT_FTRS,
                    uniqueScreenIdentifier: {
                        cltFtrsRecd: false
                    }
                })
            );
        }
    }, [state?.[STORE_KEYS.CLIENT_FTRS]?.cltFtrsRecd]);
    const [removeBxOpen, setRemoveBxOpen] = useState(false);
    const [rmClnt, setRmClnt] = useState(false);
    const [clntKey, setClntKey] = useState<any>();
    const closeRmBx = () => {
        setRemoveBxOpen(false);
    };
    const removeFn = (ele: any) => {
        setClntKey(ele?.CustomerKey);
        setRemoveBxOpen(true);
    };

    const closeDtlDrawer = () => {
        setDtlDrawerOpen(false);
    };
    const resetInput = () => {
        setFilterName('');
    };
    return (
        <div>
            <div className='audittraiTable'>
                <Container
                    maxWidth="xl"
                    style={{
                        paddingLeft: '3px',
                        paddingRight: '3px'
                    }}
                >
                    <Card
                        style={{
                            borderRadius: '0px',
                            backgroundColor: '#2b2d42',
                            overflow: 'inherit'
                        }}
                    >
                        <CustomTableToolbar
                            drawerOpen={toggleDrawer('right', true)}
                            numSelected={selected?.length}
                            filterName={filterName}
                            onFilterName={handleFilterByName}
                            resetInput={resetInput}
                            displayInput={true}
                            text={{
                                title: 'Audit Trail',
                                subTitle: `${tableData?.length} Audits`
                            }}
                            filterButton={false}
                            customButton={false}
                        />
                    </Card>
                </Container>
                <CustomTable
                    selectable={false}
                    headers={TABLE_HEAD}
                    body={filteredList}
                    isLoading={state?.isLoading}
                    filteredUsers={filteredList}
                    filterName={filterName}
                    selected={selected}
                    order={order}
                    orderBy={orderBy}
                    onRequestSort={handleRequestSort}
                    onSelectAllClick={handleSelectAllClick}
                    rowChange={(event: string) => handleClick(event)}
                    rowCount={filteredList?.length}
                    onPageChange={handleChangePage}
                    page={page}
                    setPageNumber={setPage}
                    selectRow={showRow}
                    route={handleRoute}
                    isRequestPage={true}
                    link={true}
                    removeFn={removeFn}
                />
            </div>
        </div>
    );
};

export default Audit;
