import { useState, useEffect } from 'react';
import {
  LineChart,
  Line,
  XAxis,
  YAxis,
  CartesianGrid,
  Tooltip,
  Legend,
  Scatter,
  ResponsiveContainer,
  ReferenceArea,
  Area
} from "recharts";
import { executeACGAction, updateScreenIdentifiers } from '../../../../store/slice';
import { batch, useDispatch } from 'react-redux';
import useGetState from '../../../../utils/hooks/useGetState';
import { ACTION_CODES, STORE_KEYS, BASEURL } from '../../../../constants/apiConstants';
import dateFormat from 'dateformat';
import moment from 'moment';
const Dateformat = {
  YYYY_MM_DD: 'yyyy-mm-dd',
  YYYY_MM_DD_HH_MM_SS: 'yyyy-mm-dd hh:mm:ss',
  YYYY_MM_DD_HH_MM_SS_L: 'yyyy-mm-ddTHH:MM:ss.l',
}

export default function Graph(props: any) {
  const state = useGetState();
  const bottomRange = props.vSliderB;
  const dispatch = useDispatch();
  const [selectedTime, setSelectedTime] = useState('');

  const CustomTooltip = ({ active, payload, label }: any) => {
    const [hover, setHover] = useState(false);
    if (active && payload && payload.length) {
      setSelectedTime(payload[0]?.payload?.date);
      const tooltipStyle = {
        background: "#1d1e2c",
        color: "#ffffff",
        padding: "10px",
        cursor: "pointer",
        borderRadius: '8px',
        margin: hover ? '20px' : '10px',  // Increase margin on hover
        transition: 'margin 0.3s ease-in-out',  // Smooth transition for the margin change
      };
      return (
        <div style={tooltipStyle}
        onMouseEnter={() => setHover(true)}  // Set hover state to true
        onMouseLeave={() => setHover(false)} // Set hover state to false
      >
          <p className="label mtbP">Event Date : {payload[0]?.payload?.date}</p>
          <p className="label mtbP">Actual Value : {payload[0]?.payload?.actualValue ? Number(payload[0]?.payload?.actualValue).toFixed(2) : 0}</p>
          <p className="label mtbP">Upper Limit : {payload[0]?.payload?.upperLimit ? Number(payload[0]?.payload?.upperLimit).toFixed(2) : 0}</p>
          <p className="label mtbP">Lower Limit : {payload[0]?.payload?.lowerLimit ? Number(payload[0]?.payload?.lowerLimit).toFixed(2) : 0}</p>
          <p className="label mtbP">Phase : {payload[0]?.payload?.phaseName}</p>
        </div>
      );
    }

    return null;
  };

  const fetchAlertsAndAlarms = (val: any) => {
    const formattedDate = moment(val, 'DD/MM/YYYY HH:mm:ss').format('YYYY-MM-DD HH:mm:ss');
    dispatch(
      executeACGAction({
        payload: {
          requestType: 'POST',
          urlPath: ACTION_CODES.GET_GB_ALARMS_DATA,
          reqObj: {
            "EventTime": formattedDate,
            "MachineKey": props.selectedSerial
          }
        },
        uniqueScreenIdentifier: { gbAlarms: true },
        storeKey: STORE_KEYS.GET_GB_ALARMS_DATA
      })
    );

    dispatch(
      executeACGAction({
        payload: {
          requestType: 'POST',
          urlPath: ACTION_CODES.GET_GB_ALERTS_DATA,
          reqObj: {
            "EventTime": formattedDate,
            "MachineKey": props.selectedSerial
          }
        },
        uniqueScreenIdentifier: { gbAlerts: true },
        storeKey: STORE_KEYS.GET_GB_ALERTS_DATA
      })
    );
  }

  useEffect(() => {
    if (state?.[STORE_KEYS.GET_GB_ALERTS_DATA]?.gbAlerts) {
      const rptsData = state?.[STORE_KEYS.GET_GB_ALERTS_DATA]?.body?.data;
      props.setAlertsData(rptsData);

      dispatch(
        updateScreenIdentifiers({
          storeKey: STORE_KEYS.GET_GB_ALERTS_DATA,
          uniqueScreenIdentifier: {
            gbAlerts: false
          }
        })
      );
    }
  }, [state?.[STORE_KEYS.GET_GB_ALERTS_DATA]?.gbAlerts]);

  useEffect(() => {
    if (state?.[STORE_KEYS.GET_GB_ALARMS_DATA]?.gbAlarms) {
      const rptsData = state?.[STORE_KEYS.GET_GB_ALARMS_DATA]?.body?.data;
      props.setAlarmsData(rptsData);

      dispatch(
        updateScreenIdentifiers({
          storeKey: STORE_KEYS.GET_GB_ALARMS_DATA,
          uniqueScreenIdentifier: {
            gbAlarms: false
          }
        })
      );
    }
  }, [state?.[STORE_KEYS.GET_GB_ALARMS_DATA]?.gbAlarms]);

  const CustomizedDot = (props: any) => {
    const { cx, cy, value } = props;
    if (value != "") {
      return (
        <svg
          x={cx - 0}
          y={cy - 0}
          width={10}
          height={10}
          fill={props?.payload?.isAlarm ? "#ffff00" : "#ffffff"}
          viewBox="0 0 1024 1024"
          style={{ cursor: "pointer", zIndex: 999 }}
          onClick={() => fetchAlertsAndAlarms(props?.payload?.date)}
        >
          <path d="M512,1009.984 c-274.912,0,-497.76,-222.848,-497.76,-497.76 s222.848,-497.76,497.76,-497.76 c274.912,0,497.76,222.848,497.76,497.76 s-222.848,497.76,-497.76,497.76 M340.768,295.936" />
        </svg>
      )
    } else {
      return <></>
    }
  }

  const RenderLegend = (props: any) => {
    const { payload, customProp1, customProp2 } = props;

    return (
      <div style={{ display: "inline-flex", width: "100%", justifyContent: "center", marginBottom: "0px", position: "relative", bottom: "10px" }}>
        {
          payload.map((entry: any, index: number) => {
            if (entry.value !== "alarmAndAlerts")
              return (
                <div style={{ display: "inline-flex", alignItems: "center", justifyContent: "center" }}>
                  <div style={{ width: "8px", height: "8px", borderRadius: "4px", background: entry?.color, marginRight: "3px" }}></div>
                  <div>
                    <label style={{ color: "#ffff", fontSize: "9px", marginRight: "6px", textTransform: "capitalize" }} key={`item-${index}`}>{(entry.value || '').replace(/([A-Z])/g, ' $1').trim()}</label>
                  </div>
                </div>
              )
          })
        }
        {
          customProp1?.sort((a: any, b: any) => a.key - b.key)?.map((item: any, index: number) => {
            let color = customProp2.find((i: any) => i.key === item.key)?.color;
            let name = customProp2.find((i: any) => i.key === item.key)?.name;
            return (
              <div style={{ display: "inline-flex", alignItems: "center", justifyContent: "center" }}>
                <div style={{ width: "8px", height: "8px", borderRadius: "4px", background: color, marginRight: "3px" }}></div>
                <div>
                  <label style={{ color: "#ffff", fontSize: "9px", marginRight: "6px", textTransform: "capitalize" }} key={`item-${index}`}>{(name || '').replace(/([A-Z])/g, ' $1').trim()}</label>
                </div>
              </div>
            )
          })
        }
      </div>
    );
  }

  let cumulativeSum = 0, cumulativeSumPrev = 0;
  return (
    <ResponsiveContainer height={245} width="100%" className="gblegends">
      {
        props.status === "Active" ?
        <LineChart
        height={245}
        data={state?.[STORE_KEYS.GOLDEN_BATCH_DATA_ACTIVE_FBE]?.active?.slice(props.hSliderActiveT[0], props.hSliderActiveT[1])}
        margin={{
          top: 0,
          right: 10,
          left: 20,
          bottom: 0
        }}
        width={((props?.appliedFilters && !props.rightSlider)) ? 1000 : 1250}
      >
        <CartesianGrid strokeDasharray="0 0" stroke="grey" />
        <XAxis dataKey="date"
          style={{
            fontSize: '10px',
            color: "#ffffff !important"
          }}
          tick={{ fill: 'white' }}
          xAxisId="0"
        />
        <XAxis
          xAxisId="1"
          dataKey="phase"
          allowDuplicatedCategory={true}
          tick={false}
          axisLine={false}
          orientation="top"
        />

        {
          props.uniquePhaseListUnsorted.sort((a:any, b:any) => b.index - a.index)?.map((item: any, index: number) => {
            cumulativeSumPrev += props.uniquePhaseListUnsorted.sort((a:any, b:any) => b.index - a.index)[index - 1]?.count ? props.uniquePhaseListUnsorted.sort((a:any, b:any) => b.index - a.index)[index - 1]?.count : 0;
            cumulativeSum += item?.count;
            let pos1 = index === 0 ? 0 : cumulativeSumPrev;
            let pos2 = cumulativeSum;
            let color = props.phaseList.find((i: any) => i.key === item.key);
            return (
              <ReferenceArea
                x1={pos1}
                x2={index + 1 === props.uniquePhaseListUnsorted.length ? pos2 - 1 : pos2}
                strokeOpacity={0.5}
                fill={color?.color}
                xAxisId="1"
                fillOpacity={0.3}
              />
            )
          })
        }

        <YAxis tick={{ fill: 'white' }} domain={[Math.round(props.vSliderB - 15), Math.round(props.vSliderT + 15)]} />
        <YAxis yAxisId="right" orientation="right" domain={[0, 10]} tick={{ fill: 'white' }} />
        <Tooltip
          trigger={"hover"}
          content={<CustomTooltip />}
          offset={0}
          coordinate={{ x: 0, y: 0 }}
        />
        <Legend content={<RenderLegend customProp1={props.uniquePhaseListUnsorted} customProp2={props.phaseList} />} layout="horizontal" verticalAlign="bottom" align="center" className="legendGB" />
        <Line
          type="monotone"
          dataKey="lowerLimit"
          stroke="#ff6347"
          dot={false}
          strokeWidth={2}
          style={{ cursor: "pointer" }}
          activeDot={true}
        // onClick={fetchAlertsAndAlarms}
        />
        <Line type="monotone" dataKey="actualValue" stroke="#b03d55" strokeWidth={2} dot={false}

        />
        <Line type="monotone" dataKey="alarmAndAlerts" stroke="#FF4747" strokeWidth={0} dot={<CustomizedDot />}
        />
        <Line type="monotone" dataKey="upperLimit" stroke="#4121f6" strokeWidth={2} dot={false}
        />
        <Line yAxisId="right" type="monotone" dataKey="machineRunStatus" stroke="#198754" strokeWidth={2}
          dot={false} />
      </LineChart>
        :
        <LineChart
        height={245}
        data={props.appliedFilters ? state?.[STORE_KEYS.GOLDEN_BATCH_DATA_FBE]?.data?.slice(props.hSliderT[0], props.hSliderT[1]) : null}
        margin={{
          top: 0,
          right: 10,
          left: 20,
          bottom: 0
        }}
        width={((props?.appliedFilters && !props.rightSlider)) ? 1000 : 1250}
      >
        <CartesianGrid strokeDasharray="0 0" stroke="grey" />
        <XAxis dataKey="date"
          style={{
            fontSize: '10px',
            color: "#ffffff !important"
          }}
          tick={{ fill: 'white' }}
          xAxisId="0"
        />
        <XAxis
          xAxisId="1"
          dataKey="phase"
          allowDuplicatedCategory={true}
          tick={false}
          axisLine={false}
          orientation="top"
        />

        {
          props.uniquePhaseListUnsorted.sort((a:any, b:any) => b.index - a.index)?.map((item: any, index: number) => {
            cumulativeSumPrev += props.uniquePhaseListUnsorted.sort((a:any, b:any) => b.index - a.index)[index - 1]?.count ? props.uniquePhaseListUnsorted.sort((a:any, b:any) => b.index - a.index)[index - 1]?.count : 0;
            cumulativeSum += item?.count;
            let pos1 = index === 0 ? 0 : cumulativeSumPrev;
            let pos2 = cumulativeSum;
            let color = props.phaseList.find((i: any) => i.key === item.key);
            return (
              <ReferenceArea
                x1={pos1}
                x2={index + 1 === props.uniquePhaseListUnsorted.length ? pos2 - 1 : pos2}
                strokeOpacity={0.5}
                fill={color?.color}
                xAxisId="1"
                fillOpacity={0.3}
              />
            )
          })
        }

        <YAxis tick={{ fill: 'white' }} domain={[Math.round(props.vSliderB - 15), Math.round(props.vSliderT + 15)]} />
        <YAxis yAxisId="right" orientation="right" domain={[0, 10]} tick={{ fill: 'white' }} />
        <Tooltip
          trigger={"hover"}
          content={<CustomTooltip />}
          offset={0}
          coordinate={{ x: 0, y: 0 }}
        />
        <Legend content={<RenderLegend customProp1={props.uniquePhaseListUnsorted} customProp2={props.phaseList} />} layout="horizontal" verticalAlign="bottom" align="center" className="legendGB" />
        <Line
          type="monotone"
          dataKey="lowerLimit"
          stroke="#ff6347"
          dot={false}
          strokeWidth={2}
          style={{ cursor: "pointer" }}
          activeDot={true}
        // onClick={fetchAlertsAndAlarms}
        />
        <Line type="monotone" dataKey="actualValue" stroke="#b03d55" strokeWidth={2} dot={false}

        />
        <Line type="monotone" dataKey="alarmAndAlerts" stroke="#FF4747" strokeWidth={0} dot={<CustomizedDot />}
        />
        <Line type="monotone" dataKey="upperLimit" stroke="#82c8c8" strokeWidth={2} dot={false}
        />
        <Line yAxisId="right" type="monotone" dataKey="machineRunStatus" stroke="#198754" strokeWidth={2}
          dot={false} />
      </LineChart>
      }
     
    </ResponsiveContainer>
  );
}