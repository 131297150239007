import * as React from 'react';
import { useState, useEffect } from 'react';
import CustomTable from '../../../atoms/CustomTable/index';
import CustomTableToolbar from '../../../atoms/CustomTableToolbar';
import { Container, Card, Box } from '@mui/material';
import { applySortFilter, fetchBlobImage } from '../../../utils/helper';
import TemporaryDrawer from '../../../atoms/tempDrawer';
import MachineMonitoringDrawer from '../../../atoms/MachineMonitoringDrawer';
import { useDispatch } from 'react-redux';
import { executeACGAction, resetErr, resetStoreKey, updateScreenIdentifiers } from '../../../store/slice';
import { ACTION_CODES, STORE_KEYS } from '../../../constants/apiConstants';
import useGetState from '../../../utils/hooks/useGetState';
import SnackbarAlert from '../../../atoms/Snackbar';
import AlertBox from '../../../atoms/AlertBox';
import FilterDrawer from '../../../atoms/FilterDrawer';
import DropDown from '../../../atoms/Dropdown';
import Label from '../../../atoms/Label/index';
import { renameObjKeys } from '../../Login/helper';
import EditMachine from '../EditMachine/EditMachine';
import RightDrawer from '../../../atoms/tempDrawer/indexAlt';
import CustomAlertsTable from '../../../atoms/CustomAlertsTable/CustomAlertsTable';
import TablePaginationActions from '../../../atoms/CustomTable/TablePagination';
type Props = { getSummary?: any, handleEdit: any, getCreateValue?: any, setPdfData?: any, toogleSopList: any };
const options = {
  DEFAULT: {
    message: '',
    open: false,
    type: ''
  },
};
import PictureAsPdfIcon from '@mui/icons-material/PictureAsPdf';
import FilterBox from './FilterBox/FilterBox';
const Machines = (props: Props) => {
  const { handleEdit, getCreateValue, setPdfData, toogleSopList } = props
  const dispatch = useDispatch()
  const stateVal = useGetState();
  const HeaderList = [
    { key: 'MachineModelName', label: 'Machine Model Name', alignRight: false, icon: true },
    { key: 'MaintComponentName', label: 'Machine Component Name', alignRight: false, icon: true },
    { key: 'SOPName', label: 'SOP Name', alignRight: false, icon: true },
    { key: 'SOPType', label: 'SOP Type', alignRight: false, icon: true },
    { key: 'SOPFilePath', label: 'SOP File', alignRight: false, icon: false },
    { key: 'SOPUploadDateTime', label: 'Upload Date Time', alignRight: false, icon: true },
    { key: 'Action', label: 'Action', alignLeft: true, icon: false }
  ]
  const rowPerPage = 10

  const [sopList, setSopList] = useState<any[]>([])
  const [tempSopList, setTempSopList] = useState<any[]>([])
  const [paginatedData, setPaginatedData] = useState<any[]>([])
  const [filterName, setFilterName] = useState<string>("")
  const [page, setPage] = useState<number>(0)
  const [loading, setLoading] = useState(false);
  const [snackbarOptions, setSnackbarOptions] = useState(options.DEFAULT);
  const [deleteModal, setDeleteModal] = useState<{ status: boolean, values: any }>({ status: false, values: null });
  const [filterOpen, setFilterOpen] = useState(false);
  const [values, setValues] = useState<{
    model: string,
    component: string,
    type: string,
    startDate: Date,
    endDate: Date
  }>({
    model: "",
    component: "",
    type: "",
    startDate: new Date(new Date().setMonth(new Date().getMonth() - 3)),
    endDate: new Date(),
  })

  const getLoggedUserId = stateVal[STORE_KEYS.USER_DTL]?.LoggedUserKey;

  const handleDeleteModal = () => {
    setDeleteModal((prev) => ({ ...prev, status: !deleteModal.status }))
  }

  function sortAlerts(array: any, order: any, col: string) {
    if (order == 'asc') {
      return array.sort((a: any, b: any) => a[col].localeCompare(b[col]));
    }
    else if (order == 'desc') {
      return array.sort((a: any, b: any) => b[col].localeCompare(a[col]));
    }
    else {
      console.error('Invalid order parameter. Use "asc" or "desc".');
      return array;
    }
  }
  const handleSorting = (col: string, type: string) => {
    let unSortedArr = [...tempSopList]
    let newarr = sortAlerts(unSortedArr, type, col)

    setSopList(newarr)
  }

  const resetInput = () => {
    setFilterName("")
  }
  const applyFilter = () => {
    const ModelName = stateVal[STORE_KEYS.SOP_MACHINE_MODEL_LIST]?.body?.data.filter((item: any) => item.MachineModelKey == values.model);
    const ComponentName = stateVal[STORE_KEYS.SOP_MACHINE_COM_LIST]?.body?.data.filter((item: any) => item.MaintComponentKey == values.component);
    const SopType = stateVal[STORE_KEYS.SOP_TYPE_LIST]?.body?.data.filter((item: any) => item.SOPTypeKey == values.type);
    const baseFilter = (item: any) =>
      new Date(item.SOPUploadDateTime).setHours(0, 0, 0, 0) >= new Date(values.startDate).setHours(0, 0, 0, 0) &&
      new Date(item.SOPUploadDateTime).setHours(0, 0, 0, 0) <= new Date(values.endDate).setHours(23, 59, 59, 999);
    let newFilterArr = tempSopList.filter((item) => {
      const modelFilter = !values.model || item.MachineModelName === ModelName[0].MachineModelName;
      const componentFilter = !values.component || item.MaintComponentName === ComponentName[0].MaintComponentName;
      const typeFilter = !values.type || item.SOPType === SopType[0].SOPType;
      return baseFilter(item) && modelFilter && componentFilter && typeFilter;
    });

    setSopList(newFilterArr);
    setFilterOpen(false);
  };
  const resetFilter = () => {
    setSopList(tempSopList)
    setValues({ model: "", component: "", type: "", startDate: new Date(new Date().setMonth(new Date().getMonth() - 3)), endDate: new Date() })
    setFilterOpen(false)

  }

  const handleFilterName = (e: React.ChangeEvent<HTMLInputElement>) => {
    const value = e.target.value.toLocaleLowerCase();
    setFilterName(value);
  };

  const handlePageChange = (event: any, newPage: number) => {
    setPage(newPage);
  };

  const closeSnackbar = () => { setSnackbarOptions(options.DEFAULT) };
  const handleSnackbarError = (err: any) => {
    const errorMsg = err?.message || 'Internal Server error';
    const snackbarError = {
      message: errorMsg,
      type: 'reject',
      open: true
    };
    setSnackbarOptions(snackbarError);
  };

  const getModels = () => {
    setLoading(true)
    dispatch(
      executeACGAction({
        payload: {
          requestType: 'GET',
          urlPath: ACTION_CODES.GET_EXISTING_SOP_LIST
        },
        uniqueScreenIdentifier: { modLoaded: true },
        storeKey: STORE_KEYS.EXISTING_SOP_LIST
      })
    );
  };
  const deleteSop = () => {
    dispatch(
      executeACGAction({
        payload: {
          requestType: 'POST',
          urlPath: ACTION_CODES.DELETE_SOP,
          reqObj: {
            LoggedUserKey: getLoggedUserId,
            SOPDetailsKey: Number(deleteModal.values.id),
            fileName: deleteModal.values.fileName
          }
        },
        uniqueScreenIdentifier: { modLoaded: true },
        storeKey: STORE_KEYS.DELETE_SOP_RESPONSE
      })
    );
  };
  const handlePdfClick = (fileName: string) => {
    const blobpdf = fetchBlobImage(fileName)
    window.open(blobpdf.url, "_blank")
  }

  useEffect(() => {
    getModels();
  }, [toogleSopList]);

  useEffect(() => {
    if (stateVal?.[STORE_KEYS.EXISTING_SOP_LIST]?.body?.data) {
      setSopList(stateVal?.[STORE_KEYS.EXISTING_SOP_LIST]?.body?.data.length === 0 ? [] : stateVal?.[STORE_KEYS.EXISTING_SOP_LIST]?.body?.data);
      setTempSopList(stateVal?.[STORE_KEYS.EXISTING_SOP_LIST]?.body?.data);
    }

  }, [stateVal?.[STORE_KEYS.EXISTING_SOP_LIST]]);

  useEffect(() => {
    if (stateVal?.[STORE_KEYS.DELETE_SOP_RESPONSE]?.body?.data?.[0]?.Result == "Success" && deleteModal.values) {
      getModels();
      const errorMsg = 'SOP deleted successfully';
      const snackbarError = {
        message: errorMsg,
        type: 'success',
        open: true
      };
      setSnackbarOptions(snackbarError);
      setDeleteModal({ status: false, values: null })
    }
    if (stateVal?.[STORE_KEYS.DELETE_SOP_RESPONSE]?.body?.data?.[0]?.Result == "Error" && deleteModal.values) {
      const errorMsg = stateVal?.[STORE_KEYS.DELETE_SOP_RESPONSE]?.body?.data?.[0]?.ResultMessage || 'Internal Server error';
      const snackbarError = {
        message: errorMsg,
        type: 'reject',
        open: true
      };
      setSnackbarOptions(snackbarError);
      setDeleteModal({ status: false, values: null })
    }

  }, [stateVal?.[STORE_KEYS.DELETE_SOP_RESPONSE]])
  useEffect(() => {
    if (filterName.length) {
      let newFilterArr = tempSopList.filter((item) => item.SOPName.toLowerCase().includes(filterName.toLowerCase()) || item.MaintComponentName.toLowerCase().includes(filterName.toLowerCase()) || item.SOPFilePath.toLowerCase().includes(filterName.toLowerCase()))
      setSopList(newFilterArr)
    } else {
      setSopList(tempSopList)
    }
    setPage(0)
  }, [filterName])

  useEffect(() => {
    (async function() {
    if(sopList.length){
      const startIndex = page * rowPerPage;
      const endIndex = startIndex + rowPerPage;
      const paginatedData = sopList.slice(startIndex, endIndex);
      setPaginatedData(paginatedData);
      setLoading(false);
    } else {
      setPaginatedData([]);
      const sleep = (ms:any) => new Promise(r => setTimeout(r, ms));
      await sleep(3000);
      setLoading(false);
    }
  })();
  }, [page, sopList]);
  return (
    <div>
      <SnackbarAlert options={snackbarOptions} handleClose={closeSnackbar} />
      <div>
        <Container
          maxWidth="xl"
          style={{
            paddingLeft: '3px',
            paddingRight: '3px'
          }}
        >
          <Card
            style={{
              borderRadius: '0px',
              backgroundColor: '#2b2d42',
              overflow: 'inherit'
            }}
          >
            <CustomTableToolbar
              drawerOpen={() => setFilterOpen(true)}
              numSelected={0}
              filterName={filterName}
              onFilterName={handleFilterName}
              displayInput={true}
              text={{
                title: 'SOP List',
                subTitle: `${sopList.length} SOP's`
              }}
              filterButton={true}
              customButton={false}
              radios={false}
              resetInput={() => setFilterName("")}
            />
          </Card>
        </Container>

        <CustomAlertsTable
          handleDelete={(values: any) => {
            setDeleteModal({
              status: true, values: {
                id: values.SOPDetailsKey,
                fileName: values.SOPFilePath
              }
            })
          }}
          handleSorting={handleSorting}
          loading={loading}
          HeaderList={HeaderList}
          rows={paginatedData}
          getAlertList={getModels}
          handleEdit={handleEdit}
          isAction={true}
          iconColName='SOPFilePath'
          iconComponent={<PictureAsPdfIcon />}
          iconClick={handlePdfClick}
        />
        <div style={{ position: "relative", marginRight: "0px" }}>
          <TablePaginationActions
            count={sopList.length}
            page={page}
            rowsPerPage={rowPerPage}
            onPageChange={handlePageChange}
            setPageNumber={setPage}
          />
        </div>
      </div>
      <FilterDrawer
        handlerClose={() => {
          setFilterOpen(false);
          setValues({ model: "", component: "", type: "", startDate: new Date(new Date().setMonth(new Date().getMonth() - 3)), endDate: new Date() })
        }}
        status={filterOpen}
        drawerHeader={'Filter'}
        children={<FilterBox values={values} setValues={setValues} />}
        clearFn={resetFilter}
        applyFn={applyFilter}
      />
      <AlertBox
        isOpen={deleteModal.status}
        handleClose={handleDeleteModal}
        leftBtnPress={handleDeleteModal}
        rightBtnPress={deleteSop}
        modalHeader={'Delete SOP'}
        modalContent={`Are you sure you want to delete SOP?`}
      />
    </div>
  );
};

export default Machines;
