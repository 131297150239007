import React, { useState, useEffect } from 'react';
import CustomTable from '../../../../atoms/CustomTable/index';
import CustomTableToolbar from '../../../../atoms/CustomTableToolbar';
import { Container, Card } from '@mui/material';
import { useDispatch } from 'react-redux';
import { executeACGAction, resetErr, updateScreenIdentifiers } from '../../../../store/slice';
import { ACTION_CODES, STORE_KEYS } from '../../../../constants/apiConstants';
import useGetState from '../../../../utils/hooks/useGetState';
import '../../ClientManagement/index.scss';
import { applySortFilter } from '../../../../utils/helper';
import AlertBox from '../../../../atoms/AlertBox';
import SnackbarAlert from '../../../../atoms/Snackbar';
import DetailDrawer from '../../../../atoms/DetailDrawer';

const TABLE_HEAD = [
    { id: 'ModuleOrderId', label: 'Display Order', alignRight: false },
    { id: 'name', label: 'Name', alignRight: false },
    { id: 'FeatureCount', label: 'Features', alignRight: false },
    { id: 'Action', label: 'Action', alignLeft: true }
];
const options = {
    DEFAULT: {
        message: '',
        open: false,
        type: ''
    },
    RM_SUCCESS: {
        message: 'Module deleted successfully!',
        open: true,
        type: 'success'
    }
};
type ComponentProps = {
    getSummary: Function;
    setReload: Function;
    reload: Boolean;
    dtlProps?: any;
};
const Modules = (props: ComponentProps) => {
    const { getSummary, setReload, reload, dtlProps = {} } = props;
    const [state, setState] = useState({
        right: false
    });
    const [page, setPage] = useState<number>(0);
    const [order, setOrder] = useState('desc');
    const [orderBy, setOrderBy] = useState('name');
    const [selected, setSelected] = useState<any>([]);
    const [filterName, setFilterName] = useState<string>('');
    const [tableData, setTableData] = useState<any[]>([]);
    const [isModuleLoading,setIsModuleLoading]=useState(false)
    console.log("🚀 ~ Modules ~ isModuleLoading:", isModuleLoading)
    const dispatch = useDispatch();
    const stateObj = useGetState();
    const getModules = () => {
        setIsModuleLoading(true)
        dispatch(
            executeACGAction({
                payload: {
                    requestType: 'GET',
                    urlPath: ACTION_CODES.GET_MODULES
                },
                uniqueScreenIdentifier: { modLoaded: true },
                storeKey: STORE_KEYS.MODULES
            })
        );
    };

    useEffect(() => {
        getModules();
    }, []);

    useEffect(() => {
        if (reload) {
            if (dtlProps?.drawerHeader !== 'Edit') {
                getSummary();
            }
            getModules();
            setReload(false);
        }
    }, [reload]);

    useEffect(() => {
        if (stateObj?.[STORE_KEYS.MODULES]?.modLoaded) {
            const list = stateObj?.[STORE_KEYS.MODULES]?.body?.data;
            const setData = list?.map((mod: any) => {
                const record = { ...mod };
                record.name = record?.ModuleName;
                return record;
            });
            setTableData(setData);
            dispatch(
                updateScreenIdentifiers({
                    storeKey: STORE_KEYS.MODULES,
                    uniqueScreenIdentifier: {
                        modLoaded: false
                    }
                })
            );
            setIsModuleLoading(false)
        }
    }, [stateObj?.[STORE_KEYS.MODULES]?.modLoaded]);

    const handleRequestSort = (event: any, property: any) => {
        setFilterName('');
        const isAsc = orderBy === event && order === 'asc';
        setOrder(isAsc ? 'desc' : 'asc');
        setOrderBy(event);
    };

    const handleSelectAllClick = (event: any) => {
        if (event.target.checked) {
            const newSelecteds = [];
            for (let i = 0; i < tableData.length; i++) {
                newSelecteds.push(tableData[i].id);
            }
            const toRemove = tableData.slice(page, tableData.length);
            setSelected(newSelecteds);
            return;
        }
        setSelected([]);
    };

    const handleClick = (requestedBy: string) => {
        const selectedIndex = selected.indexOf(requestedBy);
        let newSelected: any[] | ((prevState: unknown[]) => unknown[]) = [];
        if (selectedIndex === -1) {
            newSelected = newSelected.concat(selected, requestedBy);
        } else if (selectedIndex === 0) {
            newSelected = newSelected.concat(selected.slice(1));
        } else if (selectedIndex === selected.length - 1) {
            newSelected = newSelected.concat(selected.slice(0, -1));
        } else if (selectedIndex > 0) {
            newSelected = newSelected.concat(selected.slice(0, selectedIndex), selected.slice(selectedIndex + 1));
        }
        setSelected(newSelected);
    };
    const filteredList = applySortFilter(tableData, order, filterName, orderBy);
    const handleFilterByName = (event: { target: { value: React.SetStateAction<string> } }) => {
        setPage(0);
        setOrderBy('name');
        setFilterName(event.target.value);
    };
    const handleChangePage = (event: any, newPage: React.SetStateAction<number>) => {
        setPage(newPage);
    };

    const showRow = (event: any) => {};

    const [mdKey, setMdKey] = useState<any>();
    const [removeBxOpen, setRemoveBxOpen] = useState(false);
    const [rmMd, setRmMd] = useState(false);
    const [snackbarOptions, setSnackbarOptions] = useState(options.DEFAULT);

    const closeRmBx = () => {
        setRemoveBxOpen(false);
    };
    const removeMd = (ele: any) => {
        setMdKey(ele?.ModuleKey);
        setRemoveBxOpen(true);
    };
    const resetInput = () => {
        setFilterName('');
    };

    useEffect(() => {
        if (rmMd) {
            closeRmBx();
            dispatch(
                executeACGAction({
                    payload: {
                        requestType: 'DELETE',
                        urlPath: `${ACTION_CODES.DELETE_MODULE}/${mdKey}`
                    },
                    uniqueScreenIdentifier: {
                        mdRemoved: true
                    },
                    storeKey: STORE_KEYS.DELETE_RPT
                })
            );
            setRmMd(false);
        }
    }, [rmMd]);

    useEffect(() => {
        if (stateObj?.[STORE_KEYS.DELETE_RPT]?.mdRemoved) {
            if (stateObj?.[STORE_KEYS.DELETE_RPT]?.statusCode === 200) {
                setSnackbarOptions(options.RM_SUCCESS);
                closeDtlDrawer();
                getSummary();
                getModules();
            }
            dispatch(
                updateScreenIdentifiers({
                    storeKey: STORE_KEYS.DELETE_RPT,
                    uniqueScreenIdentifier: {
                        mdRemoved: false
                    }
                })
            );
        }
    }, [stateObj?.[STORE_KEYS.DELETE_RPT]?.mdRemoved]);

    const handleSnackbarError = (err: any) => {
        const errorMsg = err?.message || 'Internal Server error';
        const snackbarError = {
            message: errorMsg,
            type: 'reject',
            open: true
        };
        setSnackbarOptions(snackbarError);
    };

    useEffect(() => {
        if (stateObj?.err) {
            handleSnackbarError(stateObj?.err);
            dispatch(resetErr());
        }
    }, [stateObj?.err]);
    const closeSnackbar = () => setSnackbarOptions(options.DEFAULT);
    const [dtlDrawerOpen, setDtlDrawerOpen] = useState(false);
    const closeDtlDrawer = () => {
        setDtlDrawerOpen(false);
    };
    const handleRoute = (row: any, ele: any) => {
        if (ele === 'Action') {
            dtlProps?.setDtlData(row);
            setDtlDrawerOpen(true);
        }
    };
    return (
        <div>
            <SnackbarAlert options={snackbarOptions} handleClose={closeSnackbar} />
           
                <div>
                    <Container
                        maxWidth="xl"
                        style={{
                            paddingLeft: '3px',
                            paddingRight: '3px'
                        }}
                    >
                        <Card
                            style={{
                                borderRadius: '0px',
                                backgroundColor: '#2b2d42',
                                overflow: 'inherit'
                            }}
                        >
                            <CustomTableToolbar
                                drawerOpen={() => {}}
                                numSelected={selected?.length}
                                filterName={filterName}
                                onFilterName={handleFilterByName}
                                displayInput={true}
                                text={{
                                    title: 'Module List',
                                    subTitle: `${filteredList?.length} Modules`
                                }}
                                filterButton={false}
                                customButton={false}
                                resetInput={resetInput}
                            />
                        </Card>
                    </Container>
                    <CustomTable
                        selectable={false}
                        headers={TABLE_HEAD}
                        body={filteredList}
                        isLoading={isModuleLoading}
                        filteredUsers={filteredList}
                        filterName={filterName}
                        selected={selected}
                        order={order}
                        orderBy={orderBy}
                        onRequestSort={handleRequestSort}
                        onSelectAllClick={handleSelectAllClick}
                        rowChange={(event: string) => handleClick(event)}
                        rowCount={filteredList?.length}
                        onPageChange={handleChangePage}
                        page={page}
                        setPageNumber={setPage}
                        selectRow={showRow}
                        route={handleRoute}
                        isRequestPage={true}
                        link={true}
                        removeFn={removeMd}
                    />
                </div>
        
            <AlertBox
                isOpen={removeBxOpen}
                handleClose={closeRmBx}
                leftBtnPress={closeRmBx}
                rightBtnPress={() => setRmMd(true)}
                modalHeader="Delete Module"
                modalContent="Are you sure you want to delete module?"
            />
            <DetailDrawer hasImage={false}
                handlerOpen={() => {}}
                handlerClose={closeDtlDrawer}
                status={dtlDrawerOpen}
                data={{
                    name: dtlProps?.dtlData?.ModuleName,
                    details: [
                        { code: 'Module Code', name: dtlProps?.dtlData?.ModuleCode },
                        { code: 'Module Name', name: dtlProps?.dtlData?.ModuleName },
                        { code: 'Module Order Id', name: dtlProps?.dtlData?.ModuleOrderId }
                    ]
                }}
                type={'Feature'}
                editFn={() => {
                    dtlProps?.setCreateDrawerOpen(true);
                    dtlProps?.setCreateValue('Module');
                    dtlProps?.setDrawerHeader('Edit');
                }}
                deleteFn={() => removeMd(dtlProps?.dtlData)}
            />
        </div>
    );
};

export default Modules;
