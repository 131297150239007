import { useState, useEffect, useRef } from 'react';
import { Accordion, Container, Tooltip } from '@mui/material';
import {
    Drawer,
    List,
    ListItemButton,
    ListItemIcon,
    Collapse,
    CssBaseline,
} from "@mui/material";
import useGetState from '../../../../app/utils/hooks/useGetState';
import { ACTION_CODES, STORE_KEYS, BASEURL } from '../../../../app/constants/apiConstants';
import Axios from 'axios';
import { formatOnlyDate } from '../../../utils/formatTime';
import { Grid, Card, CardContent, CardActions, Typography, Button, Box, IconButton } from '@mui/material';
import SnackbarAlert from '../../../atoms/Snackbar';
import DownArrow from '../../../../assets/down-arrow.svg';
import Breadcrumb from '../../../atoms/BreadCrumb';
import DropDown from '../../../atoms/Dropdown';
import ACGButton from '../../../atoms/Button';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import ExpandLessIcon from '@mui/icons-material/ExpandLess';
import ChevronLeftIcon from '@mui/icons-material/ChevronLeft';
import ChevronRightIcon from '@mui/icons-material/ChevronRight';
import CustomDatePicker from '../../../atoms/CustomDatePicker';
import '../../OEEDashboard/OEEInsights/OEEInsights.scss';
import Prompter from '../../../atoms/Prompter';
import AlertBox from '../../../atoms/AlertBox';
import InfoIcon from '@mui/icons-material/Info';
import OutlinedInput from '@mui/material/OutlinedInput';
import InputLabel from '@mui/material/InputLabel';
import MenuItem from '@mui/material/MenuItem';
import FormControl from '@mui/material/FormControl';
import ListItemText from '@mui/material/ListItemText';
import Select, { SelectChangeEvent } from '@mui/material/Select';
import Checkbox from '@mui/material/Checkbox';
import RefreshIcon from '@mui/icons-material/Refresh';
import html2canvas from 'html2canvas';
import { jsPDF } from 'jspdf';
import useDebounce from '../../../hooks/useDebounce';
import moment from 'moment';
const AI_API_BASE_URL = "http://172.16.123.78:7010"

const options = {
    DEFAULT: {
        message: '',
        open: false,
        type: ''
    }
};
const Dateformat = {
    YYYY_MM_DD: 'yyyy-mm-dd',
    YYYY_MM_DD_HH_MM_SS: 'yyyy-mm-dd hh:mm:ss',
    YYYY_MM_DD_HH_MM_SS_L: 'yyyy-mm-ddTHH:MM:ss.l',
};
const ITEM_HEIGHT = 48;
const ITEM_PADDING_TOP = 8;
const MenuProps = {
    PaperProps: {
        style: {
            maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
            width: 250,
        },
    },
};
const DropDownIcon = (prop: any) => (
    <img style={{ marginRight: '5px', marginTop: '-2px' }} src={DownArrow} {...prop} />
);
const OEEInsights = (props: any) => {
   
    const [snackbarOptions, setSnackbarOptions] = useState(options.DEFAULT);
    const closeSnackbar = () => setSnackbarOptions(options.DEFAULT);
    const state = useGetState();
    const token = state?.[STORE_KEYS.USER_DTL].token;
    const loggedInUser = state[STORE_KEYS.USER_DTL]?.LoggedUserKey;
    const email = state[STORE_KEYS.USER_DTL]?.EmailId;
    const AIInsightsShow = state[STORE_KEYS.USER_DTL]?.AIInsightVisibleFlag;
    const date = new Date();
    date.setDate(date.getDate() - 90)
    const [inDate, setInDate] = useState(date);
    const d = new Date();
    const [endDate, setEndDate] = useState(new Date());
    const [tab, setTab] = useState(0);
    const [removeBxOpen, setRemoveBxOpen] = useState(false);
    const [applyFilter, setApplyFilter] = useState(false);
    const [isDefault, setIsDefult] = useState(true);
    const [canDownload, setCanDownload] = useState(false);
    const [showChat, setShowChat] = useState(false);
    const [openMenu, setOpenMenu] = useState<string | null>(props.aIInsightsList[0]?.code);
    const [subCategories, setSubCategories] = useState([
        {
            "code": "oee_insights",
            "subCategory": [
                {
                    "label": "Top & Bottom OEE Products",
                    "isSelected": false
                },
                {
                    "label": "Lines - wise OEE",
                    "isSelected": false
                },
                {
                    "label": "Operator Efficiency",
                    "isSelected": false
                },

            ]
        },
        {
            "code": "availability_insights",
            "subCategory": [
                {
                    "label": "Downtime & Machines",
                    "isSelected": false
                },
                {
                    "label": "Alarm Downtime Trends",
                    "isSelected": false
                },
                {
                    "label": "Downtime & Operators",
                    "isSelected": false
                }
            ]
        },
        {
            "code": "quality_insights",
            "subCategory": [
                {
                    "label": "Quality by Machine",
                    "isSelected": false
                },
                {
                    "label": "Quality by Product",
                    "isSelected": false
                },
                {
                    "label": "Quality & Operators",
                    "isSelected": false
                },
                {
                    "label": "Performance & Quality Correlation",
                    "isSelected": false
                }
            ]
        },
        {
            "code": "performance_insights",
            "subCategory": [
                {
                    "label": "Performance & Operators",
                    "isSelected": false
                },
                {
                    "label": "Performance & Machines",
                    "isSelected": false
                },
                {
                    "label": "Performance & Products",
                    "isSelected": false
                }
            ]
        },

    ])
    const [selectedSubCategory, setSelectedSubCategory] = useState('');

    const toggleMenu = (menuKey: string) => {
        setOpenMenu((prevKey: any) => (prevKey === menuKey ? null : menuKey));
    };

    useEffect(() => {
        if (props.machineModel.length > 0) {
            props.setSelectedMachineModel(props.machineModel[0]?.code);
            fetchMachines(props.machineModel[0]?.code);
        }

    }, [props.machineModel]);

    const fetchMachines = async (model: any) => {
        const payload = {
            userId: loggedInUser,
            model: model,
            plantKey: 'All'
        };
        await Axios({
            url: BASEURL + '/' + ACTION_CODES.API_PMEC_GET_MACHINES,
            method: 'post',
            data: payload,
            headers: {
                Authorization: 'Bearer ' + token
            }
        })
            .then((response) => {
                if (response.status == 200 && response?.data) {
                    const result = response?.data?.message;
                    let tmpTableData = result[0][0];
                    const includeACG = tmpTableData.filter((item:any) => item.MachineSerialNo.includes("BQSACG"));

                    props.setMachines([
                        ...tmpTableData?.map((item: any) => ({ code: item.MachineSerialNo, name: item.MachineName }))])
                        props.setSelectedMachines(includeACG.map((i: any) => i.MachineSerialNo));
                }
            })
            .catch((error) => {
                if (window.newrelic) {
                    window.newrelic.noticeError(error?.message);
                }
                return {
                    status: error
                };
            });
    }

    useEffect(() => {
        (async () => {
            console.log( props.selectedMachines.length,props.selectedInsight)
            if (props.selectedInsight != "" && props.selectedMachines.length > 0 && isDefault) {

                await handleSubCategoryClick(props.selectedInsight, 0, subCategories[tab].subCategory[0].label);
            }
        })();

    }, [props.selectedInsight, props.selectedMachines]);

    const fetchDefaultInsights = async (category: any, model: any, name: any) => {
        setShowChat(false);
        props.setShowLoader(true);
        const payload = {
            start_date: formatOnlyDate(inDate),
            end_date: formatOnlyDate(endDate),
            machine_family: model.filter((i:any)=> i.includes("BQSACG")),
            insight_category: category + ":" + name
        };

        await Axios({
            url: BASEURL + '/genaiapp/chat/descriptive_insights',
            method: 'post',
            data: payload,
        })
            .then((response) => {
                if (response.status == 200 && response?.data) {
                    const aiData = response?.data;
                    aiData['isUser'] = false;
                    aiData['subCategory'] = name;
                    const obj = { [category]: [aiData] }

                    const isCategoryPresent = props.defaultData.some((item: any) => item.hasOwnProperty(category));
                    const isSubCategoryPresent = props.defaultData.some((item: any) => item.hasOwnProperty(name));

                    if (!isCategoryPresent) {
                        props.setDefaultData([...props.defaultData, obj]);
                    } else {
                        const data = [...props.defaultData];
                        const parentIndex = data.findIndex(item => item[category] !== undefined);
                        if (parentIndex !== -1) {
                            data.splice(parentIndex, 1);
                        }
                        props.setDefaultData([...data, obj]);
                    }
                    setIsDefult(false);
                    props.setShowLoader(false);
                }
            })
            .catch((error) => {
                if (window.newrelic) {
                    window.newrelic.noticeError(error?.message);
                }
                return {
                    status: error
                };
            });
    }

    const fetchChatInsights = async (qs: any) => {
        props.setShowLoader(true);
        await new Promise(resolve => setTimeout(resolve, 3000));
    
        const payload = {
            start_date: formatOnlyDate(inDate),
            end_date: formatOnlyDate(endDate),
            machine_family: props.selectedMachines,
            // insight_category: selectedInsight,
            question: qs,
        };
    
        const TIMEOUT = 6000000;
    
        const apiCall = Axios({
            url: BASEURL + '/genaiapp/chat/descriptive_insights_free_text',
            method: 'post',
            data: payload,
        });
    
        const timeout = new Promise((resolve) => 
            setTimeout(() => resolve({ error: 'Timeout: No response in 5 seconds' }), TIMEOUT)
        );
    
        try {
            const response: any = await Promise.race([apiCall, timeout]);
    
            if (response?.status === 200) {
                props.setShowLoader(false);
                console.log(response.data)
                const aiData = response.data;
                aiData['isUser'] = false;
                aiData['feedback'] = null;
                return aiData;
            } else if (response?.error) {
                // Timeout case
                
                props.setShowLoader(false);
                const aiData:any = {text:"No response! Please ask the question again."};
                aiData['isUser'] = false;
                aiData['feedback'] = null;
                return aiData;
            } else {
                return { error: 'No data received' };
            }
        } catch (error: any) {
            if (window.newrelic) {
                window.newrelic.noticeError(error?.message);
            }
            return { status: 'error', message: error.message || 'Unknown error' }; // Return error details
        }
    };
    
    const handleCheckboxChange = (id: any) => {
        let data: any;
        props.setSelectedMachines((prevSelected: any) => {
            if (prevSelected.includes(id)) {
                data = prevSelected.filter((optionId: any) => optionId !== id);
                return prevSelected.filter((optionId: any) => optionId !== id);
            } else {
                data = [...prevSelected, id];
                return [...prevSelected, id];
            }
        });
    };

    const handleSelectAll = () => {
        if (props.selectedMachines.length === props.machines.length) {
            props.setSelectedMachines([]);
        } else {
            const data = props.machines.map((option: any) => option.code)
            props.setSelectedMachines(data);
        }
    };

    const handleDialogClose: React.MouseEventHandler<HTMLElement> = (e) => closeRmBx(e, 'close');

    const closeRmBx = (event: object, reason: string = "") => {
        if (reason === "close") return;
        setApplyFilter(false);
        setRemoveBxOpen(false);
    };

    const applyFilterHandler = () => {
        setApplyFilter(false);
        setRemoveBxOpen(true);
        setIsDefult(true);
    }

    useEffect(() => {
        const applyFilters = async () => {
            if (!canDownload) {
                await fetchDefaultInsights(props.selectedInsight, props.selectedMachines, selectedSubCategory);
            }
            setRemoveBxOpen(false);
        };

        if (applyFilter) {
            applyFilters().then(() => setApplyFilter(false));
        }
    }, [applyFilter]);

    const rightBtnPress = async () => {
        await props.handleDownloadPdf();
        props.setDefaultData([]);
        setCanDownload(true);
    };

    useEffect(() => {
        if (canDownload && props.defaultData.length === 0) {
            fetchDefaultInsights(props.selectedInsight, props.selectedMachines, selectedSubCategory);
            setCanDownload(false); // Reset the flag after fetching insights
        }
    }, [props.defaultData, canDownload]);

    const drawerWidth = 240;

    const handleSubCategoryClick = async (categoryCode: string, subIndex: number, name: string) => {
        setSelectedSubCategory(name);
        props.setDefaultData([]);
        fetchDefaultInsights(categoryCode, props.selectedMachines, name);
        setSubCategories((prevCategories) =>
            prevCategories.map((category) => {
                if (category.code === categoryCode) {
                    return {
                        ...category,
                        subCategory: category.subCategory.map((sub, index) => ({
                            ...sub,
                            isSelected: index === subIndex,
                        })),
                    };
                }
                return category;
            })
        );
    };

    return (
        <div className="dashboard OEEInsights" style={{ minHeight: 120, height: "100%", paddingBottom: "15px" }}>
            <SnackbarAlert options={snackbarOptions} handleClose={closeSnackbar} />
            <Container maxWidth="xl" style={{ padding: 0, height: "100%" }}>

                <Box sx={{ display: 'flex', height: "100%" }}>
                    <Box
                        className={props.right ? 'content-narrow' : 'content-expanded'}
                        sx={{
                            width: props.right ? '75%' : '100%',
                            transition: 'width 0.3s',
                            p: 0
                        }}
                    >
                        <div className='leftBox'>

                            <Box sx={{ display: "flex" }}>
                                <div
                                >
                                    <List
                                        sx={{
                                            height: '80vh',
                                            overflowY: 'auto',
                                            width: 200,
                                            paddingTop: 0,
                                            paddingBottom: 0,
                                        }}
                                    >
                                        {props.aIInsightsList?.map((item: any, i: number) => (
                                            <div key={item.code}>
                                                {/* Category */}
                                                <ListItemButton
                                                    onClick={() => {
                                                        toggleMenu(item.code);
                                                        props.setSelectedInsight(item.code);
                                                        setTab(i);
                                                        setIsDefult(true);
                                                    }}
                                                    style={{
                                                        padding: '4px 8px',
                                                        color: '#ffffff',
                                                        backgroundColor: item.code === props.selectedInsight ? '#1565c0' : '',
                                                    }}
                                                >
                                                    <ListItemText
                                                        primary={item.name}
                                                        primaryTypographyProps={{
                                                            fontSize: '11px',
                                                        }}
                                                    />

                                                    {item.code === props.selectedInsight && openMenu === item.code ? <ExpandLessIcon /> : <ExpandMoreIcon />}
                                                </ListItemButton>

                                                {/* Subcategories */}
                                                {subCategories.map((category) => {
                                                    if (category.code === item.code) {
                                                        return (
                                                            <Collapse
                                                                key={category.code}
                                                                in={openMenu === item.code} // Open only if `openMenu` matches the current category
                                                                timeout="auto"
                                                                unmountOnExit
                                                            >
                                                                <List component="div" disablePadding>
                                                                    {category.subCategory.map((sub, index) => (
                                                                        <ListItemButton
                                                                            key={index}
                                                                            sx={{ p: 1 }}
                                                                            style={{ color: sub.isSelected ? 'rgb(136 187 245)' : '#ffffff', }}
                                                                            onClick={() => { handleSubCategoryClick(category.code, index, sub.label); }}
                                                                        >
                                                                            <ListItemText
                                                                                primary={sub.label}
                                                                                primaryTypographyProps={{
                                                                                    fontSize: '11px',
                                                                                    padding: 0,
                                                                                    marginLeft: "10px",
                                                                                    fontWeight: sub.isSelected ? "bolder" : ""
                                                                                }}
                                                                            />
                                                                        </ListItemButton>
                                                                    ))}
                                                                </List>
                                                            </Collapse>
                                                        );
                                                    }
                                                    return null; // Ensure no other subcategories are rendered
                                                })}
                                            </div>
                                        ))}
                                    </List>
                                   <div style={{display: "flex", justifyContent: "center", marginRight: 12, marginBottom: 8}}>
                                    <ACGButton name="Chat with your data"
                                     className='AiInsightsBtns'
                                      disabled={!showChat ? false : true} 
                                      onClick={() =>{setShowChat(true); props.refreshInights();}}
                                      />
                                    </div> 
                                </div>

                                {/* Right-Side Container */}
                                <div
                                    style={{ width: "100%" }}
                                    className='chatsection'
                                >
                                    <Prompter
                                        tab={tab}
                                        email={email}
                                        defaultData={props.defaultData}
                                        setDefaultData={props.setDefaultData}
                                        selectedInsight={props.selectedInsight}
                                        showLoader={props.showLoader}
                                        setShowLoader={props.setShowLoader}
                                        fetchChatInsights={(data: any) => fetchChatInsights(data)}
                                        componentRef={props.componentRef}
                                        showChat={showChat}
                                    />
                                </div>
                            </Box>
                        </div>
                    </Box>
                    {/* Filter Section */}
                    {props.right && (
                        <Box
                            className={props.right ? 'filter-expanded' : 'filter-hidden'}
                            sx={{ width: '25%', transition: 'width 0.3s', p: 0, height: "100%" }}
                        >
                            <div className='rightFilters'>
                                <div className='filterdiv'>
                                    <div className='width_filter clscolordot padingLR'>
                                        <div className='lableTitle'>Select Machine Model</div>
                                        <div className='dropboxbttn'>
                                            <DropDown
                                                onChange={(e: any) => {
                                                    props.setSelectedMachineModel(e.target.value);
                                                    fetchMachines(e.target.value);
                                                }}
                                                className="inputDiv"
                                                name="dropdownAction"
                                                items={props.machineModel}
                                                placeHolder="Select Machine Model"
                                                value={props.selectedMachineModel}
                                            />
                                        </div>
                                    </div>

                                    <div className='width_filter clscolordot padingLR'>
                                        <div className='lableTitle'>Select Machine Serial Number</div>
                                        <div className='multiboxcheck'>
                                            <FormControl sx={{ width: '100%' }}>
                                                {props.selectedMachines?.length > 0 ? null : (
                                                    <InputLabel
                                                        style={{
                                                            fontFamily: 'Montserrat',
                                                            fontSize: '11px',
                                                            paddingTop: '0px',
                                                            paddingLeft: '12px',
                                                            color: '#b1c2df',
                                                            lineHeight: '1',
                                                            opacity: '0.7'
                                                        }}
                                                        shrink={false}
                                                        id="demo-multiple-checkbox-label"
                                                    >Select Machine Serial Number
                                                    </InputLabel>)}
                                                <Select
                                                    IconComponent={DropDownIcon}
                                                    labelId="demo-multiple-checkbox-label"
                                                    id="demo-multiple-checkbox"
                                                    className='chekboxmulti'
                                                    multiple
                                                    disabled={props.selectedMachine === "" ? true : false}
                                                    value={props.selectedMachines}
                                                    sx={{ marginBottom: "10px", backgroundColor: "#1d1e2c", borderRadius: 3, marginTop: '10px', color: "#b1c2df" }}
                                                    placeholder='Please select props.machines'
                                                    renderValue={(selected: any) => {
                                                        const filtered = props.machines.filter((opr: any) =>
                                                            selected.some((item: any) => item === opr.code)
                                                        );
                                                        return filtered.map((x: any) => x.code).join(', ')
                                                    }
                                                    }
                                                    MenuProps={MenuProps}

                                                >
                                                    <MenuItem value={"all"} onClick={() => handleSelectAll()} className='ulSelectProduct'>
                                                        <Checkbox
                                                            checked={props.selectedMachines.length === props.machines.length}

                                                        />
                                                        <ListItemText primary="Select All" />
                                                    </MenuItem>
                                                    {props.machines?.map((i: any) => {
                                                        if (i?.name.includes("BQSACG")) {
                                                            return (
                                                                <MenuItem value={i} onClick={() => handleCheckboxChange(i.code)} className='ulSelectProduct'>
                                                                    <Checkbox
                                                                        checked={props.selectedMachines.includes(i.code)}
                                                                    />
                                                                    <ListItemText primary={i.name} />
                                                                </MenuItem>
                                                            )
                                                        }
                                                    })}
                                                </Select>
                                            </FormControl>
                                        </div>
                                    </div>
                                    <div className='width_filter clscolordot padingLR'>
                                        <div className='lableTitle'>From</div>
                                        <CustomDatePicker
                                            name="dateFromSelect"
                                            value={inDate}
                                            maxDate={new Date()}
                                            className="inputDiv"
                                            onChange={(val: any) => {
                                                setInDate(val);
                                                window.newrelic.addPageAction('OEE-insights', { action: 'From date changed', additionalInfo: `From Date changed to ${val}` });
                                            }}
                                            inputFormat="dd/MM/yyyy"
                                            placeholder="Select From Date"
                                            minDate={new Date("01-01-2023")}

                                        />
                                    </div>
                                    <div className='width_filter clscolordot padingLR'>
                                        <div className='lableTitle'>To</div>
                                        <CustomDatePicker
                                            name="dateFromSelect"
                                            value={endDate}
                                            minDate={inDate}
                                            maxDate={new Date()}
                                            className="inputDiv"
                                            onChange={(val: any) => {
                                                setEndDate(val);
                                                window.newrelic.addPageAction('OEE-Insights', { action: 'To date changed', additionalInfo: `To Date changed to ${val}` });
                                            }}
                                            inputFormat="dd/MM/yyyy"
                                            placeholder="Select End Date"
                                        />
                                    </div>
                                    <div className={'btns noWrap'}>

                                        <ACGButton
                                            name={'Submit'}
                                            handleClick={() => { applyFilterHandler() }}
                                            type="submit"
                                            className="second-btn"
                                            variant="contained"
                                        />
                                        <div style={{ marginTop: 10, display: "inline-flex", alignItems: "center" }}>
                                            <InfoIcon fontSize='small' style={{ color: '#ffff', width: '0.8rem', height: '0.8rem', marginRight: 4 }} /> <Typography
                                                style={{ color: "#ffff", fontSize: 11 }}>
                                                Applying filters will erase the previous chat history
                                            </Typography>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </Box>
                    )}
                </Box>
            </Container>
            <AlertBox
                type="filter"
                isAlertImg={false}
                isOpen={removeBxOpen}
                handleClose={handleDialogClose}
                leftBtnPress={() => { setRemoveBxOpen(false); props.setDefaultData([]); setCanDownload(false); setApplyFilter(true) }}
                rightBtnPress={async () => {
                    setCanDownload(true);
                    setRemoveBxOpen(false);
                    await rightBtnPress();

                    fetchDefaultInsights(props.selectedInsight, props.selectedMachines, selectedSubCategory);
                }}
                modalHeader="Apply Filters"
                modalContent="Do you want to download the previous chat history?"
            />
        </div>
    );
};

export default OEEInsights;