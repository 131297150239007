import * as Yup from 'yup';
import { urlRegex } from '../FeatureManagement/schema';

export const PLANT_YUPSCHEMA = {
    dropdownClient: Yup.string().required('Please select Client'),
    dropdownCountry: Yup.string().required('Please select Country'),
    dropdownState: Yup.lazy(() =>
        Yup.string().when(['dropdownCountry'], (dropdownCountry: any) => {
            if (dropdownCountry == '') {
                return Yup.string().notRequired();
            } else {
                return Yup.string().required('Please select State');
            }
        })
    ),
    // city: Yup.lazy(() =>
    //     Yup.string().when(['dropdownCountry'], (dropdownCountry: any) => {
    //         if (dropdownCountry == '') {
    //             return Yup.string().notRequired();
    //         } else {
    //             return Yup.string().required('Please enter City');
    //         }
    //     })
    // ),
    plantCode: Yup.string().required('Please enter Plant Code'),
    plantName: Yup.string().required('Please enter Plant Name'),
    plantAddress: Yup.string().required('Please enter Plant Address'),
    dropdownTimeZone: Yup.string().required('Please select Time Zone')
};

export const PLANTLINE_YUPSCHEMA = {
    PlantLineNoCode: Yup.string().required('Please enter Line Code'),
    PlantLineNoName: Yup.string().required('Please enter Line Name')
};

export const CLIENTINFO_YUPSCHEMA = {
    clientCode: Yup.string().required('Please enter Client Code'),
    clientName: Yup.string().required('Please enter Client Name'),
    clientAllowedUsers: Yup.string().required('Please enter Allowed Number of users'),
    clientDomain: Yup.string().matches(
        /^[a-zA-Z0-9][a-zA-Z0-9-]{1,61}[a-zA-Z0-9]\.[a-zA-Z]{2,}$/,
        'Invalid domain!'
    ).required('Please enter Client Domain'),
    clientEndDate: Yup.string().required('Please enter Client end date'),
    customerImagePath: Yup.lazy(() =>
        Yup.string().when(['rptType'], (rptType: any) => {
            if (rptType == '0') {
                return Yup.string().matches(urlRegex, 'Enter a valid url').required('Please enter Power BI Base URL');
            } else {
                return Yup.string().notRequired();
            }
        })
    )
};