import { useState, useEffect, useRef } from 'react';
import { Container, Grid } from '@mui/material';
import { Formik, Form } from 'formik';
import ACGButton from '../../../../../../atoms/Button';
import { ACTION_CODES, STORE_KEYS } from '../../../../../../constants/apiConstants';
import { executeACGAction, resetErr, updateScreenIdentifiers } from '../../../../../../store/slice';
import useGetState from '../../../../../../utils/hooks/useGetState';
import * as Yup from 'yup';
import { SPOC_YUPSCHEMA } from '../AddSpoc/schema';
import Label from '../../../../../../atoms/Label/index';
import TextInput from '../../../../../../atoms/TextInput';
import { useDispatch } from 'react-redux';
import '../../../../../Admin/FeatureManagement/CreateReport/index.scss';
import FlagPhoneDropdown from '../../../../../../atoms/FlagDropdown';

type Props = {
    drawerClose: Function;
    setReload: Function;
    setSnackbarOpts: Function;
    data: any;
};
const options = {
    AD_SUCCESS: {
        message: 'Contact updated successfully!',
        open: true,
        type: 'success'
    }
};

const EditContact = (props: Props) => {
    const { drawerClose, setReload = () => {}, setSnackbarOpts = () => {}, data } = props;
    const formRef = useRef<any>(null);
    const [validationSchema, setValidationSchema] = useState({});
    const state = useGetState();
    const dispatch = useDispatch();
    const [initialValues] = useState({
        firstName: data?.spocDtl?.FirstName || '',
        lastName: data?.spocDtl?.LastName || '',
        emailId: data?.spocDtl?.EmailId || '',
        primaryNo: data?.spocDtl?.PrimaryContact || '',
        secNo: data?.spocDtl?.SecondaryContact || ''
    });
    const renderButtons = () => {
        return (
            <div className={'btns noWrap'}>
                <ACGButton
                    name={'Cancel'}
                    handleClick={drawerClose}
                    secondary={true}
                    className={'first-btn'}
                    type="button"
                    variant="contained"
                />
                <ACGButton
                    name={'Submit'}
                    handleClick={() => {}}
                    type="submit"
                    className="second-btn"
                    variant="contained"
                />
            </div>
        );
    };
    useEffect(() => {
        setValidationSchema(Yup.object().shape(SPOC_YUPSCHEMA));
    }, []);

    const handleSubmit = async (values: any) => {
        const bundle = {
            payload: {
                urlPath: ACTION_CODES.EDIT_SPOC,
                requestType: 'PUT',
                reqObj: {
                    machineKey: data?.machineKey,
                    firstName: values?.firstName,
                    lastName: values?.lastName,
                    email: values?.emailId,
                    phoneNo: values?.primaryNo,
                    alternatePhoneNo: values?.secNo,
                    spocType: data?.spocType
                }
            },
            uniqueScreenIdentifier: { spocChnged: true },
            storeKey: STORE_KEYS.ADD_RPT
        };
        dispatch(executeACGAction(bundle));
    };

    useEffect(() => {
        if (state?.[STORE_KEYS.ADD_RPT]?.spocChnged) {
            if (state?.[STORE_KEYS.ADD_RPT]?.statusCode === 200) {
                setReload(true);
                setSnackbarOpts(options.AD_SUCCESS);
                drawerClose();
            }
            dispatch(
                updateScreenIdentifiers({
                    storeKey: STORE_KEYS.ADD_RPT,
                    uniqueScreenIdentifier: {
                        spocChnged: false
                    }
                })
            );
        }
    }, [state?.[STORE_KEYS.ADD_RPT]?.spocChnged]);

    const handleSnackbarError = (err: any) => {
        const errorMsg = err?.message || 'Internal Server error';
        const snackbarError = {
            message: errorMsg,
            type: 'reject',
            open: true
        };
        setSnackbarOpts(snackbarError);
    };

    useEffect(() => {
        if (state?.err) {
            handleSnackbarError(state?.err);
            dispatch(resetErr());
        }
    }, [state?.err]);

    return (
        <Container className="createReport">
            <Formik
                initialValues={initialValues}
                validationSchema={validationSchema}
                onSubmit={(values: any) => {
                    handleSubmit(values);
                }}
                validateOnBlur={false}
                validateOnChange={false}
                innerRef={formRef}
            >
                {(formikprops: any) => {
                    const { values, handleChange, handleBlur, errors, touched, setFieldValue, setFieldTouched } =
                        formikprops;

                    return (
                        <Form>
                            <Grid container spacing={2}>
                                <Grid item xs={12} sm={12} md={6} xl={6} lg={6}>
                                    <Label label="First Name" className={'labelDiv'} />
                                    <TextInput
                                        variant="outlined"
                                        autoFocus
                                        fullWidth
                                        type="text"
                                        name="firstName"
                                        value={values.firstName}
                                        autoComplete="off"
                                        formInput="inputDiv"
                                        onChange={(e: any) => {
                                            handleChange(e);
                                        }}
                                        onBlur={handleBlur}
                                        placeholder="Enter First Name"
                                        helperText={errors.firstName && touched.firstName ? errors.firstName : ''}
                                    />
                                </Grid>
                                <Grid item xs={12} sm={12} md={6} xl={6} lg={6}>
                                    <Label label="Last Name" className={'labelDiv'} />
                                    <TextInput
                                        variant="outlined"
                                        fullWidth
                                        type="text"
                                        name="lastName"
                                        value={values.lastName}
                                        autoComplete="off"
                                        formInput="inputDiv"
                                        onChange={(e: any) => {
                                            handleChange(e);
                                        }}
                                        onBlur={handleBlur}
                                        placeholder="Enter Last Name"
                                        helperText={errors.lastName && touched.lastName ? errors.lastName : ''}
                                    />
                                </Grid>
                            </Grid>
                            <Label label="Email ID" className={'labelDiv'} />
                            <TextInput
                                variant="outlined"
                                autoFocus
                                fullWidth
                                type="text"
                                name="emailId"
                                disabled={true}
                                value={values.emailId}
                                autoComplete="off"
                                formInput="inputDiv"
                                onChange={(e: any) => {
                                    handleChange(e);
                                }}
                                onBlur={handleBlur}
                                placeholder="Enter Email ID"
                                helperText={errors.emailId && touched.emailId ? errors.emailId : ''}
                            />

                            <Label label="Primary Phone number" className={'labelDiv'} />
                            <Grid container spacing={2}>
                                <Grid item xs={12} sm={12} md={6} xl={6} lg={6}>
                                    <FlagPhoneDropdown
                                        handleChange={(event: any) => {
                                            setFieldTouched('primaryNo', true);
                                            if (event != undefined) {
                                                setFieldValue('primaryNo', event);
                                            } else {
                                                setFieldValue('primaryNo', '');
                                            }
                                        }}
                                        className="inputDiv"
                                        value={values.primaryNo}
                                        helperText={errors.primaryNo && touched.primaryNo ? errors.primaryNo : ''}
                                    />
                                </Grid>
                            </Grid>

                            <Label label="Secondary Phone number" className={'labelDiv'} />
                            <Grid container spacing={2}>
                                <Grid item xs={12} sm={12} md={6} xl={6} lg={6}>
                                    <FlagPhoneDropdown
                                        handleChange={(event: any) => {
                                            setFieldTouched('secNo', true);
                                            if (event != undefined) {
                                                setFieldValue('secNo', event);
                                            } else {
                                                setFieldValue('secNo', '');
                                            }
                                        }}
                                        className="inputDiv "
                                        value={values.secNo}
                                        helperText={errors.secNo && touched.secNo ? errors.secNo : ''}
                                    />
                                </Grid>
                            </Grid>

                            {renderButtons()}
                        </Form>
                    );
                }}
            </Formik>
        </Container>
    );
};

export default EditContact;
