import { InputAdornment, Box } from '@mui/material';
import Autocomplete from '@mui/material/Autocomplete';
import searchFill from '@iconify/icons-eva/search-fill';
import { TextField } from '@mui/material';
import { Icon } from '@iconify/react';
import './index.scss';
type MenuProps = {
    searchTxt?: any;
    setSearchTxt?: any;
    resetInput?: any;
    machines?: any[];
    onClick?: any;
};

export default function FreeSolo(props: MenuProps) {
    const { searchTxt, setSearchTxt, machines = [], onClick = () => {} } = props;
    const startAdor = (
        <div>
            <InputAdornment position="start" variant="outlined" className="searchBoxIcon">
                <Box component={Icon} icon={searchFill} sx={{ color: 'text.disabled' }} />
            </InputAdornment>
        </div>
    );
    return (
        <Autocomplete
            className="acgAutoComplete"
            id="free-solo-demo"
            freeSolo
            // disableCloseOnSelect
            //open={true}
            options={machines}
            onChange={(event: any, newValue: any) => {
                setSearchTxt(newValue);
                if (newValue?.MachineKey) {
                    onClick(newValue);
                }
            }}
            getOptionLabel={(option) => option.MachineName || ''}
            renderInput={(params) => (
                <TextField
                    {...params}
                    variant="outlined"
                    fullWidth
                    value={searchTxt}
                    placeholder="Search for Machine name"
                    InputProps={{
                        ...params.InputProps,
                        startAdornment: startAdor,
                        style: {
                            height: '40px'
                        }
                    }}
                />
            )}
        />
    );
}
