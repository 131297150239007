import { read, utils } from 'xlsx';
import Label from '../../../atoms/Label/index';
import TextInput from '../../../atoms/TextInput';
import NoData from '../../../../assets/nodata.svg';
import ACGButton from '../../../atoms/Button';
import Attachments from '../../../atoms/Attachments';
import { useDispatch } from "react-redux";
import React, { useState, useEffect } from "react";
import { Container, Grid, Typography } from "@mui/material";
import useGetState from "../../../utils/hooks/useGetState";
import { ACTION_CODES, STORE_KEYS } from "../../../constants/apiConstants";
import { executeACGAction, resetErr, updateScreenIdentifiers } from "../../../store/slice";
import { convertBlobFileToBase64 } from '../../../utils/helper';
import { boolean, string } from 'yup';

const options = {
    DEFAULT: {
        message: '',
        open: false,
        type: ''
    },
    ADD_SUCCESS: {
        message: 'Model created successfully!',
        open: true,
        type: 'success'
    }
};

const AddModel = (props: any) => {
    const { closeDrawer, setSnackbarOptions } = props;
    const dispatch = useDispatch();
    const stateObj = useGetState();
    const [parameters, setParameters] = useState<any[]>([]);
    const [isSetFile, setIsSetFile] = useState<any>([]);
    const [selectedModelImage, setSelectedModelImage] = useState<any>([]);
    const [modelName, setModelName] = useState<string>("");
    const [modelCode, setModelCode] = useState<string>("");
    const [formError, setFormError] = useState({ modelCode: '', modelName: '', paramsUpload: '', modelImage: '', hasError: false });

    const modelNameChangeHandler = (e: React.ChangeEvent<HTMLInputElement>) => {
        const val = e.currentTarget.value;
        setModelName(val);
    }
    const modelCodeChangeHandler = (e: React.ChangeEvent<HTMLInputElement>) => {
        const val = e.currentTarget.value;
        setModelCode(val);
    }

    const getModels = () => {
        dispatch(
            executeACGAction({
                payload: {
                    requestType: 'GET',
                    urlPath: ACTION_CODES.GET_MODELS
                },
                uniqueScreenIdentifier: { modLoaded: true },
                storeKey: STORE_KEYS.MODELS
            })
        );
    };

    const validateInput = (code: string, name: string, params: [], image: []) => {
        const errors = { modelCode: '', modelName: '', paramsUpload: '', modelImage: '', hasError: false };
        if (code?.trim()) {
            errors.modelCode = '';
            errors.hasError = false;
        } else {
            errors.modelCode = 'Please enter model code';
            errors.hasError = true;
        }
        if (name?.trim()) {
            errors.modelName = '';
            errors.hasError = false;
        } else {
            errors.modelName = 'Please enter model name';
            errors.hasError = true;
        }
        if (params?.length) {
            errors.paramsUpload = '';
            errors.hasError = false;
        } else {
            errors.paramsUpload = 'Please upload parameters';
            errors.hasError = true;
        }
        if (image?.length) {
            errors.modelImage = '';
            errors.hasError = false;
        } else {
            errors.modelImage = 'Please upload Image';
            errors.hasError = true;
        }
        return errors;
    }

    const submitHandler = async () => {
        const file = isSetFile[0];
        const runValidation = validateInput(modelCode, modelName, isSetFile, selectedModelImage);
        if (!runValidation.hasError) {
            setFormError(runValidation);

            // For model image upload
            const base64Image = await convertBlobFileToBase64(selectedModelImage[0]);
            const strImage = base64Image?.replace(/^data:image\/[a-z]+;base64,/, "");
            if (file) {
                const reader: FileReader = new FileReader();
                reader.readAsArrayBuffer(file);
                reader.onload = (e: any) => {
                    const binarystr = new Uint8Array(e.target.result);
                    const wb = read(binarystr, { type: 'array', raw: true, cellFormula: false });
                    const wsname = wb.SheetNames[0];
                    const data = utils.sheet_to_json(wb.Sheets[wsname]);
                    if (modelCode && modelName && data?.length) {
                        const bundle = {
                            payload: {
                                urlPath: ACTION_CODES.ADD_MACHINE_MODEL,
                                requestType: 'POST',
                                reqObj: {
                                    machineModelCode: modelCode,
                                    machineModelName: modelName.replace(/'/g, "''"),
                                    machineModelImagePath: strImage
                                }
                            },
                            uniqueScreenIdentifier: { addMachineModelLoaded: true },
                            storeKey: STORE_KEYS.ADD_MACHINE_MODEL
                        };
                        dispatch(executeACGAction(bundle));
                        setParameters(data);
                    }
                }
            }
        } else {
            setFormError(runValidation);
        }
    }

    useEffect(() => {
        if (stateObj?.[STORE_KEYS.ADD_MACHINE_MODEL]?.addMachineModelLoaded) {
            const body = stateObj?.[STORE_KEYS.ADD_MACHINE_MODEL]?.body?.data;
            if (body.machineModelKey && parameters.length > 0) {
                const bundle = {
                    payload: {
                        urlPath: ACTION_CODES.ADD_MACHINE_MODEL_PARAMETER,
                        requestType: 'POST',
                        reqObj: {
                            machineModelKey: body.machineModelKey,
                            machineModelParameters: parameters,
                        }
                    },
                    uniqueScreenIdentifier: { addMachineModelParameterLoaded: true },
                    storeKey: STORE_KEYS.ADD_MACHINE_MODEL_PARAMETER
                };
                dispatch(executeACGAction(bundle));
            }
            dispatch(
                updateScreenIdentifiers({
                    storeKey: STORE_KEYS.ADD_MACHINE_MODEL,
                    uniqueScreenIdentifier: {
                        addMachineModelLoaded: false
                    }
                })
            );
        }
    }, [stateObj?.[STORE_KEYS.ADD_MACHINE_MODEL]?.addMachineModelLoaded, parameters]);

    useEffect(() => {
        if (stateObj?.[STORE_KEYS.ADD_MACHINE_MODEL_PARAMETER]?.addMachineModelParameterLoaded) {
            if (stateObj?.[STORE_KEYS.ADD_MACHINE_MODEL_PARAMETER]?.statusCode === 200) {

                getModels();
                closeDrawer();
                setSnackbarOptions(options.ADD_SUCCESS);
            }
            dispatch(
                updateScreenIdentifiers({
                    storeKey: STORE_KEYS.ADD_MACHINE_MODEL_PARAMETER,
                    uniqueScreenIdentifier: {
                        addMachineModelParameterLoaded: false
                    }
                })
            );
        }
    }, [stateObj?.[STORE_KEYS.ADD_MACHINE_MODEL_PARAMETER]?.addMachineModelParameterLoaded]);

    const handleSnackbarError = (err: any) => {
        const errorMsg = err?.message || 'Internal Server error';
        const snackbarError = {
            message: errorMsg,
            type: 'reject',
            open: true
        };
        setSnackbarOptions(snackbarError);
    };
    useEffect(() => {
        if (stateObj?.err) {
            handleSnackbarError(stateObj?.err);
            dispatch(resetErr());
        }
    }, [stateObj?.err]);

    const renderButtons = () => {
        return (
            <div className={'btns noWrap'} style={{ display: 'flex', float: 'right', marginTop: '15px' }}>
                <ACGButton
                    name={'Cancel'}
                    disabled={stateObj?.isLoading}
                    handleClick={closeDrawer}
                    secondary={true}
                    className={'first-btn'}
                    type="button"
                    variant="contained"
                    style={{ marginRight: '25px', padding: '5px', width: '100px', height: '35px' }}
                />
                <ACGButton
                    name={`${stateObj?.isLoading ? 'Submitting...' : 'Submit'}`}
                    disabled={stateObj?.isLoading}
                    handleClick={closeDrawer}
                    type="submit"
                    onClick={submitHandler}
                    className="second-btn"
                    variant="contained"
                    style={{ marginRight: '25px', padding: '5px', width: '100px', height: '35px' }}
                />
            </div>
        );
    };

    return (
        <div>
            <div>
                <Container
                    maxWidth="xl"
                    style={{
                        paddingLeft: "3px",
                        paddingRight: "3px",
                    }}
                >
                    <div style={{ padding: '24px', borderBottom: '1px solid rgb(43 45 66 / 100%)', marginBottom: '24px' }}>
                        <Grid container spacing={2}>
                            <Grid item xs={3} lg={1}>
                                <Label label="Upload Image" className={'labelDiv'} />
                                <Attachments
                                    viewOnly={false}
                                    setError={selectedModelImage}
                                    isSetFile={setSelectedModelImage}
                                />
                                {selectedModelImage?.length === 0 ? <div>
                                    {formError.modelImage &&
                                        <Typography sx={{ mt: '2%', color: '#F06868', fontSize: '12px' }}> {formError.modelImage}</Typography>
                                    }
                                    {!formError.modelImage && <>
                                        {/* <Typography sx={{ mt: '2%', color: '#B1C2DF', fontSize: '12px' }}>Upload Image</Typography> */}
                                    </>}
                                </div> : null}

                            </Grid>
                            <Grid item xs={6} lg={3} md={4} sm={6}>
                                <Label label="Model Code" className={'labelDiv'} />
                                <TextInput
                                    variant="outlined"
                                    fullWidth
                                    type="text"
                                    name="modelCode"
                                    value={modelCode}
                                    autoComplete="off"
                                    formInput="inputDiv"
                                    onChange={modelCodeChangeHandler}
                                    onBlur={() => { }}
                                    placeholder="Enter Model Code"
                                    helperText={formError.modelCode}
                                />
                            </Grid>
                            <Grid item xs={6} lg={3} md={4} sm={6}>
                                <Label label="Model Name" className={'labelDiv'} />
                                <TextInput
                                    variant="outlined"
                                    fullWidth
                                    type="text"
                                    name="modelName"
                                    value={modelName}
                                    autoComplete="off"
                                    formInput="inputDiv"
                                    onChange={modelNameChangeHandler}
                                    onBlur={() => { }}
                                    placeholder="Enter Model Name"
                                    helperText={formError.modelName}
                                />
                            </Grid>
                        </Grid>
                    </div>
                    <div style={{ height: '50vh', borderBottom: '1px solid rgb(43 45 66 / 100%)' }}>
                        <div style={{ textAlign: 'center', marginTop: '5%' }}>
                            {isSetFile?.length === 0 ? <div>
                                <img src={NoData} alt="no data" />
                                {formError.paramsUpload &&
                                    <Typography sx={{ mt: '2%', color: '#F06868', fontSize: '18px' }} variant="h4"> {formError.paramsUpload}</Typography>
                                }
                                {!formError.paramsUpload && <>
                                    <Typography sx={{ mt: '2%', color: '#B1C2DF', fontSize: '18px' }} variant="h4">Upload Parameters to show</Typography>
                                </>}
                            </div> : null}
                            <div style={{ display: 'flex', justifyContent: 'center' }}>
                                <Attachments viewOnly={false} type={['.xlsx', '.xls']} isSetFile={setIsSetFile} />
                            </div>
                        </div>
                    </div>
                </Container>
                {renderButtons()}
            </div>
        </div>
    );
};

export default AddModel;
