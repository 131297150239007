import Box from '@mui/material/Box';
import Drawer from '@mui/material/Drawer';
import Label from '../Label';
import UserMoreMenu from '../CustomTableMenu/index';
import { sentenceCase } from 'change-case';
import Accordion from '../Accordion';
import SearchBar from '../SearchBar';
import AllAlarmDetails from '../../features/AlarmDetail/AllAlarms/Details';
import './index.scss';
import Popover from '../Popover';
import SPOCDetails from '../../features/MachineDetail/DrawerContent/TabContent/Overview/SPOCDetails';
import { STORE_KEYS, ACTION_CODES } from '../../constants/apiConstants';
import useGetState from '../../utils/hooks/useGetState';
import CallSpoc from '../../../assets/images/CallSpoc.svg';
import React, { useState, useEffect } from 'react';
import ContactDetails from '../../features/RemoteAssistance/Contacts/Details';
import { useDispatch } from 'react-redux';
import SnackbarAlert from '../../atoms/Snackbar';
import { executeACGAction, resetErr, updateScreenIdentifiers } from '../../store/slice';
import Rating from '../../atoms/Rating';
// import { ACTION_CODES, STORE_KEYS } from '../../constants/apiConstants';
const options = {
    DEFAULT: {
        message: '',
        open: false,
        type: ''
    },
    MARK_SUCCESS: {
        message: 'User marked as Favourite successfully!',
        open: true,
        type: 'success'
    },
    UNMARK_SUCCESS: {
        message: 'User unmarked as Favourite successfully!',
        open: true,
        type: 'success'
    }
};

type Anchor = 'right';

type Props = {
    status: any;
    handlerOpen: any;
    getList: any;
    handlerClose: any;
    // children: any;
    data?: any;
    removeFn?: any;
    drawerHeader?: any;
    block?: boolean;
    type?: string;
    editFn?: Function;
    deleteFn?: Function;
    usedIn?: string;
};

export default function ContactDetailDrawer(props: Props) {
    const {
        status,
        handlerOpen,
        handlerClose,
        drawerHeader,
        data,
        block = false,
        type,
        editFn = () => { },
        deleteFn = () => { },
        usedIn = '',
        removeFn = () => { },
        getList = () => { }
    } = props;
    const dispatch = useDispatch();
    const [snackbarOptions, setSnackbarOptions] = useState(options.DEFAULT);
    const stateObj = useGetState();
    const RCUserKey = data?.RCUserKey;
    const RCFUKey = data?.RCFUKey;

    const handleClose = () => {
        handlerClose();
    };
    const stateVal = useGetState();

    const favoriteUserAction = () => {
        if (data?.FavoriteUserFlag === 'Y') {
            // alert("unfavorite")
            dispatch(
                executeACGAction({
                    payload: {
                        requestType: 'DELETE',
                        urlPath: `${ACTION_CODES.UNMARK_USER_FAV}/${RCFUKey}`
                    },
                    uniqueScreenIdentifier: { unMarked: true },
                    storeKey: STORE_KEYS.ADD_CNT
                })
            );
        } else {
            // alert("favorite")
            dispatch(
                executeACGAction({
                    payload: {
                        requestType: 'POST',
                        urlPath: `${ACTION_CODES.MARK_USER_FAV}/${RCUserKey}`
                    },
                    uniqueScreenIdentifier: { marked: true },
                    storeKey: STORE_KEYS.ADD_CNT
                })
            );
        }
    };
    useEffect(() => {
        if (stateObj?.[STORE_KEYS.ADD_CNT]?.unMarked) {
            if (stateObj?.[STORE_KEYS.ADD_CNT]?.statusCode === 200) {
                setSnackbarOptions(options.UNMARK_SUCCESS);
                getList();
                // getMachines();
                handlerClose();
            }
            dispatch(
                updateScreenIdentifiers({
                    storeKey: STORE_KEYS.ADD_CNT,
                    uniqueScreenIdentifier: {
                        unMarked: false
                    }
                })
            );
        }
    }, [stateObj?.[STORE_KEYS.ADD_CNT]?.unMarked]);
    useEffect(() => {
        if (stateObj?.[STORE_KEYS.ADD_CNT]?.marked) {
            if (stateObj?.[STORE_KEYS.ADD_CNT]?.statusCode === 200) {
                setSnackbarOptions(options.MARK_SUCCESS);
                getList();
                // getMachines();
                handlerClose();
            }
            dispatch(
                updateScreenIdentifiers({
                    storeKey: STORE_KEYS.ADD_CNT,
                    uniqueScreenIdentifier: {
                        marked: false
                    }
                })
            );
        }
    }, [stateObj?.[STORE_KEYS.ADD_CNT]?.marked]);

    const closeSnackbar = () => setSnackbarOptions(options.DEFAULT);
    const handleSnackbarError = (err: any) => {
        const errorMsg = err?.message || 'Internal Server error';
        const snackbarError = {
            message: errorMsg,
            type: 'reject',
            open: true
        };
        setSnackbarOptions(snackbarError);
    };

    useEffect(() => {
        if (stateObj?.err) {
            handleSnackbarError(stateObj?.err);
            dispatch(resetErr());
        }
    }, [stateObj?.err]);

    const list = () => (
        <Box sx={{ width: 250 }} role="presentation" className={'standardBox'}>
            <div className="baseDiv">
                <div
                    onClick={handleClose}
                    style={{
                        border: '1px solid #5D97F6',
                        borderRadius: '50px',
                        height: '25px',
                        width: '25px',
                        position: 'absolute',
                        left: '20px',
                        top: '25px',
                        cursor: 'pointer'
                    }}
                >
                    <span style={{ position: 'relative', fontSize: '12px', color: '#5D97F6', left: '35%', top: '-3%' }}>
                        X
                    </span>
                </div>
                <div
                    style={{
                        display: 'flex',
                        justifyContent: 'space-between',
                        alignItems: 'flex-start',
                        width: '100%'
                        // padding:'10px'
                    }}
                >
                    <div style={{ display: 'block', marginLeft: '45px' }}>
                        {data?.name ? (
                            <h6 style={{ color: '#DADCEE', fontSize: '16px', fontFamily: 'Montserrat' }}>
                                {data?.name}
                            </h6>
                        ) : null}
                        {type === 'Role' && (
                            <p style={{ color: '#DADCEE', fontSize: '13px', fontFamily: 'Montserrat' }}>
                                {data?.EmailId} <span>&nbsp;</span>| {data?.PhoneNo?.substring(0, 3)}{' '}
                                {data?.PhoneNo?.slice(-10)}
                            </p>
                        )}
                        {type === 'Contact' && (
                            <>
                                <div style={{ display: 'flex', flexDirection: 'row' }}>
                                    <div>
                                        <p
                                            style={{
                                                color: '#DADCEE',
                                                fontSize: '16px',
                                                fontFamily: 'Montserrat',
                                                marginBottom: '0px'
                                            }}
                                        >
                                            {data?.UserName}
                                        </p>
                                        <p style={{ color: '#DADCEE', fontSize: '13px', fontFamily: 'Montserrat' }}>
                                            SPOC {data?.SPOCType}
                                        </p>
                                    </div>
                                    <div style={{ marginLeft: '15px' }}>
                                        <Rating
                                            value={data?.FavoriteUserFlag === 'Y' ? 1 : 0}
                                            markKey={RCUserKey}
                                            unMarkKey={RCFUKey}
                                            handleChange={favoriteUserAction}
                                        />
                                    </div>
                                </div>
                            </>
                        )}
                        {type === 'Client' && (
                            <p style={{ color: '#DADCEE', fontSize: '13px', fontFamily: 'Montserrat' }}>
                                {data?.details?.name}
                            </p>
                        )}
                        {type === 'Plant' && (
                            <p style={{ color: '#DADCEE', fontSize: '13px', fontFamily: 'Montserrat' }}>
                                {data?.details?.PlantName}
                            </p>
                        )}
                    </div>{' '}
                    <div style={{ display: 'block', marginLeft: '45px' }}>
                        {/* <h6
                            style={{
                                color: '#DADCEE',
                                fontSize: '16px',
                                fontFamily: 'Montserrat',
                                paddingBottom: type === 'all' ? '10px' : '0px'
                            }}
                        >
                            {data?.PhoneNo}
                        </h6> */}

                        {type === 'Contact' ? (
                            <>
                                <div style={{ marginBottom: '6px' }}>
                                    <UserMoreMenu click={editFn} remove={() => removeFn(data)} dtlMode={true} />
                                </div>
                            </>
                        ) : (
                            <UserMoreMenu click={editFn} remove={removeFn} dtlMode={true} />
                        )}
                        {type === 'all' && (
                            <div style={{ marginBottom: '10px' }}>
                                <Popover
                                    itemsArray={[
                                        {
                                            userName: 'test'
                                        }
                                    ]}
                                    src={CallSpoc}
                                    infoOnly={false}
                                    spocs={true}
                                    children={
                                        <SPOCDetails
                                            spocs={stateVal?.[STORE_KEYS.SPOCS]?.body?.data}
                                            editable={false}
                                        />
                                    }
                                />
                            </div>
                        )}
                        {type === 'Role' && (
                            <p style={{ color: '#DADCEE', fontSize: '13px', fontFamily: 'Montserrat' }}>
                                freduardo.hill@acg-world.com <span>&nbsp;</span>| +91 9988029282
                            </p>
                        )}
                        {type === 'Client' && (
                            <p style={{ color: '#DADCEE', fontSize: '13px', fontFamily: 'Montserrat' }}>
                                {data?.details?.name}
                            </p>
                        )}
                        {type === 'Plant' && (
                            <p style={{ color: '#DADCEE', fontSize: '13px', fontFamily: 'Montserrat' }}>
                                {data?.details?.PlantName}
                            </p>
                        )}
                    </div>{' '}
                    {(type === 'all' || type === 'Role') && (
                        <div style={{ marginTop: type === 'Role' ? '7px' : '0px' }}>
                            <Label variant="outlined" color="success">
                                {sentenceCase('Active')}
                            </Label>
                        </div>
                    )}
                    {type === 'Feature' ? (
                        <div style={{ marginTop: '-7px', marginBottom: '6px' }}>
                            <UserMoreMenu click={editFn} remove={deleteFn} block={block} dtlMode={true} />
                        </div>
                    ) : null}
                </div>
            </div>
            {type === 'all' ? (
                <div>
                    <div className="userDetailDiv alarmDivTop">
                        <div className={'detailDivChild firstChild'}>
                            <p>Model / Line no / Serial Number</p>
                            <h6>{data?.line}</h6>
                        </div>
                        <div className={'detailDivChild'}>
                            <p>Client</p>
                            <h6>{data?.location}</h6>
                        </div>
                    </div>
                    <div className="userDetailDiv alarmDivBottom">
                        <div className={'detailDivChild'}>
                            <p>Severity</p>
                            <Label variant="outlined" color={data?.status?.toLowerCase() || 'warning'}>
                                {sentenceCase(data?.status || '')}
                            </Label>
                        </div>
                    </div>
                </div>
            ) : (
                <div className="userDetailDiv">
                    <div className="detailDivChild firstChild">
                        <p>Email ID</p>
                        <h6>{data?.EmailId}</h6>
                    </div>
                    <div className="detailDivChild firstChild">
                        <p>Primary number</p>
                        <h6>{data?.PhoneNo}</h6>
                    </div>
                    <div className={'detailDivChild'}>
                        <p>Secondary number</p>
                        <h6>{data?.AlternatePhoneNo}</h6>
                        {/* <Label variant="outlined" color={data?.status?.toLowerCase() || 'warning'}>
                            {sentenceCase(data?.status || '')}
                        </Label> */}
                    </div>
                </div>
            )}

            <br />
            <ContactDetails data={data} usedIn={usedIn} />

            {type === 'Plant' ? <SearchBar /> : null}
        </Box>
    );

    return (
        <div>
            <SnackbarAlert options={snackbarOptions} handleClose={closeSnackbar} />
            <Drawer
                anchor={'right'}
                open={status}
                // onClose={handlerClose}
                onClose={handleClose}
                style={{ zIndex: '9999' }}
                className={'alarmDetailDrawerDiv'}
            >
                {list()}
            </Drawer>
        </div>
    );
}
