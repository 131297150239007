import { styled } from '@material-ui/core';

const StyledLabel = styled('label')((props: any) => {
    return {
        fontSize: 10,
        fontWeight: 600,
        marginBottom: 0,
        color: '#98BCF8'
    };
});

const DetailLabel = (props: any) => {
    const { label = '', ...rest } = props;
    return <StyledLabel {...rest}>{label}</StyledLabel>;
};

export default DetailLabel;
