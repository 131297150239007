import { Card } from '@mui/material';
import Typography from '@mui/material/Typography';
import { Box } from '@material-ui/core';
import TotalClients from '../../../assets/images/TotalClients.svg';
import Grid from '@mui/material/Grid';
import './DashboardStatus.scss';

type ComponentProps = {
    totalClients: any;
    // totalPlants: {
    //   count: Number;
    //   handleClick: Function;
    //   image?:any;
    //   text?:any
    // };
    // totalMachines: {
    //   count: Number;
    //   handleClick: Function;
    //   image?:any;
    //   text?:any
    // };
    type?: string;
};
// totalPlants, totalMachines,
const StatusCard = (props: ComponentProps) => {
    const { totalClients, type } = props;

    const showCard = totalClients.map((ele: any, index: any) => {
        const ind = index + 1;
        return (
            <Grid className="statuscard_clmn" item sm={6} xs={12} md={6} lg={3} xl={2.5} key={ind}>
                <div onClick={() => ele.handleClick()}>
                    <Card className="TotalCard statuscard">
                        <Box className="statuscard_img">
                            <img src={ele.image} alt="null" data-testid="profileImg1" className="img" />
                        </Box>

                        <Box className="statuscard_txt">
                            <Box style={{ flex: '70%' }}>
                                <Typography className="property">{ele.text}</Typography>
                            </Box>
                            <Box>
                                <div
                                    style={{
                                        height: '100%',
                                        width: '75px',
                                        padding: '18px'
                                    }}
                                    className={'ColorDiv' + index}
                                >
                                    <Typography className="value" data-testid="totalV">
                                        {ele.count}
                                    </Typography>
                                </div>
                            </Box>
                        </Box>
                    </Card>
                </div>
            </Grid>
        );

        // console.log(ele)
    });
    return (
        <div>
            <Box sx={{ flexGrow: 1 }}>
                <Grid className="statuscard_row" container spacing={2}>
                    {showCard}
                    {/* <Grid
            className="statuscard_clmn"
            item
            sm={6}
            xs={12}
            md={6}
            lg={2.5}
            xl={2.5}
          >
            <div onClick={() => totalClients.handleClick()}>
              <Card className="TotalCard statuscard">
                <Box className="statuscard_img">
                  <img
                    src={totalClients.image}
                    alt="null"
                    data-testid="profileImg1"
                    className="img"
                  />
                </Box>

                <Box className="statuscard_txt">
                  <Box style={{ flex: "70%" }}>
                    <Typography className="property">{totalClients.text}</Typography>
                  </Box>
                  <Box>
                    <div
                      style={{
                        height: "100%",
                        width: "75px",
                        padding: "18px",
                        // backgroundColor: "rgb(203, 184, 134)",
                        backgroundColor: "rgb(255 170 134 / 8%)",
                      }}
                    >
                      <Typography className="value" data-testid="totalV">
                        {totalClients.count}
                      </Typography>
                    </div>
                  </Box>
                </Box>
              </Card>
            </div>
          </Grid> */}

                    {/* <Grid
            className="statuscard_clmn"
            item
            sm={6}
            xs={12}
            md={6}
            lg={2.5}
            xl={2.5}
          >
            <div onClick={() => totalPlants.handleClick()}>
              <Card className="TotalCard statuscard">
                <Box className="statuscard_img">
                  <img
                    src={TotalClients}
                    alt="null"
                    data-testid="profileImg1"
                    className="img"
                  />
                </Box>

                <Box className="statuscard_txt">
                  <Box style={{ flex: "70%" }}>
                    <Typography className="property">Total Plants</Typography>
                  </Box>
                  <Box>
                    <div
                      style={{
                        height: "100%",
                        width: "75px",
                        padding: "18px",
                        backgroundColor: "rgb(203 184 134 / 10%)",
                      }}
                    >
                      <Typography className="value" data-testid="totalV">
                        {totalPlants.count}
                      </Typography>
                    </div>
                  </Box>
                </Box>
              </Card>
            </div>
          </Grid>

          <Grid
            className="statuscard_clmn"
            item
            sm={6}
            xs={12}
            md={6}
            lg={2.5}
            xl={2.5}
          >
            <div onClick={() => totalMachines.handleClick()}>
              <Card className="TotalCard statuscard">
                <Box className="statuscard_img">
                  <img
                    src={TotalClients}
                    alt="null"
                    data-testid="profileImg1"
                    className="img"
                  />
                </Box>

                <Box className="statuscard_txt">
                  <Box style={{ flex: "70%" }}>
                    <Typography className="property">Total Machines</Typography>
                  </Box>
                  <Box>
                    <div
                      style={{
                        height: "100%",
                        width: "75px",
                        padding: "18px",
                        backgroundColor: "rgb(173 134 255 / 10%)",
                      }}
                    >
                      <Typography className="value" data-testid="totalV">
                        {totalMachines.count}
                      </Typography>
                    </div>
                  </Box>
                </Box>
              </Card>
            </div>
          </Grid>

          <Grid
            className="statuscard_clmn"
            item
            sm={6}
            xs={12}
            md={6}
            lg={2.5}
            xl={2.5}
          >
            <div onClick={() => totalMachines.handleClick()}>
              <Card className="TotalCard statuscard">
                <Box className="statuscard_img">
                  <img
                    src={TotalClients}
                    alt="null"
                    data-testid="profileImg1"
                    className="img"
                  />
                </Box>

                <Box className="statuscard_txt">
                  <Box style={{ flex: "70%" }}>
                    <Typography className="property">Total Machines</Typography>
                  </Box>
                  <Box>
                    <div
                      style={{
                        height: "100%",
                        width: "75px",
                        padding: "18px",
                        backgroundColor: "rgb(173 134 255 / 10%)",
                      }}
                    >
                      <Typography className="value" data-testid="totalV">
                        {totalMachines.count}
                      </Typography>
                    </div>
                  </Box>
                </Box>
              </Card>
            </div>
          </Grid> */}
                </Grid>
            </Box>
        </div>
    );
};
export default StatusCard;
