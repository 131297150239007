import React, { useState, useEffect } from 'react';
import { useDispatch } from 'react-redux';
import { useLocation } from 'react-router-dom';
import CustomTable from '../../../../atoms/CustomTable/index';
import CustomTableToolbar from '../../../../atoms/CustomTableToolbar';
import { Container, Card } from '@mui/material';
import TemporaryDrawer from '../../../../atoms/tempDrawer';
import { ACTION_CODES, STORE_KEYS } from '../../../../constants/apiConstants';
import { executeACGAction, resetErr, resetStoreKey, updateScreenIdentifiers } from '../../../../store/slice';
import useGetState from '../../../../utils/hooks/useGetState';
import { applySortFilter } from '../../../../utils/helper';
import Axios from 'axios';
import SnackbarAlert from '../../../../atoms/Snackbar';
import AlertBox from '../../../../atoms/AlertBox';
import DetailDrawer from '../../../../atoms/DetailDrawer';
import { formatAccordionData } from '../../../../utils/formatData';
import ContactDetailDrawer from '../../../../atoms/ContactDetailDrawer';

const TABLE_HEAD = [
    { id: 'AssemblyName', label: 'Assembly Name', alignRight: false },
    { id: 'ComponentName', label: 'Component Name', alignRight: false },
    { id: 'CalendarLifeInHours', label: 'Calendar Life In Hours', alignRight: false },
    { id: 'AlertsBasisOperatingHours', label: 'Alerts Basis Operating Hours', alignRight: false },
    { id: 'OperatingLifeInHours', label: 'Operating Life In Hours', alignRight: false },
    { id: 'ReplacementAlertInHours', label: 'Replacement Alert In Hours', alignRight: false },
    { id: 'PostDueReminderInHours', label: 'Post Due Reminder In Hours', alignRight: false },
    { id: 'Action', label: 'Action', alignLeft: true }
];

const options = {
    DEFAULT: {
        message: '',
        open: false,
        type: ''
    },
    RM_SUCCESS: {
        message: 'Component Life Cycle Entry Deleted',
        open: true,
        type: 'success'
    },
};

type Anchor = 'right';

const Users = (props: any) => {
    const {
        CLCData,
        radioValue,
        selectedClient,
        setDtlData,
        dtlData,
        setReload,
        reload,
        selectedRow,
        setSelectedRow,
        setCreateValue,
        setDrawerHeader,
        setCreateDrawerOpen: setEditDrawerOpen,
        setComponentLSKey,
        componentLSKey,
        setNewTrialNotification,
        deleteCLC,
        appliedFilters,
        clearFilter,
        setAlertType,
        getCLCList,
        machineModelData,
        filteredMachine,
        setAppliedFilters,
        resetFilter,
        isLoading
    } = props;

    const dispatch = useDispatch();
    const [drawerState, setDrawerState] = useState({
        right: false
    });
    const [page, setPage] = useState<number>(0);
    const [order, setOrder] = useState('desc');
    const [orderBy, setOrderBy] = useState('TrialId');
    const [selected, setSelected] = useState<any>([]);
    const [filterName, setFilterName] = useState<string>('');
    const [tableData, setTableData] = useState<any[]>([]);
    const [createDrawerOpen, setCreateDrawerOpen] = useState(false);

    const state = useGetState();
    const token = state?.[STORE_KEYS.USER_DTL].token;
    const getLoggedUserId = state[STORE_KEYS.USER_DTL]?.LoggedUserKey;
    const currentPath = useLocation();
    const accessData = state?.[STORE_KEYS.ROUTES_PRIVILEGES];
    const isAccess = (__data: any) => {
        return __data.filter((d: any) => d.path == currentPath?.pathname)?.[0]?.isEditable;
    };
    useEffect(() => {
        if (reload) {
            setReload(false);
            setTableData([]);
        }
    }, [reload]);

    const [snackbarOptions, setSnackbarOptions] = useState(options.DEFAULT);
    const [removeBxOpen, setRemoveBxOpen] = useState(false);
    const [rmUser, setRmUser] = useState(false);
    const [RNVKey, setRNVKey] = useState<any>();

    useEffect(() => {
        if (rmUser) {
            closeRmBx();
            deleteCLC(RNVKey)
            setRmUser(false);
        }
    }, [rmUser]);

    const closeRmBx = () => {
        setRemoveBxOpen(false);
    };
    const removeFn = async (row: any) => {
        const r = await { ...row }
        setSelectedRow(r);
        setRNVKey(r?.MCLCModelComponentKey);
        setRemoveBxOpen(true);
    };

    const handleRequestSort = (event: any, property: any) => {
        const isAsc = orderBy === event && order === 'asc';
        setOrder(isAsc ? 'desc' : 'asc');
        setOrderBy(event);
    };

    const handleSelectAllClick = (event: any) => {
        if (event.target.checked) {
            const newSelecteds = [];
            for (let i = 0; i < CLCData.length; i++) {
                newSelecteds.push(CLCData[i].id);
            }
            const toRemove = CLCData.slice(page, CLCData.length);
            setSelected(newSelecteds);
            return;
        }
        setSelected([]);
    };

    const handleClick = (requestedBy: string) => {
        const selectedIndex = selected.indexOf(requestedBy);
        let newSelected: any[] | ((prevState: unknown[]) => unknown[]) = [];
        if (selectedIndex === -1) {
            newSelected = newSelected.concat(selected, requestedBy);
        } else if (selectedIndex === 0) {
            newSelected = newSelected.concat(selected.slice(1));
        } else if (selectedIndex === selected.length - 1) {
            newSelected = newSelected.concat(selected.slice(0, -1));
        } else if (selectedIndex > 0) {
            newSelected = newSelected.concat(selected.slice(0, selectedIndex), selected.slice(selectedIndex + 1));
        }
        setSelected(newSelected);
    };

    const toggleDrawer = (anchor: Anchor, open: boolean) => (event: React.KeyboardEvent | React.MouseEvent) => {
        if (
            event.type === 'keydown' &&
            ((event as React.KeyboardEvent).key === 'Tab' || (event as React.KeyboardEvent).key === 'Shift')
        ) {
            return;
        }
        setDrawerState({ ...drawerState, [anchor]: open });
    };

    const filteredList = applySortFilter(CLCData, order, filterName, orderBy);

    const handleFilterByName = (event: { target: { value: string } }) => {
        setPage(0);
        setFilterName(event.target.value);
        setOrderBy('WorkOrderID');
    };

    const handleChangePage = (event: any, newPage: React.SetStateAction<number>) => {
        setPage(newPage);
    };

    const showRow = (row: any) => {
        // setSelectedRow(row);
        // setDrawerHeader('View');
        // setAlertType("");
    };

    const handleSnackbarError = (err: any) => {
        const errorMsg = err?.message || 'Internal Server error';
        const snackbarError = {
            message: errorMsg,
            type: 'reject',
            open: true
        };
        setSnackbarOptions(snackbarError);
    };

    useEffect(() => {
        if (state?.err) {
            handleSnackbarError(state?.err);
            dispatch(resetErr());
        }
    }, [state?.err]);

    const handleRoute = (row: any, ele: any) => {
        if (ele === 'Action') {
            setSelectedRow({ ...row });
            props.editTrail(row?.TrialKey);
        }
    };

    const closeSnackbar = () => setSnackbarOptions(options.DEFAULT);
    const removeMd = (ele: any) => {
        // setMdKey(ele?.ModuleKey);
        setRemoveBxOpen(true);
    };
    const resetInput = () => {
        setFilterName('');
    };

    return (
        <div className='modelComponents'>
            <div>
                <SnackbarAlert options={snackbarOptions} handleClose={closeSnackbar} />
                <Container
                    maxWidth="xl"
                    style={{
                        paddingLeft: '3px',
                        paddingRight: '3px'
                    }}
                >
                    <Card
                        style={{
                            borderRadius: '0px',
                            backgroundColor: '#2b2d42',
                            overflow: 'inherit'
                        }}
                    >
                        <CustomTableToolbar
                            drawerOpen={() => {
                                setAlertType("");
                                setEditDrawerOpen(true);
                                setDrawerHeader("Filter");
                            }}
                            numSelected={selected?.length}
                            filterName={filterName}
                            onFilterName={handleFilterByName}
                            resetInput={resetInput}
                            displayInput={true}
                            text={{
                                title: 'Model Component List ',
                                subTitle: `${filteredList.length} Model Component`
                            }}
                            filterButton={false}
                            customButton={false}
                            appliedFilters={appliedFilters}
                            clearFilter={clearFilter}
                            resetFilter={resetFilter}
                            clc={true}
                            getCLCList={getCLCList}
                            machineModelData={machineModelData}
                            filteredMachine={filteredMachine}
                            setAppliedFilters={setAppliedFilters}
                            placeholder={"Search by Assembly Name & Component Name"}
                        />
                    </Card>
                </Container>
                <CustomTable
                    selectable={false}
                    headers={isAccess(accessData) ? TABLE_HEAD : TABLE_HEAD.slice(0, -1)}
                    body={filteredList}
                    isLoading={isLoading}
                    filteredUsers={filteredList}
                    filterName={filterName}
                    selected={selected}
                    isTableImageReqd={true}
                    order={order}
                    orderBy={orderBy}
                    onRequestSort={handleRequestSort}
                    onSelectAllClick={handleSelectAllClick}
                    rowChange={(event: string) => handleClick(event)}
                    rowCount={filteredList.length}
                    onPageChange={handleChangePage}
                    page={page}
                    setPageNumber={setPage}
                    selectRow={showRow}
                    route={handleRoute}
                    isRequestPage={true}
                    link={true}
                    removeFn={removeFn}
                    {...(radioValue === 'Customer' && !selectedClient
                        ? { noDataText: 'Select Client to display users' }
                        : {})}
                    workflow={false}
                    clc={true}
                    editFn={async (row: any, type: string) => {
                        props.setAlertType("");
                        setSelectedRow(row);
                        setComponentLSKey(row.MCLCModelComponentKey);
                        setDrawerHeader(type);
                        props.drawerOpen(true);
                        setCreateValue('');
                    }}
                />
            </div>
            <AlertBox
                isOpen={removeBxOpen}
                handleClose={closeRmBx}
                leftBtnPress={closeRmBx}
                rightBtnPress={() => setRmUser(true)}
                modalHeader="Delete Component Life Cycle"
                modalContent="Are you sure you want to delete component life cycle entry?"
            />

        </div>
    );
};

export default Users;
