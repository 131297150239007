import { useState, useEffect } from 'react';
import { Card, Grid } from '@mui/material';
import DialerIcon from '../../../../../../assets/images/Dialer.svg';
import ACGButton from '../../../../../atoms/Button';
import UserMoreMenu from '../../../../../atoms/CustomTableMenu';
import RightDrawer from '../../../../../atoms/tempDrawer/indexAlt';
import './SpocDetail.scss';
import AddContact from './AddSpoc/index';
import SnackbarAlert from '../../../../../atoms/Snackbar';
import { useDispatch } from 'react-redux';
import { executeACGAction, updateScreenIdentifiers } from '../../../../../store/slice';
import { ACTION_CODES, STORE_KEYS } from '../../../../../constants/apiConstants';
import useGetState from '../../../../../utils/hooks/useGetState';
import AlertBox from '../../../../../atoms/AlertBox';
import EditContact from './EditSpoc';

type Props = { spocs: any; machineKey?: any; setReload?: any; editable?: Boolean };
const options = {
    DEFAULT: {
        message: '',
        open: false,
        type: ''
    },
    RM_SUCCESS: {
        message: 'Spoc deleted successfully!',
        open: true,
        type: 'success'
    }
};
const SPOCDetails = (props: Props) => {
    const { spocs, machineKey, setReload = () => {}, editable = true } = props;
    const [drawerOpen, setDrawerOpen] = useState(false);
    const [snackbarOptions, setSnackbarOptions] = useState(options.DEFAULT);
    const [spocType, setSpocType] = useState('ACG');
    const closeSnackbar = () => setSnackbarOptions(options.DEFAULT);
    const [rmSpoc, setRmSpoc] = useState(false);
    const [spocKey, setSpocKey] = useState<any>('');
    const [removeBxOpen, setRemoveBxOpen] = useState(false);
    const [spocDtl, setSpocDtl] = useState({});
    const [drawerHeader, setDrawerHeader] = useState('Add');
    const dispatch = useDispatch();
    const stateObj = useGetState();
    const closeRmBx = () => {
        setRemoveBxOpen(false);
    };
    useEffect(() => {
        if (rmSpoc) {
            closeRmBx();
            dispatch(
                executeACGAction({
                    payload: {
                        requestType: 'DELETE',
                        urlPath: `${ACTION_CODES.DELETE_SPOC}/${spocKey}`
                    },
                    uniqueScreenIdentifier: {
                        spocRemoved: true
                    },
                    storeKey: STORE_KEYS.DELETE_RPT
                })
            );
            setRmSpoc(false);
        }
    }, [rmSpoc]);

    useEffect(() => {
        if (stateObj?.[STORE_KEYS.DELETE_RPT]?.spocRemoved) {
            if (stateObj?.[STORE_KEYS.DELETE_RPT]?.statusCode === 200) {
                setSnackbarOptions(options.RM_SUCCESS);
                setReload(true);
            }
            dispatch(
                updateScreenIdentifiers({
                    storeKey: STORE_KEYS.DELETE_RPT,
                    uniqueScreenIdentifier: {
                        spocRemoved: false
                    }
                })
            );
        }
    }, [stateObj?.[STORE_KEYS.DELETE_RPT]?.spocRemoved]);

    return (
        <div className="spocDetails">
            <SnackbarAlert options={snackbarOptions} handleClose={closeSnackbar} />
            {spocs
                ?.filter((spoc: any) => spoc?.SPOCType == 'ACG')
                ?.map((ele: any, idx: any) => {
                    const index = idx + 1;
                    return (
                        <div className="configureRowChild marginBtm" key={index}>
                            <h5 style={{ color: '#9CC2FF', fontSize: '10px' }}>{ele?.title}</h5>
                            <Card className="spocCard">
                                <div style={{ display: 'flex', justifyContent: 'left' }}>
                                    <div
                                        style={{
                                            height: '32px',
                                            width: '32px',
                                            borderRadius: '50%',
                                            backgroundColor: '#5D97F6',
                                            marginRight: '8px',
                                            minWidth: '32px',
                                        }}
                                    ></div>
                                    <div style={{ flex: '70%' }}>
                                        <h5 style={{ fontSize: '12px', color: '#DADCEE', marginBottom: '2px' }}>
                                            {ele?.SpocName}
                                        </h5>
                                        <p
                                            style={{
                                                fontSize: '10px',
                                                color: 'rgb(177 194 223 / 80%)',
                                                marginBottom: '12px'
                                            }}
                                        >
                                            {ele?.EmailId}
                                        </p>
                                    </div>
                                    {editable && (
                                        <div style={{ marginTop: '-7px', marginBottom: '6px' }}>
                                            <UserMoreMenu
                                                onlyDeleteMode={ele?.SpocLevel == 'Machine' ? false : true}
                                                click={() => {
                                                    setSpocType('ACG');
                                                    setDrawerHeader('Edit');
                                                    setSpocDtl(ele);
                                                    setDrawerOpen(true);
                                                }}
                                                remove={() => {
                                                    setSpocKey(ele?.RCUserKey);
                                                    setRemoveBxOpen(true);
                                                }}
                                                dtlMode={true}
                                            />
                                        </div>
                                    )}
                                </div>
                                <Grid container spacing={2}>
                                    <Grid item xs={12} sm={12} md={4} xl={4} lg={4}>
                                        <div style={{ display: 'flex', justifyContent: 'space-evenly' }}>
                                            <div style={{ flex: '30%' }}>
                                                <p style={{ fontSize: '12px', color: '#9CC2FF', marginBottom: '2px' }}>
                                                    Primary Contact
                                                </p>
                                                <p style={{ fontSize: '12px', color: '#B1C2DF', marginBottom: '2px' }}>
                                                    {ele?.PrimaryContact}
                                                    <a href={`tel:${ele?.PrimaryContact}`}>
                                                        <img src={DialerIcon} alt="Dial" />
                                                    </a>
                                                </p>
                                            </div>
                                        </div>
                                    </Grid>
                                    <Grid item xs={12} sm={12} md={4} xl={4} lg={4}>
                                        <div
                                            style={{
                                                display: 'flex',
                                                justifyContent: 'space-evenly'
                                            }}
                                        >
                                            <div style={{ flex: '30%' }}>
                                                <p style={{ fontSize: '12px', color: '#9CC2FF', marginBottom: '2px' }}>
                                                    Secondary Contact
                                                </p>
                                                <p style={{ fontSize: '12px', color: '#B1C2DF', marginBottom: '2px' }}>
                                                    {ele?.SecondaryContact}
                                                    <a href={`tel:${ele?.SecondaryContact}`}>
                                                        <img src={DialerIcon} alt="Dial" />
                                                    </a>
                                                </p>
                                            </div>
                                        </div>
                                    </Grid>
                                </Grid>
                            </Card>
                        </div>
                    );
                })}
            {editable && (
                <div className="btnDiv">
                    <ACGButton
                        name={'Add ACG Spoc'}
                        type="button"
                        variant="contained"
                        handleClick={() => {
                            setSpocType('ACG');
                            setDrawerHeader('Add');
                            setDrawerOpen(true);
                        }}
                        className={'addContact'}
                    />
                </div>
            )}
            {spocs
                ?.filter((spoc: any) => spoc?.SPOCType != 'ACG')
                ?.map((ele: any) => {
                    return (
                        <div className="configureRowChild marginBtm">
                            <h5 style={{ color: '#9CC2FF', fontSize: '10px' }}>{ele?.title}</h5>
                            <Card className="spocCard">
                                <div style={{ display: 'flex', justifyContent: 'left' }}>
                                    <div
                                        style={{
                                            height: '32px',
                                            width: '32px',
                                            borderRadius: '50%',
                                            backgroundColor: '#5D97F6',
                                            marginRight: '8px',
                                            minWidth: '32px'
                                        }}
                                    ></div>
                                    <div style={{ flex: '70%' }}>
                                        <h5 style={{ fontSize: '12px', color: '#DADCEE', marginBottom: '2px' }}>
                                            {ele?.SpocName}
                                        </h5>
                                        <p
                                            style={{
                                                fontSize: '10px',
                                                color: 'rgb(177 194 223 / 80%)',
                                                marginBottom: '12px'
                                            }}
                                        >
                                            {ele?.EmailId}
                                        </p>
                                    </div>
                                    {editable && (
                                        <div style={{ marginTop: '-7px', marginBottom: '6px' }}>
                                            <UserMoreMenu
                                                onlyDeleteMode={ele?.SpocLevel == 'Machine' ? false : true}
                                                click={() => {
                                                    setSpocType('Customer');
                                                    setDrawerHeader('Edit');
                                                    setSpocDtl(ele);
                                                    setDrawerOpen(true);
                                                }}
                                                remove={() => {
                                                    setSpocKey(ele?.RCUserKey);
                                                    setRemoveBxOpen(true);
                                                }}
                                                dtlMode={true}
                                            />
                                        </div>
                                    )}
                                </div>
                                <Grid container spacing={2}>
                                    <Grid item xs={12} sm={12} md={6} xl={6} lg={6}>
                                        <div style={{ display: 'flex', justifyContent: 'space-evenly' }}>
                                            <div style={{ flex: '30%' }}>
                                                <p style={{ fontSize: '12px', color: '#9CC2FF', marginBottom: '2px' }}>
                                                    Primary Contact
                                                </p>

                                                <p style={{ fontSize: '12px', color: '#B1C2DF', marginBottom: '2px' }}>
                                                    {ele?.PrimaryContact}
                                                    <a href={`tel:${ele?.PrimaryContact}`}>
                                                        <img src={DialerIcon} alt="Dial" />
                                                    </a>
                                                </p>
                                            </div>
                                        </div>
                                    </Grid>
                                    <Grid item xs={12} sm={12} md={6} xl={6} lg={6}>
                                        <div
                                            style={{
                                                display: 'flex',
                                                justifyContent: 'space-evenly'
                                            }}
                                        >
                                            <div style={{ flex: '30%' }}>
                                                <p style={{ fontSize: '12px', color: '#9CC2FF', marginBottom: '2px' }}>
                                                    Secondary Contact
                                                </p>

                                                <p
                                                    style={{
                                                        fontSize: '12px',
                                                        color: '#B1C2DF',
                                                        marginBottom: '2px'
                                                    }}
                                                >
                                                    {ele?.SecondaryContact}{' '}
                                                    <a href={`tel:${ele?.SecondaryContact}`}>
                                                        <img src={DialerIcon} alt="Dial" />
                                                    </a>
                                                </p>
                                            </div>
                                        </div>
                                    </Grid>
                                </Grid>
                            </Card>
                        </div>
                    );
                })}
            {editable && (
                <div className="btnDiv">
                    <ACGButton
                        name={'Add Cust Spoc'}
                        type="button"
                        variant="contained"
                        handleClick={() => {
                            setSpocType('Customer');
                            setDrawerHeader('Add');
                            setDrawerOpen(true);
                        }}
                        className={'addContact'}
                    />
                </div>
            )}
            <AlertBox
                isOpen={removeBxOpen}
                handleClose={closeRmBx}
                leftBtnPress={closeRmBx}
                rightBtnPress={() => setRmSpoc(true)}
                modalHeader="Delete Spoc"
                modalContent="Are you sure you want to delete spoc?"
            />
            <RightDrawer
                handlerOpen={() => {}}
                handlerClose={() => setDrawerOpen(false)}
                status={drawerOpen}
                drawerHeader={`${drawerHeader} Contact`}
                children={
                    drawerHeader === 'Add' ? (
                        <AddContact
                            drawerClose={() => setDrawerOpen(false)}
                            setReload={setReload}
                            setSnackbarOpts={setSnackbarOptions}
                            data={{ machineKey: machineKey, spocType: spocType }}
                        />
                    ) : (
                        <EditContact
                            drawerClose={() => setDrawerOpen(false)}
                            setReload={setReload}
                            setSnackbarOpts={setSnackbarOptions}
                            data={{ machineKey: machineKey, spocType: spocType, spocDtl: spocDtl }}
                        />
                    )
                }
            />
        </div>
    );
};

export default SPOCDetails;
