import { useState, useEffect } from "react";
import * as React from 'react';
import CustomTable from "../../../atoms/CustomTable/index";
import CustomTableToolbar from "../../../atoms/CustomTableToolbar";
import { Container, Card } from "@mui/material";
import useGetState from "../../../utils/hooks/useGetState";
import { useDispatch } from "react-redux";
import { executeACGAction, reset, resetStoreKey, updateScreenIdentifiers } from "../../../store/slice";
import { ACTION_CODES, STORE_KEYS } from "../../../constants/apiConstants";
import PowerBIViewDrawer from "../DetailDrawer/Index";
import { models } from "powerbi-client";
import DarkTheme from '../../../../themes/Dark.json';
import Axios from 'axios'
import SnackbarAlert from "../../../atoms/Snackbar";
import { report } from "process";

export interface LocaleSettings {
  language: string;
  formatLocale: string;
}

export interface Settings {
  filterPaneEnabled: boolean;
  navContentPaneEnabled: boolean;
  localeSettings: LocaleSettings;
}

export interface ReportConfig {
  type: "report";
  embedUrl?: string;
  tokenType: models.TokenType;
  permissions: models.Permissions;
  accessToken?: string;
  settings: Settings;
  name?: string;
  theme: {
    themeJson: object;
  };
  viewMode: models.ViewMode;
}

const options = {
  DEFAULT: {
    message: '',
    open: false,
    type: ''
  },
  RM_SUCCESS: {
    message: 'Plant deleted successfully!',
    open: true,
    type: 'success'
  }
};

const UserReports = () => {

  const [page, setPage] = useState<number>(0);
  const [filterName, setFilterName] = useState<string>("");
  const [tableData, setTableData] = useState<any[]>([]);
  const [masterTableData, setMasterTableData] = useState<any[]>([]);
  const [createDrawerOpen, setCreateDrawerOpen] = useState(false);
  const [selectedRow, setSelectedRow] = useState<any>(null);
  const [selectedIndex, setSelectedIndex] = useState<number>(-1);
  const [snackbarOptions, setSnackbarOptions] = useState(options.DEFAULT);
  const [isReportLoading,setIsReportLoading]=useState(false)

  const dispatch = useDispatch();

  const stateObj = useGetState();

  const [ReportConfig, setReportConfig] = useState<ReportConfig>({
    type: "report",
    tokenType: models.TokenType.Embed,
    permissions: models.Permissions.All,
    settings: {
      filterPaneEnabled: false,
      navContentPaneEnabled: false,
      localeSettings: {
        language: "en",
        formatLocale: "en-gb",
      },
    },
    theme: { themeJson: { DarkTheme } },
    viewMode: models.ViewMode.View,
    accessToken: '',
    embedUrl: ''
  });

  const handleChangePage = (
    event: any,
    newPage: React.SetStateAction<number>
  ) => {
    setPage(newPage);
  };

  const handleSnackbarError = (err: any) => {
    const errorMsg = err?.message || 'Invalid configuration';
    const snackbarError = {
      message: errorMsg,
      type: 'reject',
      open: true
    };
    setSnackbarOptions(snackbarError);
  };

  const closeSnackbar = () => setSnackbarOptions(options.DEFAULT);

  const getReports = () => {
    setIsReportLoading(true)
    dispatch(
      executeACGAction({
        payload: {
          requestType: 'GET',
          urlPath: ACTION_CODES.GET_RP_USER_REPORTS
        },
        uniqueScreenIdentifier: { rptsLoaded: true },
        storeKey: STORE_KEYS.GET_RP_USER_REPORTS
      })
    );
  };

  useEffect(() => {
    if (stateObj?.[STORE_KEYS.GET_RP_USER_REPORTS]?.rptsLoaded) {
      const rptsData = stateObj?.[STORE_KEYS.GET_RP_USER_REPORTS]?.body?.data;
      const setData = rptsData?.map((rpt: any, index: any) => {
        const record = { ...rpt };
        record.MachineModelName = record?.ReportName;
        return record;
      });

      setTableData(setData);
      setMasterTableData([...setData]);

      dispatch(
        updateScreenIdentifiers({
          storeKey: STORE_KEYS.GET_RP_USER_REPORTS,
          uniqueScreenIdentifier: {
            rptsLoaded: false
          }
        })
      );
      setIsReportLoading(false)
    }
  }, [stateObj?.[STORE_KEYS.GET_RP_USER_REPORTS]?.rptsLoaded]);

  const handleFilterByName = (event: { target: { value: React.SetStateAction<string> } }) => {

    setPage(0);

    if (event.target.value === undefined) //for clear button action
    {
      setFilterName("");
      setTableData(masterTableData);
    }
    else {
      setFilterName(event.target.value);
      const filteredArray = event.target.value.length > 0 ?
      masterTableData.filter(obj => obj.ReportName.toLowerCase().includes(String(event.target.value).toLowerCase())) :
        masterTableData;
      setTableData(filteredArray);
    }
  };

  useEffect(() => {
    getReports();
  }, []);

  const closeDrawer = () => {
    setCreateDrawerOpen(false);
    setSelectedRow(null);
    setSelectedIndex(-1);
    dispatch(resetStoreKey({ storeKey: STORE_KEYS.RPTS_PWRBI_ACCESS_TOKEN }));
  };

  const getPowerBIAccessToken = () => {
    dispatch(
      executeACGAction({
        payload: {
          requestType: 'POST',
          urlPath: ACTION_CODES.RPTS_PWRBI_ACCESS_TOKEN
        },
        storeKey: STORE_KEYS.RPTS_PWRBI_ACCESS_TOKEN,
        uniqueScreenIdentifier: { accesstokenLoaded: true }
      })
    );
  };

  useEffect(() => {
    if (selectedRow != null) {
      if (selectedRow.ReportType == "PowerBI") {
        getPowerBIAccessToken();
      }
      else {
        setCreateDrawerOpen(true);
      }
    }
  }, [selectedRow]);

  useEffect(() => {
    if (selectedRow != null && stateObj?.[STORE_KEYS.RPTS_PWRBI_ACCESS_TOKEN]?.accesstokenLoaded == true) {
      getPowereBIEmbedToken(stateObj?.[STORE_KEYS.RPTS_PWRBI_ACCESS_TOKEN]?.body.data);
    }

    dispatch(
      updateScreenIdentifiers({
        storeKey: STORE_KEYS.RPTS_PWRBI_ACCESS_TOKEN,
        uniqueScreenIdentifier: { accesstokenLoaded: false }
      })
    );

  }, [stateObj?.[STORE_KEYS.RPTS_PWRBI_ACCESS_TOKEN]?.accesstokenLoaded]);

  useEffect(() => {
    if (ReportConfig.accessToken) {
      setCreateDrawerOpen(true);
    }
  }, [ReportConfig.accessToken])

  const getPowereBIEmbedToken = async (auth: string) => {

    let URL = "";

    const { PBIBaseURL, PBIGroupId, PBIReportId, PBIReportURL, PBIDatasetId } = selectedRow;
    
    let reportUrl = PBIReportURL.replace('autoAuth=true&', '');
  
    URL = PBIBaseURL.concat(PBIGroupId, "/reports/", PBIReportId, "/GenerateToken");

    const payLoad = {
      datasets: [
        {
          id: PBIDatasetId,
        },
      ],
      reports: [
        {
          allowEdit: false,
          id: PBIReportId,
        },
      ],
      identities: [
        {
          username: stateObj?.userDtl?.EmailId,
          roles: ["RLS"],
          datasets: [PBIDatasetId],
        },
      ],
    }

    try {
      const response = await Axios.post(
        URL,
        payLoad,
        {
          headers: {
            Authorization: "Bearer " + auth,
            "Content-type": "application/json",
          },
        }
      );
      if (response.data.token) {
        setReportConfig({
          ...ReportConfig,
          embedUrl: reportUrl,
          accessToken: response.data.token
        });
      }
    } catch (err) {
      console.log(err);
      handleSnackbarError("Something Went Wrong");
    }
  };

  return (
    <div>
      <div>
        <SnackbarAlert options={snackbarOptions} handleClose={closeSnackbar} />
        <Container
          maxWidth="xl"
          style={{
            paddingLeft: "3px",
            paddingRight: "3px",
          }}
        >
          <Card
            style={{
              borderRadius: "0px",
              backgroundColor: "#2b2d42",
              overflow: "inherit",
            }}
          >
            <CustomTableToolbar
              numSelected={0}
              filterName={filterName}
              onFilterName={handleFilterByName}
              resetInput={handleFilterByName}
              displayInput={true}
              text={{
                title: "Total Reports",
                subTitle: `${tableData?.length} Reports`
              }}
              filterButton={false}
              customButton={false}
            />
          </Card>
        </Container>
        <CustomTable
          selectable={false}
          headers={[]}
          body={tableData}
          filteredUsers={tableData}
          filterName={""}
          selected={(obj: any) => { }}
          order={""}
          orderBy={""}
          onRequestSort={() => { }}
          onSelectAllClick={() => { }}
          rowChange={() => { }}
          rowCount={tableData.length}
          onPageChange={handleChangePage}
          page={page}
          setPageNumber={setPage}
          selectRow={(rowIndex: any) => {
            setSelectedIndex(rowIndex);
            setSelectedRow(tableData[rowIndex]);
          }
          }
          route={[]}
          isRequestPage={true}
          link={true}
          type="User_Reports_List"
          isLoading={isReportLoading}
        />
        <PowerBIViewDrawer
          handlerOpen={() => { }}
          handlerClose={closeDrawer}
          status={createDrawerOpen}
          data={selectedRow ? selectedRow : []}
          reportConfig={ReportConfig}
          block={true}
          type="all"
          index={selectedIndex}
        />
      </div>
    </div>
  );
};

export default UserReports;