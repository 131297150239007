import { useState, useEffect } from 'react';
import { useDispatch } from 'react-redux';
import { Container } from '@mui/material';
import MapCustomTabs from '../../../app/atoms/ButtonTabs/MapCustomTabs';
import Audit from './Audit'
import Plants from '../Admin/ClientManagement/Plants';
import { ACTION_CODES, STORE_KEYS } from '../../constants/apiConstants';
import { SCREEN_TITLES } from '../../constants/StringConstants';
import { executeACGAction } from '../../store/slice';
import useGetState from '../../utils/hooks/useGetState';
import { _noop } from '../../utils/common';
import DrawerDropdown from '../../atoms/DrawerDropdown';
import RightDrawer from '../../../app/atoms/tempDrawer/indexAlt';
import SnackbarAlert from '../../../app/atoms/Snackbar';

const tabs = ['Audit'];
const options = {
    DEFAULT: {
        message: '',
        open: false,
        type: ''
    }
};
const AuditTrail = () => {
    const [currTab, setCurrTab] = useState(0);
    const dispatch = useDispatch();
    const [createValue, setCreateValue] = useState('');
    const [createDrawerOpen, setCreateDrawerOpen] = useState(false);
    const [snackbarOptions, setSnackbarOptions] = useState(options.DEFAULT);
    const [reload, setReload] = useState(false);
    const [drawerHeader, setDrawerHeader] = useState('Create');
    const [dtlData, setDtlData] = useState<any>();
    const state = useGetState();
    const getCreateValue = (ele: string) => {
        setDrawerHeader('Create');
        setCreateValue(ele);
        setCreateDrawerOpen(true);
    };

    const navigateTab = () => {
        switch (currTab) {
            default:
                return (
                    <Audit
                        dtlProps={{
                            setCreateValue: setCreateValue,
                            setCreateDrawerOpen: setCreateDrawerOpen,
                            drawerHeader: drawerHeader,
                            setDrawerHeader: setDrawerHeader,
                            dtlData: dtlData,
                            setDtlData: setDtlData
                        }}
                    />
                );
        }
    };

    const { TotalClients = 0, TotalPlants = 0, TotalMachines = 0 } = state[STORE_KEYS.CLIENT_SUMMARY]?.body?.data?.[0] || {};
    const closeSnackbar = () => setSnackbarOptions(options.DEFAULT);
    return (
        <div className="dashboard">
            <SnackbarAlert options={snackbarOptions} handleClose={closeSnackbar} />
            <Container maxWidth="xl" style={{ marginTop: '60px' }}>
                <div style={{ display: 'flex', alignItems: 'center', flexWrap: 'wrap' }}>
                    <div style={{ flex: '80%' }}>
                        <div className='headinglabel'>
                            {SCREEN_TITLES.USER_TRAIL}
                        </div>
                    </div>
                </div>
                <div style={{ marginTop: '35px', zIndex: '0' }}>
                    <MapCustomTabs
                        navigateTab={navigateTab}
                        currTab={currTab}
                        setCurrTab={setCurrTab}
                        tabsArray={tabs}
                        className="tabs"
                        position="relative"
                    />
                </div>
            </Container>
        </div>
    );
};

export default AuditTrail;
