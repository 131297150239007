import { useState, useEffect, useRef, useMemo } from 'react';
import { Grid } from '@mui/material';
import AcgButton from '../../../../app/atoms/Button/index';
import { ACTION_CODES, STORE_KEYS } from '../../../../app/constants/apiConstants';
import { executeACGAction, resetErr, updateScreenIdentifiers } from '../../../../app/store/slice';
import { useDispatch } from 'react-redux';
import useGetState from '../../../../app/utils/hooks/useGetState';
import { _noop } from '../../../../app/utils/common';
import Box from '@mui/material/Box';
import SearchBar from '../../../../app/atoms/SearchBar';
import Accordion from '../../../../app/atoms/Accordion';
import Label from '../../../../app/atoms/Label/index';
import CustomRadioGroup from '../../../../app/atoms/CustomRadioGroup';
import {
    extractCustKeyPlantKeys,
    extractPlantKeysOnly,
    formatAccordionData,
    formatPlants
} from '../../../../app/utils/formatData';
import DropDown from '../../../../app/atoms/Dropdown';
import { renameObjKeys } from '../../Login/helper';

import OutlinedInput from '@mui/material/OutlinedInput';
import InputLabel from '@mui/material/InputLabel';
import MenuItem from '@mui/material/MenuItem';
import FormControl from '@mui/material/FormControl';
import ListItemText from '@mui/material/ListItemText';
import Select, { SelectChangeEvent } from '@mui/material/Select';
import Checkbox from '@mui/material/Checkbox';
import SnackbarAlert from '../../../atoms/Snackbar';
import { Container, Card } from '@mui/material';
import CustomTableToolbar from '../../../atoms/CustomTableToolbar';
import ContactDetails from '../Contacts/Details';
import DownArrow from '../../../../assets/down-arrow.svg';

const ITEM_HEIGHT = 48;
const ITEM_PADDING_TOP = 8;
const MenuProps = {
    PaperProps: {
        style: {
            maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
            width: 250
        }
    }
};

const ALL_CLIENT = { code: null, name: '--Select Client--' };

type Props = {
    drawerClose: Function;
    setCurrTab: Function;
    setReload: Function;
    setSnackbarOpts: Function;
    setStep: Function;
    isEdit: boolean;
    dtlData: any;
    data?: any;
    drawerHeader?: any;
    block?: boolean;
    type?: string;
    editFn?: Function;
    deleteFn?: Function;
};
const options = {
    DEFAULT: {
        message: '',
        open: false,
        type: ''
    },
    AD_USER: {
        message: 'Contact created successfully!',
        open: true,
        type: 'success'
    },
    ED_USER: {
        message: 'Contact edited successfully!',
        open: true,
        type: 'success'
    }
};

type AssignFeatureType = {
    features: any;
};

const AssignFeature = (props: Props) => {
    const {
        drawerClose,
        setCurrTab,
        setSnackbarOpts,
        setStep,
        setReload,
        isEdit,
        dtlData,
        data,
        block = false,
        type = 'Role',
        editFn = () => { },
        deleteFn = () => { }
    } = props;

    const DropDownIcon = (prop: any) => (
        <img style={{ marginRight: '5px', marginTop: '-2px' }} src={DownArrow} {...prop} />
    );

    const [allPlantsList, setPlantsList] = useState<any>([]);
    const [selectedTotalCount, setSelectedTotalCount] = useState<any>(0);
    const [radioValue, setRadioValue] = useState<any>(null);
    const [selectedClient, setSelectedClient] = useState<string[]>([]);
    const [clientList, setClientList] = useState<any[]>([]);
    // const [clients, setClients] = useState<any>([]);
    const [plantList, setPlantList] = useState<any[]>([]);
    const [selectedPlant, setSelectedPlant] = useState<string[]>([]);
    const [machineList, setMachineList] = useState<any[]>([]);
    const [selectedMachine, setSelectedMachine] = useState<string[]>([]);
    const [mapType, setMapType] = useState<any>('Customer');
    const [snackbarOptions, setSnackbarOptions] = useState(options.DEFAULT);
    const [isEditStep2, setIsEditStep2] = useState<any>(isEdit ? true : false);

    const planDropdowHandleChange = (event: SelectChangeEvent<typeof selectedPlant>) => {
        const {
            target: { value }
        } = event;
        setSelectedPlant(
            // On autofill we get a stringified value.
            typeof value === 'string' ? value.split(',') : value
        );
    };
    const machineDropdownHandleChange = (event: SelectChangeEvent<typeof selectedMachine>) => {
        const {
            target: { value }
        } = event;
        setSelectedMachine(
            // On autofill we get a stringified value.
            typeof value === 'string' ? value.split(',') : value
        );
    };
    const state = useGetState();
    const dispatch = useDispatch();

    const [assignUserAccess, setAssignUserAccess] = useState<any>([]);

    const handleRadioValue = async (val: string) => {
        await setSelectedClient([]);
        await setSelectedPlant([]);
        await setSelectedMachine([]);
        await setPlantList([]);
        await setMachineList([]);
        setRadioValue(val);
    };

    const handleSubmit = () => {
        const reqPayload = {
            payload: {
                urlPath: isEdit ? ACTION_CODES.EDIT_CONTACT : ACTION_CODES.ADD_CONTACT,
                requestType: isEdit ? 'PUT' : 'POST',
                reqObj: {
                    firstName: data.firstName,
                    lastName: data.lastName,
                    email: data.email,
                    phoneNo: data.mobilePrimary,
                    alternatePhoneNo: data.mobileSecondary,
                    spocType: data.radioValue
                }
            },
            uniqueScreenIdentifier: { contactAdded: true },
            storeKey: STORE_KEYS.ADD_CNT
        };
        dispatch(executeACGAction(reqPayload));
    };

    useEffect(() => {
        if (state?.[STORE_KEYS.ADD_CNT]?.contactAdded) {
            if (state?.[STORE_KEYS.ADD_CNT]?.statusCode === 200) {
                dispatch(
                    updateScreenIdentifiers({
                        storeKey: STORE_KEYS.ADD_CNT,
                        uniqueScreenIdentifier: {
                            contactAdded: false
                        }
                    })
                );
                const rcUserKey = state?.[STORE_KEYS.ADD_CNT]?.body?.data?.rcUserKey;
                const reqPayload = {
                    payload: {
                        urlPath: ACTION_CODES.ADD_CONTACT_MAP,
                        requestType: 'POST',
                        reqObj: {
                            mapType: mapType,
                            rcUserKey: rcUserKey,
                            customerKey: selectedClient,
                            plantKey:
                                radioValue === 'Customer'
                                    ? '-1'
                                    : radioValue === 'Machine'
                                        ? selectedPlant?.toString()
                                        : selectedPlant?.join(','),
                            machineKey:
                                radioValue === 'Customer' || radioValue === 'Plant' ? '-1' : selectedMachine?.join(',')
                        }
                    },
                    uniqueScreenIdentifier: { assistantAdded: true },
                    storeKey: STORE_KEYS.ADD_CNT
                };

                dispatch(executeACGAction(reqPayload));
            }
        }
    }, [state?.[STORE_KEYS.ADD_CNT]?.contactAdded]);

    useEffect(() => {
        if (state?.[STORE_KEYS.ADD_CNT]?.assistantAdded) {
            if (state?.[STORE_KEYS.ADD_CNT]?.statusCode === 200) {
                setReload(true);
                setCurrTab(0);
                setSnackbarOptions(isEdit ? options.ED_USER : options.AD_USER);
                // drawerClose();
                setTimeout(() => {
                    drawerClose();
                }, 2000);
            }
            dispatch(
                updateScreenIdentifiers({
                    storeKey: STORE_KEYS.ADD_CNT,
                    uniqueScreenIdentifier: {
                        assistantAdded: false
                    }
                })
            );
        }
    }, [state?.[STORE_KEYS.ADD_CNT]?.assistantAdded]);

    const handleSnackbarError = (err: any) => {
        const errorMsg = err?.message || 'Internal Server error';
        const snackbarError = {
            message: errorMsg,
            type: 'reject',
            open: true
        };

        setSnackbarOptions(snackbarError);
    };

    useEffect(() => {
        if (state?.err) {
            handleSnackbarError(state?.err);
            dispatch(resetErr());
        }
    }, [state?.err]);

    // Client drop down api call
    useEffect(() => {
        if (radioValue) {
            dispatch(
                executeACGAction({
                    payload: {
                        requestType: 'GET',
                        urlPath: ACTION_CODES.GET_CLIENT_DROPDOWN
                    },
                    storeKey: STORE_KEYS.CLIENT_DROPDOWN,
                    uniqueScreenIdentifier: {
                        isLoading: true
                    }
                })
            );
        }
    }, [radioValue]);
    useEffect(() => {
        const clientsDt = state?.[STORE_KEYS.CLIENT_DROPDOWN]?.body?.data;
        setClientList(
            clientsDt?.map((item: any) => {
                const newVal = renameObjKeys(item, { CustomerKey: 'code', CustomerName: 'name' });
                return newVal;
            })
        );
    }, [state?.[STORE_KEYS.CLIENT_DROPDOWN]?.body?.data]);
    console.log('plant list', selectedPlant, machineList);

    //Plant Api call
    useEffect(() => {
        setSelectedPlant([]);
        if (
            Number(selectedClient) > 0 &&
            clientList?.length > 0 &&
            (radioValue === 'Plant' || radioValue === 'Machine')
        ) {
            setMapType('Plant');
            dispatch(
                executeACGAction({
                    payload: {
                        requestType: 'GET',
                        urlPath: `${ACTION_CODES.GET_PLANT_DROPDOWN}/${selectedClient}`
                    },
                    storeKey: STORE_KEYS.PLANT_DROPDOWN,
                    uniqueScreenIdentifier: {
                        isLoading: true
                    }
                })
            );
        }
    }, [selectedClient]);
    useEffect(() => {
        const plantDt = state?.[STORE_KEYS.PLANT_DROPDOWN]?.body?.data;
        setPlantList(
            plantDt?.map((item: any) => {
                const newVal = renameObjKeys(item, { PlantKey: 'code', PlantName: 'name' });
                return newVal;
            })
        );
    }, [state?.[STORE_KEYS.PLANT_DROPDOWN]?.body?.data]);

    //Machine api call
    useEffect(() => {
        console.log('1001', selectedPlant);
        setSelectedMachine([]);
        if (
            Number(selectedPlant) > 0 &&
            radioValue !== 'Plant' &&
            radioValue !== 'Customer' &&
            radioValue === 'Machine'
        ) {
            setMapType('Machine');
            const reqPayload = {
                payload: {
                    urlPath: ACTION_CODES.GET_MACHINE_DROPDOWN,
                    requestType: 'POST',
                    reqObj: {
                        customerKey: selectedClient,
                        plantKey: selectedPlant?.length > 1 ? selectedPlant.join(',') : selectedPlant,
                        plantLineNoKey: '-1'
                    }
                },
                uniqueScreenIdentifier: { accessAdded: true },
                storeKey: STORE_KEYS.MACHINE_DROPDOWN
            };
            dispatch(executeACGAction(reqPayload));
        }
    }, [selectedPlant]);

    useEffect(() => {
        const machineDt = state?.[STORE_KEYS.MACHINE_DROPDOWN]?.body?.data;
        console.log('selected plant and macine data', selectedPlant, machineDt);
        setMachineList(
            machineDt?.map((item: any) => {
                const newVal = renameObjKeys(item, { MachineKey: 'code', MachineName: 'name' });
                return newVal;
            })
        );
    }, [state?.[STORE_KEYS.MACHINE_DROPDOWN]?.body?.data]);

    useEffect(() => {
        if (state?.[STORE_KEYS.ADD_CNT]?.accessAdded) {
            if (state?.[STORE_KEYS.ADD_CNT]?.statusCode === 200) {
                setCurrTab(0);
                drawerClose();
            }
            dispatch(
                updateScreenIdentifiers({
                    storeKey: STORE_KEYS.ADD_CNT,
                    uniqueScreenIdentifier: {
                        contactAdded: false,
                        accessAdded: false
                    }
                })
            );
        }
    }, [state?.[STORE_KEYS.ADD_CNT]?.accessAdded]);

    const renderButtons = () => {
        return (
            <div className={'btns noWrap'}>
                <AcgButton
                    name="Back"
                    handleClick={() => (isEdit && !isEditStep2 ? setIsEditStep2(true) : setStep(0))}
                    type="button"
                    secondary={true}
                    className={'first-btn'}
                    variant="contained"
                />
                <AcgButton
                    name={'Submit'}
                    handleClick={handleSubmit}
                    type="submit"
                    className="second-btn"
                    variant="contained"
                />
            </div>
        );
    };
    const closeSnackbar = () => setSnackbarOptions(options.DEFAULT);
    const list = () => (
        <div className="detailDrawerDiv">
            <SnackbarAlert options={snackbarOptions} handleClose={closeSnackbar} />
            <Box sx={{ width: 200 }} role="presentation" className={'standardBox'}>
                <div className="userDetailDiv">
                    <div className="detailDivChild firstChild">
                        <p>Contact Name</p>
                        <h6>
                            {data.firstName} {data.lastName}
                        </h6>
                    </div>
                    <div className={'detailDivChild '}>
                        <p>SPOC Type</p>
                        <h6>{data.radioValue}</h6>
                    </div>
                </div>
                <div className="accordionDiv" style={{ marginTop: '20px' }}>
                    <Label label="Assistance Level" />
                    <CustomRadioGroup
                        value={radioValue}
                        fields={[
                            { label: 'Client', value: 'Customer' },
                            { label: 'Plant', value: 'Plant' },
                            { label: 'Machine', value: 'Machine' }
                        ]}
                        onChange={handleRadioValue}
                    />
                </div>
                {radioValue !== null &&
                    (radioValue === 'Customer' || radioValue === 'Plant' || radioValue === 'Machine') && (
                        <Grid item xs={10}>
                            <Label label="Client" className={'labelDiv'} />
                            <DropDown
                                onChange={(event: any) => setSelectedClient(event?.target?.value)}
                                className="inputDiv"
                                name="dropdownClients"
                                items={clientList}
                                placeHolder="Select Client"
                                value={selectedClient}
                            />
                        </Grid>
                    )}
                {radioValue === 'Plant' ? (
                    <Grid item xs={10}>
                        <Label label="Plant" className={'labelDiv'} />
                        <FormControl sx={{ m: 1 }} style={{ width: '99%' }}>
                            {selectedPlant?.length > 0 ? null : (
                                <InputLabel
                                    style={{
                                        fontFamily: 'Montserrat',
                                        fontSize: '13px',
                                        paddingTop: '5px',
                                        paddingLeft: '10px',
                                        color: '#b1c2df',
                                        opacity: '0.7'
                                    }}
                                    id="demo-multiple-checkbox-label"
                                >
                                    Select Plant
                                </InputLabel>
                            )}
                            <Select
                                // labelId="demo-multiple-checkbox-label"
                                IconComponent={DropDownIcon}
                                id="demo-multiple-checkbox"
                                multiple
                                displayEmpty
                                label=""
                                style={{
                                    backgroundColor: '#1d1e2c',
                                    color: '#b1c2df',
                                    fontFamily: 'Montserrat',
                                    fontSize: '13px',
                                    borderRadius: '10px'
                                }}
                                className="inputDiv"
                                name="dropdownPlantMulti"
                                value={selectedPlant}
                                placeholder="Select Plant"
                                onChange={planDropdowHandleChange}
                                // input={<OutlinedInput style={{ border: "none", color: 'white' }} placeholder={selectedPlant?.length > 0 ? '' : 'Select Plant2'} label='' />}
                                MenuProps={MenuProps}
                                renderValue={(selected) =>
                                    plantList
                                        ?.filter((name) => selected.includes(name.code))
                                        ?.map((record) => record.name)
                                        ?.join(', ')
                                }
                            >
                                {plantList?.map((plan) => (
                                    <MenuItem key={plan.code} value={plan.code}>
                                        <Checkbox checked={selectedPlant?.indexOf(plan.code) > -1} />
                                        <ListItemText primary={plan.name} />
                                    </MenuItem>
                                ))}
                            </Select>
                        </FormControl>
                    </Grid>
                ) : radioValue !== null && radioValue !== 'Customer' ? (
                    <Grid item xs={10}>
                        <Label label="Plant" className={'labelDiv'} />
                        <DropDown
                            onChange={(e: any) => setSelectedPlant(e?.target?.value)}
                            className="inputDiv"
                            name="dropdownPlant"
                            placeHolder="Select Plant"
                            items={plantList}
                            value={selectedPlant}
                        />
                    </Grid>
                ) : null}
                {radioValue === 'Machine' && (
                    <Grid item xs={10}>
                        <Label label="Machine" className={'labelDiv'} />
                        <FormControl sx={{ m: 1 }} style={{ width: '99%' }}>
                            {selectedMachine?.length > 0 ? null : (
                                <InputLabel
                                    style={{
                                        fontFamily: 'Montserrat',
                                        fontSize: '13px',
                                        paddingTop: '5px',
                                        paddingLeft: '10px',
                                        color: '#b1c2df',
                                        opacity: '0.7'
                                    }}
                                    id="demo-multiple-checkbox-label"
                                >
                                    Select Machine
                                </InputLabel>
                            )}
                            <Select
                                IconComponent={DropDownIcon}
                                labelId="demo-multiple-checkbox-label"
                                id="demo-multiple-checkbox"
                                multiple
                                style={{
                                    backgroundColor: '#1d1e2c',
                                    color: '#b1c2df',
                                    fontFamily: 'Montserrat',
                                    fontSize: '13px',
                                    borderRadius: '10px'
                                }}
                                className="inputDiv"
                                name="dropdownMachine"
                                placeholder="Select Machine"
                                value={selectedMachine}
                                onChange={machineDropdownHandleChange}
                                // input={<OutlinedInput label="Tag" />}
                                renderValue={(selected) =>
                                    machineList
                                        ?.filter((name) => selected.includes(name.code))
                                        ?.map((record) => record.name)
                                        ?.join(', ')
                                }
                                MenuProps={MenuProps}
                            >
                                {machineList?.map((machine) => (
                                    <MenuItem key={machine.code} value={machine.code}>
                                        <Checkbox checked={selectedMachine?.indexOf(machine.code) > -1} />
                                        <ListItemText primary={machine.name} />
                                    </MenuItem>
                                ))}
                            </Select>
                        </FormControl>
                    </Grid>
                )}
            </Box>
        </div>
    );

    const stepMap2ForEditonly = () => {
        return (
            <>
                <ContactDetails data={dtlData} usedIn={'edit'} />{' '}
                <AcgButton
                    name="Add Client, Plant or Machine"
                    handleClick={() => setIsEditStep2(false)}
                    type="button"
                    style={{ marginTop: '25px' }}
                    className="first-btn"
                    variant="contained"
                />
            </>
        );
    };
    return (
        <>
            {isEditStep2 ? stepMap2ForEditonly() : list()}
            {renderButtons()}
        </>
    );
};

export default AssignFeature;