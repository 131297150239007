// import { useEffect } from 'react';

// type Props = { chartData: any };
// const LineChart = (props: Props) => {
//     const { chartData } = props;
//     useEffect(() => {
//         //setX()
//     }, []);
//     return <div>{/* <Line data={[]} /> */}</div>;
// };

// export default LineChart;

import { useEffect, useState, memo } from 'react';
// import faker from 'faker';
import {
    Chart as ChartJS,
    CategoryScale,
    LinearScale,
    PointElement,
    LineElement,
    Title,
    Tooltip,
    Legend
} from 'chart.js';
import { Line } from 'react-chartjs-2';

ChartJS.register(CategoryScale, LinearScale, PointElement, LineElement, Title, Tooltip, Legend);

// const labels = ['January', 'February', 'March', 'April', 'May', 'June', 'July'];

type Props = { chartData: any; labels: any; points: any; noData: string; setValue: any; LSLValue: any; USLValue: any };

const LineChart = (props: Props) => {
    const { chartData, labels, points, noData, setValue, LSLValue, USLValue } = props;
    const [dataPoints, setDataPoints] = useState<any>([]);
    const [dataLabels, setDataLabels] = useState<any>([]);

    useEffect(() => {
        if (points?.length > 0) {
            setDataPoints(points);
            setDataLabels(labels);
        }
    }, [points, labels]);

    const options = {
        // responsive: true,
        maintainAspectRatio: false,
        scales: {
            x: {
                ticks: {
                    font: {
                        size: 10
                    },
                    margin: {
                        left: 10
                    }
                }
            }
        },
        plugins: {
            legend: {
                position: 'top' as const
            },
            title: {
                display: true,
                text: chartData[0]?.MachineSerialNo
            }
        }
    };

    const data = {
        labels: dataLabels,
        datasets: [
            {
                label: 'Actual Value',
                borderWidth: 1,
                data: dataPoints,
                borderColor: 'rgb(255, 99, 132)',
                backgroundColor: 'rgba(255, 99, 132, 0.5)',
                pointRadius: 0,
            },
            {
                label: 'Set Value',
                borderWidth: 1,
                data: setValue,
                borderColor: 'limegreen',
                backgroundColor: 'limegreen',
                pointRadius: 0,
            },
            {
                label: 'LSL Value',
                borderWidth: 1,
                data: LSLValue,
                borderColor: 'tomato',
                backgroundColor: 'tomato',
                pointRadius: 0,
            },
            {
                label: 'USL Value',
                borderWidth: 1,
                data: USLValue,
                borderColor: 'cyan',
                backgroundColor: 'cyan',
                pointRadius: 0,
            }
        ]
    };

    return (
        <div className="ChartDiv">
            {noData == '' ? (
                points?.length > 0 ? (
                    <Line options={options} data={data} width={200} height={75} />
                ) : (
                    <h6 style={{ color: 'white' }} data-text="Loading....."></h6>
                )
            ) : (
                <h6 style={{ color: 'white' }}>{noData}</h6>
            )}
            {/* {points?.length > 0 ? (
                <Line options={options} data={data} width={200} height={75} />
            ) : (
                <h6 style={{ color: 'white' }} data-text="Loading.....">
                </h6>
            )} */}
        </div>
    );
};

export default memo(LineChart);
