import React, { useState, useEffect, ChangeEvent } from 'react';
import { useDispatch } from 'react-redux';
import useGetState from '../../utils/hooks/useGetState';
import { ACTION_CODES, STORE_KEYS } from '../../constants/apiConstants';
import { executeACGAction } from '../../store/slice';
import styles from "./style.module.scss"
import { styled } from '@mui/material/styles';
import { Icon } from '@iconify/react';
import searchFill from '@iconify/icons-eva/search-fill';
import closeFill from '@iconify/icons-eva/close-fill';
import { Box, Container, FilledInput, InputAdornment } from '@mui/material';
import CustomAlertsTable from '../CustomAlertsTable/CustomAlertsTable';
import TablePaginationActions from '../CustomTable/TablePagination';
const SearchStyle = styled(FilledInput)(({ theme }) => ({
  width: 320,
  transition: theme.transitions.create(['box-shadow', 'width'], {
    easing: theme.transitions.easing.easeInOut,
    duration: theme.transitions.duration.shorter
  }),
  borderRadius: 10,
  background: '#1d1e2c',
  height: 40,
  marginRight: 20,
  color: '#e0e0e0',
  fontFamily: "Montserrat",
  fontSize: 14,
  // position: 'absolute',
  // right: '1vw',
  '&.Mui-focused': { width: 320, background: '#1d1e2c', marginRight: 20, },
  '&:hover': { width: 320, background: '#1d1e2c', marginRight: 20, },
  '& fieldset': {
    borderWidth: '1px !important'
    // borderColor: `${theme.palette.grey[500_32]} !important`
  },
  [theme.breakpoints.down('xs')]: {
    right: '18vw',
    width: '250px'
  },
  [theme.breakpoints.down('sm')]: {
    right: '18vw',
    width: '250px'
  },
  [theme.breakpoints.up('md')]: {
    right: '13vw '
  },
  [theme.breakpoints.up('lg')]: {
    right: '0vw'
  }
}));
type ComponentsProps = {
  MachineKey: string | number
}
function AlertLogDrawer(props: ComponentsProps) {
  const { MachineKey } = props
  const dispatch = useDispatch()
  const stateVal = useGetState();
  const loggedInUser = stateVal[STORE_KEYS.USER_DTL]?.LoggedUserKey;
  const HeaderList = [
    { label: "Alert Name", key: "AlertName" },
    { label: "Trigger Time", key: "TriggerTime" },
  ]
  const rowPerPage = 10

  const [alertsLogs, setAlertsLogs] = useState<any[]>([])
  const [tempAlertsLogs, setTempAlertsLogs] = useState<any[]>([])
  const [paginatedData, setPaginatedData] = useState<any[]>([])
  const [filterName, setFilterName] = useState<string>("")
  const [page, setPage] = useState<number>(0)
  const [loading, setLoading] = useState(true)

  function sortAlerts(array: any, order: any, col: string) {

    if (order == 'asc') {
      return array.sort((a: any, b: any) => a[col].localeCompare(b[col]));
    }
    else if (order == 'desc') {
      return array.sort((a: any, b: any) => b[col].localeCompare(a[col]));
    }
    else {
      console.error('Invalid order parameter. Use "asc" or "desc".');
      return array;
    }
  }
  function sortArrayByTriggerTime(array: any, order: any) {
    return array.sort((a: any, b: any) => {
      const dateA: any = new Date(a.TriggerTime);
      const dateB: any = new Date(b.TriggerTime);
      if (order.toLowerCase() === 'asc') {
        return dateA - dateB;
      }
      else if (order.toLowerCase() === 'desc') {
        return dateB - dateA;
      }
      else {
        console.error('Invalid order. Please use "asc" or "desc".');
        return array;
      }
    });
  }
  const handleSorting = (col: string, type: string) => {
    let unSortedArr = [...tempAlertsLogs]
    if (col == "TriggerTime") {
      let newarr = sortArrayByTriggerTime(unSortedArr, type)
      setAlertsLogs(newarr)
    } else {
      let newarr = sortAlerts(unSortedArr, type, col)
      setAlertsLogs(newarr)
    }

  }

  const getAlertLogs = () => {
    setLoading(true)
    setAlertsLogs([])
    dispatch(
      executeACGAction({
        payload: {
          requestType: 'POST',
          urlPath: ACTION_CODES.GET_CUSTOM_ALERT_LOGS,
          reqObj: {
            "LoggedUserKey": loggedInUser,
            "MachineKey": MachineKey
          }
        },
        storeKey: STORE_KEYS.CUSTOM_ALERTS_LOGS
      })
    );
  };

  const resetInput = () => {
    setFilterName("")
  }
  const handleFilterName = (e: ChangeEvent<HTMLInputElement>) => {
    const value = e.target.value.toLowerCase();
    setFilterName(value);
  };

  const handlePageChange = (event: any, newPage: number) => {
    setPage(newPage);
  };

  useEffect(getAlertLogs, [])
  useEffect(() => {
    if (stateVal?.[STORE_KEYS.CUSTOM_ALERTS_LOGS]?.body?.data) {
      setAlertsLogs(stateVal?.[STORE_KEYS.CUSTOM_ALERTS_LOGS]?.body?.data);
      setTempAlertsLogs(stateVal?.[STORE_KEYS.CUSTOM_ALERTS_LOGS]?.body?.data);

    }

  }, [stateVal?.[STORE_KEYS.CUSTOM_ALERTS_LOGS]])
  useEffect(() => {
    if (filterName.length) {
      let newFilterArr = tempAlertsLogs.filter((item) => item.AlertName.toLowerCase().includes(filterName.toLowerCase()))
      setAlertsLogs(newFilterArr)
    } else {
      setAlertsLogs(tempAlertsLogs)
    }
    setPage(0)
  }, [filterName])
  useEffect(() => {
    const startIndex = page * rowPerPage;
    const endIndex = startIndex + rowPerPage;
    const paginatedData = alertsLogs.slice(startIndex, endIndex);
    setPaginatedData(paginatedData);
    setLoading(false)
  }, [page, alertsLogs]);
  return (
    <div className={styles.container}>
      <div className={styles.header}>
        <SearchStyle
          // className="searchBox"
          className={
            styles.searchBox
          }
          value={filterName}
          onChange={handleFilterName}
          placeholder="Search"
          startAdornment={
            <div>
              <InputAdornment position="start" variant="outlined" className="searchBoxIcon">
                <Box component={Icon} icon={searchFill} sx={{ color: 'white' }} />
              </InputAdornment>
              {filterName?.length > 0 ? (
                <InputAdornment position="end" className={styles.searchBoxIconCancel} onClick={resetInput}>
                  <Box component={Icon} icon={closeFill} sx={{ color: 'text.disabled' }} />
                </InputAdornment>
              ) : null}
            </div>
          }
        />
      </div>
      <CustomAlertsTable handleSorting={handleSorting} loading={loading} HeaderList={HeaderList} rows={paginatedData} isAction={false} />
      <div className={styles.paginationBox}>
        <TablePaginationActions
          count={alertsLogs.length}
          page={page}
          rowsPerPage={rowPerPage}
          onPageChange={handlePageChange}
          setPageNumber={setPage}
        />
      </div>
    </div>
  )
}

export default AlertLogDrawer