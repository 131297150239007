import PropTypes from 'prop-types';
import { Icon } from '@iconify/react';
import { Container, Card, Grid, TableRow, TableCell } from '@mui/material';
import searchFill from '@iconify/icons-eva/search-fill';
import useGetState from '../../utils/hooks/useGetState';
import { ACTION_CODES, STORE_KEYS } from '../../constants/apiConstants';
import closeFill from '@iconify/icons-eva/close-fill';
// import filterIcon from '../../../assets/images/filterIcon.svg';
import filterIcon from '../../../assets/images/filter.svg';
// material
import { styled } from '@mui/material/styles';
import {
    Box,
    Toolbar,
    Tooltip,
    IconButton,
    Typography,
    FilledInput,
    InputAdornment,
    TextField,
    Button
} from '@mui/material';
import './Toolbar.scss';
//import AddIcon from '@mui/icons-material/Add';
import AddIcon from '../../../assets/images/AddIcon.svg';
import CustomRadioGroup from '../CustomRadioGroup';
import DropDown from '../Dropdown';
import moment from 'moment';
import Label from '../Label/index';
// ----------------------------------------------------------------------

const RootStyle = styled(Toolbar)(({ theme }) => ({
    height: 96,
    display: 'flex',
    justifyContent: 'space-between'
    // padding: theme.spacing(0, 1, 0, 3)
}));

const SearchStyle = styled(FilledInput)(({ theme }) => ({
    width: 300,
    transition: theme.transitions.create(['box-shadow', 'width'], {
        easing: theme.transitions.easing.easeInOut,
        duration: theme.transitions.duration.shorter
    }),
    borderRadius: 50,
    height: 40,
    // position: 'absolute',
    // right: '1vw',
    // '&.Mui-focused': { width: 320, boxShadow: theme.customShadows.z8 },
    '& fieldset': {
        borderWidth: '1px !important'
        // borderColor: `${theme.palette.grey[500_32]} !important`
    },
    [theme.breakpoints.down('xs')]: {
        right: '18vw',
        width: '250px'
    },
    [theme.breakpoints.down('sm')]: {
        right: '18vw',
        width: '250px'
    },
    [theme.breakpoints.up('md')]: {
        right: '13vw '
    },
    [theme.breakpoints.up('lg')]: {
        right: '0vw'
    }
}));

interface Props {
    numSelected: number;
    filterName?: any;
    onFilterName?: any;
    drawerOpen?: any;
    displayInput?: boolean;
    text?: any;
    customButton?: boolean;
    ButtonText?: any;
    filterButton?: boolean;
    resetInput?: any;
    approveButton?: boolean;
    isApproved?: boolean;
    assignmentDrawer?: any;
    radios?: boolean;
    onRadioChange?: any;
    radioValue?: any;
    radioFields?: any;
    dropdown?: boolean;
    dropdownItems?: any;
    dropdownValue?: any;
    onDropdownChange?: Function;
    countNoShow?: boolean;
    clip?: boolean;
    smallSearch?: boolean;
    filterLogsButton?: any;
    appliedFilters?: any;
    appliedOMFilters?: boolean;
    clearFilter?: any;
    placeholder?: any;
    clc?: boolean;
    getCLCList?: any;
    machineModelData?: any;
    filteredMachine?: any;
    setAppliedFilters?: any;
    setAppliedOMFilters?: any;
    dashboard?: boolean;
    parameterName?: string;
    getOnboardList?: any;
    currTab?: any;
    customEditButton?: boolean;
    editFn?: any;
    setFilteredGoldenRecipeKey?: any;
    resetFilter?: any;
    resetFilterOM?: any;
    selectedRecipe?: any;
    selectedBatch?: any;
    machineModelName?: any;
    status?: any;
}

const UserListToolbar = (props: Props) => {
    const state = useGetState();
    const {
        numSelected,
        filterName,
        onFilterName,
        drawerOpen,
        displayInput,
        text,
        customButton,
        ButtonText,
        filterButton,
        resetInput,
        approveButton,
        isApproved,
        assignmentDrawer,
        radios,
        radioValue,
        onRadioChange,
        radioFields,
        dropdown = false,
        dropdownItems,
        dropdownValue,
        onDropdownChange = () => { },
        countNoShow,
        clip,
        smallSearch,
        filterLogsButton,
        appliedFilters = false,
        appliedOMFilters = false,
        clearFilter = () => { },
        resetFilter = () => { },
        resetFilterOM = () => { },
        placeholder,
        clc = false,
        getCLCList = () => { },
        machineModelData,
        filteredMachine,
        setAppliedFilters,
        setAppliedOMFilters,
        dashboard = false,
        parameterName,
        getOnboardList,
        currTab = 0,
        customEditButton = false,
        editFn = () => { },
        setFilteredGoldenRecipeKey = () => { },
        selectedRecipe,
        selectedBatch,
        machineModelName,
        status
    } = props;
    const deleteAllRows = () => {
        alert('deleted ' + numSelected + ' rows');
    };
    const handleModal = () => {
        alert('modal open');
    };

    return (
        <RootStyle
            sx={{
                ...(numSelected > 0 && {
                    color: 'primary.main',
                    bgcolor: 'primary.lighter'
                })
            }}
            className="tableToolbar"
        >
            {text ? (
                <div
                    className={
                        displayInput
                            ? 'typeAndCount'
                            : !displayInput && filterButton
                                ? 'typeAndCount'
                                : 'typeAndCount countOnly'
                    }
                    style={{ marginRight: displayInput ? '30px' : 'unset' }}
                >
                    <h6 style={{ marginBottom: '0px' }}>{text.title}</h6>
                    <div style={{ display: 'flex' }}>
                        <p style={{ marginBottom: '0px', fontSize: '12px' }}>{text.subTitle}</p>
                    </div>
                </div>
            ) : null}

            {radios ? (
                <div style={{ marginRight: '10px' }} className="toolbarRadio">
                    <CustomRadioGroup value={radioValue} fields={radioFields} onChange={onRadioChange} />
                </div>
            ) : null}
            {/* {numSelected > 0 ? (
                <Typography component="div" variant="subtitle1" style={{ marginLeft: '3px' }}>
                    {numSelected} selected
                </Typography>
            ) : null} */}
            {dropdown ? (
                <div style={{ marginRight: '50px' }} className="toolbardropdown">
                    <DropDown
                        onChange={(event: any) => onDropdownChange(event.target.value)}
                        // className="inputDiv"
                        name="dropdownTimePeriod"
                        items={dropdownItems}
                        placeHolder="Select time period"
                        value={dropdownValue}
                    />
                </div>
            ) : null}

            {clc ?
                <Grid md={12} style={{ display: "flex", alignItems: "center", marginRight: "12px" }} >
                    <DropDown
                        onChange={(e) => {
                            if (currTab === 0) {
                                getCLCList(e.target.value);
                                setAppliedFilters(true);
                            } else {
                                setAppliedOMFilters(true);
                                getOnboardList(e.target.value)
                            }
                        }}
                        className="inputDiv"
                        name="dropdownModel"
                        items={machineModelData}
                        placeHolder="Select Machine Model"
                        value={filteredMachine === -1 ? '' : filteredMachine}
                    />
                    {appliedFilters ?
                        <div
                            onClick={() => {
                                clearFilter();
                                resetFilter();
                            }}
                            style={{
                                border: '1px solid #5D97F6',
                                height: '25px',
                                width: '28px',
                                marginLeft: '10px',
                                right: '-15px',
                                top: '-15px',
                                cursor: 'pointer',
                                display: 'flex',
                                alignItems: 'center',
                                borderRadius: '50px',
                            }}
                        >
                            <span
                                style={{
                                    position: 'relative',
                                    fontSize: '12px',
                                    color: '#5D97F6',
                                    left: '32%',
                                    top: '6%'
                                }}
                            >
                                X
                            </span>
                        </div>
                        : appliedOMFilters ?
                            <div
                                onClick={() => {
                                    clearFilter();
                                    resetFilterOM();
                                }}
                                style={{
                                    border: '1px solid #5D97F6',
                                    height: '25px',
                                    width: '28px',
                                    marginLeft: '10px',
                                    right: '-15px',
                                    top: '-15px',
                                    cursor: 'pointer',
                                    display: 'flex',
                                    alignItems: 'center',
                                    borderRadius: '50px',
                                }}
                            >
                                <span
                                    style={{
                                        position: 'relative',
                                        fontSize: '12px',
                                        color: '#5D97F6',
                                        left: '32%',
                                        top: '6%'
                                    }}
                                >
                                    X
                                </span>
                            </div>
                            : null}
                </Grid>
                : null}

            {displayInput ? (
                <SearchStyle
                    // className="searchBox"
                    className={
                        customButton && !filterButton
                            ? 'searchBox' + ' buttonSearchBox'
                            : customButton && filterButton
                                ? 'searchBox' + ' bothButtons'
                                : radios
                                    ? 'searchBox radioSearch'
                                    : clip
                                        ? 'searchBox clip'
                                        : smallSearch
                                            ? 'searchBox smallSearch'
                                            : 'searchBox'
                    }
                    value={filterName}
                    onChange={onFilterName}
                    placeholder={placeholder ?? "Search"}
                    startAdornment={
                        <div>
                            <InputAdornment position="start" variant="outlined" className="searchBoxIcon">
                                <Box component={Icon} icon={searchFill} sx={{ color: 'text.disabled' }} />
                            </InputAdornment>
                            {filterName?.length > 0 ? (
                                <InputAdornment position="end" className="searchBoxIconCancel" onClick={resetInput}>
                                    <Box component={Icon} icon={closeFill} sx={{ color: 'text.disabled' }} />
                                </InputAdornment>
                            ) : null}
                        </div>
                    }
                />
            ) : null}
            {customEditButton ?
                <Button style={{ textTransform: "capitalize" }} onClick={editFn}>Bulk Edit</Button>
                : null}
            {numSelected > 0 && !countNoShow && (
                <Typography component="div" variant="subtitle1" style={{ fontSize: '15px', marginLeft: '5px' }}>
                    {numSelected} Selected
                </Typography>
            )}

            {displayInput ? (
                <div>
                    {customButton && !filterButton && !approveButton ? (
                        <div style={{ display: 'flex', justifyContent: 'center' }}>
                            <Button
                                variant="contained"
                                size="small"
                                className="addButton"
                                style={{
                                    background: 'linear-gradient(270deg, #0f249d 0%, #0f1785 100%)',
                                    borderRadius: '20px'
                                }}
                                onClick={assignmentDrawer}
                            >
                                <div style={{ display: 'flex', justifyContent: 'space-around' }}>
                                    <div>
                                        <img className="addIcon" src={AddIcon} alt="addIcon" />
                                    </div>
                                    <span>{ButtonText}</span>
                                </div>
                            </Button>
                        </div>
                    ) : !customButton && filterButton && !approveButton ? (
                        <div style={{ display: 'flex', alignItems: 'center' }}>
                            <div>
                                <div className="filter" onClick={drawerOpen} style={{ display: 'flex', marginLeft: '10px' }}>
                                    <img src={filterIcon} />
                                    <span>Filter</span>
                                </div>

                            </div>
                            {
                                appliedFilters ?
                                    <div
                                        onClick={() => {
                                            clearFilter();
                                            setFilteredGoldenRecipeKey('');
                                        }}
                                        style={{
                                            position: 'relative',
                                            fontSize: '12px',
                                            color: '#5D97F6',
                                            left: '35%',
                                            top: '1px'
                                        }}
                                    >
                                        <span
                                            style={{
                                                position: 'relative',
                                                fontSize: '12px',
                                                color: '#5D97F6',
                                                // left: '35%',
                                                top: '-3%',
                                                right: "25px",
                                                cursor: 'pointer'
                                            }}
                                        >
                                            X
                                        </span>
                                    </div>
                                    : null
                            }
                        </div>

                    ) : customButton && filterButton && !approveButton ? (
                        <div className="filterButtonAndAddMembers">
                            <div className="filter" onClick={drawerOpen}>
                                <img src={filterIcon} />
                                <span>Filter</span>
                            </div>
                            <div style={{ display: 'flex', justifyContent: 'center' }}>
                                <Button
                                    variant="contained"
                                    size="small"
                                    className="addButton"
                                    style={{
                                        background: 'linear-gradient(270deg, #0f249d 0%, #0f1785 100%)',
                                        borderRadius: '20px'
                                    }}
                                    onClick={assignmentDrawer}
                                >
                                    <div style={{ display: 'flex', justifyContent: 'space-around' }}>
                                        <div>
                                            <img className="addIcon" src={AddIcon} alt="addIcon" />
                                        </div>
                                        <span>{ButtonText}</span>
                                    </div>
                                </Button>
                            </div>
                        </div>
                    ) : !customButton && filterButton && approveButton ? (
                        <div className="filterButtonAndAddMembers reverseRow">
                            <div className="filter" onClick={drawerOpen}>
                                <img src={filterIcon} />
                                <span>Filter</span>
                            </div>
                            {!isApproved ? (
                                <div style={{ display: 'flex', justifyContent: 'center' }}>
                                    <Button
                                        variant="contained"
                                        size="small"
                                        className={numSelected > 0 ? 'addButton' : 'addButton noShadow'}
                                        style={
                                            numSelected > 0
                                                ? {
                                                    background: 'linear-gradient(270deg, #0f249d 0%, #0f1785 100%)',
                                                    borderRadius: '20px',
                                                    transition: 'all 0.3s linear'
                                                }
                                                : {
                                                    background: '#B1CCDA',
                                                    borderRadius: '20px',
                                                    color: 'white',
                                                    transition: 'background 0.1s linear'
                                                }
                                        }
                                        onClick={assignmentDrawer}
                                        disabled={numSelected > 0 ? false : true}
                                    >
                                        <div
                                            style={{
                                                display: 'flex',
                                                justifyContent: 'space-around'
                                            }}
                                        >
                                            {/* <div>
                                            <img className="addIcon" src={AddIcon} alt="addIcon" />
                                        </div> */}
                                            <span style={{ marginRight: '10px', marginLeft: '10px' }}>
                                                {ButtonText}
                                            </span>
                                        </div>
                                    </Button>
                                </div>
                            ) : null}
                        </div>
                    ) : // (
                        //   <div
                        //     className="filter"
                        //     onClick={drawerOpen}
                        //     style={{ display: "flex" }}
                        //   >
                        //     <img src={filterIcon} />
                        //     <span>Filter</span>
                        //   </div>
                        // )
                        null}
                </div>
            ) : (
                filterButton && (
                    <div className="filter" onClick={drawerOpen} style={{ display: 'flex' }}>
                        <img src={filterIcon} />
                        <span>Filter</span>
                    </div>
                )
            )}
            {dashboard ?
                <Grid md={12} style={{ display: "flex", alignItems: "center", marginRight: "12px" }} >

                    {appliedFilters && status === "Historical" ?
                        <div style={{ display: "flex" }} className='filterdatatop'>

                            <div
                                onClick={() => {
                                    clearFilter();
                                }}
                                style={{
                                    border: '1px solid #5D97F6',
                                    borderRadius: '50px',
                                    height: '25px',
                                    width: '26px',
                                    marginLeft: '10px',
                                    marginRight: '10px',
                                    marginTop: '10px',
                                    right: '-15px',
                                    top: '-15px',
                                    cursor: 'pointer',
                                    display: 'flex',
                                    alignItems: 'center'
                                }}
                            >
                                <span
                                    style={{
                                        position: 'relative',
                                        fontSize: '12px',
                                        color: '#5D97F6',
                                        left: '34%',
                                        top: '4%'
                                    }}
                                >
                                    X
                                </span>
                            </div>
                            {machineModelName.includes("BQS") ?
                                <div style={{ display: "flex" }} className='filterdata'>
                                    <Typography className='ptag'>Product Name : <br /><strong className='itag'>{state?.[STORE_KEYS.GOLDEN_BATCH_DATA]?.data ? state?.[STORE_KEYS.GOLDEN_BATCH_DATA]?.data[0]?.productName : "NA"}</strong></Typography>
                                    <Typography className='ptag'>Parameter Name : <br /><strong className='itag'>{parameterName ? parameterName : "null"}</strong></Typography>
                                    <Typography className='ptag'>Batch Start : <br /><strong className='itag'>{state?.[STORE_KEYS.GOLDEN_BATCH_DATA]?.data ? moment.utc(state?.[STORE_KEYS.GOLDEN_BATCH_DATA]?.data[0]?.batchStart).format("DD/MM/YYYY HH:mm:ss") : "NA"}</strong></Typography>
                                    <Typography className='ptag'>Batch End : <br /><strong className='itag'>{state?.[STORE_KEYS.GOLDEN_BATCH_DATA]?.data ? moment.utc(state?.[STORE_KEYS.GOLDEN_BATCH_DATA]?.data[0]?.batchend).format("DD/MM/YYYY HH:mm:ss") : "NA"}</strong></Typography>
                                    <Typography className='ptag'>Set Value : <br /><strong className='itag'>{state?.[STORE_KEYS.GOLDEN_BATCH_DATA]?.data ? state?.[STORE_KEYS.GOLDEN_BATCH_DATA]?.data[0]?.setValue : "NA"}</strong></Typography>
                                    <Typography className='ptag'>Ref Set Value : <br /><strong className='itag'>{state?.[STORE_KEYS.GOLDEN_BATCH_DATA]?.data ? state?.[STORE_KEYS.GOLDEN_BATCH_DATA]?.data[0]?.refSetValue : "NA"}</strong></Typography>
                                </div>

                                :
                                <div style={{ display: "flex" }} className='filterdata'>
                                    <Typography className='ptag'>Product Name : <br /><strong className='itag'>{state?.[STORE_KEYS.GOLDEN_BATCH_DATA_FBE]?.body?.data[0]?.ProductName ? state?.[STORE_KEYS.GOLDEN_BATCH_DATA_FBE]?.body?.data[0]?.ProductName : "NA"}</strong></Typography>
                                    <Typography className='ptag'>Parameter Name : <br /><strong className='itag'>{parameterName ? parameterName : "null"}</strong></Typography>
                                    <Typography className='ptag'>Batch Start : <br /><strong className='itag'>{state?.[STORE_KEYS.GOLDEN_BATCH_DATA_FBE]?.body?.data[0]?.BatchStartTime ? moment.utc(state?.[STORE_KEYS.GOLDEN_BATCH_DATA_FBE]?.body?.data[0]?.BatchStartTime).format("DD/MM/YYYY HH:mm:ss") : "NA"}</strong></Typography>
                                    <Typography className='ptag'>Batch End : <br /><strong className='itag'>{state?.[STORE_KEYS.GOLDEN_BATCH_DATA_FBE]?.body?.data[0]?.BatchEndTime ? moment.utc(state?.[STORE_KEYS.GOLDEN_BATCH_DATA_FBE]?.body?.data[0]?.BatchEndTime).format("DD/MM/YYYY HH:mm:ss") : "NA"}</strong></Typography>
                                </div>
                            }
                        </div>
                        : null}
                </Grid>
                : null}
        </RootStyle>
    );
};

export default UserListToolbar;
