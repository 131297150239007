import { Paper, Box, Typography } from '@mui/material';
import PropTypes from 'prop-types';
import Button from '../../../../atoms/Button/index';
import SuccessIcon from '../../../../../assets/passwordSuccess.svg';
import { useHistory } from 'react-router-dom';

type ComponentProps = { data: any };
const PasswordSuccess = (props: ComponentProps) => {
    const { data } = props;
    const history = useHistory();
    const clickLogin = () => {
        if (data?.isForgotPwdPage) {
            history.push('/');
        } else {
            data?.setTemplateType('simpleLogin');
        }
    };
    return (
        <Paper elevation={2} className="loginPaper">
            <Box className="loginLogo">
                <img src={SuccessIcon} alt="logo" />
            </Box>
            <div className="loginFormHeader">
                <Typography className="welcomeText">Password updated successfully!</Typography>
                <Typography className="loginText">Please login with your new password</Typography>
            </div>

            <Button
                formInput="buttonDiv"
                fullWidth
                name="Login"
                type="button"
                variant="contained"
                onClick={clickLogin}
            />
        </Paper>
    );
};

PasswordSuccess.propTypes = {
    data: PropTypes.any
};

export default PasswordSuccess;
