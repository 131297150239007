import React, { useEffect, useState } from 'react'
import { Container, Card, Box } from '@mui/material';
import DropDown from '../../../../atoms/Dropdown';
import Label from '../../../../atoms/Label/index';
import { ACTION_CODES, STORE_KEYS } from '../../../../constants/apiConstants';
import { executeACGAction } from '../../../../store/slice';
import { useDispatch } from 'react-redux';
import useGetState from '../../../../utils/hooks/useGetState';
import { renameObjKeys } from '../../../Login/helper';
import CustomDatePicker from '../../../../atoms/CustomDatePicker';
function FilterBox({ values, setValues }: any) {
    const dispatch = useDispatch()
    const stateVal: any = useGetState();

    const [maachineModelOptions, setMachineModelOptions] = useState<any>([])
    const [maachineComponentOptions, setMachineComponentOptions] = useState<any>([])
    const [sopTypeOptions, setSopTypeOptions] = useState<any>([])

    const getMachineModelList = () => {
        dispatch(
            executeACGAction({
                payload: {
                    requestType: 'GET',
                    urlPath: ACTION_CODES.GET_SOP_MACHINE_MODEL,
                    reqObj: {
                    }
                },
                storeKey: STORE_KEYS.SOP_MACHINE_MODEL_LIST
            })
        );
    };
    const getMachineComponentList = (MachineModelKey: string) => {
        dispatch(
            executeACGAction({
                payload: {
                    requestType: 'POST',
                    urlPath: ACTION_CODES.GET_SOP_MACHINE_COM,
                    reqObj: {
                        MachineModalKey: MachineModelKey
                    }
                },
                storeKey: STORE_KEYS.SOP_MACHINE_COM_LIST
            })
        );
    };
    const getSopTypeList = () => {
        dispatch(
            executeACGAction({
                payload: {
                    requestType: 'GET',
                    urlPath: ACTION_CODES.GET_SOP_TYPE,
                    reqObj: {

                    }
                },
                storeKey: STORE_KEYS.SOP_TYPE_LIST
            })
        );
    };
    useEffect(() => {
        getMachineModelList()
        getSopTypeList()
    }, [])
    useEffect(() => {
        getMachineComponentList(values.model)

    }, [values.model])
    useEffect(() => {
        if (stateVal[STORE_KEYS.SOP_MACHINE_MODEL_LIST]?.body?.data) {
            const grpsList = stateVal[STORE_KEYS.SOP_MACHINE_MODEL_LIST]?.body?.data?.map((item: any) => {
                const newVal = renameObjKeys(item, { MachineModelKey: 'code', MachineModelName: 'name' });
                return newVal;
            });
            setMachineModelOptions(grpsList)
        }
    }, [stateVal[STORE_KEYS.SOP_MACHINE_MODEL_LIST]])
    useEffect(() => {
        if (stateVal[STORE_KEYS.SOP_MACHINE_COM_LIST]?.body?.data) {
            const grpsList = stateVal[STORE_KEYS.SOP_MACHINE_COM_LIST]?.body?.data?.map((item: any) => {
                const newVal = renameObjKeys(item, { MaintComponentKey: 'code', MaintComponentName: 'name' });
                return newVal;
            });
            setMachineComponentOptions(grpsList)
        }
    }, [stateVal[STORE_KEYS.SOP_MACHINE_COM_LIST]])
    useEffect(() => {
        if (stateVal[STORE_KEYS.SOP_TYPE_LIST]?.body?.data) {
            const grpsList = stateVal[STORE_KEYS.SOP_TYPE_LIST]?.body?.data?.map((item: any) => {
                const newVal = renameObjKeys(item, { SOPTypeKey: 'code', SOPType: 'name' });
                return newVal;
            });
            setSopTypeOptions(grpsList)
        }
    }, [stateVal[STORE_KEYS.SOP_TYPE_LIST]])
    return (
        <Container className="createReport">
            <Box style={{ minHeight: '75vh' }}>
                <Label label="Machine Model" className={'labelDiv'} />
                <DropDown
                    onChange={(e: any) => {
                        setValues((prev: any) => ({ ...prev, [e.target.name]: e.target.value }));
                    }}
                    name="model"
                    className="inputDiv"
                    items={maachineModelOptions}
                    value={values.model}
                    placeHolder='Select Machine Model'
                />
                <Label label="Machine Sub Component" className={'labelDiv'} />
                <DropDown
                    onChange={(e: any) => {
                        setValues((prev: any) => ({ ...prev, [e.target.name]: e.target.value }));
                    }}
                    name="component"
                    className="inputDiv"
                    items={maachineComponentOptions}
                    value={values.component}
                    placeHolder='Select Machine Sub Component'
                />
                <Label label="SOP Type" className={'labelDiv'} />
                <DropDown
                    onChange={(e: any) => {
                        setValues((prev: any) => ({ ...prev, [e.target.name]: e.target.value }));
                    }}
                    name="type"
                    className="inputDiv"
                    items={sopTypeOptions}
                    value={values.type}
                    placeHolder='Select SOP Type'
                />
                <Label label="Start Date" className={'labelDiv'} />
                <CustomDatePicker
                    name="startDate"
                    value={values.startDate}
                    className="inputDiv"
                    onChange={(value: any) => {
                        setValues((prev: any) => ({ ...prev, startDate:value }));
                    }}
                    inputFormat="dd/MM/yyyy"
                    placeholder="Select Start Date"
                    maxDate={values.endDate}
                    minDate={new Date(new Date().setMonth(new Date().getMonth() - 3))}
                />
                <Label label="End Date" className={'labelDiv'} />
                <CustomDatePicker
                    name="endDate"
                    value={values.endDate}
                    className="inputDiv"
                    onChange={(value: any) => {
                        setValues((prev: any) => ({ ...prev,endDate: value }));
                    }}
                    inputFormat="dd/MM/yyyy"
                    placeholder="Select Start Date"
                    maxDate={new Date()}
                    minDate={values.startDate}
                />

            </Box>
        </Container>

    )
}

export default FilterBox