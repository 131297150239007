import React, { useState, useEffect } from 'react';
import { useDispatch } from 'react-redux';
import { Container, Card, Box } from '@mui/material';
import CustomTable from '../../../atoms/CustomTable/index';
import CustomTableToolbar from '../../../atoms/CustomTableToolbar';
import { executeACGAction, resetErr, resetStoreKey, updateScreenIdentifiers } from '../../../store/slice';
import useGetState from '../../../utils/hooks/useGetState';
import SnackbarAlert from '../../../atoms/Snackbar';
import { applySortFilter } from '../../../utils/helper';
import AlertBox from '../../../atoms/AlertBox';
import FilterDrawer from '../../../atoms/FilterDrawer';
import Label from '../../../atoms/Label/index';
import DropDown from '../../../atoms/Dropdown';
import DetailDrawer from '../../../atoms/DetailDrawer';
import RightDrawer from '../../../atoms/tempDrawer/indexAlt';
import EditBroadcast from '../EditBroadcast';
import { ACTION_CODES, STORE_KEYS } from '../../../constants/apiConstants';
import ViewBroadcast from '../Upcoming/ViewBroadcast';

const TABLE_HEAD = [
    { id: 'BroadcastMessage', label: 'Message', alignRight: false },
    { id: 'BroadcastType', label: 'Type', alignRight: false },
    { id: 'NoOfClients', label: 'Number of clients', alignRight: false },
    { id: 'DateTimeRange', label: 'Date and time range', alignRight: false },
    { id: 'Action', label: 'Action', alignLeft: true }
];

const options = {
    DEFAULT: {
        message: '',
        open: false,
        type: ''
    },
    RM_SUCCESS: {
        message: 'Broadcast deleted successfully!',
        open: true,
        type: 'success'
    },
    ADD_SUCCESS: {
        message: 'Broadcast scheduled successfully!',
        open: true,
        type: 'success'
    },
    ED_SUCCESS: {
        message: 'Broadcast updated successfully!',
        open: true,
        type: 'success'
    }
};

const History = (props: any) => {
    const { setCurrTab, reload, setReload, summary, getBcClients } = props;
    const [snackbarOptions, setSnackbarOptions] = useState(options.DEFAULT);
    const state = useGetState();
    const dispatch = useDispatch();
    const [filterOpen, setFilterOpen] = useState<any>(false);
    const [page, setPage] = useState<number>(0);
    const [order, setOrder] = useState('desc');
    const [orderBy, setOrderBy] = useState('BroadcastMessage');
    const [selected, setSelected] = useState<any>([]);
    const [filterName, setFilterName] = useState<string>('');
    const [removeBxOpen, setRemoveBxOpen] = useState(false);
    const [selectedRow, setSelectedRow] = useState<any>([]);
    const [rmBc, setRmBc] = useState(false);
    const [bcData, setBcData] = useState<any>([]);
    const [bcs, setBcs] = useState<any>([]);
    const [filterType, setFilterType] = useState<any>('All');
    const [createDrawerOpen, setCreateDrawerOpen] = useState(false);
    const [editDrawerOpen, setEditDrawerOpen] = useState(false);
    const [isHisBcsLoading,setIsHisBcsLoading]=useState(false)

    const filterTypes = [
        { code: 'All', name: 'All' },
        { code: 'Client', name: 'Client' },
        { code: 'Global', name: 'Global' }
    ];
    const filters = {
        typeFilter: (item: any) => item?.BroadcastType === filterType,
        srchFilter: (item: any) => item?.message?.toLowerCase()?.includes(filterName?.toLowerCase())
    };

    const getBcs = () => {
        setIsHisBcsLoading(true)
        dispatch(
            executeACGAction({
                payload: {
                    requestType: 'GET',
                    urlPath: `${ACTION_CODES.GET_UC_BC}/History`
                },
                uniqueScreenIdentifier: { bcsRecd: true },
                storeKey: STORE_KEYS.UC_BC
            })
        );
    };

    useEffect(() => {
        getBcs();
    }, []);

    useEffect(() => {
        if (reload) {
            summary();
            getBcs();
            setReload(false);
        }
    }, [reload]);

    useEffect(() => {
        if (state?.[STORE_KEYS.UC_BC]?.bcsRecd) {
            setFilterName('');
            setBcData(state?.[STORE_KEYS.UC_BC]?.body?.data);
            setBcs(state?.[STORE_KEYS.UC_BC]?.body?.data);
            dispatch(
                updateScreenIdentifiers({
                    storeKey: STORE_KEYS.UC_BC,
                    uniqueScreenIdentifier: {
                        bcsRecd: false
                    }
                })
            );
            setIsHisBcsLoading(false)
        }
    }, [state?.[STORE_KEYS.UC_BC]?.bcsRecd]);

    const handleFilterByName = (event: any) => {
        setOrderBy('BroadcastMessage');
        setPage(0);
        setFilterName(event.target.value);
        setFilterType('All');
        // let selectedFilter: any = [];
        // if (filterType !== 'All') {
        //     selectedFilter = [...selectedFilter, filters.typeFilter];
        // }
        // if (filterName !== '') {
        //     selectedFilter = [...selectedFilter, filters.srchFilter];
        // }
        // if (selectedFilter?.length !== 0) {
        //     const x = [...bcData]?.filter((ele: any) => selectedFilter?.every((f: any) => f(ele)));
        //     setBcs(x);
        // } else {
        //     setBcs([...bcData]);
        // }
    };
    const resetInput = () => {
        setPage(0);
        setFilterName('');
        let selectedFilter: any = [];
        if (filterType !== 'All') {
            selectedFilter = [...selectedFilter, filters.typeFilter];
        }
        if (selectedFilter?.length !== 0) {
            const x = [...bcData]?.filter((ele: any) => selectedFilter?.every((f: any) => f(ele)));
            setBcs(x);
        } else {
            setBcs([...bcData]);
        }
    };
    const filteredList = applySortFilter(bcs, order, filterName, orderBy);

    const handleRequestSort = (event: any, property: any) => {
        setPage(0);
        setFilterName('');
        const isAsc = orderBy === event && order === 'asc';
        setOrder(isAsc ? 'desc' : 'asc');
        setOrderBy(event);
    };

    const handleSelectAllClick = (event: any) => { };

    const handleClick = (requestedBy: string) => {
        const selectedIndex = selected.indexOf(requestedBy);
        let newSelected: any[] | ((prevState: unknown[]) => unknown[]) = [];
        if (selectedIndex === -1) {
            newSelected = newSelected.concat(selected, requestedBy);
        } else if (selectedIndex === 0) {
            newSelected = newSelected.concat(selected.slice(1));
        } else if (selectedIndex === selected.length - 1) {
            newSelected = newSelected.concat(selected.slice(0, -1));
        } else if (selectedIndex > 0) {
            newSelected = newSelected.concat(selected.slice(0, selectedIndex), selected.slice(selectedIndex + 1));
        }
        setSelected(newSelected);
    };

    const handleChangePage = (event: any, newPage: React.SetStateAction<number>) => {
        setPage(newPage);
    };

    const showRow = (event: any) => { };

    const handleRoute = async (row: any, ele: any) => {
        if (ele === 'Action') {
            setSelectedRow(row);
            if (row?.BroadcastType != 'Global') {
                dispatch(
                    executeACGAction({
                        payload: {
                            requestType: 'GET',
                            urlPath: `${ACTION_CODES.GET_BC_MAPPINGS_CLIENT}/${row?.BroadcastKey}`
                        },
                        uniqueScreenIdentifier: { viewClMaps: true },
                        storeKey: STORE_KEYS.BC_MAPDCLIENTS
                    })
                );
            } else {
                setCreateDrawerOpen(true);
            }
        }
    };

    useEffect(() => {
        if (state?.[STORE_KEYS.BC_MAPDCLIENTS]?.viewClMaps) {
            const clientsLst = state?.[STORE_KEYS.BC_MAPDCLIENTS]?.body?.data?.map((cust: any) => {
                return { CustomerName: cust.CustomerName };
            });
            setSelectedRow({ ...selectedRow, broadcastChildren: <ViewBroadcast data={{ customers: clientsLst }} /> });
            setCreateDrawerOpen(true);
            dispatch(
                updateScreenIdentifiers({
                    storeKey: STORE_KEYS.BC_MAPDCLIENTS,
                    uniqueScreenIdentifier: {
                        viewClMaps: false
                    }
                })
            );
        }
    }, [state?.[STORE_KEYS.BC_MAPDCLIENTS]?.viewClMaps]);

    const handleSnackbarError = (err: any) => {
        const errorMsg = err?.message || 'Internal Server error';
        const snackbarError = {
            message: errorMsg,
            type: 'reject',
            open: true
        };
        setSnackbarOptions(snackbarError);
    };

    useEffect(() => {
        if (state?.err) {
            handleSnackbarError(state?.err);
            dispatch(resetErr());
        }
    }, [state?.err]);
    const closeSnackbar = () => setSnackbarOptions(options.DEFAULT);

    const [bcKey, setBcKey] = useState<any>();

    const removeBc = (ele: any) => {
        setBcKey(ele?.BroadcastKey);
        setRemoveBxOpen(true);
    };
    useEffect(() => {
        if (rmBc) {
            closeRmBx();
            dispatch(
                executeACGAction({
                    payload: {
                        requestType: 'DELETE',
                        urlPath: `${ACTION_CODES.DELETE_UC_BC}/${bcKey}`
                    },
                    uniqueScreenIdentifier: {
                        bcRemoved: true
                    },
                    storeKey: STORE_KEYS.DELETE_RPT
                })
            );
            setRmBc(false);
        }
    }, [rmBc]);

    useEffect(() => {
        if (state?.[STORE_KEYS.DELETE_RPT]?.bcRemoved) {
            if (state?.[STORE_KEYS.DELETE_RPT]?.statusCode === 200) {
                setSnackbarOptions(options.RM_SUCCESS);
                closeDrawer();
                summary();
                getBcs();
            }
            dispatch(
                updateScreenIdentifiers({
                    storeKey: STORE_KEYS.DELETE_RPT,
                    uniqueScreenIdentifier: {
                        bcRemoved: false
                    }
                })
            );
        }
    }, [state?.[STORE_KEYS.DELETE_RPT]?.bcRemoved]);

    const closeRmBx = () => {
        setRemoveBxOpen(false);
    };
    const handleAction = () => {
        setRmBc(true);
    };
    const clearFilter = () => {
        setPage(0);
        let selectedFilter: any = [];
        setFilterType('All');
        if (filterName !== '') {
            selectedFilter = [...selectedFilter, filters.srchFilter];
        }
        if (selectedFilter?.length !== 0) {
            const x = [...bcData]?.filter((ele: any) => selectedFilter?.every((f: any) => f(ele)));
            setBcs(x);
        } else {
            setBcs([...bcData]);
        }
        setFilterOpen(false);
    };

    const applyFilter = () => {
        setPage(0);
        let selectedFilter: any = [];
        if (filterType !== 'All') {
            selectedFilter = [...selectedFilter, filters.typeFilter];
        }
        if (filterName !== '') {
            selectedFilter = [...selectedFilter, filters.srchFilter];
        }
        if (selectedFilter?.length !== 0) {
            const x = [...bcData]?.filter((ele: any) => selectedFilter?.every((f: any) => f(ele)));
            setBcs(x);
        } else {
            setBcs([...bcData]);
        }
        setFilterOpen(false);
    };

    const bcFilter = () => {
        return (
            <Container className="createReport">
                <Box style={{ minHeight: '75vh' }}>
                    <Label label="Type" className={'labelDiv'} />
                    <DropDown
                        onChange={(event: any) => {
                            setFilterType(event.target.value);
                        }}
                        name="dropdowntype"
                        className="inputDiv"
                        items={filterTypes}
                        value={filterType}
                    />
                </Box>
            </Container>
        );
    };
    const closeDrawer = () => {
        setSelectedRow([]);
        setCreateDrawerOpen(false);
    };
    const handlerCloseEditDrawer = () => {
        setEditDrawerOpen(false);
    };
    const getBcMappings = () => {
        dispatch(
            executeACGAction({
                payload: {
                    requestType: 'GET',
                    urlPath: `${ACTION_CODES.GET_BC_MAPPINGS_CLIENT}/${selectedRow?.BroadcastKey}`
                },
                uniqueScreenIdentifier: { bcClMaps: true },
                storeKey: STORE_KEYS.BC_MAPDCLIENTS
            })
        );
    };

    useEffect(() => {
        if (state?.[STORE_KEYS.BC_MAPDCLIENTS]?.bcClMaps) {
            const customers = state?.[STORE_KEYS.BC_MAPDCLIENTS]?.body?.data?.map((cust: any) => cust.CustomerKey);
            setSelectedRow({ ...selectedRow, customers: customers });
            dispatch(
                updateScreenIdentifiers({
                    storeKey: STORE_KEYS.BC_MAPDCLIENTS,
                    uniqueScreenIdentifier: {
                        bcClMaps: false
                    }
                })
            );
            setEditDrawerOpen(true);
        }
    }, [state?.[STORE_KEYS.BC_MAPDCLIENTS]?.bcClMaps]);

    return (
        <div>
            <SnackbarAlert options={snackbarOptions} handleClose={closeSnackbar} />
            <div>
                <Container
                    maxWidth="xl"
                    style={{
                        paddingLeft: '3px',
                        paddingRight: '3px'
                    }}
                >
                    <Card
                        style={{
                            borderRadius: '0px',
                            backgroundColor: '#2b2d42',
                            overflow: 'inherit'
                        }}
                    >
                        <CustomTableToolbar
                            drawerOpen={() => setFilterOpen(true)}
                            numSelected={selected?.length}
                            filterName={filterName}
                            onFilterName={handleFilterByName}
                            displayInput={true}
                            text={{
                                title: 'Broadcasts List',
                                subTitle: `${filteredList?.length} broadcasts`
                            }}
                            filterButton={true}
                            customButton={false}
                            resetInput={resetInput}
                        />
                    </Card>
                </Container>
                <CustomTable
                    selectable={false}
                    headers={TABLE_HEAD}
                    body={filteredList}
                    filteredUsers={filteredList}
                    filterName={filterName}
                    selected={selected}
                    order={order}
                    orderBy={orderBy}
                    onRequestSort={handleRequestSort}
                    onSelectAllClick={handleSelectAllClick}
                    rowChange={(event: string) => handleClick(event)}
                    rowCount={filteredList?.length}
                    onPageChange={handleChangePage}
                    page={page}
                    setPageNumber={setPage}
                    selectRow={showRow}
                    route={handleRoute}
                    isRequestPage={true}
                    link={true}
                    noDataText={'No Broadcast data available'}
                    removeFn={removeBc}
                    isLoading={isHisBcsLoading}
                />
            </div>
            <AlertBox
                isOpen={removeBxOpen}
                handleClose={closeRmBx}
                leftBtnPress={closeRmBx}
                rightBtnPress={handleAction}
                modalHeader={'Delete Broadcast'}
                modalContent={`Are you sure you want to delete broadcast?`}
            />
            <DetailDrawer hasImage={false}
                handlerOpen={() => { }}
                handlerClose={closeDrawer}
                status={createDrawerOpen}
                data={selectedRow}
                type={'broadcast'}
                editFn={() => {
                    getBcClients();
                    getBcMappings();
                }}
                deleteFn={() => removeBc(selectedRow)}
            />
            <RightDrawer
                handlerOpen={() => { }}
                handlerClose={handlerCloseEditDrawer}
                status={editDrawerOpen}
                drawerHeader={'Schedule Broadcast'}
                children={
                    <EditBroadcast
                        drawerClose={() => setEditDrawerOpen(false)}
                        setCurrTab={setCurrTab}
                        setReload={setReload}
                        setSnackbarOpts={setSnackbarOptions}
                        dtlData={selectedRow}
                    />
                }
            />
            <FilterDrawer
                handlerClose={() => {
                    setFilterOpen(false);
                }}
                status={filterOpen}
                drawerHeader={'Filter'}
                children={<>{bcFilter()}</>}
                clearFn={clearFilter}
                applyFn={applyFilter}
            />
        </div>
    );
};

export default History;
