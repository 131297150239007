import { useState, useEffect } from "react";
import * as React from 'react';
import CustomTable from "../../../atoms/CustomTable/index";
import CustomTableToolbar from "../../../atoms/CustomTableToolbar";
import { Container, Card } from "@mui/material";
import { ACTION_CODES, STORE_KEYS } from "../../../constants/apiConstants";
import { useDispatch } from "react-redux";
import useGetState from "../../../utils/hooks/useGetState";
import { executeACGAction, updateScreenIdentifiers } from "../../../store/slice";

import AlarmAnalysisDrawer from "./AlaramAnalysis";
import AuditReportDrawer from "./AuditReport";
import BatchReportDrawer from "./BatchReport";
import AlarmReportDrawer from "./AlarmReport";
import DowntimeReportDrawer from "./DowntimeReport";
import MPRBQSReportsDrawer from "./MPRBQSReports";
import MPRProtabReportDrawer from "./MPRProtabReport";

import SnackbarAlert from "../../../atoms/Snackbar";
import CSVReport from "./CSVReport";
import { SCREEN_TITLES } from "../../../constants/StringConstants";

const DownloadableReport = () => {
    const [page, setPage] = useState<number>(0);
    const [filterName, setFilterName] = useState<string>("");
    const [tableData, setTableData] = useState<any[]>([]);
    const [masterTableData, setMasterTableData] = useState<any[]>([]);

    const [dnRptAlarmDrawerOpen, setdnRptAlarmDrawerOpen] = useState(false);
    const [dnRptAuditDrawerOpen, setdnRptAuditDrawerOpen] = useState(false);
    const [dnRptBatchDrawerOpen, setBatchDrawerOpen] = useState(false);
    const [dnRptBQSDrawerOpen, setdnRptBQSDrawerOpen] = useState(false);
    const [dnRptPROTABDrawerOpen, setdnRptPROTABDrawerOpen] = useState(false);
    const [dnRptDowntimeRPTDrawerOpen, setdnRptDowntimeRPTDrawerOpen] = useState(false);
    const [dnRptRawBDrawerOpen, setRptRawBDrawerOpen] = useState(false);
    const [dnRptAlarmReportDrawerOpen, setAlarmReportDrawerOpen] = useState(false);

    const options = {
        DEFAULT: {
            message: '',
            open: false,
            type: ''
        },
        INVALID_RPT_CODE: {
            message: 'Invalid Report Code!',
            open: true,
            type: 'success'
        },
        SOME_ERROR: {
            message: 'Invalid report code!',
            open: true,
            type: 'success'
        },
        PDF_WIP_ERROR: {
            message: 'PDF reports work in progress',
            open: true,
            type: 'success'
        }
    };

    const [snackbarOptions, setSnackbarOptions] = useState(options.DEFAULT);

    const [selectedRow, setSelectedRow] = useState<any>(null);
    const [selectedIndex, setSelectedIndex] = useState<number>(-1);

    const dispatch = useDispatch();

    const stateObj = useGetState();

    const getDownloadableReports = () => {
        dispatch(
            executeACGAction({
                payload: {
                    requestType: 'GET',
                    urlPath: ACTION_CODES.GET_DNWDRPTS_ROLE_BASED
                },
                uniqueScreenIdentifier: { reportsLoaded: true },
                storeKey: STORE_KEYS.GET_DNWDRPTS_ROLE_BASED
            })
        );
    };

    useEffect(() => {
        getDownloadableReports();
    }, []);

    useEffect(() => {
        if (stateObj?.[STORE_KEYS.GET_DNWDRPTS_ROLE_BASED]?.reportsLoaded) {
            const list = stateObj?.[STORE_KEYS.GET_DNWDRPTS_ROLE_BASED]?.body?.data;
            const setData = list?.map((mod: any) => {
                const record = { ...mod };
                record.MachineModelName = record?.ReportName;
                return record;
            });

            setMasterTableData([...setData]);
            setTableData(setData);
        }
    }, [stateObj?.[STORE_KEYS.GET_DNWDRPTS_ROLE_BASED]?.reportsLoaded]);

    const handleChangePage = (
        event: any,
        newPage: React.SetStateAction<number>
    ) => {
        setPage(newPage);
    };

    const handleFilterByName = (event: { target: { value: React.SetStateAction<string> } }) => {

        setPage(0);

        if (event.target.value === undefined) //for clear button action
        {
            setFilterName("");
            setTableData(masterTableData);
        }
        else {
            setFilterName(event.target.value);
            const filteredArray = event.target.value.length > 0 ?
                masterTableData.filter(obj => obj.ReportName.toLowerCase().includes(String(event.target.value).toLowerCase())) :
                masterTableData;
            setTableData(filteredArray);
        }
    };

    const closeDrawer = () => {
        setdnRptAlarmDrawerOpen(false);
        setAlarmReportDrawerOpen(false);
        setdnRptAuditDrawerOpen(false);
        setBatchDrawerOpen(false);
        setdnRptBQSDrawerOpen(false);
        setdnRptPROTABDrawerOpen(false);
        setdnRptDowntimeRPTDrawerOpen(false);
        setRptRawBDrawerOpen(false);
        setSelectedRow(null);
        setSelectedIndex(-1);
    };

    const closeSnackbar = () => setSnackbarOptions(options.DEFAULT);

    return (
        <div className="dashboard">
            <Container maxWidth="xl" style={{ marginTop: '60px' }}>
                <div className='headinglabel'>{SCREEN_TITLES.DOWNLOADS}</div>
                <div style={{ marginTop: '35px', zIndex: '0' }}>
                </div>
                <div style={{ marginTop: '15px', zIndex: '0' }}>
                    <div>
                        <SnackbarAlert options={snackbarOptions} handleClose={closeSnackbar} />
                        <Container
                            maxWidth="xl"
                            style={{
                                paddingLeft: "3px",
                                paddingRight: "3px",
                            }}
                        >
                            <Card
                                style={{
                                    borderRadius: "0px",
                                    backgroundColor: "#2b2d42",
                                    overflow: "inherit",
                                }}
                            >
                                <CustomTableToolbar
                                    numSelected={0}
                                    filterName={filterName}
                                    onFilterName={handleFilterByName}
                                    resetInput={handleFilterByName}
                                    displayInput={true}
                                    text={{
                                        title: "Total Reports",
                                        subTitle: `${tableData?.length} Reports`,
                                    }}
                                    filterButton={false}
                                    customButton={false}
                                />
                            </Card>
                        </Container>
                        <CustomTable
                            selectable={false}
                            headers={[]}
                            body={tableData}
                            filteredUsers={tableData}
                            filterName={""}
                            selected={[]}
                            order={""}
                            orderBy={""}
                            onRequestSort={() => { }}
                            onSelectAllClick={() => { }}
                            rowChange={() => { }}
                            rowCount={tableData.length}
                            onPageChange={handleChangePage}
                            route={
                                () => { }
                            }
                            page={page}
                            setPageNumber={setPage}
                            selectRow={(rowIndex: any) => {

                                setSelectedIndex(rowIndex);
                                setSelectedRow(tableData[rowIndex]);

                                const reportCode = tableData[rowIndex].ReportCode;

                                reportCode === null || undefined ? setSnackbarOptions(options.INVALID_RPT_CODE) :
                                    reportCode === "ALARMRPT" ? setAlarmReportDrawerOpen(true) ://setdnRptAlarmDrawerOpen(true) :
                                        reportCode === "ADTRPT" ? setdnRptAuditDrawerOpen(true) :
                                            reportCode === "BATRPT" ? setBatchDrawerOpen(true) :
                                                reportCode === "MPRBQS" ? setSnackbarOptions(options.PDF_WIP_ERROR) ://setdnRptBQSDrawerOpen(true) :
                                                    reportCode === "MPRPROTAB" ? setSnackbarOptions(options.PDF_WIP_ERROR) ://setdnRptPROTABDrawerOpen(true) :
                                                        reportCode === "RAW" ? setRptRawBDrawerOpen(true) :
                                                            reportCode === "DT" ? setdnRptDowntimeRPTDrawerOpen(true) : setSnackbarOptions(options.SOME_ERROR);
                            }
                            }
                            isRequestPage={true}
                            link={true}
                            type="User_Reports_List"
                        />
                        <AlarmAnalysisDrawer
                            handlerOpen={() => { }}
                            handlerClose={closeDrawer}
                            status={dnRptAlarmDrawerOpen}
                            block={true}
                        />
                        <AuditReportDrawer
                            handlerOpen={() => { }}
                            handlerClose={closeDrawer}
                            status={dnRptAuditDrawerOpen}
                            block={true}
                        />
                        <BatchReportDrawer
                            handlerOpen={() => { }}
                            handlerClose={closeDrawer}
                            status={dnRptBatchDrawerOpen}
                            block={true}
                        />
                        <MPRBQSReportsDrawer
                            handlerOpen={() => { }}
                            handlerClose={closeDrawer}
                            status={dnRptBQSDrawerOpen}
                            block={true}
                        />
                        <MPRProtabReportDrawer
                            handlerOpen={() => { }}
                            handlerClose={closeDrawer}
                            status={dnRptPROTABDrawerOpen}
                            block={true}
                        />
                        <DowntimeReportDrawer
                            handlerOpen={() => { }}
                            handlerClose={closeDrawer}
                            status={dnRptDowntimeRPTDrawerOpen}
                        />
                        <CSVReport
                            handlerOpen={() => { }}
                            handlerClose={closeDrawer}
                            status={dnRptRawBDrawerOpen}
                        />
                        <AlarmReportDrawer
                            handlerOpen={() => { }}
                            handlerClose={closeDrawer}
                            status={dnRptAlarmReportDrawerOpen}
                        />
                    </div>
                </div>
            </Container>
        </div>
    );
};

export default DownloadableReport;