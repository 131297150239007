import * as React from 'react';
import { useState, useEffect } from 'react';
import CustomTable from '../../../atoms/CustomTable/index';
import CustomTableToolbar from '../../../atoms/CustomTableToolbar';
import { Container, Card, Box } from '@mui/material';
import { applySortFilter } from '../../../utils/helper';
import TemporaryDrawer from '../../../atoms/tempDrawer';
import MachineMonitoringDrawer from '../../../atoms/MachineMonitoringDrawer';
import { useDispatch } from 'react-redux';
import { executeACGAction, resetErr, resetStoreKey, updateScreenIdentifiers } from '../../../store/slice';
import { ACTION_CODES, STORE_KEYS } from '../../../constants/apiConstants';
import useGetState from '../../../utils/hooks/useGetState';
import SnackbarAlert from '../../../atoms/Snackbar';
import AlertBox from '../../../atoms/AlertBox';
import FilterDrawer from '../../../atoms/FilterDrawer';
import DropDown from '../../../atoms/Dropdown';
import Label from '../../../atoms/Label/index';
import { renameObjKeys } from '../../Login/helper';
import EditMachine from '../EditMachine/EditMachine';
import RightDrawer from '../../../atoms/tempDrawer/indexAlt';
import GenerateToken from './GenerateToken';

const TABLE_HEAD = [
    { id: 'MachineName', label: 'Machine Name', alignRight: false },
    { id: 'CustomerName', label: 'Client', alignRight: false },
    { id: 'MachineModelName', label: 'Model', alignRight: false },
    { id: 'MachineSerialNo', label: 'Serial No', alignRight: false },
    { id: 'MachineFamilyName', label: 'Machine Family', alignRight: false },
    { id: 'OnboardStatus', label: 'Status', alignRight: false },
    { id: 'Action', label: 'Action', alignLeft: true }
];

type Props = { getSummary?: any };
const options = {
    DEFAULT: {
        message: '',
        open: false,
        type: ''
    },
    RM_SUCCESS: {
        message: 'Machine deleted successfully!',
        open: true,
        type: 'success'
    },
    UN_SUCCESS: {
        message: 'Machine Unassigned successfully!',
        open: true,
        type: 'success'
    }
};

const Machines = (props: Props) => {
    const { getSummary } = props;
    const [page, setPage] = useState<number>(0);
    const [snackbarOptions, setSnackbarOptions] = useState(options.DEFAULT);
    const [order, setOrder] = useState('desc');
    const [orderBy, setOrderBy] = useState('MachineName');
    const [selected, setSelected] = useState<any>([]);
    const [filterName, setFilterName] = useState<string>('');
    const [removeBxOpen, setRemoveBxOpen] = useState(false);
    const [unMach, setUnMach] = useState(false);
    const [rmMach, setRmMach] = useState(false);
    const [createDrawerOpen, setCreateDrawerOpen] = useState(false);
    const [editDrawerOpen, setEditDrawerOpen] = useState(false);
    const [tokenDrawerOpen, setTokenDrawerOpen] = useState(false);
    const [selectedRow, setSelectedRow] = useState<any>([]);
    const dispatch = useDispatch();
    const stateObj = useGetState();
    const [radioValue, setRadioValue] = useState('All');
    const [machineData, setMachineData] = useState<any>([]);
    const [machines, setMachines] = useState<any>([]);
    const [isUnasgnd, setIsUnasgnd] = useState<any>(false);
    const [filterOpen, setFilterOpen] = useState<any>(false);
    const [filterMd, setFilterMd] = useState<any>('All');
    const [filterMf, setFilterMf] = useState<any>('All');
    const [machinesReLoad, setMachinesReLoad] = useState(false);
    const [isMachineLoading,setIsMachineLoading]=useState(false)
    const filters = {
        mdFilter: (item: any) => item?.MachineModelKey === filterMd,
        mfFilter: (item: any) => item?.MachineFamilyKey === filterMf,
        stFilter: (item: any) => item?.OnboardStatus === radioValue,
        srchFilter: (item: any) => item?.MachineName?.toLowerCase()?.includes(filterName?.toLowerCase())
    };
    const [onBoard, setOnBoard] = useState(false);
    const closeRmBx = () => {
        setRemoveBxOpen(false);
        setIsUnasgnd(false);
    };
    const getMachines = () => {
        setIsMachineLoading(true)
        dispatch(
            executeACGAction({
                payload: {
                    requestType: 'POST',
                    urlPath: ACTION_CODES.GET_ALLMACHINES,
                    reqObj: {
                        onboardStatus: 'All',
                        customerKey: -1
                    }
                },
                uniqueScreenIdentifier: { mchsRecd: true },
                storeKey: STORE_KEYS.ALL_MACHINES
            })
        );
    };
    const getMachFamily = () => {
        dispatch(
            executeACGAction({
                payload: {
                    requestType: 'GET',
                    urlPath: ACTION_CODES.MACH_FAMILY
                },
                uniqueScreenIdentifier: { machFam: true },
                storeKey: STORE_KEYS.MACHFAMILY
            })
        );
    };
    const [filterModels, setFilterModels] = useState<any>([]);
    const [filterMfs, setFilterMfs] = useState<any>([]);

    useEffect(() => {
        getMachines();
        getMachFamily();
    }, []);

    useEffect(() => {
        if (stateObj?.[STORE_KEYS.MACHFAMILY]?.machFam) {
            const arry = [{ code: 'All', name: 'All' }];
            setFilterModels([
                ...arry,
                ...stateObj?.[STORE_KEYS.MODELS]?.body?.data?.map((item: any) => {
                    const newVal = renameObjKeys(item, { MachineModelKey: 'code', MachineModelName: 'name' });
                    return newVal;
                })
            ]);
            setFilterMfs([
                ...arry,
                ...stateObj?.[STORE_KEYS.MACHFAMILY]?.body?.data?.map((item: any) => {
                    const newVal = renameObjKeys(item, { MachineFamilyKey: 'code', MachineFamilyName: 'name' });
                    return newVal;
                })
            ]);
            dispatch(
                updateScreenIdentifiers({
                    storeKey: STORE_KEYS.MACHFAMILY,
                    uniqueScreenIdentifier: {
                        machFam: false
                    }
                })
            );
        }
    }, [stateObj?.[STORE_KEYS.MACHFAMILY]?.machFam]);

    useEffect(() => {
        if (stateObj?.[STORE_KEYS.ALL_MACHINES]?.mchsRecd) {
            setFilterName('');
            setMachineData(stateObj?.[STORE_KEYS.ALL_MACHINES]?.body?.data);
            if (radioValue !== 'All') {
                const newData = [...stateObj?.[STORE_KEYS.ALL_MACHINES]?.body?.data];
                const x = newData?.filter((ele: any) => ele?.ConnectivityStatus === radioValue);
                setMachines(x);
            } else {
                setMachines(stateObj?.[STORE_KEYS.ALL_MACHINES]?.body?.data);
            }

            dispatch(
                updateScreenIdentifiers({
                    storeKey: STORE_KEYS.ALL_MACHINES,
                    uniqueScreenIdentifier: {
                        mchsRecd: false
                    }
                })
            );
            setIsMachineLoading(false)
        }
    }, [stateObj?.[STORE_KEYS.ALL_MACHINES]?.mchsRecd]);

    const handleRadioValue = (val: string) => {
        setRadioValue(val);
    };

    useEffect(() => {
        let selectedFilter: any = [];
        if (filterMd !== 'All') {
            selectedFilter = [...selectedFilter, filters.mdFilter];
        }
        if (filterMf !== 'All') {
            selectedFilter = [...selectedFilter, filters.mfFilter];
        }
        if (radioValue !== 'All') {
            selectedFilter = [...selectedFilter, filters.stFilter];
        }
        if (filterName !== '') {
            selectedFilter = [...selectedFilter, filters.srchFilter];
        }
        if (selectedFilter?.length !== 0) {
            const x = [...machineData]?.filter((ele: any) => selectedFilter?.every((f: any) => f(ele)));
            setMachines(x);
        } else {
            setMachines([...machineData]);
        }
    }, [radioValue]);

    const filteredList = applySortFilter(machines, order, filterName, orderBy);

    const handleRequestSort = (event: any, property: any) => {
        setPage(0);
        setFilterName('');
        const isAsc = orderBy === event && order === 'asc';
        setOrder(isAsc ? 'desc' : 'asc');
        setOrderBy(event);
    };

    const handleSelectAllClick = (event: any) => {};

    const handleClick = (requestedBy: string) => {
        const selectedIndex = selected.indexOf(requestedBy);
        let newSelected: any[] | ((prevState: unknown[]) => unknown[]) = [];
        if (selectedIndex === -1) {
            newSelected = newSelected.concat(selected, requestedBy);
        } else if (selectedIndex === 0) {
            newSelected = newSelected.concat(selected.slice(1));
        } else if (selectedIndex === selected.length - 1) {
            newSelected = newSelected.concat(selected.slice(0, -1));
        } else if (selectedIndex > 0) {
            newSelected = newSelected.concat(selected.slice(0, selectedIndex), selected.slice(selectedIndex + 1));
        }
        setSelected(newSelected);
    };

    const handleFilterByName = (event: any) => {
        setOrderBy('MachineName');
        setPage(0);
        setFilterName(event.target.value);
        let selectedFilter: any = [];
        if (filterMd !== 'All') {
            selectedFilter = [...selectedFilter, filters.mdFilter];
        }
        if (filterMf !== 'All') {
            selectedFilter = [...selectedFilter, filters.mfFilter];
        }
        if (radioValue !== 'All') {
            selectedFilter = [...selectedFilter, filters.stFilter];
        }
        if (filterName !== '') {
            selectedFilter = [...selectedFilter, filters.srchFilter];
        }
        if (selectedFilter?.length !== 0) {
            const x = [...machineData]?.filter((ele: any) => selectedFilter?.every((f: any) => f(ele)));
            setMachines(x);
        } else {
            setMachines([...machineData]);
        }
    };

    const handleChangePage = (event: any, newPage: React.SetStateAction<number>) => {
        setPage(newPage);
    };

    const showRow = (event: any) => {};

    const getMachineDetailsSummary = (key: any) => {
        dispatch(
            executeACGAction({
                payload: {
                    requestType: 'GET',
                    urlPath: `${ACTION_CODES.GET_MACHINE_SUMMARY_DETAILS}/${key}`
                },
                uniqueScreenIdentifier: {
                    summaryAdded: true
                },
                storeKey: STORE_KEYS.MACHINE_DETAIL_SUMMARY
            })
        );
    };
    useEffect(() => {
        if (stateObj?.[STORE_KEYS.MACHINE_DETAIL_SUMMARY]?.body?.data) {
            if (stateObj?.[STORE_KEYS.MACHINE_DETAIL_SUMMARY]?.statusCode === 200) {
                const __data = stateObj?.[STORE_KEYS.MACHINE_DETAIL_SUMMARY]?.body?.data?.[0];
                setSelectedRow({ ...selectedRow, ...__data });
                dispatch(resetStoreKey({ storeKey: STORE_KEYS.MACHINE_DETAIL_SUMMARY }));
            }
        }
    }, [stateObj?.[STORE_KEYS.MACHINE_DETAIL_SUMMARY]?.body?.data]);

    const handleRoute = async (row: any, ele: any) => {
        if (ele === 'Action') {
            setSelectedRow(row);
            await getMachineDetailsSummary(row?.MachineKey);
            setCreateDrawerOpen(true);
        }
    };

    const resetInput = () => {
        setPage(0);
        setFilterName('');
        let selectedFilter: any = [];
        if (filterMd !== 'All') {
            selectedFilter = [...selectedFilter, filters.mdFilter];
        }
        if (filterMf !== 'All') {
            selectedFilter = [...selectedFilter, filters.mfFilter];
        }
        if (radioValue !== 'All') {
            selectedFilter = [...selectedFilter, filters.stFilter];
        }
        if (selectedFilter?.length !== 0) {
            const x = [...machineData]?.filter((ele: any) => selectedFilter?.every((f: any) => f(ele)));
            setMachines(x);
        } else {
            setMachines([...machineData]);
        }
    };

    const closeDrawer = () => {
        setSelectedRow([]);
        setCreateDrawerOpen(false);
    };

    const [machKey, setMachKey] = useState<any>();

    const removeMach = (ele: any) => {
        setMachKey(ele?.MachineKey);
        setRemoveBxOpen(true);
    };

    const unassigned = (ele: any) => {
        setMachKey(ele?.MachineKey);
        setRemoveBxOpen(true);
        setIsUnasgnd(true);
    };

    useEffect(() => {
        if (rmMach) {
            closeRmBx();
            dispatch(
                executeACGAction({
                    payload: {
                        requestType: 'DELETE',
                        urlPath: `${ACTION_CODES.DELETE_MACHINE}/${machKey}`
                    },
                    uniqueScreenIdentifier: {
                        machRemoved: true
                    },
                    storeKey: STORE_KEYS.DELETE_RPT
                })
            );
            setRmMach(false);
        }
    }, [rmMach]);

    useEffect(() => {
        if (unMach) {
            closeRmBx();
            dispatch(
                executeACGAction({
                    payload: {
                        requestType: 'POST',
                        urlPath: `${ACTION_CODES.UNASSIGN_MACHINE}/${machKey}`
                    },
                    uniqueScreenIdentifier: {
                        machUnassigned: true
                    },
                    storeKey: STORE_KEYS.DELETE_RPT
                })
            );
            setUnMach(false);
        }
    }, [unMach]);

    const handleAction = () => {
        if (isUnasgnd) {
            setUnMach(true);
        } else {
            setRmMach(true);
        }
    };

    useEffect(() => {
        if (stateObj?.[STORE_KEYS.DELETE_RPT]?.machRemoved) {
            if (stateObj?.[STORE_KEYS.DELETE_RPT]?.statusCode === 200) {
                setSnackbarOptions(options.RM_SUCCESS);
                getSummary();
                getMachines();
            }
            dispatch(
                updateScreenIdentifiers({
                    storeKey: STORE_KEYS.DELETE_RPT,
                    uniqueScreenIdentifier: {
                        machRemoved: false
                    }
                })
            );
        }
    }, [stateObj?.[STORE_KEYS.DELETE_RPT]?.machRemoved]);

    useEffect(() => {
        if (stateObj?.[STORE_KEYS.DELETE_RPT]?.machUnassigned) {
            if (stateObj?.[STORE_KEYS.DELETE_RPT]?.statusCode === 200) {
                closeDrawer();
                setSnackbarOptions(options.UN_SUCCESS);
                getSummary();
                getMachines();
            }
            dispatch(
                updateScreenIdentifiers({
                    storeKey: STORE_KEYS.DELETE_RPT,
                    uniqueScreenIdentifier: {
                        machUnassigned: false
                    }
                })
            );
        }
    }, [stateObj?.[STORE_KEYS.DELETE_RPT]?.machUnassigned]);
    useEffect(() => {
        if (machinesReLoad) {
            getSummary();
            getMachines();
            setRadioValue('All');
            setFilterMd('All');
            setFilterMf('All');
            setFilterMfs('All');
            setFilterName('');
            setMachinesReLoad(false);
        }
    }, [machinesReLoad]);

    const closeSnackbar = () => setSnackbarOptions(options.DEFAULT);
    const handleSnackbarError = (err: any) => {
        const errorMsg = err?.message || 'Internal Server error';
        const snackbarError = {
            message: errorMsg,
            type: 'reject',
            open: true
        };
        setSnackbarOptions(snackbarError);
    };

    useEffect(() => {
        if (stateObj?.err) {
            handleSnackbarError(stateObj?.err);
            dispatch(resetErr());
        }
    }, [stateObj?.err]);

    const clearFilter = () => {
        setPage(0);
        setFilterMd('All');
        setFilterMf('All');
        let selectedFilter: any = [];
        if (radioValue !== 'All') {
            selectedFilter = [...selectedFilter, filters.stFilter];
        }
        if (filterName !== '') {
            selectedFilter = [...selectedFilter, filters.srchFilter];
        }
        if (selectedFilter?.length !== 0) {
            const x = [...machineData]?.filter((ele: any) => selectedFilter?.every((f: any) => f(ele)));
            setMachines(x);
        } else {
            setMachines([...machineData]);
        }
        setFilterOpen(false);
    };

    const applyFilter = () => {
        setPage(0);
        let selectedFilter: any = [];
        if (filterMd !== 'All') {
            selectedFilter = [...selectedFilter, filters.mdFilter];
        }
        if (filterMf !== 'All') {
            selectedFilter = [...selectedFilter, filters.mfFilter];
        }
        if (radioValue !== 'All') {
            selectedFilter = [...selectedFilter, filters.stFilter];
        }
        if (filterName !== '') {
            selectedFilter = [...selectedFilter, filters.srchFilter];
        }
        if (selectedFilter?.length !== 0) {
            const x = [...machineData]?.filter((ele: any) => selectedFilter?.every((f: any) => f(ele)));
            setMachines(x);
        } else {
            setMachines([...machineData]);
        }
        setFilterOpen(false);
    };

    const machineFilter = () => {
        return (
            <Container className="createReport">
                <Box style={{ minHeight: '75vh' }}>
                    <Label label="Model" className={'labelDiv'} />
                    <DropDown
                        onChange={(event: any) => {
                            setFilterMd(event.target.value);
                        }}
                        name="dropdownMd"
                        className="inputDiv"
                        items={filterModels}
                        value={filterMd}
                    />
                    <Label label="Machine Family" className={'labelDiv'} />
                    <DropDown
                        onChange={(event: any) => {
                            setFilterMf(event.target.value);
                        }}
                        name="dropdownMf"
                        className="inputDiv"
                        items={filterMfs}
                        value={filterMf}
                    />
                </Box>
            </Container>
        );
    };

    return (
        <div className='tabmachine'>
            <SnackbarAlert options={snackbarOptions} handleClose={closeSnackbar} />
            <div>
                <Container
                    maxWidth="xl"
                    style={{
                        paddingLeft: '3px',
                        paddingRight: '3px'
                    }}
                >
                    <Card
                        style={{
                            borderRadius: '0px',
                            backgroundColor: '#2b2d42',
                            overflow: 'inherit'
                        }}
                    >
                        <CustomTableToolbar
                            drawerOpen={() => setFilterOpen(true)}
                            numSelected={selected?.length}
                            filterName={filterName}
                            onFilterName={handleFilterByName}
                            displayInput={true}
                            text={{
                                title: 'Machine List',
                                subTitle: `${filteredList?.length} machines`
                            }}
                            filterButton={true}
                            customButton={false}
                            radios={true}
                            radioValue={radioValue}
                            radioFields={[
                                { label: 'All', value: 'All' },
                                { label: 'Unassigned', value: 'Unassigned' },
                                { label: 'Onboarded', value: 'Onboarded' }
                            ]}
                            onRadioChange={handleRadioValue}
                            resetInput={resetInput}
                        />
                    </Card>
                </Container>
                <CustomTable
                    selectable={false}
                    headers={TABLE_HEAD}
                    body={filteredList}
                    filteredUsers={filteredList}
                    filterName={filterName}
                    selected={selected}
                    order={order}
                    orderBy={orderBy}
                    onRequestSort={handleRequestSort}
                    onSelectAllClick={handleSelectAllClick}
                    rowChange={(event: string) => handleClick(event)}
                    rowCount={filteredList?.length}
                    onPageChange={handleChangePage}
                    page={page}
                    setPageNumber={setPage}
                    selectRow={showRow}
                    route={handleRoute}
                    isRequestPage={true}
                    link={true}
                    noDataText={'No Machine data available'}
                    removeFn={removeMach}
                    isLoading={isMachineLoading}
                />
            </div>
            <AlertBox
                isOpen={removeBxOpen}
                handleClose={closeRmBx}
                leftBtnPress={closeRmBx}
                rightBtnPress={handleAction}
                modalHeader={isUnasgnd ? 'Unassigned Machine' : 'Delete Machine'}
                modalContent={`Are you sure you want to ${isUnasgnd ? 'unassigned' : 'delete'} machine?`}
            />
            <MachineMonitoringDrawer
                handlerOpen={() => {}}
                handlerClose={closeDrawer}
                status={createDrawerOpen}
                deleteFn={() => removeMach(selectedRow)}
                unassignFn={(dt: any) => unassigned(dt)}
                data={selectedRow}
                block={false}
                type={'machines'}
                editFn={() => {
                    setEditDrawerOpen(true);
                }}
                setMachinesReLoad={setMachinesReLoad}
                setSelectedRow={setSelectedRow}
                toggleTokenModal={setTokenDrawerOpen}
            />
            <FilterDrawer
                handlerClose={() => {
                    setFilterOpen(false);
                }}
                status={filterOpen}
                drawerHeader={'Filter'}
                children={<>{machineFilter()}</>}
                clearFn={clearFilter}
                applyFn={applyFilter}
            />

            {tokenDrawerOpen && (
                <RightDrawer
                    handlerOpen={() => {}}
                    handlerClose={() => setTokenDrawerOpen(false)}
                    status={tokenDrawerOpen}
                    drawerHeader={'Generate SAS Token'}
                    children={
                        <GenerateToken
                            machineSerialNo={selectedRow?.MachineSerialNo}
                            drawerClose={() => setTokenDrawerOpen(false)}
                            setSnackbarOpts={setSnackbarOptions}
                        />
                    }
                />
            )}
            {editDrawerOpen && (
                <RightDrawer
                    handlerOpen={() => {}}
                    handlerClose={() => setEditDrawerOpen(false)}
                    status={createDrawerOpen}
                    drawerHeader={'Edit Machine'}
                    children={
                        <EditMachine
                            data={selectedRow}
                            closeDrawer={() => setEditDrawerOpen(false)}
                            setSnackbarOpts={setSnackbarOptions}
                        />
                    }
                    type="model"
                />
            )}
        </div>
    );
};

export default Machines;
