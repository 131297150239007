import React, { useState, useEffect } from 'react';
import DropDown from '../../../atoms/Dropdown';
import { batch, useDispatch } from 'react-redux';
import useGetState from '../../../../app/utils/hooks/useGetState';
import { executeACGAction, updateScreenIdentifiers } from '../../../../app/store/slice';
import { ACTION_CODES, STORE_KEYS, BASEURL } from '../../../../app/constants/apiConstants';
import ACGButton from '../../../atoms/Button';
import "./notification.scss";

const NotificationCard = (props: any) => {
    const [value, setValue] = useState<any>('');
    const [recommendList, setRecommendList] = useState<any>([]);
    const dispatch = useDispatch();
    const state = useGetState();
    const loggedInUser = state[STORE_KEYS.USER_DTL]?.LoggedUserKey;

    useEffect(() => {
        const list = JSON.parse(props.item?.RecActions);
        setRecommendList([
            ...list?.map((item: any) => ({ code: item.GBAlertParamRecActionKey, name: item.RecActionName }))
        ]);
    }, [props.items]);

    const postActiveAlertResponse = (type: string) => {
        dispatch(
            executeACGAction({
                payload: {
                    requestType: 'POST',
                    urlPath: ACTION_CODES.POST_GB_ACTIVE_ALERT_RESPONSE,
                    reqObj: {
                        "LoggedUserKey": parseInt(loggedInUser),
                        "GBAlertKey": parseInt(props?.item?.GBAlertKey),
                        "AckType": type,
                        "GBAlertParamRecActionKey": type === "RC" ? parseInt(value) : null
                    }
                },
                uniqueScreenIdentifier: { sentResponse: true },
                storeKey: STORE_KEYS.POST_GB_ACTIVE_ALERT_RESPONSE
            })
        );
        setValue('')
    }

    return (
        <div className={props.newActiveAlertItems.has(props.item?.GBAlertId) || props.newActiveAlertItems.size == 1 ?'innerCards borderanimation' : 'innerCards'}>
            <div className='idTitle'>
                <div className='idnumber'>ID: </div>
                <div className='serialnumber'>{props.item?.GBAlertId}</div>
                <div className='viewIcons' onClick={() => { props.setSelectedActiveParameter(props.item?.MMParamKey) }}><svg width="16" height="16" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
                    <path d="M10.0017 13.61C8.01001 13.61 6.39334 11.9933 6.39334 10.0016C6.39334 8.00998 8.01001 6.39331 10.0017 6.39331C11.9933 6.39331 13.61 8.00998 13.61 10.0016C13.61 11.9933 11.9933 13.61 10.0017 13.61ZM10.0017 7.64331C8.70167 7.64331 7.64334 8.70164 7.64334 10.0016C7.64334 11.3016 8.70167 12.36 10.0017 12.36C11.3017 12.36 12.36 11.3016 12.36 10.0016C12.36 8.70164 11.3017 7.64331 10.0017 7.64331Z" fill="#B1C2DF" />
                    <path d="M9.99833 17.5167C6.865 17.5167 3.90666 15.6834 1.87333 12.5001C0.989997 11.1251 0.989997 8.8834 1.87333 7.50006C3.915 4.31673 6.87333 2.4834 9.99833 2.4834C13.1233 2.4834 16.0817 4.31673 18.115 7.50006C18.9983 8.87506 18.9983 11.1167 18.115 12.5001C16.0817 15.6834 13.1233 17.5167 9.99833 17.5167ZM9.99833 3.7334C7.30666 3.7334 4.73166 5.35007 2.93166 8.17507C2.30666 9.15007 2.30666 10.8501 2.93166 11.8251C4.73166 14.6501 7.30666 16.2667 9.99833 16.2667C12.69 16.2667 15.265 14.6501 17.065 11.8251C17.69 10.8501 17.69 9.15007 17.065 8.17507C15.265 5.35007 12.69 3.7334 9.99833 3.7334Z" fill="#B1C2DF" />
                </svg>
                </div>
                <div className='closeIcons'>
                    <svg width="25" height="24" viewBox="0 0 25 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                        <path opacity="0.88" d="M18.5001 6L12.5001 12L6.50006 18" stroke="#e0e0e0" stroke-linecap="round" stroke-linejoin="round" />
                        <path opacity="0.88" d="M6.50006 6L18.5001 18" stroke="#e0e0e0" stroke-linecap="round" stroke-linejoin="round" />
                    </svg>
                </div>
            </div>
            <div className='innereTitle'>
                <span><strong>Condition:</strong> {props.item?.Condition}</span>
            </div>
            <div className='dropboxbtn'>
                <DropDown
                    onChange={(e: any) => {
                        setValue(e.target.value)
                    }}
                    name="dropdownSerialNo"
                    items={recommendList}
                    placeHolder="Select Action"
                    value={value}
                    style={{ padding: 0 }}
                />
            </div>
            <div className='btnAct'>
                <div className='submitBtn'>
                    <ACGButton
                        name={'Submit'}
                        handleClick={() => postActiveAlertResponse("RC")}
                        type="submit"
                        className="second-btn widthbtnn"
                        variant="contained"
                        disabled={value === "" ? true : false}
                    />
                </div>
                <div className='ackNoledge'>
                    <ACGButton
                        name={'Acknowledge'}
                        handleClick={() => postActiveAlertResponse("Ack")}
                        type="submit"
                        className="second-btn widthbtnn"
                        variant="contained"
                    />
                </div>
            </div>
        </div>
    );
};

export default NotificationCard;
