import React, { useState, useEffect } from 'react'
import TableHeaderComponent from './TableHeader/TableHeader'
import TableBodyContainer from './TableBody/TableBodyContainer'
import { Table, TableContainer, TableRow, TableCell } from '@mui/material'
import { useDispatch } from 'react-redux';
import { executeACGAction } from '../../store/slice';
import { ACTION_CODES, STORE_KEYS } from '../../constants/apiConstants';
import useGetState from '../../utils/hooks/useGetState';
import SnackbarAlert from '../Snackbar';
import "./index.scss"
import { Diversity1 } from '@mui/icons-material';
import NoData from '../../../assets/nodata.svg'
import Loader from '../Loader';
const scrollCSS = {
  '&::-webkit-scrollbar': {
    width: '0.3em',
    height: '0.2em'
  },
  '&::-webkit-scrollbar-track': {
    backgroundColor: '#2b2d42',
    boxShadow: 'inset 0 0 6px rgba(0, 0, 0, 0.3)'
  },
  '&::-webkit-scrollbar-thumb': {
    backgroundColor: 'darkgrey',
    outline: '1px solid slategrey'
  }
};
type ComponentsProps = {
  HeaderList: any[],
  rows: any[],
  getAlertList?: any,
  handleEdit?: any,
  handleDelete?: any,
  isAction: boolean,
  loading: boolean,
  handleSorting:any,
  iconColName?:string,
  iconComponent?:any,
  iconClick?:any,
}

function CustomAlertsTable(props: ComponentsProps) {
  const { HeaderList, rows, getAlertList, handleEdit, isAction, loading,handleSorting ,handleDelete,iconColName,iconComponent,iconClick} = props;
  const dispatch = useDispatch();
  const stateVal = useGetState();
  const loggedInUser = stateVal[STORE_KEYS.USER_DTL]?.LoggedUserKey;

  const DropdownOptions = [
    { label: "Edit", click: (values: any) => { handleEdit(values) } },
    { label: "Delete", click: (values: any) => { handleDelete(values) } },
  ]

  return (
    <div className='container_table' style={{"background": "#2b2d42", "borderRadius": "0px 0px 10px 10px", "margin": "0 3px"}}>
      
      <TableContainer sx={{
        // minWidth: 300,
        // maxHeight: 300,
        ...scrollCSS
      }}
        className="customTable"
      >
        <Table className="customTable" >
          <TableHeaderComponent HeaderList={HeaderList} handleSorting={handleSorting} />
          {loading ? 
                                <TableRow className="emptyTable">
                                    <TableCell colSpan={14} style={{ width: '100%' }}>
                                        <Loader />
                                    </TableCell>
                                </TableRow>
                            
            : rows?.length
              ? <TableBodyContainer rows={rows} DropdownOptions={DropdownOptions} HeaderList={HeaderList} isAction={isAction} iconColName={iconColName} iconComponent={iconComponent} iconClick={iconClick}/>
              : <TableRow className="emptyTable">
                <TableCell colSpan={7} style={{ width: '100%', border: "none" }}><div className="nodataRowAlerts">
                  <img src={NoData} alt="no data" />
                  <div className="no_text" style={{"fontFamily": "Montserrat", "fontSize": "1rem", "color": "#c0c2d8"}}>
                    No data to show
                  </div>
                </div></TableCell></TableRow>
          }
        </Table>
      </TableContainer>
    </div>

  )
}

export default CustomAlertsTable