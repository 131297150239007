import React, { useState, useEffect } from 'react';
import { useDispatch } from 'react-redux';
import CustomTable from '../../../../atoms/CustomTable/index';
import CustomTableToolbar from '../../../../atoms/CustomTableToolbar';
import { Container, Card } from '@mui/material';
import TemporaryDrawer from '../../../../atoms/tempDrawer';
import { ACTION_CODES, STORE_KEYS } from '../../../../constants/apiConstants';
import { executeACGAction, resetErr, resetStoreKey, updateScreenIdentifiers } from '../../../../store/slice';
import useGetState from '../../../../utils/hooks/useGetState';
import { applySortFilter } from '../../../../utils/helper';
import '../../ClientManagement/index.scss';
import SnackbarAlert from '../../../../atoms/Snackbar';
import AlertBox from '../../../../atoms/AlertBox';
import DetailDrawer from '../../../../atoms/DetailDrawer';
import { formatAccordionData } from '../../../../utils/formatData';

const TABLE_HEAD = [
    { id: 'UserName', label: 'Name', alignRight: false },
    { id: 'CustomerName', label: 'Customer', alignRight: false },
    { id: 'LastAccessed', label: 'Last Accessed', alignRight: false },
    { id: 'RoleName', label: 'Role', alignRight: false },
    { id: 'status', label: 'Status', alignRight: false },
    { id: 'Action', label: 'Action', alignLeft: true }
];

const options = {
    DEFAULT: {
        message: '',
        open: false,
        type: ''
    },
    RM_SUCCESS: {
        message: 'User Deleted successfully!',
        open: true,
        type: 'success'
    },
    USR_ADD_SUCCESS: {
        message: 'User Added successfully!',
        open: true,
        type: 'success'
    },
    SA_ACCESS_LIMIT: {
        message: 'Client Admin user cannot be viewed',
        open: true,
        type: 'reject'
    },
    MARK_SUCCESS: {
        message: 'User Activated successfully!',
        open: true,
        type: 'success'
    },
    UNMARK_SUCCESS: {
        message: 'User Inactivated successfully!',
        open: true,
        type: 'success'
    },
    UNLOCK_SUCCESS: {
        message: 'User Unlock successfully!',
        open: true,
        type: 'success'
    }
};

type Anchor = 'right';

const Users = (props: any) => {
    const {
        getSummary,
        radioValue,
        selectedClient,
        setDtlData,
        dtlData,
        setCreateValue,
        setDrawerHeader,
        setCreateDrawerOpen: setEditDrawerOpen,
        setTabChange,
        setPage,
        page
    } = props;

    const dispatch = useDispatch();
    const [drawerState, setDrawerState] = useState({
        right: false
    });
    
    const [order, setOrder] = useState('desc');
    const [orderBy, setOrderBy] = useState('UserName');
    const [selected, setSelected] = useState<any>([]);
    const [selectedRow, setSelectedRow] = useState<any>({});
    const [filterName, setFilterName] = useState<string>('');
    const [tableData, setTableData] = useState<any[]>([]);
    const [createDrawerOpen, setCreateDrawerOpen] = useState(false);
    const [isUsersLoading,setIsUsersLoading]=useState(false)

    const state = useGetState();

    const getList = () => {
        setIsUsersLoading(true)
        const loginData = state[STORE_KEYS.USER_DTL];
        const reqObj: {
            [key: string]: any;
        } = {
            userType: loginData?.RoleName === 'Client Admin' ? 'Customer' : radioValue
        };

        let pullData = true;
        if (loginData?.IsACGUser === 'Y') {
            if (radioValue === 'Customer') {
                reqObj.customerKey = selectedClient;
                pullData = Boolean(selectedClient);
            }
        } else {
            reqObj.customerKey = loginData?.CustomerKey;
        }

        pullData &&
            dispatch(
                executeACGAction({
                    payload: {
                        requestType: 'POST',
                        urlPath: ACTION_CODES.GET_USERS,
                        reqObj
                    },
                    storeKey: STORE_KEYS.USER_LIST
                })
            );
    };
    useEffect(() => {
        setTableData([]);
        getList();
    }, [radioValue, selectedClient]);

    useEffect(() => {
        const tmpTableData = state[STORE_KEYS.USER_LIST]?.body?.data;
        if(!tmpTableData) return 
        tmpTableData && setTableData(tmpTableData.map((item: any) => ({ ...item, status: item.UserStatus })));
        setIsUsersLoading(false)
    }, [state[STORE_KEYS.USER_LIST]?.body?.data]);

    const [snackbarOptions, setSnackbarOptions] = useState(options.DEFAULT);
    const [removeBxOpen, setRemoveBxOpen] = useState(false);
    const [activeBxOpen, setActiveBxOpen] = useState(false);
    const [unlockBxOpen, setUnlockBxOpen] = useState(false);
    const [activeAlertHeader, setActiveHeader] = useState("");
    const [unlockHeader, setUnlockHeader] = useState("");
    const [rmUser, setRmUser] = useState(false);
    const [activeInactive, setActiveInactive] = useState(false);
    const [unlockUser, setUnlockUser] = useState(false);
    const [userKey, setUserKey] = useState<any>();
    const [email, setEmail] = useState<any>();
    const closeRmBx = () => {
        setRemoveBxOpen(false);
        setActiveBxOpen(false);
        setUnlockBxOpen(false);
    };
    // const closeFnBx = () => {

    // };
    const removeFn = (ele: any) => {
        setUserKey(ele?.UserKey);
        setRemoveBxOpen(true);

    };

    const userUnlockFn = (ele: any) => {
        setEmail(ele?.EmailId);
        setUserKey(ele?.UserKey);
        setUnlockBxOpen(true);
        setUnlockHeader("Unlock")

    };

    const activeFn = (ele: any) => {
        setUserKey(ele?.UserKey);
        setActiveBxOpen(true);
        setActiveHeader(ele?.UserStatus !== "Active" ? "Activate" : "Inactivate")
    };

    useEffect(() => {
        if (unlockUser) {
            closeRmBx();
            dispatch(
                executeACGAction({
                    payload: {
                        requestType: 'POST',
                        urlPath: ACTION_CODES.UNLOCK_USER + userKey + '/'
                    },
                    uniqueScreenIdentifier: {
                        rowDeleted: true
                    },
                    storeKey: STORE_KEYS.UNLOCK
                })
            );
            setUnlockUser(false);
        }
    }, [unlockUser]);

    useEffect(() => {
        if (rmUser) {
            closeRmBx();
            dispatch(
                executeACGAction({
                    payload: {
                        requestType: 'DELETE',
                        urlPath: ACTION_CODES.DELETE_USER + userKey
                    },
                    uniqueScreenIdentifier: {
                        rowDeleted: true
                    },
                    storeKey: STORE_KEYS.DELETE
                })
            );
            setRmUser(false);
        }
    }, [rmUser]);
    useEffect(() => {
        if (state[STORE_KEYS.DELETE]?.rowDeleted) {
            if (state?.[STORE_KEYS.DELETE]?.statusCode === 200) {
                setSnackbarOptions(options.RM_SUCCESS);
                getList();
                getSummary();
                closeDrawer();
            }
            dispatch(
                updateScreenIdentifiers({
                    storeKey: STORE_KEYS.DELETE,
                    uniqueScreenIdentifier: {
                        rowDeleted: false
                    }
                })
            );
        }
    }, [state[STORE_KEYS.DELETE]?.rowDeleted]);

    useEffect(() => {
        if (state[STORE_KEYS.UNLOCK]?.rowDeleted) {
            if (state?.[STORE_KEYS.UNLOCK]?.statusCode === 200) {
                setSnackbarOptions(options.UNLOCK_SUCCESS);
                getList();
                getSummary();
                closeDrawer();
            }
            dispatch(
                updateScreenIdentifiers({
                    storeKey: STORE_KEYS.UNLOCK,
                    uniqueScreenIdentifier: {
                        rowDeleted: false
                    }
                })
            );
        }
    }, [state[STORE_KEYS.UNLOCK]?.rowDeleted]);

    useEffect(() => {
        if (state?.[STORE_KEYS.ADD_RPT]?.accessAdded) {
            if (state?.[STORE_KEYS.ADD_RPT]?.statusCode === 200) {
                setSnackbarOptions(options.USR_ADD_SUCCESS);
                getList();
                getSummary();
                setTabChange(2);
                setPage(0);
            }
            dispatch(
                updateScreenIdentifiers({
                    storeKey: STORE_KEYS.ADD_RPT,
                    uniqueScreenIdentifier: {
                        accessAdded: false
                    }
                })
            );
        }
    }, [state?.[STORE_KEYS.ADD_RPT]?.accessAdded]);

    useEffect(() => {
        if (state?.[STORE_KEYS.ADD_RPT]?.accessEdited) {
            if (state?.[STORE_KEYS.ADD_RPT]?.statusCode === 200) {
                setSnackbarOptions(options.USR_ADD_SUCCESS);
                getList();
                getSummary();
                setTabChange(2);
                setPage(0);
            }
            dispatch(
                updateScreenIdentifiers({
                    storeKey: STORE_KEYS.ADD_RPT,
                    uniqueScreenIdentifier: {
                        accessEdited: false
                    }
                })
            );
        }
    }, [state?.[STORE_KEYS.ADD_RPT]?.accessEdited]);

    const getUserAccessDetails = () => {
        const listData = state[STORE_KEYS.ALL_SELECTED_PLANTS]?.body?.data;
        !listData &&
            dispatch(
                executeACGAction({
                    payload: {
                        requestType: 'GET',
                        urlPath: ACTION_CODES.GET_ALL_PLANTS
                    },
                    storeKey: STORE_KEYS.ALL_SELECTED_PLANTS
                })
            );
    };

    const handleRequestSort = (event: any, property: any) => {
        const isAsc = orderBy === event && order === 'asc';
        setOrder(isAsc ? 'desc' : 'asc');
        setOrderBy(event);
    };

    const handleSelectAllClick = (event: any) => {
        if (event.target.checked) {
            const newSelecteds = [];
            for (let i = 0; i < tableData.length; i++) {
                newSelecteds.push(tableData[i].id);
            }
            const toRemove = tableData.slice(page, tableData.length);
            setSelected(newSelecteds);
            return;
        }
        setSelected([]);
    };

    const handleClick = (requestedBy: string) => {
        const selectedIndex = selected.indexOf(requestedBy);
        let newSelected: any[] | ((prevState: unknown[]) => unknown[]) = [];
        if (selectedIndex === -1) {
            newSelected = newSelected.concat(selected, requestedBy);
        } else if (selectedIndex === 0) {
            newSelected = newSelected.concat(selected.slice(1));
        } else if (selectedIndex === selected.length - 1) {
            newSelected = newSelected.concat(selected.slice(0, -1));
        } else if (selectedIndex > 0) {
            newSelected = newSelected.concat(selected.slice(0, selectedIndex), selected.slice(selectedIndex + 1));
        }
        setSelected(newSelected);
    };

    const toggleDrawer = (anchor: Anchor, open: boolean) => (event: React.KeyboardEvent | React.MouseEvent) => {
        if (
            event.type === 'keydown' &&
            ((event as React.KeyboardEvent).key === 'Tab' || (event as React.KeyboardEvent).key === 'Shift')
        ) {
            return;
        }
        setDrawerState({ ...drawerState, [anchor]: open });
    };

    const filteredList = applySortFilter(tableData, order, filterName, orderBy);

    const handleFilterByName = (event: { target: { value: string } }) => {
        setPage(0);
        setFilterName(event.target.value);
        setOrderBy('UserName');
    };

    const handleChangePage = (event: any, newPage: React.SetStateAction<number>) => {
        setPage(newPage);
    };

    const showRow = (event: any) => { };

    const handleSnackbarError = (err: any) => {
        const errorMsg = err?.message || 'Internal Server error';
        const snackbarError = {
            message: errorMsg,
            type: 'reject',
            open: true
        };
        setSnackbarOptions(snackbarError);
    };

    useEffect(() => {
        if (state?.err) {
            handleSnackbarError(state?.err);
            dispatch(resetErr());
        }
    }, [state?.err]);

    const handleRoute = (row: any, ele: any) => {
        // if (row?.RoleName == 'Client Admin') {
        //     setSnackbarOptions(options.SA_ACCESS_LIMIT);
        // } else {
            if (ele === 'Action') {
                const reqPayload = {
                    payload: {
                        urlPath: `${ACTION_CODES.GET_USER_ACCESS_DETAILS}/${row.UserKey}`,
                        requestType: 'GET'
                    },
                    storeKey: STORE_KEYS.USER_ACCESS_DETAILS
                };
                dispatch(executeACGAction(reqPayload));
            }
            setSelectedRow({ ...row, name: row?.UserName, plants: [], count: 0 });
            setDtlData({ ...row, name: row?.UserName });
            setCreateDrawerOpen(true);
        // }
    };

    useEffect(() => {
        if (state?.[STORE_KEYS.USER_ACCESS_DETAILS]?.body?.data) {
            const tmpTableData = state[STORE_KEYS.USER_ACCESS_DETAILS]?.body?.data;
            if (tmpTableData) {
                if (tmpTableData?.[0]?.PlantKey === -1) {
                    getUserAccessDetails();
                } else {
                    const formatedData = formatAccordionData(tmpTableData, true);
                    selectedRow.plants = formatedData;
                    selectedRow.count = tmpTableData?.length;
                    selectedRow.actualData = tmpTableData;
                    setDtlData(selectedRow);
                    dispatch(resetStoreKey({ storeKey: STORE_KEYS.USER_ACCESS_DETAILS }));
                }
            }
        }
    }, [state?.[STORE_KEYS.USER_ACCESS_DETAILS]?.body?.data]);

    useEffect(() => {
        if (state?.[STORE_KEYS.ALL_SELECTED_PLANTS]?.body?.data) {
            const AcgAllSelectedData = state[STORE_KEYS.ALL_SELECTED_PLANTS]?.body?.data;
            if (AcgAllSelectedData) {
                const formatedData = formatAccordionData(AcgAllSelectedData, true);
                selectedRow.plants = formatedData;
                selectedRow.count = AcgAllSelectedData?.length;
                selectedRow.actualData = state?.[STORE_KEYS.USER_ACCESS_DETAILS]?.body?.data;
                setDtlData(selectedRow);
                dispatch(resetStoreKey({ storeKey: STORE_KEYS.USER_ACCESS_DETAILS }));
                dispatch(resetStoreKey({ storeKey: STORE_KEYS.ALL_SELECTED_PLANTS }));
            }
        }
    }, [state[STORE_KEYS.ALL_SELECTED_PLANTS]?.body?.data]);

    const closeDrawer = () => {
        setDtlData([]);
        setCreateDrawerOpen(false);
    };

    const closeSnackbar = () => setSnackbarOptions(options.DEFAULT);
    const resetInput = () => {
        setFilterName('');
    };

    useEffect(() => {
        if (activeInactive) {
            closeRmBx();
            if (dtlData?.UserStatus === "Active") {
                // alert("unfavorite")
                const userKey = dtlData?.UserKey
                dispatch(
                    executeACGAction({
                        payload: {
                            requestType: 'PUT',
                            urlPath: `${ACTION_CODES.INACTIVATE_USER}/${userKey}`
                        },
                        uniqueScreenIdentifier: { unMarked: true },
                        storeKey: STORE_KEYS.ADD_CNT
                    })
                );
            } else {
                // alert("favorite")
                dispatch(
                    executeACGAction({
                        payload: {
                            requestType: 'PUT',
                            urlPath: `${ACTION_CODES.ACTIVATE_USER}/${userKey}`
                        },
                        uniqueScreenIdentifier: { marked: true },
                        storeKey: STORE_KEYS.ADD_CNT
                    })
                );
            }

            setActiveInactive(false);
        }
    }, [activeInactive]);

    useEffect(() => {
        if (state?.[STORE_KEYS.ADD_CNT]?.unMarked) {
            if (state?.[STORE_KEYS.ADD_CNT]?.statusCode === 200) {
                setSnackbarOptions(options.UNMARK_SUCCESS);
                getList();
                closeDrawer();
                // getMachines();
                // handlerClose();
            }
            dispatch(
                updateScreenIdentifiers({
                    storeKey: STORE_KEYS.ADD_CNT,
                    uniqueScreenIdentifier: {
                        unMarked: false
                    }
                })
            );
        }
    }, [state?.[STORE_KEYS.ADD_CNT]?.unMarked]);
    useEffect(() => {
        if (state?.[STORE_KEYS.ADD_CNT]?.marked) {
            if (state?.[STORE_KEYS.ADD_CNT]?.statusCode === 200) {
                setSnackbarOptions(options.MARK_SUCCESS);
                getList();
                closeDrawer()
                // getMachines();
                // handlerClose();
            }
            dispatch(
                updateScreenIdentifiers({
                    storeKey: STORE_KEYS.ADD_CNT,
                    uniqueScreenIdentifier: {
                        marked: false
                    }
                })
            );
        }
    }, [state?.[STORE_KEYS.ADD_CNT]?.marked]);

    return (
        <div>
            <div>
                <SnackbarAlert options={snackbarOptions} handleClose={closeSnackbar} />
                <Container
                    maxWidth="xl"
                    style={{
                        paddingLeft: '3px',
                        paddingRight: '3px'
                    }}
                >
                    <Card
                        style={{
                            borderRadius: '0px',
                            backgroundColor: '#2b2d42',
                            overflow: 'inherit'
                        }}
                    >
                        <CustomTableToolbar
                            drawerOpen={toggleDrawer('right', true)}
                            numSelected={selected?.length}
                            filterName={filterName}
                            onFilterName={handleFilterByName}
                            resetInput={resetInput}
                            displayInput={true}
                            text={{
                                title: 'User List',
                                subTitle: `${filteredList.length} users`
                            }}
                            filterButton={false}
                            customButton={false}
                        />
                    </Card>
                </Container>
                <CustomTable
                    selectable={false}
                    headers={TABLE_HEAD}
                    body={filteredList}
                    isLoading={isUsersLoading}
                    filteredUsers={filteredList}
                    filterName={filterName}
                    selected={selected}
                    order={order}
                    orderBy={orderBy}
                    onRequestSort={handleRequestSort}
                    onSelectAllClick={handleSelectAllClick}
                    rowChange={(event: string) => handleClick(event)}
                    rowCount={filteredList.length}
                    onPageChange={handleChangePage}
                    page={page}
                    setPageNumber={setPage}
                    selectRow={showRow}
                    route={handleRoute}
                    isRequestPage={true}
                    link={true}
                    removeFn={removeFn}
                    userUnlockFn={userUnlockFn}
                    userFlag={true}
                    {...(radioValue === 'Customer' && !selectedClient
                        ? { noDataText: 'Selected cient to display users' }
                        : {})}
                />
            </div>
            <AlertBox
                isOpen={removeBxOpen}
                handleClose={closeRmBx}
                leftBtnPress={closeRmBx}
                rightBtnPress={() => setRmUser(true)}
                modalHeader="Delete User"
                modalContent="Are you sure you want to Delete the user?"
            />

            <AlertBox
                isOpen={unlockBxOpen}
                handleClose={closeRmBx}
                leftBtnPress={closeRmBx}
                custombutton={true}
                rightBtnPress={() => setUnlockUser(true)}
                modalHeader={`${unlockHeader} user`}
                modalContent={`"Are you sure you want to ${unlockHeader} the user?"`}
            />

            <AlertBox
                isOpen={activeBxOpen}
                handleClose={closeRmBx}
                leftBtnPress={closeRmBx}
                rightBtnPress={() => setActiveInactive(true)}
                modalHeader={`${activeAlertHeader} user`}
                modalContent={`"Are you sure you want to ${activeAlertHeader} the user?"`}
            />
            <TemporaryDrawer
                handlerOpen={toggleDrawer('right', true)}
                handlerClose={toggleDrawer('right', false)}
                status={state['right']}
            />
            <DetailDrawer hasImage={false}
                handlerOpen={() => { }}
                handlerClose={closeDrawer}
                status={createDrawerOpen}
                data={dtlData}
                block={true}
                type={'Role'}
                deleteFn={() => removeFn(dtlData)}
                activeFn={() => activeFn(dtlData)}
                editFn={() => {
                    setEditDrawerOpen(true);
                    setCreateValue('New User');
                    setDrawerHeader('Edit');
                }}
            />
        </div>
    );
};

export default Users;
