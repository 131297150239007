import { useState, useEffect, useRef } from 'react';
import { Container, Grid } from '@mui/material';
import AcgButton from '../../../../atoms/Button/index';
import { Formik, Form, FormikProps } from 'formik';
import * as Yup from 'yup';
import { DB_YUPSCHEMA } from '../schema';
import Label from '../../../../atoms/Label/index';
import TextInput from '../../../../atoms/TextInput';
import RadioButton from '../../../../atoms/Radiobutton';
import { ACTION_CODES, STORE_KEYS } from '../../../../constants/apiConstants';
import { executeACGAction, resetErr, updateScreenIdentifiers } from '../../../../store/slice';
import { useDispatch } from 'react-redux';
import useGetState from '../../../../utils/hooks/useGetState';
// import './index.scss';

type Props = {
    drawerClose: Function;
    setCurrTab: Function;
    setReload: Function;
    setSnackbarOpts: Function;
};
const options = {
    AD_SUCCESS: {
        message: 'Dashboard created successfully!',
        open: true,
        type: 'success'
    }
};

type DashboardType = {
    dbCode: string | '';
    dbName: string | '';
    dbType: string;
    baseUrl: string | '';
    dbUrl: string | '';
    grpId: string | '';
    rptId: string | '';
    dbId: string | '';
    rptOrdId: Number | null;
};

const CreateDashboard = (props: Props) => {
    const { drawerClose, setCurrTab, setReload = () => {}, setSnackbarOpts = () => {} } = props;
    const formRef = useRef<any>(null);
    const [validationSchema, setValidationSchema] = useState({});
    const state = useGetState();
    const dispatch = useDispatch();
    const [initialValues] = useState({
        dbCode: '',
        dbName: '',
        dbType: '0',
        baseUrl: '',
        dbUrl: '',
        grpId: '',
        rptId: '',
        dbId: '',
        rptOrdId: null
    });
    const renderButtons = () => {
        return (
            <div className={'btns noWrap'}>
                <AcgButton
                    name={'Cancel'}
                    handleClick={drawerClose}
                    secondary={true}
                    className={'first-btn'}
                    type="button"
                    variant="contained"
                />
                <AcgButton
                    name={'Submit'}
                    handleClick={() => {}}
                    type="submit"
                    className="second-btn"
                    variant="contained"
                />
            </div>
        );
    };
    useEffect(() => {
        setValidationSchema(Yup.object().shape(DB_YUPSCHEMA));
    }, []);

    const handleSubmit = async () => {
        let reqObj:any = {};
        if (formRef?.current?.values?.dbType == '0') {
            reqObj = {
                reportCategory: 'Dashboard',
                reportCode: formRef?.current?.values?.dbCode,
                reportName: formRef?.current?.values?.dbName.replace(/'/g, "''"),
                reportType: 'PowerBI',
                pbiBaseURL: formRef?.current?.values?.baseUrl,
                pbiReportURL: formRef?.current?.values?.dbUrl,
                pbiGroupId: formRef?.current?.values?.grpId,
                pbiReportId: formRef?.current?.values?.rptId,
                pbiDatasetId: formRef?.current?.values?.dbId,
                reportOrderId: Number(formRef?.current?.values?.rptOrdId)
            };
        } else {
            reqObj = {
                reportCategory: 'Dashboard',
                reportCode: formRef?.current?.values?.dbCode,
                reportName: formRef?.current?.values?.dbName.replace(/'/g, "''"),
                reportType: 'React',
                reportOrderId: Number(formRef?.current?.values?.rptOrdId)
            };
        }
        const bundle = {
            payload: {
                urlPath: ACTION_CODES.ADD_REPORT,
                requestType: 'POST',
                reqObj: reqObj
            },
            uniqueScreenIdentifier: { rptAdded: true },
            storeKey: STORE_KEYS.ADD_RPT
        };
        dispatch(executeACGAction(bundle));
    };

    useEffect(() => {
        if (state?.[STORE_KEYS.ADD_RPT]?.rptAdded) {
            if (state?.[STORE_KEYS.ADD_RPT]?.statusCode === 200) {
                setReload(true);
                setCurrTab(3);
                setSnackbarOpts(options.AD_SUCCESS);
                drawerClose();
            }
            dispatch(
                updateScreenIdentifiers({
                    storeKey: STORE_KEYS.ADD_RPT,
                    uniqueScreenIdentifier: {
                        rptAdded: false
                    }
                })
            );
        }
    }, [state?.[STORE_KEYS.ADD_RPT]?.rptAdded]);

    const handleSnackbarError = (err: any) => {
        const errorMsg = err?.message || 'Internal Server error';
        const snackbarError = {
            message: errorMsg,
            type: 'reject',
            open: true
        };
        setSnackbarOpts(snackbarError);
    };

    useEffect(() => {
        if (state?.err) {
            handleSnackbarError(state?.err);
            dispatch(resetErr());
        }
    }, [state?.err]);

    return (
        <Container className="createReport">
            <Formik
                initialValues={initialValues}
                validationSchema={validationSchema}
                onSubmit={() => {
                    handleSubmit();
                }}
                validateOnBlur={false}
                validateOnChange={false}
                innerRef={formRef}
            >
                {(formikprops: FormikProps<DashboardType>) => {
                    const { values, handleChange, handleBlur, isValid, errors, touched, setFieldValue } = formikprops;
                    return (
                        <Form>
                            <Label label="Dashboard Code" className={'labelDiv'} />
                            <TextInput
                                variant="outlined"
                                autoFocus
                                fullWidth
                                type="text"
                                name="dbCode"
                                value={values.dbCode}
                                autoComplete="off"
                                formInput="inputDiv"
                                onChange={(e: any) => {
                                    handleChange(e);
                                }}
                                onBlur={handleBlur}
                                placeholder="Enter Dashboard Code"
                                helperText={errors.dbCode && touched.dbCode ? errors.dbCode : ''}
                            />
                            <Label label="Dashboard Name" className={'labelDiv'} />
                            <TextInput
                                variant="outlined"
                                fullWidth
                                type="text"
                                name="dbName"
                                value={values.dbName}
                                autoComplete="off"
                                formInput="inputDiv"
                                onChange={(e: any) => {
                                    handleChange(e);
                                }}
                                onBlur={handleBlur}
                                placeholder="Enter Dashboard Name"
                                helperText={errors.dbName && touched.dbName ? errors.dbName : ''}
                            />
                            <Label label="Dashboard Type" className={'labelDiv'} />
                            <RadioButton
                                options={[
                                    { value: '0', label: 'Power BI' },
                                    { value: '1', label: 'React' }
                                ]}
                                onChange={(e: any) => {
                                    setFieldValue('dbType', e?.currentTarget?.value);
                                }}
                                defaultValue={'0'}
                                rowDirection={true}
                                value={values.dbType}
                            />
                            {values?.dbType == '0' ? (
                                <>
                                    <Label label="Power BI Base URL" />
                                    <TextInput
                                        variant="outlined"
                                        fullWidth
                                        type="text"
                                        name="baseUrl"
                                        value={values.baseUrl}
                                        autoComplete="off"
                                        formInput="inputDiv"
                                        onChange={(e: any) => {
                                            handleChange(e);
                                        }}
                                        onBlur={handleBlur}
                                        placeholder="Enter Power BI Base URL"
                                        helperText={errors.baseUrl && touched.baseUrl ? errors.baseUrl : ''}
                                    />
                                    <Label label="Power BI Report URL" className={'labelDiv'} />
                                    <TextInput
                                        variant="outlined"
                                        fullWidth
                                        type="text"
                                        name="dbUrl"
                                        value={values.dbUrl}
                                        autoComplete="off"
                                        formInput="longInputDiv"
                                        height="80px"
                                        onChange={(e: any) => {
                                            handleChange(e);
                                        }}
                                        onBlur={handleBlur}
                                        placeholder="Enter Power BI Report URL"
                                        helperText={errors.dbUrl && touched.dbUrl ? errors.dbUrl : ''}
                                    />
                                    <Grid container spacing={2}>
                                        <Grid item xs={12} sm={12} md={6} xl={6} lg={6}>
                                            <Label label="Power BI Group ID" className={'labelDiv'} />
                                            <TextInput
                                                variant="outlined"
                                                fullWidth
                                                type="text"
                                                name="grpId"
                                                value={values.grpId}
                                                autoComplete="off"
                                                formInput="inputDiv"
                                                onChange={(e: any) => {
                                                    handleChange(e);
                                                }}
                                                onBlur={handleBlur}
                                                placeholder="Enter Power BI Group ID"
                                                helperText={errors.grpId && touched.grpId ? errors.grpId : ''}
                                            />
                                        </Grid>
                                        <Grid item xs={12} sm={12} md={6} xl={6} lg={6}>
                                            <Label label="Power BI Report ID" className={'labelDiv'} />
                                            <TextInput
                                                variant="outlined"
                                                fullWidth
                                                type="text"
                                                name="rptId"
                                                value={values.rptId}
                                                autoComplete="off"
                                                formInput="inputDiv"
                                                onChange={(e: any) => {
                                                    handleChange(e);
                                                }}
                                                onBlur={handleBlur}
                                                placeholder="Enter Power BI Report ID"
                                                helperText={errors.rptId && touched.rptId ? errors.rptId : ''}
                                            />
                                        </Grid>
                                    </Grid>
                                    <Grid container spacing={2}>
                                        <Grid item xs={12} sm={6} md={6} xl={6} lg={6}>
                                            <Label label="Power BI Dataset ID" className={'labelDiv'} />
                                            <TextInput
                                                variant="outlined"
                                                fullWidth
                                                type="text"
                                                name="dbId"
                                                value={values.dbId}
                                                autoComplete="off"
                                                formInput="inputDiv"
                                                onChange={(e: any) => {
                                                    handleChange(e);
                                                }}
                                                onBlur={handleBlur}
                                                placeholder="Enter Power BI Database ID"
                                                helperText={errors.dbId && touched.dbId ? errors.dbId : ''}
                                            />
                                        </Grid>
                                        <Grid item xs={12} sm={12} md={6} xl={6} lg={6}>
                                            <Label label="Report Order ID" className={'labelDiv'} />
                                            <TextInput
                                                variant="outlined"
                                                fullWidth
                                                type="text"
                                                name="rptOrdId"
                                                value={values.rptOrdId?.toString()}
                                                autoComplete="off"
                                                formInput="inputDiv"
                                                onChange={(e: any) => {
                                                    handleChange(e);
                                                }}
                                                onBlur={handleBlur}
                                                placeholder="Enter Report Order ID"
                                                helperText={errors.rptOrdId && touched.rptOrdId ? errors.rptOrdId : ''}
                                            />
                                        </Grid>
                                    </Grid>
                                </>
                            ) : (
                                <>
                                    <Label label="Report Order ID" />
                                    <TextInput
                                        variant="outlined"
                                        fullWidth
                                        type="text"
                                        name="rptOrdId"
                                        value={values.rptOrdId?.toString()}
                                        autoComplete="off"
                                        formInput="inputDiv"
                                        onChange={(e: any) => {
                                            handleChange(e);
                                        }}
                                        onBlur={handleBlur}
                                        placeholder="Enter Report Order ID"
                                        helperText={errors.rptOrdId && touched.rptOrdId ? errors.rptOrdId : ''}
                                    />
                                </>
                            )}

                            {renderButtons()}
                        </Form>
                    );
                }}
            </Formik>
        </Container>
    );
};

export default CreateDashboard;
