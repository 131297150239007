import { Icon } from '@iconify/react';
import { useRef, useState } from 'react';
import editFill from '@iconify/icons-eva/edit-fill';
import { Link as RouterLink, useLocation } from 'react-router-dom';
import trash2Outline from '@iconify/icons-eva/trash-2-outline';
import moreVerticalFill from '@iconify/icons-eva/more-vertical-fill';
import { Menu, MenuItem, IconButton, ListItemIcon, ListItemText, Button } from '@mui/material';
// import './index.scss';
interface Props {
    DropdownOptions:any,
    values?:any
}
export default function CustomAlertBtn(props: Props) {
    const { DropdownOptions,values } = props;
    const ref = useRef(null);
    const [isOpen, setIsOpen] = useState(false);
    const actionHandler = () => {
        setIsOpen(true);
    }
    return (
        <>
         <Button
                id="demo-customized-button"
                // aria-controls={open ? 'demo-customized-menu' : undefined}
                // aria-haspopup="true"
                // aria-expanded={open ? 'true' : undefined}
                variant="contained"
                // disabled={!isAccess(accessData)}
                disableElevation
                // onClick={handleClick}
                // endIcon={<KeyboardArrowDownIcon />}
                style={{
                    backgroundColor: '#5D97F6',
                    color: 'black',
                    fontSize: '12px',
                    width: '110px',
                    height: '40px',
                    textTransform: 'capitalize',
                    letterSpacing: '0.2px',
                    fontFamily: 'Montserrat',
                    outline: 'none',
                    fontWeight: 'bold',
                    position: 'relative',
                    right: '-110px',
                }}
                ref={ref} onClick={actionHandler}
            >
                Create New
            </Button>
        {/* <div className='test' ref={ref} onClick={actionHandler}>Create</div> */}
            {/* <IconButton ref={ref} onClick={actionHandler}>
                <Icon className="customTableMenuAction" icon={moreVerticalFill} width={20} height={20} />
            </IconButton> */}
            {/* ,marginTop:'30px' */}
            <Menu
                open={isOpen}
                anchorEl={ref.current}
                onClose={() => setIsOpen(false)}
                PaperProps={{
                    sx: { width: 'auto', minWidth: '118px', maxWidth: '100%', borderRadius: '10px' }
                }}
                anchorOrigin={{ vertical: 'top', horizontal: 'right' }}
                transformOrigin={{ vertical: 'top', horizontal: 'right' }}
                onClick={() => setIsOpen(false)}
                style={{ zIndex: '99999' }}
                className="userMenu"
            >
                {DropdownOptions?.map((item:any)=>(
                    <MenuItem sx={{ color: 'text.secondary' }} className="table_actionmenu">
                    <ListItemText
                        primary={item.label}
                        primaryTypographyProps={{ variant: 'body2' }}
                        onClick={()=>{item.click(values)}}
                        
                    />
                </MenuItem>
                ))}
                
            </Menu >
        </>
    );
}
