import React, { useState, useEffect } from 'react';
import { useDispatch } from 'react-redux';
import CustomTable from '../../../../app/atoms/CustomTable/index';
import CustomTableToolbar from '../../../../app/atoms/CustomTableToolbar';
import { Container, Card } from '@mui/material';
import TemporaryDrawer from '../../../../app/atoms/tempDrawer';
import { ACTION_CODES, STORE_KEYS } from '../../../../app/constants/apiConstants';
import { executeACGAction, resetErr, resetStoreKey, updateScreenIdentifiers } from '../../../../app/store/slice';
import useGetState from '../../../../app/utils/hooks/useGetState';
import { applySortFilter } from '../../../../app/utils/helper';
import SnackbarAlert from '../../../../app/atoms/Snackbar';
import AlertBox from '../../../../app/atoms/AlertBox';
import DetailDrawer from '../../../../app/atoms/DetailDrawer';
import { formatAccordionData } from '../../../../app/utils/formatData';
import ContactDetailDrawer from '../../../atoms/ContactDetailDrawer';

const TABLE_HEAD = [
    { id: 'UserName', label: 'User Name', alignRight: false },
    { id: 'CustomerName', label: 'Client', alignRight: false },
    // { id: 'LastAccessed', label: 'Machines Mapped', alignRight: false },
    { id: 'EmailId', label: 'Email ID', alignRight: false },
    { id: 'PhoneNo', label: 'Primary Contact', alignRight: false },
    { id: 'AlternatePhoneNo', label: 'Alternate Contact', alignRight: false },
    { id: 'favourite', label: 'Favourite', alignRight: false },
    { id: 'Action', label: 'Action', alignLeft: true }
];

const options = {
    DEFAULT: {
        message: '',
        open: false,
        type: ''
    },
    RM_SUCCESS: {
        message: 'Remote User Deleted',
        open: true,
        type: 'success'
    },
    USR_ADD_SUCCESS: {
        message: 'Remote User Added successfully!',
        open: true,
        type: 'success'
    }
};

type Anchor = 'right';

const Users = (props: any) => {
    const {
        getSummary,
        radioValue,
        selectedClient,
        setDtlData,
        setReload,
        reload,
        dtlData,
        setCreateValue,
        setDrawerHeader,
        setCreateDrawerOpen: setEditDrawerOpen
    } = props;

    const dispatch = useDispatch();
    const [drawerState, setDrawerState] = useState({
        right: false
    });
    const [page, setPage] = useState<number>(0);
    const [order, setOrder] = useState('desc');
    const [orderBy, setOrderBy] = useState('UserName');
    const [selected, setSelected] = useState<any>([]);
    const [selectedRow, setSelectedRow] = useState<any>({});
    const [filterName, setFilterName] = useState<string>('');
    const [tableData, setTableData] = useState<any[]>([]);
    const [createDrawerOpen, setCreateDrawerOpen] = useState(false);
    const [isContactLoading,setIsContactLoading]=useState(false)

    const state = useGetState();
    //add in use effect
    const getList = () => {
        setIsContactLoading(true)
        const loginData = state[STORE_KEYS.USER_DTL];
        const reqObj: {
            [key: string]: any;
        } = {
            spocType: radioValue,
            customerKey: -1,
            plantKey: -1,
            machineKey: -1
        };

        let pullData = true;
        if (loginData?.IsACGUser === 'Y') {
            if (radioValue === 'Customer') {
                reqObj.customerKey = selectedClient;
                pullData = Boolean(selectedClient);
            }
        } else {
            reqObj.customerKey = loginData?.CustomerKey;
        }

        pullData &&
            dispatch(
                executeACGAction({
                    payload: {
                        requestType: 'POST',
                        urlPath: ACTION_CODES.GET_CONTACTS,
                        reqObj
                    },
                    storeKey: STORE_KEYS.CONTACT_LIST
                })
            );
    };
    useEffect(() => {
        setTableData([]);
        getList();
    }, [radioValue, selectedClient]);
    useEffect(() => {
        if (reload) {
            getList();
            setReload(false);
            setTableData([]);
        }
    }, [reload]);

    useEffect(() => {
        const tmpTableData = state[STORE_KEYS.CONTACT_LIST]?.body?.data;
        if(!tmpTableData) return
        tmpTableData && setTableData(tmpTableData.map((item: any) => ({ ...item, status: item.UserStatus, favourite: item.FavoriteUserFlag === 'Y' ? 'Yes' : 'No' })));
        setIsContactLoading(false)
    }, [state[STORE_KEYS.CONTACT_LIST]?.body?.data]);

    const [snackbarOptions, setSnackbarOptions] = useState(options.DEFAULT);
    const [removeBxOpen, setRemoveBxOpen] = useState(false);
    const [rmUser, setRmUser] = useState(false);
    const [RCUserKey, setRCUserKey] = useState<any>();
    const closeRmBx = () => {
        setRemoveBxOpen(false);
    };
    const removeFn = (row: any, ele: any) => {
        setSelectedRow({ ...row });
        setRCUserKey(ele?.RCUserKey);
        setDtlData(row);
        setRemoveBxOpen(true);
    };
    useEffect(() => {
        setRCUserKey(dtlData?.RCUserKey);
    }, []);
    useEffect(() => {
        if (rmUser) {
            closeRmBx();
            dispatch(
                executeACGAction({
                    payload: {
                        requestType: 'DELETE',
                        urlPath: ACTION_CODES.DELETE_CONTACT + selectedRow?.RCUserKey || dtlData?.RCUserKey || RCUserKey
                    },
                    uniqueScreenIdentifier: {
                        rowDeleted: true
                    },
                    storeKey: STORE_KEYS.DELETE
                })
            );
            setRmUser(false);
        }
    }, [rmUser]);
    useEffect(() => {
        if (state[STORE_KEYS.DELETE]?.rowDeleted) {
            if (state?.[STORE_KEYS.DELETE]?.statusCode === 200) {
                setSnackbarOptions(options.RM_SUCCESS);
                setDtlData([]);
                setCreateDrawerOpen(false);
                getList();
                getSummary();
            }
            dispatch(
                updateScreenIdentifiers({
                    storeKey: STORE_KEYS.DELETE,
                    uniqueScreenIdentifier: {
                        rowDeleted: false
                    }
                })
            );
        }
    }, [state[STORE_KEYS.DELETE]?.rowDeleted]);

    const handleRequestSort = (event: any, property: any) => {
        const isAsc = orderBy === event && order === 'asc';
        setOrder(isAsc ? 'desc' : 'asc');
        setOrderBy(event);
    };

    const handleSelectAllClick = (event: any) => {
        if (event.target.checked) {
            const newSelecteds = [];
            for (let i = 0; i < tableData.length; i++) {
                newSelecteds.push(tableData[i].id);
            }
            const toRemove = tableData.slice(page, tableData.length);
            setSelected(newSelecteds);
            return;
        }
        setSelected([]);
    };

    const handleClick = (requestedBy: string) => {
        const selectedIndex = selected.indexOf(requestedBy);
        let newSelected: any[] | ((prevState: unknown[]) => unknown[]) = [];
        if (selectedIndex === -1) {
            newSelected = newSelected.concat(selected, requestedBy);
        } else if (selectedIndex === 0) {
            newSelected = newSelected.concat(selected.slice(1));
        } else if (selectedIndex === selected.length - 1) {
            newSelected = newSelected.concat(selected.slice(0, -1));
        } else if (selectedIndex > 0) {
            newSelected = newSelected.concat(selected.slice(0, selectedIndex), selected.slice(selectedIndex + 1));
        }
        setSelected(newSelected);
    };

    const toggleDrawer = (anchor: Anchor, open: boolean) => (event: React.KeyboardEvent | React.MouseEvent) => {
        if (
            event.type === 'keydown' &&
            ((event as React.KeyboardEvent).key === 'Tab' || (event as React.KeyboardEvent).key === 'Shift')
        ) {
            return;
        }
        setDrawerState({ ...drawerState, [anchor]: open });
    };

    const filteredList = applySortFilter(tableData, order, filterName, orderBy);

    const handleFilterByName = (event: { target: { value: string } }) => {
        setPage(0);
        setFilterName(event.target.value);
        setOrderBy('UserName');
    };

    const handleChangePage = (event: any, newPage: React.SetStateAction<number>) => {
        setPage(newPage);
    };

    const showRow = (event: any) => { };

    const handleSnackbarError = (err: any) => {
        const errorMsg = err?.message || 'Internal Server error';
        const snackbarError = {
            message: errorMsg,
            type: 'reject',
            open: true
        };
        setSnackbarOptions(snackbarError);
    };

    useEffect(() => {
        if (state?.err) {
            handleSnackbarError(state?.err);
            dispatch(resetErr());
        }
    }, [state?.err]);

    const handleRoute = (row: any, ele: any) => {
        if (ele === 'Action') {
            setSelectedRow({ ...row });
            setCreateDrawerOpen(true);
            setDtlData(row);
        }
    };

    const closeDrawer = () => {
        setDtlData([]);
        setCreateDrawerOpen(false);
    };

    const closeSnackbar = () => setSnackbarOptions(options.DEFAULT);
    const resetInput = () => {
        setFilterName('');
    };

    return (
        <div>
            <div>
                <SnackbarAlert options={snackbarOptions} handleClose={closeSnackbar} />
                <Container
                    maxWidth="xl"
                    style={{
                        paddingLeft: '3px',
                        paddingRight: '3px'
                    }}
                >
                    <Card
                        style={{
                            borderRadius: '0px',
                            backgroundColor: '#2b2d42',
                            overflow: 'inherit'
                        }}
                    >
                        <CustomTableToolbar
                            drawerOpen={toggleDrawer('right', true)}
                            numSelected={selected?.length}
                            filterName={filterName}
                            onFilterName={handleFilterByName}
                            resetInput={resetInput}
                            displayInput={true}
                            text={{
                                title: 'Contact List ',
                                subTitle: `${tableData.length} Contacts`
                            }}
                            filterButton={false}
                            customButton={false}
                        />
                    </Card>
                </Container>
                <CustomTable
                    selectable={false}
                    headers={TABLE_HEAD}
                    body={filteredList}
                    isLoading={isContactLoading}
                    filteredUsers={filteredList}
                    filterName={filterName}
                    selected={selected}
                    isTableImageReqd={true}
                    order={order}
                    orderBy={orderBy}
                    onRequestSort={handleRequestSort}
                    onSelectAllClick={handleSelectAllClick}
                    rowChange={(event: string) => handleClick(event)}
                    rowCount={filteredList.length}
                    onPageChange={handleChangePage}
                    page={page}
                    setPageNumber={setPage}
                    selectRow={showRow}
                    route={handleRoute}
                    isRequestPage={true}
                    link={true}
                    removeFn={removeFn}
                    {...(radioValue === 'Customer' && !selectedClient
                        ? { noDataText: 'Selected client to display users' }
                        : {})}
                />
                <ContactDetailDrawer
                    handlerOpen={() => { }}
                    handlerClose={closeDrawer}
                    status={createDrawerOpen}
                    data={selectedRow}
                    block={true}
                    usedIn="contact"
                    type="Contact"
                    getList={getList}
                    removeFn={removeFn}
                    // deleteFn={() => removeFn}
                    editFn={() => {
                        setEditDrawerOpen(true);
                        setCreateValue('Contact');
                        setDrawerHeader('Edit');
                    }}
                />
            </div>
            <AlertBox
                isOpen={removeBxOpen}
                handleClose={closeRmBx}
                leftBtnPress={closeRmBx}
                rightBtnPress={() => setRmUser(true)}
                modalHeader="Delete Remote call user"
                modalContent="Are you sure you want to delete Remote call user?"
            />
            <TemporaryDrawer
                handlerOpen={toggleDrawer('right', true)}
                handlerClose={toggleDrawer('right', false)}
                status={state['right']}
            />

        </div>
    );
};

export default Users;
