export const SnackBarOptions = {
    DEFAULT: {
        message: '',
        open: false,
        type: ''
    },
    SENDING_EMAIL: {
        message: 'Sending email',
        open: true,
        type: 'success'
    },
    SENT_EMAIL: {
        message: 'Email sent successfully',
        open: true,
        type: 'success'
    },
    ERROR_SENDING_EMAIL: {
        message: 'Error sending email, Please retry',
        open: true,
        type: 'success'
    },
    ERROR_DOWNLOADING_REPORT: {
        message: 'Error downloading report, Please retry',
        open: true,
        type: 'success'
    }
};

export const Dateformat = {
    YYYY_MM_DD : 'yyyy-mm-dd',
    YYYY_MM_DD_HH_MM_SS : 'yyyy-mm-dd hh:mm:ss',
    YYYY_MM_DD_HH_MM_SS_L : 'yyyy-mm-ddTHH:MM:ss.l',
}

export const ERROR_MESSAGES = {
    NO_DATA_AVAILABLE : 'No data available',
    NO_BATCH_INFO_AVAILABLE : 'No batch info available',
};