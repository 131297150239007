import axios from 'axios';
import { ACTION_CODES, STORE_KEYS, BASEURL } from '../../constants/apiConstants';

class NotificationService {

    getCustomers(req:any) {
        var data = {
            "userId":req.userId
        };
        return axios
        .post(ACTION_CODES.API_GET_CUSTOMERS,data, {
            headers: {
                Authorization: 'Bearer ' + req?.token
            }
        })
        .then(response => {
            return {
                status: response.status,
                data: response.data
            }
        })
        .catch(error => { 
            
            return {
                status: error.response.status
            }
        })
    }
    getfilterCustomers(req:any) {
        var data = {
            "userId":req.userId,
            "moduleKey":req.moduleKey
        };
        return axios
        .post(ACTION_CODES.API_GET_FILTER_CUSTOMERS,data, {
            headers: {
                Authorization: 'Bearer ' + req?.token
            }
        })
        .then(response => {
            return {
                status: response.status,
                data: response.data
            }
        })
        .catch(error => { 
            
            return {
                status: error.response.status
            }
        })
    }

    getModule(req:any) {
        var data = {
            "userId":req.userId
        };
        return axios
        .post(ACTION_CODES.API_GET_MODULES,data, {
            headers: {
                Authorization: 'Bearer ' + req?.token
            }
        })
        .then(response => {
            return {
                status: response.status,
                data: response.data
            }
        })
        .catch(error => { 
            
            return {
                status: error.response.status
            }
        })
    }

    getNotificationGroups(req:any) {
        var data = {
            "userId": req.userId && req.userId.toString(),
            "customerId": typeof req.customerId === 'number' ? req.customerId.toString() : req.customerId,
            "moduleId": typeof req.moduleId === 'number' ? req.moduleId.toString() : req.moduleId,
            "plantKey": typeof req.plantKey === 'number' ? req.plantKey.toString() : req.plantKey
        };
        return axios
        .post(ACTION_CODES.API_GET_ALL_NOTIFICATTIONS,data, {
            headers: {
                Authorization: 'Bearer ' + req?.token
            }
        })
        .then(response => {
            return {
                status: response.status,
                data: response.data
            }
        })
        .catch(error => { 
            
            return {
                status: error.response.status
            }
        })
    }

    createNotificationGroup(req:any) {
        var data = {
            "userId":req.userId,
            "moduleId": req.moduleId,
            "customerId":req.customerKey,
            "grpName": req.grpName,
            "grpDescription": req.grpDescription,
            "type": req.type,
            "grpKey": req.grpKey,
            "plantKey": typeof req.plantKey == "number"? req.plantKey.toString(): req.plantKey,
        };
        return axios
        .post(ACTION_CODES.API_CREATE_NOTIFICATION_GROUP,data, {
            headers: {
                Authorization: 'Bearer ' + req?.token
            }
        })
        .then(response => {
            return {
                status: response.status,
                data: response.data
            }
        })
        .catch(error => { 
            
            return {
                status: error.response.status
            }
        })
    }

    getPortalUserList(req:any) {
        var data = {
            "userId":req.userId,
            "customerId":req.customerKey,
        };
        return axios
        .post(ACTION_CODES.API_PORTAL_USERS,data, {
            headers: {
                Authorization: 'Bearer ' + req?.token
            }
        })
        .then(response => {
            return {
                status: response.status,
                data: response.data
            }
        })
        .catch(error => { 
            
            return {
                status: error.response.status
            }
        })
    }

    deleteNotificationGM(req:any) {
        var data = {
            "userId":req.userId,
            "tableName":req.table,
            "grpKey":req.key
        };
        return axios
        .post(ACTION_CODES.API_DELETE_NOTIFICATION,data, {
            headers: {
                Authorization: 'Bearer ' + req?.token
            }
        })
        .then(response => {
            return {
                status: response.status,
                data: response.data
            }
        })
        .catch(error => { 
            
            return {
                status: error.response.status
            }
        })
    }

    addMemberToGroup(req:any) {
        var data = {
            "userId":req.userId,
            "type":req.type,
            "userName":req.userName,
            "userEmail": req.userEmail,
            "userKey": req.userKey,
            "grpKey": req.grpKey
        };
        return axios
        .post(ACTION_CODES.API_GROUP_ADD_MEMBER,data, {
            headers: {
                Authorization: 'Bearer ' + req?.token
            }
        })
        .then(response => {
            return {
                status: response.status,
                data: response.data
            }
        })
        .catch(error => { 
            
            return {
                status: error.response.status
            }
        })
    }

    getGroupMemberList(req:any) {
        var data = {
            "grpKey": req.grpKey
        };
        return axios
        .post(ACTION_CODES.API_GET_GROUP_USERS,data, {
            headers: {
                Authorization: 'Bearer ' + req?.token
            }
        })
        .then(response => {
            return {
                status: response.status,
                data: response.data
            }
        })
        .catch(error => { 
            
            return {
                status: error.response.status
            }
        })
    }

    getFMEACustomerNotificationGroups(req:any) {
        var data = {
            "userId": req.userId
        };
        return axios
        .post(ACTION_CODES.API_FMEA_CUSTOMER_NGROUPS,data, {
            headers: {
                Authorization: 'Bearer ' + req?.token
            }
        })
        .then(response => {
            return {
                status: response.status,
                data: response.data
            }
        })
        .catch(error => { 
            
            return {
                status: error.response.status
            }
        })
    }

    getFMEANotifications(req:any) {
        var data = {
            "userId": req.userId,
        };
        return axios
        .post(ACTION_CODES.API_FMEA_NOTIFICATIONS,data, {
            headers: {
                Authorization: 'Bearer ' + req?.token
            }
        })
        .then(response => {
            return {
                status: response.status,
                data: response.data
            }
        })
        .catch(error => { 
            
            return {
                status: error.response.status
            }
        })
    }
    getFMEANotificationDetails(req:any) {
        var data = {
            "ruleKey": req.ruleKey,
            "type":req.type
        };
        return axios
        .post(ACTION_CODES.API_FMEA_NOTIFICATION_DETAILS,data, {
            headers: {
                Authorization: 'Bearer ' + req?.token
            }
        })
        .then(response => {
            return {
                status: response.status,
                data: response.data
            }
        })
        .catch(error => { 
            
            return {
                status: error.response.status
            }
        })
    }

    assignFMEANotificationGroups(req:any) {
        var data = {
            userId: req.userId,
            failureKey: req.presetRuleKey,
            groups: req.groups,
            type: req.type
        };
        return axios
        .post(ACTION_CODES.API_FMEA_ASSIGN_NOTIFY_GROUPS,data, {
            headers: {
                Authorization: 'Bearer ' + req?.token
            }
        })
        .then(response => {
            return {
                status: response.status,
                data: response.data
            }
        })
        .catch(error => { 
            
            return {
                status: error.response.status
            }
        })
    }

    getFailureModeNotificationGroups(req:any) {
        var data = {
            "userId":req.userId,
            failureKey: req.ruleKey
        };
        return axios
        .post(ACTION_CODES.API_FMEA_GET_FAILURE_NOTIFICATION_GROUPS,data, {
            headers: {
                Authorization: 'Bearer ' + req?.token
            }
        })
        .then(response => {
            return {
                status: response.status,
                data: response.data
            }
        })
        .catch(error => { 
            
            return {
                status: error.response.status
            }
        })
    }
   
}
export default new NotificationService();