import { SetStateAction, useEffect, useRef, useState } from 'react';
import {
    Checkbox,
    Container,
    FormControl,
    ListItemText,
    MenuItem,
    Grid,
    Select,
    Typography,
    Box
} from '@mui/material';
import Radio from '@mui/material/Radio';
import RadioGroup from '@mui/material/RadioGroup';
import FormControlLabel from '@mui/material/FormControlLabel';
import dateFormat from 'dateformat';
import { SnackBarOptions, Dateformat } from '../../../UserReport/DownloadableReport/CommonUtil';
import Attachments from '../../../../atoms/Attachments';
import DownArrow from '../../../../../assets/down-arrow.svg';
import Edit from '../../../../../assets/images/edit.svg';
import { Formik, Form, FormikProps, useFormikContext } from 'formik';
import useGetState from '../../../../utils/hooks/useGetState';
import { useDispatch } from 'react-redux';
import { executeACGAction, resetErr, updateScreenIdentifiers } from '../../../../store/slice';
import ACGButton from '../../../../atoms/Button';
import Label from '../../../../atoms/Label/index';
import TextInput from '../../../../atoms/TextInput';
import DropDown from '../../../../atoms/Dropdown';
import CustomDatePicker from '../../../../atoms/CustomDatePicker';
import CustomDateTimePicker from '../../../../atoms/CustomDateTimePicker';
import AcgTimePicker from '../../../../atoms/TimePicker';
import { fetchBlobImage } from '../../../../utils/helper';
import { convertBlobFileToBase64 } from '../../../../utils/helper';
import Axios from 'axios';
import { ACTION_CODES, STORE_KEYS, BASEURL } from '../../../../constants/apiConstants';

type ComponentProps = {
    drawerClose: Function;
    setCurrTab: Function;
    setReload: Function;
    setSnackbarOpts: Function;
    dtlData: any;
    selectedRow: any;
    isEdit: boolean;
    setDtlData: Function;
    setSelectedRow: Function;
    assetList: any;
    categoryList: any;
    actionTypeList: any;
    serviceRequestKey: any;
    getServiceRequestList: Function;
    fetchServiceRequestOrderDetails: Function;
    assignmentData: any;
};
type ModuleType = {
    startDateMO: any;
    startTimeMO: any;
    scName: string | '';
    scValue: string | '';
    scDesc: string | '';
};

const CreateModule = (props: ComponentProps) => {
    const { serviceRequestKey, drawerClose, setCurrTab, setReload = () => { }, setSnackbarOpts = () => { }, dtlData, isEdit, setDtlData = () => { }, selectedRow, setSelectedRow = () => { }, assetList, categoryList, actionTypeList, getServiceRequestList = () => { }, fetchServiceRequestOrderDetails = () => { }, assignmentData, } = props;
    const formRef = useRef<any>(null);
    const [feedback, setFeedback] = useState('');
    const [assignUserKey, setAssignUserKey] = useState<number|string|any>('');
    const [changeMOD, setChangeMOD] = useState(false);
    const [changeMOT, setChangeMOT] = useState(false);
    const [initialValues, setInitialValues] = useState({
        startDateMO: new Date(),
        startTimeMO: new Date(),
        scName: '',
        scValue: '',
        scDesc: '',
    });
    const [editImage, setEditImage] = useState(false);
    const [fName, setFname] = useState('');
    const state = useGetState();
    const token = state?.[STORE_KEYS.USER_DTL].token;
    const loggedInUser = state[STORE_KEYS.USER_DTL]?.LoggedUserKey;
    const dispatch = useDispatch();
    const ITEM_HEIGHT = 48;
    const ITEM_PADDING_TOP = 8;
    const MenuProps = {
        PaperProps: {
            style: {
                maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
                width: 250
            }
        }
    };

    const options = {
        AD_SUCCESS: {
            message: `Service request closed successfully!`,
            open: true,
            type: 'success'
        },
    };

    const isDisabled = feedback === '' || !/\S/.test(feedback) ? true : false;
    const renderButtons = () => {
        return (
            <div className={'btns noWrap'}>
                <ACGButton
                    name={'Cancel'}
                    handleClick={drawerClose}
                    secondary={true}
                    className={'first-btn'}
                    type="button"
                    variant="contained"
                />
                <ACGButton
                    name={'Submit'}
                    handleClick={() => { handleSubmit() }}
                    type="submit"
                    className="second-btn"
                    variant="contained"
                    disabled={isDisabled ? true : false}
                />
            </div>
        );
    };

    const handleSubmit = async () => {
        const bundle = {
            payload: {
                urlPath: ACTION_CODES.ADD_SERVICE_REQUEST_CLOSE,
                requestType: 'POST',
                reqObj: {
                    userId: loggedInUser,
                    workOrderKey: assignmentData[0]?.MaintBDWorkOrderKey,
                    MoDate: dateFormat(initialValues.startDateMO, Dateformat.YYYY_MM_DD) + ' ' + initialValues.startTimeMO.toString().split(' ')[4],
                    feedback: feedback.replace(/'/g, "''")
                }
            },
            uniqueScreenIdentifier: { serviceReqClose: true },
            storeKey: STORE_KEYS.ADD_SERVICE_REQUEST_CLOSE
        };
        dispatch(executeACGAction(bundle));
    };

    const handleSnackbarError = (err: any) => {
        const errorMsg = err?.message || 'Internal Server error';
        const snackbarError = {
            message: errorMsg,
            type: 'reject',
            open: true
        };
        setSnackbarOpts(snackbarError);
    };

    useEffect(() => {
        if (state?.[STORE_KEYS.ADD_SERVICE_REQUEST_CLOSE]?.serviceReqClose) {
            dispatch(
                updateScreenIdentifiers({
                    storeKey: STORE_KEYS.ADD_SERVICE_REQUEST_CLOSE,
                    uniqueScreenIdentifier: {
                        serviceReqClose: false
                    }
                })
            );
        }
    }, [state?.[STORE_KEYS.ADD_SERVICE_REQUEST_CLOSE]?.data]);

    useEffect(() => {
        if (state?.[STORE_KEYS.ADD_SERVICE_REQUEST_CLOSE]?.serviceReqClose) {
            if (state?.[STORE_KEYS.ADD_SERVICE_REQUEST_CLOSE]?.status === "Success") {
                setReload(true);
                setCurrTab(1);
                setSnackbarOpts(options.AD_SUCCESS);
                drawerClose();
                serviceRequestKey !== '' ? fetchServiceRequestOrderDetails(serviceRequestKey) : undefined;
                getServiceRequestList();
            }
            dispatch(
                updateScreenIdentifiers({
                    storeKey: STORE_KEYS.ADD_SERVICE_REQUEST_CLOSE,
                    uniqueScreenIdentifier: {
                        serviceReqClose: false
                    }
                })
            );
        }
    }, [state?.[STORE_KEYS.ADD_SERVICE_REQUEST_CLOSE]?.serviceReqClose]);

    useEffect(() => {
        if (state?.err) {
            handleSnackbarError(state?.err);
            dispatch(resetErr());
        }
    }, [state?.err]);

    const handleChangeAssignUser = (event: { target: { value: any; }; }) => {
        setAssignUserKey(event.target.value);
    }

    return (
        <Container className="createReport">
            <Formik
                initialValues={initialValues}
                onSubmit={(values: any) => {
                    // handleSubmit(values);
                }}
                validateOnBlur={false}
                validateOnChange={false}
                innerRef={formRef}
            >
                {(formikprops: FormikProps<ModuleType>) => {
                    const { values, handleChange, handleBlur, errors, touched, setFieldValue } = formikprops;
                    return (
                        <Form>
                        <Grid item xs={12} sm={12} md={6} xl={6} lg={6}>
                                            <Label label="Machine Back In Operation Date" className={'labelDiv'} />
                                             <CustomDateTimePicker
                                            name="dateTimeFromSelect"
                                            value={values.startDateMO}                                            
                                            className="inputDiv"
                                            onChange={(val: any) => {
                                                setInitialValues({ ...initialValues, startDateMO: val });
                                                setFieldValue('startDateMO', val);
                                            }}
                                            inputFormat="dd/MM/yyyy hh:mm aa"
                                            placeholder="Select From Date"
                                        />
                                        </Grid>
                                    <Label label="Feedback &nbsp; *" className={'labelDiv'} />
                                    <TextInput
                                        variant="outlined"
                                        fullWidth
                                        type="text"
                                        name="gtId"
                                        value={feedback}
                                        autoComplete="off"
                                        formInput="inputDiv"
                                        placeholder='Feedback'
                                        onChange={(e: any) => {
                                            setFeedback(e.target.value)
                                        }}
                                        maxLength={300}
                                    />
                            <Typography variant="body1" sx={{color:"#ffff", marginTop: 4}}>Note: * fields are mandatory! </Typography>
                            {renderButtons()}
                        </Form>
                    );
                }}
            </Formik>
        </Container>
    );
};
export default CreateModule;
