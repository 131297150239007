import { useState, useEffect } from 'react';
import { batch, useDispatch } from 'react-redux';
import { Container } from '@mui/material';
import { ACTION_CODES, STORE_KEYS, BASEURL } from '../../../app/constants/apiConstants';
import Axios from 'axios';
import CustomDatePicker from '../../atoms/CustomDatePicker';
import useGetState from '../../../app/utils/hooks/useGetState';
import { _noop } from '../../../app/utils/common';
import '../Admin/ClientManagement/index.scss';
import "./cm.scss"
import SnackbarAlert from '../../atoms/Snackbar';
import DropDown from '../../atoms/Dropdown';
import { Chart, registerables, ArcElement } from "chart.js";

import { executeACGAction, resetStoreKey } from '../../store/slice';
import dateFormat from 'dateformat';
import DownArrow from '../../../assets/down-arrow.svg';
import NoData from '../../../assets/nodata.svg'
import {
    Checkbox,
    FormControl,
    ListItemText,
    MenuItem,
    Select,
} from '@mui/material';
import { renameObjKeys } from '../Login/helper';
import { Dateformat } from '../UserReport/DownloadableReport/CommonUtil';

Chart.register(...registerables);
Chart.register(ArcElement);
const tabs = ['Machine List'];
const options = {
    DEFAULT: {
        message: '',
        open: false,
        type: ''
    }
};

const ITEM_HEIGHT = 48;
const ITEM_PADDING_TOP = 8;

const MenuProps = {
    PaperProps: {
        style: {
            maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
            width: 250
        }
    }
};

const Grafana = () => {
    const state = useGetState();
    const dispatch = useDispatch();
    const token = state?.[STORE_KEYS.USER_DTL].token;
    const loggedInUser = state[STORE_KEYS.USER_DTL]?.LoggedUserKey;
    const date = new Date();
    const d = new Date();
    date.setDate(date.getDate() - 14)

    const [snackbarOptions, setSnackbarOptions] = useState(options.DEFAULT);
    const [inDate, setInDate] = useState(date);
    const [endDate, setEndDate] = useState(new Date())
    const [machineList, setMachineList] = useState<any[]>([]);
    const [productList, setProductList] = useState<any>([]);
    const [batchList, setBatchList] = useState<any>([]);

    const [selectedMachine, setSelectedMachine] = useState<any>('');
    const [selectedProduct, setSelectedProduct] = useState<any>('');
    const [selectedBatch, setSelectedBatch] = useState<any>('');

    const [values, setValues] = useState<{ machine: string, product: string, batch: string } | null>(null)

    const handleChangeMachine = (event: any) => {
        setSelectedMachine(event.target.value);
        setProductList([])
        setSelectedProduct('')
        setBatchList([])
        setSelectedBatch('')
    }
    const handleChangeProduct = (event: any) => {
        setSelectedProduct(event.target.value);
        setBatchList([])
        setSelectedBatch('')
    }
    const handleChangeBatch = (event: any) => {
        setSelectedBatch(event.target.value);
    }

    const closeSnackbar = () => setSnackbarOptions(options.DEFAULT);

    const DropDownIcon = (prop: any) => (
        <img style={{ marginRight: '5px', marginTop: '-2px' }} src={DownArrow} {...prop} />
    );

    const getDowntimeFilters = (obj: any, pStoreKey: string) => {
        dispatch(
            executeACGAction({
                payload: {
                    requestType: 'POST',
                    urlPath: ACTION_CODES.DN_RPT_DOWNTIME_FILTER_DATA,
                    reqObj: obj
                },
                storeKey: pStoreKey
            })
        );
    };

    useEffect(() => {
        const date = new Date();
        setMachineList([])
        setProductList([])
        setSelectedMachine([])
        setSelectedProduct([])

        if (inDate && endDate) {
            const fromDate = dateFormat(inDate, Dateformat.YYYY_MM_DD);
            const toDate = dateFormat(
                endDate,
                Dateformat.YYYY_MM_DD
            );

            const payload = {
                params: {
                    type: 'machineName',
                    startDate: fromDate,
                    endDate: toDate
                }
            };
            getDowntimeFilters(payload, STORE_KEYS.DN_RPT_DOWNTIME_FILTER_MACHINE_DATA);
        }

    }, [inDate, endDate]);

    useEffect(() => {
        if (selectedMachine) {
            const payload = {
                params: {
                    type: 'productKey', machineKey: selectedMachine,
                    startDate: dateFormat(
                        inDate,
                        Dateformat.YYYY_MM_DD
                    ),
                    endDate: dateFormat(
                        endDate,
                        Dateformat.YYYY_MM_DD
                    ),
                }
            };
            getDowntimeFilters(payload, STORE_KEYS.DN_RPT_DOWNTIME_FILTER_PRODUCT_DATA);
        }
    }, [selectedMachine]);

    useEffect(() => {
        const date = new Date();
        if (selectedProduct) {
            const batchPayload = {
                params: {
                    type: 'batchNo',
                    machineKey: selectedMachine,
                    zproductkey: selectedProduct,
                    startDate: dateFormat(
                        inDate,
                        Dateformat.YYYY_MM_DD
                    ),
                    endDate: dateFormat(
                        endDate,
                        Dateformat.YYYY_MM_DD
                    ),
                }
            };
            getDowntimeFilters(batchPayload, STORE_KEYS.DN_RPT_DOWNTIME_FILTER_BATCH_DATA);
        }
    }, [selectedProduct]);

    useEffect(() => {
        if (state?.[STORE_KEYS.DN_RPT_DOWNTIME_FILTER_PRODUCT_DATA]?.body?.data) {
            const data = state?.[STORE_KEYS.DN_RPT_DOWNTIME_FILTER_PRODUCT_DATA]?.body?.data;
            const plants = data?.map((item: any) => {
                const newVal = renameObjKeys(item, { ZProductKey: 'code', ProductName: 'name' });
                return newVal;
            });
            setProductList(plants);
            dispatch(resetStoreKey({ storeKey: STORE_KEYS.DN_RPT_DOWNTIME_FILTER_PRODUCT_DATA }));
        }
    }, [state?.[STORE_KEYS.DN_RPT_DOWNTIME_FILTER_PRODUCT_DATA]?.body?.data]);

    useEffect(() => {
        if (state?.[STORE_KEYS.DN_RPT_DOWNTIME_FILTER_MACHINE_DATA]?.body?.data) {
            const data = state?.[STORE_KEYS.DN_RPT_DOWNTIME_FILTER_MACHINE_DATA]?.body?.data;
            const machineList = data?.map((item: any) => {
                const newVal = renameObjKeys(item, { MachineKey: 'code', MachineName: 'name' });
                return newVal;
            });
            setMachineList(machineList);
            dispatch(resetStoreKey({ storeKey: STORE_KEYS.DN_RPT_DOWNTIME_FILTER_MACHINE_DATA }));
        }
    }, [state?.[STORE_KEYS.DN_RPT_DOWNTIME_FILTER_MACHINE_DATA]?.body?.data]);

    useEffect(() => {
        if (state?.[STORE_KEYS.DN_RPT_DOWNTIME_FILTER_BATCH_DATA]?.body?.data) {
            const data = state?.[STORE_KEYS.DN_RPT_DOWNTIME_FILTER_BATCH_DATA]?.body?.data;
            let index = 0;
            const plants = data?.map((item: any) => {
                const item1 = Object.assign({}, item, { code: index });
                const newVal = renameObjKeys(item1, { code: 'code', BatchNo: 'name' });
                index++;
                return newVal;
            });
            setBatchList(plants);
            dispatch(resetStoreKey({ storeKey: STORE_KEYS.DN_RPT_DOWNTIME_FILTER_BATCH_DATA }));
        }
    }, [state?.[STORE_KEYS.DN_RPT_DOWNTIME_FILTER_BATCH_DATA]?.body?.data]);

    useEffect(() => {

        const MachineName = machineList.find((item: any) => item.code == selectedMachine)
        const ProductName = productList.find((item: any) => item.code == selectedProduct)
        const BatchName = batchList.find((item: any) => item.code == selectedBatch)
        if (MachineName && ProductName && BatchName) {

            setValues({
                machine: MachineName.name,
                product: ProductName.name,
                batch: BatchName.name
            })
        }

    }, [selectedMachine, selectedProduct, selectedBatch])

    return (
        <div className="dashboard PMEC-Dash">
            <SnackbarAlert options={snackbarOptions} handleClose={closeSnackbar} />
            <Container maxWidth="xl" style={{ marginTop: '60px' }}>
                <div className="headinglabel mb10" style={{ marginBottom: '15px' }}>
                    Grafana
                </div>
                <div className='filterdiv'>
                    <div className='width_filter'>
                        <div className='lableTitle'>From</div>
                        <CustomDatePicker
                            name="dateFromSelect"
                            value={inDate}
                            maxDate={new Date()}
                            className="inputDiv"
                            onChange={(val: any) => {
                                setInDate(val);
                                setMachineList([])
                                setProductList([])
                                setSelectedMachine([]);
                                setSelectedProduct([])
                            }}
                            inputFormat="dd/MM/yyyy"
                            placeholder="Select From Date"
                            minDate={new Date("01-01-2023")}

                        />
                    </div>
                    <div className='width_filter'>
                        <div className='lableTitle'>To</div>
                        <CustomDatePicker
                            name="dateFromSelect"
                            value={endDate}
                            minDate={inDate}
                            maxDate={new Date()}
                            className="inputDiv"
                            onChange={(val: any) => {
                                setEndDate(val);
                                setMachineList([])
                                setProductList([])
                                setSelectedMachine([]);
                                setSelectedProduct([])
                            }}
                            inputFormat="dd/MM/yyyy"
                            placeholder="Select End Date"
                        />
                    </div>
                    <div className='width_filter'>
                        <div className='lableTitle'>Select Machine</div>
                        <DropDown
                            onChange={handleChangeMachine}
                            className="inputDiv dropdownbx"
                            name="dropdownModel"
                            items={machineList}
                            placeHolder="Select Machines"
                            value={selectedMachine}
                        />
                    </div>
                    <div className='width_filter'>
                        <div className='lableTitle'>Select Product</div>
                        <DropDown
                            onChange={handleChangeProduct}
                            className="inputDiv dropdownbx"
                            name="dropdownModel"
                            items={productList}
                            placeHolder="Select Product"
                            value={selectedProduct}
                        />

                    </div>
                    <div className='width_filter'>
                        <div className='lableTitle'>Select Batch</div>
                        <DropDown
                            onChange={handleChangeBatch}
                            className="inputDiv dropdownbx"
                            name="dropdownModel"
                            items={batchList}
                            placeHolder="Select Product"
                            value={selectedBatch}
                        />

                    </div>
                </div>
                <div className='nodatagraphana'>
                    {values ? <iframe style={{width:"100%",height:"80vh"}} className='grafanavalue' src={`https://test.futuredna.io:3000/d/b7462558-613c-440e-a55d-4bac939b885e/bqs-filter-test?orgId=1&var-Machines=${values.machine}&var-ProductName=${values.product}&var-BatchNo=${values.batch}`}></iframe>
                        : <div className="emptyTable">
                        <div style={{ width: '100%', border: "none" }}><div className="nodataRowAlerts">
                          <img src={NoData} alt="no data" />
                          <div className="no_text" style={{"fontFamily": "Montserrat", "fontSize": "1rem", "color": "#c0c2d8"}}>
                            No data to show
                          </div>
                        </div></div></div>
                    }
                </div>
            </Container>
        </div>
    );
};

export default Grafana;
