import { Card } from '@mui/material';
import DialerIcon from '../../../assets/images/Dialer.svg';

type Props = { data: any };
const CurrentBroadcastCard = (props: Props) => {
    const { data } = props;
    return (
        <Card
            style={{
                border: '1px solid #2B2D42',
                borderRadius: '6px',
                backgroundColor: '#1D1E2C',
                padding: '10px',
                boxShadow: 'none',
                margin: '10px'
            }}
        >
            <div style={{ display: 'flex', justifyContent: 'space-evenly' }}>
                <div style={{ flex: '30%' }}>
                    <p
                        style={{
                            fontSize: '13px',
                            color: '#9CC2FF',
                            marginBottom: '10px',
                            fontFamily: 'Montserrat',
                            fontWeight: '600'
                        }}
                    >
                        {data?.BroadcastMessage}
                    </p>
                    <p style={{ fontSize: '11px', color: '#B1C2DF', marginBottom: '2px' }}> {data?.DateTimeRange}</p>
                </div>
            </div>
        </Card>
    );
};

export default CurrentBroadcastCard;
