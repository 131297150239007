import * as React from 'react';
import { useState, useEffect } from 'react';
import CustomTable from '../../../../atoms/CustomTable/index';
import CustomTableToolbar from '../../../../atoms/CustomTableToolbar';
import { Container, Card } from '@mui/material';
import { applySortFilter } from '../../../../utils/helper';
import { executeACGAction, resetStoreKey } from '../../../../store/slice';
import { ACTION_CODES, STORE_KEYS } from '../../../../constants/apiConstants';
import { useDispatch } from 'react-redux';
import useGetState from '../../../../utils/hooks/useGetState';
import DropDown from '../../../../atoms/Dropdown';
import FilterDrawer from '../../../../atoms/FilterDrawer';
import { Box } from '@material-ui/core';

const TABLE_HEAD = [
    { id: 'ParamCode', label: 'D Tags', alignRight: false },
    { id: 'ParamName', label: 'Parameter Name', alignRight: false },
    { id: 'ParamDesc', label: 'Parameter Description', alignRight: false },
    { id: 'SectionName', label: 'Section', alignRight: false },
    { id: 'ComponentName', label: 'Component', alignRight: false },
    { id: 'ParamLevel', label: 'Parameter Level', alignRight: false },
    { id: 'Category', label: 'Category', alignRight: false },
    { id: 'SubCategory', label: 'Sub Category', alignRight: false },
    { id: 'DataType', label: 'Data Type', alignRight: false },
    { id: 'Severity', label: 'Severity', alignRight: false },
    { id: 'AlarmType', label: 'Alarm Type', alignRight: false },
    { id: 'UOM', label: 'UOM', alignRight: false },
    { id: "IOTag", label: "IOTag", alignRight: false },
    { id: 'DowntimeFlag', label: 'Downtime Flag', alignRight: false }
];

type Details = {
    data: any;
};

type Props = {
    data: any;
};

const Details = (props: Props) => {
    const { MachineKey } = props?.data;
    const [page, setPage] = useState<number>(0);
    const [order, setOrder] = useState('desc');
    const [orderBy, setOrderBy] = useState('name');
    const [selected, setSelected] = useState<any>([]);
    const [filterName, setFilterName] = useState<string>('');
    const [tableData, setTableData] = useState<any[]>([]);
    const [severityDropdown, setseverityDropdown] = useState<any>([]);
    const [dataTypeDropdown, setDataTypeDropdown] = useState<any>([]);
    const [categoryDropdown, setCategoryDropdown] = useState<any>([]);
    const [filterOpen, setFilterOpen] = useState<boolean>(false);
    const [initialTableData, setInitialTableData] = useState<any[]>([]);
    const [selectedSeverityKey, setSeverityKey] = useState<any>('All');
    const [selectedDataType, setSelectedDataType] = useState<any>('All');
    const [selectedCategory, setSelectedCategory] = useState<any>('All');
    const filters = {
        mdFilter: (item: any) => item?.Severity === selectedSeverityKey,
        mfFilter: (item: any) => item?.DataType === selectedDataType,
        stFilter: (item: any) => item?.Category === selectedCategory,
        srchFilter: (item: any) => item?.ParamName?.toLowerCase()?.includes(filterName?.toLowerCase())
    };
    const dispatch = useDispatch();
    const reduxState = useGetState();

    useEffect(() => {
        getMachineParams();
        return () => {
            dispatch(resetStoreKey({ storeKey: STORE_KEYS.MACHINE_PARMS }));
        };
    }, [reduxState?.[STORE_KEYS.ADD_RPT]?.paramsMapped]);

    const getSeverityDropdownData = () => {
        dispatch(
            executeACGAction({
                payload: {
                    requestType: 'GET',
                    urlPath: ACTION_CODES.GET_MACHINE_SEVERITY_DROPDOWN
                },
                uniqueScreenIdentifier: { severityDropdown: true },
                storeKey: STORE_KEYS.ADD_SEVERITY_DROPDOWN
            })
        );
    };

    useEffect(() => {
        if (
            reduxState?.[STORE_KEYS.ADD_SEVERITY_DROPDOWN]?.body?.data &&
            reduxState?.[STORE_KEYS.ADD_SEVERITY_DROPDOWN]?.statusCode === 200
        ) {
            const __severity = reduxState?.[STORE_KEYS.ADD_SEVERITY_DROPDOWN]?.body?.data;
            const arry = [{ code: 'All', name: 'All' }];
            setseverityDropdown([
                ...arry,
                ...__severity?.map((item: any) => {
                    return { code: item.Severity, name: item.Severity };
                })
            ]);
            dispatch(resetStoreKey({ storeKey: STORE_KEYS.ADD_SEVERITY_DROPDOWN }));
        }
    }, [reduxState?.[STORE_KEYS.ADD_SEVERITY_DROPDOWN]?.severityDropdown]);

    const getDataTypeDropdownData = () => {
        dispatch(
            executeACGAction({
                payload: {
                    requestType: 'GET',
                    urlPath: ACTION_CODES.GET_MACHINE_DATA_TYPE_DROPDOWN
                },
                uniqueScreenIdentifier: { dataTypeDropdown: true },
                storeKey: STORE_KEYS.ADD_DATATYPE
            })
        );
    };

    useEffect(() => {
        if (
            reduxState?.[STORE_KEYS.ADD_DATATYPE]?.body?.data &&
            reduxState?.[STORE_KEYS.ADD_DATATYPE]?.statusCode === 200
        ) {
            const __dataType = reduxState?.[STORE_KEYS.ADD_DATATYPE]?.body?.data;
            const arry = [{ code: 'All', name: 'All' }];
            setDataTypeDropdown([
                ...arry,
                ...__dataType?.map((item: any) => {
                    return { code: item.DataType, name: item.DataType };
                })
            ]);
            dispatch(resetStoreKey({ storeKey: STORE_KEYS.ADD_DATATYPE }));
        }
    }, [reduxState?.[STORE_KEYS.ADD_DATATYPE]?.dataTypeDropdown]);

    const getCategoryDropdownData = () => {
        dispatch(
            executeACGAction({
                payload: {
                    requestType: 'GET',
                    urlPath: ACTION_CODES.GET_MACHINE_CATEGORY_DROPDOWN
                },
                uniqueScreenIdentifier: { categoryDropdown: true },
                storeKey: STORE_KEYS.ADD_CATEGORY
            })
        );
    };

    useEffect(() => {
        if (
            reduxState?.[STORE_KEYS.ADD_CATEGORY]?.body?.data &&
            reduxState?.[STORE_KEYS.ADD_CATEGORY]?.statusCode === 200
        ) {
            const __category = reduxState?.[STORE_KEYS.ADD_CATEGORY]?.body?.data;
            const arry = [{ code: 'All', name: 'All' }];
            setCategoryDropdown([
                ...arry,
                ...__category?.map((item: any) => {
                    return { code: item.Category, name: item.Category };
                })
            ]);
            dispatch(resetStoreKey({ storeKey: STORE_KEYS.ADD_CATEGORY }));
        }
    }, [reduxState?.[STORE_KEYS.ADD_CATEGORY]?.categoryDropdown]);

    const getMachineParams = () => {
        // GET_MACHINE_PARMS_DETAIL
        dispatch(
            executeACGAction({
                payload: {
                    requestType: 'GET',
                    urlPath: `${ACTION_CODES.GET_MACHINE_PARMS_DETAIL}/${MachineKey}`
                },
                uniqueScreenIdentifier: { dtlParmsAdded: true },
                storeKey: STORE_KEYS.MACHINE_PARMS
            })
        );
    };

    useEffect(() => {
        if (
            reduxState?.[STORE_KEYS.MACHINE_PARMS]?.statusCode == 200 &&
            reduxState?.[STORE_KEYS.MACHINE_PARMS]?.body?.data
        ) {
            const tmpTableData = reduxState?.[STORE_KEYS.MACHINE_PARMS]?.body?.data;
            setTableData(tmpTableData);
            setInitialTableData(tmpTableData);
        }
    }, [reduxState?.[STORE_KEYS.MACHINE_PARMS]?.body?.data]);

    const resetInput = () => {
        setFilterName('');
        let selectedFilter: any = [];
        if (selectedSeverityKey !== 'All') {
            selectedFilter = [...selectedFilter, filters.mdFilter];
        }
        if (selectedDataType !== 'All') {
            selectedFilter = [...selectedFilter, filters.mfFilter];
        }
        if (selectedCategory !== 'All') {
            selectedFilter = [...selectedFilter, filters.stFilter];
        }
        if (selectedFilter?.length !== 0) {
            const x = [...initialTableData]?.filter((ele: any) => selectedFilter?.every((f: any) => f(ele)));
            setTableData(x);
        } else {
            setTableData([...initialTableData]);
        }
    };

    const filteredList = applySortFilter(tableData, order, filterName, orderBy);

    const handleRequestSort = (event: any, property: any) => {
        setFilterName('');
        const isAsc = orderBy === event && order === 'asc';
        setOrder(isAsc ? 'desc' : 'asc');
        setOrderBy(event);
    };

    const handleFilterByName = (event: { target: { value: React.SetStateAction<string> } }) => {
        setOrderBy('ParamName');
        setPage(0);
        setFilterName(event.target.value);
    };

    const handleChangePage = (event: any, newPage: React.SetStateAction<number>) => {
        setFilterName('');
        setPage(newPage);
    };

    const handleFilter = async () => {
        await getSeverityDropdownData();
        await getCategoryDropdownData();
        await getDataTypeDropdownData();
        setFilterOpen(true);
    };
    const clearFilter = () => {
        setPage(0);
        setTableData(initialTableData);
        setSeverityKey('All');
        setSelectedCategory('All');
        setSelectedDataType('All');
        let selectedFilter: any = [];
        if (selectedCategory !== 'All') {
            selectedFilter = [...selectedFilter, filters.stFilter];
        }
        if (filterName !== '') {
            selectedFilter = [...selectedFilter, filters.srchFilter];
        }
        if (selectedFilter?.length !== 0) {
            const x = [...initialTableData]?.filter((ele: any) => selectedFilter?.every((f: any) => f(ele)));
            setTableData(x);
        } else {
            setTableData([...initialTableData]);
        }
        setFilterOpen(false);
    };
    const applyFilter = () => {
        setPage(0);
        setFilterName('');
        setOrderBy('ParamName');
        setOrder('desc');
        let selectedFilter: any = [];
        if (selectedSeverityKey !== 'All') {
            selectedFilter = [...selectedFilter, filters.mdFilter];
        }
        if (selectedDataType !== 'All') {
            selectedFilter = [...selectedFilter, filters.mfFilter];
        }
        if (selectedCategory !== 'All') {
            selectedFilter = [...selectedFilter, filters.stFilter];
        }
        if (filterName !== '') {
            selectedFilter = [...selectedFilter, filters.srchFilter];
        }
        if (selectedFilter?.length !== 0) {
            const x = [...initialTableData]?.filter((ele: any) => selectedFilter?.every((f: any) => f(ele)));
            setTableData(x);
        } else {
            setTableData([...initialTableData]);
        }

        setFilterOpen(false);
    };

    return (
        <div>
            <div>
                <Container
                    maxWidth="xl"
                    style={{
                        paddingLeft: '3px',
                        paddingRight: '3px'
                    }}
                >
                    <Card
                        style={{
                            borderRadius: '0px',
                            backgroundColor: '#2b2d42',
                            overflow: 'inherit'
                        }}
                    >
                        <CustomTableToolbar
                            drawerOpen={handleFilter}
                            numSelected={selected?.length}
                            filterName={filterName}
                            resetInput={resetInput}
                            onFilterName={handleFilterByName}
                            displayInput={true}
                            text={{
                                title: 'Total Parameters',
                                subTitle: `${tableData?.length} Parameters`
                            }}
                            filterButton={true}
                            customButton={false}
                        />
                    </Card>
                </Container>
                <CustomTable
                    selectable={false}
                    headers={TABLE_HEAD}
                    body={filteredList}
                    filteredUsers={filteredList}
                    filterName={filterName}
                    selected={[]}
                    machineSelected={[]}
                    order={order}
                    orderBy={orderBy}
                    onRequestSort={handleRequestSort}
                    onSelectAllClick={() => { }}
                    rowChange={() => { }}
                    machineRowChange={() => { }}
                    rowCount={filteredList.length}
                    onPageChange={handleChangePage}
                    page={page}
                    setPageNumber={setPage}
                    selectRow={() => { }}
                    route={() => { }}
                    isRequestPage={true}
                    link={true}
                />
            </div>
            <FilterDrawer
                handlerClose={() => {
                    setFilterOpen(false);
                }}
                status={filterOpen}
                drawerHeader={'Filter'}
                children={
                    <Container className="createReport">
                        <Box style={{ minHeight: '75vh' }}>
                            {/* <Label label="Severity" className={'labelDiv'} /> */}
                            <DropDown
                                onChange={(event: any) => {
                                    setSeverityKey(event.target.value);
                                }}
                                label="Severity"
                                placeHolder="Select Severity"
                                name="dropdownSeverity"
                                className="inputDiv"
                                items={severityDropdown}
                                value={selectedSeverityKey}
                            />
                            <DropDown
                                onChange={(event: any) => {
                                    setSelectedDataType(event.target.value);
                                }}
                                label="Data Type"
                                placeHolder="Select data type"
                                name="dropdownDatatype"
                                className="inputDiv"
                                items={dataTypeDropdown}
                                value={selectedDataType}
                            />
                            <DropDown
                                onChange={(event: any) => {
                                    setSelectedCategory(event.target.value);
                                }}
                                label="Category"
                                placeHolder="Select Category"
                                name="dropdownCategory"
                                className="inputDiv"
                                items={categoryDropdown}
                                value={selectedCategory}
                            />
                        </Box>
                    </Container>
                }
                clearFn={clearFilter}
                applyFn={applyFilter}
            />
        </div>
    );
};

export default Details;
