import { Paper, Grid, Typography, IconButton } from '@mui/material';
import { Formik, Form } from 'formik';
import { useState, useEffect, useRef } from 'react';
import * as Yup from 'yup';
import { useHistory } from 'react-router-dom';
import Button from '../../../../atoms/Button/index';
import Label from '../../../../atoms/Label/index';
import TextInput from '../../../../atoms/TextInput/index';
import { FORGOTPWD_YUPSCHEMA } from './schema';
import { useDispatch, useSelector } from 'react-redux';
import { ACTION_CODES, STORE_KEYS } from '../../../../constants/apiConstants';
import { executeACGAction, resetErr, updateScreenIdentifiers } from '../../../../store/slice';
import { acgSelector } from '../../../../store/selector';
import { createStructuredSelector } from 'reselect';
import SnackbarAlert from '../../../../atoms/Snackbar';
const options = {
    DEFAULT: {
        message: '',
        open: false,
        type: ''
    },
    ERROR: {
        message: 'Email ID is not registered',
        type: 'reject',
        open: true
    }
};
type ComponentProps = { data: any };
const ForgotPwdTemplate = (props: ComponentProps) => {
    const { data } = props;
    const dispatch = useDispatch();
    const history = useHistory();
    const [validationSchema, setValidationSchema] = useState({});
    const [disableProceed, setDisableProceed] = useState(true);
    const [snackbarOptions, setSnackbarOptions] = useState(options.DEFAULT);
    const formRef = useRef<any>(null);
    const [initialValues] = useState({
        emailId: ''
    });
    const acgStateSelector = createStructuredSelector({
        acgSlice: acgSelector()
    });
    const { acgSlice: state } = useSelector(acgStateSelector);

    useEffect(() => {
        setValidationSchema(Yup.object().shape(FORGOTPWD_YUPSCHEMA));
    }, []);

    const handleSubmit = async () => {
        const bundle = {
            payload: {
                urlPath: `${ACTION_CODES.GETQA}/${formRef?.current?.values?.emailId}/`,
                requestType: 'GET',
                reqObj: {}
            },
            uniqueScreenIdentifier: { qnRecd: true },
            storeKey: STORE_KEYS.CHANGE_PWD
        };
        dispatch(executeACGAction(bundle));
    };

    useEffect(() => {
        if (state?.changePwd?.qnRecd) {
            dispatch(
                updateScreenIdentifiers({
                    storeKey: STORE_KEYS.CHANGE_PWD,
                    uniqueScreenIdentifier: {
                        qnRecd: false
                    }
                })
            );
            if (state?.changePwd?.statusCode === 200) {
                data?.setValues({
                    ...data?.values,
                    email: formRef?.current?.values?.emailId,
                    forgetPwdQnkey: state?.changePwd?.body?.data?.[0]?.ForgetPwdQnKey
                });
                data?.setTemplateType('forgotPwdDtl');
            } else if (state?.changePwd?.statusCode === 202) {
                if (state?.changePwd?.body?.data?.length == 0) {
                    setSnackbarOptions(options.ERROR);
                }
            }
        }
    }, [state?.changePwd?.qnRecd]);

    const handleSnackbarError = (err: any) => {
        const errorMsg = err?.message || 'Internal Server error';
        const snackbarError = {
            message: errorMsg,
            type: 'reject',
            open: true
        };
        setSnackbarOptions(snackbarError);
    };

    useEffect(() => {
        if (state?.err) {
            handleSnackbarError(state?.err);
            dispatch(resetErr());
        }
    }, [state?.err]);
    const closeSnackbar = () => setSnackbarOptions(options.DEFAULT);
    return (
        <Paper elevation={2} className="loginPaper forgotPass">
            <SnackbarAlert className="login-snackbar" options={snackbarOptions} handleClose={closeSnackbar} />
            <div className="loginFormHeader">
                <Typography className="welcomeText">Forgot Password</Typography>
                <Typography className="loginText">Enter below details to create new password</Typography>
            </div>
            <Formik
                initialValues={initialValues}
                validationSchema={validationSchema}
                onSubmit={() => {
                    handleSubmit();
                }}
                validateOnBlur={false}
                innerRef={formRef}
            >
                {(formikprops) => {
                    const { values, handleChange, handleBlur, isValid, errors, touched } = formikprops;
                    return (
                        <Form>
                            <Label label="Email ID" />
                            <TextInput
                                variant="outlined"
                                maxLength={50}
                                autoFocus
                                fullWidth
                                type="text"
                                name="emailId"
                                value={values.emailId}
                                autoComplete="off"
                                formInput="textBoxDiv"
                                onChange={(e) => {
                                    setDisableProceed(false);
                                    handleChange(e);
                                }}
                                onBlur={handleBlur}
                                placeholder="Enter Email ID"
                                helperText={errors.emailId && touched.emailId ? errors.emailId : ''}
                            />

                            <Grid container spacing={2}>
                                <Grid item xs={12} sm={6} md={6} xl={6} lg={6}>
                                    <Button
                                        formInput="buttonDiv"
                                        fullWidth
                                        name="Cancel"
                                        handleClick={() => history.push('/')}
                                        secondary
                                        type="button"
                                        variant="contained"
                                    />
                                </Grid>
                                <Grid item xs={12} sm={6} md={6} xl={6} lg={6}>
                                    <Button
                                        formInput="buttonDiv"
                                        className="simpleLoginButton"
                                        fullWidth
                                        name="Proceed"
                                        type="submit"
                                        variant="contained"
                                        disabled={disableProceed || !isValid}
                                    />
                                </Grid>
                            </Grid>
                        </Form>
                    );
                }}
            </Formik>
        </Paper>
    );
};

export default ForgotPwdTemplate;
