import { useState, useEffect, useRef } from 'react';
import Box from '@mui/material/Box';
import Tooltip from '@mui/material/Tooltip';
import Axios from 'axios';
import EditIcon from '@mui/icons-material/Edit';
import AddIcon from '@mui/icons-material/Add';
import DeleteIcon from '@mui/icons-material/Delete';
import RightDrawer from '../../../../app/atoms/tempDrawer/indexAlt';
import { Typography } from '@mui/material';
import Label from '../../../atoms/Label/index';
import TextInput from '../../../atoms/TextInput';
import DownArrow from '../../../../assets/down-arrow.svg';
import DropDown from '../../../atoms/Dropdown';
import InputLabel from '@mui/material/InputLabel';
import CheckBoxOutlineBlankIcon from '@mui/icons-material/CheckBoxOutlineBlank';
import FailureIndicatorConfig from '../CreateFailureIndicatorConfig';
import { QueryBuilderMaterial } from '@react-querybuilder/material';
import CheckBoxIcon from '@mui/icons-material/CheckBox';
import { createTheme, ThemeProvider } from '@mui/material/styles';
import Checkbox from '@mui/material/Checkbox';
import OutlinedInput from '@mui/material/OutlinedInput';
import HelpIcon from '@mui/icons-material/Help';
import Autocomplete from '@mui/material/Autocomplete';
import Radio from '@mui/material/Radio';
import RadioGroup from '@mui/material/RadioGroup';
import FormControlLabel from '@mui/material/FormControlLabel';
import MenuItem from '@mui/material/MenuItem';
import FormControl from '@mui/material/FormControl';
import Select from '@mui/material/Select';
import ListItemText from '@mui/material/ListItemText';
import TextField from '@mui/material/TextField';
import Stack from '@mui/material/Stack';
import Button from '@mui/material/Button';
import Container from '@mui/material/Container';
import ACGButton from '../../../atoms/Button';
import useGetState from '../../../utils/hooks/useGetState';
import { ACTION_CODES, STORE_KEYS, BASEURL } from '../../../constants/apiConstants';
import FMEAService from '../fmeaServices';
import "../cm.scss";
import "./style.css";
import QueryBuilder, { defaultOperators, RuleValidator, ValidationResult, formatQuery } from 'react-querybuilder';
import { Grid } from '@mui/material';
import { AnyARecord } from 'dns';
import { fontWeight } from '@mui/system';
import { set } from 'date-fns';

const ITEM_HEIGHT = 48;
const ITEM_PADDING_TOP = 8;
const MenuProps = {
  PaperProps: {
    style: {
      maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
      width: 250,
    },
  },
};
const muiTheme = createTheme();
const icon = <CheckBoxOutlineBlankIcon fontSize="small" />;
const checkedIcon = <CheckBoxIcon fontSize="small" />;
const initialQuery = { combinator: 'and', rules: [] };
export default function CreateForm(props: any) {
  const [potentialCauseData, setPotentialCauseData] = useState<any>([]);
  const [potentialCause, setPotentialCause] = useState<any>("");
  const [occurance, setOccurance] = useState<any>("");
  const [detection, setDetection] = useState<any>("");
  const [openCasual, setOpenCasual] = useState<boolean>(false);
  const editing = props.customKey !== '' ? true : false;
  const [queryData, setQueryData] = useState<any>([]);
  const [machineGroupKey, setMachineGropuKey] = useState<string>('');
  const [recommandAction, setRecommandAction] = useState<string>("");
  const [recommandList, setRecommandList] = useState<any>([]);
  const [data, setData] = useState<any>("");
  const [index, setIndex] = useState<any>("");
  const ref = useRef(null);
  const state = useGetState();
  const token = state?.[STORE_KEYS.USER_DTL].token;
  const loggedInUser = state[STORE_KEYS.USER_DTL]?.LoggedUserKey;
  const [showQuery, setShowQuery] = useState<boolean>(false);
  const [key, setKey] = useState('');
  const [isReset,setReset]=useState(false)

  useEffect(() => {
    if (props.potentialCauses[props.selectedCasualParameter] && props.customKey !== '' && !props.newEntry) {
      (async function () {
        console.log(props.potentialCauses[props.selectedCasualParameter])
        setPotentialCause(props.potentialCauses[props.selectedCasualParameter]?.PotentialCause);
        const okey = props.occuranceCriteria.filter((i: any) => i.name === props.potentialCauses[props.selectedCasualParameter]?.OccurenceCriteria);
        const dkey = props.detectionCriteria.filter((i: any) => i.name === props.potentialCauses[props.selectedCasualParameter]?.DetectionCriteria);
        setOccurance(okey[0]?.code);
        setDetection(dkey[0]?.code);
        const rl = await props?.recList[props.selectedCasualParameter];
        setRecommandList(rl);
        setQueryData([{
          condFormula: props.queryData[props.selectedCasualParameter],
          jsonCond: props.custQuery[props.selectedCasualParameter],
          paramCodes: props.queryParameters[props.selectedCasualParameter]
        }]);
      })()
    }
  }, [props.customKey])

  const reset = async () => {
    setOccurance("");
    setDetection("");
    setPotentialCause("");
  }

  const options = {
    AD_SUCCESS: {
      message: `Potential causes ${props.potentialCauses.length === 0 ? 'created' : 'edited'} successfully!`,
      open: true,
      type: 'success'
    },
    AD_FAIL: {
      message: `Potential causes failed to create`,
      open: true,
      type: 'remark'
    }
  };

  const createCustomAlert = () => {
    const cf: any = [], jc: any = [], pc: any = [], rl: any = [];
    queryData?.map((i: any) => {
      cf.push(i.condFormula);
      jc.push(i.jsonCond);
      pc.push(i.paramCodes);
    });
    recommandList?.map((i: any) => {
      rl.push(i)
    })
    const body = {
      userId: loggedInUser,
      presetRuleKey: props.customKey,
      potentialCause: potentialCause,
      occurance: occurance,
      detection: detection,
      condFormula: cf.toString(),
      jsonCond: jc.toString(),
      MachMdlParamGrpKeys: machineGroupKey,
      paramCodes: pc.toString(),
      recommandList: JSON.stringify(rl),
      PotentialCauseKey: props.newEntry ? null : props.casualParameterKey,
      type: props.newEntry ? 'Add' : "Edit",
      token: token
    }
    console.log("🚀 ~ file: index.tsx:145 ~ createCustomAlert ~ body:", body)
    console.log("aaaaabody",body)
    FMEAService.createFMEAPotentialCause(body)
      .then((response: any) => {
        if (response.status === 200) {
          reset();
          props.setCurrTab(1);
          const result = response?.data?.message[0];
          const checkIfExsist = result[0];
          const isError = checkIfExsist[0];
          if (isError.Result === 'Error') {
            props.setSnackbarOpts(options.AD_FAIL);
          }
          else {
            props.setSnackbarOpts(options.AD_SUCCESS);
          }
          props.drawerClose();
          props.fetchCustomAlerts();
        }
      })
      .catch((err) => {
        console.log(err);
      });
  }

  const check = (object: any) => {
    for (const value of Object.values(object)) {
      if (value === "" || (typeof value === "object" && check(value))) {
        return true;
      }
    }

    return false;
  }

  const yesDisabled = (
    (potentialCause === '' ||
      occurance === "" ||
      detection === "" || queryData.length === 0 || recommandList.length === 0)) ? true : false;

  const renderButtons = () => {
    return (
      <div className={'btns noWrap'} style={{ display: "flex", margin: "10px 0", justifyContent: "flex-end", width: "100%" }}>
        <ACGButton
          name={'Cancel'}
          handleClick={props.drawerClose}
          secondary={true}
          className={'first-btn'}
          style={{ height: '40px', marginRight: '10px' }}
          type="button"
          variant="contained"
        />
        <ACGButton
          name={'Submit'}
          handleClick={() => { createCustomAlert() }}
          type="submit"
          className="second-btn"
          style={{ height: '40px', fontWeight: '500' }}
          variant="contained"
          disabled={yesDisabled ? true : false}
        />
      </div>
    );
  };

  const handleChangeOccurance = (e: any) => {
    setOccurance(e.target.value);
  }

  const handleChangeDetection = (e: any) => {
    setDetection(e.target.value);
  }

  const addRecommendations = async () => {
    if (recommandAction !== "") {
      if(key) deleteHandler(data,index);
      setRecommandList([...recommandList, { PotentialCauseRcmddActKey: key !== '' ? key : null, RecommendedAction: recommandAction }]);
      setRecommandAction("")
      setKey('');
  }
  }

  const editHandler = async (data: any, index: number) => {
    setData(data);
    setIndex(index)
    const key = data?.PotentialCauseRcmddActKey
    setKey(key);
    const comment = data?.RecommendedAction
    setRecommandAction(comment);
}

  const deleteHandler = (data: any, index: number) => {
    for (var i = 0; i < recommandList.length; i++) {
      if (recommandList[index].PotentialCauseRcmddActKey === data?.PotentialCauseRcmddActKey) {
        const data = recommandList.splice(i, 1);
        recommandList.length === 0 ? setRecommandList([]) : setRecommandList(data);
        setData('');
        setIndex('');
        break;
      }
    }
  }

  return (
    <Container className="createReportt">
      <RightDrawer
        handlerOpen={() => { setOpenCasual(true) }}
        handlerClose={() => { setOpenCasual(false) }}
        status={openCasual}
        drawerHeader={`Causal Parameter`}
        children={
          <FailureIndicatorConfig
            customKey={props.customKey}
            drawerClose={() => { setOpenCasual(false); setShowQuery(false) }}
            setDtlData={props.setDtlData}
            dtlData={props.dtlData}
            setSnackbarOpts={props.setSnackbarOptions}
            isEdit={false}
            selectedRow={props.selectedRow}
            setSelectedRow={props.setSelectedRow}
            customersData={props.customersData}
            setCustomKey={props.setCustomKey}
            fetchCustomAlerts={props.fetchCustomAlerts}
            editableCustomData={props.editableCustomData}
            failureIndicatorsData={[]}
            casualEntry={true}
            setQueryData={setQueryData}
            setMachineGropuKey={setMachineGropuKey}
            setOpenCasual={setOpenCasual}
            queryData={queryData}
            showQuery={showQuery}
            groupKeyNames={isReset?"":props.groupKeyNames}
            mainOperators={props.mainOperators}
          />
        }
      />
      <Box>
        <div className='formwrap all-labelcls'>
          <FormControl fullWidth>
            <Grid item xs={4}>
              <FormControl fullWidth>
                <Label label="Potential Causes &nbsp; *" className={'CMlabelDiv multilineheight'} />
                <Box
                  className='multilineheight'
                  component="form"
                  sx={{
                    '& .MuiTextField-root': { m: 0, width: '100%' },
                  }}
                  noValidate
                  autoComplete="off"
                >
                  <TextInput
                    fullWidth
                    placeholder='Potential Cause'
                    className="multilinetxt"
                    variant="outlined"
                    maxRows={4}
                    sx={{ margin: '0px', padding: '2px' }}
                    multiline
                    value={potentialCause}
                    onChange={(e) => setPotentialCause(e.target.value)}

                  // size='500'

                  />
                </Box>
              </FormControl>
            </Grid>
            <Grid item xs={4}>
              <FormControl fullWidth>
                <Label label="Occurance Frequency &nbsp; *" className={'CMlabelDiv'} />
                <DropDown
                  onChange={handleChangeOccurance}
                  className="inputDiv"
                  name="dropdownModel"
                  items={props.occuranceCriteria}
                  placeHolder="Occurance Frequency"
                  value={occurance}
                />
              </FormControl>
            </Grid>

            <Grid item xs={4}>
              <FormControl fullWidth>
                <Label label="Ease of detection &nbsp; *" className={'CMlabelDiv'} />
                <DropDown
                  onChange={handleChangeDetection}
                  className="inputDiv"
                  name="dropdownModel"
                  items={props.detectionCriteria}
                  placeHolder="Select Ease of detection"
                  value={detection}
                />
              </FormControl>
            </Grid>
            {queryData.length === 0 ?
              <Grid item xs={4} mt={2}>
                <FormControl fullWidth>
                  <div style={{width: '170px'}} onClick={() => { setShowQuery(false); setOpenCasual(true); setReset(true) }}><Label label="+ Add Causal Parameter" className={'CMlabelDivv'} /></div>
                </FormControl>
              </Grid> : null}
            {!props.newEntry ?
              <Grid item xs={4} mt={2}>
                <FormControl fullWidth>
                  <Button onClick={() => { setShowQuery(true); setOpenCasual(true); }}><Label label="View Added Causal Parameter" className={'CMlabelDiv'} /></Button>
                </FormControl>
              </Grid> : null}
            <Grid item xs={4}>
              <FormControl fullWidth>
                <Label label="Recommended Action" className={'CMlabelDiv multilineheight'} />
                <Box
                  className='multilineheight'
                  component="form"
                  sx={{
                    '& .MuiTextField-root': { m: 0, width: '100%' },
                  }}
                  noValidate
                  autoComplete="off"
                >
                  <div style={{ display: 'flex' }}>
                    <div style={{ width: "90%" }}>
                      <TextInput
                        fullWidth
                        placeholder='Recommended Action'
                        className="multilinetxt"
                        variant="outlined"
                        maxRows={4}
                        sx={{ margin: '0px', padding: '2px' }}
                        multiline
                        value={recommandAction}
                        onChange={(e) => setRecommandAction(e.target.value)}

                      // size='500'

                      />
                    </div>
                    <Button onClick={addRecommendations}>
                      <AddIcon />
                    </Button>
                  </div>
                </Box>
              </FormControl>
            </Grid>
            {

              recommandList?.map((i: any, index: number) => {
                return (
                  <Grid item xs={4} mt={2}>
                    <FormControl fullWidth>
                      <div style={{ display: "flex", alignItems: "center" }}>
                        <div style={{ wordBreak: "break-all", width: "400px" }} >
                          <span style={{ color: "#ffff" }}>{i?.RecommendedAction}</span>
                        </div>
                        <div>
                          <Button><EditIcon onClick={() => { editHandler(i, index) }} /></Button>
                          <Button><DeleteIcon onClick={() => deleteHandler(i, index)} /></Button>
                        </div>
                      </div>
                    </FormControl>
                  </Grid>)
              })

            }
          </FormControl>
        </div>
      </Box>
      <Grid style={{ display: "flex", marginTop: 20 }}>
        {renderButtons()}
      </Grid>
    </Container>
  );
}