import React from 'react'
import "./index.scss"

function Loader() {
  return (
    <div className="loader-con">
        <div className='loader-text'>Loading...</div>
        <div className='loader'></div>
    </div>
  )
}

export default Loader