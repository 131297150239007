import { useEffect, useRef, useState } from 'react';
import { Container } from '@mui/material';
import { Formik, Form, FormikProps } from 'formik';
import * as Yup from 'yup';
import { MN_YUPSCHEMA } from '../schema';
import useGetState from '../../../../utils/hooks/useGetState';
import { useDispatch } from 'react-redux';
import { executeACGAction, resetErr, updateScreenIdentifiers } from '../../../../store/slice';
import ACGButton from '../../../../atoms/Button';
import Label from '../../../../atoms/Label/index';
import TextInput from '../../../../atoms/TextInput';
import '../CreateReport/index.scss';
import { ACTION_CODES, STORE_KEYS } from '../../../../constants/apiConstants';
import DropDown from '../../../../atoms/Dropdown';
import { renameObjKeys } from '../../../Login/helper';

type ComponentProps = {
    drawerClose: Function;
    setSnackbarOpts: Function;
    dtlData?: any;
    setReload: Function;
    setDtlData: Function;
};

const options = {
    AD_SUCCESS: {
        message: 'Menu updated successfully!',
        open: true,
        type: 'success'
    }
};

type ModuleType = {
    dropdownMd: string | '';
    mnCode: string | '';
    mnFName: string | '';
    mnHName: string | '';
    mnOrdId: Number | undefined;
    mnIcon: string | '';
};
const EditMenu = (props: ComponentProps) => {
    const { drawerClose, dtlData, setReload = () => {}, setSnackbarOpts = () => {}, setDtlData } = props;

    const formRef = useRef<any>(null);
    const [validationSchema, setValidationSchema] = useState({});
    const [initialValues] = useState({
        dropdownMd: dtlData?.ModuleKey || '',
        mnCode: dtlData?.MenuCode || '',
        mnFName: dtlData?.MenuName || '',
        mnHName: dtlData?.MenuHeaderName || '',
        mnOrdId: dtlData?.MenuOrderId || '',
        mnIcon: dtlData?.MenuImagePath || ''
    });
    const [modules, setModules] = useState<any[]>([]);
    const state = useGetState();
    const dispatch = useDispatch();

    const renderButtons = () => {
        return (
            <div className={'btns noWrap'}>
                <ACGButton
                    name={'Cancel'}
                    handleClick={drawerClose}
                    secondary={true}
                    className={'first-btn'}
                    type="button"
                    variant="contained"
                />
                <ACGButton
                    name={'Submit'}
                    handleClick={() => {}}
                    type="submit"
                    className="second-btn"
                    variant="contained"
                />
            </div>
        );
    };
    useEffect(() => {
        setValidationSchema(Yup.object().shape(MN_YUPSCHEMA));
        dispatch(
            executeACGAction({
                payload: {
                    requestType: 'GET',
                    urlPath: ACTION_CODES.GET_MODULES_DN
                },
                uniqueScreenIdentifier: { mdsLoaded: true },
                storeKey: STORE_KEYS.MODULES_DN
            })
        );
    }, []);
    useEffect(() => {
        if (state?.[STORE_KEYS.MODULES_DN]?.mdsLoaded) {
            const mds = state?.[STORE_KEYS.MODULES_DN]?.body?.data;
            const mdsArr = mds?.map((item: any) => {
                const newVal = renameObjKeys(item, { ModuleKey: 'code', ModuleName: 'name' });
                return newVal;
            });
            setModules(mdsArr);
        }
    }, [state?.[STORE_KEYS.MODULES_DN]?.mdsLoaded]);
    const handleSubmit = async () => {
        let reqObj = {};
        reqObj = {
            menuCode: formRef?.current?.values?.mnCode,
            menuName: formRef?.current?.values?.mnFName,
            menuHeaderName: formRef?.current?.values?.mnHName,
            menuImagePath: formRef?.current?.values?.mnIcon,
            moduleKey: formRef?.current?.values?.dropdownMd,
            menuOrderId: Number(formRef?.current?.values?.mnOrdId)
        };

        const bundle = {
            payload: {
                urlPath: ACTION_CODES.EDIT_MENU,
                requestType: 'PUT',
                reqObj: reqObj
            },
            uniqueScreenIdentifier: { menuAdded: true },
            storeKey: STORE_KEYS.ADD_MENU
        };
        dispatch(executeACGAction(bundle));
        
    };

    useEffect(() => {
        if (state?.[STORE_KEYS.ADD_MENU]?.menuAdded) {
            if (state?.[STORE_KEYS.ADD_MENU]?.statusCode === 200) {
                setDtlData(
                    renameObjKeys(
                        { ...formRef?.current?.values },
                        {
                            mnCode: 'MenuCode',
                            mnFName: 'MenuName',
                            mnOrdId: 'MenuOrderId',
                            dropdownMd: 'ModuleKey',
                            mnIcon: 'MenuImagePath',
                            mnHName: 'MenuHeaderName'
                        }
                    )
                );
                setSnackbarOpts(options.AD_SUCCESS);
                setReload(true);
                drawerClose();
            }
            dispatch(
                updateScreenIdentifiers({
                    storeKey: STORE_KEYS.ADD_MENU,
                    uniqueScreenIdentifier: {
                        menuAdded: false
                    }
                })
            );
        }
    }, [state?.[STORE_KEYS.ADD_MENU]?.menuAdded]);

    const handleSnackbarError = (err: any) => {
        const errorMsg = err?.message || 'Internal Server error';
        const snackbarError = {
            message: errorMsg,
            type: 'reject',
            open: true
        };
        setSnackbarOpts(snackbarError);
    };

    useEffect(() => {
        if (state?.err) {
            handleSnackbarError(state?.err);
            dispatch(resetErr());
        }
    }, [state?.err]);

    return (
        <Container className="createReport">
            <Formik
                initialValues={initialValues}
                validationSchema={validationSchema}
                onSubmit={() => {
                    handleSubmit();
                }}
                validateOnBlur={false}
                validateOnChange={false}
                innerRef={formRef}
            >
                {(formikprops: FormikProps<ModuleType>) => {
                    const { values, handleChange, handleBlur, errors, touched } = formikprops;
                    return (
                        <Form>
                            <Label label="Module" className={'labelDiv'} />
                            <DropDown
                                onChange={(event) => {
                                    handleChange(event);
                                }}
                                disabled={true}
                                name="dropdownMd"
                                items={modules}
                                placeHolder="Select Module"
                                value={values.dropdownMd}
                                helperText={errors.dropdownMd && touched.dropdownMd ? errors.dropdownMd : ''}
                            />
                            <Label label="Feature Code" className={'labelDiv'} />
                            <TextInput
                                variant="outlined"
                                fullWidth
                                disabled={true}
                                type="text"
                                name="mnCode"
                                value={values.mnCode}
                                autoComplete="off"
                                formInput="inputDiv"
                                onChange={(e: any) => {
                                    handleChange(e);
                                }}
                                onBlur={handleBlur}
                                placeholder="Enter Feature Code"
                                helperText={errors.mnCode && touched.mnCode ? errors.mnCode : ''}
                            />
                            <Label label="Feature Name" className={'labelDiv'} />
                            <TextInput
                                variant="outlined"
                                fullWidth
                                type="text"
                                name="mnFName"
                                value={values.mnFName}
                                autoComplete="off"
                                formInput="inputDiv"
                                onChange={(e: any) => {
                                    handleChange(e);
                                }}
                                onBlur={handleBlur}
                                placeholder="Enter Feature Name"
                                helperText={errors.mnFName && touched.mnFName ? errors.mnFName : ''}
                            />
                            <Label label="Header Name" className={'labelDiv'} />
                            <TextInput
                                variant="outlined"
                                fullWidth
                                type="text"
                                name="mnHName"
                                value={values.mnHName}
                                autoComplete="off"
                                formInput="inputDiv"
                                onChange={(e: any) => {
                                    handleChange(e);
                                }}
                                onBlur={handleBlur}
                                placeholder="Enter Header Name"
                                helperText={errors.mnHName && touched.mnHName ? errors.mnHName : ''}
                            />
                            <Label label="Order ID" className={'labelDiv'} />
                            <TextInput
                                variant="outlined"
                                fullWidth
                                type="text"
                                name="mnOrdId"
                                value={values.mnOrdId?.toString()}
                                autoComplete="off"
                                formInput="inputDiv"
                                onChange={(e: any) => {
                                    handleChange(e);
                                }}
                                onBlur={handleBlur}
                                placeholder="Enter Order ID"
                                helperText={errors.mnOrdId && touched.mnOrdId ? errors.mnOrdId : ''}
                            />
                            <Label label="Menu Icon" className={'labelDiv'} />
                            <TextInput
                                variant="outlined"
                                fullWidth
                                type="text"
                                name="mnIcon"
                                value={values.mnIcon}
                                autoComplete="off"
                                formInput="inputDiv"
                                onChange={(e: any) => {
                                    handleChange(e);
                                }}
                                onBlur={handleBlur}
                                placeholder="Enter Menu Icon File Name"
                                helperText={errors.mnIcon && touched.mnIcon ? errors.mnIcon : ''}
                            />
                            {renderButtons()}
                        </Form>
                    );
                }}
            </Formik>
        </Container>
    );
};
export default EditMenu;
