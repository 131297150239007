import React, { useState, useEffect, useRef } from 'react';
import { Button, Grid } from '@mui/material';
import Label from '../../../atoms/Label/index';
import TextInput from '../../../atoms/TextInput';
import Attachments from '../../../atoms/Attachments';
import { Avatar } from '@mui/material';
import { Formik, Form, FormikProps } from 'formik';
import * as Yup from 'yup';
import { MACHINE_SCHEMAS } from '../schema';
import useGetState from '../../../utils/hooks/useGetState';
import { useDispatch } from 'react-redux';
import { ACTION_CODES, STORE_KEYS } from '../../../constants/apiConstants';
import { executeACGAction, resetStoreKey } from '../../../store/slice';
import DropDown from '../../../atoms/Dropdown';
import { renameObjKeys } from '../../Login/helper';
import ACGButton from '../../../atoms/Button';

interface Props {
    dtlData: any;
    uploadParams: Function;
    downloadparams: Function;
    addParams: Function;
    handleSubmit: Function;
    children?: any;
}
type MachineType = {
    machineName: string | '';
    dropdownModelNameKey: string | '';
    dropdownMachineFamilyKey: string | '';
    machineSrNo: string | '';
};
const HeaderItems = (props: Props) => {
    const { dtlData, handleSubmit, uploadParams, downloadparams, addParams, children } = props;
    console.log('dtlData', dtlData);

    const [initialValues] = useState({
        machineName: dtlData?.MachineName,
        dropdownMachineFamilyKey: dtlData?.MachineFamilyKey,
        machineSrNo: dtlData?.MachineSerialNo,
        dropdownModelNameKey: dtlData?.MachineModelKey
    });
    const [machineModelNames, setMachineModelNames] = useState<any>([]);
    const [machineFamily, setMachineFamily] = useState<any>([]);
    const [validationSchema, setValidationSchema] = useState({});
    const reduxState = useGetState();
    const dispatch = useDispatch();
    const formRef = useRef<any>(null);

    useEffect(() => {
        setValidationSchema(Yup.object().shape(MACHINE_SCHEMAS));
        getMachineFamily();
        getMachineModel();
    }, []);

    useEffect(() => {
        if (
            reduxState?.[STORE_KEYS.ADD_MACHINEMODEL_DROPDOWN]?.body?.data &&
            reduxState?.[STORE_KEYS.ADD_MACHINEMODEL_DROPDOWN]?.statusCode === 200
        ) {
            const modelNames = reduxState?.[STORE_KEYS.ADD_MACHINEMODEL_DROPDOWN]?.body?.data;
            setMachineModelNames(
                modelNames?.map((item: any) => {
                    const newVal = renameObjKeys(item, { MachineModelKey: 'code', MachineModelName: 'name' });
                    return newVal;
                })
            );
            dispatch(resetStoreKey({ storeKey: STORE_KEYS.ADD_MACHINEMODEL_DROPDOWN }));
        }
    }, [reduxState?.[STORE_KEYS.ADD_MACHINEMODEL_DROPDOWN]?.body?.data]);

    const getMachineModel = () => {
        if (!reduxState?.[STORE_KEYS.ADD_MACHINEMODEL_DROPDOWN]?.body?.data) {
            dispatch(
                executeACGAction({
                    payload: {
                        requestType: 'GET',
                        urlPath: ACTION_CODES.GET_MACHINEMODEL_DROPDOWN
                    },
                    storeKey: STORE_KEYS.ADD_MACHINEMODEL_DROPDOWN,
                    uniqueScreenIdentifier: {
                        isLoading: true
                    }
                })
            );
        }
    };

    const getMachineFamily = () => {
        if (!reduxState?.[STORE_KEYS.ADD_MACHINE_FAMILY]?.body?.data) {
            dispatch(
                executeACGAction({
                    payload: {
                        requestType: 'GET',
                        urlPath: ACTION_CODES.GET_MACHINE_FAMILY
                    },
                    storeKey: STORE_KEYS.ADD_MACHINE_FAMILY,
                    uniqueScreenIdentifier: {
                        isLoading: true
                    }
                })
            );
        }
    };

    useEffect(() => {
        if (
            reduxState?.[STORE_KEYS.ADD_MACHINE_FAMILY]?.body?.data &&
            reduxState?.[STORE_KEYS.ADD_MACHINE_FAMILY]?.statusCode === 200
        ) {
            const mchnFamily = reduxState?.[STORE_KEYS.ADD_MACHINE_FAMILY]?.body?.data;
            setMachineFamily(
                mchnFamily?.map((item: any) => {
                    const newVal = renameObjKeys(item, { MachineFamilyKey: 'code', MachineFamilyName: 'name' });
                    return newVal;
                })
            );
            dispatch(resetStoreKey({ storeKey: STORE_KEYS.ADD_MACHINE_FAMILY }));
        }
    }, [reduxState?.[STORE_KEYS.ADD_MACHINE_FAMILY]?.body?.data]);

    const handleDropdownChange = (event: any) => {
        const { name, value } = event.target;
        if (name === 'dropdownModelNameKey') {
            // setTableData([]);
            // setSelectedModalData(event.target.value);
        } else {
            // setSelectedMachineFamily(value);
        }
    };

    const renderButtons = () => {
        return (
            <Grid
                container
                spacing={2}
                justifyContent="end"
                style={{
                    marginTop: '20px'
                }}
            >
                <Grid item>
                    <div
                        style={{
                            display: 'flex',
                            justifyContent: 'center',
                            marginLeft: 'auto'
                        }}
                    >
                        <ACGButton
                            name="Upload Parameters"
                            handleClick={uploadParams}
                            secondary={true}
                            type="button"
                            className="second-btn"
                            variant="contained"
                        />
                    </div>
                </Grid>

                <Grid item>
                    <div
                        style={{
                            display: 'flex',
                            justifyContent: 'center',
                            marginLeft: 'auto'
                        }}
                    >
                        <ACGButton
                            name="Download Excel Templete"
                            handleClick={downloadparams}
                            secondary={true}
                            type="button"
                            className="first-btn"
                            variant="contained"
                        />
                    </div>
                </Grid>

                <Grid item>
                    <div
                        style={{
                            display: 'flex',
                            justifyContent: 'center',
                            marginLeft: 'auto'
                        }}
                    >
                        <ACGButton
                            name="Add Parameters"
                            handleClick={addParams}
                            type="button"
                            className="third-btn"
                            variant="contained"
                        />
                    </div>
                </Grid>
            </Grid>
        );
    };
    return (
        <Formik
            initialValues={initialValues}
            validationSchema={validationSchema}
            onSubmit={(values: any) => {
                handleSubmit(values);
            }}
            validateOnBlur={false}
            validateOnChange={false}
            innerRef={formRef}
        >
            {(formikprops: FormikProps<MachineType>) => {
                const { values, handleChange, errors, touched } = formikprops;
                return (
                    <>
                        <Form>
                            <div
                                style={{
                                    padding: '24px',
                                    borderBottom: '1px solid rgb(43 45 66 / 100%)',
                                    marginBottom: '24px'
                                }}
                            >
                                <Grid container spacing={2}>
                                    <Grid item xs={6} lg={3} md={4} sm={6}>
                                        <Label label="Model Name" className={'labelDiv'} />
                                        <DropDown
                                            onChange={(event) => {
                                                handleChange(event);
                                                handleDropdownChange(event);
                                            }}
                                            className="inputDiv"
                                            name="dropdownModelNameKey"
                                            items={machineModelNames}
                                            placeHolder="All Parameters"
                                            value={values.dropdownModelNameKey}
                                            helperText={
                                                errors.dropdownModelNameKey && touched.dropdownModelNameKey
                                                    ? errors.dropdownModelNameKey
                                                    : ''
                                            }
                                        />
                                    </Grid>
                                    <Grid item xs={6} lg={3} md={4} sm={6}>
                                        <Label label="Machine Name" className={'labelDiv'} />
                                        <TextInput
                                            variant="outlined"
                                            fullWidth
                                            type="text"
                                            name="machineName"
                                            value={values.machineName}
                                            autoComplete="off"
                                            formInput="inputDiv"
                                            onChange={(event) => {
                                                handleChange(event);
                                            }}
                                            onBlur={() => {}}
                                            placeholder="Enter Machine Name"
                                            helperText={
                                                errors.machineName && touched.machineName ? errors.machineName : ''
                                            }
                                        />
                                    </Grid>
                                    <Grid item xs={6} lg={3} md={4} sm={6}>
                                        <Label label="Machine Serial Number" className={'labelDiv'} />
                                        <TextInput
                                            variant="outlined"
                                            fullWidth
                                            type="text"
                                            name="machineSrNo"
                                            value={values.machineSrNo}
                                            autoComplete="off"
                                            formInput="inputDiv"
                                            onChange={(event) => {
                                                handleChange(event);
                                            }}
                                            placeholder="Enter Machine Serial Number"
                                            helperText={
                                                errors.machineSrNo && touched.machineSrNo ? errors.machineSrNo : ''
                                            }
                                        />
                                    </Grid>
                                    <Grid item xs={6} lg={3} md={4} sm={6}>
                                        <Label label="Machine Family" className={'labelDiv'} />
                                        <DropDown
                                            onChange={(event) => {
                                                handleChange(event);
                                                handleDropdownChange(event);
                                            }}
                                            className="inputDiv"
                                            name="dropdownMachineFamilyKey"
                                            items={machineFamily}
                                            placeHolder="All Parameters"
                                            value={values.dropdownMachineFamilyKey}
                                            helperText={
                                                errors.dropdownMachineFamilyKey && touched.dropdownMachineFamilyKey
                                                    ? errors.dropdownMachineFamilyKey
                                                    : ''
                                            }
                                        />
                                    </Grid>
                                    {/* {renderButtons()} */}
                                </Grid>
                            </div>
                            {children}
                        </Form>
                    </>
                );
            }}
        </Formik>
    );
};

export default React.memo(HeaderItems);
