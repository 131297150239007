import React, { useState, useEffect } from 'react';
import { useDispatch } from 'react-redux';
import CustomTable from '../../../atoms/CustomTable/index';
import CustomTableToolbar from '../../../atoms/CustomTableToolbar';
import { Container, Card } from '@mui/material';
import TemporaryDrawer from '../../../atoms/tempDrawer';
import { ACTION_CODES, STORE_KEYS } from '../../../constants/apiConstants';
import { executeACGAction, resetErr, resetStoreKey, updateScreenIdentifiers } from '../../../store/slice';
import useGetState from '../../../utils/hooks/useGetState';
import { applySortFilter } from '../../../utils/helper';
import SnackbarAlert from '../../../atoms/Snackbar';
import AlertBox from '../../../atoms/AlertBox';
import DetailDrawer from '../../../atoms/DetailDrawer';
import { formatAccordionData } from '../../../utils/formatData';
import ContactDetailDrawer from '../../../atoms/ContactDetailDrawer';

const TABLE_HEAD = [
    { id: 'SysAttributeName', label: 'Name', alignRight: false },
    { id: 'SysAttributeValue', label: 'Value', alignRight: false },
    { id: 'SysAttributeDesc', label: 'Description', alignRight: false },
    { id: 'Action', label: 'Action', alignLeft: true }
];

const options = {
    DEFAULT: {
        message: '',
        open: false,
        type: ''
    },
    RM_SUCCESS: {
        message: 'System Configuration Deleted',
        open: true,
        type: 'success'
    },
    USR_ADD_SUCCESS: {
        message: 'System Configuration Added successfully!',
        open: true,
        type: 'success'
    }
};

type Anchor = 'right';

const Users = (props: any) => {
    const {
        getSummary,
        radioValue,
        selectedClient,
        setDtlData,
        dtlData,
        setReload,
        reload,
        // selectedRow,
        // setSelectedRow,
        setCreateValue,
        setDrawerHeader,
        editButtonClick,
        setCreateDrawerOpen: setEditDrawerOpen
    } = props;

    const dispatch = useDispatch();
    const [drawerState, setDrawerState] = useState({
        right: false
    });
    const [page, setPage] = useState<number>(0);
    const [order, setOrder] = useState('desc');
    const [orderBy, setOrderBy] = useState('SysAttributeName');
    const [selected, setSelected] = useState<any>([]);
    const [selectedRow, setSelectedRow] = useState<any>({});
    const [filterName, setFilterName] = useState<string>('');
    const [tableData, setTableData] = useState<any[]>([]);
    const [createDrawerOpen, setCreateDrawerOpen] = useState(false);
    const [isSystemLoading,setIsSystemLoading]=useState(false)

    const state = useGetState();
    //add in use effect
    const getList = () => {
        setIsSystemLoading(true)
        dispatch(
            executeACGAction({
                payload: {
                    requestType: 'GET',
                    urlPath: ACTION_CODES.GET_SYSTEMCONFIG
                },
                storeKey: STORE_KEYS.SYSTEMCONFIG_LIST
            })
        );
    };
    useEffect(() => {
        setTableData([]);
        getList();
    }, []);

    useEffect(() => {
        if (reload) {
            getList();
            setReload(false);
            setTableData([]);
        }
    }, [reload]);

    useEffect(() => {
        const tmpTableData = state[STORE_KEYS.SYSTEMCONFIG_LIST]?.body?.data;
        if(!tmpTableData) return
        tmpTableData && setTableData(tmpTableData.map((item: any) => ({ ...item, status: item.UserStatus })));
        setIsSystemLoading(false)
    }, [state[STORE_KEYS.SYSTEMCONFIG_LIST]?.body?.data]);

    const [snackbarOptions, setSnackbarOptions] = useState(options.DEFAULT);
    const [removeBxOpen, setRemoveBxOpen] = useState(false);
    const [rmUser, setRmUser] = useState(false);
    const [RNVKey, setRNVKey] = useState<any>();
    const closeRmBx = () => {
        setRemoveBxOpen(false);
    };
    const removeFn = (row: any, ele: any) => {
        setSelectedRow({ ...row });
        setRNVKey(ele?.RNVKey);
        setRemoveBxOpen(true);
    };
    useEffect(() => {
        if (rmUser) {
            closeRmBx();
            dispatch(
                executeACGAction({
                    payload: {
                        requestType: 'DELETE',
                        urlPath: ACTION_CODES.DELETE_SYSCONFIG + selectedRow?.RNVKey ?? RNVKey
                    },
                    uniqueScreenIdentifier: {
                        rowDeleted: true
                    },
                    storeKey: STORE_KEYS.DELETE
                })
            );
            setRmUser(false);
        }
    }, [rmUser]);
    useEffect(() => {
        if (state[STORE_KEYS.DELETE]?.rowDeleted) {
            if (state?.[STORE_KEYS.DELETE]?.statusCode === 200) {
                setSnackbarOptions(options.RM_SUCCESS);
                getList();
                getSummary();
                setCreateDrawerOpen(false);
            }
            dispatch(
                updateScreenIdentifiers({
                    storeKey: STORE_KEYS.DELETE,
                    uniqueScreenIdentifier: {
                        rowDeleted: false
                    }
                })
            );
        }
    }, [state[STORE_KEYS.DELETE]?.rowDeleted]);

    useEffect(() => {
        if (state?.[STORE_KEYS.ADD_RPT]?.accessAdded) {
            if (state?.[STORE_KEYS.ADD_RPT]?.statusCode === 200) {
                setSnackbarOptions(options.USR_ADD_SUCCESS);
                getList();
                getSummary();
            }
            dispatch(
                updateScreenIdentifiers({
                    storeKey: STORE_KEYS.ADD_RPT,
                    uniqueScreenIdentifier: {
                        accessAdded: false
                    }
                })
            );
        }
    }, [state?.[STORE_KEYS.ADD_RPT]?.accessAdded]);

    const handleRequestSort = (event: any, property: any) => {
        const isAsc = orderBy === event && order === 'asc';
        setOrder(isAsc ? 'desc' : 'asc');
        setOrderBy(event);
    };

    const handleSelectAllClick = (event: any) => {
        if (event.target.checked) {
            const newSelecteds = [];
            for (let i = 0; i < tableData.length; i++) {
                newSelecteds.push(tableData[i].id);
            }
            const toRemove = tableData.slice(page, tableData.length);
            setSelected(newSelecteds);
            return;
        }
        setSelected([]);
    };

    const handleClick = (requestedBy: string) => {
        const selectedIndex = selected.indexOf(requestedBy);
        let newSelected: any[] | ((prevState: unknown[]) => unknown[]) = [];
        if (selectedIndex === -1) {
            newSelected = newSelected.concat(selected, requestedBy);
        } else if (selectedIndex === 0) {
            newSelected = newSelected.concat(selected.slice(1));
        } else if (selectedIndex === selected.length - 1) {
            newSelected = newSelected.concat(selected.slice(0, -1));
        } else if (selectedIndex > 0) {
            newSelected = newSelected.concat(selected.slice(0, selectedIndex), selected.slice(selectedIndex + 1));
        }
        setSelected(newSelected);
    };

    const toggleDrawer = (anchor: Anchor, open: boolean) => (event: React.KeyboardEvent | React.MouseEvent) => {
        if (
            event.type === 'keydown' &&
            ((event as React.KeyboardEvent).key === 'Tab' || (event as React.KeyboardEvent).key === 'Shift')
        ) {
            return;
        }
        setDrawerState({ ...drawerState, [anchor]: open });
    };

    const filteredList = applySortFilter(tableData, order, filterName, orderBy);

    const handleFilterByName = (event: { target: { value: string } }) => {
        setPage(0);
        setFilterName(event.target.value);
        setOrderBy('SysAttributeName');
    };

    const handleChangePage = (event: any, newPage: React.SetStateAction<number>) => {
        setPage(newPage);
    };

    const showRow = (event: any) => { };

    const handleSnackbarError = (err: any) => {
        const errorMsg = err?.message || 'Internal Server error';
        const snackbarError = {
            message: errorMsg,
            type: 'reject',
            open: true
        };
        setSnackbarOptions(snackbarError);
    };

    useEffect(() => {
        if (state?.err) {
            handleSnackbarError(state?.err);
            dispatch(resetErr());
        }
    }, [state?.err]);

    const handleRoute = (row: any, ele: any) => {
        if (ele === 'Action') {
            setSelectedRow({ ...row });
            setDtlData(row);
            setCreateDrawerOpen(true);
        }
    };

    // useEffect(() => {
    //     if (state?.[STORE_KEYS.USER_ACCESS_DETAILS]?.statusCode === 200) {
    //         const tmpTableData = state[STORE_KEYS.USER_ACCESS_DETAILS]?.body?.data;
    //         if (tmpTableData) {
    //             if (tmpTableData?.[0]?.PlantKey === -1) {
    //                 getUserAccessDetails();
    //             } else {
    //                 const formatedData = formatAccordionData(tmpTableData, true);
    //                 selectedRow.plants = formatedData;
    //                 selectedRow.count = tmpTableData?.length;
    //                 selectedRow.actualData = tmpTableData;
    //                 setDtlData(selectedRow);
    //                 dispatch(resetStoreKey({ storeKey: STORE_KEYS.USER_ACCESS_DETAILS }));
    //             }
    //         }
    //     }
    // }, [state?.[STORE_KEYS.USER_ACCESS_DETAILS]?.body?.data]);

    useEffect(() => {
        if (state?.[STORE_KEYS.ALL_SELECTED_PLANTS]?.statusCode === 200) {
            const AcgAllSelectedData = state[STORE_KEYS.ALL_SELECTED_PLANTS]?.body?.data;
            if (AcgAllSelectedData) {
                const formatedData = formatAccordionData(AcgAllSelectedData, true);
                selectedRow.plants = formatedData;
                selectedRow.count = AcgAllSelectedData?.length;
                selectedRow.actualData = state?.[STORE_KEYS.USER_ACCESS_DETAILS]?.body?.data;
                setDtlData(selectedRow);
                dispatch(resetStoreKey({ storeKey: STORE_KEYS.USER_ACCESS_DETAILS }));
            }
        }
    }, [state[STORE_KEYS.ALL_SELECTED_PLANTS]?.body?.data]);

    const closeDrawer = () => {
        setDtlData([]);
        setCreateDrawerOpen(false);
    };

    const closeSnackbar = () => setSnackbarOptions(options.DEFAULT);
    const removeMd = (ele: any) => {
        // setMdKey(ele?.ModuleKey);
        setRemoveBxOpen(true);
    };
    const resetInput = () => {
        setFilterName('');
    };

    useEffect(()=>{
        closeDrawer()
    },[editButtonClick])

    return (
        <div>
            <div>
                <SnackbarAlert options={snackbarOptions} handleClose={closeSnackbar} />
                <Container
                    maxWidth="xl"
                    style={{
                        paddingLeft: '3px',
                        paddingRight: '3px'
                    }}
                >
                    <Card
                        style={{
                            borderRadius: '0px',
                            backgroundColor: '#2b2d42',
                            overflow: 'inherit'
                        }}
                    >
                        <CustomTableToolbar
                            drawerOpen={toggleDrawer('right', true)}
                            numSelected={selected?.length}
                            filterName={filterName}
                            onFilterName={handleFilterByName}
                            resetInput={resetInput}
                            displayInput={true}
                            text={{
                                title: 'System List ',
                                subTitle: `${filteredList.length} System Count`
                            }}
                            filterButton={false}
                            customButton={false}
                        />
                    </Card>
                </Container>
                <CustomTable
                    selectable={false}
                    headers={TABLE_HEAD}
                    body={filteredList}
                    isLoading={isSystemLoading}
                    filteredUsers={filteredList}
                    filterName={filterName}
                    selected={selected}
                    isTableImageReqd={true}
                    order={order}
                    orderBy={orderBy}
                    onRequestSort={handleRequestSort}
                    onSelectAllClick={handleSelectAllClick}
                    rowChange={(event: string) => handleClick(event)}
                    rowCount={filteredList.length}
                    onPageChange={handleChangePage}
                    page={page}
                    setPageNumber={setPage}
                    selectRow={showRow}
                    route={handleRoute}
                    isRequestPage={true}
                    link={true}
                    removeFn={removeFn}
                    {...(radioValue === 'Customer' && !selectedClient
                        ? { noDataText: 'Selected client to display users' }
                        : {})}
                />
            </div>
            <AlertBox
                isOpen={removeBxOpen}
                handleClose={closeRmBx}
                leftBtnPress={closeRmBx}
                rightBtnPress={() => setRmUser(true)}
                modalHeader="Delete System Configuration user"
                modalContent="Are you sure you want to delete System Configuration user?"
            />
            <TemporaryDrawer
                handlerOpen={toggleDrawer('right', true)}
                handlerClose={toggleDrawer('right', false)}
                status={state['right']}
            />
            {/* <ContactDetailDrawer
                handlerOpen={() => { }}
                handlerClose={closeDrawer}
                status={createDrawerOpen}
                data={selectedRow}
                block={true}
                usedIn="contact"
                type="Contact"
                getList={getList}
                removeFn={removeFn}
                // deleteFn={() => removeFn}
                editFn={() => {
                    setEditDrawerOpen(true);
                    setCreateValue('Contact');
                    setDrawerHeader('Edit');
                }}
            /> */}
            <DetailDrawer hasImage={false}
                handlerOpen={() => { }}
                handlerClose={closeDrawer}
                status={createDrawerOpen}
                data={{
                    name: selectedRow.SysAttributeName,
                    details: [
                        { code: 'System Configuration Value', name: selectedRow.SysAttributeValue },
                        { code: 'System Configuration Description', name: selectedRow.SysAttributeDesc }
                    ]
                }}
                type={'Feature'}
                editFn={() => {
                    setEditDrawerOpen(true);
                    setCreateValue("System");
                    setDrawerHeader("Edit");
                }}
                deleteFn={() => removeMd('')}
            />

        </div>
    );
};

export default Users;
