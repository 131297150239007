import { useState, useEffect } from 'react';
import Container from '@mui/material/Container';
import Grid from '@mui/material/Grid';
import Box from '@mui/material/Box';
import { Typography } from '@mui/material';
import moment from 'moment';
const ViewDetails = (props: any) => {
    console.log("ceecking", props)
    return (
        <Container className="createReport">
            <Box>
                <div className='formwrap all-labelcls'>
                    <Grid mt={2}>
                        <div className='viewData'>
                            {props.selectedRow.MachineName ? <div className="viewLeftCLC">Machine Name<span>:&nbsp;{props.selectedRow.MachineName}</span></div> : null}
                            <div className="viewLeftCLC">Assembly Name<span>:&nbsp;{props.selectedRow.AssemblyName}</span></div>
                            <div className="viewLeftCLC">Component Name<span>:&nbsp;{props.selectedRow.ComponentName}</span></div>
                            {props.selectedRow.ComponentsStatus?<div className="viewLeftCLC">Components Status<span>:&nbsp;{props.selectedRow.ComponentsStatus === "E" ? "Enabled" : "Disabled"}</span></div>:null}
                            {props.selectedRow.MCLCWorkOrderStatusName?<div className="viewLeftCLC">Work Order Status<span>:&nbsp;{props.selectedRow.MCLCWorkOrderStatusName}</span></div>:null}
                            {props.selectedRow.LastReplacedDate?<div className="viewLeftCLC">Last Replaced Date<span>:&nbsp;{props?.selectedRow?.LastReplacedDate}</span></div>:null}
                            
                            <div className="viewLeftCLC">Calendar Life In Hours<span>:&nbsp;{props.selectedRow.CalendarLifeInHours}</span></div>
                            {props.selectedRow.OperatingLifeInHours?<div className="viewLeftCLC">Operating Life In Hours<span>:&nbsp;{props.selectedRow.OperatingLifeInHours}</span></div>:null}
                            <div className="viewLeftCLC">Alerts Basis Operating Hours<span>:&nbsp;{props.selectedRow.AlertsBasisOperatingHours === "Y" ? "Yes" : "No"}</span></div>
                           {props.selectedRow.ReplacementAlertInHours?<div className="viewLeftCLC">Remaining Life (in hours)<span>:&nbsp;{props.selectedRow.ReplacementAlertInHours}</span></div>:null} 
                           {/* {props.selectedRow.PostDueReminderInHours?<div className="viewLeftCLC">Post Due Reminder In Hours<span>:&nbsp;{props.selectedRow.PostDueReminderInHours}</span></div>:null}  */}
                            {props.selectedRow.CloseDate ? <div className="viewLeftCLC">Closed Date<span>:&nbsp;{props.selectedRow.CloseDate}</span></div> : null}
                            {props.selectedRow.ReplacedActionDesc ? <div className="viewLeftCLC">Replaced Action Description<span>:&nbsp;{props.selectedRow.ReplacedActionDesc}</span></div> : null}
                        </div>
                    </Grid>
                </div>
            </Box>
        </Container>
    )
}
export default ViewDetails;