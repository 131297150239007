import * as React from 'react';
import { useState, useEffect } from 'react';
import CustomTable from '../../atoms/CustomTable/index';
import CustomTableToolbar from '../../atoms/CustomTableToolbar';
import { Container, Card, Box } from '@mui/material';
import { applySortFilter } from '../../utils/helper';
import MachineManagementTopCard from '../../atoms/MachineMonitoringTopCard';
import AlarmDetailDrawer from '../../atoms/AlarmDetailDrawer';
import { executeACGAction, resetStoreKey, updateScreenIdentifiers } from '../../store/slice';
import { ACTION_CODES, STORE_KEYS } from '../../constants/apiConstants';
import { renameObjKeys } from '../Login/helper';
import { useDispatch } from 'react-redux';
import useGetState from '../../utils/hooks/useGetState';
import { formatAlarmDataObj } from '../../utils/formatData';
import DropDown from '../../atoms/Dropdown';
import FilterDrawer from '../../atoms/FilterDrawer';
import { paths } from '../../constants/paths';
import BackArrowIcon from '../../../assets/images/BackArrowIcon.svg';
import { useHistory } from 'react-router-dom';

const TABLE_HEAD = [
    { id: 'ParamName', label: 'Event', alignRight: false },
    // {id: 'MachineName', label: 'Machine Name', alignRight: false},
    { id: 'NoOfOccurred', label: 'Occurrence', alignRight: false },
    { id: 'MachineModelName', label: 'Model', alignRight: false },
    { id: 'TriggeredTime', label: 'Last Trigger Time', alignRight: false },
    { id: 'Severity', label: 'Severity', alignRight: false },
    { id: 'Action', label: 'Action', alignLeft: true }
];

type Anchor = 'right';

const EventDetail = () => {
    const [state, setState] = useState({
        right: false
    });
    const [page, setPage] = useState<number>(0);
    const [order, setOrder] = useState('desc');
    const [orderBy, setOrderBy] = useState('machineName');
    const [selected, setSelected] = useState<any>([]);
    const [filterName, setFilterName] = useState<string>('');
    const [tableData, setTableData] = useState<any[]>([]);
    const [initialTableData, setInitialTableData] = useState<any[]>([]);
    const [createDrawerOpen, setCreateDrawerOpen] = useState(false);
    const [selectedRow, setSelectedRow] = useState<any>([]);
    const [activeAlarms, setActiveAlarms] = useState<any[]>([]);
    const [timePeriodDropdown, setTimePeriodDropdown] = useState<any[]>([]);
    const [timePeriodKey, setTimePeriodKey] = useState<any>(1);
    const [filterOpen, setFilterOpen] = useState<boolean>(false);
    const [selectedSeverityKey, setSeverityKey] = useState<any>(null);
    const [severityDropdown, setseverityDropdown] = useState<any>(null);
    const [eventSummary, setEventSummary] = useState<any[]>([]);

    const dispatch = useDispatch();
    const reduxState = useGetState();

    useEffect(() => {
        getTimePeriodDropdownData();
        if (filterOpen) {
            getSeverityDropdownData();
        }
    }, [filterOpen]);

    useEffect(() => {
        getEventMachineList();
    }, [timePeriodKey]);

    useEffect(() => {
        return () => {
            setTableData([]);
            setInitialTableData([]);
            setActiveAlarms([]);
        };
    }, []);
    useEffect(() => {
        getAlarmSummary();
    }, []);
    const getAlarmSummary = () => {
        dispatch(
            executeACGAction({
                payload: {
                    requestType: 'POST',
                    urlPath: ACTION_CODES.GET_EVENTS_SUMMARY,
                    reqObj: {
                        severityKey: -1,
                        timePeriodKey: 1
                    }
                },
                uniqueScreenIdentifier: { alarmSummary: true },
                storeKey: STORE_KEYS.ADD_EVENT_SUMMARY
            })
        );
    };

    useEffect(() => {
        if (
            reduxState?.[STORE_KEYS.ADD_EVENT_SUMMARY]?.body?.data &&
            reduxState?.[STORE_KEYS.ADD_EVENT_SUMMARY]?.statusCode === 200
        ) {
            const __eventSummary = reduxState?.[STORE_KEYS.ADD_EVENT_SUMMARY]?.body?.data?.[0];
            const formateddata = [
                { title: 'Total Events', count: __eventSummary.TotalEvents },
                { title: 'Critical', count: __eventSummary.Critical },
                { title: 'Major', count: __eventSummary.Major },
                { title: 'Minor', count: __eventSummary.Minor }
            ];
            setEventSummary(formateddata);
            dispatch(resetStoreKey({ storeKey: STORE_KEYS.ADD_EVENT_SUMMARY }));
        }
        dispatch(
            updateScreenIdentifiers({
                storeKey: STORE_KEYS.ADD_EVENT_SUMMARY,
                uniqueScreenIdentifier: {
                    alarmSummary: false
                }
            })
        );
    }, [reduxState?.[STORE_KEYS.ADD_EVENT_SUMMARY]?.alarmSummary]);

    const getEventMachineList = () => {
        dispatch(
            executeACGAction({
                payload: {
                    requestType: 'POST',
                    urlPath: ACTION_CODES.GET_ALL_EVENTS__OF_MODEL,
                    reqObj: {
                        severityKey: -1,
                        timePeriodKey: timePeriodKey
                    }
                },
                uniqueScreenIdentifier: { eventModelTable: true },
                storeKey: STORE_KEYS.ADD_ALL_EVENT_OF_MODEL
            })
        );
    };

    useEffect(() => {
        if (
            reduxState?.[STORE_KEYS.ADD_ALL_EVENT_OF_MODEL]?.body?.data &&
            reduxState?.[STORE_KEYS.ADD_ALL_EVENT_OF_MODEL]?.statusCode === 200
        ) {
            const __activeAlarms = reduxState?.[STORE_KEYS.ADD_ALL_EVENT_OF_MODEL]?.body?.data;
            setTableData(__activeAlarms);
            setInitialTableData(__activeAlarms);
            dispatch(resetStoreKey({ storeKey: STORE_KEYS.ADD_ALL_EVENT_OF_MODEL }));
        }
        dispatch(
            updateScreenIdentifiers({
                storeKey: STORE_KEYS.ADD_ALL_EVENT_OF_MODEL,
                uniqueScreenIdentifier: {
                    eventModelTable: false
                }
            })
        );
    }, [reduxState?.[STORE_KEYS.ADD_ALL_EVENT_OF_MODEL]?.eventModelTable]);

    const getTimePeriodDropdownData = () => {
        dispatch(
            executeACGAction({
                payload: {
                    requestType: 'GET',
                    urlPath: ACTION_CODES.GET_TIMER_PERIOD_DROPDWON
                },
                uniqueScreenIdentifier: { timeperioddrpAdded: true },
                storeKey: STORE_KEYS.ADD_TIMEPERIOD_DROPDOWN
            })
        );
    };

    const getSeverityDropdownData = () => {
        dispatch(
            executeACGAction({
                payload: {
                    requestType: 'GET',
                    urlPath: ACTION_CODES.GET_SEVERITY_DROPDOWN
                },
                uniqueScreenIdentifier: { severityDropdown: true },
                storeKey: STORE_KEYS.ADD_SEVERITY_DROPDOWN
            })
        );
    };

    useEffect(() => {
        if (
            reduxState?.[STORE_KEYS.ADD_TIMEPERIOD_DROPDOWN]?.body?.data &&
            reduxState?.[STORE_KEYS.ADD_TIMEPERIOD_DROPDOWN]?.statusCode === 200
        ) {
            const __activeAlarms = reduxState?.[STORE_KEYS.ADD_TIMEPERIOD_DROPDOWN]?.body?.data;
            setTimePeriodDropdown(
                __activeAlarms?.map((item: any) => {
                    const newVal = renameObjKeys(item, { TimePeriodKey: 'code', TimePeriodName: 'name' });
                    return newVal;
                })
            );
            dispatch(resetStoreKey({ storeKey: STORE_KEYS.ADD_TIMEPERIOD_DROPDOWN }));
            dispatch(
                updateScreenIdentifiers({
                    storeKey: STORE_KEYS.ADD_TIMEPERIOD_DROPDOWN,
                    uniqueScreenIdentifier: {
                        timeperioddrpAdded: false
                    }
                })
            );
        }
    }, [reduxState?.[STORE_KEYS.ADD_TIMEPERIOD_DROPDOWN]?.timeperioddrpAdded]);

    useEffect(() => {
        if (
            reduxState?.[STORE_KEYS.ADD_SEVERITY_DROPDOWN]?.body?.data &&
            reduxState?.[STORE_KEYS.ADD_SEVERITY_DROPDOWN]?.statusCode === 200
        ) {
            const __activeAlarms = reduxState?.[STORE_KEYS.ADD_SEVERITY_DROPDOWN]?.body?.data;
            setseverityDropdown(
                __activeAlarms?.map((item: any) => {
                    return { code: item.Severity, name: item.Severity };
                })
            );
            dispatch(resetStoreKey({ storeKey: STORE_KEYS.ADD_SEVERITY_DROPDOWN }));
            dispatch(
                updateScreenIdentifiers({
                    storeKey: STORE_KEYS.ADD_SEVERITY_DROPDOWN,
                    uniqueScreenIdentifier: {
                        severityDropdown: false
                    }
                })
            );
        }
    }, [reduxState?.[STORE_KEYS.ADD_SEVERITY_DROPDOWN]?.severityDropdown]);

    const filteredList = applySortFilter(tableData, order, filterName, orderBy);

    const handleRequestSort = (event: any, property: any) => {
        setFilterName('');
        console.log(event, property);
        const isAsc = orderBy === event && order === 'asc';
        setOrder(isAsc ? 'desc' : 'asc');
        setOrderBy(event);
    };

    const handleSelectAllClick = (event: any) => {
        if (event.target.checked) {
            const newSelecteds = [];
            for (let i = 0; i < tableData.length; i++) {
                newSelecteds.push(tableData[i].id);
            }
            const toRemove = tableData.slice(page, tableData.length);
            setSelected(newSelecteds);
            return;
        }
        setSelected([]);
    };

    const handleClick = (requestedBy: string) => {
        const selectedIndex = selected.indexOf(requestedBy);
        let newSelected: any[] | ((prevState: unknown[]) => unknown[]) = [];
        if (selectedIndex === -1) {
            newSelected = newSelected.concat(selected, requestedBy);
        } else if (selectedIndex === 0) {
            newSelected = newSelected.concat(selected.slice(1));
        } else if (selectedIndex === selected.length - 1) {
            newSelected = newSelected.concat(selected.slice(0, -1));
        } else if (selectedIndex > 0) {
            newSelected = newSelected.concat(selected.slice(0, selectedIndex), selected.slice(selectedIndex + 1));
        }
        setSelected(newSelected);
    };

    const handleFilterByName = (event: { target: { value: React.SetStateAction<string> } }) => {
        setOrderBy('ParamName');
        setFilterName(event.target.value);
    };

    const resetInput = () => {
        setPage(0);
        setFilterName('');
    };

    const handleChangePage = (event: any, newPage: React.SetStateAction<number>) => {
        setPage(newPage);
    };

    const showRow = (event: any) => { };

    const handleRoute = (row: any, ele: any) => {
        if (ele === 'Action') {
            const __data = { ...formatAlarmDataObj(row) };
            setSelectedRow(__data);
            setCreateDrawerOpen(true);
        }
    };

    const closeDrawer = () => {
        setSelectedRow([]);
        setCreateDrawerOpen(false);
    };

    const clearFilter = () => {
        setPage(0);
        setTableData(initialTableData);
        setSeverityKey(null);
        setFilterOpen(false);
    };
    const applyFilter = () => {
        setPage(0);
        setFilterName('');
        setOrderBy('ParamName');
        setOrder('desc');
        if (selectedSeverityKey !== 'All Severity') {
            const newData = [...initialTableData];
            const x = newData.filter((ele: any) => ele?.Severity == selectedSeverityKey);
            setTableData(x);
        } else {
            setTableData(initialTableData);
        }
        setFilterOpen(false);
    };
    const history = useHistory();

    return (
        <div className="dashboard">
            <Container maxWidth="xl" style={{ marginTop: '50px' }}>
                <div style={{ display: 'flex' }}>
                    <img
                        style={{ cursor: 'pointer', height: '16px', alignSelf: "center" }}
                        src={BackArrowIcon}
                        alt="Arrow"
                        onClick={() => history.push(paths.MACHINE_MONITOR.path)}
                    />
                    <div className="headinglabel">Events</div>
                </div>
                <br />

                <MachineManagementTopCard data={eventSummary} type="events" />
                <br />
                <Container
                    maxWidth="xl"
                    style={{
                        paddingLeft: '3px',
                        paddingRight: '3px'
                    }}
                >
                    <Card
                        style={{
                            borderRadius: '10px 10px 0px 0px',
                            backgroundColor: '#2b2d42',
                            overflow: 'inherit'
                        }}
                    >
                        <CustomTableToolbar
                            drawerOpen={() => setFilterOpen(true)}
                            numSelected={selected?.length}
                            filterName={filterName}
                            onFilterName={handleFilterByName}
                            displayInput={true}
                            resetInput={resetInput}
                            text={{
                                title: 'Events List',
                                subTitle: `${tableData?.length}` + ' Events'
                            }}
                            filterButton={true}
                            customButton={false}
                            dropdown={true}
                            dropdownItems={timePeriodDropdown}
                            dropdownValue={timePeriodKey}
                            onDropdownChange={(timekey: any) => {
                                setPage(0);
                                setTimePeriodKey(timekey);
                            }}
                        />
                    </Card>
                </Container>
                <CustomTable
                    selectable={false}
                    headers={TABLE_HEAD}
                    body={filteredList}
                    filteredUsers={filteredList}
                    filterName={filterName}
                    selected={selected}
                    order={order}
                    orderBy={orderBy}
                    onRequestSort={handleRequestSort}
                    onSelectAllClick={handleSelectAllClick}
                    rowChange={(event: string) => handleClick(event)}
                    rowCount={filteredList?.length}
                    onPageChange={handleChangePage}
                    page={page}
                    setPageNumber={setPage}
                    selectRow={showRow}
                    route={handleRoute}
                    isRequestPage={true}
                    link={true}
                    alarmFlag={true}
                />
                {/* <MachineMonitoringDrawer
                handlerOpen={() => {}}
                handlerClose={closeDrawer}
                status={createDrawerOpen}
                data={selectedRow}
                block={true}
                type={'machines'}
            /> */}
                <AlarmDetailDrawer
                    handlerOpen={() => { }}
                    handlerClose={closeDrawer}
                    status={createDrawerOpen}
                    data={selectedRow}
                    block={true}
                    type="default"
                    usedIn="event-details"
                />
            </Container>
            <FilterDrawer
                handlerClose={() => {
                    setFilterOpen(false);
                }}
                status={filterOpen}
                drawerHeader={'Filter'}
                children={
                    <Container className="createReport">
                        <Box style={{ minHeight: '75vh' }}>
                            {/* <Label label="Severity" className={'labelDiv'} /> */}
                            <DropDown
                                onChange={(event: any) => {
                                    setSeverityKey(event.target.value);
                                }}
                                label="Severity"
                                placeHolder="Select Severity"
                                name="dropdownSeverity"
                                className="inputDiv"
                                items={severityDropdown}
                                value={selectedSeverityKey}
                            />
                        </Box>
                    </Container>
                }
                clearFn={clearFilter}
                applyFn={applyFilter}
            />
        </div>
    );
};

export default EventDetail;
