import { scheduler } from "timers/promises";
import * as Yup from "yup";

export const VALIDATION_YUPSCHEMA = {
  dateFromSelect: Yup.date().required("Please select from date")
    .max(new Date(), 'To date cannot be in the future')
    .min(new Date(Date.now() - 6 * 30 * 24 * 60 * 60 * 1000), // Last 6 Monthts
      'From date cannot be more than 6 months in the past'),
  dateToSelect: Yup.date().required("Please select to date")
    .max(new Date(), 'To date cannot be in the future')
    .when("dateFromSelect",
      (dateFromSelect, yup) => dateFromSelect && yup.min(dateFromSelect, "To date cannot be before start date"))
    .test(
      'date-range',
      'Date range should not be more than one month',
      function (value) {
        const fromDate = this.resolve(Yup.ref('dateFromSelect')) as Date;
        const toDate = value as Date;
        const diffInMs = toDate.getTime() - fromDate.getTime();
        const diffInDays = diffInMs / (1000 * 60 * 60 * 24);
        return diffInDays <= 31; //31 days range between 6 months
      }),
  dropdownPlant: Yup.string().required("Please select plant"),
  dropdownModel: Yup.string().required("Please select model"),
  dropdownSerialNo: Yup.string().required("Please select serial no"),
  // dropdownCategory: Yup.string().required("Please select category"),
  // dropdownBatch: Yup.string().required('Please select batch'),
}

const isSerialNumberValid = (dropdownSerialNo: any) => {
  return true
};