import React, { useState, useEffect } from 'react';
import { Chart as ChartJS, ChartData, ChartDataset } from 'chart.js';
import { Bar } from 'react-chartjs-2';
import 'chartjs-plugin-datalabels';
import ChartDataLabels from 'chartjs-plugin-datalabels';

// Ensure necessary chart.js modules are registered
ChartJS.register();

const BarChart = (props: any) => {
  const [labelSet, setLabelSet] = useState<any>([]);
  const [opdata1, setOPData1] = useState<any>([]);
  const [opdata2, setOPData2] = useState<any>([]);
  const [opdata3, setOPData3] = useState<any>([]);
  const [opdata4, setOPData4] = useState<any>([]);

  useEffect(() => {
    const lab: any = [], d1: any = [], d2: any = [], d3: any = [], d4: any = [];
    props.apq.map((i: any) => {
      lab.push(i.BatchNo);
      d1.push(i.Availability);
      d2.push(i.Quality);
      d3.push(i.Performance);
      d4.push(i.OEE);
    });
    setLabelSet(lab);
    setOPData1(d1);
    setOPData2(d2);
    setOPData3(d3);
    setOPData4(d4);
  }, [props]);

  // Specify the type as ChartData<'bar'>
  const data: ChartData<'bar'> = {
    labels: labelSet,
    datasets: [
      {
        label: 'OEE %',
        backgroundColor: '#00ffff',
        borderColor: '#00ffff',
        borderWidth: 2,
        hoverBackgroundColor: '#D0AE8B',
        hoverBorderColor: '#D0AE8B',
        data: opdata4,
        type: 'line', // Mixed chart type: line
        order: 1, // Optional: Specify order in the mixed chart
      },
      {
        label: 'Availability %',
        backgroundColor: '#ffc107',
        borderColor: '#ffc107',
        borderWidth: 0,
        hoverBackgroundColor: '#ffc107',
        hoverBorderColor: '#ffc107',
        data: opdata1,
        order: 2, // Optional: Specify order in the mixed chart
      },
      {
        label: 'Performance %',
        backgroundColor: '#0d6efd',
        borderColor: '#0d6efd',
        borderWidth: 0,
        hoverBackgroundColor: '#0d6efd',
        hoverBorderColor: '#0d6efd',
        data: opdata3,
        order: 3, // Optional: Specify order in the mixed chart
      },
      {
        label: 'Quality %',
        backgroundColor: '#198754',
        borderColor: '#198754',
        borderWidth: 0,
        hoverBackgroundColor: '#198754',
        hoverBorderColor: '#198754',
        data: opdata2,
        order: 4, // Optional: Specify order in the mixed chart
      },
    ] as ChartDataset<'bar'>[],
  };

  const options = {
    maintainAspectRatio: false,
    responsive: true,
    plugins: {
      datalabels: { color: 'white', display: false },
      legend: {
        display: false, // Disable the default legend
      },
      tooltip: {
        callbacks: {
          label: function (tooltipItem: any) {
            return tooltipItem.dataset.label + ': ' + tooltipItem.raw.toFixed(2);
          }
        }
      }
    },
    scales: {
      x: {
        grid: {
          color: 'transparent',
        },
        ticks: {
          color: 'white',
        },
      },
      y: {
        title: {
          display: true,
          text: 'A P Q  ( % )',
          color: 'white',
        },
        grid: {
          color: '#878f99',
        },
        ticks: {
          beginAtZero: true,
          color: 'white',
        },
      },
    },
  };

  const renderCustomLegend = () => {
    return (
      <ul style={{ display: 'flex', justifyContent: 'center', listStyleType: 'none', padding: 0 }} className='legendcls'>
        {data.datasets.map((dataset, index) => (
          <li key={index} style={{ display: 'flex', alignItems: 'center', marginRight: '20px'}}>
            <span style={{
              backgroundColor: dataset.backgroundColor as string,
              width: '35px',
              height: '10px',
              display: 'inline-block',
              marginRight: '5px'
            }}></span>
            <span style={{ color: '#e0e0e0', fontFamily: 'montserrat', fontSize: '10px' }}>{dataset.label}</span>
          </li>
        ))}
      </ul>
    );
  };

  return (
    <div className='barchartPMEC noheight'>
      {renderCustomLegend()}
      <Bar data={data} options={options} plugins={[ChartDataLabels]} />
    </div>
  );
};

export default BarChart;
