import React from 'react';
import Avatar from '@mui/material/Avatar';

const EmailAvatar = ({ email }:any) => {
  const initial = email ? email.charAt(0).toUpperCase() : '?';
  return (
    <Avatar>
      {initial}
    </Avatar>
  );
};

export default EmailAvatar;
