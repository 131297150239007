import { Container, Grid } from '@material-ui/core';
import { useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';
import { executeACGAction } from '../../../store/slice';
import useGetState from '../../../utils/hooks/useGetState';
import CustomTable from '../../../atoms/CustomTable/index';
import RightDrawer from '../../../atoms/tempDrawer/indexAlt';
import AddParams from '../../AddParameters';
import { ACTION_CODES, STORE_KEYS } from '../../../constants/apiConstants';
import SnackbarAlert from '../../../atoms/Snackbar';
import GroupParameterList from './GroupParameterList';
import ACGButton from '../../../atoms/Button';
interface Props {
    closeDrawer: Function;
    MachineModelKey: number;
}
const options = {
    DEFAULT: {
        message: '',
        open: false,
        type: ''
    }
};
const TABLE_HEAD = [
    { id: 'MachineModelCode', label: 'Machine Model Code', alignRight: false },
    { id: 'GroupCode', label: 'Group Code', alignRight: false },
    { id: 'GroupName', label: 'Group Name', alignRight: false },
    { id: 'Action', label: 'Action', alignLeft: true }
];

const GroupParameter = (props: Props) => {
    const { closeDrawer, MachineModelKey } = props;
    const dispatch = useDispatch();
    const stateObj = useGetState();
    const [selectedRow, setSelectedRow] = useState<any>([]);
    const [page, setPage] = useState<number>(0);
    const [order, setOrder] = useState('desc');
    const [orderBy, setOrderBy] = useState('GroupCode');
    const [selected, setSelected] = useState<any>([]);
    const [filterName, setFilterName] = useState<string>('');
    const [createDrawerOpen, setCreateDrawerOpen] = useState(false);
    const [editDrawerOpen, setEditDrawerOpen] = useState(false);
    const [viewDrawerOpen, setViewCreateDrawerOpen] = useState(false);
    const [snackbarOptions, setSnackbarOptions] = useState(options.DEFAULT);
    const [reload, setReload] = useState(false);

    const getParamGroupDetails = () => {
        if(!MachineModelKey){
            return
        }
        dispatch(
            executeACGAction({
                payload: {
                    requestType: 'POST',
                    urlPath: ACTION_CODES.MACHINES_GET_PARAMGROUP_DETAILS,
                    reqObj: {
                        machineModelKey: MachineModelKey
                    }
                },
                uniqueScreenIdentifier: { getParamGroupDetailsLoaded: true },
                storeKey: STORE_KEYS.MACHINES_GET_PARAMGROUP_DETAILS_DATA
            })
        );
    };

    const handleRequestSort = (event: any, property: any) => {
        setFilterName('');
        const isAsc = orderBy === event && order === 'asc';
        setOrder(isAsc ? 'desc' : 'asc');
        setOrderBy(event);
    };
    const handleChangePage = (event: any, newPage: React.SetStateAction<number>) => {
        setPage(newPage);
    };
    const handleSelectAllClick = (event: any) => { };
    const showRow = (row: any) => {
        const rowId = row['MMParamKey'];
        const isSelected = selected.length && selected.indexOf(rowId) !== -1;
        if (isSelected) {
            setSelected([...selected.filter((item: number) => item !== rowId)]);
        } else {
            setSelected([...selected, rowId]);
        }
    };

    const handleRoute = (row: any, ele: any) => {
        if (ele === 'Action') {
            setSelectedRow(row);
            setViewCreateDrawerOpen(true);
        }
    };

    const handleClick = (requestedBy: string) => {
        const selectedIndex = selected.indexOf(requestedBy);
        let newSelected: any[] | ((prevState: unknown[]) => unknown[]) = [];
        if (selectedIndex === -1) {
            newSelected = newSelected.concat(selected, requestedBy);
        } else if (selectedIndex === 0) {
            newSelected = newSelected.concat(selected.slice(1));
        } else if (selectedIndex === selected.length - 1) {
            newSelected = newSelected.concat(selected.slice(0, -1));
        } else if (selectedIndex > 0) {
            newSelected = newSelected.concat(selected.slice(0, selectedIndex), selected.slice(selectedIndex + 1));
        }
        setSelected(newSelected);
    };

    useEffect(() => {
        getParamGroupDetails();
    }, []);
    useEffect(() => {
        getParamGroupDetails();
    }, [reload]);

    const filteredList = stateObj?.[STORE_KEYS.MACHINES_GET_PARAMGROUP_DETAILS_DATA]?.body?.data ?? [];

    const closeSnackbar = () => setSnackbarOptions(options.DEFAULT);

    const setEditParamOpen = () => {
        // console.log("click");
        setEditDrawerOpen(true)
    }

    return (
        <>
            <SnackbarAlert options={snackbarOptions} handleClose={closeSnackbar} />
            <Container
                maxWidth="xl"
                style={{
                    padding: '24px'
                }}
            >
                <Grid container spacing={2}>
                    <Grid item xs={6} md={10}></Grid>
                    <Grid item xs={6} md={2}>
                        <ACGButton
                            // disabled={stateObj?.isLoading}
                            name={'Add New Group'}
                            handleClick={() => setCreateDrawerOpen(true)}
                            secondary={true}
                            className={'first-btn'}
                            type="button"
                            variant="contained"
                            style={{
                                marginRight: '25px',
                                padding: '5px',
                                width: '150px',
                                height: '35px',
                                marginBottom: '20px'
                            }}
                        />
                    </Grid>
                </Grid>

                <CustomTable
                    selectable={false}
                    headers={TABLE_HEAD}
                    body={filteredList}
                    filteredUsers={filteredList}
                    filterName={filterName}
                    selected={selected}
                    order={order}
                    orderBy={orderBy}
                    onRequestSort={handleRequestSort}
                    onSelectAllClick={handleSelectAllClick}
                    rowChange={(event: string) => handleClick(event)}
                    rowCount={filteredList.length}
                    onPageChange={handleChangePage}
                    page={page}
                    setPageNumber={setPage}
                    selectRow={showRow}
                    route={handleRoute}
                    isRequestPage={true}
                    link={true}
                    idKey="MMParamKey"
                />
                <RightDrawer
                    handlerOpen={() => { }}
                    handlerClose={() => {
                        setCreateDrawerOpen(false);
                    }}
                    status={createDrawerOpen}
                    drawerHeader={`Add New Group`}
                    children={
                        <AddParams
                            closeDrawer={() => setCreateDrawerOpen(false)}
                            MachineModelKey={MachineModelKey}
                            setSnackbarOptions={setSnackbarOptions}
                            setReload={setReload}
                        />
                    }
                    type="model"
                />
                <RightDrawer
                    handlerOpen={() => { }}
                    handlerClose={() => {
                        setViewCreateDrawerOpen(false);
                    }}
                    status={viewDrawerOpen}
                    drawerHeader={`Group Parameters`}
                    children={
                        <GroupParameterList
                            data={selectedRow}
                            closeDrawer={() => setViewCreateDrawerOpen(false)}
                            MachineModelKey={MachineModelKey}
                        />
                    }
                    type={"model"}
                    groupFlag={true}
                    editFn={() => { setEditParamOpen() }}
                // deleteFn={() => removeFn(dtlProps?.dtlData)}
                />

                <RightDrawer
                    handlerOpen={() => { }}
                    handlerClose={() => {
                        setEditDrawerOpen(false);
                    }}
                    status={editDrawerOpen}
                    drawerHeader={`Edit Group`}
                    children={
                        <AddParams
                            data={selectedRow}
                            closeDrawer={() => setEditDrawerOpen(false)}
                            MachineModelKey={MachineModelKey}
                            setSnackbarOptions={setSnackbarOptions}
                            isEdit={true}
                            setReload={setReload}
                        />
                    }
                    type="model"
                />
            </Container>
        </>
    );
};

export default GroupParameter;
