import * as React from 'react';
import OutlinedInput from '@mui/material/OutlinedInput';
import MenuItem from '@mui/material/MenuItem';
import FormControl from '@mui/material/FormControl';
import ListItemText from '@mui/material/ListItemText';
import Select from '@mui/material/Select';
import Checkbox from '@mui/material/Checkbox';
import DownArrow from '../../../assets/down-arrow.svg';
import { makeStyles } from '@material-ui/core/styles';
import { SelectProps } from '@mui/material';

const ITEM_HEIGHT = 48;
const ITEM_PADDING_TOP = 8;
const MenuProps = {
    PaperProps: {
        // style: {
        //     maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
        //     width: 250
        // }
    }
};

type ItemsType = {
    label: string;
    type?: string;
    value: string;
    disabled?: boolean;
};

interface MultipleSelectCheckmarks {
    onChange?: Function;
    value: any;
    setValue?: any;
    options?: ItemsType[] | undefined;
    names?: any;
    label?: any;
    disabled?: boolean;
    style?: any;
    className?: string;
    type?: string;
    required?: boolean;
    helperText?: any;
    variant?: SelectProps['variant'];
    control?: Function;
    isIcon?: boolean;
    fullWidth?: boolean;
    placeHolder?: string; // Placeholder prop
    removeShowAll?: boolean;
    maxSelect?: number; // Max selection limit
}

const DropDownIcon = (prop: any) => <img style={{ marginRight: '5px', marginTop: '-2px' }} src={DownArrow} {...prop} />;

const useStyles = makeStyles(() => ({
    formControl: {
        margin: 0,
        fullWidth: true,
        display: 'flex',
        wrap: 'nowrap',
        '& .MuiOutlinedInput-root .MuiOutlinedInput-notchedOutline': {
            border: 'none'
        },
        '& .MuiSelect-icon': {
            color: '#949fb1'
        },

        '& .MuiInputLabel-formControl': {
            transform: 'translate(12px, 20px) scale(1)'
        },
        '& .MuiOutlinedInput-root': {
            height: '40px',
            backgroundColor: '#1D1E2C',
            borderRadius: '10px',
            borderWidth: '1px',
            borderColor: '#2B2D42',
            alignContent: 'center',
            justifyContent: 'center',
            width: '100%',
            '&.Mui-focused fieldset': {
                borderWidth: '1px',
                borderColor: '#2B2D42'
            }
        },
        '& .MuiFormHelperText-root': {
            color: '#F06868',
            fontSize: '11px',
            fontWeight: 600,
            letterSpacing: 0,
            marginBottom: '-20px',
            fontFamily: 'Montserrat'
        }
    },
    paper: {
        width: '100%',
        minWidth: '185px'
    },

    dropdownStyle: {
        color: '#949fb1',
        fontSize: '12px',
        letterSpacing: '0',
        fontWeight: 500,
        lineHeight: '1',
        marginTop: '9px',
        background: '#1D1E2C'
    },
    //#949fb1
    disabledComponent: {
        opacity: 0.6
    },
    label: {
        height: '1',
        opacity: 0.5,
        color: '#2C333A',
        fontFamily: 'Montserrat',
        fontSize: '12px',
        fontWeight: 500,
        letterSpacing: 0,
        marginBottom: '12px'
    },
    optionStyle: {
        color: '#949fb1',
        fontSize: '12px',
        letterSpacing: '0',
        fontWeight: 500,
        lineHeight: '2',
        padding: '3px 8px'
    },

    iconStart: {
        position: 'absolute',
        marginTop: '15px',
        marginLeft: '10px',
        zIndex: 9
    }
}));

export default function MultipleSelectCheckmarks(props: MultipleSelectCheckmarks) {
    const {
        value,
        setValue,
        options,
        helperText,
        names,
        disabled,
        required,
        isIcon,
        onChange,
        label,
        variant,
        fullWidth,
        placeHolder, // Receive the placeholder prop
        removeShowAll,
        maxSelect, // Receive the maxSelect prop
        ...rest
    } = props;

    const [selectAllShift, setSelectAllShift] = React.useState<boolean>(false);
    const classes = useStyles();

    const handleChange = (event: any) => {
        // If maxSelect is set and the user is trying to select more than the limit, show alert
        if (maxSelect && value.length >= maxSelect && !value.some((e: any) => e.value === event.value)) {
            alert(`You can only select up to ${maxSelect} items.`);
            return; // Prevent adding more items if the limit is reached
        }

        const find = value.some((e: any) => e.value === event.value);
        if (!find) {
            setValue([...value, event]);
            if ([...value, event].length === options?.length) {
                setSelectAllShift(true);
            }
        } else {
            const filter = value.filter((e: any) => e.value !== event.value);
            setValue(filter);
            setSelectAllShift(false);
        }
    };

    React.useEffect(() => {
        options && options?.length > 0 && onChange && onChange(value.map((e: any) => e.value).join(','));
    }, [value]);

    const handleSelectAll = () => {
        const sb: any = [];
        if (!selectAllShift && options) {
            options.map((i: any) => sb.push(i));
            setValue(sb);
            setSelectAllShift(true);
        } else {
            setValue([]);
            setSelectAllShift(false);
        }
    };

    return (
        <FormControl fullWidth className={`${classes.formControl}`} required={required} variant={variant}>
            <Select
                IconComponent={DropDownIcon}
                labelId="demo-multiple-checkbox-label"
                id="demo-multiple-checkbox"
                className="muti_boxcheck"
                label=""
                multiple
                fullWidth={fullWidth}
                value={value}
                sx={{
                    marginBottom: '10px',
                    backgroundColor: '#1d1e2c',
                    borderRadius: 3,
                    marginTop: '10px',
                    color: '#b1c2df',
                    maxWidth: '100%',
                }}
                renderValue={(selected) => {
                    if (selected.length === 0) {
                        return placeHolder || 'Please select an option';  // Show placeholder when no items are selected
                    }
                    return selected.map((x: any) => x.label).join(', ');
                }}
                displayEmpty // Ensures that the placeholder is visible even when nothing is selected
            >
                {!removeShowAll && options && options?.length > 0 && (
                    <MenuItem value={'all'} onClick={() => handleSelectAll()}>
                        <Checkbox checked={selectAllShift} />
                        <ListItemText primary="Select All" />
                    </MenuItem>
                )}
                {options?.map((i: any) => (
                    <MenuItem
                        value={i}
                        onClick={() => handleChange(i)}
                        className="ulSelectBatchRecipe"
                    >
                        <Checkbox checked={value.findIndex((item: any) => item.value === i.value) >= 0} />
                        <ListItemText primary={i.label} />
                    </MenuItem>
                ))}
            </Select>
        </FormControl>
    );
}
