import React, { useState, useEffect } from 'react';
import { useDispatch } from 'react-redux';
import { useLocation } from 'react-router-dom';
import CustomTable from '../../../../atoms/CustomTable/index';
import CustomTableToolbar from '../../../../atoms/CustomTableToolbar';
import { Container, Card } from '@mui/material';
import TemporaryDrawer from '../../../../atoms/tempDrawer';
import { ACTION_CODES, STORE_KEYS } from '../../../../constants/apiConstants';
import { executeACGAction, resetErr, resetStoreKey, updateScreenIdentifiers } from '../../../../store/slice';
import useGetState from '../../../../utils/hooks/useGetState';
import { applySortFilter } from '../../../../utils/helper';
import Axios from 'axios';
import SnackbarAlert from '../../../../atoms/Snackbar';
import AlertBox from '../../../../atoms/AlertBox';
import DetailDrawer from '../../../../atoms/DetailDrawer';
import { formatAccordionData } from '../../../../utils/formatData';
import ContactDetailDrawer from '../../../../atoms/ContactDetailDrawer';
import { LocalConvenienceStoreOutlined } from '@material-ui/icons';

const TABLE_HEAD = [
    { id: 'PreventMaintID', label: 'Preventive Maintenance Id', alignRight: false },
    { id: 'WorkOrderID', label: 'Work Order Id', alignRight: false },
    { id: 'Machine', label: 'Machine', alignRight: false },
    { id: 'MaintComponentName', label: 'Component Name', alignRight: false },
    { id: 'MaintDesc', label: 'Maintenance Description', alignRight: false },
    { id: 'PlannedDate', label: 'Planned Date', alignRight: false },
    { id: 'AssignedTo', label: 'Assigned To', alignRight: false },
    { id: 'CurrentStatus', label: 'Current Status', alignRight: false },
    { id: 'Action', label: 'Action', alignLeft: true }
];

const options = {
    DEFAULT: {
        message: '',
        open: false,
        type: ''
    },
    RM_SUCCESS: {
        message: 'Preventive Maintenance Work Order Deleted',
        open: true,
        type: 'success'
    },
    USR_ADD_SUCCESS: {
        message: 'Breakdown Maintenance Added successfully!',
        open: true,
        type: 'success'
    }
};

type Anchor = 'right';

const Users = (props: any) => {
    const {
        preventiveMaintenanceWorkOrderList,
        getPreventiveMaintenanceWorkOrderList,
        radioValue,
        selectedClient,
        setDtlData,
        dtlData,
        setReload,
        reload,
        // selectedRow,
        // setSelectedRow,
        setCreateValue,
        setDrawerHeader,
        setCreateDrawerOpen: setEditDrawerOpen,
        fetchPreventiveMaintenanceWorkOrderDetails,
        setAssignmentData,
        setPreventiveMaintenanceKey,
        getWorkOrderUsers,
        setPreventiveMaintenanceWorkOrderKey,
        alertType,
        isLoading
    } = props;

    const dispatch = useDispatch();
    const [drawerState, setDrawerState] = useState({
        right: false
    });
    const [page, setPage] = useState<number>(0);
    const [order, setOrder] = useState('desc');
    const [orderBy, setOrderBy] = useState('WorkOrderID');
    const [selected, setSelected] = useState<any>([]);
    const [selectedRow, setSelectedRow] = useState<any>({});
    const [filterName, setFilterName] = useState<string>('');
    const [tableData, setTableData] = useState<any[]>([]);
    const [createDrawerOpen, setCreateDrawerOpen] = useState(false);

    const state = useGetState();
    const currentPath = useLocation();
    const accessData = state?.[STORE_KEYS.ROUTES_PRIVILEGES];
    const isAccess = (__data: any) => {
        return __data.filter((d: any) => d.path == currentPath?.pathname)?.[0]?.isEditable;
    };
    useEffect(() => {
        if (reload) {
            setReload(false);
            setTableData([]);
        }
    }, [reload]);

    const [snackbarOptions, setSnackbarOptions] = useState(options.DEFAULT);
    const [removeBxOpen, setRemoveBxOpen] = useState(false);
    const [rmUser, setRmUser] = useState(false);
    const [RNVKey, setRNVKey] = useState<any>();
    const closeRmBx = () => {
        setRemoveBxOpen(false);
    };
    const removeFn = (row: any, ele: any) => {
        setSelectedRow({ ...row });
        setRNVKey(ele?.RNVKey);
        setRemoveBxOpen(true);
    };
    useEffect(() => {
        if (rmUser) {
            closeRmBx();
            dispatch(
                executeACGAction({
                    payload: {
                        requestType: 'POST',
                        urlPath: ACTION_CODES.DELETE_MAINTENANCE_MODULE,
                        reqObj: {
                            userId: state[STORE_KEYS.USER_DTL]?.LoggedUserKey,
                            type: "MaintPREVWorkOrders",
                            moduleKey: selectedRow?.MaintPREVWorkOrderKey

                        }
                    },
                    uniqueScreenIdentifier: {
                        rowDeleted: true
                    },
                    storeKey: STORE_KEYS.DELETE_MAINTENANCE_MODULE
                })
            );
            setRmUser(false);
        }
    }, [rmUser]);

    useEffect(() => {
        if (state[STORE_KEYS.DELETE_MAINTENANCE_MODULE]?.rowDeleted) {
            if (state?.[STORE_KEYS.DELETE_MAINTENANCE_MODULE]?.status === "Success") {
                setSnackbarOptions(options.RM_SUCCESS);
                setCreateDrawerOpen(false);
                getPreventiveMaintenanceWorkOrderList();
            }
            dispatch(
                updateScreenIdentifiers({
                    storeKey: STORE_KEYS.DELETE_MAINTENANCE_MODULE,
                    uniqueScreenIdentifier: {
                        rowDeleted: false
                    }
                })
            );
        }
    }, [state[STORE_KEYS.DELETE_MAINTENANCE_MODULE]?.rowDeleted]);

    const handleRequestSort = (event: any, property: any) => {
        const isAsc = orderBy === event && order === 'asc';
        setOrder(isAsc ? 'desc' : 'asc');
        setOrderBy(event);
    };

    const handleSelectAllClick = (event: any) => {
        if (event.target.checked) {
            const newSelecteds = [];
            for (let i = 0; i < preventiveMaintenanceWorkOrderList.length; i++) {
                newSelecteds.push(preventiveMaintenanceWorkOrderList[i].id);
            }
            const toRemove = preventiveMaintenanceWorkOrderList.slice(page, preventiveMaintenanceWorkOrderList.length);
            setSelected(newSelecteds);
            return;
        }
        setSelected([]);
    };

    const handleClick = (requestedBy: string) => {
        const selectedIndex = selected.indexOf(requestedBy);
        let newSelected: any[] | ((prevState: unknown[]) => unknown[]) = [];
        if (selectedIndex === -1) {
            newSelected = newSelected.concat(selected, requestedBy);
        } else if (selectedIndex === 0) {
            newSelected = newSelected.concat(selected.slice(1));
        } else if (selectedIndex === selected.length - 1) {
            newSelected = newSelected.concat(selected.slice(0, -1));
        } else if (selectedIndex > 0) {
            newSelected = newSelected.concat(selected.slice(0, selectedIndex), selected.slice(selectedIndex + 1));
        }
        setSelected(newSelected);
    };

    const toggleDrawer = (anchor: Anchor, open: boolean) => (event: React.KeyboardEvent | React.MouseEvent) => {
        if (
            event.type === 'keydown' &&
            ((event as React.KeyboardEvent).key === 'Tab' || (event as React.KeyboardEvent).key === 'Shift')
        ) {
            return;
        }
        setDrawerState({ ...drawerState, [anchor]: open });
    };

    const filteredList = applySortFilter(preventiveMaintenanceWorkOrderList, order, filterName, orderBy);

    const handleFilterByName = (event: { target: { value: string } }) => {
        setPage(0);
        setFilterName(event.target.value);
        setOrderBy('WorkOrderID');
    };

    const handleChangePage = (event: any, newPage: React.SetStateAction<number>) => {
        setPage(newPage);
    };

    const showRow = (event: any) => { };

    const handleSnackbarError = (err: any) => {
        const errorMsg = err?.message || 'Internal Server error';
        const snackbarError = {
            message: errorMsg,
            type: 'reject',
            open: true
        };
        setSnackbarOptions(snackbarError);
    };

    useEffect(() => {
        if (state?.err) {
            handleSnackbarError(state?.err);
            dispatch(resetErr());
        }
    }, [state?.err]);

    const handleRoute = (row: any, ele: any) => {
        if (ele === 'Action') {
            setSelectedRow({ ...row });
            fetchPreventiveMaintenanceWorkOrderDetails(row.MaintPREVWorkOrderKey);
            setCreateDrawerOpen(true);
        }
    };

    useEffect(() => {
        const tmpTableData = state[STORE_KEYS.GET_PREVENTIVE_MAINTENANCE_WORK_ORDER_LIST_DETAILS]?.data;
        if (tmpTableData && tmpTableData.length) {
            setDtlData(tmpTableData);
        }
    }, [state[STORE_KEYS.GET_PREVENTIVE_MAINTENANCE_WORK_ORDER_LIST_DETAILS]?.data]);

    const closeDrawer = () => {
        setDtlData([]);
        setCreateDrawerOpen(false);
        setAssignmentData([]);
    };

    const closeSnackbar = () => setSnackbarOptions(options.DEFAULT);
    const removeMd = (ele: any) => {
        // setMdKey(ele?.ModuleKey);
        setRemoveBxOpen(true);
    };
    const resetInput = () => {
        setFilterName('');
    };

    const checklistData:any = [];
    dtlData.length > 0 && dtlData?.map((i:any)=>{
        checklistData.push({'TaskId': i.TaskId, 'TaskDesc': i.TaskDesc, 'InspectionDate': i.InspectionDate, 'InspRemark': i.InspRemark })
    })

    return (
        <div className='prevMWOrder'>
            <div>
                <SnackbarAlert options={snackbarOptions} handleClose={closeSnackbar} />
                <Container
                    maxWidth="xl"
                    style={{
                        paddingLeft: '3px',
                        paddingRight: '3px'
                    }}
                >
                    <Card
                        style={{
                            borderRadius: '0px',
                            backgroundColor: '#2b2d42',
                            overflow: 'inherit'
                        }}
                    >
                        <CustomTableToolbar
                            drawerOpen={toggleDrawer('right', true)}
                            numSelected={selected?.length}
                            filterName={filterName}
                            onFilterName={handleFilterByName}
                            resetInput={resetInput}
                            displayInput={true}
                            text={{
                                title: 'Preventive Maintenance Work Order List ',
                                subTitle: `${filteredList.length} Preventive Maintenance Work Order`
                            }}
                            filterButton={false}
                            customButton={false}
                            placeholder={"Search by PM Id, Work Order Id & Machine"}
                        />
                    </Card>
                </Container>
                <CustomTable
                    selectable={false}
                    headers={isAccess(accessData) ? TABLE_HEAD : TABLE_HEAD.slice(0, -1)}
                    body={filteredList}
                    isLoading={isLoading}
                    filteredUsers={filteredList}
                    filterName={filterName}
                    selected={selected}
                    isTableImageReqd={true}
                    order={order}
                    orderBy={orderBy}
                    onRequestSort={handleRequestSort}
                    onSelectAllClick={handleSelectAllClick}
                    rowChange={(event: string) => handleClick(event)}
                    rowCount={filteredList.length}
                    onPageChange={handleChangePage}
                    page={page}
                    setPageNumber={setPage}
                    selectRow={showRow}
                    route={handleRoute}
                    isRequestPage={true}
                    link={true}
                    removeFn={removeFn}
                    {...(radioValue === 'Customer' && !selectedClient
                        ? { noDataText: 'Selected client to display users' }
                        : {})}
                    workflow={true}
                    editFn={async (row: any, type: string) => {
                        fetchPreventiveMaintenanceWorkOrderDetails(row?.MaintPREVWorkOrderKey);
                        // await setAssignmentData(data);
                        setSelectedRow(row);
                        setEditDrawerOpen(true);
                        setCreateValue("Work Order");
                        setDrawerHeader(type);
                    }}
                    setAssign={async (key: number)=> {
                        setPreventiveMaintenanceWorkOrderKey(key);
                        getWorkOrderUsers(key);
                        setEditDrawerOpen(true);
                        setCreateValue("");
                        setDrawerHeader("Assign Preventive Maintenance")
                       
                    }}
                    updateInspectionAction = {async (key: number)=> {
                        setCreateValue("Custom Alerts")
                        await setPreventiveMaintenanceKey(key);
                        setEditDrawerOpen(true);
                        setDrawerHeader("Preventive Maintenance Update Checklist")
                       
                    }}
                />
            </div>
            <AlertBox
                isOpen={removeBxOpen}
                handleClose={closeRmBx}
                leftBtnPress={closeRmBx}
                rightBtnPress={() => setRmUser(true)}
                modalHeader="Delete Preventive Maintenance Work Order"
                modalContent="Are you sure you want to delete Preventive Maintenance Work Order?"
            />
            <TemporaryDrawer
                handlerOpen={toggleDrawer('right', true)}
                handlerClose={toggleDrawer('right', false)}
                status={state['right']}
            />
            <DetailDrawer
                alertType={"Custom Alerts"}
                actionRequired={false} 
                hasImage={true}
                workflow={true}
                handlerOpen={() => { }}
                handlerClose={closeDrawer}
                status={createDrawerOpen}
                data={{
                    name: selectedRow.WorkOrderID,
                    IsEnableEditSR: selectedRow.IsEnableEditSR,
                    details: [
                        { code: 'Maintenance Description', name: dtlData[0]?.MaintDesc },
                        { code: 'Maintenance Started Time', name: dtlData[0]?.MaintenanceStartDate ? dtlData[0]?.MaintenanceStartDate : "-" },
                        { code: 'Maintenance Completion Time', name: dtlData[0]?.MaintenanceCompletionDate ? dtlData[0]?.MaintenanceCompletionDate : "-" },
                        { code: 'Closure Remark', name: dtlData[0]?.ClosureRemrk ? dtlData[0]?.ClosureRemrk : "-" },
                        { code: 'imageName', name:  dtlData[0]?.ImagePath},
                        { code: 'checkListDetails', name: checklistData}
                    ]
                }}
                type={'MaintMod'}
            />

        </div>
    );
};

export default Users;
