import React, { useState, useEffect } from 'react';
import { Container, Card } from '@mui/material';
import CustomTableToolbar from '../../../atoms/CustomTableToolbar';
import CustomTable from '../../../atoms/CustomTable/index';
import { applySortFilter } from '../../../utils/helper';

const TABLE_HEAD = [{ id: 'CustomerName', label: 'Client', alignRight: false }];

const ViewBroadcast = (props: any) => {
    const { data } = props;
    const [page, setPage] = useState<number>(0);
    const [order, setOrder] = useState('desc');
    const [orderBy, setOrderBy] = useState('CustomerName');
    const [selected, setSelected] = useState<any>([]);
    const [filterName, setFilterName] = useState<string>('');
    const [tableData, setTableData] = useState<any>([]);

    const filteredList = applySortFilter(tableData, order, filterName, orderBy);

    const handleRequestSort = (event: any, property: any) => {
        setPage(0);
        setFilterName('');
        const isAsc = orderBy === event && order === 'asc';
        setOrder(isAsc ? 'desc' : 'asc');
        setOrderBy(event);
    };
    const handleChangePage = (event: any, newPage: React.SetStateAction<number>) => {
        setPage(newPage);
    };
    const handleFilterByName = (event: any) => {
        setOrderBy('CustomerName');
        setFilterName(event.target.value);
    };

    const resetInput = () => {
        setPage(0);
        setFilterName('');
    };

    useEffect(() => {
        setTableData(data?.customers);
    }, []);

    useEffect(() => {
        setTableData(data?.customers);
    }, [data?.customers]);

    return (
        <>
            <Container
                maxWidth="xl"
                style={{
                    paddingLeft: '3px',
                    paddingRight: '3px'
                }}
            >
                <Card
                    style={{
                        borderRadius: '0px',
                        backgroundColor: '#2b2d42',
                        overflow: 'inherit'
                    }}
                >
                    <CustomTableToolbar
                        drawerOpen={() => {}}
                        numSelected={selected?.length}
                        filterName={filterName}
                        onFilterName={handleFilterByName}
                        displayInput={true}
                        text={null}
                        // text={{
                        //     title: 'Client List',
                        //     subTitle: `${filteredList?.length} clients`
                        // }}
                        filterButton={false}
                        customButton={false}
                        resetInput={resetInput}
                    />
                </Card>
            </Container>
            <CustomTable
                selectable={false}
                headers={TABLE_HEAD}
                body={filteredList}
                filteredUsers={filteredList}
                filterName={filterName}
                selected={selected}
                order={order}
                orderBy={orderBy}
                onRequestSort={handleRequestSort}
                onSelectAllClick={() => {}}
                rowChange={() => {}}
                rowCount={filteredList?.length}
                onPageChange={handleChangePage}
                page={page}
                setPageNumber={setPage}
                selectRow={() => {}}
                isRequestPage={true}
                link={true}
                noDataText={'No Client data available'}
                noAction={true}
            />
        </>
    );
};

export default ViewBroadcast;
