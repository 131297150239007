import * as React from 'react';
import { useState, useEffect } from 'react';
import CustomTable from '../../../../atoms/CustomTable/index';
import CustomTableToolbar from '../../../../atoms/CustomTableToolbar';
import { Container, Card, Box } from '@mui/material';
import { applySortFilter } from '../../../../utils/helper';
import useGetState from '../../../../utils/hooks/useGetState';
import { ACTION_CODES, STORE_KEYS } from '../../../../constants/apiConstants';
import { useDispatch } from 'react-redux';
import { executeACGAction, resetStoreKey } from '../../../../store/slice';
import FilterDrawer from '../../../../atoms/FilterDrawer';
import DropDown from '../../../../atoms/Dropdown';
import Label from '../../../../atoms/Label/index';

const TABLE_HEAD = [
    { id: 'ParamCode', label: 'D tags', alignRight: false },
    { id: 'ParamName', label: 'Parameter Name', alignRight: false },
    { id: 'ParamDesc', label: 'Parameter Description', alignRight: false },
    { id: 'SectionName', label: 'Section', alignRight: false },
    { id: 'ComponentName', label: 'Component', alignRight: false },
    { id: 'ParamLevel', label: 'Parameter Level', alignRight: false },
    { id: 'Category', label: 'Category', alignRight: false },
    { id: 'SubCategory', label: 'Sub Category', alignRight: false },
    { id: 'DataType', label: 'Data Type', alignRight: false },
    { id: 'Severity', label: 'Severity', alignRight: false },
    { id: 'AlarmType', label: 'Alarm Type', alignRight: false },
    { id: 'UOM', label: 'UOM', alignRight: false },
    { id: "IOTag", label: "IOTag", alignRight: false },
    { id: 'DowntimeFlag', label: 'Downtime Flag', alignRight: false }
];
type Anchor = 'right';
const Details = () => {
    const [state, setState] = useState({
        right: false
    });
    const [page, setPage] = useState<number>(0);
    const [order, setOrder] = useState('desc');
    const [orderBy, setOrderBy] = useState('ParamName');
    const [selected, setSelected] = useState<any>([]);
    const [filterName, setFilterName] = useState<string>('');
    const [modelData, setModelData] = useState<any>([]);
    const [tableData, setTableData] = useState<any[]>([]);
    const [filterOpen, setFilterOpen] = useState<boolean>(false);
    const [severityDropdown, setseverityDropdown] = useState<any>([]);
    const [dataTypeDropdown, setDataTypeDropdown] = useState<any>([]);
    const [categoryDropdown, setCategoryDropdown] = useState<any>([]);
    const [selectedSeverityKey, setSeverityKey] = useState<any>('All');
    const [selectedDataType, setSelectedDataType] = useState<any>('All');
    const [selectedCategory, setSelectedCategory] = useState<any>('All');
    const dispatch = useDispatch();
    const filters = {
        mdFilter: (item: any) => item?.Severity === selectedSeverityKey,
        mfFilter: (item: any) => item?.DataType === selectedDataType,
        stFilter: (item: any) => item?.Category === selectedCategory,
        srchFilter: (item: any) => item?.ParamName?.toLowerCase()?.includes(filterName?.toLowerCase())
    };
    const stateObj = useGetState();

    const getSeverityDropdownData = () => {
        dispatch(
            executeACGAction({
                payload: {
                    requestType: 'GET',
                    urlPath: ACTION_CODES.GET_MACHINE_SEVERITY_DROPDOWN
                },
                uniqueScreenIdentifier: { severityDropdown: true },
                storeKey: STORE_KEYS.ADD_SEVERITY_DROPDOWN
            })
        );
    };
    useEffect(() => {
        if (
            stateObj?.[STORE_KEYS.ADD_SEVERITY_DROPDOWN]?.body?.data &&
            stateObj?.[STORE_KEYS.ADD_SEVERITY_DROPDOWN]?.statusCode === 200
        ) {
            const __severity = stateObj?.[STORE_KEYS.ADD_SEVERITY_DROPDOWN]?.body?.data;
            const arry = [{ code: 'All', name: 'All' }];
            setseverityDropdown([
                ...arry,
                ...__severity?.map((item: any) => {
                    return { code: item.Severity, name: item.Severity };
                })
            ]);
            //dispatch(resetStoreKey({ storeKey: STORE_KEYS.ADD_SEVERITY_DROPDOWN }));
        }
    }, [stateObj?.[STORE_KEYS.ADD_SEVERITY_DROPDOWN]?.severityDropdown]);

    const getDataTypeDropdownData = () => {
        dispatch(
            executeACGAction({
                payload: {
                    requestType: 'GET',
                    urlPath: ACTION_CODES.GET_MACHINE_DATA_TYPE_DROPDOWN
                },
                uniqueScreenIdentifier: { dataTypeDropdown: true },
                storeKey: STORE_KEYS.ADD_DATATYPE
            })
        );
    };

    useEffect(() => {
        if (
            stateObj?.[STORE_KEYS.ADD_DATATYPE]?.body?.data &&
            stateObj?.[STORE_KEYS.ADD_DATATYPE]?.statusCode === 200
        ) {
            const __dataType = stateObj?.[STORE_KEYS.ADD_DATATYPE]?.body?.data;
            const arry = [{ code: 'All', name: 'All' }];
            setDataTypeDropdown([
                ...arry,
                ...__dataType?.map((item: any) => {
                    return { code: item.DataType, name: item.DataType };
                })
            ]);
            // dispatch(resetStoreKey({ storeKey: STORE_KEYS.ADD_DATATYPE }));
        }
    }, [stateObj?.[STORE_KEYS.ADD_DATATYPE]?.dataTypeDropdown]);

    const getCategoryDropdownData = () => {
        dispatch(
            executeACGAction({
                payload: {
                    requestType: 'GET',
                    urlPath: ACTION_CODES.GET_MACHINE_CATEGORY_DROPDOWN
                },
                uniqueScreenIdentifier: { categoryDropdown: true },
                storeKey: STORE_KEYS.ADD_CATEGORY
            })
        );
    };

    useEffect(() => {
        if (
            stateObj?.[STORE_KEYS.ADD_CATEGORY]?.body?.data &&
            stateObj?.[STORE_KEYS.ADD_CATEGORY]?.statusCode === 200
        ) {
            const __category = stateObj?.[STORE_KEYS.ADD_CATEGORY]?.body?.data;
            const arry = [{ code: 'All', name: 'All' }];
            setCategoryDropdown([
                ...arry,
                ...__category?.map((item: any) => {
                    return { code: item.Category, name: item.Category };
                })
            ]);
            //  dispatch(resetStoreKey({ storeKey: STORE_KEYS.ADD_CATEGORY }));
        }
    }, [stateObj?.[STORE_KEYS.ADD_CATEGORY]?.categoryDropdown]);

    useEffect(() => {
        setModelData(stateObj?.[STORE_KEYS.MODEL_PARMS]?.body?.data);
        setTableData(stateObj?.[STORE_KEYS.MODEL_PARMS]?.body?.data);
        getSeverityDropdownData();
        getCategoryDropdownData();
        getDataTypeDropdownData();
    }, []);

    const filteredList = applySortFilter(tableData, order, filterName, orderBy);

    const handleRequestSort = (event: any, property: any) => {
        setPage(0);
        setFilterName('');
        const isAsc = orderBy === event && order === 'asc';
        setOrder(isAsc ? 'desc' : 'asc');
        setOrderBy(event);
    };

    const handleSelectAllClick = (event: any) => {
        if (event.target.checked) {
            const newSelecteds = [];
            for (let i = 0; i < tableData.length; i++) {
                newSelecteds.push(tableData[i].id);
            }
            const toRemove = tableData.slice(page, tableData.length);
            setSelected(newSelecteds);
            return;
        }
        setSelected([]);
    };

    const handleClick = (requestedBy: string) => {
        const selectedIndex = selected.indexOf(requestedBy);
        let newSelected: any[] | ((prevState: unknown[]) => unknown[]) = [];
        if (selectedIndex === -1) {
            newSelected = newSelected.concat(selected, requestedBy);
        } else if (selectedIndex === 0) {
            newSelected = newSelected.concat(selected.slice(1));
        } else if (selectedIndex === selected.length - 1) {
            newSelected = newSelected.concat(selected.slice(0, -1));
        } else if (selectedIndex > 0) {
            newSelected = newSelected.concat(selected.slice(0, selectedIndex), selected.slice(selectedIndex + 1));
        }
        setSelected(newSelected);
    };

    const toggleDrawer = (anchor: Anchor, open: boolean) => (event: React.KeyboardEvent | React.MouseEvent) => {
        if (
            event.type === 'keydown' &&
            ((event as React.KeyboardEvent).key === 'Tab' || (event as React.KeyboardEvent).key === 'Shift')
        ) {
            return;
        }
        setState({ ...state, [anchor]: open });
    };

    const handleFilterByName = (event: { target: { value: React.SetStateAction<string> } }) => {
        setPage(0);
        setOrderBy('ParamName');
        setFilterName(event.target.value);
    };

    const handleChangePage = (event: any, newPage: React.SetStateAction<number>) => {
        setPage(newPage);
    };

    const showRow = (event: any) => { };

    const handleRoute = (row: any, ele: any) => {
        if (ele === 'Action') {
        }
    };

    const resetInput = () => {
        setPage(0);
        setFilterName('');
        let selectedFilter: any = [];
        if (selectedSeverityKey !== 'All') {
            selectedFilter = [...selectedFilter, filters.mdFilter];
        }
        if (selectedDataType !== 'All') {
            selectedFilter = [...selectedFilter, filters.mfFilter];
        }
        if (selectedCategory !== 'All') {
            selectedFilter = [...selectedFilter, filters.stFilter];
        }
        if (selectedFilter?.length !== 0) {
            const x = [...modelData]?.filter((ele: any) => selectedFilter?.every((f: any) => f(ele)));
            setTableData(x);
        } else {
            setTableData([...modelData]);
        }
    };

    const clearFilter = () => {
        setPage(0);
        setSeverityKey('All');
        setSelectedCategory('All');
        setSelectedDataType('All');
        let selectedFilter: any = [];
        if (filterName !== '') {
            selectedFilter = [...selectedFilter, filters.srchFilter];
        }
        if (selectedFilter?.length !== 0) {
            const x = [...modelData]?.filter((ele: any) => selectedFilter?.every((f: any) => f(ele)));
            setTableData(x);
        } else {
            setTableData([...modelData]);
        }
        setFilterOpen(false);
    };
    const applyFilter = () => {
        setPage(0);
        setOrderBy('ParamName');
        setOrder('desc');
        let selectedFilter: any = [];
        if (selectedSeverityKey !== 'All') {
            selectedFilter = [...selectedFilter, filters.mdFilter];
        }
        if (selectedDataType !== 'All') {
            selectedFilter = [...selectedFilter, filters.mfFilter];
        }
        if (selectedCategory !== 'All') {
            selectedFilter = [...selectedFilter, filters.stFilter];
        }
        if (filterName !== '') {
            selectedFilter = [...selectedFilter, filters.srchFilter];
        }
        if (selectedFilter?.length !== 0) {
            const x = [...modelData]?.filter((ele: any) => selectedFilter?.every((f: any) => f(ele)));
            setTableData(x);
        } else {
            setTableData([...modelData]);
        }
        setFilterOpen(false);
    };

    useEffect(() => {
        return () => {
            dispatch(resetStoreKey({ storeKey: STORE_KEYS.ADD_CATEGORY }));
            dispatch(resetStoreKey({ storeKey: STORE_KEYS.ADD_DATATYPE }));
            dispatch(resetStoreKey({ storeKey: STORE_KEYS.ADD_SEVERITY_DROPDOWN }));
        };
    }, []);

    return (
        <div>
            <div>
                <Container
                    maxWidth="xl"
                    style={{
                        paddingLeft: '3px',
                        paddingRight: '3px'
                    }}
                >
                    <Card
                        style={{
                            borderRadius: '0px',
                            backgroundColor: '#2b2d42',
                            overflow: 'inherit'
                        }}
                    >
                        <CustomTableToolbar
                            drawerOpen={() => setFilterOpen(true)}
                            numSelected={selected?.length}
                            filterName={filterName}
                            onFilterName={handleFilterByName}
                            displayInput={true}
                            text={{
                                title: 'Parameter List',
                                subTitle: `${filteredList?.length} Parameters`
                            }}
                            filterButton={true}
                            customButton={false}
                            resetInput={resetInput}
                        />
                    </Card>
                </Container>
                <CustomTable
                    selectable={false}
                    headers={TABLE_HEAD}
                    body={filteredList}
                    filteredUsers={filteredList}
                    filterName={filterName}
                    selected={selected}
                    order={order}
                    orderBy={orderBy}
                    onRequestSort={handleRequestSort}
                    onSelectAllClick={handleSelectAllClick}
                    rowChange={(event: string) => handleClick(event)}
                    rowCount={filteredList.length}
                    onPageChange={handleChangePage}
                    page={page}
                    setPageNumber={setPage}
                    selectRow={showRow}
                    route={handleRoute}
                    isRequestPage={true}
                    link={true}
                />
            </div>
            <FilterDrawer
                handlerClose={() => {
                    setFilterOpen(false);
                }}
                status={filterOpen}
                drawerHeader={'Filter'}
                children={
                    <Container className="createReport">
                        <Box style={{ minHeight: '75vh' }}>
                            <Label label="Severity" className={'labelDiv'} />
                            <DropDown
                                onChange={(event: any) => {
                                    setSeverityKey(event.target.value);
                                }}
                                placeHolder="Select Severity"
                                name="dropdownSeverity"
                                className="inputDiv"
                                items={severityDropdown}
                                value={selectedSeverityKey}
                            />
                            <Label label="Data Type" className={'labelDiv'} />
                            <DropDown
                                onChange={(event: any) => {
                                    setSelectedDataType(event.target.value);
                                }}
                                placeHolder="Select data type"
                                name="dropdownDatatype"
                                className="inputDiv"
                                items={dataTypeDropdown}
                                value={selectedDataType}
                            />
                            <Label label="Category" className={'labelDiv'} />
                            <DropDown
                                onChange={(event: any) => {
                                    setSelectedCategory(event.target.value);
                                }}
                                placeHolder="Select Category"
                                name="dropdownCategory"
                                className="inputDiv"
                                items={categoryDropdown}
                                value={selectedCategory}
                            />
                        </Box>
                    </Container>
                }
                clearFn={clearFilter}
                applyFn={applyFilter}
            />
        </div>
    );
};

export default Details;
