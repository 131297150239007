import { Paper, Box, Typography } from '@mui/material';
import Button from '../../../../atoms/Button/index';
import FailureIcon from '../../../../../assets/lockedAccount.svg';

type ComponentProps = { data: any };
const LockedAccount = (props: ComponentProps) => {
    const { data } = props;

    const handleSubmit = async () => {
        // api call to check if its first time login
        data?.setTemplateType('simpleLogin');
    };
    return (
        <Paper elevation={2} className="loginPaper">
            <Box className="loginLogo">
                <img src={FailureIcon} alt="logo" />
            </Box>
            <div className="loginFormHeader">
                <Typography className="welcomeText lockTextDiv">Your account is locked</Typography>
                <Typography className="loginText">Please contact admin to retrieve</Typography>
            </div>

            <Button
                formInput="buttonDiv"
                fullWidth
                name="Back to Login"
                type="button"
                variant="contained"
                onClick={handleSubmit}
            />
        </Paper>
    );
};

export default LockedAccount;
