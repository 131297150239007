import { useState, useEffect } from 'react';
import { Container } from '@mui/material';
import AcgStepper from '../../../../atoms/Stepper';
import PlantInfo from './PlantInfo';
import PlantLine from './PlantLine';
import '../../FeatureManagement/CreateReport/index.scss';

type Props = {
    drawerClose: Function;
    setCurrTab: Function;
    setReload: Function;
    setSnackbarOpts: Function;
};
const options = {
    AD_SUCCESS: {
        message: 'Plant created successfully!',
        open: true,
        type: 'success'
    }
};
const CreatePlant = (props: Props) => {
    const { drawerClose, setCurrTab, setReload = () => {}, setSnackbarOpts = () => {} } = props;
    const [step, setStep] = useState(0);
    const [stepState, setStepState] = useState({ info: true, line: false });
    const [step1Vals, setStep1Vals] = useState<any>({});

    useEffect(() => {
        if (step === 0) {
            setStepState({ info: true, line: false });
        } else {
            setStepState({ info: false, line: true });
        }
    }, [step]);

    return (
        <Container className="createReport">
            <div style={{ marginTop: '37px' }}>
                <AcgStepper
                    steps={[
                        { label: 'Plant Information', active: stepState?.info },
                        { label: 'Add Line Number', active: stepState?.line }
                    ]}
                />
            </div>
            {step == 0 ? (
                <PlantInfo
                    drawerClose={drawerClose}
                    setCurrTab={setCurrTab}
                    setReload={setReload}
                    setSnackbarOpts={setSnackbarOpts}
                    setStep={setStep}
                    step1Vals={step1Vals}
                    setStep1Vals={setStep1Vals}
                />
            ) : (
                <PlantLine
                    drawerClose={drawerClose}
                    setCurrTab={setCurrTab}
                    setReload={setReload}
                    setSnackbarOpts={setSnackbarOpts}
                    setStep={setStep}
                    step1Vals={step1Vals}
                />
            )}
        </Container>
    );
};

export default CreatePlant;
