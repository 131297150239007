import React, { useState, useEffect } from 'react';
import { useDispatch } from 'react-redux';
import CustomTable from '../../../atoms/CustomTable/index';
import CustomTableToolbar from '../../../atoms/CustomTableToolbar';
import { Container, Card } from '@mui/material';
import TemporaryDrawer from '../../../atoms/tempDrawer';
import { ACTION_CODES, STORE_KEYS } from '../../../constants/apiConstants';
import { executeACGAction, resetErr, resetStoreKey, updateScreenIdentifiers } from '../../../store/slice';
import useGetState from '../../../utils/hooks/useGetState';
import { applySortFilter } from '../../../utils/helper';
// import '../../ClientManagement/index.scss';
import SnackbarAlert from '../../../atoms/Snackbar';
import AlertBox from '../../../atoms/AlertBox';
import DetailDrawer from '../../../atoms/DetailDrawer';
import { formatAccordionData } from '../../../utils/formatData';

const TABLE_HEAD = [
    { id: 'UserName', label: 'Contact Name', alignRight: false },
    { id: 'EmailId', label: 'Email', alignRight: false },
    { id: 'CalledTime', label: 'Timing', alignRight: false },
    { id: 'CallDuration', label: 'Duration', alignRight: false },
    { id: 'MeetingRecordingLink', label: 'Meeting Recording Link', alignRight: false }
    // { id: 'status', label: 'Status', alignRight: false }
];

const options = {
    DEFAULT: {
        message: '',
        open: false,
        type: ''
    },
    RM_SUCCESS: {
        message: 'User inactivated successfully!',
        open: true,
        type: 'success'
    },
    USR_ADD_SUCCESS: {
        message: 'User Added successfully!',
        open: true,
        type: 'success'
    }
};

type Anchor = 'right';

const Users = (props: any) => {
    const {
        getSummary,
        radioValue,
        selectedClient,
        setDtlData,
        dtlData,
        setCreateValue,
        setDrawerHeader,
        setCreateDrawerOpen: setEditDrawerOpen
    } = props;

    const dispatch = useDispatch();
    const [drawerState, setDrawerState] = useState({
        right: false
    });
    const [page, setPage] = useState<number>(0);
    const [order, setOrder] = useState('desc');
    const [orderBy, setOrderBy] = useState('UserName');
    const [selected, setSelected] = useState<any>([]);
    const [selectedRow, setSelectedRow] = useState<any>({});
    const [filterName, setFilterName] = useState<string>('');
    const [tableData, setTableData] = useState<any[]>([]);
    const [createDrawerOpen, setCreateDrawerOpen] = useState(false);
    const [isCallHistLoading,setIsCallHistLoading]=useState(false)

    const state = useGetState();

    const getList = () => {
        setIsCallHistLoading(true)
        const loginData = state[STORE_KEYS.USER_DTL];
        const reqObj: {
            [key: string]: any;
        } = {
            userType: radioValue
        };

        let pullData = true;
        if (loginData?.IsACGUser === 'Y') {
            if (radioValue === 'Customer') {
                reqObj.customerKey = selectedClient;
                pullData = Boolean(selectedClient);
            }
        } else {
            reqObj.customerKey = loginData?.CustomerKey;
        }

        pullData &&
            dispatch(
                executeACGAction({
                    payload: {
                        requestType: 'GET',
                        urlPath: ACTION_CODES.GET_CALLHISTORY,
                        reqObj
                    },
                    storeKey: STORE_KEYS.CALLHISTORY_LIST
                })
            );
    };
    useEffect(() => {
        setTableData([]);
        getList();
    }, [radioValue, selectedClient]);

    useEffect(() => {
        const tmpTableData = state[STORE_KEYS.CALLHISTORY_LIST]?.body?.data;
        if(!tmpTableData) return
        tmpTableData && setTableData(tmpTableData.map((item: any) => ({ ...item, status: item.UserStatus })));
        setIsCallHistLoading(false)
    }, [state[STORE_KEYS.CALLHISTORY_LIST]?.body?.data]);

    const [snackbarOptions, setSnackbarOptions] = useState(options.DEFAULT);
    const [removeBxOpen, setRemoveBxOpen] = useState(false);
    const [rmUser, setRmUser] = useState(false);
    const [userKey, setUserKey] = useState<any>();
    const closeRmBx = () => {
        setRemoveBxOpen(false);
    };
    const removeFn = (ele: any) => {
        setUserKey(ele?.UserKey);
        setRemoveBxOpen(true);
    };
    useEffect(() => {
        if (rmUser) {
            closeRmBx();
            dispatch(
                executeACGAction({
                    payload: {
                        requestType: 'DELETE',
                        urlPath: ACTION_CODES.DELETE_USER + userKey
                    },
                    uniqueScreenIdentifier: {
                        rowDeleted: true
                    },
                    storeKey: STORE_KEYS.DELETE
                })
            );
            setRmUser(false);
        }
    }, [rmUser]);
    useEffect(() => {
        if (state[STORE_KEYS.DELETE]?.rowDeleted) {
            if (state?.[STORE_KEYS.DELETE]?.statusCode === 200) {
                setSnackbarOptions(options.RM_SUCCESS);
                getList();
                getSummary();
            }
            dispatch(
                updateScreenIdentifiers({
                    storeKey: STORE_KEYS.DELETE,
                    uniqueScreenIdentifier: {
                        rowDeleted: false
                    }
                })
            );
        }
    }, [state[STORE_KEYS.DELETE]?.rowDeleted]);

    const handleRequestSort = (event: any, property: any) => {
        const isAsc = orderBy === event && order === 'asc';
        setOrder(isAsc ? 'desc' : 'asc');
        setOrderBy(event);
    };

    const handleSelectAllClick = (event: any) => {
        if (event.target.checked) {
            const newSelecteds = [];
            for (let i = 0; i < tableData.length; i++) {
                newSelecteds.push(tableData[i].id);
            }
            const toRemove = tableData.slice(page, tableData.length);
            setSelected(newSelecteds);
            return;
        }
        setSelected([]);
    };

    const handleClick = (requestedBy: string) => {
        const selectedIndex = selected.indexOf(requestedBy);
        let newSelected: any[] | ((prevState: unknown[]) => unknown[]) = [];
        if (selectedIndex === -1) {
            newSelected = newSelected.concat(selected, requestedBy);
        } else if (selectedIndex === 0) {
            newSelected = newSelected.concat(selected.slice(1));
        } else if (selectedIndex === selected.length - 1) {
            newSelected = newSelected.concat(selected.slice(0, -1));
        } else if (selectedIndex > 0) {
            newSelected = newSelected.concat(selected.slice(0, selectedIndex), selected.slice(selectedIndex + 1));
        }
        setSelected(newSelected);
    };

    const toggleDrawer = (anchor: Anchor, open: boolean) => (event: React.KeyboardEvent | React.MouseEvent) => {
        if (
            event.type === 'keydown' &&
            ((event as React.KeyboardEvent).key === 'Tab' || (event as React.KeyboardEvent).key === 'Shift')
        ) {
            return;
        }
        setDrawerState({ ...drawerState, [anchor]: open });
    };

    const filteredList = applySortFilter(tableData, order, filterName, orderBy);

    const handleFilterByName = (event: { target: { value: string } }) => {
        setPage(0);
        setFilterName(event.target.value);
        setOrderBy('UserName');
    };

    const handleChangePage = (event: any, newPage: React.SetStateAction<number>) => {
        setPage(newPage);
    };

    const showRow = (event: any) => { };

    const handleSnackbarError = (err: any) => {
        const errorMsg = err?.message || 'Internal Server error';
        const snackbarError = {
            message: errorMsg,
            type: 'reject',
            open: true
        };
        setSnackbarOptions(snackbarError);
    };

    useEffect(() => {
        if (state?.err) {
            handleSnackbarError(state?.err);
            dispatch(resetErr());
        }
    }, [state?.err]);

    const handleRoute = (row: any, ele: any) => {
        if (ele === 'Action') {
            const reqPayload = {
                payload: {
                    urlPath: `${ACTION_CODES.GET_USER_ACCESS_DETAILS}/${row.UserKey}`,
                    requestType: 'GET'
                },
                storeKey: STORE_KEYS.USER_ACCESS_DETAILS
            };
            dispatch(executeACGAction(reqPayload));
        }
        setSelectedRow({ ...row, name: row?.RoleName, plants: [], count: 0 });
        setCreateDrawerOpen(true);
    };

    useEffect(() => {
        if (state?.[STORE_KEYS.ALL_SELECTED_PLANTS]?.statusCode === 200) {
            const AcgAllSelectedData = state[STORE_KEYS.ALL_SELECTED_PLANTS]?.body?.data;
            if (AcgAllSelectedData) {
                const formatedData = formatAccordionData(AcgAllSelectedData, true);
                selectedRow.plants = formatedData;
                selectedRow.count = AcgAllSelectedData?.length;
                selectedRow.actualData = state?.[STORE_KEYS.USER_ACCESS_DETAILS]?.body?.data;
                setDtlData(selectedRow);
                dispatch(resetStoreKey({ storeKey: STORE_KEYS.USER_ACCESS_DETAILS }));
            }
        }
    }, [state[STORE_KEYS.ALL_SELECTED_PLANTS]?.body?.data]);

    const closeDrawer = () => {
        setDtlData([]);
        setCreateDrawerOpen(false);
    };

    const closeSnackbar = () => setSnackbarOptions(options.DEFAULT);
    const resetInput = () => {
        setFilterName('');
    };

    return (
        <div>
            <div>
                <SnackbarAlert options={snackbarOptions} handleClose={closeSnackbar} />
                <Container
                    maxWidth="xl"
                    style={{
                        paddingLeft: '3px',
                        paddingRight: '3px'
                    }}
                >
                    <Card
                        style={{
                            borderRadius: '0px',
                            backgroundColor: '#2b2d42',
                            overflow: 'inherit'
                        }}
                    >
                        <CustomTableToolbar
                            drawerOpen={toggleDrawer('right', true)}
                            numSelected={selected?.length}
                            filterName={filterName}
                            onFilterName={handleFilterByName}
                            resetInput={resetInput}
                            displayInput={true}
                            text={{
                                title: 'All Call History',
                                subTitle: `${tableData.length} users`
                            }}
                            filterButton={false}
                            customButton={false}
                        />
                    </Card>
                </Container>
                <CustomTable
                    selectable={false}
                    headers={TABLE_HEAD}
                    body={filteredList}
                    isLoading={isCallHistLoading}
                    filteredUsers={filteredList}
                    filterName={filterName}
                    selected={selected}
                    order={order}
                    orderBy={orderBy}
                    onRequestSort={handleRequestSort}
                    onSelectAllClick={handleSelectAllClick}
                    rowChange={(event: string) => handleClick(event)}
                    rowCount={filteredList.length}
                    onPageChange={handleChangePage}
                    page={page}
                    setPageNumber={setPage}
                    selectRow={showRow}
                    route={handleRoute}
                    isRequestPage={true}
                    link={true}
                    removeFn={removeFn}
                    {...(radioValue === 'Customer' && !selectedClient
                        ? { noDataText: 'Selected client to display users' }
                        : {})}
                />
            </div>
            <AlertBox
                isOpen={removeBxOpen}
                handleClose={closeRmBx}
                leftBtnPress={closeRmBx}
                rightBtnPress={() => setRmUser(true)}
                modalHeader="Inactivate User"
                modalContent="Are you sure you want to inactive the user?"
            />
            <TemporaryDrawer
                handlerOpen={toggleDrawer('right', true)}
                handlerClose={toggleDrawer('right', false)}
                status={state['right']}
            />
            <DetailDrawer hasImage={false}
                handlerOpen={() => { }}
                handlerClose={closeDrawer}
                status={createDrawerOpen}
                data={dtlData}
                block={true}
                type={'Role'}
                deleteFn={() => removeFn(dtlData)}
                editFn={() => {
                    setEditDrawerOpen(true);
                    setCreateValue('New User');
                    setDrawerHeader('Edit');
                }}
            />
        </div>
    );
};

export default Users;
