import React, { useState, useEffect } from 'react';
import Box from '@mui/material/Box';
import Drawer from '@mui/material/Drawer';
import Label from '../Label';
import UserMoreMenu from '../CustomTableMenu/index';
import { sentenceCase } from 'change-case';
import CloudDoneOutlinedIcon from '@material-ui/icons/CloudDoneOutlined';
import './index.scss';
import Tabs from '../../features/MachineDetail/DrawerContent';
import MachineDetails from '../../features/MachineManagement/Machines/Details';
import ModelDetails from '../../features/MachineManagement/Models/Details';
import { Avatar } from '@mui/material';
import Popover from '../Popover';
import CallSpoc from '../../../assets/images/CallSpoc.svg';
import SpocDetails from '../../features/MachineDetail/DrawerContent/TabContent/Overview/SPOCDetails';
import useGetState from '../../utils/hooks/useGetState';
import { STORE_KEYS } from '../../constants/apiConstants';
import MachineSearch from './MachineSearch';
import RightDrawer from '../tempDrawer/indexAlt';
import OnboardForm from './MachineOnboard/OnboardForm';
import ACGButton from '../Button';
import EditOnboardForm from './MachineOnboard/EditOnbordForm';
import SnackbarAlert from '../Snackbar';
import moment from 'moment';

type Anchor = 'right';

type Props = {
    status: any;
    handlerOpen: any;
    handlerClose: any;
    data?: any;
    drawerHeader?: any;
    block?: boolean;
    type?: string;
    editFn?: Function;
    deleteFn?: Function;
    searchTxt?: string;
    setSearchTxt?: any;
    resetInput?: any;
    machines?: any[];
    setSelectedRow?: any;
    setMachinesReLoad?: any;
    unassignFn?: Function;
    toggleTokenModal?: Function;
};
const options = {
    DEFAULT: {
        message: '',
        open: false,
        type: ''
    }
};
export default function TemporaryDrawer(props: Props) {
    const {
        status,
        handlerClose,
        data,
        block = false,
        type,
        editFn = () => { },
        deleteFn = () => { },
        toggleTokenModal = () => { },
        unassignFn = () => { },
        searchTxt,
        resetInput = () => { },
        setSearchTxt = () => { },
        machines,
        setSelectedRow,
        setMachinesReLoad = () => { }
    } = props;
    const labelStatus = data?.OnboardStatus || '';
    const [state, setState] = React.useState({
        right: false
    });
    const [onboardForm, setOnboardForm] = useState(false);
    const [onboardHeader, setOnboardHeader] = useState('');
    const [snackbarOptions, setSnackbarOptions] = useState(options.DEFAULT);
    const closeSnackbar = () => setSnackbarOptions(options.DEFAULT);
    const stateVal = useGetState();
    const feature:any = stateVal?.[STORE_KEYS.USER_DTL]?.features;
    const userRole =  feature?.find((item:any) => item.FeatureCode === "MACHMGMT")?.IsEditable;
    const toggleDrawer = (anchor: Anchor, open: boolean) => (event: React.KeyboardEvent | React.MouseEvent) => {
        if (
            event.type === 'keydown' &&
            ((event as React.KeyboardEvent).key === 'Tab' || (event as React.KeyboardEvent).key === 'Shift')
        ) {
            return;
        }

        setState({ ...state, [anchor]: open });
    };

    const handleClose = () => {
        handlerClose();
        toggleDrawer('right', false);
    };

    const list = () => (
        <Box sx={{ width: 250 }} role="presentation" className={'standardBox'}>
            {!type ? (
                <div>
                    <div className="baseDiv">
                        <div
                            onClick={handleClose}
                            style={{
                                border: '1px solid #5D97F6',
                                borderRadius: '50px',
                                height: '25px',
                                width: '26px',
                                position: 'absolute',
                                left: '20px',
                                top: '30px',
                                cursor: 'pointer'
                            }}
                        >
                            <span
                                style={{
                                    position: 'relative',
                                    fontSize: '12px',
                                    color: '#5D97F6',
                                    left: '35%',
                                    top: '-3%'
                                }}
                            >
                                X
                            </span>
                        </div>

                        <div
                            style={{
                                display: 'flex',
                                justifyContent: 'start',
                                alignItems: 'flex-start',
                                width: '100%'
                            }}
                        >
                            <div style={{ display: 'block', marginLeft: '45px' }}>
                                <h6 style={{ color: '#DADCEE', fontSize: '16px', fontFamily: 'Montserrat' }}>
                                    {data?.MachineName}
                                </h6>
                                <p style={{ color: '#DADCEE', fontSize: '13px', fontFamily: 'Montserrat' }}>
                                    {data?.PlantName}
                                </p>
                                <p style={{ color: '#DADCEE', fontSize: '10px', fontFamily: 'Montserrat' }}>
                                    {`Mode: ${data?.MachineMode === null ? 'N/A' : data?.MachineMode} | Status: ${data?.MachineStatus === null ? 'N/A' : data?.MachineStatus
                                        }`}
                                </p>
                            </div>
                            <div style={{ marginLeft: '12px', marginTop: '-2px' }}>
                                <Label
                                    variant="outlined"
                                    color={
                                        data?.ConnectivityStatus === 'Critical'
                                            ? 'critical'
                                            : data?.ConnectivityStatus === 'Major'
                                                ? 'major'
                                                : data?.ConnectivityStatus === 'Minor'
                                                    ? 'minor'
                                                    : data?.ConnectivityStatus === 'Failure'
                                                        ? 'failure'
                                                        : data?.ConnectivityStatus === 'Maintenance'
                                                            ? 'maintenance'
                                                            : data?.ConnectivityStatus === 'General' || 'Online'
                                                                ? 'general'
                                                                : 'warning'
                                    }
                                >
                                    {data?.ConnectivityStatus ? sentenceCase(data?.ConnectivityStatus) : 'Unavailable'}
                                </Label>
                            </div>

                            <div className="onlineMainDiv">
                                <p className="onlineDiv">
                                    <CloudDoneOutlinedIcon className="online" />
                                    {data?.ConnectivityStatusTime}
                                </p>
                            </div>

                            {/* <div className="offlineMainDiv">
                      <p className="offlineDiv">
                          <CloudOffOutlinedIcon className="offline" />
                          Offline since {data?.time}
                      </p>
                  </div> */}

                            <div className="phoneMainDiv">
                                {/* <p className="phoneDiv">
                                    <CallOutlinedIcon className="phone" />
                                    Call ACG SPOC
                                </p> */}
                                {/* <SpocDetails spocs={stateVal?.[STORE_KEYS.SPOCS]?.body?.data} editable={false} /> */}
                                {/* <Popover
                                    itemsArray={[
                                        {
                                            userName: 'test'
                                        }
                                    ]}
                                    infoOnly={false}
                                    src={CallSpoc}
                                    spocs={true}
                                    children={
                                        stateVal?.[STORE_KEYS.SPOCS]?.body?.data?.length > 0 ? (
                                            <SpocDetails
                                                spocs={stateVal?.[STORE_KEYS.SPOCS]?.body?.data}
                                                editable={false}
                                            />
                                        ) : null
                                    }
                                /> */}
                            </div>

                            {/* <UserMoreMenu click={editFn} remove={deleteFn} block={block} dtlMode={true} /> */}
                            <div className="machineDrawerSearch">
                                <div>
                                    <MachineSearch
                                        searchTxt={searchTxt}
                                        setSearchTxt={setSearchTxt}
                                        resetInput={resetInput}
                                        machines={machines}
                                        onClick={(machine: any) => {
                                            setSelectedRow({
                                                ...data,
                                                reloadTabs: true,
                                                MachineKey: machine?.MachineKey,
                                                MachineName: machine?.MachineName,
                                                PlantName: machine?.PlantName,
                                                MachineStatus: machine?.MachineStatus,
                                                ConnectivityStatus: machine?.ConnectivityStatus,
                                                ConnectivityStatusTime: machine?.ConnectivityStatusTime,
                                                LastUpdatedTime: machine?.LastUpdatedTime,
                                                MachineMode: machine?.MachineMode
                                            });
                                        }}
                                    />
                                </div>
                                <div style={{ marginTop: '5px', marginLeft: '45px' }}>
                                    <h6 style={{ fontSize: '12px', color: '#B1C2DF', marginTop: '15px' }}>
                                    {`Last Updated: ${data?.LastUpdatedTime}`}
                                    </h6>
                                </div>
                            </div>
                        </div>
                    </div>
                    <Tabs data={data} setSelectedRow={setSelectedRow} />
                </div>
            ) : type === 'machines' ? (
                <div>
                    <div className="baseDiv">
                        <div
                            onClick={handleClose}
                            style={{
                                border: '1px solid #5D97F6',
                                borderRadius: '50px',
                                height: '25px',
                                width: '25px',
                                position: 'absolute',
                                left: '20px',
                                top: '30px',
                                cursor: 'pointer'
                            }}
                        >
                            <span
                                style={{
                                    position: 'relative',
                                    fontSize: '12px',
                                    color: '#5D97F6',
                                    left: '35%',
                                    top: '-3%'
                                }}
                            >
                                X
                            </span>
                        </div>

                        <div
                            style={{
                                display: 'flex',
                                justifyContent: 'space-between',
                                alignItems: 'flex-start',
                                width: '100%'
                            }}
                        >
                            <div style={{ display: 'block', marginLeft: '45px' }}>
                                <h6
                                    style={{
                                        color: '#DADCEE',
                                        fontSize: '16px',
                                        fontFamily: 'Montserrat',
                                        marginBottom: '5px'
                                    }}
                                >
                                    {data?.MachineName}
                                </h6>
                                {/* <p style={{ color: '#DADCEE', fontSize: '13px', fontFamily: 'Montserrat' }}>
                          {data?.location}
                      </p> */}
                                <p
                                    style={{
                                        color: '#DADCEE',
                                        fontSize: '10px',
                                        fontFamily: 'Montserrat',
                                        marginBottom: '10px'
                                    }}
                                >
                                    {data?.MachineModelName} | {data?.MachineFamilyName} | {data?.MachineSerialNo}
                                </p>
                            </div>
                            <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
                                <div style={{ marginLeft: '12px', marginRight: '5px' }}>
                                    <Label
                                        variant="outlined"
                                        color={
                                            labelStatus === ('Onboarded' || 'Online')
                                                ? 'general'
                                                : labelStatus === 'Unassigned' || data?.OnboardStatus === 'Unassigned'
                                                    ? 'failure'
                                                    : 'warning'
                                        }
                                    >
                                        {sentenceCase(labelStatus)}
                                    </Label>
                                </div>
                                <div style={{ marginTop: '2px' }}>
                                    <UserMoreMenu click={editFn} remove={deleteFn} block={block} dtlMode={true} />
                                </div>
                            </div>
                        </div>
                    </div>
                    <div style={{ paddingBottom: '30px', borderBottom: '1px solid #3A4456' }}>
                        <div className="userDetailDiv" style={{ width: '95%', justifyContent: 'flex-start' }}>
                            <div className={'detailDivChild firstChild'} style={{ width: '20%', textAlign: 'left' }}>
                                <p>Client</p>
                                <h6 style={{ color: '#B1C2DF' }}>{data?.CustomerName || '-'}</h6>
                            </div>
                            <div
                                className={'detailDivChild firstChild'}
                                style={{ width: '20%', textAlign: 'left', paddingLeft: '20px' }}
                            >
                                <p>Plant</p>
                                <h6 style={{ color: '#B1C2DF' }}>{data?.PlantName || '-'}</h6>
                            </div>
                            <div
                                className={'detailDivChild firstChild'}
                                style={{ width: '20%', textAlign: 'left', paddingLeft: '20px' }}
                            >
                                <p>Line Number</p>
                                <h6 style={{ color: '#B1C2DF' }}>{data?.PlantLineNoName || '-'}</h6>
                            </div>
                            <div
                                className="detailDivChild firstChild"
                                style={{ width: '20%', textAlign: 'left', paddingLeft: '20px' }}
                            >
                                <p>GatewayId</p>
                                <h6 style={{ color: '#B1C2DF' }}>{data?.GatewayId || '-'}</h6>
                            </div>
                            <div
                                className={'detailDivChild firstChild'}
                                style={{ width: '20%', textAlign: 'left', paddingLeft: '20px' }}
                            >
                                <p>Gateway Installation Date</p>
                                <h6 style={{ color: '#B1C2DF' }}>{moment.utc(data?.GatewayInstalledDate).format("DD/MM/YYYY") || '-'}</h6>
                            </div>
                            <div
                                className={'detailDivChild'}
                                style={{ width: '20%', textAlign: 'left', paddingLeft: '20px' }}
                            >
                                <p>Warranty Till Date</p>
                                <h6 style={{ color: '#B1C2DF' }}>{moment.utc(data?.WarrantyTillDate).format("DD/MM/YYYY") || '-'}</h6>
                            </div>

                            <div
                                style={{
                                    display: 'flex',
                                    width: '40%',
                                    justifyContent: 'flex-end',
                                    alignItems: 'center'
                                }}
                            >
                                { userRole === "Y" ?
                                data?.OnboardStatus === 'Onboarded' ? (
                                    <>
                                        <div style={{ marginRight: '15px' }}>
                                            <ACGButton
                                                variant="outlined"
                                                onClick={() => toggleTokenModal(true)}
                                                name="Get SAS Token"
                                                className='machineButton'
                                            // style={{ textAlign: 'center', minWidth: '100px' }}
                                            />
                                        </div>
                                        <div style={{ marginRight: '15px' }}>
                                            <ACGButton
                                                variant="outlined"
                                                onClick={() => unassignFn(data)}
                                                name="Unassign"
                                                className='machineButton'
                                            />
                                        </div>

                                        <ACGButton
                                            variant="outlined"
                                            onClick={() => {
                                                setOnboardForm(true);
                                                setOnboardHeader('Edit machine onboarding');
                                            }}
                                            name="Manage"
                                            className='machineButton'
                                        />
                                    </>
                                ) : (
                                    <ACGButton
                                        variant="outlined"
                                        onClick={() => {
                                            setOnboardForm(true);
                                            setOnboardHeader('Onboard Machine');
                                        }}
                                        name="Onboard Machine"
                                        className='machineButton'
                                    />
                                )
                                : null
                            }
                            </div>
                        </div>
                    </div>
                    <MachineDetails data={data} />
                    <RightDrawer
                        handlerOpen={() => { }}
                        handlerClose={() => setOnboardForm(false)}
                        status={onboardForm}
                        drawerHeader={onboardHeader}
                        children={
                            onboardHeader === 'Onboard Machine' ? (
                                <OnboardForm
                                    data={data}
                                    drawerClose={() => setOnboardForm(false)}
                                    setSnackbarOpts={setSnackbarOptions}
                                    setMachinesReLoad={setMachinesReLoad}
                                    setSelectedRow={setSelectedRow}
                                />
                            ) : (
                                <EditOnboardForm
                                    data={data}
                                    drawerClose={() => setOnboardForm(false)}
                                    setSnackbarOpts={setSnackbarOptions}
                                    setMachinesReLoad={setMachinesReLoad}
                                    setSelectedRow={setSelectedRow}
                                />
                            )
                        }
                    />
                </div>
            ) : (
                <div>
                    <div className="baseDiv">
                        <div
                            onClick={handleClose}
                            style={{
                                border: '1px solid #5D97F6',
                                borderRadius: '50px',
                                height: '25px',
                                width: '25px',
                                position: 'absolute',
                                left: '20px',
                                top: '30px',
                                cursor: 'pointer'
                            }}
                        >
                            <span
                                style={{
                                    position: 'relative',
                                    fontSize: '12px',
                                    color: '#5D97F6',
                                    left: '35%',
                                    top: '-3%'
                                }}
                            >
                                X
                            </span>
                        </div>

                        <div
                            style={{
                                display: 'flex',
                                justifyContent: 'space-between',
                                alignItems: 'flex-start',
                                width: '100%'
                            }}
                        >
                            <div
                                style={{
                                    display: 'flex',
                                    marginLeft: '45px',
                                    alignItems: 'center',
                                    marginBottom: '10px'
                                }}
                            >
                                <Avatar
                                    src={data?.MachineModelImagePath}
                                    alt={data?.name?.[0]}
                                    style={{ borderRadius: '6px', height: '50px', width: '50px' }}
                                />
                                <div style={{ display: 'block', marginLeft: '10px' }}>
                                    <h6
                                        style={{
                                            color: '#DADCEE',
                                            fontSize: '16px',
                                            fontFamily: 'Montserrat',
                                            marginBottom: '0px'
                                        }}
                                    >
                                        {data?.MachineModelName}
                                    </h6>
                                    <p
                                        style={{
                                            color: '#DADCEE',
                                            fontSize: '14px',
                                            fontFamily: 'Montserrat',
                                            marginBottom: '0px'
                                        }}
                                    >
                                        {data?.MachineModelCode}
                                    </p>
                                </div>
                            </div>

                            <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
                                <div style={{ marginTop: '2px' }}>
                                    <UserMoreMenu click={editFn} remove={deleteFn} block={block} dtlMode={true} />
                                </div>
                            </div>
                        </div>
                    </div>
                    <ModelDetails />
                </div>
            )}
        </Box>
    );

    return (
        <div className='machinemonitoringDrawer'>
            <SnackbarAlert options={snackbarOptions} handleClose={closeSnackbar} />
            <Drawer
                anchor={'right'}
                open={status}
                onClose={handleClose}
                style={{ zIndex: '9999' }}
                className={'machineMonitoringDrawerDiv'}
            >
                {list()}
            </Drawer>
        </div>
    );
}
