import { useEffect, useState } from 'react';
import { useHistory } from 'react-router-dom';
import ReplayIcon from '@mui/icons-material/Replay';
import Button from '@mui/material/Button';

const ErrorPage = () => {
	const history = useHistory();
	const redirect = () => {
		window.location.reload();
	}
	return (
		<div class="errorpage" style={{
			textAlign: 'center',
			background: "#24263a",
			color: "#c0c2d8",
			fontSize: '12px',
			padding: '10% 20px',
			height: '100vh'
		}}>
			<div class="iconerror">
				<svg width="66" height="66" viewBox="0 0 66 66" fill="none" xmlns="http://www.w3.org/2000/svg">
					<path d="M36.0311 6.25L57.6817 43.75C59.0289 46.0833 57.3449 49 54.6506 49H11.3494C8.65506 49 6.97113 46.0833 8.31828 43.75L29.9689 6.25C31.3161 3.91666 34.6839 3.91667 36.0311 6.25Z" fill="#CF1F25" stroke="#CF1F25" />
					<circle cx="33" cy="41" r="3" fill="#D9D9D9" />
					<path d="M30 17C30 15.3431 31.3431 14 33 14V14C34.6569 14 36 15.3431 36 17V30C36 31.6569 34.6569 33 33 33V33C31.3431 33 30 31.6569 30 30V17Z" fill="#D9D9D9" />
					<ellipse cx="33" cy="29" rx="3" ry="6" fill="#D9D9D9" />
				</svg>
			</div>
			<div class="headingerror" style={{ fontSize: '20px', margin: '10px 0 20px 0' }}>Something went wrong</div>
			<div class="paraerror" style={{ paddingBottom: '15px' }}>There was a problem processing the request. Please try again.</div>
			<div class="buttonerror" style={{
				display: 'flex',
				justifyContent: 'center',
				color: '#5d97f6',
				fontSize: '14px'
			}}>
				<Button onClick={redirect}><ReplayIcon /> <span style={{marginLeft: 8}}>Retry</span></Button>
			</div>
		</div>
	)
}

export default ErrorPage;