import * as Yup from 'yup';

export const urlRegex =
    /(http(s)?:\/\/.)?(www\.)?[-a-zA-Z0-9@:%._\+~#=]{2,256}\.[a-z]{2,6}\b([-a-zA-Z0-9@:%_\+.~#?&//=]*)/g;
// /^((http|https):\/\/)?(www.)?(?!.*(http|https|www.))[a-zA-Z0-9_-]+(\.[a-zA-Z]+)+(\/)?.([\w\?[a-zA-Z-_%\/@?]+)*([^\/\w\?[a-zA-Z0-9_-]+=\w+(&[a-zA-Z0-9_]+=\w+)*)?$/;

export const RPT_YUPSCHEMA = {
    rptCode: Yup.string().required('Please enter Report Code'),
    rptName: Yup.string().required('Please enter Report Name'),
    baseUrl: Yup.lazy(() =>
        Yup.string().when(['rptType'], (rptType: any) => {
            if (rptType == '0') {
                return Yup.string().matches(urlRegex, 'Enter a valid url').required('Please enter Power BI Base URL');
            } else {
                return Yup.string().notRequired();
            }
        })
    ),
    rptUrl: Yup.lazy(() =>
        Yup.string().when(['rptType'], (rptType: any) => {
            if (rptType == '0') {
                return Yup.string().matches(urlRegex, 'Enter a valid url').required('Please enter Power BI Report URL');
            } else {
                return Yup.string().notRequired();
            }
        })
    ),
    grpId: Yup.lazy(() =>
        Yup.string().when(['rptType'], (rptType: any) => {
            if (rptType == '0') {
                return Yup.string().required('Please enter Power BI Group ID');
            } else {
                return Yup.string().notRequired();
            }
        })
    ),
    rptId: Yup.lazy(() =>
        Yup.string().when(['rptType'], (rptType: any) => {
            if (rptType == '0') {
                return Yup.string().required('Please enter Power BI Report ID');
            } else {
                return Yup.string().notRequired();
            }
        })
    ),
    dbId: Yup.lazy(() =>
        Yup.string().when(['rptType'], (rptType: any) => {
            if (rptType == '0') {
                return Yup.string().required('Please enter Power BI Database ID');
            } else {
                return Yup.string().notRequired();
            }
        })
    ),
    rptOrdId: Yup.number().typeError('Report Order ID must be a number').required('Please enter Report Order ID')
};

export const DB_YUPSCHEMA = {
    dbCode: Yup.string().required('Please enter Dashboard Code'),
    dbName: Yup.string().required('Please enter Dashboard Name'),
    baseUrl: Yup.lazy(() =>
        Yup.string().when(['dbType'], (dbType: any) => {
            if (dbType == '0') {
                return Yup.string().matches(urlRegex, 'Enter a valid url').required('Please enter Power BI Base URL');
            } else {
                return Yup.string().notRequired();
            }
        })
    ),
    dbUrl: Yup.lazy(() =>
        Yup.string().when(['dbType'], (dbType: any) => {
            if (dbType == '0') {
                return Yup.string().matches(urlRegex, 'Enter a valid url').required('Please enter Power BI Report URL');
            } else {
                return Yup.string().notRequired();
            }
        })
    ),
    grpId: Yup.lazy(() =>
        Yup.string().when(['dbType'], (dbType: any) => {
            if (dbType == '0') {
                return Yup.string().required('Please enter Power BI Group ID');
            } else {
                return Yup.string().notRequired();
            }
        })
    ),
    rptId: Yup.lazy(() =>
        Yup.string().when(['dbType'], (dbType: any) => {
            if (dbType == '0') {
                return Yup.string().required('Please enter Power BI Report ID');
            } else {
                return Yup.string().notRequired();
            }
        })
    ),
    dbId: Yup.lazy(() =>
        Yup.string().when(['dbType'], (dbType: any) => {
            if (dbType == '0') {
                return Yup.string().required('Please enter Power BI Database ID');
            } else {
                return Yup.string().notRequired();
            }
        })
    ),
    rptOrdId: Yup.number().typeError('Report Order ID must be a number').required('Please enter Report Order ID')
};

export const MD_YUPSCHEMA = {
    mdCode: Yup.string().required('Please enter Module Code'),
    mdName: Yup.string().required('Please enter Module Name'),
    mdOrdId: Yup.number().typeError('Module Order ID must be a number').required('Please enter Module Order ID')
};

export const DNWDREPORT_YUPSCHEMA = {
    dnRpCode: Yup.string().required('Please enter Report Code'),
    dnRpOrdId: Yup.number().typeError('Report order ID must be a number').required('Please enter Report Order ID'),
    dnRpName: Yup.string().required('Please enter Report Name')
};

export const MN_YUPSCHEMA = {
    dropdownMd: Yup.string().required('Please select Module'),
    mnCode: Yup.string().required('Please enter Feature Code'),
    mnFName: Yup.string().required('Please enter Feature Name'),
    mnHName: Yup.string().required('Please enter Header Name'),
    mnOrdId: Yup.number().typeError('Menu order Id must be a number').required('Please enter Order ID'),
    mnIcon: Yup.string().required('Please enter Menu Icon File Name')
};
