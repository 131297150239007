import * as React from 'react';
import { useEffect, useRef, useState } from 'react';
import { styled, alpha } from '@mui/material/styles';
import Button from '@mui/material/Button';
import Menu, { MenuProps } from '@mui/material/Menu';
import MenuItem from '@mui/material/MenuItem';
import EditIcon from '@mui/icons-material/Edit';
import Divider from '@mui/material/Divider';
import ArchiveIcon from '@mui/icons-material/Archive';
import FileCopyIcon from '@mui/icons-material/FileCopy';
import MoreHorizIcon from '@mui/icons-material/MoreHoriz';
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';
import './index.scss';
import { AnyARecord } from 'dns';
import { useLocation } from 'react-router-dom';
import useGetState from '../../utils/hooks/useGetState';
import { STORE_KEYS } from '../../constants/apiConstants';

const StyledMenu = styled((props: MenuProps) => (
    <Menu
        elevation={0}
        anchorOrigin={{
            vertical: 'bottom',
            horizontal: 'right'
        }}
        transformOrigin={{
            vertical: 'top',
            horizontal: 'right'
        }}
        {...props}
    />
))(({ theme }) => ({
    '& .MuiPaper-root': {
        borderRadius: 6,
        marginTop: theme.spacing(1),
        minWidth: 180,
        backgroundColor: 'black',
        color: theme.palette.mode === 'light' ? 'rgb(55, 65, 81)' : theme.palette.grey[300],
        boxShadow:
            'rgb(255, 255, 255) 0px 0px 0px 0px, rgba(0, 0, 0, 0.05) 0px 0px 0px 1px, rgba(0, 0, 0, 0.1) 0px 10px 15px -3px, rgba(0, 0, 0, 0.05) 0px 4px 6px -2px',
        '& .MuiMenu-list': {
            padding: '4px 0'
        },
        '& .MuiMenuItem-root': {
            '& .MuiSvgIcon-root': {
                fontSize: 18,
                color: theme.palette.text.secondary,
                marginRight: theme.spacing(1.5)
            },
            '&:active': {
                backgroundColor: alpha(theme.palette.primary.main, theme.palette.action.selectedOpacity)
            }
        }
    }
}));

type ComponentProps = {
    click?: any;
    items: any;
    isDisabled?: boolean;
    // accessData?: any;
};

export default function CustomizedMenus(props: ComponentProps) {
    const { click, items, isDisabled = false } = props;
    const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null);
    const open = Boolean(anchorEl);
    const currentPath = useLocation();
    const state = useGetState();
    const accessData = state?.[STORE_KEYS.ROUTES_PRIVILEGES];
    const globalDrawer = state?.[STORE_KEYS.GLOBAL_DRAWER];
    const isAccess = (__data: any) => {
        return __data.filter((d: any) => d.path == currentPath?.pathname)?.[0]?.isEditable;
    };
    const handleClick = (event: React.MouseEvent<HTMLElement>) => {
        setAnchorEl(event.currentTarget);
    };
    const handleClose = () => {
        setAnchorEl(null);
    };
    const handleMenuClick = (evt: any) => {
        click(evt.target.innerText);
        handleClose();
    };

    useEffect(() => {
        if (state?.[STORE_KEYS.GLOBAL_DRAWER]?.body) {
            setAnchorEl(null)
        }
    }, [state?.[STORE_KEYS.GLOBAL_DRAWER]?.body]);

    return isAccess(accessData) ? (
        <div>
            <Button
                id="demo-customized-button"
                aria-controls={open ? 'demo-customized-menu' : undefined}
                aria-haspopup="true"
                aria-expanded={open ? 'true' : undefined}
                variant="contained"
                // disabled={!isAccess(accessData)}
                disabled={isDisabled}
                disableElevation
                onClick={handleClick}
                endIcon={<KeyboardArrowDownIcon />}
                style={{
                    backgroundColor: '#5D97F6',
                    color: 'black',
                    fontSize: '12px',
                    width: '140px',
                    height: '40px',
                    textTransform: 'capitalize',
                    letterSpacing: '0.2px',
                    fontFamily: 'Montserrat',
                    outline: 'none',
                    fontWeight: 'bold'
                }}
            >
                Create New
            </Button>
            <StyledMenu
                id="demo-customized-menu"
                MenuListProps={{
                    'aria-labelledby': 'demo-customized-button'
                }}
                anchorEl={anchorEl}
                open={open}
                onClose={handleClose}
                className="drawerDropdown"
            >
                {items.map((ele: any) => {
                    return (
                        <MenuItem onClick={(e: any) => handleMenuClick(e)} disableRipple style={{ color: '#B1C2DF' }}>
                            {/* <EditIcon /> */}
                            {/* {ele} */}
                            {ele}
                        </MenuItem>
                    );
                })}
                {/* <MenuItem onClick={(e:any)=>handleMenuClick(e)} disableRipple>
          <EditIcon />
         Module
        </MenuItem>
        <MenuItem onClick={(e:any)=>handleMenuClick(e)} disableRipple>
          <FileCopyIcon />
          Menu
        </MenuItem>
        <Divider sx={{ my: 0.5 }} />
        <MenuItem onClick={(e:any)=>handleMenuClick(e)} disableRipple>
          <ArchiveIcon />
          Feature
        </MenuItem>
        <MenuItem onClick={(e:any)=>handleMenuClick(e)} disableRipple>
          <MoreHorizIcon />
          Dashboard
        </MenuItem> */}
            </StyledMenu>
        </div>
    ) : null;
}
