import * as React from 'react';
import TextField from '@mui/material/TextField';
import { DatePicker } from '@mui/x-date-pickers/DatePicker';
import './index.scss';

const CustomDatePicker = (props: any) => {
    return (
        <DatePicker
            openTo="day"
            views={['year', 'month', 'day']}
            {...props}
            className={`custom-date-picker ${props.className}`}
            PopperProps={{
                ...props?.PopperProps,
                className: `custom-date-picker-popper ${props?.PopperProps?.className}`
            }}
            renderInput={(params: any) => <TextField {...params} autoComplete="off" />}
            minDate={props?.minDate}
            disabled={props.disabled}
        />
    );
};

export default CustomDatePicker;
