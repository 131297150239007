import { useState, useEffect } from 'react';
import './tableRow.scss';
import TextInput from '../../TextInput';
import { TableRow, TableCell, Button } from '@mui/material';
import DropDown from '../../Dropdown';
import useDebounce from '../../../hooks/useDebounce';
interface Props {
    header: any;
    row: any;
    isTableImageReqd?: boolean;
    isRadio?: boolean;
    isPermission?: boolean;
    checkDrodownSelection?: any;
    getUpdatedRowData: any;
    categories: any[],
    datatypes: any[],
    severities: any[]
}
const TableBody = (props: Props) => {

    const { row, header, getUpdatedRowData, categories, datatypes, severities } = props;
    const { MMParamKey } = row;
    const [rowFormData, setRowFormData] = useState<any>({});
    const [changedRow, setChangedRow] = useState<any>(null);
    const debouncedMachineModelName = useDebounce<string>(changedRow, 800);

    const onChangeHandler = (e: any, id: string) => {
        const updatedRow = { ...rowFormData };
        updatedRow[id] = e.target.value;
        setRowFormData(updatedRow);
        setChangedRow(updatedRow);
    }

    useEffect(() => {
        setRowFormData(row);
        setChangedRow(null);
    }, [row, setRowFormData, setChangedRow])
    // For updating parameters state
    useEffect(() => {
        if (debouncedMachineModelName)
            getUpdatedRowData(debouncedMachineModelName);
    }, [debouncedMachineModelName]);

    const orderList = header.map((ele: any) => ele.id);
    const dropDownEnable = ['DataType', 'Severity', 'Category'];

    const dropdown = { DataType: datatypes, Severity: severities, Category: categories };
    const dropdownValue = { Category: rowFormData.Category, DataType: rowFormData.DataType, Severity: rowFormData.Severity };

    const displayArray = orderList.map((ele: any, index: number) => {
        const cellKey = index + 1;
        if ((dropDownEnable.includes(ele))) {
            const mappedItems = dropdown[ele as string as keyof typeof dropdown].map((el) => {
                return { name: el[ele], code: el[ele] }
            })

            return <TableCell align="left" key={cellKey}>
                <DropDown
                    key={cellKey}
                    onChange={(e) => onChangeHandler(e, ele)}
                    className="inputDiv"
                    name="dropdownClient"
                    items={mappedItems}
                    placeHolder={ele}
                    value={dropdownValue?.[ele as string as keyof typeof dropdownValue] ?? ''}
                    helperText={''}
                />
            </TableCell>
        }

        return <TableCell key={cellKey} style={ele === 'ParamCode' ? { position: 'sticky', left: 0, zIndex: 800 } : {}}>
            <div style={ele === 'ParamCode' ? { width: '79px' } : { width: 'max-content' }}>
                <TextInput
                    readOnly={ele === 'ParamCode' ? true : false}
                    variant="outlined"
                    fullWidth
                    type="text"
                    name="modelCode"
                    value={rowFormData[ele] ?? ''}
                    autoComplete="off"
                    formInput="inputDiv"
                    onChange={(e) => onChangeHandler(e, ele)}
                    helperText={''}
                />
            </div>
        </TableCell>
    });

    return (
        <TableRow
            hover
            key={MMParamKey}
            tabIndex={-1}
            role="checkbox"
            className="tableRow"
        >
            {displayArray}
        </TableRow>
    );
};

export default TableBody;
