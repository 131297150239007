import { useState, useEffect } from "react";
import * as React from 'react';
import CustomTable from "../../../atoms/CustomTable/index";
import CustomTableToolbar from "../../../atoms/CustomTableToolbar";
import { Container, Card } from "@mui/material";
import { ACTION_CODES, STORE_KEYS } from "../../../constants/apiConstants";
import { useDispatch } from "react-redux";
import useGetState from "../../../utils/hooks/useGetState";
import { executeACGAction, updateScreenIdentifiers } from "../../../store/slice";
import PowerBIViewDrawer from "../DetailDrawer/Index";
import { models } from "powerbi-client";
import DarkTheme from '../../../../themes/Dark.json';
import Axios from 'axios';
import { ReportConfig } from "../Report";

const Dashboard = () => {

  const [page, setPage] = useState<number>(0);
  const [filterName, setFilterName] = useState<string>("");
  const [tableData, setTableData] = useState<any[]>([]);
  const [masterTableData, setMasterTableData] = useState<any[]>([]);
  const [createDrawerOpen, setCreateDrawerOpen] = useState(false);
  const [selectedRow, setSelectedRow] = useState<any>(null);
  const [selectedIndex, setSelectedIndex] = useState<number>(-1);
  const [isDashReportLoading,setIsDashReportLoading]=useState(false)

  const dispatch = useDispatch();

  const stateObj = useGetState();

  const [ReportConfiguration, setReportConfiguration] = useState<ReportConfig>({
    type: "report",
    tokenType: models.TokenType.Embed,
    permissions: models.Permissions.All,
    settings: {
      filterPaneEnabled: false,
      navContentPaneEnabled: false,
      localeSettings: {
        language: "en",
        formatLocale: "en-gb",
      },
    },
    theme: { themeJson: { DarkTheme } },
    viewMode: models.ViewMode.View,
    accessToken: '',
    embedUrl: ''
  });

  const getDashboard = () => {
    setIsDashReportLoading(true)
    dispatch(
      executeACGAction({
        payload: {
          requestType: 'GET',
          urlPath: ACTION_CODES.GET_RP_USER_DASHBOARDS
        },
        uniqueScreenIdentifier: { dashboardsLoaded: true },
        storeKey: STORE_KEYS.GET_RP_USER_DASHBOARDS
      })
    );
  };

  useEffect(() => {
    getDashboard();
  }, []);

  useEffect(() => {
    if (stateObj?.[STORE_KEYS.GET_RP_USER_DASHBOARDS]?.dashboardsLoaded) {
      const list = stateObj?.[STORE_KEYS.GET_RP_USER_DASHBOARDS]?.body?.data;
      const setData = list?.map((mod: any) => {
        const record = { ...mod };
        record.MachineModelName = record?.ReportName;
        return record;
      });

      setMasterTableData([...setData]);
      setTableData(setData);
      setIsDashReportLoading(false)
    }
  }, [stateObj?.[STORE_KEYS.GET_RP_USER_DASHBOARDS]?.dashboardsLoaded]);

  const handleChangePage = (
    event: any,
    newPage: React.SetStateAction<number>
  ) => {
    setPage(newPage);
  };

  const handleFilterByName = (event: { target: { value: React.SetStateAction<string> } }) => {

    setPage(0);

    if (event.target.value === undefined) //for clear button action
    {
      setFilterName("");
      setTableData(masterTableData);
    }
    else {
      setFilterName(event.target.value);
      const filteredArray = event.target.value.length > 0 ?
      masterTableData.filter(obj => obj.ReportName.toLowerCase().includes(String(event.target.value).toLowerCase())) :
      masterTableData;
      setTableData(filteredArray);
    }
    console.log(event.target.value, masterTableData.length);
  };

  const closeDrawer = () => {
    setCreateDrawerOpen(false);
    setSelectedRow(null);
    setSelectedIndex(-1);
    dispatch(
      updateScreenIdentifiers({
        storeKey: STORE_KEYS.RPTS_PWRBI_ACCESS_TOKEN,
        uniqueScreenIdentifier: { accesstokenLoaded: false }
      })
    );
  };

  const getPowerBIAccessToken = () => {
    dispatch(
      executeACGAction({
        payload: {
          requestType: 'POST',
          urlPath: ACTION_CODES.RPTS_PWRBI_ACCESS_TOKEN
        },
        storeKey: STORE_KEYS.RPTS_PWRBI_ACCESS_TOKEN,
        uniqueScreenIdentifier: { accesstokenLoaded: true }
      })
    );
  };

  useEffect(() => {
    if (selectedRow != null) {
      if (selectedRow.ReportType == "PowerBI") {
        getPowerBIAccessToken();
      }
      else {
        setCreateDrawerOpen(true);
      }
    }
  }, [selectedRow]);

  useEffect(() => {
    if (selectedRow != null && stateObj?.[STORE_KEYS.RPTS_PWRBI_ACCESS_TOKEN]?.accesstokenLoaded == true) {
      getPowereBIEmbedToken(stateObj?.[STORE_KEYS.RPTS_PWRBI_ACCESS_TOKEN]?.body.data);
    }

    dispatch(
      updateScreenIdentifiers({
        storeKey: STORE_KEYS.RPTS_PWRBI_ACCESS_TOKEN,
        uniqueScreenIdentifier: { accesstokenLoaded: false }
      })
    );

  }, [stateObj?.[STORE_KEYS.RPTS_PWRBI_ACCESS_TOKEN]?.accesstokenLoaded]);

  useEffect(() => {
    if (ReportConfiguration.accessToken) {
      setCreateDrawerOpen(true);
    }
  }, [ReportConfiguration.accessToken])

  const getPowereBIEmbedToken = async (auth: string) => {

    let URL = "";

    const { PBIBaseURL, PBIGroupId, PBIReportId, PBIReportURL, PBIDatasetId } = selectedRow;

    let reportUrl = PBIReportURL.replace('autoAuth=true&', '');
    
    URL = PBIBaseURL.concat(PBIGroupId, "/reports/", PBIReportId, "/GenerateToken");

    const payLoad = {
      datasets: [
        {
          id: PBIDatasetId,
        },
      ],
      reports: [
        {
          allowEdit: false,
          id: PBIReportId,
        },
      ],
      identities: [
        {
          username: stateObj?.userDtl?.EmailId,
          roles: ["RLS"],
          datasets: [PBIDatasetId],
        },
      ],
    }

    try {
      const response = await Axios.post(
        URL,
        payLoad,
        {
          headers: {
            Authorization: "Bearer " + auth,
            "Content-type": "application/json",
          },
        }
      );
      if (response.data.token) {
        setReportConfiguration({
          ...ReportConfiguration,
          embedUrl: reportUrl,
          accessToken: response.data.token
        });
      }
    } catch (err) {
      console.log(err);
    }
  };

  return (
    <div>
      <div>
        <Container
          maxWidth="xl"
          style={{
            paddingLeft: "3px",
            paddingRight: "3px",
          }}
        >
          <Card
            style={{
              borderRadius: "0px",
              backgroundColor: "#2b2d42",
              overflow: "inherit",
            }}
          >
            <CustomTableToolbar
              numSelected={0}
              filterName={filterName}
              onFilterName={handleFilterByName}
              resetInput={handleFilterByName}
              displayInput={true}
              text={{
                title: "Total Dashboards",
                subTitle: `${tableData?.length} Dashboards`,
              }}
              filterButton={false}
              customButton={false}
            />
          </Card>
        </Container>
        <CustomTable
          selectable={false}
          headers={[]}
          body={tableData}
          filteredUsers={tableData}
          filterName={""}
          selected={[]}
          order={""}
          orderBy={""}
          onRequestSort={() => { }}
          onSelectAllClick={() => { }}
          rowChange={() => { }}
          rowCount={tableData.length}
          onPageChange={handleChangePage}
          route={
            () => { console.log("asd'adasd;al") }
          }
          page={page}
          setPageNumber={setPage}
          selectRow={(rowIndex: any) => {
            setSelectedIndex(rowIndex);
            setSelectedRow(tableData[rowIndex]);
          }
          }
          isRequestPage={true}
          link={true}
          type="User_DashBoard_List"
          isLoading={isDashReportLoading}
        />
        <PowerBIViewDrawer
          handlerOpen={() => { }}
          handlerClose={closeDrawer}
          status={createDrawerOpen}
          data={selectedRow ? selectedRow : null}
          reportConfig={setReportConfiguration}
          block={true}
          type="all"
          index={selectedIndex}
        />
      </div>
    </div>
  );
};

export default Dashboard;