import { SetStateAction, useEffect, useRef, useState } from 'react';
import {
    Checkbox,
    Container,
    FormControl,
    ListItemText,
    MenuItem,
    Grid,
    Select,
    Typography,
    Box
} from '@mui/material';
import Attachments from '../../../../atoms/Attachments';
import DownArrow from '../../../../../assets/down-arrow.svg';
import Edit from '../../../../../assets/images/edit.svg';
import { Formik, Form, FormikProps, useFormikContext } from 'formik';
import * as Yup from 'yup';
import { MD_YUPSCHEMA } from './schema';
import useGetState from '../../../../utils/hooks/useGetState';
import { useDispatch } from 'react-redux';
import { executeACGAction, resetErr, updateScreenIdentifiers } from '../../../../store/slice';
import ACGButton from '../../../../atoms/Button';
import Label from '../../../../atoms/Label/index';
import TextInput from '../../../../atoms/TextInput';
import DropDown from '../../../../atoms/Dropdown';
import { fetchBlobImage } from '../../../../utils/helper';
import { convertBlobFileToBase64 } from '../../../../utils/helper';
import Axios from 'axios';
import { ACTION_CODES, STORE_KEYS, BASEURL } from '../../../../constants/apiConstants';
import { trackErrorsInNewRelic } from '../../../../utils/newRelicFuncations';

type ComponentProps = {
    drawerClose: Function;
    setCurrTab: Function;
    setReload: Function;
    setSnackbarOpts: Function;
    dtlData: any;
    selectedRow: any;
    isEdit: boolean;
    setDtlData: Function;
    setSelectedRow: Function;
    assetList: any;
    categoryList: any;
    actionTypeList: any;
    serviceRequestKey: any;
    getServiceRequestList: Function;
    fetchServiceRequestOrderDetails: Function;
};
type ModuleType = {

    scName: string | '';
    scValue: string | '';
    scDesc: string | '';
};

const CreateModule = (props: ComponentProps) => {
    const { serviceRequestKey, drawerClose, setCurrTab, setReload = () => { }, setSnackbarOpts = () => { }, dtlData, isEdit, setDtlData = () => { }, selectedRow, setSelectedRow = () => { }, assetList, categoryList, actionTypeList, getServiceRequestList = () => { }, fetchServiceRequestOrderDetails = () => { } } = props;
    const formRef = useRef<any>(null);
    const [machineKey, setMachineKey] = useState('');
    const [categoryKey, setCategoryKey] = useState('');
    const [machineDetails, setMachineDetails] = useState<any[]>([]);
    const [componentList, setComponentList] = useState<any[]>([]);
    const [subComponentList, setSubComponentList] = useState<any[]>([]);
    const [componentKey, setComponentKey] = useState('');
    const [subComponentKey, setSubComponentKey] = useState('');
    const [reportProblem, setReportProblem] = useState('');
    const [reportDescription, setReportDescription] = useState('');
    const [priorityList, setPriorityList] = useState<any[]>([]);
    const [priorityKey, setPriorityKey] = useState('');
    const [selectedModelImage, setSelectedModelImage] = useState<any>([]);
    const [formError, setFormError] = useState({ modelCode: '', modelName: '', paramsUpload: '', modelImage: '', hasError: false });
    const [validationSchema, setValidationSchema] = useState({});
    const [initialValues, setInitialValues] = useState({
        scName: '',
        scValue: '',
        scDesc: '',
    });
    const [editImage, setEditImage] = useState(false);
    const [fName, setFname] = useState('');
    const state = useGetState();
    const token = state?.[STORE_KEYS.USER_DTL].token;
    const loggedInUser = state[STORE_KEYS.USER_DTL]?.LoggedUserKey;
    const dispatch = useDispatch();
    const ITEM_HEIGHT = 48;
    const ITEM_PADDING_TOP = 8;
    const MenuProps = {
        PaperProps: {
            style: {
                maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
                width: 250
            }
        }
    };

    const options = {
        AD_SUCCESS: {
            message: `Service request ${serviceRequestKey === "" ? 'created' : 'edited'} successfully!`,
            open: true,
            type: 'success'
        },
    };

    useEffect(() => {
        if (serviceRequestKey !== '') {
            const tmpTableData = state[STORE_KEYS.GET_SERVICE_REQUEST_LIST_DETAILS]?.data;
            if (tmpTableData && tmpTableData.length) {
                setMachineKey(tmpTableData[0].Machinekey);
                getMachineDetails(state[STORE_KEYS.USER_DTL]?.LoggedUserKey, tmpTableData[0].Machinekey);
                setCategoryKey(tmpTableData[0].MaintBreakdownCategoryKey);
                setComponentKey(tmpTableData[0].MaintComponentKey);
                getSubComponents(tmpTableData[0].MaintComponentKey);
                setSubComponentKey(tmpTableData[0].MaintSubComponentKey);
                setSelectedModelImage(tmpTableData[0].ServiceRequestImagePath);
                setFname(tmpTableData[0].ImagePath);
                setReportProblem(tmpTableData[0].ReportProblem);
                setReportDescription(tmpTableData[0].ProblemDesc);
                setPriorityKey(tmpTableData[0].MaintPriorityKey);
            }
        }
    }, [serviceRequestKey])

    const isDisabled = machineKey === '' || categoryKey === '' || componentKey === '' || subComponentKey === '' || reportProblem === "" ||  !/\S/.test(reportProblem) || reportDescription === "" ||  !/\S/.test(reportDescription) || priorityKey === '' ? true : false;
    const renderButtons = () => {
        return (
            <div className={'btns noWrap'}>
                <ACGButton
                    name={'Cancel'}
                    handleClick={drawerClose}
                    secondary={true}
                    className={'first-btn'}
                    type="button"
                    variant="contained"
                />
                <ACGButton
                    name={'Submit'}
                    handleClick={() => { handleSubmit() }}
                    type="submit"
                    className="second-btn"
                    variant="contained"
                    disabled={isDisabled ? true : false}
                />
            </div>
        );
    };
    useEffect(() => {
        setValidationSchema(Yup.object().shape(MD_YUPSCHEMA));
  
        Axios({
            url: BASEURL + '/' + ACTION_CODES.GET_SERVICE_REQUEST_PRIORITIES,
            method: 'get',
            headers: {
                Authorization: 'Bearer ' + token
            }
        })
            .then((response) => {
                if (response.status == 200 && response?.data) {
                    const tmpTableData = response.data.data;
                    setPriorityList([
                        ...tmpTableData?.map((item: any) => ({ code: item.MaintPriorityKey, name: item.MaintPriorityName }))
                    ]);
                }
            })
            .catch((error) => {
             trackErrorsInNewRelic({error: error})

                return {
                    status: error.response.status
                };
            });

    }, []);
    const handleSubmit = async () => {
        const sp: any[] = [];
        let strImage;
        if (selectedModelImage.length > 0 && typeof (selectedModelImage) !== "string") {
            const base64Image = await convertBlobFileToBase64(selectedModelImage[0]);
            strImage = base64Image?.replace(/^data:image\/[a-z]+;base64,/, "");
        }
        const bundle = {
            payload: {
                urlPath: ACTION_CODES.ADD_SERVICE_REQUEST,
                requestType: 'POST',
                reqObj: {
                    userId: loggedInUser,
                    machineKey: machineKey,
                    category: categoryKey,
                    subComponentKey: subComponentKey,
                    reportProblem: reportProblem.replace(/'/g, "''"),
                    reportDesc: reportDescription,
                    priorityKey: priorityKey,
                    image: strImage ? strImage : null,
                    key: serviceRequestKey !== '' ? serviceRequestKey : null,
                    type: serviceRequestKey !== '' ? 'Edit' : 'Add',
                    fName: serviceRequestKey ? fName.replace(/'/g, "''") : null,
                    updateRequire: fName === selectedModelImage ? false : true
                }
            },
            uniqueScreenIdentifier: { serviceReqmoduleAdded: true },
            storeKey: STORE_KEYS.ADD_SERVICE_REQUEST
        };
        dispatch(executeACGAction(bundle));
    };

    const handleSnackbarError = (err: any) => {
        const errorMsg = err?.message || 'Internal Server error';
        const snackbarError = {
            message: errorMsg,
            type: 'reject',
            open: true
        };
        setSnackbarOpts(snackbarError);
    };

    useEffect(() => {
        if (state?.[STORE_KEYS.ADD_SERVICE_REQUEST]?.serviceReqmoduleAdded) {
            dispatch(
                updateScreenIdentifiers({
                    storeKey: STORE_KEYS.ADD_SERVICE_REQUEST,
                    uniqueScreenIdentifier: {
                        serviceReqmoduleAdded: false
                    }
                })
            );
        }
    }, [state?.[STORE_KEYS.ADD_SERVICE_REQUEST]?.data]);

    useEffect(() => {
        if (state?.[STORE_KEYS.ADD_SERVICE_REQUEST]?.serviceReqmoduleAdded) {
            if (state?.[STORE_KEYS.ADD_SERVICE_REQUEST]?.status === "Success") {
                setReload(true);
                setCurrTab(1);
                setSnackbarOpts(options.AD_SUCCESS);
                drawerClose();
                serviceRequestKey !== '' ? fetchServiceRequestOrderDetails(serviceRequestKey) : undefined;
                getServiceRequestList();
            }
            dispatch(
                updateScreenIdentifiers({
                    storeKey: STORE_KEYS.ADD_SERVICE_REQUEST,
                    uniqueScreenIdentifier: {
                        serviceReqmoduleAdded: false
                    }
                })
            );
        }
    }, [state?.[STORE_KEYS.ADD_SERVICE_REQUEST]?.serviceReqmoduleAdded]);

    useEffect(() => {
        if (state?.err) {
            handleSnackbarError(state?.err);
            dispatch(resetErr());
        }
    }, [state?.err]);

    const getMachineDetails = (userId: any, mKey: any) => {

        const payload = {
            userId: loggedInUser,
            machineKey: mKey
        };
        Axios({
            url: BASEURL + '/' + ACTION_CODES.GET_BREAKDOWN_MACHINE_DETAILS,
            method: 'post',
            data: payload,
            headers: {
                Authorization: 'Bearer ' + token
            }
        })
            .then((response) => {
                if (response.status == 200 && response?.data) {
                    setMachineDetails(response.data.data);
                    getComponent(response.data.data[0].MachineModelKey);
                }
            })
            .catch((error) => {
                return {
                    status: error.response.status
                };
            });

    }

    const handleChangeAsset = (event: { target: { value: any; }; }) => {
        setMachineKey(event.target.value);
        getMachineDetails(state[STORE_KEYS.USER_DTL]?.LoggedUserKey, event.target.value);
    }

    const getComponent = (mmKey: Number) => {
        const payload = {
            userId: loggedInUser,
            machineModelKey: mmKey
        };
        Axios({
            url: BASEURL + '/' + ACTION_CODES.GET_BREAKDOWN_COMPONENTS,
            method: 'post',
            data: payload,
            headers: {
                Authorization: 'Bearer ' + token
            }
        })
            .then(async (response) => {
                if (response.status == 200 && response?.data) {
                    const tmpTableData = response.data.data;
                    setComponentList([
                        ...tmpTableData?.map((item: any) => ({ code: item.MaintComponentKey, name: item.MaintComponentName }))
                    ]);
                }
            })
            .catch((error) => {
             trackErrorsInNewRelic({error: error})

                return {
                    status: error.response.status
                };
            });
    }

    const getSubComponents = (cKey: number) => {
        const payload = {
            userId: loggedInUser,
            componentKey: cKey
        };
        Axios({
            url: BASEURL + '/' + ACTION_CODES.GET_BREAKDOWN_SUB_COMPONENTS,
            method: 'post',
            data: payload,
            headers: {
                Authorization: 'Bearer ' + token
            }
        })
            .then(async (response) => {
                if (response.status == 200 && response?.data) {
                    const tmpTableData = response.data.data;
                    setSubComponentList([
                        ...tmpTableData?.map((item: any) => ({ code: item.MaintSubComponentKey, name: item.MaintSubComponentName }))
                    ]);
                }
            })
            .catch((error) => {
             trackErrorsInNewRelic({error: error})

                return {
                    status: error.response.status
                };
            });
    }

    const handleChangeCategory = (event: { target: { value: any; }; }) => {
        setCategoryKey(event.target.value);
    }

    const handleChangeComponent = (event: { target: { value: any; }; }) => {
        setSubComponentKey('');
        setComponentKey(event.target.value);
        getSubComponents(event.target.value);
    }

    const handleChangeSubComponent = (event: { target: { value: any; }; }) => {
        setSubComponentKey(event.target.value);
    }

    const handleChangePriorityType = (event: { target: { value: any; }; }) => {
        setPriorityKey(event.target.value);
    }

    const DropDownIcon = (prop: any) => (
        <img style={{ marginRight: '5px', marginTop: '-2px' }} src={DownArrow} {...prop} />
    );

    return (
        <Container className="createReport">
            <Formik
                initialValues={initialValues}
                validationSchema={validationSchema}
                onSubmit={(values: any) => {
                    // handleSubmit(values);
                }}
                validateOnBlur={false}
                validateOnChange={false}
                innerRef={formRef}
            >
                {(formikprops: FormikProps<ModuleType>) => {
                    const { values, handleChange, handleBlur, errors, touched, setFieldValue } = formikprops;
                    return (
                        <Form>
                            <Label label="Asset ID &nbsp; *" className={'labelDiv'} />
                            <DropDown
                                onChange={handleChangeAsset}
                                className="inputDiv"
                                name="dropdownModel"
                                items={assetList}
                                placeHolder="Select Asset ID"
                                value={machineKey}
                            // helperText={
                            //     errors.dropdownModel && touched.dropdownModel ? errors.dropdownModel : ''
                            // }
                            />
                            {machineKey !== '' ?
                                <>
                                    <Label label="Asset Details" className={'labelDiv'} />
                                    <img
                                        style={{
                                            padding: 10, maxHeight: "200px",
                                            objectFit: "contain",
                                            width: "100%"
                                        }}
                                        src={
                                            machineDetails[0]?.MachineModelName ? fetchBlobImage(machineDetails[0]?.MachineModelName + ".png").url : ""
                                        } alt="No image" />
                                    <TextInput
                                        variant="outlined"
                                        autoFocus
                                        fullWidth
                                        type="text"
                                        name="scName"
                                        disabled={true}
                                        value={"Model: " + machineDetails[0]?.MachineModelName}
                                        autoComplete="off"
                                        formInput="inputDiv"
                                        maxLength={300}
                                    />
                                    <TextInput
                                        variant="outlined"
                                        autoFocus
                                        fullWidth
                                        type="text"
                                        name="scName"
                                        disabled={true}
                                        value={"Year of purchase: " + machineDetails[0]?.YearOfPurchase}
                                        autoComplete="off"
                                        formInput="inputDiv"
                                    />
                                    <TextInput
                                        variant="outlined"
                                        autoFocus
                                        fullWidth
                                        type="text"
                                        name="scName"
                                        disabled={true}
                                        value={"Warranty info: " + machineDetails[0]?.WarrantyInfo}
                                        autoComplete="off"
                                        formInput="inputDiv"
                                    />
                                    <TextInput
                                        variant="outlined"
                                        autoFocus
                                        fullWidth
                                        type="text"
                                        name="scName"
                                        disabled={true}
                                        value={"Current AMC status: " + machineDetails[0]?.CurrentAMCStatus}
                                        autoComplete="off"
                                        formInput="inputDiv"
                                    />
                                    <Label label="Breakdown Category &nbsp; *" className={'labelDiv'} />
                                    <DropDown
                                        onChange={handleChangeCategory}
                                        className="inputDiv"
                                        name="dropdownModel"
                                        items={categoryList}
                                        placeHolder="Select Breakdown Category"
                                        value={categoryKey}
                                    // helperText={
                                    //     errors.dropdownModel && touched.dropdownModel ? errors.dropdownModel : ''
                                    // }
                                    />

                                    <Label label="Select Component &nbsp; *" className={'labelDiv'} />
                                    <DropDown
                                        onChange={handleChangeComponent}
                                        className="inputDiv"
                                        name="dropdownModel"
                                        items={componentList}
                                        placeHolder="Select Component"
                                        value={componentKey}
                                    />
                                    <Label label="Select Sub Component &nbsp; *" className={'labelDiv'} />
                                    <DropDown
                                        onChange={handleChangeSubComponent}
                                        className="inputDiv"
                                        name="dropdownModel"
                                        items={subComponentList}
                                        placeHolder="Select Sub Component"
                                        value={subComponentKey}
                                    />
                                    <Label label="Report Problem &nbsp; *" className={'labelDiv'} />
                                    <TextInput
                                        variant="outlined"
                                        fullWidth
                                        type="text"
                                        name="gtId"
                                        value={reportProblem}
                                        autoComplete="off"
                                        formInput="inputDiv"
                                        placeholder='Report Problem'
                                        onChange={(e: any) => {
                                            setReportProblem(e.target.value)
                                        }}
                                        maxLength={300}
                                    />
                                    <Label label="Problem Description &nbsp; *" className={'labelDiv'} />
                                    <TextInput
                                        variant="outlined"
                                        fullWidth
                                        type="text"
                                        name="gtId"
                                        value={reportDescription}
                                        autoComplete="off"
                                        formInput="inputDiv"
                                        placeholder='Problem Description'
                                        onChange={(e: any) => {
                                            setReportDescription(e.target.value)
                                        }}
                                        maxLength={300}
                                    />
                                    <Label label="Priority &nbsp; *" className={'labelDiv'} />
                                    <DropDown
                                        onChange={handleChangePriorityType}
                                        className="inputDiv"
                                        name="dropdownModel"
                                        items={priorityList}
                                        placeHolder="Select Priority"
                                        value={priorityKey}
                                    />
                                    <Grid item xs={3} lg={1}>
                                        {serviceRequestKey === '' ?
                                            <>
                                                <Label label="Upload Image" className={'labelDiv'} />
                                                <Attachments
                                                    viewOnly={false}
                                                    setError={selectedModelImage}
                                                    isSetFile={setSelectedModelImage}
                                                />
                                                {selectedModelImage?.length === 0 ? <div>
                                                    {formError.modelImage &&
                                                        <Typography sx={{ mt: '2%', color: '#F06868', fontSize: '12px' }}> {formError.modelImage}</Typography>
                                                    }
                                                </div> : null}
                                            </>
                                            :
                                            editImage
                                                ?
                                                <Grid item xs={3} lg={1}>
                                                    <Label label="Upload Image" className={'labelDiv'} />
                                                    <Attachments
                                                        viewOnly={false}
                                                        setError={selectedModelImage}
                                                        isSetFile={setSelectedModelImage}
                                                    />
                                                </Grid>
                                                :
                                                <Box className="attachment_wrapper" style={{ marginTop: 30 }}>
                                                    {<img onClick={() => setEditImage(true)} src={Edit} alt="X" className="closeBtn" />}
                                                    <>
                                                        <img
                                                            src={selectedModelImage ? fetchBlobImage(selectedModelImage).url : undefined}
                                                            alt={'avtar'}
                                                        />

                                                    </>
                                                </Box>

                                        }
                                    </Grid>
                                    <Typography variant="body1" sx={{color:"#ffff", marginTop: 2}}>Note: * fields are mandatory! </Typography>
                                </>
                                : null}

                            {renderButtons()}
                        </Form>
                    );
                }}
            </Formik>
        </Container>
    );
};
export default CreateModule;
