import React, { useState, useEffect } from 'react';
import { useDispatch } from 'react-redux';
import CustomTable from '../../../../atoms/CustomTable/index';
import CustomTableToolbar from '../../../../atoms/CustomTableToolbar';
import { Container, Card } from '@mui/material';
import { ACTION_CODES, STORE_KEYS } from '../../../../constants/apiConstants';
import { executeACGAction, resetErr, updateScreenIdentifiers } from '../../../../store/slice';
import useGetState from '../../../../utils/hooks/useGetState';
import { format12HrTime } from '../../../../utils/formatTime';
import { applySortFilter } from '../../../../utils/helper';
import DetailDrawer from '../../../../atoms/DetailDrawer';
import '../index.scss';
import SnackbarAlert from '../../../../atoms/Snackbar';
import AlertBox from '../../../../atoms/AlertBox';

const TABLE_HEAD = [
    { id: 'PlantName', label: 'Plant Name', alignRight: false },
    { id: 'CustomerName', label: 'Client', alignRight: false },
    { id: 'LocationName', label: 'Location', alignRight: false },
    { id: 'CountryName', label: 'Country', alignRight: false },
    { id: 'TotalMachines', label: 'Machines', alignRight: false },
    { id: 'ShiftStartTime', label: 'Shift Starts', alignRight: false },
    { id: 'Action', label: 'Action', alignLeft: true }
];

const options = {
    DEFAULT: {
        message: '',
        open: false,
        type: ''
    },
    RM_SUCCESS: {
        message: 'Plant deleted successfully!',
        open: true,
        type: 'success'
    }
};

type Anchor = 'right';

type Props = {
    getClientSummary: any;
    dtlProps: any;
    setReload: Function;
    reload: Boolean;
};

const Plants = (props: Props) => {
    const { getClientSummary, dtlProps, setReload = () => {}, reload = false } = props;
    const dispatch = useDispatch();
    const [drawerState, setDrawerState] = useState({
        right: false
    });
    const [page, setPage] = useState<number>(0);
    const [order, setOrder] = useState('desc');
    const [orderBy, setOrderBy] = useState('PlantName');
    const [selected, setSelected] = useState<any>([]);
    const [filterName, setFilterName] = useState<string>('');
    const [tableData, setTableData] = useState<any[]>([]);
    const state = useGetState();
    const [dtlDrawerOpen, setDtlDrawerOpen] = useState(false);
    const [plntText, setPlntText] = useState<string>('');
    const [isPlantLoading,setIsPlantLoading]=useState(false)
    const resetPlntText = () => {
        setPlntText('');
    };
    const closeDtlDrawer = () => {
        setDtlDrawerOpen(false);
    };

    const getPlantList = () => {
        setIsPlantLoading(true)
        dispatch(
            executeACGAction({
                payload: {
                    requestType: 'GET',
                    urlPath: ACTION_CODES.GET_PLANTS
                },
                storeKey: STORE_KEYS.PLANT_LIST
            })
        );
    };

    useEffect(getPlantList, []);

    useEffect(() => {
        if (reload) {
            getClientSummary();
            getPlantList();
            setReload(false);
        }
    }, [reload]);

    useEffect(() => {
        const tmpTableData = state[STORE_KEYS.PLANT_LIST]?.body?.data;
        if(!tmpTableData){
            return
        }
        tmpTableData &&
            setTableData(
                tmpTableData.map((item: any) => ({
                    ...item
                    // ShiftStartTime: format12HrTime(item.ShiftStartTime)
                }))
            );
            setIsPlantLoading(false)
    }, [state[STORE_KEYS.PLANT_LIST]?.body?.data]);

    useEffect(() => {
        if (state[STORE_KEYS.DELETE]?.rowDeleted) {
            getPlantList();
            getClientSummary();
            dispatch(
                updateScreenIdentifiers({
                    storeKey: STORE_KEYS.DELETE,
                    uniqueScreenIdentifier: {
                        rowDeleted: false
                    }
                })
            );
        }
    }, [state[STORE_KEYS.DELETE]?.rowDeleted]);

    const handleRequestSort = (event: any, property: any) => {
        setFilterName('');
        const isAsc = orderBy === event && order === 'asc';
        setOrder(isAsc ? 'desc' : 'asc');
        setOrderBy(event);
    };

    const handleSelectAllClick = (event: any) => {
        if (event.target.checked) {
            const newSelecteds = [];
            for (let i = 0; i < tableData.length; i++) {
                newSelecteds.push(tableData[i].id);
            }
            const toRemove = tableData.slice(page, tableData.length);
            setSelected(newSelecteds);
            return;
        }
        setSelected([]);
    };

    const handleClick = (requestedBy: string) => {
        const selectedIndex = selected.indexOf(requestedBy);
        let newSelected: any[] | ((prevState: unknown[]) => unknown[]) = [];
        if (selectedIndex === -1) {
            newSelected = newSelected.concat(selected, requestedBy);
        } else if (selectedIndex === 0) {
            newSelected = newSelected.concat(selected.slice(1));
        } else if (selectedIndex === selected.length - 1) {
            newSelected = newSelected.concat(selected.slice(0, -1));
        } else if (selectedIndex > 0) {
            newSelected = newSelected.concat(selected.slice(0, selectedIndex), selected.slice(selectedIndex + 1));
        }
        setSelected(newSelected);
    };

    const toggleDrawer = (anchor: Anchor, open: boolean) => (event: React.KeyboardEvent | React.MouseEvent) => {
        if (
            event.type === 'keydown' &&
            ((event as React.KeyboardEvent).key === 'Tab' || (event as React.KeyboardEvent).key === 'Shift')
        ) {
            return;
        }
        setDrawerState({ ...drawerState, [anchor]: open });
    };

    const handleFilterByName = (event: { target: { value: string } }) => {
        setOrderBy('PlantName');
        setPage(0)
        setFilterName(event.target.value);
    };

    const filteredList = applySortFilter(tableData, order, filterName, orderBy);

    const handleChangePage = (event: any, newPage: React.SetStateAction<number>) => {
        setPage(newPage);
    };

    const showRow = (event: any) => {};

    const resetTableSearch = () => {
        setFilterName("");
        setOrderBy('PlantName');
        setPage(0)
    }

    const handleRoute = (row: any, ele: any) => {
        if (ele === 'Action') {
            dispatch(
                executeACGAction({
                    payload: {
                        requestType: 'GET',
                        urlPath: `${ACTION_CODES.GET_PLANT_LINES}/${row?.PlantKey}`
                    },
                    uniqueScreenIdentifier: { pltLinesRecd: true },
                    storeKey: STORE_KEYS.PLANT_LINES
                })
            );
            dtlProps?.setDtlData(row);
        }
    };
    useEffect(() => {
        if (state?.[STORE_KEYS.PLANT_LINES]?.pltLinesRecd) {
            dtlProps?.setDtlData({ ...dtlProps?.dtlData, lines: state?.[STORE_KEYS.PLANT_LINES]?.body?.data });

            dispatch(
                updateScreenIdentifiers({
                    storeKey: STORE_KEYS.PLANT_LINES,
                    uniqueScreenIdentifier: {
                        pltLinesRecd: false
                    }
                })
            );
            setDtlDrawerOpen(true);
        }
    }, [state?.[STORE_KEYS.PLANT_LINES]?.pltLinesRecd]);

    const [snackbarOptions, setSnackbarOptions] = useState(options.DEFAULT);
    const [removeBxOpen, setRemoveBxOpen] = useState(false);
    const [rmPlnt, setRmPlnt] = useState(false);
    const [plntKey, setPlntKey] = useState<any>();
    const closeRmBx = () => {
        setRemoveBxOpen(false);
    };
    const removeFn = (ele: any) => {
        setPlntKey(ele?.PlantKey);
        setRemoveBxOpen(true);
    };
    useEffect(() => {
        if (rmPlnt) {
            closeRmBx();
            dispatch(
                executeACGAction({
                    payload: {
                        requestType: 'DELETE',
                        urlPath: `${ACTION_CODES.DELETE_PLANT}/${plntKey}`
                    },
                    uniqueScreenIdentifier: {
                        rowDeleted: true
                    },
                    storeKey: STORE_KEYS.DELETE
                })
            );
            setRmPlnt(false);
        }
    }, [rmPlnt]);

    useEffect(() => {
        if (state?.[STORE_KEYS.DELETE]?.rowDeleted) {
            if (state?.[STORE_KEYS.DELETE]?.statusCode === 200) {
                setSnackbarOptions(options.RM_SUCCESS);
                closeDtlDrawer();
                getPlantList();
                getClientSummary();
            }
            dispatch(
                updateScreenIdentifiers({
                    storeKey: STORE_KEYS.DELETE,
                    uniqueScreenIdentifier: {
                        rowDeleted: false
                    }
                })
            );
        }
    }, [state?.[STORE_KEYS.DELETE]?.rowDeleted]);

    const handleSnackbarError = (err: any) => {
        const errorMsg = err?.message || 'Internal Server error';
        const snackbarError = {
            message: errorMsg,
            type: 'reject',
            open: true
        };
        setSnackbarOptions(snackbarError);
    };

    useEffect(() => {
        if (state?.err) {
            handleSnackbarError(state?.err);
            dispatch(resetErr());
        }
    }, [state?.err]);

    const closeSnackbar = () => setSnackbarOptions(options.DEFAULT);

    return (
        <div>
            <div>
                <SnackbarAlert options={snackbarOptions} handleClose={closeSnackbar} />
                <Container
                    maxWidth="xl"
                    style={{
                        paddingLeft: '3px',
                        paddingRight: '3px'
                    }}
                >
                    <Card
                        style={{
                            borderRadius: '0px',
                            backgroundColor: '#2b2d42',
                            overflow: 'inherit'
                        }}
                    >
                        <CustomTableToolbar
                            drawerOpen={toggleDrawer('right', true)}
                            numSelected={selected?.length}
                            filterName={filterName}
                            onFilterName={handleFilterByName}
                            resetInput={resetTableSearch}
                            displayInput={true}
                            text={{
                                title: 'Plant List',
                                subTitle: `${tableData.length} plants`
                            }}
                            filterButton={false}
                            customButton={false}
                        />
                    </Card>
                </Container>
                <CustomTable
                    selectable={false}
                    headers={TABLE_HEAD}
                    body={tableData}
                    isLoading={isPlantLoading}
                    filteredUsers={filteredList}
                    filterName={filterName}
                    selected={selected}
                    order={order}
                    orderBy={orderBy}
                    onRequestSort={handleRequestSort}
                    onSelectAllClick={handleSelectAllClick}
                    rowChange={(event: string) => handleClick(event)}
                    rowCount={filteredList?.length}
                    onPageChange={handleChangePage}
                    page={page}
                    setPageNumber={setPage}
                    selectRow={showRow}
                    route={handleRoute}
                    isRequestPage={true}
                    link={true}
                    removeFn={removeFn}
                />
            </div>
            <AlertBox
                isOpen={removeBxOpen}
                handleClose={closeRmBx}
                leftBtnPress={closeRmBx}
                rightBtnPress={() => setRmPlnt(true)}
                modalHeader="Delete Plant"
                modalContent="Are you sure you want to delete plant?"
            />
            <DetailDrawer hasImage={false}
                handlerOpen={() => {}}
                handlerClose={closeDtlDrawer}
                status={dtlDrawerOpen}
                data={{
                    details: {
                        ...dtlProps?.dtlData,
                        plntText: plntText,
                        setPlntText: (e: any) => setPlntText(e.target.value),
                        resetPlntText: resetPlntText
                    }
                }}
                type={'Plant'}
                editFn={() => {
                    dtlProps?.setCreateDrawerOpen(true);
                    dtlProps?.setCreateValue('Plant');
                    dtlProps?.setDrawerHeader('Edit');
                }}
                deleteFn={() => removeFn(dtlProps?.dtlData)}
            />
        </div>
    );
};

export default Plants;
