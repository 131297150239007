import axios from 'axios';
import { ACTION_CODES, STORE_KEYS, BASEURL } from '../../../app/constants/apiConstants';

class RuleEngineService {

    getRuleEngineCustomers(req:any) {
        var data = {
            "userId":req.userId
        };
        return axios
        .post(ACTION_CODES.API_RULE_ENGINE_CUSTOMERS,data, {
            headers: {
                Authorization: 'Bearer ' + req?.token
            }
        })
        .then(response => {
            return {
                status: response.status,
                data: response.data
            }
        })
        .catch(error => { 
            
            return {
                status: error.response.status
            }
        })
    }

    getRuleEngineCustomerModels(req:any) {
        var data = {
            "userId":req.userId,
            "customerKey": req.customerKey
        };
        return axios
        .post(ACTION_CODES.API_RULE_ENGINE_CUSTOMER_MODELS,data, {
            headers: {
                Authorization: 'Bearer ' + req?.token
            }
        })
        .then(response => {
            return {
                status: response.status,
                data: response.data
            }
        })
        .catch(error => { 
            
            return {
                status: error.response.status
            }
        })
    }

    getRuleEnginePlants(req:any) {
        var data = {
            "userId":req.userId,
            "customerKey":req.customerKey
        };
        return axios
        .post(ACTION_CODES.API_RULE_ENGINE_PLANTS,data, {
            headers: {
                Authorization: 'Bearer ' + req?.token
            }
        })
        .then(response => {
            return {
                status: response.status,
                data: response.data
            }
        })
        .catch(error => { 
            
            return {
                status: error.response.status
            }
        })
    }

    getNotificationGroups(req:any) {
        var data = {
            "userId":req.userId,
            "customerId":-1
        };
        return axios
        .post(ACTION_CODES.API_RULE_ENGINE_NOTIFICATION_GROUPS,data, {
            headers: {
                Authorization: 'Bearer ' + req?.token
            }
        })
        .then(response => {
            return {
                status: response.status,
                data: response.data
            }
        })
        .catch(error => { 
            
            return {
                status: error.response.status
            }
        })
    }

    createNotificationGroup(req:any) {
        var data = {
            "userId":req.userId,
            "customerId":req.customerKey,
            "grpName": req.grpName,
            "grpDescription": req.grpDescription,
            "type": req.type,
            "grpKey": req.grpKey,
        };
        return axios
        .post(ACTION_CODES.API_RULE_ENGINE_CREATE_NOTIFICATION_GROUP,data, {
            headers: {
                Authorization: 'Bearer ' + req?.token
            }
        })
        .then(response => {
            return {
                status: response.status,
                data: response.data
            }
        })
        .catch(error => { 
            
            return {
                status: error.response.status
            }
        })
    }

    getPortalUserList(req:any) {
        var data = {
            "userId":req.userId,
            "customerId":req.customerKey,
        };
        return axios
        .post(ACTION_CODES.API_RULE_ENGINE_PORTAL_USERS,data, {
            headers: {
                Authorization: 'Bearer ' + req?.token
            }
        })
        .then(response => {
            return {
                status: response.status,
                data: response.data
            }
        })
        .catch(error => { 
            
            return {
                status: error.response.status
            }
        })
    }

    deleteNotificationGM(req:any) {
        var data = {
            "userId":req.userId,
            "tableName":req.table,
            "grpKey":req.key
        };
        return axios
        .post(ACTION_CODES.API_RULE_ENGINE_DELETE_NMGROUP,data, {
            headers: {
                Authorization: 'Bearer ' + req?.token
            }
        })
        .then(response => {
            return {
                status: response.status,
                data: response.data
            }
        })
        .catch(error => { 
            
            return {
                status: error.response.status
            }
        })
    }

    addMemberToGroup(req:any) {
        var data = {
            "userId":req.userId,
            "type":req.type,
            "userName":req.userName,
            "userEmail": req.userEmail,
            "userKey": req.userKey,
            "grpKey": req.grpKey
        };
        return axios
        .post(ACTION_CODES.API_RULE_ENGINE_ADD_MEMBER,data, {
            headers: {
                Authorization: 'Bearer ' + req?.token
            }
        })
        .then(response => {
            return {
                status: response.status,
                data: response.data
            }
        })
        .catch(error => { 
            
            return {
                status: error.response.status
            }
        })
    }

    getGroupMemberList(req:any) {
        var data = {
            "grpKey": req.grpKey
        };
        return axios
        .post(ACTION_CODES.API_RULE_ENGINE_LIST_MEMBERS,data, {
            headers: {
                Authorization: 'Bearer ' + req?.token
            }
        })
        .then(response => {
            return {
                status: response.status,
                data: response.data
            }
        })
        .catch(error => { 
            
            return {
                status: error.response.status
            }
        })
    }

    getAlertList(req:any) {
        var data = {
            "userId": req.userId,
            "keys": req.keys,
            "startDate": req.startDate,
            "endDate": req.endDate
        };
        return axios
        .post(ACTION_CODES.API_RULE_ENGINE_ALERT_LIST,data, {
            headers: {
                Authorization: 'Bearer ' + req?.token
            }
        })
        .then(response => {
            return {
                status: response.status,
                data: response.data
            }
        })
        .catch(error => { 
            
            return {
                status: error.response.status
            }
        })
    }

    getPresetAlertList(req:any) {
        var data = {
            "userId": req.userId,
            "customerKey": req.customerKey,
            "machineModelKey": req.machineModelKey,
            "type": req.type
        };
        return axios
        .post(ACTION_CODES.API_RULE_ENGINE_PRESET_ALERT_LIST,data, {
            headers: {
                Authorization: 'Bearer ' + req?.token
            }
        })
        .then(response => {
            return {
                status: response.status,
                data: response.data
            }
        })
        .catch(error => { 
            
            return {
                status: error.response.status
            }
        })
    }

    getMachineList(req:any) {
        var data = {
            "userId": req.userId,
            "customerKey": req.customerKey,
            "machineModelKey": req.machineModelKey
        };
        return axios
        .post(ACTION_CODES.API_RULE_ENGINE_MACHINE_NAME_LIST,data, {
            headers: {
                Authorization: 'Bearer ' + req?.token
            }
        })
        .then(response => {
            return {
                status: response.status,
                data: response.data
            }
        })
        .catch(error => { 
            
            return {
                status: error.response.status
            }
        })
    }
    
    getRecipeList(req:any) {
        var data = {
            "userId": req.userId,
            "customerKey": req.customerKey,
            "machineModelKey": req.machineModelKey,
            "machineKeys": req.machineKeys
        };
        return axios
        .post(ACTION_CODES.API_RULE_ENGINE_RECIPE_LIST,data, {
            headers: {
                Authorization: 'Bearer ' + req?.token
            }
        })
        .then(response => {
            return {
                status: response.status,
                data: response.data
            }
        })
        .catch(error => { 
            
            return {
                status: error.response.status
            }
        })
    }

    getRuleEngineSeverityList(req:any) {
        return axios
        .get(ACTION_CODES.API_RULE_ENGINE_SEVERITY_LIST, {
            headers: {
                Authorization: 'Bearer ' + req?.token
            }
        })
        .then(response => {
            return {
                status: response.status,
                data: response.data
            }
        })
        .catch(error => { 
            
            return {
                status: error.response.status
            }
        })
    }

    getRuleEngineTimeDuration(req:any) {
        var data = {
            "userId": req.userId
        };
        return axios
        .post(ACTION_CODES.API_RULE_ENGINE_TIME_DURATION,data, {
            headers: {
                Authorization: 'Bearer ' + req?.token
            }
        })
        .then(response => {
            return {
                status: response.status,
                data: response.data
            }
        })
        .catch(error => { 
            
            return {
                status: error.response.status
            }
        })
    }

    getRuleEngineCustomerNotificationGroups(req:any) {
        var data = {
            "userId": req.userId,
            "customerKey": req.customerKey
        };
        return axios
        .post(ACTION_CODES.API_RULE_ENGINE_CUSTOMER_NGROUPS,data, {
            headers: {
                Authorization: 'Bearer ' + req?.token
            }
        })
        .then(response => {
            return {
                status: response.status,
                data: response.data
            }
        })
        .catch(error => { 
            
            return {
                status: error.response.status
            }
        })
    }

    createRuleEnginePresetRule(req:any) {
        var data = {
            "userId": req.userId,
            "customerKey": req.customerKey,
            "machineModelKey":req.machineModelKey,
            "presetRuleKey":req.presetRuleKey,
            "machineKeys":req.machineKeys,
            "recipeKeys":req.recipeKeys,
            "severityKey":req.severityKey,
            "recommendedNotes":req.recommendedNotes,
            "duration":req.duration,
            "alertType":req.alertType,
            "notifiyGroups":req.notifiyGroups,
            "subject":req.subject,
            "type":req.type 
        };
        return axios
        .post(ACTION_CODES.API_RULE_ENGINE_CREATE_PRESET_ALERT,data, {
            headers: {
                Authorization: 'Bearer ' + req?.token
            }
        })
        .then(response => {
            return {
                status: response.status,
                data: response.data
            }
        })
        .catch(error => { 
            
            return {
                status: error.response.status
            }
        })
    }

    getAllPresetRuleConditions(req:any) {
        var data = {
            "userId": req.userId
        };
        return axios
        .post(ACTION_CODES.API_RULE_ENGINE_PRESET_RULE_CONDITIONS,data, {
            headers: {
                Authorization: 'Bearer ' + req?.token
            }
        })
        .then(response => {
            return {
                status: response.status,
                data: response.data
            }
        })
        .catch(error => { 
            
            return {
                status: error.response.status
            }
        })
    }

    getRuleEnginePresetAlertDetails(req:any) {
        var data = {
            "userId": req.userId,
            "ruleKey": req.ruleKey
        };
        return axios
        .post(ACTION_CODES.API_RULE_ENGINE_PRESET_RULE_DETAILS,data, {
            headers: {
                Authorization: 'Bearer ' + req?.token
            }
        })
        .then(response => {
            return {
                status: response.status,
                data: response.data
            }
        })
        .catch(error => { 
            
            return {
                status: error.response.status
            }
        })
    }

    getAllCustomRuleConditions(req:any) {
        var data = {
            "userId": req.userId
        };
        return axios
        .post(ACTION_CODES.API_RULE_ENGINE_CUSTOM_RULE_CONDITIONS,data, {
            headers: {
                Authorization: 'Bearer ' + req?.token
            }
        })
        .then(response => {
            return {
                status: response.status,
                data: response.data
            }
        })
        .catch(error => { 
            
            return {
                status: error.response.status
            }
        })
    }

    toggleRuleAlertStatus(req:any) {
        var data = {
            "userId": req.userId,
            "ruleKey":req.ruleKey,
            "status":req.status
        };
        return axios
        .post(ACTION_CODES.API_RULE_ENGINE_TOGGLE_ALERT_STATUS,data, {
            headers: {
                Authorization: 'Bearer ' + req?.token
            }
        })
        .then(response => {
            return {
                status: response.status,
                data: response.data
            }
        })
        .catch(error => { 
            
            return {
                status: error.response.status
            }
        })
    }
    
    getRuleEngineGroupNames(req:any) {
        var data = {
            "userId": req.userId,
            "machineModelKey": req.machineModelKey
        };
        return axios
        .post(ACTION_CODES.API_RULE_ENGINE_CUSTOM_GROUP_NAMES,data, {
            headers: {
                Authorization: 'Bearer ' + req?.token
            }
        })
        .then(response => {
            return {
                status: response.status,
                data: response.data
            }
        })
        .catch(error => { 
            
            return {
                status: error.response.status
            }
        })
    }
    
    getParameterList(req:any) {
        var data = {
            "userId": req.userId,
            "machineModelKey": req.machineModelKey,
            "groupKey": req.groupKey
        };
        return axios
        .post(ACTION_CODES.API_RULE_ENGINE_CUSTOM_PARAMETERS,data, {
            headers: {
                Authorization: 'Bearer ' + req?.token
            }
        })
        .then(response => {
            return {
                status: response.status,
                data: response.data
            }
        })
        .catch(error => { 
            
            return {
                status: error.response.status
            }
        })
    }

    getRuleEngineOperators(req:any) {
        return axios
        .get(ACTION_CODES.API_RULE_ENGINE_CUSTOM_OPERATORS, {
            headers: {
                Authorization: 'Bearer ' + req?.token
            }
        })
        .then(response => {
            return {
                status: response.status,
                data: response.data
            }
        })
        .catch(error => { 
            
            return {
                status: error.response.status
            }
        })
    }

    getRuleEngineExsistingRules(req:any) {
        var data = {
            userId: req.userId
        };
        return axios
        .post(ACTION_CODES.API_RULE_ENGINE_EXSISTING_RULES, data, {
            headers: {
                Authorization: 'Bearer ' + req?.token
            }
        })
        .then(response => {
            return {
                status: response.status,
                data: response.data
            }
        })
        .catch(error => { 
            
            return {
                status: error.response.status
            }
        })
    }

    getParameterValueTypes(req:any) {
        var data = {
            machineParamKeys: req.machineParamKeys
        };
        return axios
        .post(ACTION_CODES.API_RULE_ENGINE_PARAMETER_VALUE_TYPES, data, {
            headers: {
                Authorization: 'Bearer ' + req?.token
            }
        })
        .then(response => {
            return {
                status: response.status,
                data: response.data
            }
        })
        .catch(error => { 
            
            return {
                status: error.response.status
            }
        })
    }

    createRuleEngineCustomRule(req:any) {
        var data = {
            "userId": req.userId,
            "customerKey": req.customerKey,
            "machineModelKey":req.machineModelKey,
            "presetRuleKey":req.presetRuleKey,
            "machineKeys":req.machineKeys,
            "recipeKeys":req.recipeKeys,
            "severityKey":req.severityKey,
            "recommendedNotes":req.recommendedNotes,
            "duration":req.duration,
            "alertType":req.alertType,
            "notifiyGroups":req.notifiyGroups,
            "subject":req.subject,
            "type":req.type,
            "ruleName":req.ruleName,
            "ruleDesc":req.ruleDesc,
            "condFormula":req.condFormula,
            "jsonCond":req.jsonCond,
            "MachMdlParamGrpKeys":req.MachMdlParamGrpKeys,
            "paramCodes":req.paramCodes,
            "instantAlert":req.instantAlert
        };
        return axios
        .post(ACTION_CODES.API_RULE_ENGINE_CREATE_CUSTOM_ALERT,data, {
            headers: {
                Authorization: 'Bearer ' + req?.token
            }
        })
        .then(response => {
            return {
                status: response.status,
                data: response.data
            }
        })
        .catch(error => { 
            
            return {
                status: error.response.status
            }
        })
    }

    getRuleEngineCustomAlertDetails(req:any) {
        var data = {
            "userId": req.userId,
            "ruleKey": req.ruleKey
        };
        return axios
        .post(ACTION_CODES.API_RULE_ENGINE_CUSTOM_RULE_DETAILS,data, {
            headers: {
                Authorization: 'Bearer ' + req?.token
            }
        })
        .then(response => {
            return {
                status: response.status,
                data: response.data
            }
        })
        .catch(error => { 
            
            return {
                status: error.response.status
            }
        })
    }

    getRuleEngineNotifications(req:any) {
        var data = {
            "userId": req.userId,
        };
        return axios
        .post(ACTION_CODES.API_RULE_ENGINE_NOTIFICATIONS,data, {
            headers: {
                Authorization: 'Bearer ' + req?.token
            }
        })
        .then(response => {
            return {
                status: response.status,
                data: response.data
            }
        })
        .catch(error => { 
            
            return {
                status: error.response.status
            }
        })
    }
    getRuleEngineNotificationDetails(req:any) {
        var data = {
            "ruleKey": req.ruleKey,
            "type":req.type
        };
        return axios
        .post(ACTION_CODES.API_RULE_ENGINE_NOTIFICATION_DETAILS,data, {
            headers: {
                Authorization: 'Bearer ' + req?.token
            }
        })
        .then(response => {
            return {
                status: response.status,
                data: response.data
            }
        })
        .catch(error => { 
            
            return {
                status: error.response.status
            }
        })
    }

    getRuleEngineParameterRangeLimit(req:any) {
        var data = {
            "userId": req.userId,
            "recipeKeys": req.recipeKeys,
            "paramKeys": req.paramKeys
        };
        return axios
        .post(ACTION_CODES.API_RULE_ENGINE_RANGE_DETAILS,data, {
            headers: {
                Authorization: 'Bearer ' + req?.token
            }
        })
        .then(response => {
            return {
                status: response.status,
                data: response.data
            }
        })
        .catch(error => { 
            
            return {
                status: error.response.status
            }
        })
    }
}
export default new RuleEngineService();