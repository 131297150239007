import { useState, useEffect, useRef } from 'react';
import { useDispatch } from 'react-redux';
import useGetState from '../../../utils/hooks/useGetState';
import { Container, Grid } from '@mui/material';
import { Formik, Form } from 'formik';
import * as Yup from 'yup';
import { MOB_YUPSCHEMA } from './schema';
import '../../../features/Admin/FeatureManagement/CreateReport/index.scss';
import { ACTION_CODES, STORE_KEYS } from '../../../constants/apiConstants';
import { executeACGAction, resetErr, updateScreenIdentifiers } from '../../../store/slice';
import TextInput from '../../TextInput';
import Label from '../../Label/index';
import ACGButton from '../../Button';
import DropDown from '../../Dropdown';
import CustomDatePicker from '../../CustomDatePicker';
import './index.scss';
import { renameObjKeys } from '../../../features/Login/helper';
import dateFormat from 'dateformat';
type Props = {
    drawerClose: Function;
    setSnackbarOpts: Function;
    data?: any;
    setMachinesReLoad?: any;
    setSelectedRow?: any;
};
const options = {
    AD_SUCCESS: {
        message: 'Machine Onboarded successfully!',
        open: true,
        type: 'success'
    }
};
const OnboardForm = (props: Props) => {
    const {
        drawerClose,
        setSnackbarOpts = () => {},
        data,
        setMachinesReLoad = () => {},
        setSelectedRow = () => {}
    } = props;
    const formRef = useRef<any>(null);
    const [validationSchema, setValidationSchema] = useState({});
    const [clients, setClients] = useState<any>([]);
    const [plants, setPlants] = useState<any>([]);
    const [plantLines, setPlantLines] = useState<any>([]);
    const state = useGetState();
    const dispatch = useDispatch();
    const [initialValues] = useState({
        dropdownCl: '',
        dropdownPl: '',
        dropdownPlLn: '',
        gtId: '',
        gtDt: new Date(),
        wrDt: new Date(),
        slNo: '',
        simNo: ''
    });
    const getClients = () => {
        dispatch(
            executeACGAction({
                payload: {
                    requestType: 'GET',
                    urlPath: ACTION_CODES.GET_CLIENTS
                },
                storeKey: STORE_KEYS.CLIENT_LIST,
                uniqueScreenIdentifier: {
                    clientsRecd: true
                }
            })
        );
    };
    const getPlants = (clientKey: any) => {
        dispatch(
            executeACGAction({
                payload: {
                    requestType: 'GET',
                    urlPath: `${ACTION_CODES.GET_PLANTS_FROMCLIENT}/${clientKey}`
                },
                storeKey: STORE_KEYS.PLANTS_OFCLIENT,
                uniqueScreenIdentifier: {
                    plantsRecd: true
                }
            })
        );
    };
    const getPlantLines = (customerKey: any, plantKey: any) => {
        dispatch(
            executeACGAction({
                payload: {
                    requestType: 'POST',
                    urlPath: ACTION_CODES.GET_PLANTLINES_FROMPLANT,
                    reqObj: {
                        customerKey: customerKey,
                        plantKey: plantKey
                    }
                },
                storeKey: STORE_KEYS.PLANTLINES_OFPLANT,
                uniqueScreenIdentifier: {
                    linesRecd: true
                }
            })
        );
    };
    useEffect(() => {
        getClients();
        setValidationSchema(Yup.object().shape(MOB_YUPSCHEMA));
    }, []);
    useEffect(() => {
        if (state?.[STORE_KEYS.CLIENT_LIST]?.clientsRecd) {
            setClients(
                state?.[STORE_KEYS.CLIENT_LIST]?.body?.data?.map((item: any) => {
                    const newVal = renameObjKeys(item, { CustomerKey: 'code', CustomerName: 'name' });
                    return newVal;
                })
            );
            dispatch(
                updateScreenIdentifiers({
                    storeKey: STORE_KEYS.CLIENT_LIST,
                    uniqueScreenIdentifier: {
                        clientsRecd: false
                    }
                })
            );
        }
    }, [state?.[STORE_KEYS.CLIENT_LIST]?.clientsRecd]);
    useEffect(() => {
        if (state?.[STORE_KEYS.PLANTS_OFCLIENT]?.plantsRecd) {
            setPlants(
                state?.[STORE_KEYS.PLANTS_OFCLIENT]?.body?.data?.map((item: any) => {
                    const newVal = renameObjKeys(item, { PlantKey: 'code', PlantName: 'name' });
                    return newVal;
                })
            );
            dispatch(
                updateScreenIdentifiers({
                    storeKey: STORE_KEYS.PLANTS_OFCLIENT,
                    uniqueScreenIdentifier: {
                        plantsRecd: false
                    }
                })
            );
        }
    }, [state?.[STORE_KEYS.PLANTS_OFCLIENT]?.plantsRecd]);
    useEffect(() => {
        if (state?.[STORE_KEYS.PLANTLINES_OFPLANT]?.linesRecd) {
            setPlantLines(
                state?.[STORE_KEYS.PLANTLINES_OFPLANT]?.body?.data?.map((item: any) => {
                    const newVal = renameObjKeys(item, { PlantLineNoKey: 'code', PlantLineNoName: 'name' });
                    return newVal;
                })
            );
            dispatch(
                updateScreenIdentifiers({
                    storeKey: STORE_KEYS.PLANTLINES_OFPLANT,
                    uniqueScreenIdentifier: {
                        linesRecd: false
                    }
                })
            );
        }
    }, [state?.[STORE_KEYS.PLANTLINES_OFPLANT]?.linesRecd]);
    const renderButtons = () => {
        return (
            <div className={'btns noWrap'}>
                <ACGButton
                    name={'Cancel'}
                    handleClick={drawerClose}
                    secondary={true}
                    className={'first-btn'}
                    type="button"
                    variant="contained"
                />
                <ACGButton
                    name={'Proceed'}
                    handleClick={() => {}}
                    type="submit"
                    className="second-btn"
                    variant="contained"
                />
            </div>
        );
    };

    const handleSubmit = async (values: any) => {
        const bundle = {
            payload: {
                urlPath: ACTION_CODES.MACHINE_ONBOARD,
                requestType: 'POST',
                reqObj: {
                    machineKey: data?.MachineKey,
                    plantLineNoKey: values.dropdownPlLn,
                    gatewayId: values.gtId,
                    gatewayInstalledDate: dateFormat(values.gtDt, 'yyyy-mm-dd'),
                    warrantyDate: dateFormat(values.wrDt, 'yyyy-mm-dd'),
                    machineSerialNo: values.slNo,
                    simCardNumber: values.simNo
                }
            },
            uniqueScreenIdentifier: { machineOnboarded: true },
            storeKey: STORE_KEYS.ADD_RPT
        };
        dispatch(executeACGAction(bundle));
    };

    useEffect(() => {
        if (state?.[STORE_KEYS.ADD_RPT]?.machineOnboarded) {
            if (state?.[STORE_KEYS.ADD_RPT]?.statusCode === 200) {
                setMachinesReLoad(true);
                setSelectedRow({
                    ...data,
                    CustomerKey: formRef?.current?.values?.dropdownCl,
                    CustomerName: clients?.find((client: any) => client.code === formRef?.current?.values?.dropdownCl)
                        ?.name,
                    PlantKey: formRef?.current?.values?.dropdownPl,
                    PlantName: plants?.find((plnt: any) => plnt.code === formRef?.current?.values?.dropdownPl)?.name,
                    PlantLineNoKey: formRef?.current?.values?.dropdownPlLn,
                    PlantLineNoName: plantLines?.find(
                        (plntL: any) => plntL.code === formRef?.current?.values?.dropdownPlLn
                    )?.name,
                    GatewayId: formRef?.current?.values?.gtId,
                    GatewayInstalledDate: dateFormat(formRef?.current?.values?.gtDt, 'yyyy-mm-dd'),
                    WarrantyTillDate: dateFormat(formRef?.current?.values?.wrDt, 'yyyy-mm-dd'),
                    OnboardStatus: 'Onboarded',
                    MachineSerialNo: formRef?.current?.values?.slNo,
                    SimCardNumber: formRef?.current?.values?.simNo
                });
                setSnackbarOpts(options.AD_SUCCESS);
                drawerClose();
            }
            dispatch(
                updateScreenIdentifiers({
                    storeKey: STORE_KEYS.ADD_RPT,
                    uniqueScreenIdentifier: {
                        machineOnboarded: false
                    }
                })
            );
        }
    }, [state?.[STORE_KEYS.ADD_RPT]?.machineOnboarded]);

    const handleSnackbarError = (err: any) => {
        const errorMsg = err?.message || 'Internal Server error';
        const snackbarError = {
            message: errorMsg,
            type: 'reject',
            open: true
        };
        setSnackbarOpts(snackbarError);
    };

    useEffect(() => {
        if (state?.err) {
            handleSnackbarError(state?.err);
            dispatch(resetErr());
        }
    }, [state?.err]);

    return (
        <Container className="createReport machineOnboard">
            <Formik
                initialValues={initialValues}
                validationSchema={validationSchema}
                onSubmit={(values: any) => {
                    handleSubmit(values);
                }}
                validateOnBlur={false}
                validateOnChange={false}
                innerRef={formRef}
            >
                {(formikprops: any) => {
                    const { values, handleChange, handleBlur, errors, touched, setFieldValue } = formikprops;
                    return (
                        <Form>
                            <Grid container spacing={2}>
                                <Grid item xs={12} sm={8} md={8} xl={8} lg={8}>
                                    <Label label="Machine Name" className={'labelDiv'} />
                                    <Label label={data?.MachineName} className={'subTitle'} />{' '}
                                </Grid>
                                <Grid item xs={12} sm={4} md={4} xl={4} lg={4}>
                                    <Label label="Family" className={'labelDiv'} />
                                    <Label label={data?.MachineFamilyName} className={'subTitle'} />
                                </Grid>
                            </Grid>
                            <Label label="Select Client" className={'labelDiv'} />
                            <DropDown
                                onChange={(event: any) => {
                                    handleChange(event);
                                    setFieldValue('dropdownPl', '');
                                    setFieldValue('dropdownPlLn', '');
                                    getPlants(event.target.value);
                                }}
                                name="dropdownCl"
                                className={'inputDiv'}
                                items={clients}
                                placeHolder="Select Client"
                                value={values.dropdownCl}
                                helperText={errors.dropdownCl && touched.dropdownCl ? errors.dropdownCl : ''}
                            />
                            <Label label="Select Plant" className={'labelDiv'} />
                            <DropDown
                                onChange={(event: any) => {
                                    handleChange(event);
                                    setFieldValue('dropdownPlLn', '');
                                    getPlantLines(values.dropdownCl, event.target.value);
                                }}
                                name="dropdownPl"
                                className={'inputDiv'}
                                items={plants}
                                placeHolder="Select Plant"
                                disabled={values.dropdownCl === '' ? true : false}
                                value={values.dropdownPl}
                                helperText={errors.dropdownPl && touched.dropdownPl ? errors.dropdownPl : ''}
                            />
                            <Label label="Select Plant Line Number" className={'labelDiv'} />
                            <DropDown
                                onChange={(event: any) => {
                                    handleChange(event);
                                }}
                                name="dropdownPlLn"
                                className={'inputDiv'}
                                items={plantLines}
                                placeHolder="Select Plant Line Number"
                                disabled={values.dropdownPl === '' ? true : false}
                                value={values.dropdownPlLn}
                                helperText={errors.dropdownPlLn && touched.dropdownPlLn ? errors.dropdownPlLn : ''}
                            />
                            <Grid container spacing={2}>
                                <Grid item xs={12} sm={6} md={6} xl={6} lg={6}>
                                    <Label label="Gateway ID" className={'labelDiv'} />
                                    <TextInput
                                        variant="outlined"
                                        fullWidth
                                        type="text"
                                        name="gtId"
                                        value={values.gtId}
                                        autoComplete="off"
                                        formInput="inputDiv"
                                        onChange={(e: any) => {
                                            handleChange(e);
                                        }}
                                        onBlur={handleBlur}
                                        placeholder="Enter Gateway ID"
                                        helperText={errors.gtId && touched.gtId ? errors.gtId : ''}
                                    />
                                </Grid>
                                <Grid item xs={12} sm={12} md={6} xl={6} lg={6}>
                                    <Label label="Gateway Installation Date" className={'labelDiv'} />
                                    <CustomDatePicker
                                        name="gtDt"
                                        value={values.gtDt}
                                        className="inputDiv"
                                        onChange={(val: any) => {
                                            setFieldValue('gtDt', val);
                                        }}
                                        inputFormat="dd/MM/yyyy"
                                        placeholder="Select Gateway Installation Date"
                                        helperText={errors.gtDt && touched.gtDt ? errors.gtDt : ''}
                                    />
                                </Grid>
                            </Grid>
                            <Grid container spacing={2}>
                                <Grid item xs={12} sm={6} md={6} xl={6} lg={6}>
                                    <Label label="Warranty Date" className={'labelDiv'} />
                                    <CustomDatePicker
                                        name="gtDt"
                                        value={values.wrDt}
                                        className="inputDiv"
                                        onChange={(val: any) => {
                                            setFieldValue('wrDt', val);
                                        }}
                                        inputFormat="dd/MM/yyyy"
                                        placeholder="Select Warranty Date"
                                        helperText={errors.wrDt && touched.wrDt ? errors.wrDt : ''}
                                    />
                                </Grid>
                            </Grid>
                            <Label label="Machine Serial Number" className={'labelDiv'} />
                            <TextInput
                                variant="outlined"
                                fullWidth
                                type="string"
                                name="slNo"
                                value={values.slNo}
                                autoComplete="off"
                                formInput="inputDiv"
                                onChange={(e: any) => {
                                    handleChange(e);
                                }}
                                onBlur={handleBlur}
                                placeholder="Enter machine serial number"
                                helperText={errors.slNo && touched.slNo ? errors.slNo : ''}
                            />
                            <Label label="Sim Card Number" className={'labelDiv'} />
                            <TextInput
                                variant="outlined"
                                fullWidth
                                type="string"
                                name="simNo"
                                value={values.simNo}
                                autoComplete="off"
                                formInput="inputDiv"
                                onChange={(e: any) => {
                                    handleChange(e);
                                }}
                                onBlur={handleBlur}
                                placeholder="Enter SIM card number"
                                helperText={errors.simNo && touched.simNo ? errors.simNo : ''}
                            />
                            {renderButtons()}
                        </Form>
                    );
                }}
            </Formik>
        </Container>
    );
};

export default OnboardForm;
