import { Paper, Typography } from '@mui/material';
import { Formik, Form } from 'formik';
import { useState, useEffect, useRef } from 'react';
import * as Yup from 'yup';
import { useDispatch, useSelector } from 'react-redux';
import { createStructuredSelector } from 'reselect';
import TextInput from '../../../../atoms/TextInput/index';
import Label from '../../../../atoms/Label/index';
import Button from '../../../../atoms/Button/index';
import { SETSECURITYQN_YUPSCHEMA } from './schema';
import DropDown from '../../../../atoms/Dropdown/index';
import { ACTION_CODES, STORE_KEYS } from '../../../../constants/apiConstants';
import { acgSelector } from '../../../../store/selector';
import Snackbar from '../../../../atoms/Snackbar/index';
import { executeACGAction, resetErr, updateScreenIdentifiers } from '../../../../store/slice';
import { renameObjKeys } from '../../helper';

const options = {
    DEFAULT: {
        message: '',
        open: false,
        type: ''
    }
};

type ComponentProps = { data: any };
const SetSecurityQn = (props: ComponentProps) => {
    const { data } = props;
    const dispatch = useDispatch();
    const [validationSchema, setValidationSchema] = useState({});
    const [snackbarOptions, setSnackbarOptions] = useState(options.DEFAULT);
    const [disableSubmit, setDisableSubmit] = useState(true);
    const [qaList, setQaList] = useState<any>([]);
    const [initialValues] = useState({
        securityQn: '',
        answer: ''
    });
    const acgStateSelector = createStructuredSelector({
        acgSlice: acgSelector()
    });
    const { acgSlice: state } = useSelector(acgStateSelector);
    const formRef = useRef<any>();

    useEffect(() => {
        setValidationSchema(Yup.object().shape(SETSECURITYQN_YUPSCHEMA));
        const bundle = {
            payload: {
                urlPath: ACTION_CODES.GETQA,
                requestType: 'GET'
            },
            uniqueScreenIdentifier: { qaLoaded: true },
            storeKey: STORE_KEYS.SECURITY_QA
        };
        dispatch(executeACGAction(bundle));
    }, []);

    useEffect(() => {
        if (state?.securityQa?.qaLoaded) {
            const qns = state?.securityQa?.body?.data;
            const qnsArr = qns?.map((item: any) => {
                const newVal = renameObjKeys(item, { ForgetPwdQnKey: 'code', Question: 'name' });
                return newVal;
            });
            setQaList(qnsArr);
            dispatch(
                updateScreenIdentifiers({
                    storeKey: STORE_KEYS.SECURITY_QA,
                    uniqueScreenIdentifier: {
                        qaLoaded: false
                    }
                })
            );
        }
    }, [state?.securityQa?.qaLoaded]);

    const handleSubmit = async () => {
        const bundle = {
            payload: {
                urlPath: ACTION_CODES.ADDQA,
                requestType: 'POST',
                reqObj: {
                    email: state?.loginData?.emailId,
                    forgetPwdQnkey: formRef?.current?.values?.securityQn,
                    answer: formRef?.current?.values?.answer.replace(/'/g, "''")
                }
            },
            uniqueScreenIdentifier: { qaSet: true },
            storeKey: STORE_KEYS.ADD_QA
        };
        dispatch(executeACGAction(bundle));
    };

    useEffect(() => {
        if (state?.addQa?.qaSet) {
            if (state?.addQa?.statusCode === 200) {
                dispatch(
                    updateScreenIdentifiers({
                        storeKey: STORE_KEYS.ADD_QA,
                        uniqueScreenIdentifier: {
                            qaSet: false
                        }
                    })
                );
                data?.setTemplateType('createPassword');
            }
        }
    }, [state?.addQa?.qaSet]);

    const handleSnackbarError = (err: any) => {
        const errorMsg = err?.message || 'Internal Server error';
        const snackbarError = {
            message: errorMsg,
            type: 'reject',
            open: true
        };
        setSnackbarOptions(snackbarError);
    };

    useEffect(() => {
        if (state?.err) {
            handleSnackbarError(state?.err);
            dispatch(resetErr());
        }
    }, [state?.err]);

    const closeSnackbar = () => setSnackbarOptions(options.DEFAULT);

    return (
        <Paper elevation={2} className="loginPaper">
            <Snackbar className="login-snackbar" options={snackbarOptions} handleClose={closeSnackbar} />
            <div className="loginFormHeader">
                <Typography className="welcomeText">Set Security Question</Typography>
                <Typography className="loginText">Setup your security questions</Typography>
            </div>
            <Formik
                initialValues={initialValues}
                validationSchema={validationSchema}
                onSubmit={() => {
                    handleSubmit();
                }}
                validateOnBlur={false}
                innerRef={formRef}
            >
                {(formikprops) => {
                    const { values, handleChange, handleBlur, isValid, errors, touched } = formikprops;
                    return (
                        <Form>
                            <Label label="Security Question" />
                            <DropDown
                                onChange={(event) => {
                                    handleChange(event);
                                }}
                                className="textBoxDiv"
                                name="securityQn"
                                items={qaList}
                                placeHolder="Select Security Question"
                                value={values.securityQn}
                                helperText={errors.securityQn && touched.securityQn ? errors.securityQn : ''}
                            />
                            <Label className="password-class" label="Answer" />
                            <TextInput
                                variant="outlined"
                                maxLength={50}
                                fullWidth
                                type="text"
                                name="answer"
                                autoComplete="off"
                                formInput="textBoxDiv"
                                onChange={(e) => {
                                    setDisableSubmit(false);
                                    handleChange(e);
                                }}
                                placeholder="Enter Answer"
                                value={values.answer}
                                onBlur={handleBlur}
                                helperText={errors.answer && touched.answer ? errors.answer : ''}
                            />
                            <Button
                                formInput="buttonDiv"
                                fullWidth
                                name="Proceed"
                                type="submit"
                                variant="contained"
                                disabled={disableSubmit || !isValid}
                            />
                        </Form>
                    );
                }}
            </Formik>
        </Paper>
    );
};

export default SetSecurityQn;
