import React, { useState } from 'react';
import { Snackbar } from '@mui/material';
import ACGButton from '../Button';
import TextField from '@mui/material/TextField';
import { Grid } from '@material-ui/core';
import './index.scss';

type Props = {
    text: string;
    setSnkBar: Function;
};
const CopyToClipboardButton = (props: Props) => {
    const { text, setSnkBar } = props;

    const handleClick = () => {
        setSnkBar();
        navigator.clipboard.writeText(text);
    };

    return (
        <div className="clipboard-copy">
            <Grid container className="email-section">
                <Grid item style={{ marginLeft: '10px', width: '100%', marginRight: '20px' }}>
                    {text && (
                        <TextField
                            style={{ margin: '10px 0px' }}
                            fullWidth
                            id="fullWidth"
                            defaultValue={text}
                            value={text}
                            disabled
                            multiline
                        />
                    )}
                </Grid>
                <Grid item>
                    <ACGButton
                        onClick={handleClick}
                        name={'Copy'}
                        secondary={true}
                        className={'btn'}
                        type="button"
                        disabled={!text}
                        variant="contained"
                    >
                        Copy
                    </ACGButton>
                </Grid>
            </Grid>
        </div>
    );
};

export default CopyToClipboardButton;
