import React, { useState, useEffect } from 'react';
import { Container, Card } from '@mui/material';
import { useDispatch } from 'react-redux';
import { executeACGAction, resetErr, updateScreenIdentifiers } from '../../../../store/slice';
import '../../ClientManagement/index.scss';
import { applySortFilter } from '../../../../utils/helper';
import SnackbarAlert from '../../../../atoms/Snackbar';
import AlertBox from '../../../../atoms/AlertBox';
import CustomTable from '../../../../atoms/CustomTable/index';
import CustomTableToolbar from '../../../../atoms/CustomTableToolbar';
import { ACTION_CODES, STORE_KEYS } from '../../../../constants/apiConstants';
import '../../ClientManagement/index.scss';
import useGetState from '../../../../utils/hooks/useGetState';
import DetailDrawer from '../../../../atoms/DetailDrawer';

const TABLE_HEAD = [
    { id: 'ReportOrderId', label: 'Display Order', alignRight: false },
    { id: 'name', label: 'Name', alignRight: false },
    { id: 'Action', label: 'Action', alignLeft: true }
];
const options = {
    DEFAULT: {
        message: '',
        open: false,
        type: ''
    },
    RM_SUCCESS: {
        message: 'Report deleted successfully!',
        open: true,
        type: 'success'
    }
};
type ComponentProps = { getSummary: Function; setReload: Function; reload: Boolean; dtlProps?: any };
const Reports = (props: ComponentProps) => {
    const { getSummary, setReload = () => { }, reload = false, dtlProps = {} } = props;
    const [state, setState] = useState({
        right: false
    });
    const [page, setPage] = useState<number>(0);
    const [order, setOrder] = useState('desc');
    const [orderBy, setOrderBy] = useState('name');
    const [selected, setSelected] = useState<any>([]);
    const [filterName, setFilterName] = useState<string>('');
    const [tableData, setTableData] = useState<any[]>([]);
    const dispatch = useDispatch();
    const stateObj = useGetState();
    const [snackbarOptions, setSnackbarOptions] = useState(options.DEFAULT);
    const [removeBxOpen, setRemoveBxOpen] = useState(false);
    const [rmRpt, setRmRpt] = useState(false);
    const [isReportLoading,setIsReportLoading]=useState(false)

    const closeRmBx = () => {
        setRemoveBxOpen(false);
    };
    const getReports = () => {
        setIsReportLoading(true)
        dispatch(
            executeACGAction({
                payload: {
                    requestType: 'GET',
                    urlPath: ACTION_CODES.GET_RPT
                },
                uniqueScreenIdentifier: { rptsLoaded: true },
                storeKey: STORE_KEYS.RPT_LIST
            })
        );
    };

    useEffect(() => {
        getReports();
    }, []);

    useEffect(() => {
        if (reload) {
            getSummary();
            getReports();
            setReload(false);
        }
    }, [reload]);

    useEffect(() => {
        if (stateObj?.[STORE_KEYS.RPT_LIST]?.rptsLoaded) {
            const rptsData = stateObj?.[STORE_KEYS.RPT_LIST]?.body?.data;
            const setData = rptsData?.map((rpt: any, index: any) => {
                const record = { ...rpt };
                record.name = record?.ReportName;
                return record;
            });

            setTableData(setData);
            dispatch(
                updateScreenIdentifiers({
                    storeKey: STORE_KEYS.RPT_LIST,
                    uniqueScreenIdentifier: {
                        rptsLoaded: false
                    }
                })
            );
            setIsReportLoading(false)
        }
    }, [stateObj?.[STORE_KEYS.RPT_LIST]?.rptsLoaded]);

    const handleRequestSort = (event: any, property: any) => {
        setFilterName('');
        const isAsc = orderBy === event && order === 'asc';
        setOrder(isAsc ? 'desc' : 'asc');
        setOrderBy(event);
    };

    const handleSelectAllClick = (event: any) => {
        if (event.target.checked) {

            const newSelecteds = [];

            for (let i = 0; i < tableData.length; i++) {
                newSelecteds.push(tableData[i].id);
            }

            const toRemove = tableData.slice(page, tableData.length);

            setSelected(newSelecteds);
            return;
        }
        setSelected([]);
    };

    const handleClick = (requestedBy: string) => {
        const selectedIndex = selected.indexOf(requestedBy);
        let newSelected: any[] | ((prevState: unknown[]) => unknown[]) = [];
        if (selectedIndex === -1) {
            newSelected = newSelected.concat(selected, requestedBy);
        } else if (selectedIndex === 0) {
            newSelected = newSelected.concat(selected.slice(1));
        } else if (selectedIndex === selected.length - 1) {
            newSelected = newSelected.concat(selected.slice(0, -1));
        } else if (selectedIndex > 0) {
            newSelected = newSelected.concat(selected.slice(0, selectedIndex), selected.slice(selectedIndex + 1));
        }
        setSelected(newSelected);
    };

    const filteredList = applySortFilter(tableData, order, filterName, orderBy);

    const handleFilterByName = (event: { target: { value: React.SetStateAction<string> } }) => {
        setPage(0);
        setOrderBy('name');
        setFilterName(event.target.value);
    };

    const handleChangePage = (event: any, newPage: React.SetStateAction<number>) => {
        setPage(newPage);
    };

    const showRow = (event: any) => { };

    const handleRoute = (row: any, ele: any) => {
        if (ele === 'Action') {
            dtlProps?.setDtlData(row);
            setDtlDrawerOpen(true);
        }
    };

    const resetInput = () => {
        setFilterName('');
    };

    const [rptKey, setRptKey] = useState<any>();

    const removeRpt = (ele: any) => {
        setRptKey(ele?.ReportKey);
        setRemoveBxOpen(true);
    };

    useEffect(() => {
        if (rmRpt) {
            closeRmBx();
            dispatch(
                executeACGAction({
                    payload: {
                        requestType: 'DELETE',
                        urlPath: `${ACTION_CODES.DELETE_RPT}/${rptKey}`
                    },
                    uniqueScreenIdentifier: {
                        rptRemoved: true
                    },
                    storeKey: STORE_KEYS.DELETE_RPT
                })
            );
            setRmRpt(false);
        }
    }, [rmRpt]);

    useEffect(() => {
        if (stateObj?.[STORE_KEYS.DELETE_RPT]?.rptRemoved) {
            if (stateObj?.[STORE_KEYS.DELETE_RPT]?.statusCode === 200) {
                setSnackbarOptions(options.RM_SUCCESS);
                closeDtlDrawer();
                getSummary();
                getReports();
            }
            dispatch(
                updateScreenIdentifiers({
                    storeKey: STORE_KEYS.DELETE_RPT,
                    uniqueScreenIdentifier: {
                        rptRemoved: false
                    }
                })
            );
        }
    }, [stateObj?.[STORE_KEYS.DELETE_RPT]?.rptRemoved]);

    const handleSnackbarError = (err: any) => {
        const errorMsg = err?.message || 'Internal Server error';
        const snackbarError = {
            message: errorMsg,
            type: 'reject',
            open: true
        };

        setSnackbarOptions(snackbarError);
    };

    useEffect(() => {
        if (stateObj?.err) {
            handleSnackbarError(stateObj?.err);
            dispatch(resetErr());
        }
    }, [stateObj?.err]);

    const closeSnackbar = () => setSnackbarOptions(options.DEFAULT);
    const [dtlDrawerOpen, setDtlDrawerOpen] = useState(false);
    const closeDtlDrawer = () => {
        setDtlDrawerOpen(false);
    };
    return (
        <div>
            <SnackbarAlert options={snackbarOptions} handleClose={closeSnackbar} />
       
                <div>
                    <Container
                        maxWidth="xl"
                        style={{
                            paddingLeft: '3px',
                            paddingRight: '3px'
                        }}
                    >
                        <Card
                            style={{
                                borderRadius: '0px',
                                backgroundColor: '#2b2d42',
                                overflow: 'inherit'
                            }}
                        >
                            <CustomTableToolbar
                                drawerOpen={() => { }}
                                numSelected={selected?.length}
                                filterName={filterName}
                                onFilterName={handleFilterByName}
                                resetInput={handleFilterByName}
                                displayInput={true}
                                text={{
                                    title: 'User List',
                                    subTitle: `${filteredList.length} users`
                                }}
                                filterButton={false}
                                customButton={false}
                            />
                        </Card>
                    </Container>
                    <CustomTable
                        selectable={false}
                        headers={TABLE_HEAD}
                        body={filteredList}
                        isLoading={isReportLoading}
                        filteredUsers={filteredList}
                        filterName={filterName}
                        selected={selected}
                        order={order}
                        orderBy={orderBy}
                        onRequestSort={handleRequestSort}
                        onSelectAllClick={handleSelectAllClick}
                        rowChange={(event: string) => handleClick(event)}
                        rowCount={filteredList?.length}
                        onPageChange={handleChangePage}
                        page={page}
                        setPageNumber={setPage}
                        selectRow={showRow}
                        route={handleRoute}
                        isRequestPage={true}
                        link={true}
                        removeFn={removeRpt}
                    />
                </div>
           
            <AlertBox
                isOpen={removeBxOpen}
                handleClose={closeRmBx}
                leftBtnPress={closeRmBx}
                rightBtnPress={() => setRmRpt(true)}
                modalHeader="Delete Report"
                modalContent="Are you sure you want to delete report?"
            />
            <DetailDrawer hasImage={false}
                handlerOpen={() => { }}
                handlerClose={closeDtlDrawer}
                status={dtlDrawerOpen}
                data={{
                    name: dtlProps?.dtlData?.ReportName,
                    details:
                        dtlProps?.dtlData?.ReportType === 'React'
                            ? [
                                { code: 'Report Type', name: dtlProps?.dtlData?.ReportType },
                                { code: 'Report Code', name: dtlProps?.dtlData?.ReportCode },
                                { code: 'Report Name', name: dtlProps?.dtlData?.ReportName },
                                { code: 'Report Order Id', name: dtlProps?.dtlData?.ReportOrderId }
                            ]
                            : [
                                { code: 'Report Type', name: dtlProps?.dtlData?.ReportType },
                                { code: 'Report Code', name: dtlProps?.dtlData?.ReportCode },
                                { code: 'Report Name', name: dtlProps?.dtlData?.ReportName },
                                { code: 'Report Order Id', name: dtlProps?.dtlData?.ReportOrderId },
                                { code: 'Report Type', name: dtlProps?.dtlData?.ReportType },
                                { code: 'Report URL', name: dtlProps?.dtlData?.PBIReportURL },
                                { code: 'Base URL', name: dtlProps?.dtlData?.PBIBaseURL },
                                { code: 'BI Group ID', name: dtlProps?.dtlData?.PBIGroupId },
                                { code: 'Report Id', name: dtlProps?.dtlData?.PBIReportId },
                                { code: 'Database ID', name: dtlProps?.dtlData?.PBIDatasetId }
                            ]
                }}
                type={'Feature'}
                editFn={() => {
                    dtlProps?.setCreateDrawerOpen(true);
                    dtlProps?.setCreateValue('Report');
                    dtlProps?.setDrawerHeader('Edit');
                }}
                deleteFn={() => removeRpt(dtlProps?.dtlData)}
            />
        </div>
    );
};

export default Reports;
