import { useState, useEffect } from 'react';
import Box from '@mui/material/Box';
import Label from '../../../atoms/Label/index';
import TextInput from '../../../atoms/TextInput';
import DropDown from '../../../atoms/Dropdown';
import InputLabel from '@mui/material/InputLabel';
import MenuItem from '@mui/material/MenuItem';
import FormControl from '@mui/material/FormControl';
import Select from '@mui/material/Select';
import TextField from '@mui/material/TextField';
import Stack from '@mui/material/Stack';
import Button from '@mui/material/Button';
import Container from '@mui/material/Container';
import ACGButton from '../../../atoms/Button';
import useGetState from '../../../utils/hooks/useGetState';
import { ACTION_CODES, STORE_KEYS, BASEURL } from '../../../constants/apiConstants';
import { Grid } from '@mui/material';
import { useDispatch } from 'react-redux';
import { executeACGAction, resetErr, updateScreenIdentifiers } from '../../../store/slice';
import { renameObjKeys } from '../../Login/helper';
import CustomDatePicker from '../../../atoms/CustomDatePicker';

export default function CreateForm(props: any) {
  const [goldenMachineModel, setGoldenMachineModel] = useState<any>([]);
  const [goldenRecipeList, setGoldenRecipeList] = useState<any>([]);
  const dispatch = useDispatch();
  const state = useGetState();
  const token = state?.[STORE_KEYS.USER_DTL].token;
  const loggedInUser = state[STORE_KEYS.USER_DTL]?.LoggedUserKey;

  const renderButtons = () => {
    return (
      <div className={'btns noWrap'} style={{ display: "flex", justifyContent: "flex-end", width: "100%" }}>
        <ACGButton
          name={'Cancel'}
          handleClick={props.drawerClose}
          secondary={true}
          className={'first-btn'}
          type="button"
          variant="contained"
        />
        <ACGButton
          name={'Submit'}
          handleClick={() => { props.drawerClose(); props.getQualityAttributesBQS(props.filteredGoldenRecipeKey); props.setClearGBLimitFilter(true);}}
          type="submit"
          disabled={props.filteredGoldenRecipeKey === "" ? true : false}
          className="second-btn"
          variant="contained"
        />
      </div>
    );
  };

  useEffect(()=>{
    const list = props.goldenBatchList?.map((item: any) => {
      const newVal = renameObjKeys(item, { RecipeKey: 'code', RecipeName: 'name' });
      return newVal;
    });
    setGoldenRecipeList(list);
  },[])

  return (
    <Container className="createReport">
      <Box>
        <div >
          <FormControl fullWidth>
 
           <Label label="Golden Recipe &nbsp; *" className={'CMlabelDiv'} />
           <DropDown
             onChange={(e: any) => { props.setFilteredGoldenRecipeKey(e.target.value) }}
             className="inputDiv"
             name="dropdownModel"
             items={ goldenRecipeList}
             placeHolder="Select Golden Recipe"
             value={props.filteredGoldenRecipeKey} 
             />
          
          </FormControl>
        </div>
      </Box>
    
      <Grid style={{ display: "flex", marginTop: 20 }}>
        {renderButtons()}
      </Grid>

    </Container>
  );
}