//import MuiPhoneNumber from 'material-ui-phone-number';
import PhoneInput, { isValidPhoneNumber } from 'react-phone-number-input';
import 'react-phone-number-input/style.css';
import './index.scss';

type Props = { value?: any; handleChange?: any; className?: string; helperText?: string };

const FlagPhoneDropdown = (props: Props) => {
    const { value, handleChange = () => {}, className, helperText } = props;
    const styleNames =
        className && helperText
            ? `${className} phoneRedOutline phoneFlag`
            : className && !helperText
            ? `${className} phoneFlag`
            : 'phoneFlag';
    return (
        <div>
            <PhoneInput
                onChange={handleChange}
                international
                defaultCountry={'IN'}
                value={value}
                className={styleNames}
                placeholder={'Enter mobile number'}
            />
            {helperText && (
                <span
                    style={{
                        color: '#F06868',
                        fontSize: '11px',
                        fontWeight: 600,
                        letterSpacing: 0,
                        marginBottom: '-23px',
                        fontFamily: 'Montserrat',
                        marginLeft: '15%'
                    }}
                >
                    {helperText}
                </span>
            )}

            {/* <MuiPhoneNumber
                preferredCountries={['in']}
                // disableAreaCodes={true}
                defaultCountry={'in'}
                variant="outlined"
                type="tel"
                style={{ width: '120px' }}
                onChange={handleChange}
                //  value={value}
            /> */}
        </div>
    );
};
export default FlagPhoneDropdown;
