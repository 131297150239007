import React, { useEffect, useState } from 'react';
import { Container, Card } from '@mui/material';
import CustomTable from '../CustomTable/index';
import CustomTableToolbar from '../CustomTableToolbar';
import './index.scss';
import { applySortFilter } from '../../utils/helper';
import useGetState from '../../utils/hooks/useGetState';
import { STORE_KEYS } from '../../constants/apiConstants';

const TABLE_HEAD = [
    { id: 'ModuleName', label: 'Feature', alignRight: false },
    { id: 'FeatureName', label: 'Feature Name', alignRight: false },
    { id: 'FeatureOrderId', label: 'Order ID', alignRight: false }
];

type Anchor = 'right';

const arr = [
    {
        machineParameters: [{ id: 'M1', name: 'daa', description: 'desc', section: 'a', level: 8 }],
        modelParameters: [
            { id: '2', name: 'aaa', description: 'desc', section: 'a', level: 8 },
            { id: '3', name: 'bbb', description: 'desc', section: 'b', level: 10 },
            { id: '4', name: 'ccc', description: 'desc', section: 'c', level: 7 }
        ]
    }
];
const arr1 = [
    { name: 'aaa', description: 'desc', section: 'a', level: 8 },
    { name: 'bbb', description: 'desc', section: 'b', level: 10 },
    { name: 'ccc', description: 'desc', section: 'c', level: 7 }
];

const BadgeTable = (props: any) => {
    const { data } = props;

    const [state, setState] = useState({
        right: false
    });
    const [page, setPage] = useState<number>(0);
    const [order, setOrder] = useState('desc');
    const [orderBy, setOrderBy] = useState('ModuleName');
    const [selected, setSelected] = useState<any>([]);
    const [filterName, setFilterName] = useState<string>('');
    const [allData, setAllData] = useState<any>([]);
    const [machineSelected, setMachineSelected] = useState<any>([]);
    const stateObj = useGetState();
    const [finalObj, setFinalObj] = useState<any>(data || {});

    useEffect(() => {
        setFinalObj(data);
    }, [data]);

    useEffect(() => {
        setAllData(stateObj?.[STORE_KEYS.CLIENT_FTRS]?.data);
    }, []);
    const filterData = (dt: any) => {
        const __data: any = {};
        Object.keys(dt).forEach((key: any) => {
            __data[key] = applySortFilter(dt[key], order, filterName, orderBy);
        });
        return __data;
    };
    useEffect(() => {
        setFinalObj(filterData(data));
    }, [order, filterName, orderBy]);

    const filteredList = applySortFilter(allData, order, filterName, orderBy);

    const handleRequestSort = (event: any, property: any) => {
        setFilterName('');
        console.log(event, property);
        const isAsc = orderBy === event && order === 'asc';
        setOrder(isAsc ? 'desc' : 'asc');
        setOrderBy(event);
    };

    const handleSelectAllClick = (event: any) => {
        if (event.target.checked) {
            const newSelecteds = [];
            for (let i = 0; i < allData?.length; i++) {
                newSelecteds.push(allData[i].id);
            }
            const toRemove = allData.slice(page, allData.length);
            setSelected(newSelecteds);
            return;
        }
        setSelected([]);
    };

    const handleClick = (requestedBy: string) => {
        const selectedIndex = selected.indexOf(requestedBy);
        let newSelected: any[] | ((prevState: unknown[]) => unknown[]) = [];
        if (selectedIndex === -1) {
            newSelected = newSelected.concat(selected, requestedBy);
        } else if (selectedIndex === 0) {
            newSelected = newSelected.concat(selected.slice(1));
        } else if (selectedIndex === selected.length - 1) {
            newSelected = newSelected.concat(selected.slice(0, -1));
        } else if (selectedIndex > 0) {
            newSelected = newSelected.concat(selected.slice(0, selectedIndex), selected.slice(selectedIndex + 1));
        }
        setSelected(newSelected);
    };

    const handleMachineClick = (requestedBy: string) => {
        const selectedIndex = machineSelected.indexOf(requestedBy);
        let newSelected: any[] | ((prevState: unknown[]) => unknown[]) = [];
        if (selectedIndex === -1) {
            newSelected = newSelected.concat(machineSelected, requestedBy);
        } else if (selectedIndex === 0) {
            newSelected = newSelected.concat(machineSelected.slice(1));
        } else if (selectedIndex === machineSelected.length - 1) {
            newSelected = newSelected.concat(machineSelected.slice(0, -1));
        } else if (selectedIndex > 0) {
            newSelected = newSelected.concat(
                machineSelected.slice(0, selectedIndex),
                machineSelected.slice(selectedIndex + 1)
            );
        }
        setMachineSelected(newSelected);
    };

    const toggleDrawer = (anchor: Anchor, open: boolean) => (event: React.KeyboardEvent | React.MouseEvent) => {
        if (
            event.type === 'keydown' &&
            ((event as React.KeyboardEvent).key === 'Tab' || (event as React.KeyboardEvent).key === 'Shift')
        ) {
            return;
        }
        setState({ ...state, [anchor]: open });
    };

    const handleFilterByName = (event: { target: { value: React.SetStateAction<string> } }) => {
        setOrderBy('FeatureName');
        setFilterName(event.target.value);
    };

    const handleChangePage = (event: any, newPage: React.SetStateAction<number>) => {
        setPage(newPage);
    };

    const showRow = (event: any) => { };
    const resetInput = () => {
        setFilterName('');
    };

    return (
        <div>
            <div>
                <Container
                    maxWidth="xl"
                    style={{
                        paddingLeft: '3px',
                        paddingRight: '3px'
                    }}
                >
                    <Card
                        style={{
                            borderRadius: '0px',
                            backgroundColor: '#2b2d42',
                            overflow: 'inherit'
                        }}
                    >
                        <CustomTableToolbar
                            drawerOpen={toggleDrawer('right', true)}
                            numSelected={selected?.length}
                            filterName={filterName}
                            onFilterName={handleFilterByName}
                            displayInput={true}
                            text={{
                                title: 'Features'
                            }}
                            filterButton={false}
                            customButton={false}
                            resetInput={resetInput}
                        />
                    </Card>
                </Container>
                <CustomTable
                    selectable={false}
                    headers={TABLE_HEAD}
                    body={filteredList}
                    filteredUsers={filteredList}
                    filterName={filterName}
                    selected={selected}
                    machineSelected={machineSelected}
                    order={order}
                    orderBy={orderBy}
                    onRequestSort={handleRequestSort}
                    onSelectAllClick={handleSelectAllClick}
                    rowChange={(event: string) => handleClick(event)}
                    machineRowChange={(event: string) => handleMachineClick(event)}
                    rowCount={allData?.length}
                    onPageChange={handleChangePage}
                    page={page}
                    setPageNumber={setPage}
                    selectRow={showRow}
                    route={() => { }}
                    isRequestPage={true}
                    link={true}
                    type="multiple"
                    tableDataObj={finalObj}
                />
            </div>
        </div>
    );
};

export default BadgeTable;
