import { Paper, Typography, IconButton } from '@mui/material';
import { Formik, Form } from 'formik';
import { useState, useEffect, useRef } from 'react';
import { createStructuredSelector } from 'reselect';
import RestoreIcon from '@mui/icons-material/Restore';
import * as Yup from 'yup';
import { useHistory } from 'react-router-dom';
import TextInput from '../../../../atoms/TextInput/index';
import Label from '../../../../atoms/Label/index';
import Button from '../../../../atoms/Button/index';
import Snackbar from '../../../../atoms/Snackbar/index';
import { executeACGAction, resetErr, setStoreKey, updateScreenIdentifiers } from '../../../../store/slice';
import { acgSelector } from '../../../../store/selector';
import { getFromLocalStorage } from '../../../../../utilities/storageUtility';
import { LOGIN_YUPSCHEMA } from './schema';
import { ACTION_CODES, STORE_KEYS } from '../../../../constants/apiConstants';
import { useDispatch, useSelector } from 'react-redux';
import { paths, ROUTES_PATH } from '../../../../constants/paths';

const options = {
    DEFAULT: {
        message: '',
        open: false,
        type: ''
    }
};

type ComponentProps = { data: any };
const ValidateOTPTemplate = (props: ComponentProps) => {
    const { data } = props;
    const history = useHistory();
    const [showPassword, setShowPassword] = useState(false);
    const [validationSchema, setValidationSchema] = useState({});
    const [disableLogin, setDisableLogin] = useState(true);
    const [resendEnabled, setResendEnabled] = useState(false);
    const [timer, setTimer] = useState(60); // Timer set to 60 seconds
    const dispatch = useDispatch();
    const formRef = useRef<any>();
    const [snackbarOptions, setSnackbarOptions] = useState(options.DEFAULT);
    const acgStateSelector = createStructuredSelector({
        acgSlice: acgSelector()
    });
    const { acgSlice: state } = useSelector(acgStateSelector);

    const initialValues = {
        otp: ''
    };

    useEffect(() => {
        setValidationSchema(Yup.object().shape(LOGIN_YUPSCHEMA));
    }, []);

    useEffect(() => {
        if (timer > 0) {
            const countdown = setInterval(() => {
                setTimer((prev) => prev - 1);
            }, 1000);
            return () => clearInterval(countdown);
        } else {
            setResendEnabled(true);
        }
    }, [timer]);

    const handleResendOTP = async () => {
        setResendEnabled(false);
        setTimer(60); // Reset the timer

        const bundle = {
            payload: {
                urlPath: ACTION_CODES.RESEND_OTP, // Change this to your actual resend OTP API path
                requestType: 'POST',
                reqObj: { email: state?.loginData?.emailForOTP }
            },
            storeKey: STORE_KEYS.RESEND_OTP
        };

        // Dispatch the API action
        dispatch(executeACGAction(bundle));
    };

    const handleSubmit = async () => {
        const bundle = {
            payload: {
                urlPath: ACTION_CODES.VERIFY_OTP,
                requestType: 'POST',
                reqObj: { email: state?.loginData?.emailForOTP, otp: formRef?.current?.values?.otp }
            },
            uniqueScreenIdentifier: { isLoggedIn: true, emailForOTP: state?.loginData?.emailForOTP },
            storeKey: STORE_KEYS.LOGIN_DATA
        };

        dispatch(executeACGAction(bundle));
    };

    const closeSnackbar = () => setSnackbarOptions(options.DEFAULT);

    const getRoutes = (__data: any) => {
        const newRoutes: any = [];
        console.log('-----routes', __data);
        // if(__data && __data.length > 0){
        // __data =   [...__data,
        //     {
        //         FeatureCode:"GOLDBATCH",
        //         FeatureHeaderName: "Golden Batch",
        //         FeatureName: "Golden Batch",
        //         FeatureType: "Admin",
        //         IsEditable: "Y",
        //         IsHomePage: "N",
        //         ModuleCode: "ADMIN",
        //         ModuleName: "Admin"
        //     }
        // ]
        // }
        __data?.forEach((newroute: any) => {
            ROUTES_PATH?.forEach((route: any) => {
                const __route = { ...route };
                if (newroute.FeatureCode === route.FeartureCode) {
                    __route.isAccess = true;
                    __route.isEditable = newroute?.IsEditable === 'Y';
                    __route.isHomePage = newroute?.IsHomePage === 'Y';
                    __route.title = newroute?.FeatureName;
                    __route.Module = newroute?.ModuleName;
                    newRoutes.push(__route);
                }
            });
        });
        return newRoutes.filter((v: any, i: any, self: any) => i === self?.findIndex((t: any) => t.path == v.path));
    };

    useEffect(() => {
        if (state?.loginData?.isLoggedIn) {
           
                if (state?.loginData?.statusCode === 200) {
                    const newRoutes = getRoutes(state?.loginData?.body?.data?.features);
                    dispatch(
                        setStoreKey({
                            storeKey: STORE_KEYS.USER_DTL,
                            value: state?.loginData?.body?.data
                        })
                    );
                    dispatch(
                        setStoreKey({
                            storeKey: STORE_KEYS.DEFAULT_PAGE,
                            value: newRoutes?.filter((d: any) => d.isHomePage)?.[0]?.path || newRoutes?.[0]?.path
                        })
                    );
                    const { body, ...newLoginData } = state?.loginData;
                    dispatch(
                        setStoreKey({
                            storeKey: STORE_KEYS.LOGIN_DATA,
                            value: newLoginData
                        })
                    );
                    dispatch(
                        setStoreKey({
                            storeKey: STORE_KEYS.ROUTES_PRIVILEGES,
                            value: newRoutes
                        })
                    );
                    const defaultPath = newRoutes?.filter((d: any) => d.isHomePage)?.[0]?.path || newRoutes?.[0]?.path;
                    history.push(defaultPath);
                    console.log(defaultPath)
                } else if (state?.loginData?.statusCode === 202) {
                    dispatch(
                        updateScreenIdentifiers({
                            storeKey: STORE_KEYS.LOGIN_DATA,
                            uniqueScreenIdentifier: {
                                emailId: formRef?.current?.values?.emailId,
                                currentPwd: formRef?.current?.values?.password,
                                isLoggedIn: false
                            }
                        })
                    );
                    data?.setTemplateType('setSecurityQn');
                }
        }
    }, [state?.loginData?.body?.data?.EmailId]);

    const handleSnackbarError = (err: any) => {
        const errorMsg = err?.message || 'Internal Server error';
        const snackbarError = {
            message: errorMsg,
            type: 'reject',
            open: true
        };
        setSnackbarOptions(snackbarError);
    };

    useEffect(() => {
        if (state?.err) {
            if (state?.err?.message?.toLowerCase()?.includes('is locked')) {
                data?.setTemplateType('lockAccount');
                dispatch(resetErr());
            } else {
                if (window.newrelic) {
                    window.newrelic.noticeError(state?.err.message);
                }
                handleSnackbarError(state?.err);
                dispatch(resetErr());
            }
        }
    }, [state?.err]);

    return (
        <Paper elevation={2} className="loginPaper simpleLogin">
            <Snackbar className="login-snackbar" options={snackbarOptions} handleClose={closeSnackbar} />
            <div className="loginFormHeader">
                <Typography className="welcomeText">OTP Verification</Typography>
                <Typography className="loginText"> OTP has been sent to {state?.loginData?.emailForOTP}. Please check. </Typography>
            </div>
            <Formik
                initialValues={initialValues}
                validationSchema={validationSchema}
                onSubmit={handleSubmit}
                validateOnBlur={false}
                innerRef={formRef}
            >
                {(formikprops) => {
                    const { values, handleChange, handleBlur, isValid, errors, touched } = formikprops;
                    return (
                        <Form>
                            <Label label="OTP" />
                            <TextInput
                                variant="outlined"
                                maxLength={6}
                                autoFocus
                                fullWidth
                                type="text"
                                name="otp"
                                formInput="textBoxDiv"
                                onBlur={handleBlur}
                                helperText={errors.otp && touched.otp ? errors.otp : ''}
                                value={values.otp}
                                onChange={(e) => {
                                    setDisableLogin(false);
                                    handleChange(e);
                                }}
                                placeholder="Enter OTP"
                            />
                            <Button
                                formInput="buttonDiv"
                                className="simpleLoginButton"
                                fullWidth
                                name="Verify OTP"
                                disabled={disableLogin || !isValid}
                                type="submit"
                                variant="contained"
                                secondary={false}
                            />
                            <div className="resendOtpContainer">
                              
                                <IconButton
                                    onClick={handleResendOTP}
                                    disabled={!resendEnabled}
                                    color="primary"
                                    aria-label="resend OTP"
                                    type='button'
                                >   Resend OTP <span>{timer > 0 ? `in ${timer}s` :   <RestoreIcon /> }</span>
                                </IconButton>
                            </div>
                        </Form>
                    );
                }}
            </Formik>
        </Paper>
    );
};

export default ValidateOTPTemplate;
