import 'react-app-polyfill/ie11';
import 'react-app-polyfill/stable';
import { render } from 'react-dom';
import configureAppStore from './app/store/index';
import { Provider } from 'react-redux';
import { PersistGate } from 'redux-persist/integration/react';
import { persistStore } from 'redux-persist';
import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFns';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import App from './app/index';
import './index.scss';

const initialState = {};
const store = configureAppStore(initialState);
const persistor = persistStore(store);

render(
    <Provider store={store}>
        <LocalizationProvider dateAdapter={AdapterDateFns}>
            <PersistGate loading={null} persistor={persistor}>
                <App />
            </PersistGate>
        </LocalizationProvider>
    </Provider>,
    document.getElementById('root')
);
if (module.hot) {
    module.hot.accept();
}
