import { useEffect, useRef, useState } from 'react';
import { Grid } from '@mui/material';
import ACGButton from '../../../../atoms/Button';
import Label from '../../../../atoms/Label/index';
import TextInput from '../../../../atoms/TextInput';
import { Formik, Form, FieldArray } from 'formik';
import * as Yup from 'yup';
import { PLANTLINE_YUPSCHEMA } from '../schema';
import { useDispatch } from 'react-redux';
import { executeACGAction, updateScreenIdentifiers } from '../../../../store/slice';
import { ACTION_CODES, STORE_KEYS } from '../../../../constants/apiConstants';
import useGetState from '../../../../utils/hooks/useGetState';
import PlusIcon from '../../../../../assets/images/PlusIcon.svg';
import BinIcon from '../../../../../assets/images/bin.svg';
import '../../FeatureManagement/CreateReport/index.scss';

type Props = {
    drawerClose: Function;
    setCurrTab: Function;
    setReload: Function;
    setSnackbarOpts: Function;
    setStep: Function;
    dtlData?: any;
    setDtlData?: Function;
};

const options = {
    AD_SUCCESS: {
        message: 'Plant updated successfully!',
        open: true,
        type: 'success'
    }
};
const PlantLine = (props: Props) => {
    const {
        drawerClose,
        setCurrTab,
        setReload = () => {},
        setSnackbarOpts = () => {},
        setStep,
        dtlData,
        setDtlData = () => {}
    } = props;
    const oldLines = JSON.parse(JSON.stringify(dtlData?.lines));

    for (const val of oldLines) {
        val.pushedItem = false;
    }
    const [lines, setLines] = useState<any>([]);
    const formRef = useRef<any>(null);
    const [validationSchema, setValidationSchema] = useState({});
    const [initialValues] = useState(
        oldLines?.length > 0
            ? { lines: oldLines }
            : {
                  lines: [
                      {
                          PlantLineNoCode: '',
                          PlantLineNoName: '',
                          pushedItem: true
                      }
                  ]
              }
    );

    const dispatch = useDispatch();
    const state = useGetState();

    useEffect(() => {
        setValidationSchema(Yup.object().shape({ lines: Yup.array().of(Yup.object().shape(PLANTLINE_YUPSCHEMA)) }));
    }, []);

    const handleSubmit = async (values: any) => {
        setLines(values?.lines);
        dispatch(
            executeACGAction({
                payload: {
                    urlPath: ACTION_CODES.EDIT_PLANT,
                    requestType: 'PUT',
                    reqObj: {
                        customerKey: dtlData?.CustomerKey,
                        locationKey: dtlData?.LocationKey,
                        plantCode: dtlData?.PlantCode,
                        plantName: dtlData?.PlantName,
                        plantAddress: dtlData?.PlantAddress,
                        plantGPSLocation: '1',
                        timeZoneKey: dtlData?.TimeZoneKey,
                        shiftStartTime: dtlData?.ShiftStartTime?.toString()
                    }
                },
                uniqueScreenIdentifier: { plantUpdated: true },
                storeKey: STORE_KEYS.ADD_RPT
            })
        );
    };
    const [finalLines, setFinalLines] = useState<any>([]);
    useEffect(() => {
        if (state?.[STORE_KEYS.ADD_RPT]?.plantUpdated) {
            if (state?.[STORE_KEYS.ADD_RPT]?.statusCode == 200) {
                const finalLinesVal = [];
                for (const val of lines) {
                    finalLinesVal.push({
                        PlantLineNoCode: val?.PlantLineNoCode,
                        PlantLineNoName: val?.PlantLineNoName
                    });
                }
                setFinalLines(finalLinesVal);
                dispatch(
                    executeACGAction({
                        payload: {
                            urlPath: ACTION_CODES.ADD_PLANTLINE,
                            requestType: 'POST',
                            reqObj: {
                                plantLineNos: {
                                    PlantKey: state?.[STORE_KEYS.ADD_RPT]?.body?.data?.plantKey,
                                    PlantLineNos: finalLinesVal
                                }
                            }
                        },
                        uniqueScreenIdentifier: { lineAdded: true },
                        storeKey: STORE_KEYS.ADD_LINE
                    })
                );
            }
            dispatch(
                updateScreenIdentifiers({
                    storeKey: STORE_KEYS.ADD_RPT,
                    uniqueScreenIdentifier: {
                        plantUpdated: false
                    }
                })
            );
        }
    }, [state?.[STORE_KEYS.ADD_RPT]?.plantUpdated]);

    useEffect(() => {
        if (state?.[STORE_KEYS.ADD_LINE]?.lineAdded) {
            if (state?.[STORE_KEYS.ADD_LINE]?.statusCode === 200) {
                setDtlData({ ...dtlData, lines: finalLines });
                setReload(true);
                setCurrTab(1);
                setSnackbarOpts(options.AD_SUCCESS);
                drawerClose();
            }
            dispatch(
                updateScreenIdentifiers({
                    storeKey: STORE_KEYS.ADD_LINE,
                    uniqueScreenIdentifier: {
                        lineAdded: false
                    }
                })
            );
        }
    }, [state?.[STORE_KEYS.ADD_LINE]?.lineAdded]);

    const renderButtons = () => {
        return (
            <div className={'btns noWrap'}>
                <ACGButton
                    name={'Back'}
                    handleClick={() => setStep(0)}
                    secondary={true}
                    className={'first-btn'}
                    type="button"
                    variant="contained"
                />
                <ACGButton
                    name={'Submit'}
                    handleClick={() => {}}
                    type="submit"
                    className="second-btn"
                    variant="contained"
                />
            </div>
        );
    };

    return (
        <>
            <div className="userDetailDiv">
                <div className="detailDivChild firstChild">
                    <p>Client</p>
                    <h6>{dtlData?.clientVal}</h6>
                </div>
                <div className={'detailDivChild '}>
                    <p>Location</p>
                    <h6>{`${dtlData?.stateVal}, ${dtlData?.ctryVal}`}</h6>
                </div>
            </div>
            <Formik
                initialValues={initialValues}
                validationSchema={validationSchema}
                onSubmit={(values: any) => {
                    handleSubmit(values);
                }}
                validateOnBlur={false}
                validateOnChange={false}
                innerRef={formRef}
            >
                {(formikprops: any) => {
                    const { values, handleChange, handleBlur, errors, touched } = formikprops;
                    return (
                        <Form>
                            <FieldArray
                                name={'lines'}
                                render={(fieldArrayProps) => {
                                    return values?.lines?.map((line: any, idx: any) => {
                                        const index = idx + 1;
                                        return (
                                            <>
                                                <Grid container spacing={2} key={index}>
                                                    <Grid item xs={12} sm={12} md={6} xl={6} lg={6}>
                                                        <Label label="Line Code" className={'labelDiv'} />
                                                        <TextInput
                                                            variant="outlined"
                                                            fullWidth
                                                            type="text"
                                                            name={`lines[${idx}].PlantLineNoCode`}
                                                            value={line.PlantLineNoCode}
                                                            disabled={line?.pushedItem ? false : true}
                                                            autoComplete="off"
                                                            formInput="inputDiv"
                                                            onChange={(e: any) => {
                                                                handleChange(e);
                                                            }}
                                                            onBlur={handleBlur}
                                                            placeholder="Enter Line Code"
                                                            helperText={
                                                                errors?.lines?.[idx]?.PlantLineNoCode &&
                                                                touched?.lines?.[idx]?.PlantLineNoCode
                                                                    ? errors?.lines?.[idx]?.PlantLineNoCode
                                                                    : ''
                                                            }
                                                        />
                                                    </Grid>
                                                    <Grid item xs={12} sm={12} md={6} xl={6} lg={6}>
                                                        <Label label="Line Name" className={'labelDiv'} />
                                                        <div className="lineName">
                                                            <TextInput
                                                                variant="outlined"
                                                                fullWidth
                                                                type="text"
                                                                name={`lines[${idx}].PlantLineNoName`}
                                                                value={line.PlantLineNoName}
                                                                autoComplete="off"
                                                                formInput="inputDiv"
                                                                onChange={(e: any) => {
                                                                    handleChange(e);
                                                                }}
                                                                onBlur={handleBlur}
                                                                placeholder="Enter Line Name"
                                                                helperText={
                                                                    errors?.lines?.[idx]?.PlantLineNoName &&
                                                                    touched?.lines?.[idx]?.PlantLineNoName
                                                                        ? errors?.lines?.[idx]?.PlantLineNoName
                                                                        : ''
                                                                }
                                                            />
                                                            {values?.lines?.length != 1 && (
                                                                <img
                                                                    className="binIcon"
                                                                    src={BinIcon}
                                                                    alt="BinIcon"
                                                                    onClick={() => {
                                                                        fieldArrayProps.remove(idx);
                                                                    }}
                                                                />
                                                            )}
                                                        </div>
                                                    </Grid>
                                                </Grid>
                                                {idx == values?.lines?.length - 1 && (
                                                    <div
                                                        className="plantLine"
                                                        onClick={() => {
                                                            fieldArrayProps.push({
                                                                PlantLineNoCode: '',
                                                                PlantLineNoName: '',
                                                                pushedItem: true
                                                            });
                                                        }}
                                                    >
                                                        <img className="PlusIcon" src={PlusIcon} alt="PlusIcon" />
                                                        <span>{'Add Line'}</span>
                                                    </div>
                                                )}
                                            </>
                                        );
                                    });
                                }}
                            />
                            {renderButtons()}
                        </Form>
                    );
                }}
            </Formik>
        </>
    );
};

export default PlantLine;
