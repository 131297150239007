import React, { useState, useEffect } from 'react';
import { useDispatch } from 'react-redux';
// material
import { Paper, Typography } from '@mui/material';
import { Grid } from '@material-ui/core';
import CustomTableToolbar from '../../../../atoms/CustomTableToolbar';
import Button from '@mui/material/Button';
import { Container, Card } from '@mui/material';
import { ACTION_CODES, STORE_KEYS } from '../../../../constants/apiConstants';
import useGetState from '../../../../utils/hooks/useGetState';
import Box from '@mui/material/Box';
import Slider from '@mui/material/Slider';
import SnackbarAlert from '../../../../atoms/Snackbar';
import Graph from './graph';
import GraphFBE from './graphFBE';
import ChevronLeftIcon from '@mui/icons-material/ChevronLeft';
import ChevronRightIcon from '@mui/icons-material/ChevronRight';
import Label from '../../../../atoms/Label/index';
import DropDown from '../../../../atoms/Dropdown';
import NotificationCard from '../../components/notificationCard';
import moment from 'moment';

const options = {
    DEFAULT: {
        message: '',
        open: false,
        type: ''
    },
    RM_SUCCESS: {
        message: 'Preset Alert Deleted',
        open: true,
        type: 'success'
    },
};

type Anchor = 'right';

const Users = (props: any) => {

    const {
        closeSnackbar,
        appliedFilters,
        gbData,
        chart1ActivePage,
        updateActivePage,
        totalPages,
        parameterActiveName,
        selectedSerial,
        setDefVal,
        defVal,
        hSliderActiveT,
        setHSliderActiveT,
        setVSliderActiveB,
        setVSliderActiveT,
        vSliderActiveB,
        vSliderActiveT,
        selectedRecipe,
        selectedBatch,
        machineModelName,
        status,
        setSelectedActiveSerial,
        serialActiveData,
        selectedActiveSerial,
        selectedActiveParameter,
        setSelectedActiveParameter,
        parameterActiveData,
        fetchParameters,
        setChart1ActivePage,
        activeAlerts,
        getActiveBatchAlertsData,
        newActiveAlertItems
    } = props;

    const dispatch = useDispatch();
    const [drawerState, setDrawerState] = useState({
        right: false
    });
    const [selected, setSelected] = useState<any>([]);
    const [filterName, setFilterName] = useState<string>('');
    const [right, setRight] = useState(false);
    const [alerstData, setAlertsData] = useState([]);
    const [alarmsData, setAlarmsData] = useState([]);
    const [phaseList, setPhaseList] = useState<any>([]);
    const [uniquePhaseListUnsorted, setUniquePhaseListUnsorted] = useState<any>([]);
    const state = useGetState();
    const token = state?.[STORE_KEYS.USER_DTL].token;
    const getLoggedUserId = state[STORE_KEYS.USER_DTL]?.LoggedUserKey;

    useEffect(() => {
        setAlarmsData([]);
        setAlertsData([]);
    },[machineModelName, selectedActiveSerial, selectedActiveParameter])

    const lastPage = totalPages;
    const [snackbarOptions, setSnackbarOptions] = useState(options.DEFAULT);

    function valuetext(value: any) {
        return `${value}`;
    }
    const chart1NextPageConditionCheck = lastPage > chart1ActivePage ? true : false;

    const pagination = async (count: number, operator: string) => {
        if (machineModelName.includes("BQS")) {
            setDefVal([0, state?.[STORE_KEYS.GOLDEN_BATCH_ACTIVE_DATA]?.body?.data?.length < 100 ? 100 : state?.[STORE_KEYS.GOLDEN_BATCH_ACTIVE_DATA]?.body?.data?.length])
            setHSliderActiveT([0, state?.[STORE_KEYS.GOLDEN_BATCH_ACTIVE_DATA]?.body?.data?.length < 100 ? 100 : state?.[STORE_KEYS.GOLDEN_BATCH_ACTIVE_DATA]?.body?.data?.length]);
            updateActivePage(chart1ActivePage, operator, count);
        } else {
            setDefVal([0, state?.[STORE_KEYS.GOLDEN_BATCH_DATA_ACTIVE_FBE]?.body?.data?.length < 100 ? 100 : state?.[STORE_KEYS.GOLDEN_BATCH_DATA_ACTIVE_FBE]?.body?.data?.length])
            setHSliderActiveT([0, state?.[STORE_KEYS.GOLDEN_BATCH_DATA_ACTIVE_FBE]?.body?.data?.length < 100 ? 100 : state?.[STORE_KEYS.GOLDEN_BATCH_DATA_ACTIVE_FBE]?.body?.data?.length]);
            updateActivePage(chart1ActivePage, operator, count);
        }
    }

    useEffect(() => {
        if (state?.[STORE_KEYS.GOLDEN_BATCH_ACTIVE_DATA]?.body?.data?.length) {
            const LL: any = [], UL: any = [], AV: any = [];
            state?.[STORE_KEYS.GOLDEN_BATCH_ACTIVE_DATA]?.body?.data.map((i: any) => {
                LL.push(i.OptimumLowerLimit);
                UL.push(i.OptimumUpperLimit);
                AV.push(i.ActualValue)
            })
            setVSliderActiveB(Math.min(...LL) < Math.min(...AV) ? Math.min(...LL) : Math.min(...AV));
            setVSliderActiveT(Math.max(...LL) > Math.max(...AV) ? Math.max(...LL) : Math.max(...AV));
            setHSliderActiveT([0, state?.[STORE_KEYS.GOLDEN_BATCH_ACTIVE_DATA]?.body?.data?.length < 100 ? 100 : state?.[STORE_KEYS.GOLDEN_BATCH_ACTIVE_DATA]?.body?.data?.length])
            setDefVal([0, state?.[STORE_KEYS.GOLDEN_BATCH_ACTIVE_DATA]?.body?.data?.length < 100 ? 100 : state?.[STORE_KEYS.GOLDEN_BATCH_ACTIVE_DATA]?.body?.data?.length])
        }
    }, [state?.[STORE_KEYS.GOLDEN_BATCH_ACTIVE_DATA]?.body?.data])

    useEffect(() => {
        if (state?.[STORE_KEYS.GOLDEN_BATCH_DATA_ACTIVE_FBE]?.body?.data?.length) {
            const LL: any = [], UL: any = [], AV: any = [], items: any = [];
            state?.[STORE_KEYS.GOLDEN_BATCH_DATA_ACTIVE_FBE]?.body?.data.map((i: any) => {
                LL.push(i.OptimumLowerLimit);
                UL.push(i.OptimumUpperLimit);
                AV.push(i.ActualValue)
            })
            setVSliderActiveB(Math.min(...LL) < Math.min(...AV) ? Math.min(...LL) : Math.min(...AV));
            setVSliderActiveT(Math.max(...LL) > Math.max(...AV) ? Math.max(...LL) : Math.max(...AV));
            setHSliderActiveT([0, state?.[STORE_KEYS.GOLDEN_BATCH_DATA_ACTIVE_FBE]?.body?.data?.length < 100 ? 100 : state?.[STORE_KEYS.GOLDEN_BATCH_DATA_ACTIVE_FBE]?.body?.data?.length])
            setDefVal([0, state?.[STORE_KEYS.GOLDEN_BATCH_DATA_ACTIVE_FBE]?.body?.data?.length < 100 ? 100 : state?.[STORE_KEYS.GOLDEN_BATCH_DATA_ACTIVE_FBE]?.body?.data?.length])
            const phases = state?.[STORE_KEYS.GOLDEN_BATCH_DATA_ACTIVE_FBE]?.body?.data.map((item: any, index: number) => {
                items.push({ 'key': item?.PhaseKey, name: item?.PhaseName, index: index });
                return ({ key: item?.PhaseKey, color: item.PhaseColour, name: item?.PhaseName, index: index })
            });

            const sortedItems = [...items].sort((a: any, b: any) => a.ind - b.ind);
            const indices: any = [];
            [...sortedItems].map((item: any,) => {
                indices.push(getIndices(items, 'key', item.key))
            });
            const countDuplicates = indices.reduce((acc: any, current: any, index: number) => {
                const found = acc.find((item: any) => item.key === current.key);
                if (found) {
                    found.count += 1;
                } else {
                    acc.push({ key: current.key, count: 1, index: index });
                }
                return acc;
            }, []);
            setPhaseList(phases);
            setUniquePhaseListUnsorted(countDuplicates);
        }
    }, [state?.[STORE_KEYS.GOLDEN_BATCH_DATA_ACTIVE_FBE]?.body?.data]);

    const updateSliderPhase = (sliderData:any) => {
        const items: any = [];
        const phases = sliderData.reverse().map((item: any, index: number) => {
            items.push({ 'key': item?.phase, name: item?.phaseName, index: index });
            return ({ key: item?.phase, color: item.color, name: item?.phaseName, index: index })
        });

        const sortedItems = [...items].sort((a: any, b: any) => a.ind - b.ind);

        const indices: any = [];
        [...sortedItems].map((item: any,) => {
            indices.push(getIndices(items, 'key', item.key))
        });
   
        const countDuplicates = indices.reduce((acc:any, current:any, index: number) => {
            const found = acc.find((item:any) => item.key === current.key);
            if (found) {
                found.count += 1;
            } else {
                acc.push({ key: current.key, count: 1, index: index });
            }
            return acc;
        }, []);
        setPhaseList(phases);
        setUniquePhaseListUnsorted(countDuplicates);
    }

    const getIndices = (array: any, key: any, value: any) => {
        const startIndex = array.findIndex((item: any) => item[key] === value);
        const endIndex = array.length - 1 - [...array].reverse().findIndex(item => item[key] === value);
        return { startIndex, endIndex, key: value };
    };

    const minDistance = 0;

    const percentage = (partialValue: any, totalValue: any) => {
        let indexAtPercentage = Math.round((totalValue) * partialValue / 100);
        return (indexAtPercentage)
    }

    const handleChange2 = (
        event: Event,
        newValue: number | number[],
        activeThumb: number,
    ) => {
        if (!Array.isArray(newValue)) {
            return;
        }

        if (newValue[1] - newValue[0] < minDistance) {
            if (activeThumb === 0) {
                const clamped = Math.min(newValue[0], 100 - minDistance);
                setDefVal([clamped, clamped + minDistance]);
            } else {
                const clamped = Math.max(newValue[1], minDistance);
                setDefVal([clamped - minDistance, clamped]);
            }
        } else {
            const length = machineModelName.includes("BQS") ? state?.[STORE_KEYS.GOLDEN_BATCH_ACTIVE_DATA]?.body?.data.length : state?.[STORE_KEYS.GOLDEN_BATCH_DATA_ACTIVE_FBE]?.body?.data.length;
            setHSliderActiveT([percentage(newValue[0], length), percentage(newValue[1], length)])
            setDefVal(newValue as number[]);
            if(!machineModelName.includes("BQS")) {
                const newData = state?.[STORE_KEYS.GOLDEN_BATCH_DATA_ACTIVE_FBE]?.active?.slice(percentage(newValue[0], length),percentage(newValue[1], length))
                updateSliderPhase(newData);
                }
        }
    };

    return (
        <div className='pmec-table goldenBatchCls'>
            <div className='pmec-tableInner'>
                <SnackbarAlert options={snackbarOptions} handleClose={closeSnackbar} />
                <Container
                    maxWidth="xl"
                    style={{
                        paddingLeft: '0px',
                        paddingRight: '0px'
                    }}
                >
                    <Card
                        style={{
                            borderRadius: '0px',
                            backgroundColor: '#2b2d42',
                            overflow: 'inherit'
                        }}
                    >
                    </Card>
                    <Card style={{
                        borderRadius: '0px',
                        backgroundColor: '#2b2d42',
                        overflow: 'inherit'
                    }}>
                        <Grid container md={12} style={{ display: "flex", padding: "0 14px" }} className='mainGridItem'>
                            <Grid item md={5} style={{ display: "flex", paddingTop: 15, paddingBottom: '5px' }}>
                                <DropDown
                                    onChange={(snEvent: any) => {
                                        setSelectedActiveSerial(snEvent.target.value);
                                        setSelectedActiveParameter('');
                                        fetchParameters(snEvent.target.value, machineModelName);
                                        setChart1ActivePage(0);

                                    }}
                                    name="dropdownSerialNo"
                                    items={serialActiveData}
                                    placeHolder="Select Line / Serial Number"
                                    value={selectedActiveSerial}
                                    style={{ marginRight: '6px' }}
                                />
                                <DropDown
                                    onChange={(snEvent: any) => {
                                        setSelectedActiveParameter(snEvent.target.value);
                                        setChart1ActivePage(0);
                                    }}
                                    name="dropdownParameter"
                                    items={parameterActiveData}
                                    placeHolder="Select Parameter"
                                    value={selectedActiveParameter}
                                    style={{ marginRight: '6px' }}
                                />
                            </Grid>
                            <Grid item md={7} style={{ paddingTop: 15, paddingBottom: '5px' }} className='activeGB'>
                                {machineModelName.includes("BQS") ?
                                    <div style={{ display: "flex" }} className='filterdata'>
                                        <Typography className='ptag'>Product Name : <strong className='itag'>{state?.[STORE_KEYS.GOLDEN_BATCH_ACTIVE_DATA]?.active?.length > 0  ? state?.[STORE_KEYS.GOLDEN_BATCH_ACTIVE_DATA]?.active[0]?.productName : "NA"}</strong></Typography>
                                        <Typography className='ptag'>Batch Start : <strong className='itag'>{state?.[STORE_KEYS.GOLDEN_BATCH_ACTIVE_DATA]?.active?.length > 0  ? moment.utc(state?.[STORE_KEYS.GOLDEN_BATCH_ACTIVE_DATA]?.active[0]?.batchStart).format("DD/MM/YYYY HH:mm:ss") : "NA"}</strong></Typography>
                                        <Typography className='ptag'>Set Value : <strong className='itag'>{state?.[STORE_KEYS.GOLDEN_BATCH_ACTIVE_DATA]?.active?.length > 0  ? state?.[STORE_KEYS.GOLDEN_BATCH_ACTIVE_DATA]?.active[0]?.setValue : "NA"}</strong></Typography>
                                        <Typography className='ptag'>Ref Set Value : <strong className='itag'>{state?.[STORE_KEYS.GOLDEN_BATCH_ACTIVE_DATA]?.active?.length > 0  ? state?.[STORE_KEYS.GOLDEN_BATCH_ACTIVE_DATA]?.active[0]?.refSetValue : "NA"}</strong></Typography>
                                    </div>
                                    :
                                    <div style={{ display: "flex" }} className='filterdata'>
                                        <Typography className='ptag'>Product Name : <br /><strong className='itag'>{state?.[STORE_KEYS.GOLDEN_BATCH_DATA_ACTIVE_FBE]?.body?.data[0]?.ProductName ? state?.[STORE_KEYS.GOLDEN_BATCH_DATA_ACTIVE_FBE]?.body?.data[0]?.ProductName : "NA"}</strong></Typography>
                                        <Typography className='ptag'>Batch Start : <br /><strong className='itag'>{state?.[STORE_KEYS.GOLDEN_BATCH_DATA_ACTIVE_FBE]?.body?.data[0]?.BatchStartTime ? moment.utc(state?.[STORE_KEYS.GOLDEN_BATCH_DATA_ACTIVE_FBE]?.body?.data[0]?.BatchStartTime).format("DD/MM/YYYY HH:mm:ss") : "NA"}</strong></Typography>
                                    </div>
                                }
                            </Grid>

                            <div className={`left-content ${!right ? "notfilter" : "filterapplied"}`}>
                                <Grid item>

                                    <div className='yaxisbar'>{parameterActiveName ? parameterActiveName : ""}</div>
                                </Grid>
                                <Grid item>
                                    {machineModelName.includes("BQS") ?
                                        <Graph
                                            gbData={gbData}
                                            vSliderActiveT={vSliderActiveT}
                                            vSliderActiveB={vSliderActiveB}
                                            hSliderActiveT={hSliderActiveT}
                                            appliedFilters={appliedFilters}
                                            selectedSerial={selectedActiveSerial}
                                            setAlertsData={setAlertsData}
                                            setAlarmsData={setAlarmsData}
                                            rightSlider={right}
                                            status={status}
                                        />
                                        :
                                        <GraphFBE
                                            status={status}
                                            gbData={gbData}
                                            vSliderActiveT={vSliderActiveT}
                                            vSliderActiveB={vSliderActiveB}
                                            hSliderActiveT={hSliderActiveT}
                                            appliedFilters={appliedFilters}
                                            selectedSerial={selectedActiveSerial}
                                            setAlertsData={setAlertsData}
                                            setAlarmsData={setAlarmsData}
                                            rightSlider={right}
                                            phaseList={phaseList}
                                            uniquePhaseListUnsorted={[...uniquePhaseListUnsorted.reverse()]}
                                            updateSliderPhase={updateSliderPhase}
                                        />
                                    }
                                    <div className='divwrap gbDivWrap'>
                                        <div className='buttonwrap'
                                        >
                                            <Button variant="contained" size="small"
                                                style={chart1NextPageConditionCheck && lastPage != 1 ? { color: '#e0e0e0', textTransform: 'capitalize', lineHeight: '1', padding: '10px 10px', marginRight: "8px" } : { padding: '10px', marginLeft: "0px", marginRight: "8px" }}
                                                disabled={chart1NextPageConditionCheck && lastPage != 1 ? false : true} onClick={() => { pagination(1, '+'); }}>
                                                Previous
                                            </Button>
                                            <Slider style={{ marginLeft: "4px", marginRight: "4px" }}
                                                getAriaLabel={() => 'Minimum distance'}
                                                value={defVal}
                                                onChange={handleChange2}
                                                valueLabelDisplay="auto"
                                                getAriaValueText={valuetext}
                                                disableSwap
                                                disabled={state?.[STORE_KEYS.GOLDEN_BATCH_ACTIVE_DATA]?.body?.data.length || state?.[STORE_KEYS.GOLDEN_BATCH_DATA_ACTIVE_FBE]?.body?.data.length > 0 ? false : true}
                                            />
                                            <Button variant="contained" size="small"
                                                style={chart1ActivePage > 1 ? { color: '#e0e0e0', textTransform: 'capitalize', lineHeight: '1', padding: '10px 10px', marginLeft: "8px" } : { padding: '10px', marginLeft: "8px" }}
                                                disabled={chart1ActivePage > 1 ? false : true} onClick={() => { pagination(1, '-') }}>
                                                Next
                                            </Button>
                                        </div>
                                    </div>
                                </Grid>
                            </div>
                            <div className='arrowSwapActive'>
                                {(!right) && <ChevronRightIcon onClick={() => setRight(true)} />}
                                {right && <ChevronLeftIcon onClick={() => setRight(false)} />}
                            </div>
                            {(!right) ?
                                <div className='right-content'>
                                    <Grid item>

                                        <Box sx={{ width: '100%', maxWidth: '100%', bgcolor: '#1d1e2c', padding: "12px", borderRadius: '8px' }}>
                                            <Typography style={{ color: "#ffffff", fontSize: "12px", fontWeight: "bold", lineHeight: '12px', marginBottom: '10px' }} ><span className='AlarmDot'></span>Alarm Details</Typography>
                                            <table style={{ border: "1px solid #817272", width: '100%' }}>
                                                <tr style={{ color: "#ffffff", fontWeight: "normal", fontSize: "12px", border: "1px solid #817272", padding: "4px 6px" }}><th style={{ color: "#ffffff", fontWeight: "normal", fontSize: "12px", border: "1px solid #817272", padding: "4px 6px" }}>Parameter Name</th><th style={{ color: "#ffffff", fontWeight: "normal", fontSize: "12px", border: "1px solid #817272", padding: "4px 6px" }}>Triggered Time</th></tr>
                                                {
                                                    alarmsData?.map((i: any) => {
                                                        return (
                                                            <tr style={{ color: "#ffffff", fontWeight: "normal", fontSize: "12px", border: "1px solid #817272", padding: "4px 6px" }}><td style={{ color: "#ffffff", fontWeight: "normal", fontSize: "12px", border: "1px solid #817272", padding: "4px 6px" }}>{i.ParamName}</td><td style={{ color: "#ffffff", fontWeight: "normal", fontSize: "12px", border: "1px solid #817272", padding: "4px 6px" }}>
                                                                {i.TriggerTime}
                                                            </td></tr>
                                                        )
                                                    })
                                                }
                                            </table>
                                        </Box>
                                        <Box sx={{ width: '100%', maxWidth: 350, bgcolor: '#1d1e2c', padding: "12px", marginTop: "8px", borderRadius: '8px' }}>
                                            <Typography style={{ color: "#ffffff", fontSize: "12px", fontWeight: "bold", lineHeight: '12px', marginBottom: '10px' }} ><span className='AlertsDot'></span>Alerts Details</Typography>
                                            <table style={{ border: "1px solid #817272", width: '100%' }}>
                                                <tr style={{ color: "#ffffff", fontSize: "12px", border: "1px solid #817272", padding: "4px 6px" }}><th style={{ color: "#ffffff", fontWeight: "normal", fontSize: "12px", border: "1px solid #817272", padding: "4px 6px" }}>Parameter Name</th><th style={{ color: "#ffffff", fontWeight: "normal", fontSize: "12px", border: "1px solid #817272", padding: "4px 6px" }}>Triggered Time</th></tr>
                                                {
                                                    alerstData?.map((i: any) => {
                                                        return (
                                                            <tr style={{ color: "#ffffff", fontSize: "12px", border: "1px solid #817272", padding: "4px 6px" }}><td style={{ color: "#ffffff", fontWeight: "normal", fontSize: "12px", border: "1px solid #817272", padding: "4px 6px" }}>{i.AlertName}</td><td style={{ color: "#ffffff", fontWeight: "normal", fontSize: "12px", border: "1px solid #817272", padding: "4px 6px" }}>
                                                                {/* {new Date(i.TriggerTime)} */}
                                                                {i.TriggerTime}
                                                            </td></tr>
                                                        )
                                                    })
                                                }
                                            </table>
                                        </Box>
                                    </Grid>
                                </div>
                                : null}
                        </Grid>
                    </Card>

                </Container>
                <div className='active-alerts'>
                    <h3>Golden Tunnel Deviations</h3>
                    <div className='cardwrap'>
                        <div className='scrollCards'>
                            {
                                 activeAlerts && activeAlerts?.map((item: any) => {
                                    return (
                                        <NotificationCard item={item} getActiveBatchAlertsData={getActiveBatchAlertsData} setSelectedActiveParameter={setSelectedActiveParameter} newActiveAlertItems={newActiveAlertItems} />
                                    )
                                })
                            }

                        </div>
                    </div>

                </div>
            </div>
        </div>
    );
};

export default Users;
