import React from 'react';
import Box from '@mui/material/Box';
import Drawer from '@mui/material/Drawer';
import './index.scss';
import ACGButton from '../Button';

type Anchor = 'right';

type Props = {
    status: any;
    handlerClose: any;
    children: any;
    drawerHeader?: any;
    clearFn?: Function;
    applyFn?: Function;
};

export default function FilterDrawer(props: Props) {
    const { status, handlerClose, drawerHeader, children, clearFn = () => {}, applyFn = () => {} } = props;
    const [state, setState] = React.useState({
        right: false
    });

    const toggleDrawer = (anchor: Anchor, open: boolean) => (event: React.KeyboardEvent | React.MouseEvent) => {
        if (
            event.type === 'keydown' &&
            ((event as React.KeyboardEvent).key === 'Tab' || (event as React.KeyboardEvent).key === 'Shift')
        ) {
            return;
        }

        setState({ ...state, [anchor]: open });
    };

    const handleClose = () => {
        handlerClose();
        toggleDrawer('right', false);
    };

    const list = () => (
        <Box sx={{ width: 250 }} role="presentation" className={'standardBox'}>
            <div
                style={{
                    padding: '20px',
                    paddingBottom: '0px',
                    borderBottom: '1px solid grey',
                    display: 'flex',
                    justifyContent: 'space-between'
                }}
            >
                <div
                    onClick={handleClose}
                    style={{
                        border: '1px solid #5D97F6',
                        borderRadius: '50px',
                        height: '25px',
                        width: '25px',
                        position: 'absolute',
                        left: '20px',
                        top: '17px',
                        cursor: 'pointer'
                    }}
                >
                    <span style={{ position: 'relative', fontSize: '12px', color: '#5D97F6', left: '35%', top: '-3%' }}>
                        X
                    </span>
                </div>
                <div
                    style={{
                        display: 'flex',
                        flexDirection: 'column',
                        color: '#DADCEE',
                        fontSize: '16px',
                        fontFamily: 'Montserrat',
                        marginBottom: '8px',
                        marginLeft: '45px'
                    }}
                >
                    <h6>{drawerHeader}</h6>
                </div>
            </div>
            <div style={{ borderBottom: '1px solid grey' }}> {children}</div>
            {renderButtons()}
        </Box>
    );
    const renderButtons = () => {
        return (
            <div className={'btn noWrap'}>
                <ACGButton
                    name={'Clear All'}
                    handleClick={clearFn}
                    secondary={true}
                    className={'first-bttn'}
                    type="button"
                    variant="contained"
                />
                <ACGButton
                    name={'Apply'}
                    handleClick={applyFn}
                    type="submit"
                    className="second-bttn"
                    variant="contained"
                />
            </div>
        );
    };

    return (
        <div>
            <Drawer
                anchor={'right'}
                open={status}
                onClose={handleClose}
                style={{ zIndex: '9999' }}
                className={'filterDiv'}
            >
                {list()}
            </Drawer>
        </div>
    );
}
