import React from 'react';
// import Button from '@material-ui/core/Button';
import { Menu, MenuItem, Typography, Button } from '@mui/material';
import './index.scss';
import InfoIcon from '@mui/icons-material/Info';

type ListType = {
    avatarURL?: string;
    userName: string;
};

type MenuProps = {
    itemsArray: ListType[];
    src?: any;
    // handleCloseMenuList?: () => void;
    // openMenuList?: any;
    infoOnly?: boolean;
    spocs?: Boolean;
    children?: any;
};

const Popover = (props: MenuProps) => {
    // const element = document.getElementById('anchor-button');
    const element = document.getElementById('panel1a-header');
    const [anchorEl, setAnchorEl] = React.useState<HTMLElement | null>(null);
    function handleClick(event: any) {
        if (anchorEl !== event.currentTarget) {
            setAnchorEl(event.currentTarget);
        }
    }

    function handleClose(event: any) {
        setAnchorEl(null);
    }
    const { itemsArray, src, infoOnly = false, spocs = false, children } = props;
    const renderMenuList = () => {
        if (infoOnly) {
            return (
                <MenuItem>
                    <Typography className="infoItem"> {itemsArray[0]?.userName}</Typography>
                </MenuItem>
            );
        } else if (spocs) {
            return children;
        } else {
            return itemsArray?.map((item, idx) => {
                const menuKey = idx + 1;
                return (
                    <MenuItem key={menuKey}>
                        {item?.avatarURL ? <img className="menuImage" src={item?.avatarURL} alt=" " /> : null}
                        <Typography className="menuItem"> {item?.userName}</Typography>
                    </MenuItem>
                );
            });
        }
    };

    return (
        <div>
            {src === '' && spocs && (
                <span style={{ color: '#B1C2DF', fontSize: '12px', fontWeight: '500' }}>SPOC Details</span>
            )}
            <Button
                id="anchor-button"
                aria-owns={anchorEl ? 'simple-menu' : ''}
                aria-haspopup="true"
                onClick={handleClick}
                onMouseOver={handleClick}
                className="popOverButton"
            >
                {src === '' ? <InfoIcon /> : <img src={src} />}
            </Button>
            {((spocs && children != null) || infoOnly || itemsArray?.length > 0) && (
                <Menu
                    id="simple-menu"
                    anchorEl={anchorEl}
                    open={Boolean(anchorEl)}
                    anchorOrigin={{
                        vertical: 'top',
                        horizontal: 'left'
                    }}
                    keepMounted
                    container={element}
                    transformOrigin={{
                        vertical: 'top',
                        horizontal: 'left'
                    }}
                    className="hoverCard"
                    onClose={handleClose}
                    MenuListProps={{ onMouseLeave: handleClose }}
                >
                    {renderMenuList()}
                </Menu>
            )}
        </div>
    );
};

export default Popover;