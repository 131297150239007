import { useState, useEffect, useRef, useMemo } from 'react';
import { Container, Card, Grid } from '@mui/material';
import AcgButton from '../../../../app/atoms/Button/index';
import { Formik, Form, FormikProps } from 'formik';
import * as Yup from 'yup';
import Label from '../../../../app/atoms/Label/index';
import TextInput from '../../../../app/atoms/TextInput';
import { executeACGAction, resetErr } from '../../../../app/store/slice';
import { useDispatch } from 'react-redux';
import useGetState from '../../../../app/utils/hooks/useGetState';
import { _noop } from '../../../../app/utils/common';
import { USER_YUPSCHEMA } from '../schema';
import { ACTION_CODES, STORE_KEYS } from '../../../../app/constants/apiConstants';
import CustomRadioGroup from '../../../../app/atoms/CustomRadioGroup';
import DropDown from '../../../../app/atoms/Dropdown';
import { renameObjKeys } from '../../Login/helper';

type Props = {
    drawerClose: Function;
    setCurrTab: Function;
    setReload: Function;
    setSnackbarOpts: Function;
    setStep: Function;
    step1Vals: any;
    setStep1Vals: Function;
    isEdit: boolean;
    dtlData: any;
};

type ClientType = {
    firstName: string | '';
    lastName: string | '';
    radioValue: string | '';
    dropdownClient?: string | '';
    dropdownRole?: string | '';
    email: string | '';
    dropdownCountryCode: string | '';
    dropdownCountryCodeAlternate: string | '';
    mobilePrimary: string | '';
    mobileSecondary: string | '';
};

const CreateUser = (props: Props) => {
    const { drawerClose, setCurrTab, setStep1Vals, step1Vals, setStep, setSnackbarOpts, isEdit, dtlData } = props;
    const formRef = useRef<any>(null);
    const [validationSchema, setValidationSchema] = useState({});
    const [clients, setClients] = useState<any>([]);
    const [countryCode, setcountryCode] = useState<any>([]);
    const [alternateCountryCode, setAlternatecountryCode] = useState<any>([]);
    const [roles, setRoles] = useState<any>([]);
    const [customerKey, setCustomerKey] = useState<any>(dtlData?.CustomerKey || null);
    const [roleKey, setRoleKey] = useState<any>(dtlData?.CustRoleKey || null);
    const [userType, setUserType] = useState<any>(isEdit ? (dtlData?.SPOCType === 'ACG' ? 'ACG' : 'Customer') : 'ACG');

    const state = useGetState();
    const dispatch = useDispatch();

    const [initialValues] = useState(
        isEdit
            ? {
                firstName: step1Vals?.firstName || dtlData?.FirstName,
                lastName: step1Vals?.lastName || dtlData?.LastName,
                radioValue: step1Vals?.radioValue || dtlData?.SPOCType,
                dropdownRole: step1Vals?.dropdownRole || dtlData?.CustRoleKey,
                dropdownClient: step1Vals?.dropdownClient || dtlData?.CustomerKey,
                email: step1Vals?.email || dtlData?.EmailId,
                mobilePrimary: step1Vals?.mobilePrimary || dtlData?.PhoneNo?.slice(-10),
                mobileSecondary: step1Vals?.mobileSecondary || dtlData?.AlternatePhoneNo?.slice(-10),
                dropdownCountryCode: step1Vals?.dropdownCountryCode || dtlData?.PhoneCountryKey
            }
            : {
                firstName: step1Vals?.firstName || '',
                lastName: step1Vals?.lastName || '',
                radioValue: step1Vals?.radioValue || 'ACG',
                dropdownRole: step1Vals?.dropdownRole || '',
                dropdownClient: step1Vals?.dropdownClient || '',
                email: step1Vals?.email || '',
                mobilePrimary: step1Vals?.mobilePrimary || '',
                mobileSecondary: step1Vals?.mobileSecondary || '',
                dropdownCountryCode: step1Vals?.dropdownCountryCode || ''
            }
    );

    useEffect(() => {
        setValidationSchema(Yup.object().shape(USER_YUPSCHEMA));
        dispatch(
            executeACGAction({
                payload: {
                    requestType: 'GET',
                    urlPath: ACTION_CODES.GET_COUNTRY_CODE
                },
                storeKey: STORE_KEYS.COUNTRY_CODE_DROPDOWN,
                uniqueScreenIdentifier: {
                    isLoading: true
                }
            })
        );
    }, []);

    useEffect(() => {
        if (state?.[STORE_KEYS.COUNTRY_CODE_DROPDOWN]?.body?.data) {
            const clientsDt = state?.[STORE_KEYS.COUNTRY_CODE_DROPDOWN]?.body?.data;
            setcountryCode(
                clientsDt?.map((item: any) => {
                    const newVal = renameObjKeys(item, { PhoneCountryKey: 'code', CountryPhoneCode: 'name' });
                    return newVal;
                })
            );
        }
    }, [state?.[STORE_KEYS.COUNTRY_CODE_DROPDOWN]?.body?.data]);

    useEffect(() => { setAlternatecountryCode(countryCode) }, [countryCode])

    const handleSubmit = async (values: any) => {
        const vals = {
            firstName: values?.firstName,
            lastName: values?.lastName,
            radioValue:
                values?.radioValue || isEdit
                    ? dtlData?.IsACGUser === 'Y'
                        ? values?.radioValue || 'ACG'
                        : values?.radioValue || 'Customer'
                    : 'ACG',
            dropdownRole: values?.dropdownRole,
            // dropdownClient: values?.dropdownClient,
            email: values?.email,
            mobilePrimary: values?.mobilePrimary,
            mobileSecondary: values?.mobileSecondary,
            dropdownCountryCode: values?.dropdownCountryCode
            // clientName: getName(values?.dropdownClient, 'client'),
            // roleName: getName(values?.dropdownRole, 'role')
        };
        setStep1Vals(vals);
        setStep(1);
    };

    const renderButtons = () => {
        return (
            <div className={'btns noWrap'}>
                <>
                    <AcgButton
                        name={'Cancel'}
                        handleClick={drawerClose}
                        secondary={true}
                        className={'first-btn'}
                        type="button"
                        variant="contained"
                    />

                    <AcgButton
                        name="Proceed"
                        handleClick={() => { }}
                        type="submit"
                        className="second-btn"
                        variant="contained"
                    />
                </>
            </div>
        );
    };

    const handleSnackbarError = (err: any) => {
        const errorMsg = err?.message || 'Internal Server error';
        const snackbarError = {
            message: errorMsg,
            type: 'reject',
            open: true
        };

        setSnackbarOpts(snackbarError);
    };

    useEffect(() => {
        if (state?.err) {
            handleSnackbarError(state?.err);
            dispatch(resetErr());
        }
    }, [state?.err]);
    const getStates = (country: any) => {
        dispatch(
            executeACGAction({
                payload: {
                    requestType: 'GET',
                    urlPath: `${ACTION_CODES.GET_LOCATIONS}/${country}`
                },
                uniqueScreenIdentifier: { statesRecd: true },
                storeKey: STORE_KEYS.STATES
            })
        );
    };
    return (
        <Formik
            initialValues={initialValues}
            validationSchema={validationSchema}
            onSubmit={(values: any) => {
                handleSubmit(values);
            }}
            validateOnBlur={false}
            validateOnChange={false}
            innerRef={formRef}
        >
            {(formikprops: FormikProps<ClientType>) => {
                const { values, handleChange, handleBlur, isValid, errors, touched, setFieldValue, validateField } =
                    formikprops;
                return (
                    <Form>
                        <>
                            <Grid container spacing={2}>
                                <Grid container spacing={2} className="radio-buttons">
                                    <Grid item>
                                        <Label label="Type of User" />
                                        <CustomRadioGroup
                                            value={values?.radioValue || userType}
                                            fields={[
                                                { label: 'ACG', value: 'ACG' },
                                                { label: 'Client', value: 'Customer' }
                                            ]}
                                            onChange={(e: any) => {
                                                handleChange(e);
                                                setFieldValue('radioValue', e.currentTarget.value);
                                                setUserType(e.currentTarget.value);
                                            }}
                                            getEvent={true}
                                        />
                                    </Grid>
                                </Grid>
                                <Grid item xs={12} sm={12} md={6} xl={6} lg={6}>
                                    <Label label="First Name" className={'labelDiv'} />
                                    <TextInput
                                        variant="outlined"
                                        fullWidth
                                        type="text"
                                        name="firstName"
                                        value={values.firstName}
                                        autoComplete="off"
                                        formInput="inputDiv"
                                        onChange={(e: any) => {
                                            handleChange(e);
                                        }}
                                        onBlur={handleBlur}
                                        placeholder="Enter First Name"
                                        helperText={errors.firstName && touched.firstName ? errors.firstName : ''}
                                    />
                                </Grid>
                                <Grid item xs={12} sm={12} md={6} xl={6} lg={6}>
                                    <Label label="Last Name" className={'labelDiv'} />
                                    <TextInput
                                        variant="outlined"
                                        fullWidth
                                        type="text"
                                        name="lastName"
                                        value={values.lastName}
                                        autoComplete="off"
                                        formInput="inputDiv"
                                        onChange={(e: any) => {
                                            handleChange(e);
                                        }}
                                        onBlur={handleBlur}
                                        placeholder="Enter Last Name"
                                        helperText={errors.lastName && touched.lastName ? errors.lastName : ''}
                                    />
                                </Grid>
                            </Grid>

                            <Label label="Email" className={'labelDiv'} />
                            <TextInput
                                variant="outlined"
                                fullWidth
                                type="text"
                                name="email"
                                value={values.email}
                                autoComplete="off"
                                formInput="inputDiv"
                                onChange={(e: any) => {
                                    handleChange(e);
                                }}
                                onBlur={handleBlur}
                                placeholder="Enter Email Address"
                                disabled={isEdit}
                                helperText={errors.email && touched.email ? errors.email : ''}
                            />
                            <Label label="Primary Mobile Number" className={'labelDiv'} />
                            <Grid container className="email-section">
                                <Grid item style={{ width: '40%', marginTop: '5px' }}>
                                    <DropDown
                                        onChange={(event: any) => {
                                            handleChange(event);
                                        }}
                                        className="inputDiv"
                                        name="dropdownCountryCode"
                                        items={countryCode}
                                        placeHolder="Select Country Code"
                                        value={values.dropdownCountryCode}
                                        helperText={
                                            errors.dropdownCountryCode && touched.dropdownCountryCode
                                                ? errors.dropdownCountryCode
                                                : ''
                                        }
                                    />
                                </Grid>
                                <Grid item style={{ marginLeft: '10px', width: '60%' }}>
                                    <TextInput
                                        variant="outlined"
                                        fullWidth
                                        type="text"
                                        name="mobilePrimary"
                                        value={values.mobilePrimary}
                                        autoComplete="off"
                                        formInput="inputDiv"
                                        onChange={(e: any) => {
                                            handleChange(e);
                                        }}
                                        maxLength={10}
                                        onBlur={handleBlur}
                                        placeholder="Enter Primary Mobile Number"
                                        helperText={errors.mobilePrimary && touched.mobilePrimary ? errors.mobilePrimary : ''
                                        }
                                    />
                                </Grid>
                            </Grid>
                            <Label label="Secondary Mobile Number" className={'labelDiv'} />
                            <Grid container className="email-section">
                                <Grid item style={{ width: '40%', marginTop: '5px' }}>
                                    <DropDown
                                        onChange={(event: any) => {
                                            handleChange(event);
                                        }}
                                        className="inputDiv"
                                        name="dropdownCountryCodeAlternate"
                                        items={alternateCountryCode}
                                        placeHolder="Select Country Code"
                                        value={values.dropdownCountryCodeAlternate}
                                        helperText={
                                            errors.dropdownCountryCodeAlternate && touched.dropdownCountryCodeAlternate
                                                ? errors.dropdownCountryCodeAlternate
                                                : ''
                                        }
                                    />
                                </Grid>
                                <Grid item style={{ marginLeft: '10px', width: '60%' }}>
                                    <TextInput
                                        variant="outlined"
                                        fullWidth
                                        type="text"
                                        name="mobileSecondary"
                                        value={values.mobileSecondary}
                                        autoComplete="off"
                                        formInput="inputDiv"
                                        onChange={(e: any) => {
                                            handleChange(e);
                                        }}
                                        maxLength={10}
                                        onBlur={handleBlur}
                                        placeholder="Enter Secondary Mobile Number"
                                        helperText={
                                            errors.mobileSecondary && touched.mobileSecondary
                                                ? errors.mobileSecondary
                                                : ''
                                        }
                                    />
                                </Grid>
                            </Grid>
                        </>
                        {renderButtons()}
                    </Form>
                );
            }}
        </Formik>
    );
};

export default CreateUser;
