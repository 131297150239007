import * as React from 'react';
import TextField from '@mui/material/TextField';
import { DateTimePicker } from '@mui/x-date-pickers/DateTimePicker';
import './index.scss';

const CustomDateTimePicker = (props: any) => {
    return (
        <DateTimePicker
            openTo="day"
            views={['year', 'month', 'day', 'hours', 'minutes']}
            {...props}
            className={`custom-date-time-picker ${props.className}`}
            PopperProps={{
                ...props?.PopperProps,
                className: `custom-date-time-picker-popper ${props?.PopperProps?.className}`
            }}
            renderInput={(params:any) => {
                return (
                  <TextField
                    {...params}
                    inputProps={{
                      ...params.inputProps,
                      placeholder: "dd mm yyyy hh:mm:ss"
                    }}
                  />
                );
              }}
            minDate={props.minDate}
        />
    );
};

export default CustomDateTimePicker;
