import * as React from 'react';
import Typography from '@mui/material/Typography';
import Breadcrumbs from '@mui/material/Breadcrumbs';
import Link from '@mui/material/Link';
import { useHistory } from "react-router-dom";

type ComponentProps = {
    data?: any;
    disabled?: boolean;
};

export default function Breadcrumb(props: ComponentProps) {
    const history = useHistory();

    const handleClick = (url:string) => {
        if(!props.disabled) history.push(url)
    }
    return (
        <div role="presentation" style={{ marginBottom: 8 }}>
            <Breadcrumbs aria-label="breadcrumb"  separator="›"  style={{ color: "#ffff", fontSize: 12 }}>
                {props.data?.map((i: any) => {
                    return (
                        <Link underline="hover" color="#ffff" onClick={()=>handleClick(i.url)}>
                            <span style={window.location === i.url ? {fontSize: 12, fontWeight: 'bold', cursor: 'pointer'}: {fontSize: 12, cursor: 'pointer'}}>{i?.label}</span>
                        </Link>
                    )
                })}
            </Breadcrumbs>
        </div>
    );
}