import React, { useState, useEffect } from 'react';
import { Bar, Chart } from 'react-chartjs-2';
import 'chartjs-plugin-datalabels';
import { ChartData } from 'chart.js';
import ChartDataLabels from 'chartjs-plugin-datalabels';
interface CustomLegendProps {
  datasets: ChartData['datasets'];
}

const CustomLegend: React.FC<CustomLegendProps> = ({ datasets }) => {
  return (
    <div className="custom-legend">
      <ul style={{ display: 'flex', justifyContent: 'center', listStyleType: 'none', padding: 0 }} className='legendcls'>
      {datasets.map((dataset:any, index:any) => (
        
        <li key={index} style={{ display: 'flex', alignItems: 'center', marginRight: '20px'}}>
        <span style={{
            backgroundColor: dataset.backgroundColor as string,
            width: '35px',
            height: '10px',
            display: 'inline-block',
            marginRight: '5px'
          }}></span>
          <span style={{ color: '#e0e0e0', fontFamily: 'montserrat', fontSize: '10px' }}>{dataset.label}</span>
        </li>
        
      ))}
      </ul>
    </div>
  );
};

const BarChart = (props:any) => {
  const [labelSet, setLabelSet] = useState<any>([]);
  const [opdata1, setOPData1] = useState<any>([]);
  const [opdata2, setOPData2] = useState<any>([]);
  const [opdata3, setOPData3] = useState<any>([]);
  useEffect(()=> {
    const lab:any = [], d1:any = [], d2:any = [], d3:any = [];
    props.OPData.map((i:any)=> {
      lab.push(props.selectedOption === "option1" ? i.OperatorName : i.ProductName);
      d1.push(props.selectedOption === "option1" ? i.NoOfShifts : i.Quality);
      d2.push(i.TotalProduction);
      d3.push(i.QualityPerc);
    });
    setLabelSet(lab);
    setOPData1(d1);
    setOPData2(d2);
    setOPData3(d3);
  },[props])

  const data = {
    labels: labelSet,
    datasets: props.selectedMachineModel !== 11 && props.selectedOption === "option1" ? [
      {
        label: "Total Production",
        backgroundColor: '#0d63fd',
        borderColor: '#0d63fd',
        borderWidth: 0,
        hoverBackgroundColor: '#ffff08',
        hoverBorderColor: '#ffff08',
        data: opdata2,
        yAxisID: 'y',
      },
     
    ] : 
    [
      {
        label: props.selectedOption === "option1"? "Number of Shifts": "Quality %",
        backgroundColor: '#e0a909',
        borderColor: '#e0a909',
        borderWidth: props.selectedOption === "option1"? 0 : 1, 
        hoverBackgroundColor: '#ffff08',
        hoverBorderColor: '#ffff08',
        data: opdata1,
        type: 'line' as const,
        yAxisID: 'y1',
      },
      {
        label: "Average Production",
        backgroundColor: '#0d63fd',
        borderColor: '#0d63fd',
        borderWidth: 0,
        hoverBackgroundColor: '#ffff08',
        hoverBorderColor: '#ffff08',
        data: opdata2,
        yAxisID: 'y',
      },
     
    ],
  };
  
  const options = {
    maintainAspectRatio: false, 
    responsive: true,
    plugins: {
      tooltip: {
        callbacks: {
          title: (xDatapoint:any) => {
            return xDatapoint.label;
          },
          label: (yDatapoint:any) => {
            const formattedValue = Number(yDatapoint.raw).toFixed(2);
            const formattedQPercent = Number(opdata3[yDatapoint.dataIndex]).toFixed(2);
            
            if (props.selectedOption === "option2") {
              return `${yDatapoint.dataset.label}: ${formattedValue}`;
            } else {
              return `${yDatapoint.dataset.label}: ${formattedValue} (Quality %: ${formattedQPercent})`;
            }
          },
        }
      },
      datalabels: {color: 'white', display: false},
      legend: {
        display: false,
        // labels: {
        //   color: "#e0e0e0",
        //   font: {
        //     size: 10,
        //     family: "montserrat",
        //   }
        // }
      },
        
      },
      // barThickness: 55,
    scales: {
      
      x: {
        display: true,

        grid: {
          color: 'transparent',
        },
        ticks: {
          // stepSize: 1,
          callback: function(label:any) {
            if(labelSet[label]?.length < 10) {
              return labelSet[label];
            } else { 
              return labelSet[label]?.substr(0, 10) + "..."   
            }       
          },
        color: 'white',
      },
      },
      y: {
        type: 'linear' as const,
        display: true,
        position: 'left' as const,
        ticks: {
                color: 'white', 
             },
             title: {
              display: true,
              text: 'Blister Count',
              color: 'white',
              font: {
                size: 12
              }
            },
            grid: {
              color: '#878f99',
            },
      },
      y1: {
        type: 'linear' as const,
        display: props.selectedMachineModel !== 11 && props.selectedOption === "option1" ? false : true,
        position: 'right' as const,
        ticks: {
          stepSize: 1,
          color: 'white', 
       },
       grid: {
        color: 'transparent',
      },
      title: {
        display: true,
        // text: 'Blister Count',
        color: 'white',
        font: {
          size: 12
        }
      },
      }
    },
  };

  return (
    <>
    <CustomLegend datasets={data.datasets} />
    <div className='barchartOP'>
      <Chart type='bar' data={data} options={options} plugins={[ChartDataLabels]} />
    </div>
    </>
  );
};

export default BarChart;