import Box from '@mui/material/Box';
import Drawer from '@mui/material/Drawer';
import Label from '../Label';
import UserMoreMenu from '../CustomTableMenu/index';
import { sentenceCase } from 'change-case';
import Accordion from '../Accordion';
import SearchBar from '../SearchBar';
import AllAlarmDetails from '../../features/AlarmDetail/AllAlarms/Details';
import './index.scss';
import Popover from '../Popover';
import SPOCDetails from '../../features/MachineDetail/DrawerContent/TabContent/Overview/SPOCDetails';
import { STORE_KEYS } from '../../constants/apiConstants';
import useGetState from '../../utils/hooks/useGetState';
import CallSpoc from '../../../assets/images/CallSpoc.svg';

type Anchor = 'right';

type Props = {
    status: any;
    handlerOpen: any;
    handlerClose: any;
    // children: any;
    data?: any;
    drawerHeader?: any;
    block?: boolean;
    type?: string;
    editFn?: Function;
    deleteFn?: Function;
    usedIn?: string;
    timePeriodKey?:string;
};

export default function AlarmDetailDrawer(props: Props) {
    const {
        status,
        handlerOpen,
        handlerClose,
        drawerHeader,
        data,
        block = false,
        type,
        editFn = () => {},
        deleteFn = () => {},
        usedIn = '',
        timePeriodKey
    } = props;

    const handleClose = () => {
        handlerClose();
    };
    const stateVal = useGetState();

    const list = () => (
        <Box sx={{ width: 250 }} role="presentation" className={'standardBox'}>
            <div className="baseDiv">
                <div
                    onClick={handleClose}
                    style={{
                        border: '1px solid #5D97F6',
                        borderRadius: '50px',
                        height: '25px',
                        width: '25px',
                        position: 'absolute',
                        left: '20px',
                        top: type === 'Role' ? '30px' : type === 'all' ? '28px' : type === 'default' ? '17px' : '17px',
                        cursor: 'pointer'
                    }}
                >
                    <span style={{ position: 'relative', fontSize: '12px', color: '#5D97F6', left: '35%', top: '-3%' }}>
                        X
                    </span>
                </div>

                <div
                    style={{
                        display: 'flex',
                        justifyContent: 'space-between',
                        alignItems: 'flex-start',
                        width: '100%'
                        // padding:'10px'
                    }}
                >
                    <div style={{ display: 'block', marginLeft: '45px' }}>
                        <h6
                            style={{
                                color: '#DADCEE',
                                fontSize: '16px',
                                fontFamily: 'Montserrat',
                                paddingBottom: type === 'all' ? '0px' : '10px'
                            }}
                        >
                            {data.title}
                        </h6>
                        {type === 'all' && (
                            <div style={{ marginBottom: '10px' }}>
                                <Popover
                                    itemsArray={[]}
                                    src={''}
                                    infoOnly={false}
                                    spocs={true}
                                    children={
                                        stateVal?.[STORE_KEYS.SPOCS]?.body?.data?.length > 0 ? (
                                            <SPOCDetails
                                                spocs={stateVal?.[STORE_KEYS.SPOCS]?.body?.data}
                                                editable={false}
                                            />
                                        ) : null
                                    }
                                />
                            </div>
                        )}
                        {type === 'Role' && (
                            <p style={{ color: '#DADCEE', fontSize: '13px', fontFamily: 'Montserrat' }}>
                                freduardo.hill@acg-world.com <span>&nbsp;</span>| +91 9988029282
                            </p>
                        )}
                        {type === 'Client' && (
                            <p style={{ color: '#DADCEE', fontSize: '13px', fontFamily: 'Montserrat' }}>
                                {data?.details?.name}
                            </p>
                        )}
                        {type === 'Plant' && (
                            <p style={{ color: '#DADCEE', fontSize: '13px', fontFamily: 'Montserrat' }}>
                                {data?.details?.PlantName}
                            </p>
                        )}
                    </div>{' '}
                    {(type === 'all' || type === 'Role') && (
                        <div style={{ marginTop: type === 'Role' ? '7px' : '8px' }}>
                            <Label variant="outlined" color="success">
                                {sentenceCase('Active')}
                            </Label>
                        </div>
                    )}
                    {type === 'Feature' ? (
                        <div style={{ marginTop: '-7px', marginBottom: '6px' }}>
                            <UserMoreMenu click={editFn} remove={deleteFn} block={block} dtlMode={true} />
                        </div>
                    ) : null}
                </div>
            </div>
            {type === 'all' ? (
                <div>
                    <div className="userDetailDiv alarmDivTop">
                        <div className={'detailDivChild firstChild'}>
                            <p>Model / Line no / Serial Number</p>
                            <h6 style={{ color: '#B1C2DF' }}>{data?.line}</h6>
                        </div>
                        <div className={'detailDivChild'}>
                            <p>Client</p>
                            <h6 style={{ color: '#B1C2DF' }}>{data?.location}</h6>
                        </div>
                    </div>
                    <div className="userDetailDiv alarmDivBottom">
                        <div className={'detailDivChild'}>
                            <p>Severity</p>
                            <Label variant="outlined" color={data?.status?.toLowerCase() || 'warning'}>
                                {sentenceCase(data?.status || '')}
                            </Label>
                        </div>
                    </div>
                </div>
            ) : (
                <div className="userDetailDiv">
                    <div className="detailDivChild firstChild">
                        <p>Model</p>
                        <h6>{data?.line}</h6>
                    </div>
                    <div className={'detailDivChild'}>
                        <p>Severity</p>
                        <Label variant="outlined" color={data?.status?.toLowerCase() || 'warning'}>
                            {sentenceCase(data?.status || '')}
                        </Label>
                    </div>
                </div>
            )}

            <br />
            <AllAlarmDetails data={data} usedIn={usedIn}  timePeriodKey={timePeriodKey} />

            {type === 'Plant' ? <SearchBar /> : null}
        </Box>
    );

    return (
        <div>
            <Drawer
                anchor={'right'}
                open={status}
                // onClose={handlerClose}
                onClose={handleClose}
                style={{ zIndex: '9999' }}
                className={'alarmDetailDrawerDiv'}
            >
                {list()}
            </Drawer>
        </div>
    );
}
