import axios from 'axios';
import { ACTION_CODES, STORE_KEYS, BASEURL } from '../../constants/apiConstants';

class FMEAService {

    getFMEACustomers(req:any) {
        var data = {
            "userId":req.userId
        };
        return axios
        .post(ACTION_CODES.API_FMEA_CUSTOMERS,data, {
            headers: {
                Authorization: 'Bearer ' + req?.token
            }
        })
        .then(response => {
            return {
                status: response.status,
                data: response.data
            }
        })
        .catch(error => { 
            
            return {
                status: error.response.status
            }
        })
    }

    getFMEACustomerModels(req:any) {
        var data = {
            "userId":req.userId,
        };
        return axios
        .post(ACTION_CODES.API_FMEA_CUSTOMER_MODELS,data, {
            headers: {
                Authorization: 'Bearer ' + req?.token
            }
        })
        .then(response => {
            return {
                status: response.status,
                data: response.data
            }
        })
        .catch(error => { 
            
            return {
                status: error.response.status
            }
        })
    }

    getFMEAPlants(req:any) {
        var data = {
            "userId":req.userId,
            "customerKey":req.customerKey
        };
        return axios
        .post(ACTION_CODES.API_FMEA_PLANTS,data, {
            headers: {
                Authorization: 'Bearer ' + req?.token
            }
        })
        .then(response => {
            return {
                status: response.status,
                data: response.data
            }
        })
        .catch(error => { 
            
            return {
                status: error.response.status
            }
        })
    }

    getNotificationGroups(req:any) {
        var data = {
            "userId":req.userId,
            "customerId":-1
        };
        return axios
        .post(ACTION_CODES.API_FMEA_NOTIFICATION_GROUPS,data, {
            headers: {
                Authorization: 'Bearer ' + req?.token
            }
        })
        .then(response => {
            return {
                status: response.status,
                data: response.data
            }
        })
        .catch(error => { 
            
            return {
                status: error.response.status
            }
        })
    }

    createNotificationGroup(req:any) {
        var data = {
            "userId":req.userId,
            "customerId":req.customerKey,
            "grpName": req.grpName,
            "grpDescription": req.grpDescription,
            "type": req.type,
            "grpKey": req.grpKey,
        };
        return axios
        .post(ACTION_CODES.API_FMEA_CREATE_NOTIFICATION_GROUP,data, {
            headers: {
                Authorization: 'Bearer ' + req?.token
            }
        })
        .then(response => {
            return {
                status: response.status,
                data: response.data
            }
        })
        .catch(error => { 
            
            return {
                status: error.response.status
            }
        })
    }

    getPortalUserList(req:any) {
        var data = {
            "userId":req.userId,
            "customerId":req.customerKey,
        };
        return axios
        .post(ACTION_CODES.API_FMEA_PORTAL_USERS,data, {
            headers: {
                Authorization: 'Bearer ' + req?.token
            }
        })
        .then(response => {
            return {
                status: response.status,
                data: response.data
            }
        })
        .catch(error => { 
            
            return {
                status: error.response.status
            }
        })
    }

    deleteNotificationGM(req:any) {
        var data = {
            "userId":req.userId,
            "tableName":req.table,
            "grpKey":req.key
        };
        return axios
        .post(ACTION_CODES.API_FMEA_DELETE_NMGROUP,data, {
            headers: {
                Authorization: 'Bearer ' + req?.token
            }
        })
        .then(response => {
            return {
                status: response.status,
                data: response.data
            }
        })
        .catch(error => { 
            
            return {
                status: error.response.status
            }
        })
    }

    addMemberToGroup(req:any) {
        var data = {
            "userId":req.userId,
            "type":req.type,
            "userName":req.userName,
            "userEmail": req.userEmail,
            "userKey": req.userKey,
            "grpKey": req.grpKey
        };
        return axios
        .post(ACTION_CODES.API_FMEA_ADD_MEMBER,data, {
            headers: {
                Authorization: 'Bearer ' + req?.token
            }
        })
        .then(response => {
            return {
                status: response.status,
                data: response.data
            }
        })
        .catch(error => { 
            
            return {
                status: error.response.status
            }
        })
    }

    getGroupMemberList(req:any) {
        var data = {
            "grpKey": req.grpKey
        };
        return axios
        .post(ACTION_CODES.API_FMEA_LIST_MEMBERS,data, {
            headers: {
                Authorization: 'Bearer ' + req?.token
            }
        })
        .then(response => {
            return {
                status: response.status,
                data: response.data
            }
        })
        .catch(error => { 
            
            return {
                status: error.response.status
            }
        })
    }

    getAlertList(req:any) {
        var data = {
            "userId": req.userId,
            "keys": req.keys,
            "startDate": req.startDate,
            "endDate": req.endDate
        };
        return axios
        .post(ACTION_CODES.API_FMEA_ALERT_LIST,data, {
            headers: {
                Authorization: 'Bearer ' + req?.token
            }
        })
        .then(response => {
            return {
                status: response.status,
                data: response.data
            }
        })
        .catch(error => { 
            
            return {
                status: error.response.status
            }
        })
    }

    getPresetAlertList(req:any) {
        var data = {
            "userId": req.userId,
            "customerKey": req.customerKey,
            "machineModelKey": req.machineModelKey,
            "type": req.type
        };
        return axios
        .post(ACTION_CODES.API_FMEA_PRESET_ALERT_LIST,data, {
            headers: {
                Authorization: 'Bearer ' + req?.token
            }
        })
        .then(response => {
            return {
                status: response.status,
                data: response.data
            }
        })
        .catch(error => { 
            
            return {
                status: error.response.status
            }
        })
    }

    getMachineList(req:any) {
        var data = {
            "userId": req.userId,
            "machineModelKey": req.machineModelKey
        };
        return axios
        .post(ACTION_CODES.API_FMEA_MACHINE_NAME_LIST,data, {
            headers: {
                Authorization: 'Bearer ' + req?.token
            }
        })
        .then(response => {
            return {
                status: response.status,
                data: response.data
            }
        })
        .catch(error => { 
            
            return {
                status: error.response.status
            }
        })
    }
    
    getRecipeList(req:any) {
        var data = {
            "userId": req.userId,
            "customerKey": req.customerKey,
            "machineModelKey": req.machineModelKey,
            "machineKeys": req.machineKeys
        };
        return axios
        .post(ACTION_CODES.API_FMEA_RECIPE_LIST,data, {
            headers: {
                Authorization: 'Bearer ' + req?.token
            }
        })
        .then(response => {
            return {
                status: response.status,
                data: response.data
            }
        })
        .catch(error => { 
            
            return {
                status: error.response.status
            }
        })
    }

    getFMEASeverityList(req:any) {
        return axios
        .get(ACTION_CODES.API_FMEA_SEVERITY_LIST, {
            headers: {
                Authorization: 'Bearer ' + req?.token
            }
        })
        .then(response => {
            return {
                status: response.status,
                data: response.data
            }
        })
        .catch(error => { 
            
            return {
                status: error.response.status
            }
        })
    }

    getFMEATimeDuration(req:any) {
        var data = {
            "userId": req.userId
        };
        return axios
        .post(ACTION_CODES.API_FMEA_TIME_DURATION,data, {
            headers: {
                Authorization: 'Bearer ' + req?.token
            }
        })
        .then(response => {
            return {
                status: response.status,
                data: response.data
            }
        })
        .catch(error => { 
            
            return {
                status: error.response.status
            }
        })
    }

    getFMEACustomerNotificationGroups(req:any) {
        var data = {
            "userId": req.userId,
            "customerKey": req.customerKey
        };
        return axios
        .post(ACTION_CODES.API_FMEA_CUSTOMER_NGROUPS,data, {
            headers: {
                Authorization: 'Bearer ' + req?.token
            }
        })
        .then(response => {
            return {
                status: response.status,
                data: response.data
            }
        })
        .catch(error => { 
            
            return {
                status: error.response.status
            }
        })
    }

    createFMEAPresetRule(req:any) {
        var data = {
            "userId": req.userId,
            "customerKey": req.customerKey,
            "machineModelKey":req.machineModelKey,
            "presetRuleKey":req.presetRuleKey,
            "machineKeys":req.machineKeys,
            "recipeKeys":req.recipeKeys,
            "severityKey":req.severityKey,
            "recommendedNotes":req.recommendedNotes,
            "duration":req.duration,
            "alertType":req.alertType,
            "notifiyGroups":req.notifiyGroups,
            "subject":req.subject,
            "type":req.type 
        };
        return axios
        .post(ACTION_CODES.API_FMEA_CREATE_PRESET_ALERT,data, {
            headers: {
                Authorization: 'Bearer ' + req?.token
            }
        })
        .then(response => {
            return {
                status: response.status,
                data: response.data
            }
        })
        .catch(error => { 
            
            return {
                status: error.response.status
            }
        })
    }

    getAllPresetRuleConditions(req:any) {
        var data = {
            "userId": req.userId
        };
        return axios
        .post(ACTION_CODES.API_FMEA_PRESET_RULE_CONDITIONS,data, {
            headers: {
                Authorization: 'Bearer ' + req?.token
            }
        })
        .then(response => {
            return {
                status: response.status,
                data: response.data
            }
        })
        .catch(error => { 
            
            return {
                status: error.response.status
            }
        })
    }

    getFMEAPresetAlertDetails(req:any) {
        var data = {
            "userId": req.userId,
            "ruleKey": req.ruleKey
        };
        return axios
        .post(ACTION_CODES.API_FMEA_PRESET_RULE_DETAILS,data, {
            headers: {
                Authorization: 'Bearer ' + req?.token
            }
        })
        .then(response => {
            return {
                status: response.status,
                data: response.data
            }
        })
        .catch(error => { 
            
            return {
                status: error.response.status
            }
        })
    }

    getAllCustomRuleConditions(req:any) {
        var data = {
            "userId": req.userId
        };
        return axios
        .post(ACTION_CODES.API_FMEA_CUSTOM_RULE_CONDITIONS,data, {
            headers: {
                Authorization: 'Bearer ' + req?.token
            }
        })
        .then(response => {
            return {
                status: response.status,
                data: response.data
            }
        })
        .catch(error => { 
            
            return {
                status: error.response.status
            }
        })
    }

    toggleCurrentFMEAStatus(req:any) {
        var data = {
            "userId": req.userId,
            "FailureModeKey":req.FailureModeKey,
            "status":req.status
        };
        return axios
        .post(ACTION_CODES.API_FMEA_TOGGLE_CURRENT_STATUS,data, {
            headers: {
                Authorization: 'Bearer ' + req?.token
            }
        })
        .then(response => {
            return {
                status: response.status,
                data: response.data
            }
        })
        .catch(error => { 
            
            return {
                status: error.response.status
            }
        })
    }
    
    getFMEAGroupNames(req:any) {
        var data = {
            "userId": req.userId,
            "machineModelKey": req.machineModelKey
        };
        return axios
        .post(ACTION_CODES.API_FMEA_CUSTOM_GROUP_NAMES,data, {
            headers: {
                Authorization: 'Bearer ' + req?.token
            }
        })
        .then(response => {
            return {
                status: response.status,
                data: response.data
            }
        })
        .catch(error => { 
            
            return {
                status: error.response.status
            }
        })
    }
    
    getParameterList(req:any) {
        var data = {
            "userId": req.userId,
            "machineModelKey": req.machineModelKey,
            "groupKey": typeof req.groupKey =="number"?req.groupKey:req.groupKey.includes(-1)?-1:req.groupKey
        };
        return axios
        .post(ACTION_CODES.API_FMEA_CUSTOM_PARAMETERS,data, {
            headers: {
                Authorization: 'Bearer ' + req?.token
            }
        })
        .then(response => {
            return {
                status: response.status,
                data: response.data
            }
        })
        .catch(error => { 
            
            return {
                status: error.response.status
            }
        })
    }

    getFMEAOperators(req:any) {
        return axios
        .get(ACTION_CODES.API_FMEA_CUSTOM_OPERATORS, {
            headers: {
                Authorization: 'Bearer ' + req?.token
            }
        })
        .then(response => {
            return {
                status: response.status,
                data: response.data
            }
        })
        .catch(error => { 
            
            return {
                status: error.response.status
            }
        })
    }

    getParameterValueTypes(req:any) {
        var data = {
            machineParamKeys: req.machineParamKeys
        };
        return axios
        .post(ACTION_CODES.API_FMEA_PARAMETER_VALUE_TYPES, data, {
            headers: {
                Authorization: 'Bearer ' + req?.token
            }
        })
        .then(response => {
            return {
                status: response.status,
                data: response.data
            }
        })
        .catch(error => { 
            
            return {
                status: error.response.status
            }
        })
    }

    createFMEACustomRule(req:any) {
        var data = {
            "userId": req.userId,
            "subComponent": req.subComponent,
            "machineModelKey":req.machineModelKey,
            "presetRuleKey":req.presetRuleKey,
            "machineKeys":req.machineKeys,
            "potentialFailure":req.potentialFailure,
            "severityKey":req.severityKey,
            "recommendedNotes":req.recommendedNotes,
            "type": req.type
        };
        return axios
        .post(ACTION_CODES.API_FMEA_CREATE_CUSTOM_ALERT,data, {
            headers: {
                Authorization: 'Bearer ' + req?.token
            }
        })
        .then(response => {
            return {
                status: response.status,
                data: response.data
            }
        })
        .catch(error => { 
            
            return {
                status: error.response.status
            }
        })
    }

    getFMEACustomAlertDetails(req:any) {
        var data = {
            "userId": req.userId,
            "ruleKey": req.ruleKey
        };
        return axios
        .post(ACTION_CODES.API_FMEA_CUSTOM_RULE_DETAILS,data, {
            headers: {
                Authorization: 'Bearer ' + req?.token
            }
        })
        .then(response => {
            return {
                status: response.status,
                data: response.data
            }
        })
        .catch(error => { 
            
            return {
                status: error.response.status
            }
        })
    }

    getFMEANotifications(req:any) {
        var data = {
            "userId": req.userId,
        };
        return axios
        .post(ACTION_CODES.API_FMEA_NOTIFICATIONS,data, {
            headers: {
                Authorization: 'Bearer ' + req?.token
            }
        })
        .then(response => {
            return {
                status: response.status,
                data: response.data
            }
        })
        .catch(error => { 
            
            return {
                status: error.response.status
            }
        })
    }
    getFMEANotificationDetails(req:any) {
        var data = {
            "ruleKey": req.ruleKey,
            "type":req.type
        };
        return axios
        .post(ACTION_CODES.API_FMEA_NOTIFICATION_DETAILS,data, {
            headers: {
                Authorization: 'Bearer ' + req?.token
            }
        })
        .then(response => {
            return {
                status: response.status,
                data: response.data
            }
        })
        .catch(error => { 
            
            return {
                status: error.response.status
            }
        })
    }

    createFMEAFailureIndicatorRule(req:any) {
        var data = {
            "userId": req.userId,
            "type":req.type,
            "condFormula":req.condFormula,
            "jsonCond":req.jsonCond,
            "MachMdlParamGrpKeys":req.MachMdlParamGrpKeys,
            "paramCodes":req.paramCodes,
            "presetRuleKey": req.presetRuleKey
        };
        return axios
        .post(ACTION_CODES.API_FMEA_CREATE_FAILURE_INDICATOR,data, {
            headers: {
                Authorization: 'Bearer ' + req?.token
            }
        })
        .then(response => {
            return {
                status: response.status,
                data: response.data
            }
        })
        .catch(error => { 
            
            return {
                status: error.response.status
            }
        })
    }
    getFMEAExsistingFailureIndicators(req:any) {
        var data = {
            "userId": req.userId,
            "ruleKey": req.ruleKey
        };
        return axios
        .post(ACTION_CODES.API_FMEA_EXSISTING_FAILURE_INDICATOR,data, {
            headers: {
                Authorization: 'Bearer ' + req?.token
            }
        })
        .then(response => {
            return {
                status: response.status,
                data: response.data
            }
        })
        .catch(error => { 
            
            return {
                status: error.response.status
            }
        })
    }

    getFMEADetectionCriteria(req:any) {
        return axios
        .get(ACTION_CODES.API_FMEA_DETECTION_CRITERIA, {
            headers: {
                Authorization: 'Bearer ' + req?.token
            }
        })
        .then(response => {
            return {
                status: response.status,
                data: response.data
            }
        })
        .catch(error => { 
            
            return {
                status: error.response.status
            }
        })
    }

    getFMEAOccurance(req:any) {
        return axios
        .get(ACTION_CODES.API_FMEA_OCCURANCE_CRITERIA,{
            headers: {
                Authorization: 'Bearer ' + req?.token
            }
        })
        .then(response => {
            return {
                status: response.status,
                data: response.data
            }
        })
        .catch(error => { 
            
            return {
                status: error.response.status
            }
        })
    }

    createFMEAPotentialCause(req:any) {
        var data = {
            userId: req.userId,
            failureKey: req.presetRuleKey,
            potentialCause: req.potentialCause,
            occurance: req.occurance,
            detection: req.detection,
            condFormula:req.condFormula,
            jsonCond: req.jsonCond,
            MachMdlParamGrpKeys: req.MachMdlParamGrpKeys,
            paramCodes: req.paramCodes,
            recommandList: req.recommandList,
            PotentialCauseKey: req.PotentialCauseKey,
            type: req.type
        };
        return axios
        .post(ACTION_CODES.API_FMEA_CREATE_POTENTIAL_CAUSES,data, {
            headers: {
                Authorization: 'Bearer ' + req?.token
            }
        })
        .then(response => {
            return {
                status: response.status,
                data: response.data
            }
        })
        .catch(error => { 
            
            return {
                status: error.response.status
            }
        })
    }

    assignFMEANotificationGroups(req:any) {
        var data = {
            userId: req.userId,
            failureKey: req.presetRuleKey,
            groups: req.groups,
            type: req.type
        };
        return axios
        .post(ACTION_CODES.API_FMEA_ASSIGN_NOTIFY_GROUPS,data, {
            headers: {
                Authorization: 'Bearer ' + req?.token
            }
        })
        .then(response => {
            return {
                status: response.status,
                data: response.data
            }
        })
        .catch(error => { 
            
            return {
                status: error.response.status
            }
        })
    }

    getFailureModeNotificationGroups(req:any) {
        var data = {
            "userId":req.userId,
            failureKey: req.ruleKey
        };
        return axios
        .post(ACTION_CODES.API_FMEA_GET_FAILURE_NOTIFICATION_GROUPS,data, {
            headers: {
                Authorization: 'Bearer ' + req?.token
            }
        })
        .then(response => {
            return {
                status: response.status,
                data: response.data
            }
        })
        .catch(error => { 
            
            return {
                status: error.response.status
            }
        })
    }

    getExsistingPotentialCauses(req:any) {
        var data = {
            ruleKey: req.ruleKey
        };
        return axios
        .post(ACTION_CODES.API_FMEA_GET_POTENTIAL_CAUSES,data, {
            headers: {
                Authorization: 'Bearer ' + req?.token
            }
        })
        .then(response => {
            return {
                status: response.status,
                data: response.data
            }
        })
        .catch(error => { 
            
            return {
                status: error.response.status
            }
        })
    }

    getExsistingPotentialCauseDetails(req:any) {
        var data = {
            ruleKey: req.ruleKey
        };
        return axios
        .post(ACTION_CODES.API_FMEA_GET_POTENTIAL_CAUSE_DETAILS,data, {
            headers: {
                Authorization: 'Bearer ' + req?.token
            }
        })
        .then(response => {
            return {
                status: response.status,
                data: response.data
            }
        })
        .catch(error => { 
            
            return {
                status: error.response.status
            }
        })
    }

    getExsistingPotentialCauseRecommendations(req:any) {
        var data = {
            ruleKey: req.ruleKey
        };
        return axios
        .post(ACTION_CODES.API_FMEA_GET_POTENTIAL_CAUSE_RECOMM,data, {
            headers: {
                Authorization: 'Bearer ' + req?.token
            }
        })
        .then(response => {
            return {
                status: response.status,
                data: response.data
            }
        })
        .catch(error => { 
            
            return {
                status: error.response.status
            }
        })
    }

    deletetExsistingFMEAFailureMode(req:any) {
        var data = {
            ruleKey: req.ruleKey,
            tableName: "FMEAFailureMode",
            userId: req.userId,
        };
        return axios
        .post(ACTION_CODES.API_FMEA_DELETE_FAILURE_MODE,data, {
            headers: {
                Authorization: 'Bearer ' + req?.token
            }
        })
        .then(response => {
            return {
                status: response.status,
                data: response.data
            }
        })
        .catch(error => { 
            
            return {
                status: error.response.status
            }
        })
    }

    deletetExsistingFMEACasualParameter(req:any) {
        var data = {
            ruleKey: req.ruleKey,
            tableName: "PotentialCause",
            userId: req.userId,
        };
        return axios
        .post(ACTION_CODES.API_FMEA_DELETE_FAILURE_MODE,data, {
            headers: {
                Authorization: 'Bearer ' + req?.token
            }
        })
        .then(response => {
            return {
                status: response.status,
                data: response.data
            }
        })
        .catch(error => { 
            
            return {
                status: error.response.status
            }
        })
    }
    
}
export default new FMEAService();