import { Typography, Box } from '@mui/material';
import './index.scss';
import DownloadReport from '../../../../assets/images/DownloadReport.svg';
import { Button } from '@mui/material';
type DataProps = {
    imageUrl?: any;
    type?: string;
    educationData?: any;
    saveButtons?: boolean;
    disabled?: boolean;
    clickSave?: any;
    disabledSubmit?: any;
    clickSubmit?: any;
    children?: any;
};

const ComplianceHistory = (props: DataProps) => {
    const { type, imageUrl, educationData, saveButtons, disabled, clickSave, disabledSubmit, clickSubmit, children } =
        props;

    return (
        <div className="complianceWrapper">
            {/* <Box className="fieldBox1"> */}
            {/* <div className="fieldHealthFormHeader">
                    {imageUrl ? <img className="educationIcon" src={imageUrl} /> : null}
                    <Typography className="educationType">{type}</Typography>
                </div> */}
            {children && children}
            {/* </Box> */}
        </div>
    );
};

export default ComplianceHistory;
