import React, { useState, useEffect } from 'react';
import 'chartjs-plugin-datalabels';
import ChartDataLabels from 'chartjs-plugin-datalabels';

import {
  Chart as ChartJS,
  LinearScale,
  CategoryScale,
  BarElement,
  PointElement,
  LineElement,
  Tooltip,
  LineController,
  BarController,
} from 'chart.js';
import { Chart } from 'react-chartjs-2';

ChartJS.register(
  LinearScale,
  CategoryScale,
  BarElement,
  PointElement,
  LineElement,
  Tooltip,
  LineController,
  BarController
);

const Stacked = (props: any) => {
  const [labelSet, setLabelSet] = useState<any>([]);
  const [opdata1, setOPData1] = useState<any>([]);
  const [opdata2, setOPData2] = useState<any>([]);
  const [opdata3, setOPData3] = useState<any>([]);
  const [opdata4, setOPData4] = useState<any>([]);

  useEffect(() => {
    const lab: any = [], d1: any = [], d2: any = [], d3: any = [], d4: any = [];

    props?.oeeDetails.map((i: any) => {
      if (!lab.includes(i.BatchNo)) lab.push(i.BatchNo);
      if (i.MetricName === "AvgSpeed") d1.push(i.MetricValue);
      if (i.MetricName === "Good") d2.push(i.MetricValue);
      if (i.MetricName === "Rejected") d3.push(i.MetricValue);
      if (i.MetricName === "RatedSpeed") d4.push(i.MetricValue);
    });
    setLabelSet(lab);
    setOPData1(d1);
    setOPData2(d2);
    setOPData3(d3);
    setOPData4(d4);
  }, [props]);

  const options = {
    plugins: {
      datalabels: { color: 'white', display: false },
      legend: {
        display: false, // Disable the default legend
      },
      tooltip: {
        callbacks: {
          label: function (tooltipItem: any) {
            let label = tooltipItem.dataset.label || '';
            if (label === 'Actual Speed') {
              label += ': ' + parseFloat(tooltipItem.raw).toFixed(2); // Format Actual Speed to 2 decimal places
            } else {
              label += ': ' + tooltipItem.raw;
            }
            return label;
          },
        },
      },
    },
    responsive: true,
    scales: {
      x: {
        stacked: true,
        ticks: {
          color: 'white',
        },
        grid: {
          color: 'transparent',
        },
      },
      y: {
        stacked: true,
        display: true,
        position: 'left' as const,
        ticks: {
          color: 'white',
        },
        grid: {
          color: '#878f99',
        },
        title: {
          display: true,
          text: 'Product Count',
          color: 'white',
          font: {
            size: 12
          }
        },
      },
      y1: {
        type: 'linear' as const,
        display: true,
        position: 'right' as const,
        ticks: {
          color: 'white',
        },
        grid: {
          color: 'transparent',
        },
        title: {
          display: true,
          text: 'Actual Speed / Rated Speed (bpm)',
          color: 'white',
          font: {
            size: 12
          }
        },
      },
    },
  };

  const labels = labelSet;

  const data = {
    labels,
    datasets: [
      {
        label: 'Actual Speed',
        data: opdata1,
        backgroundColor: '#ffc107',
        borderColor: '#ffc107',
        borderWidth: 2,
        type: 'line' as const,
        yAxisID: 'y1',
      },
      {
        label: 'Rated Speed',
        data: opdata4,
        backgroundColor: '#0dcaf0',
        borderColor: '#0dcaf0',
        borderWidth: 2,
        type: 'line' as const,
        yAxisID: 'y1',
      },
      {
        label: 'Good Count',
        data: opdata2,
        backgroundColor: '#198754',
        borderColor: '#198754',
        borderWidth: 0,
        yAxisID: 'y',
      },
      {
        label: 'Rejected Count',
        data: opdata3,
        backgroundColor: '#FF4747',
        borderColor: '#FF4747',
        borderWidth: 0,
        yAxisID: 'y',
      },
    ],
  };

  const renderCustomLegend = () => {
    return (
      <ul style={{ display: 'flex', justifyContent: 'center', listStyleType: 'none', padding: 0 }} className='legendcls'>
        {data.datasets.map((dataset, index) => (
          <li key={index} style={{ display: 'flex', alignItems: 'center', marginRight: '20px'}}>
            <span style={{
              backgroundColor: dataset.backgroundColor as string,
              width: '35px',
              height: '10px',
              display: 'inline-block',
              marginRight: '5px'
            }}></span>
            <span style={{ color: '#e0e0e0', fontFamily: 'montserrat', fontSize: '10px' }}>{dataset.label}</span>
          </li>
        ))}
      </ul>
    );
  };

  return (
    <div className='barchartPMEC noheight'>
      {renderCustomLegend()} {/* Render the custom legend here */}
      <Chart type='bar' data={data} options={options} plugins={[ChartDataLabels]} />
    </div>
  );
}

export default Stacked;
