import { Card } from '@mui/material';
import DialerIcon from '../../../assets/images/Dialer.svg';
import './index.scss';
type Props = { data: any };
const MachineOverviewContactCard = (props: Props) => {
    const { data } = props;
    return (
        <Card
            style={{
                border: '1px solid #2B2D42',
                borderRadius: '6px',
                backgroundColor: '#1D1E2C',
                padding: '10px',
                boxShadow: 'none',
                minHeight: '180px'
            }}
        >
            <div style={{ display: 'flex', justifyContent: 'left' }}>
                <div
                    style={{
                        height: '32px',
                        width: '32px',
                        borderRadius: '50%',
                        backgroundColor: '#5D97F6',
                        marginRight: '8px',
                        minWidth: '32px'
                    }}
                ></div>
                <div className='spocID'>
                    <h5 style={{ fontSize: '12px', color: '#DADCEE', marginBottom: '2px' }}> {data?.SpocName}</h5>
                    <p style={{ fontSize: '10px', color: 'rgb(177 194 223 / 80%)', marginBottom: '12px' }}>
                        {data?.EmailId}
                    </p>
                </div>
            </div>
            <div style={{ display: 'flex', justifyContent: 'space-evenly' }}>
                <div style={{ flex: '30%' }}>
                    <p style={{ fontSize: '12px', color: '#9CC2FF', marginBottom: '2px' }}>Primary Contact</p>
                    <p style={{ fontSize: '12px', color: '#B1C2DF', marginBottom: '2px' }}> {data?.PrimaryContact}</p>
                </div>
                {/* <div style={{ height: '32px', width: '32px', borderRadius: '50%', backgroundColor: '#5D97F6' }}>
                   
                </div> */}
                <a href={`tel:${data?.PrimaryContact}`}>
                    <img src={DialerIcon} alt="Dial" />
                </a>
            </div>
            <div style={{ display: 'flex', justifyContent: 'space-evenly', marginTop: '10px' }}>
                <div style={{ flex: '30%' }}>
                    <p style={{ fontSize: '12px', color: '#9CC2FF', marginBottom: '2px' }}>Secondary Contact</p>
                    <p style={{ fontSize: '12px', color: '#B1C2DF', marginBottom: '2px' }}>{data?.SecondaryContact}</p>
                </div>
                {/* <div style={{ height: '32px', width: '32px', borderRadius: '50%', backgroundColor: '#5D97F6' }}></div> */}
                <a href={`tel:${data?.SecondaryContact}`}>
                    <img src={DialerIcon} alt="Dial" />
                </a>
            </div>
        </Card>
    );
};

export default MachineOverviewContactCard;
