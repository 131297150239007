import Radio from '@mui/material/Radio';
import FormControlLabel from '@mui/material/FormControlLabel';
import FormLabel from '@mui/material/FormLabel';

type ComponentProps = {
    value?: any;
    label?: string;
    fields?: { label?: string; value?: any }[];
    onChange?: Function;
    getEvent?: boolean;
    disabled?: boolean;
};
const CustomRadioGroup = (props: ComponentProps) => {
    const { label = '', value, fields = [], onChange = () => { }, getEvent, disabled = false } = props;

    const handleChange = (event: any) => {
        getEvent ? onChange(event) : onChange(event?.target?.value);
    };

    return (
        <div className="section-buttons radio-font notificatgb">
            {label && <FormLabel>{label}</FormLabel>}
            {fields.map((field) => (
                <FormControlLabel
                sx={{
                    "&.MuiFormControlLabel-root .MuiFormControlLabel-label.Mui-disabled": {
                      color: "#ffffff"
                    },
                    fontFamily: 'Montserrat' 
                  }}
                    key={field?.value}
                    checked={field?.value === value}
                    onChange={handleChange}
                    value={field?.value}
                    control={<Radio size="small" />}
                    label={field?.label}
                    disabled={disabled}
                />
            ))}
        </div>
    );
};

export default CustomRadioGroup;
