import { useEffect, useRef, useState } from 'react';
import { Container, Paper, Typography, IconButton, Grid } from '@mui/material';
import { Formik, Form, FormikProps } from 'formik';
import * as Yup from 'yup';
import { MD_YUPSCHEMA } from './schema';
import { CREATEPWD_YUPSCHEMA } from './schema';
import useGetState from '../../../../utils/hooks/useGetState';
import { withRouter, Link, NavLink, useHistory } from 'react-router-dom';
import { useDispatch } from 'react-redux';
import { executeACGAction, resetErr, updateScreenIdentifiers, reset } from '../../../../store/slice';
import ACGButton from '../../../../atoms/Button';
import Label from '../../../../atoms/Label/index';
import TextInput from '../../../../atoms/TextInput';
import VisibilityOffIcon from '../../../../../assets/visibilityOffIcon.svg';
// import '../CreateReport/index.scss';
import VisibilityIcon from '../../../../../assets/visibilityIcon.svg';
import { ACTION_CODES, STORE_KEYS } from '../../../../constants/apiConstants';
import SnackbarAlert from '../../../../atoms/Snackbar';
import { renameObjKeys } from '../../helper';
import DropDown from '../../../../atoms/Dropdown';
import { paths, ROUTES_PATH } from '../../../../constants/paths';
// import useGetState from '../../../../utils/hooks/useGetState';

type ComponentProps = {
    drawerClose: Function;
    setCurrTab?: Function;
    setReload?: Function;
    setSnackbarOpts?: Function;
    handleLogout?: Function;
    removeLocalStorage?: Function;
};

type AssignFeatureType = {
    features: any;
};
// type ModuleType = {
//     passWord: string | '';
//     newPwd: string | '';
//     // mdOrdId: Number | null;
// };
const options = {
    AD_SUCCESS: {
        message: 'Password Verify successfully!',
        open: true,
        type: 'success'
    },
    DEFAULT: {
        message: '',
        open: false,
        type: ''
    }
};

const optionsUpdateQuestion = {
    AD_SUCCESS: {
        message: 'Question and answer updated successfully!',
        open: true,
        type: 'success'
    },
    DEFAULT: {
        message: '',
        open: false,
        type: ''
    }
};
const UpdateQuestion = (props: ComponentProps) => {
    const { drawerClose, setCurrTab, setReload = () => { }, handleLogout = () => { }, removeLocalStorage = () => { } } = props;
    const formRef = useRef<any>(null);
    const [validationSchema, setValidationSchema] = useState({});
    const [initialValues] = useState({
        passWord: '',
        newPwd: '',
        newAnswer: ''
    });
    const [snackbarOptions, setSnackbarOpts] = useState(options.DEFAULT);
    const [showPassword, setShowPassword] = useState(false);
    const [disableSubmit, setDisableSubmit] = useState(true);
    const [passwordField, setPasswordField] = useState(true);
    const [questionField, setQuestionField] = useState(false);
    const [questionList, setQuestionList] = useState<any[]>([]);
    const [editField, setEditField] = useState(false);
    const [selectedQuestion, setSelectedQuestion] = useState(null);
    const [userQuestion, setUserQuestion] = useState<any>('');
    const [userAnswer, setUserAnswer] = useState<any>('');
    const [forgetKey, setForgetKey] = useState<any>('');
    const [question, setQuestion] = useState<any[]>([]);
    const [selectedMachine, setSelectedMachine] = useState<string[]>([]);
    const state = useGetState();
    const userName = state[STORE_KEYS.USER_DTL]
    const handleClick = () => {
        setShowPassword((prev) => !prev);
    };
    const closeSnackbar = () => setSnackbarOpts(options.DEFAULT);
    const [showConfirmPassword, setShowConfirmPassword] = useState(false);
    const history = useHistory();
    // const state = useGetState();
    const dispatch = useDispatch();
    const handleClickConfirm = () => {
        setShowConfirmPassword((prev) => !prev);
    };
    const renderButtons = () => {
        return (
            <div className={'btns noWrap'}>
                <ACGButton
                    name={'Cancel'}
                    handleClick={drawerClose}
                    secondary={true}
                    className={'first-btn'}
                    type="button"
                    variant="contained"
                />
                <ACGButton
                    name={'Submit'}
                    handleClick={() => { }}
                    type="submit"
                    className="second-btn"
                    variant="contained"
                />
            </div>
        );
    };
    const handleCancel = () => {
        dispatch(updateScreenIdentifiers({
            storeKey: STORE_KEYS.PWD_VERIFY,
            uniqueScreenIdentifier: {
                body: [],
                statusCode: null,
                mdAdded: false
            }
        }))
        drawerClose();
    }
    useEffect(() => {
        setValidationSchema(Yup.object().shape(CREATEPWD_YUPSCHEMA));
    }, []);
    useEffect(() => {
        dispatch(updateScreenIdentifiers({
            storeKey: STORE_KEYS.PWD_VERIFY,
            uniqueScreenIdentifier: {
                body: [],
                statusCode: null,
                mdAdded: false
            }
        }))
    }, [])
    const handleSubmit = async () => {
        const userEmail = userName?.EmailId
        const bundle = {
            payload: {
                urlPath: ACTION_CODES.UPDATEPWD,
                requestType: 'POST',
                reqObj: {
                    email: userEmail,
                    currentPassword: formRef?.current?.values?.passWord,
                    newPassword: formRef?.current?.values?.newPwd,
                }
            },
            uniqueScreenIdentifier: { mdAdded: true },
            storeKey: STORE_KEYS.UPDATE_PWD
        };
        dispatch(executeACGAction(bundle));
    };
    useEffect(() => {
        if (state?.[STORE_KEYS.UPDATE_PWD]?.statusCode === 200) {
            setSnackbarOpts(options.AD_SUCCESS);
            drawerClose();
        }
        dispatch(
            updateScreenIdentifiers({
                storeKey: STORE_KEYS.UPDATE_PWD,
                uniqueScreenIdentifier: {
                    mdAdded: false
                }
            })
        );
    }, [state?.[STORE_KEYS.UPDATE_PWD]?.statusCode === 200]);
    const handleSnackbarError = (err: any) => {
        const errorMsg = err?.message || 'Internal Server error';
        const snackbarError = {
            message: errorMsg,
            type: 'reject',
            open: true
        };
        setSnackbarOpts(snackbarError);
    };

    useEffect(() => {
        if (state?.err) {
            handleSnackbarError(state?.err);
            dispatch(resetErr());
        }
    }, [state?.err]);

    const handleVerifyPassword = async () => {
        const userEmail = userName?.EmailId
        const bundle = {
            payload: {
                urlPath: ACTION_CODES.PWDVRFY,
                requestType: 'POST',
                reqObj: {
                    email: userEmail,
                    password: formRef?.current?.values?.passWord,
                }
            },
            uniqueScreenIdentifier: { mdAdded: true },
            storeKey: STORE_KEYS.PWD_VERIFY
        };
        dispatch(executeACGAction(bundle));
    };
    useEffect(() => {
        if (state?.[STORE_KEYS.PWD_VERIFY]?.statusCode === 200) {
            setSnackbarOpts(options.AD_SUCCESS);
            getQuestions();
            setReload(true);
            setQuestionField(true);
            setPasswordField(false)
        }
        dispatch(
            updateScreenIdentifiers({
                storeKey: STORE_KEYS.PWD_VERIFY,
                uniqueScreenIdentifier: {
                    mdAdded: false
                }
            })
        );
    }, [state?.[STORE_KEYS.PWD_VERIFY]?.statusCode === 200]);

    const getQuestions = async () => {
        const userEmail = userName?.EmailId
        const bundle = {
            payload: {
                urlPath: `${ACTION_CODES.GETQA}/${userEmail}`,
                requestType: 'GET',
                reqObj: {}
            },
            uniqueScreenIdentifier: { qnRecd: true },
            storeKey: STORE_KEYS.GET_QA
        };
        dispatch(executeACGAction(bundle));
    };
    useEffect(() => {
        const questionList = state?.[STORE_KEYS.GET_QA]?.body?.data;
        setQuestion(questionList);
    }, [state?.[STORE_KEYS.GET_QA]?.body?.data]);
    useEffect(() => {
        const question = state?.[STORE_KEYS.GET_QA]?.body?.data[0]?.Question;
        const answer = state?.[STORE_KEYS.GET_QA]?.body?.data[0]?.Answer;
        const forgetKey = state?.[STORE_KEYS.GET_QA]?.body?.data[0]?.ForgetPwdQnKey;
        setUserQuestion(question);
        setUserAnswer(answer);
        setForgetKey(forgetKey);
    }, [question])

    const handleEditQuestion = () => {
        setQuestionField(false);
        setEditField(true);
    }

    //API CALL FOR QUESTION LIST

    useEffect(() => {
        dispatch(
            executeACGAction({
                payload: {
                    requestType: 'GET',
                    urlPath: ACTION_CODES.GETQA
                },
                storeKey: STORE_KEYS.GET_QA,
                uniqueScreenIdentifier: {
                    isLoading: true
                }
            })
        );
    }, [editField]);
    useEffect(() => {
        const clientsDt = state?.[STORE_KEYS.GET_QA]?.body?.data;
        setQuestionList(
            clientsDt?.map((item: any) => {
                const newVal = renameObjKeys(item, { ForgetPwdQnKey: 'code', Question: 'name' });
                return newVal;
            })
        );
    }, [state?.[STORE_KEYS.GET_QA]?.body?.data]);

    //API CALL FOR UPDATE QUESTION AND ANSWER

    const handleUpdateQuestion = async () => {
        console.log("edit password click", formRef?.current?.values?.newAnswer);
        const userEmail = userName?.EmailId
        const forgetPwdQnkey = state?.[STORE_KEYS.GET_QA]?.body?.data[0]?.forgetPwdQnkey;
        const bundle = {
            payload: {
                urlPath: ACTION_CODES.ADDQA,
                requestType: 'POST',
                reqObj: {
                    email: userEmail,
                    forgetPwdQnkey: selectedQuestion,
                    answer: formRef?.current?.values?.newAnswer.replace(/'/g, "''"),
                }
            },
            uniqueScreenIdentifier: { mdAdded: true },
            storeKey: STORE_KEYS.ADD_QA
        };
        dispatch(executeACGAction(bundle));
    };

    useEffect(() => {
        if (state?.[STORE_KEYS.ADD_QA]?.statusCode === 200) {
            setSnackbarOpts(optionsUpdateQuestion.AD_SUCCESS);
            setTimeout(() => {
                drawerClose();
                handleLogout();
            }, 2000);
        }
        dispatch(
            updateScreenIdentifiers({
                storeKey: STORE_KEYS.ADD_QA,
                uniqueScreenIdentifier: {
                    mdAdded: false
                }
            })
        );
    }, [state?.[STORE_KEYS.ADD_QA]?.statusCode === 200]);

    return (
        <Container className="createReport">
            <SnackbarAlert options={snackbarOptions} handleClose={closeSnackbar} />
            <Formik
                initialValues={initialValues}
                validationSchema={validationSchema}
                onSubmit={() => {
                    handleSubmit();
                }}
                validateOnBlur={false}
                validateOnChange={false}
                innerRef={formRef}
            >
                {(formikprops) => {
                    const { values, handleChange, handleBlur, errors, touched } = formikprops;
                    return (
                        <Form>
                            {passwordField &&
                                <>
                                    <Label label="Enter password" className={'labelDiv'} />
                                    <TextInput
                                        variant="outlined"
                                        maxLength={30}
                                        autoFocus
                                        fullWidth
                                        type={showPassword ? 'text' : 'password'}
                                        name="passWord"
                                        value={values.passWord}
                                        autoComplete="off"
                                        // formInput="textBoxDiv"
                                        formInput="inputDiv"
                                        onChange={(e) => {
                                            setDisableSubmit(false);
                                            handleChange(e);
                                        }}
                                        endAdornment={
                                            <IconButton tabIndex={-1} className="password-eye" onClick={handleClick}>
                                                {showPassword ? (
                                                    <img src={VisibilityIcon} alt="logo" />
                                                ) : (
                                                    <img src={VisibilityOffIcon} alt="logo" />
                                                )}
                                            </IconButton>
                                        }
                                        onBlur={handleBlur}
                                        placeholder="Enter password"
                                        helperText={errors.passWord && touched.passWord ? errors.passWord : ''}
                                    />
                                    <div className={'btns noWrap'}>
                                        <ACGButton
                                            name={'Cancel'}
                                            handleClick={drawerClose}
                                            secondary={true}
                                            className={'first-btn'}
                                            type="button"
                                            variant="contained"
                                        />
                                        <ACGButton
                                            name={'Submit'}
                                            handleClick={() => { handleVerifyPassword() }}
                                            type="submit"
                                            className="second-btn"
                                            variant="contained"
                                        />
                                    </div>
                                </>}
                            {questionField &&
                                <>
                                    <Label label="Security Question" className={'labelDiv'} />
                                    <Typography className={'labelDiv'} sx={{ color: 'white' }}>{userQuestion}</Typography>
                                    <Label label="Answer" className={'labelDiv'} />
                                    <Typography className={'labelDiv'} sx={{ color: 'white' }}>{userAnswer}</Typography>
                                    <div className={'btns noWrap'}>
                                        <ACGButton
                                            name={'Cancel'}
                                            // handleClick={drawerClose}
                                            handleClick={(event: any) => { handleCancel() }}
                                            secondary={true}
                                            className={'first-btn'}
                                            type="button"
                                            variant="contained"
                                        />
                                        <ACGButton
                                            name={'Edit'}
                                            handleClick={() => { handleEditQuestion() }}
                                            type="submit"
                                            className="second-btn"
                                            variant="contained"
                                        />
                                    </div>
                                </>}
                            {editField && <>
                                <SnackbarAlert options={snackbarOptions} handleClose={closeSnackbar} />
                                <Grid item xs={10}>
                                    <Label label="Security Question" className={'labelDiv'} />
                                    <DropDown
                                        style={{ color: 'black' }}
                                        onChange={(event: any) => {
                                            setSelectedQuestion(event?.target?.value);
                                        }}
                                        className="inputDiv"
                                        name="dropdownClient"
                                        placeHolder="Select Question"
                                        items={questionList}
                                        value={selectedQuestion}
                                    />
                                    <Label label="Security Answer" className={'labelDiv'} />
                                    <TextInput
                                        variant="outlined"
                                        fullWidth
                                        type="text"
                                        name="newAnswer"
                                        value={values.newAnswer}
                                        autoComplete="off"
                                        formInput="inputDiv"
                                        onChange={(e: any) => {
                                            handleChange(e);
                                        }}
                                        onBlur={handleBlur}
                                        placeholder="Enter New Answer"
                                        helperText={errors.newAnswer && touched.newAnswer ? errors.newAnswer : ''}
                                    />
                                </Grid>
                                <div className={'btns noWrap'}>
                                    <ACGButton
                                        name={'Cancel'}
                                        // handleClick={drawerClose}
                                        handleClick={(event: any) => { handleCancel() }}
                                        secondary={true}
                                        className={'first-btn'}
                                        type="button"
                                        variant="contained"
                                    />
                                    <ACGButton
                                        name={'Submit'}
                                        handleClick={() => { handleUpdateQuestion() }}
                                        type="submit"
                                        className="second-btn"
                                        variant="contained"
                                    />
                                </div>

                            </>}
                        </Form>
                    );
                }}
            </Formik>
        </Container>
    );
};
export default UpdateQuestion;
