import * as React from 'react';
import Box from '@mui/material/Box';
import Drawer from '@mui/material/Drawer';
import Button from '@mui/material/Button';
import List from '@mui/material/List';
import Divider from '@mui/material/Divider';
import ListItem from '@mui/material/ListItem';
import ListItemButton from '@mui/material/ListItemButton';
import ListItemIcon from '@mui/material/ListItemIcon';
import ListItemText from '@mui/material/ListItemText';
import InboxIcon from '@mui/icons-material/MoveToInbox';
import MailIcon from '@mui/icons-material/Mail';

type Anchor = 'right';

type Props = {
    status: any;
    handlerOpen: any;
    handlerClose: any;
    drawerHeader?: any;
    children?: any;
};

export default function TemporaryDrawer(props: Props) {
    const { status, handlerOpen, handlerClose, drawerHeader, children } = props;
    //   const [state, setState] = React.useState({
    //     // top: false,
    //     // left: false,
    //     // bottom: false,
    //     right: false,
    //   });

    //   const toggleDrawer =
    //     (anchor: Anchor, open: boolean) =>
    //     (event: React.KeyboardEvent | React.MouseEvent) => {
    //       if (
    //         event.type === "keydown" &&
    //         ((event as React.KeyboardEvent).key === "Tab" ||
    //           (event as React.KeyboardEvent).key === "Shift")
    //       ) {
    //         return;
    //       }

    //       setState({ ...state, [anchor]: open });
    //     };

    const list = () => (
        <Box sx={{ width: 250 }} role="presentation" onClick={handlerClose} onKeyDown={handlerClose}>
            <List>
                {['Inbox', 'Starred', 'Send email', 'Drafts'].map((text, index) => (
                    <ListItem key={text} disablePadding>
                        <ListItemButton>
                            <ListItemIcon>{index % 2 === 0 ? <InboxIcon /> : <MailIcon />}</ListItemIcon>
                            <ListItemText primary={text} />
                        </ListItemButton>
                    </ListItem>
                ))}
            </List>
            <Divider />
            <List>
                {['All mail', 'Trash', 'Spam'].map((text, index) => (
                    <ListItem key={text} disablePadding>
                        <ListItemButton>
                            <ListItemIcon>{index % 2 === 0 ? <InboxIcon /> : <MailIcon />}</ListItemIcon>
                            <ListItemText primary={text} />
                        </ListItemButton>
                    </ListItem>
                ))}
            </List>
        </Box>
    );

    return (
        <div>
            {/* {(["right"] as const).map((anchor) => ( */}
            {/* <React.Fragment key={"right"}> */}
            {/* <Button onClick={handlerOpen}>{"right"}</Button> */}
            <Drawer anchor={'right'} open={status} onClose={handlerClose} style={{ zIndex: '999999999' }}
             >
                {/* {list()} */}
                {children}
            </Drawer>
            {/* </React.Fragment> */}
            {/* ))} */}
        </div>
    );
}
