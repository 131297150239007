import { useState, useEffect } from 'react';
import Box from '@mui/material/Box';
import Label from '../../../../atoms/Label/index';
import TextInput from '../../../../atoms/TextInput';
import DropDown from '../../../../atoms/Dropdown';
import InputLabel from '@mui/material/InputLabel';
import MenuItem from '@mui/material/MenuItem';
import FormControl from '@mui/material/FormControl';
import Select from '@mui/material/Select';
import TextField from '@mui/material/TextField';
import Stack from '@mui/material/Stack';
import Button from '@mui/material/Button';
import Container from '@mui/material/Container';
import ACGButton from '../../../../atoms/Button';
import useGetState from '../../../../utils/hooks/useGetState';
import { ACTION_CODES, STORE_KEYS, BASEURL } from '../../../../constants/apiConstants';
import notificationService from '../../notificationService';
import "../../cm.scss";
import { Grid } from '@mui/material';
import { useDispatch } from 'react-redux';
import { executeACGAction } from '../../../../store/slice';
import { renameObjKeys } from '../../../Login/helper';

export default function CreateForm(props: any) {
  console.log("🚀 ~ CreateForm ~ props:", props)
  const dispatch = useDispatch()
  const [moduleKey, setModuleKey] = useState<any>('');
  const [grpName, setGrpName] = useState('');
  const [grpDescription, setGrpDescription] = useState('');
  const [customerKey, setCustomerKey] = useState<any>('');
  const [plantKey, setPlantKey] = useState('');
  const [plantList, setPlantList] = useState([])
  const state = useGetState();
  const token = state?.[STORE_KEYS.USER_DTL].token;
  const getLoggedUserId = state[STORE_KEYS.USER_DTL]?.LoggedUserKey;
  const handleChange = (event: any, type: string) => {
    type === "modules" ? setModuleKey(event.target.value) : setCustomerKey(event.target.value);
  };

  useEffect(() => {
    if (props.nKey !== "") {
      const editableData = props.notificationData?.find((i: any) => i.NotifGrpKey === props.nKey);
      setGrpDescription(editableData?.NotifGroupDesc);
      setGrpName(editableData?.NotifGroupName);
    }
  }, [props.nKey])

  const reset = () => {
    setModuleKey('');
    setCustomerKey('');
    setGrpDescription('');
    setGrpName('');
    // props.setGrpKey('');
  }

  useEffect(() => {
    if (props.nKey === '') {
      reset();
    }
  }, [props.nKey])

  const options = {
    AD_SUCCESS: {
      message: `Notification group ${props.nKey === "" ? 'created' : 'edited'} successfully!`,
      open: true,
      type: 'success'
    },
    AD_FAIL: {
      message: `Notification group name alredy exsist !`,
      open: true,
      type: 'remark'
    }
  };

  const editableData = props.notificationData?.find((i: any) => i.NotifGrpKey === props.nKey);

  const createNotificationGroup = () => {
    let name, desc;
    if (props.nKey === '') {
      name = grpName;
    } else {
      if (grpName === '') {
      } else {
        name = grpName
      }
    }
    if (props.nKey === '') {
      desc = grpDescription;
    } else {
      if (grpDescription === '') {
        // desc = editableData.RuleEngNotifGroupDesc;
      } else {
        desc = grpDescription
      }
    }
    const body = {
      userId: getLoggedUserId,
      moduleId: props.nKey === '' ? moduleKey : editableData.NotifGrpModuleKey,
      customerKey: props.nKey === '' ? customerKey : editableData.CustomerKey,
      grpName: name,
      grpDescription: desc,
      type: props.nKey === '' ? 'Add' : 'Edit',
      grpKey: props.nKey === '' ? null : props.nKey,
      token: token,
      plantKey: props.nKey === '' ? plantKey : editableData.PlantKey
    }
    notificationService.createNotificationGroup(body)
      .then((response: any) => {
        if (response.status === 200) {
          const hasError = response?.data?.message[0]
          const errorMessage = hasError[0];
          reset();
          props.setCurrTab(2);
          props.setSnackbarOpts(errorMessage[0]?.Result === "Error" ? {
            message: errorMessage[0]?.ResultMessage,
            open: true,
            type: 'remark'
          } : options.AD_SUCCESS);
          props.drawerClose();
          props.fetchNotificationGroups(true);
        } else {
          props.setCurrTab(2);
          props.setSnackbarOpts(options.AD_FAIL);
        }
      })
      .catch((err: any) => {
        console.log(err);
      });
  }

  let yesDisabled: boolean;
  if (props.nKey === '') {
    yesDisabled = grpDescription === '' || grpName === '' || customerKey === '' || plantKey==""? true : false;
  } else {
    yesDisabled = grpDescription === '' || grpName === '' || editableData?.PlantName=="" ? true : false;
  }

  const renderButtons = () => {
    return (
      <div className={'btns noWrap'} style={{ display: "flex", justifyContent: "flex-end", width: "100%" }}>
        <ACGButton
          name={'Cancel'}
          handleClick={props.drawerClose}
          secondary={true}
          className={'first-btn'}
          type="button"
          variant="contained"
        />
        <ACGButton
          name={'Submit'}
          handleClick={() => { createNotificationGroup() }}
          type="submit"
          className="second-btn"
          variant="contained"
          disabled={yesDisabled ? true : false}
        />
      </div>
    );
  };
  useEffect(() => {
    if (customerKey) {
      dispatch(
        executeACGAction({
          payload: {
            requestType: 'POST',
            urlPath: ACTION_CODES.API_GET_NOTIFICATION_GROUP_PLANT_LIST,
            reqObj: {
              "CustomerKey": `${customerKey}`
            }
          },
          storeKey: STORE_KEYS.API_GET_NOTIFICATION_GROUP_PLANT_LIST
        })
      );
    }
  }, [customerKey])

  useEffect(() => {
    if (state?.[STORE_KEYS.API_GET_NOTIFICATION_GROUP_PLANT_LIST]?.body?.data) {
      const grpsList = state?.[STORE_KEYS.API_GET_NOTIFICATION_GROUP_PLANT_LIST]?.body?.data?.map((item: any) => {
        const newVal = renameObjKeys(item, { PlantKey: 'code', PlantName: 'name' });
        return newVal;
      });
      setPlantList(grpsList);
    }

  }, [state?.[STORE_KEYS.API_GET_NOTIFICATION_GROUP_PLANT_LIST]])

  return (
    <Container className="createReport">
      <Box>
        <div >
          <FormControl fullWidth>
            <Label label="Module Name &nbsp; *" className={'CMlabelDiv'} />
            {props.nKey === '' ?
              <DropDown
                onChange={(e: any) => handleChange(e, "modules")}
                className="inputDiv"
                name="dropdownModel"
                items={props.modulesData}
                placeHolder="Select Module"
                value={moduleKey}
              />
              :
              <TextInput id="outlined-basic"
                fullWidth
                variant="outlined"
                disabled
                value={editableData?.ModuleName}
              />
            }

            <Label label="Customer Name &nbsp; *" className={'CMlabelDiv'} />
            {props.nKey === '' ?
              <DropDown
                onChange={(e: any) => handleChange(e, "customers")}
                className="inputDiv"
                name="dropdownModel"
                items={props.customersData}
                placeHolder="Select Customer Name"
                value={customerKey}
              // helperText={
              //     errors.dropdownModel && touched.dropdownModel ? errors.dropdownModel : ''
              // }
              />
              :
              <TextInput id="outlined-basic"
                fullWidth
                variant="outlined"
                disabled
                value={editableData?.CustomerName}
              />
            }
            <Label label="Plant Name &nbsp; *" className={'CMlabelDiv'} />
            {props.nKey === '' ?
              <DropDown
                onChange={(e: any) => setPlantKey(e.target.value)}
                className="inputDiv"
                name="dropdownModel"
                items={plantList}
                placeHolder="Select Plant"
                value={plantKey}
              />
              :
              <TextInput id="outlined-basic"
                fullWidth
                variant="outlined"
                disabled
                value={editableData?.PlantName}
              />
            }

            <Label label="Group Name &nbsp; *" className={'CMlabelDiv'} />
            <TextInput id="outlined-basic"
              fullWidth
              variant="outlined"
              inputProps={{ maxLength: 20 }}
              value={grpName}
              onChange={(e) => setGrpName(e.target.value)}
              placeholder='Enter Name'
            />

            <Label label="Group Description &nbsp; *" className={'CMlabelDiv'} />
            <Box
              component="form"
              className={'multilineheight'}
              sx={{
                '& .MuiTextField-root': { m: 0, width: '100%' },
              }}
              noValidate
              autoComplete="off"
            >

              <TextInput
                fullWidth
                multiline
                maxRows={4}
                sx={{ margin: '0px', padding: '2px' }}
                id="outlined-basic"
                className='textareaheight'
                variant="outlined"
                inputProps={{ maxLength: 50, shrink: true }}
                value={grpDescription}
                onChange={(e) => { setGrpDescription(e.target.value) }}
                placeholder='Enter Descritption'

              />
            </Box>
          </FormControl>
        </div>
      </Box>
      <Grid style={{ display: "flex", marginTop: 20 }}>
        {renderButtons()}
      </Grid>

    </Container>
  );
}