import React from 'react';

import { useState, useEffect, useRef } from 'react';
import { Container } from '@mui/material';
import ACGButton from '../../../../atoms/Button';
import { Formik, Form, FormikProps } from 'formik';
import { Grid } from '@material-ui/core';
import TextInput from '../../../../atoms/TextInput';
import DropDown from '../../../../atoms/Dropdown';
import Label from '../../../../atoms/Label/index';
import { TOKEN_SCHEMA } from './schems';
import * as Yup from 'yup';
import useGetState from '../../../../utils/hooks/useGetState';
import { useDispatch } from 'react-redux';
import { executeACGAction, resetErr, resetStoreKey } from '../../../../store/slice';
import CopyToClipboardButton from '../../../../atoms/CopyToClipBoard';
import './index.scss';
import { ACTION_CODES, STORE_KEYS } from '../../../../constants/apiConstants';
import { convertDaysToMin, convertHrsToMin } from '../../../../utils/formatTime';

type Props = {
    drawerClose: Function;
    setSnackbarOpts: Function;
    machineSerialNo: string;
};
type TokenType = {
    dropdownTimeUnit: string | '';
    unit: string | '';
};
const options = {
    TOKEN_COPIED: {
        message: 'Token Copied successfully!',
        open: true,
        type: 'success'
    }
};
const GenerateToken = (props: Props) => {
    const { drawerClose, setSnackbarOpts, machineSerialNo } = props;
    console.log(machineSerialNo);

    const state = useGetState();
    const dispatch = useDispatch();
    const [timeUnitsDropdown, setTimeUnitsDropdown] = useState<any>([]);
    const [validationSchema, setValidationSchema] = useState<any>({});
    const [sasCode, setSasCode] = useState<string>('');
    const [apiCall, setApicall] = useState<boolean>(false);
    const formRef = useRef<any>(null);
    const [initialValues] = useState({
        dropdownTimeUnit: '',
        unit: ''
    });

    const handleUnitChange = () => {
        setApicall(!apiCall);
    };
    useEffect(() => {
        return () => {
            setSasCode('');
            dispatch(resetStoreKey({ storeKey: STORE_KEYS.ADD_RPT }));
        };
    }, []);
    useEffect(() => {
        setSasCode('');
        dispatch(resetStoreKey({ storeKey: STORE_KEYS.ADD_RPT }));
    }, [apiCall]);

    const handleSubmit = (values: any) => {
        if (!sasCode) {
            const min = values.dropdownTimeUnit == 'hrs' ? convertHrsToMin(values.unit) : convertDaysToMin(values.unit);
            const reqPayload = {
                payload: {
                    urlPath: ACTION_CODES.GET_SAS_TOKEN,
                    requestType: 'POST',
                    reqObj: {
                        machineSerialNo: machineSerialNo,
                        expiresInMins: min
                    }
                },
                uniqueScreenIdentifier: { sasReceived: true },
                storeKey: STORE_KEYS.ADD_RPT
            };
            dispatch(executeACGAction(reqPayload));
        }
        return;
    };

    useEffect(() => {
        if (state?.[STORE_KEYS.ADD_RPT]?.sasReceived && state?.[STORE_KEYS.ADD_RPT]?.body?.data) {
            setSasCode(state?.[STORE_KEYS.ADD_RPT]?.body?.data);
        }
    }, [state?.[STORE_KEYS.ADD_RPT]?.sasReceived]);
    const renderButtons = () => {
        return (
            <div className={'btns noWrap'}>
                <>
                    <ACGButton
                        name={'Cancel'}
                        handleClick={drawerClose}
                        secondary={true}
                        className={'first-btn'}
                        type="button"
                        variant="contained"
                    />

                    <ACGButton
                        name="Proceed"
                        handleClick={() => {}}
                        type="submit"
                        className="second-btn"
                        variant="contained"
                    />
                </>
            </div>
        );
    };

    const handleSnackbarError = (err: any) => {
        const errorMsg = err?.message || 'Internal Server error';
        const snackbarError = {
            message: errorMsg,
            type: 'reject',
            open: true
        };

        setSnackbarOpts(snackbarError);
    };

    useEffect(() => {
        if (state?.err) {
            handleSnackbarError(state?.err);
            dispatch(resetErr());
        }
    }, [state?.err]);

    useEffect(() => {
        const val: any = [
            { code: 'hrs', name: 'Hrs' },
            { code: 'days', name: 'Days' }
        ];
        setTimeUnitsDropdown(val);
        setValidationSchema(Yup.object().shape(TOKEN_SCHEMA));
    }, []);
    return (
        <Container className="createUser">
            <Formik
                initialValues={initialValues}
                validationSchema={validationSchema}
                onSubmit={(values: any) => {
                    handleSubmit(values);
                }}
                validateOnBlur={false}
                validateOnChange={false}
                innerRef={formRef}
            >
                {(formikprops: FormikProps<TokenType>) => {
                    const { values, handleChange, handleBlur, errors, touched } = formikprops;
                    return (
                        <Form>
                            <>
                                <Label label="Enter unit" className={'labelDiv'} />
                                <Grid container className="email-section">
                                    <Grid item style={{ marginLeft: '10px', width: '100%', marginRight: '20px' }}>
                                        <TextInput
                                            variant="outlined"
                                            fullWidth
                                            type="text"
                                            name="unit"
                                            value={values.unit}
                                            autoComplete="off"
                                            formInput="inputDiv"
                                            onChange={(e: any) => {
                                                handleChange(e);
                                                handleUnitChange();
                                            }}
                                            maxLength={6}
                                            onBlur={handleBlur}
                                            placeholder="Enter unit Number"
                                            helperText={errors.unit && touched.unit ? errors.unit : ''}
                                        />
                                    </Grid>
                                    <Grid item style={{ width: '50%' }}>
                                        <DropDown
                                            onChange={(event: any) => {
                                                handleChange(event);
                                                handleUnitChange();
                                            }}
                                            className="inputDiv"
                                            name="dropdownTimeUnit"
                                            items={timeUnitsDropdown}
                                            placeHolder="Select option"
                                            value={values.dropdownTimeUnit}
                                            helperText={
                                                errors.dropdownTimeUnit && touched.dropdownTimeUnit
                                                    ? errors.dropdownTimeUnit
                                                    : ''
                                            }
                                        />
                                    </Grid>
                                </Grid>
                            </>
                            <CopyToClipboardButton
                                text={sasCode}
                                setSnkBar={() => setSnackbarOpts(options.TOKEN_COPIED)}
                            />
                            {renderButtons()}
                        </Form>
                    );
                }}
            </Formik>
        </Container>
    );
};

export default GenerateToken;
