import React, { useState, useEffect } from 'react';
import moment from 'moment';
import 'chartjs-plugin-datalabels';
import { useHistory } from 'react-router-dom';
import ChartDataLabels from 'chartjs-plugin-datalabels';
import {
  Chart as ChartJS,
  LinearScale,
  CategoryScale,
  BarElement,
  PointElement,
  LineElement,
  Tooltip,
  LineController,
  BarController,
} from 'chart.js';
import { Bar, Chart } from 'react-chartjs-2';

ChartJS.register(
  LinearScale,
  CategoryScale,
  BarElement,
  PointElement,
  LineElement,
  Tooltip,
  LineController,
  BarController
);

const Stacked = (props: any) => {
  const history = useHistory();
  const [labelSet, setLabelSet] = useState<any>([]);
  const [opdata1, setOPData1] = useState<any>([]);
  const [opdata2, setOPData2] = useState<any>([]);
  const [opdata3, setOPData3]= useState<any>([]);

  useEffect(() => {
    const lab: any = [], d1: any = [], d2: any = [], d3:any = [];

    props?.downtime?.map((i: any) => {
      lab.push(i.DTCategoryDesc);
      d1.push(i.DTCategoryValueInMins);
      d2.push(i.CumuPerc);
      d3.push(i.DTCategoryPerc)
    });
    setLabelSet(lab);
    setOPData1(d1);
    setOPData2(d2);
    setOPData3(d3);
  }, [props])

  const options = {
    plugins: {
      tooltip: {
        callbacks: {
          title: (xDatapoint:any) => {
            return xDatapoint.label;
          },
          label: (yDatapoint:any) => {
            const formattedValue = Number(yDatapoint.raw).toFixed(2);
            const formattedCategory = Number(opdata3[yDatapoint.dataIndex]).toFixed(2);
            return `${yDatapoint.dataset.label}: ${formattedValue}, Downtime %: ${formattedCategory}`;
          },
        }
      }, 
      datalabels: { color: 'white', display: false },
      legend: {
        display: false // Disable the default legend
      }
    },
    responsive: true,
    scales: {
      x: {
        stacked: true,
        ticks: {
          callback: function(label:any) {
            if(labelSet[label].length < 10) {
              return labelSet[label];
            } else { 
              return labelSet[label].substr(0, 10) + "..."   
            }       
          },
        color: 'white',
      },
        grid: {
          color: 'transparent',
        },
      },
      y: {
        stacked: true,
        ticks: {
          color: 'white',
        },
        grid: {
          color: '#878f99',
        },
      },
      y1: {
        type: 'linear' as const,
        display: true,
        position: 'right' as const,
        ticks: {
          color: 'white', 
       },
        grid: {
          color: 'transparent',
        },
        title: {
          display: true,
          text: 'Cumulative %',
          color: 'white',
          font: {
            size: 12
          }
        },
      },
    },
  };

  const labels = labelSet;

  const data = {
    labels,
    datasets: [
      {
        label: 'Cumulative %',
        data: opdata2,
        backgroundColor: '#ffc107',
        borderColor: '#ffc107',
        borderWidth: 2,
        type: 'line' as const,
        yAxisID: 'y1',
      },
      {
        label: 'Duration (in mins.)',
        data: opdata1,
        backgroundColor: '#0d6efd',
        borderColor: '#0d6efd',
        borderWidth: 0,
        type: 'bar' as const,
      },
    ],
  };

  // Custom Legend Component
  const CustomLegend = () => {
    return (
      <div style={{ display: 'flex', justifyContent: 'center' }} className='legendcls'>
        {data.datasets.map((dataset, index) => (
          <div key={index} style={{ marginRight: '10px', display: 'flex', alignItems: 'center' }}>
            <div style={{
              backgroundColor: dataset.backgroundColor as string,
              width: '35px',
              height: '10px',
              marginRight: '5px'
            }} />
            <span style={{ color: '#e0e0e0', fontSize: '10px', fontFamily: 'montserrat' }}>
              {dataset.label}
            </span>
          </div>
        ))}
      </div>
    );
  };

  return (
    <div>
      <CustomLegend />
      <Chart type='bar' style={{ cursor: "pointer" }} data={data} options={options} plugins={[ChartDataLabels]} />
    </div>
  );
}

export default Stacked;
