import * as React from 'react';
import { useState, useEffect } from 'react';
import CustomTable from '../../../../atoms/CustomTable/index';
import CustomTableToolbar from '../../../../atoms/CustomTableToolbar';
import allAlarms from '../../../../_mocks_/allAlarms';
import { Container, Card } from '@mui/material';
import { applySortFilter } from '../../../../utils/helper';
// import "../index.scss";
// import { filter } from "lodash";
// import MapCustomTabs from "../../../atoms/ButtonTabs/MapCustomTabs";
// import FilterModal from "../../../atoms/filterModal";
import MachineMonitoringDrawer from '../../../../atoms/MachineMonitoringDrawer';
import useGetState from '../../../../utils/hooks/useGetState';
import { useDispatch } from 'react-redux';
import { ACTION_CODES, STORE_KEYS } from '../../../../constants/apiConstants';
import { executeACGAction, resetStoreKey, updateScreenIdentifiers } from '../../../../store/slice';
// import StatusCard from "../../../atoms/DashboardStatus/StatusCard";

const TABLE_HEAD = [
    { id: 'MachineName', label: 'Machine Name', alignRight: false },
    { id: 'TriggeredTime', label: 'Trigger Time', alignRight: false },
    { id: 'ResetTime', label: 'Reset Time', alignRight: false },
    { id: 'AlarmDuration', label: 'Duration', alignRight: false }
];

const TABLE_EVENT_HEAD = [
    { id: 'MachineName', label: 'Machine Name', alignRight: false },
    { id: 'TriggeredTime', label: 'Trigger Time', alignRight: false }
];

type Anchor = 'right';

const AllAlarmDetails = (props: any) => {
    const { data, usedIn } = props;
    const [state, setState] = useState({
        right: false
    });
    const [page, setPage] = useState<number>(0);
    const [order, setOrder] = useState('desc');
    const [orderBy, setOrderBy] = useState('machine');
    const [selected, setSelected] = useState<any>([]);
    const [filterName, setFilterName] = useState<string>('');
    const [tableData, setTableData] = useState<any[]>([]);
    const reduxState = useGetState();
    const dispatch = useDispatch();

    useEffect(() => {
        getAlarmMachineList();
        return () => {
            setTableData([]);
        };
    }, []);

    const getAlarmMachineList = () => {
        dispatch(
            executeACGAction({
                payload: {
                    requestType: 'POST',
                    urlPath:
                        usedIn === 'alarm'
                            ? ACTION_CODES.GET_ALARM_DETAILS_OF_MODEL
                            : usedIn === 'event'
                                ? ACTION_CODES.GET_EVENTS_DETAILS_OF_MACHINE
                                : usedIn === 'event-details'
                                    ? ACTION_CODES.GET_EVENTS_DETAILS__OF_MODEL
                                    : ACTION_CODES.GET_ALARM_DETAILS_OF_MACHINE,
                    reqObj:
                        usedIn === 'alarm'
                            ? {
                                severityKey: data?.severity,
                                timePeriodKey: props.timePeriodKey,
                                machineModelKey: data?.machineModelKey,
                                mmParamKey: data?.mmParamKey
                            }
                            : usedIn === 'event-details'
                                ? {
                                    severityKey: data?.severity,
                                    timePeriodKey: props.timePeriodKey ? props.timePeriodKey : 1,
                                    machineModelKey: data?.machineModelKey,
                                    mmParamKey: data?.mmParamKey
                                }
                                : {
                                    machineKey: data?.MachineKey,
                                    severityKey: data?.severity,
                                    timePeriodKey:props.timePeriodKey ? props.timePeriodKey : 1,
                                    mmParamKey: data?.mmParamKey
                                }
                },
                uniqueScreenIdentifier: { activeAlarmDetailTable: true },
                storeKey: STORE_KEYS.ADD_DETAILS_ACTIVE_ALARM
            })
        );
    };

    useEffect(() => {
        if (
            reduxState?.[STORE_KEYS.ADD_DETAILS_ACTIVE_ALARM]?.body?.data &&
            reduxState?.[STORE_KEYS.ADD_DETAILS_ACTIVE_ALARM]?.statusCode === 200
        ) {
            const __activeAlarms = reduxState?.[STORE_KEYS.ADD_DETAILS_ACTIVE_ALARM]?.body?.data;
            setTableData(__activeAlarms);
            dispatch(resetStoreKey({ storeKey: STORE_KEYS.ADD_DETAILS_ACTIVE_ALARM }));
        }
        dispatch(
            updateScreenIdentifiers({
                storeKey: STORE_KEYS.ADD_DETAILS_ACTIVE_ALARM,
                uniqueScreenIdentifier: {
                    activeAlarmDetailTable: false
                }
            })
        );
    }, [reduxState?.[STORE_KEYS.ADD_DETAILS_ACTIVE_ALARM]?.activeAlarmDetailTable]);

    const filteredList = applySortFilter(tableData, order, filterName, orderBy);

    const handleRequestSort = (event: any, property: any) => {
        setFilterName('');
        console.log(event, property);
        const isAsc = orderBy === event && order === 'asc';
        setOrder(isAsc ? 'desc' : 'asc');
        setOrderBy(event);
    };

    const handleSelectAllClick = (event: any) => {
        if (event.target.checked) {
            const newSelecteds = [];
            for (let i = 0; i < tableData.length; i++) {
                newSelecteds.push(tableData[i].id);
            }
            const toRemove = tableData.slice(page, tableData.length);
            setSelected(newSelecteds);
            return;
        }
        setSelected([]);
    };

    const handleClick = (requestedBy: string) => {
        const selectedIndex = selected.indexOf(requestedBy);
        let newSelected: any[] | ((prevState: unknown[]) => unknown[]) = [];
        if (selectedIndex === -1) {
            newSelected = newSelected.concat(selected, requestedBy);
        } else if (selectedIndex === 0) {
            newSelected = newSelected.concat(selected.slice(1));
        } else if (selectedIndex === selected.length - 1) {
            newSelected = newSelected.concat(selected.slice(0, -1));
        } else if (selectedIndex > 0) {
            newSelected = newSelected.concat(selected.slice(0, selectedIndex), selected.slice(selectedIndex + 1));
        }
        setSelected(newSelected);
    };

    const toggleDrawer = (anchor: Anchor, open: boolean) => (event: React.KeyboardEvent | React.MouseEvent) => {
        if (
            event.type === 'keydown' &&
            ((event as React.KeyboardEvent).key === 'Tab' || (event as React.KeyboardEvent).key === 'Shift')
        ) {
            return;
        }
        setState({ ...state, [anchor]: open });
    };

    const handleFilterByName = (event: { target: { value: React.SetStateAction<string> } }) => {
        setOrderBy('machineName');
        setFilterName(event.target.value);
    };

    const handleChangePage = (event: any, newPage: React.SetStateAction<number>) => {
        setPage(newPage);
    };

    const showRow = (event: any) => { };

    const handleRoute = (row: any, ele: any) => {
        if (ele === 'Action') {
        }
    };

    const handleFilter = () => { };

    return (
        <div>
            <div>
                <Container
                    maxWidth="xl"
                    style={{
                        paddingLeft: '3px',
                        paddingRight: '3px'
                    }}
                >
                    <Card
                        style={{
                            borderRadius: '0px',
                            backgroundColor: '#2b2d42',
                            overflow: 'inherit'
                        }}
                    >
                        <CustomTableToolbar
                            drawerOpen={toggleDrawer('right', true)}
                            numSelected={selected?.length}
                            filterName={filterName}
                            onFilterName={handleFilterByName}
                            displayInput={false}
                            text={{
                                title: 'History',
                                subTitle: `Occurence: ${data?.NoOfOccurred || tableData?.length} times`
                            }}
                            filterButton={false}
                            customButton={false}
                        //     radios={true}
                        //     radioValue={radioValue}
                        //     radioFields={  [
                        //       { label: 'All', value: 'All' },
                        //       { label: 'Unassigned', value: 'Unassigned' },
                        //       { label: 'Onboarded', value: 'Onboarded' }
                        //   ]}
                        //     onRadioChange = {handleRadioValue}
                        />
                    </Card>
                </Container>
                <CustomTable
                    selectable={false}
                    headers={usedIn === 'event' || usedIn === 'event-details' ? TABLE_EVENT_HEAD : TABLE_HEAD}
                    body={filteredList}
                    filteredUsers={filteredList}
                    filterName={filterName}
                    selected={selected}
                    order={order}
                    orderBy={orderBy}
                    onRequestSort={handleRequestSort}
                    onSelectAllClick={handleSelectAllClick}
                    rowChange={(event: string) => handleClick(event)}
                    rowCount={filteredList?.length}
                    onPageChange={handleChangePage}
                    page={page}
                    setPageNumber={setPage}
                    selectRow={showRow}
                    route={handleRoute}
                    isRequestPage={true}
                    link={true}
                    noAction={true}
                    overflow={false}
                // drawerTable={usedIn == 'event-details' ? true : false}
                />
            </div>
        </div>
    );
};

export default AllAlarmDetails;
